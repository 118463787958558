// constants
import {TABLE_HEADER_CLASSNAMES} from 'core/constants';
// types
import {TStyles} from '../types';

export const getTableHeadClasses = (styles: TStyles, callChild: string = '') => {
  const headClassNameKey = TABLE_HEADER_CLASSNAMES[callChild as keyof typeof TABLE_HEADER_CLASSNAMES] || '';
  const headClassNameValue = styles[headClassNameKey];

  return {
    [headClassNameKey]: headClassNameValue,
  };
};
