import {BaseSyntheticEvent, useEffect, useState} from 'react';
import type {UserResponse} from 'stream-chat';
import cx from 'classnames';
//helpers
import {getWholeChar} from './helpers';
//functions
import {getDefaultImage} from 'core/functions';
//types
import {DefaultStreamChatGenerics} from './types';

export type AvatarProps<StreamChatGenerics extends DefaultStreamChatGenerics = DefaultStreamChatGenerics> = {
  image?: string | null;
  name?: string;
  onClick?: (event: BaseSyntheticEvent) => void;
  onMouseOver?: (event: BaseSyntheticEvent) => void;
  shape?: 'circle' | 'rounded' | 'square';
  size?: number;
  user?: UserResponse<StreamChatGenerics>;
};

const CustomAvatar = <StreamChatGenerics extends DefaultStreamChatGenerics = DefaultStreamChatGenerics>(
  props: AvatarProps<StreamChatGenerics>
) => {
  const {image, name, onClick = () => undefined, onMouseOver = () => undefined, shape = 'circle', size = 32} = props;

  const [error, setError] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setError(false);
    setLoaded(false);
  }, [image]);

  const onError = () => setError(true);
  const onLoad = () => setLoaded(true);

  const nameStr = name?.toString() || '';
  const initials = getWholeChar(nameStr, 0);
  const defaultImage = getDefaultImage();

  return (
    // eslint-disable-next-line
    <div
      className={cx(`str-chat__avatar str-chat__avatar--${shape}`, 'str-chat__message-sender-avatar')}
      data-testid="avatar"
      onClick={onClick}
      onMouseOver={onMouseOver}
      style={{
        flexBasis: `${size}px`,
        fontSize: `${size / 2}px`,
        height: `${size}px`,
        lineHeight: `${size}px`,
        width: `${size}px`,
      }}
      title={name}
    >
      {image && !error ? (
        <img
          alt={initials}
          className={cx('str-chat__avatar-image', {'str-chat__avatar-image--loaded': loaded})}
          data-testid="avatar-img"
          onError={onError}
          onLoad={onLoad}
          src={image}
          style={{
            flexBasis: `${size}px`,
            height: `${size}px`,
            objectFit: 'cover',
            width: `${size}px`,
          }}
        />
      ) : (
        <img
          className="str-chat__avatar-image"
          src={defaultImage}
          style={{
            flexBasis: `${size}px`,
            height: `${size}px`,
            objectFit: 'cover',
            width: `${size}px`,
          }}
          alt=""
        />
      )}
    </div>
  );
};

export default CustomAvatar;
