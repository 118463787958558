import {ChangeEvent, FC} from 'react';
import {useFormikContext} from 'formik';
import {useTranslation} from 'react-i18next';
//form configuration
import {RepeatOpportunityModalFormValues} from '../../formConfiguration';
//material
import {FormControl, FormControlLabel, Radio, RadioGroup} from '@material-ui/core';
//components
import OnContent from './OnContent';
import AfterContent from './AfterContent';
//styles
import styles from './RepeatEnds.module.scss';

const RepeatsEnds: FC = () => {
  const {
    setFieldValue,
    values: {endsType},
  } = useFormikContext<RepeatOpportunityModalFormValues>();

  const {t} = useTranslation('form');

  const endsLabel = t('opportunity.ends');

  const onEndsTypeChangeHandler = (value: ChangeEvent<HTMLInputElement>) =>
    setFieldValue('endsType', value.target.value);

  return (
    <FormControl className={styles.form}>
      <label>{endsLabel}</label>

      <RadioGroup name="endsType" onChange={onEndsTypeChangeHandler} value={endsType} className={styles.group}>
        <FormControlLabel value="on" control={<Radio />} label={<OnContent />} />
        <FormControlLabel value="after" control={<Radio />} label={<AfterContent />} />
      </RadioGroup>
    </FormControl>
  );
};

export default RepeatsEnds;
