// react
import {FC, useEffect} from 'react';
// other packages
import cx from 'classnames';
// interfaces
import {ActionType} from '../interfaces';
//styles
import styles from './index.module.scss';

interface ITimeLimitButtonProps {
  value: string;
  disabled: boolean;
  onClick?: () => void;
  actionType: ActionType;
  stopCounter: () => void;
  disabledButton: boolean;
  classNameDisabled: boolean;
  startCounter: (disabledButton: boolean, actionType: ActionType) => void;
}

export const TimeLimitButton: FC<ITimeLimitButtonProps> = ({
  classNameDisabled,
  disabledButton,
  startCounter,
  stopCounter,
  actionType,
  disabled,
  onClick,
  value,
}) => {
  useEffect(() => {
    if (disabledButton) {
      stopCounter();
    }
  }, [disabledButton]);

  const handleMouseDown = () => {
    startCounter(disabledButton, actionType);
  };

  const handleMouseLeave = () => {
    stopCounter();
  };

  const handleMouseUp = () => {
    stopCounter();
  };

  return (
    <button
      type="button"
      onClick={onClick}
      disabled={disabled}
      onMouseUp={handleMouseUp}
      onMouseDown={handleMouseDown}
      onMouseLeave={handleMouseLeave}
      className={cx(styles.button, {[styles.button_disabled]: classNameDisabled})}
    >
      <span>{value}</span>
    </button>
  );
};
