import {useState} from 'react';
import {useTranslation} from 'react-i18next';
//types
import {MESSAGES_FILTER_OPTION_VALUE} from '../../types';
//context
import {useMessagesFilterContext} from '../../context';
//components
import Checkbox from 'shared/components/Checkbox';
import PopupContainer from 'shared/components/PopupContainer';
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
//styles
import styles from './styles.module.scss';

const MessagesFilter = () => {
  const {t} = useTranslation(['buttons', 'form']);

  const {messagesFilterOptions, setMessagesFilterOptions, setIsMessagesFilterVisible} = useMessagesFilterContext();

  const [options, setOptions] = useState(messagesFilterOptions);

  const checkboxChangeHandler = (checked: boolean, value?: string | number) => {
    const newMessagesFilterOptions = messagesFilterOptions.map((option) =>
      option.value === value ? {...option, checked} : option
    );

    setOptions(newMessagesFilterOptions);
  };

  const saveClickHandler = () => {
    setMessagesFilterOptions!(options);
    setIsMessagesFilterVisible!(false);
  };

  const closeClickHandler = () => {
    setIsMessagesFilterVisible!(false);
  };

  const getLabel = (value: MESSAGES_FILTER_OPTION_VALUE) => {
    const titles: Record<typeof value, string> = {
      unread: t('form:filter.unreadMessages'),
    };

    return titles[value];
  };

  return (
    <PopupContainer
      isContentCenter
      isDisableContentMarginTop
      isContentHeightAuto
      isContentWidthAuto
      isDisableCloseButton
      isDisablePadding
      isEnableClickOutside
      closeButtonParentClickHandler={closeClickHandler}
    >
      <div className={styles.container}>
        {options.map((option) => (
          <Checkbox
            classNames={styles.checkbox}
            label={getLabel(option.value)}
            changeHandle={checkboxChangeHandler}
            value={option.value}
            defaultChecked={option.checked}
          />
        ))}
        <ButtonDefault title={t('buttons:button.save')} classList={['primary', 'md']} clickHandler={saveClickHandler} />
      </div>
    </PopupContainer>
  );
};

export default MessagesFilter;
