export const TABLE_ROW_CLASSNAMES = {
  members: 'members',
  viewMore: 'viewMore',
  students: 'students',
  past_oppo: 'past_oppo',
  volunteers: 'volunteers',
  smart_group: 'smart_group',
  opportunities: 'opportunities',
  organizations: 'organizations',
  inviteStudents: 'inviteStudents',
  signup_requests: 'signup_requests',
  inviteVolunteers: 'inviteVolunteers',
  smart_group_small: 'smart_group_small',
  possible_volunteers: 'possible_volunteers',
  opportunitiesVolunteer: 'opportunitiesVolunteer',
  possible_volunteers_pending: 'possible_volunteers_pending',
  opportunities_without_action: 'opportunities_without_action',
};
