import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
//redux
import {selectorGetOrgId} from 'redux/organization-service/selector';
//assets
import CopyImg from 'assets/image/copy.svg';
//helpers
import {getInviteLink} from './helpers';
//styles
import styles from './InviteLink.module.scss';

const InviteLink = () => {
  const {t} = useTranslation(['common', 'buttons', 'messages', 'tooltips']);

  const orgId = useSelector(selectorGetOrgId);

  const [isCopied, setIsCopied] = useState(false);
  const [inviteLink, setInviteLink] = useState('');

  useEffect(() => {
    (async () => {
      const inviteLink = await getInviteLink(orgId);
      setInviteLink(inviteLink);
    })();
  }, [orgId]);

  const copyClickHandler = () => {
    const cb = navigator.clipboard;
    cb.writeText(inviteLink);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 1000);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.link} onClick={copyClickHandler} title={t('tooltips:copyToClipboard')}>
        {t('buttons:button.copyLink')}
      </div>
      {!isCopied ? (
        <img
          className={styles.icon}
          src={CopyImg}
          alt={t('common:copyImg')}
          onClick={copyClickHandler}
          title={t('tooltips:copyToClipboard')}
        />
      ) : (
        t('messages:copied').toLowerCase()
      )}
    </div>
  );
};

export default InviteLink;
