import {Dispatch, FC, SetStateAction, useEffect, useRef, useState} from 'react';
import cx from 'classnames';
//styles
import styles from './index.module.scss';

type InputSearchProps = {
  changeHandler: (value: string) => void;
  placeholder: string;
  setIsClearSearch?: Dispatch<SetStateAction<boolean>>;
  id?: string;
  classList?: Array<string>;
  parentClass?: string;
  value?: string;
  autoFocus?: boolean;
  isClearSearch?: boolean;
  setIsInputFocused?: Dispatch<SetStateAction<boolean>>;
};

const InputSearch: FC<InputSearchProps> = ({
  placeholder = '',
  id,
  classList,
  changeHandler,
  parentClass,
  value,
  autoFocus = false,
  isClearSearch,
  setIsClearSearch,
  setIsInputFocused,
}: InputSearchProps) => {
  const [inputValue, setInputValue] = useState<string>(value ?? '');

  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const getData = setTimeout(() => changeHandler(inputValue.trim()), 500);
    return () => clearTimeout(getData);
  }, [inputValue]);

  useEffect(() => {
    if (isClearSearch) setInputValue('');
  }, [isClearSearch]);

  const setFocus = (): void => {
    if (ref && ref.current) ref.current.focus();
  };

  const focusHandler = () => setIsInputFocused?.(true);
  const blurHandler = () => setIsInputFocused?.(false);

  return (
    <div
      className={cx(styles.input__search, parentClass, {
        [styles.big]: classList?.includes('big'),
      })}
    >
      <div className={styles.input__search_icon} onClick={setFocus} />
      <input
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={autoFocus}
        id={id && id}
        type="text"
        value={value || inputValue || ''}
        placeholder={placeholder}
        ref={ref}
        onChange={(e) => {
          setInputValue(e.target.value);
          setIsClearSearch && setIsClearSearch(false);
        }}
        onFocus={focusHandler}
        onBlur={blurHandler}
      />
    </div>
  );
};

export default InputSearch;
