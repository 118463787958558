import {FC} from 'react';
import cx from 'classnames';
//assets
import UserIcon from 'assets/image/user-icon.svg';
//styles
import styles from './UserIconDefault.module.scss';

type UserIconDefaultProps = {
  parentClassName?: string;
};

const UserIconDefault: FC<UserIconDefaultProps> = ({parentClassName}) => {
  return (
    <div className={cx(styles.main, parentClassName)}>
      <img src={UserIcon} alt="user-icon" />
    </div>
  );
};

export default UserIconDefault;
