import {Dispatch, FC, SetStateAction, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import cx from 'classnames';
//formik
import {Formik} from 'formik';
//redux
import {Store} from 'redux/root';
import {setError} from 'redux/error-service/action';
import {getOrganizationUserByEmailAndPassword} from 'redux/user-service/actions';
import {getErrorActionCreatorDefaultArguments} from 'redux/user-service/helpers';
//urls
import {urls} from 'core/appUrls';
//form configuration
import {LoginFormValues, initialValues, validationSchema} from './formConfiguration';
//functions
import {getLoginError} from 'core/functions/firebase/firebaseSignin';
//types
import {LOGIN_ERROR} from 'core/types';
//components
import UserOrganizationsSelect from 'shared/components/SelectDropdown/UserOrganizationsSelect';
import CreateAccount from 'components/Organization/Login/CreateAccount';
import LoginFieldsForm from './LoginFieldsForm';
//styles
import styles from './LoginForm.module.scss';

type LoginFormParentProps = {
  setLoginError: Dispatch<SetStateAction<LOGIN_ERROR>>;
};

const LoginForm: FC<LoginFormParentProps> = ({setLoginError}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {t} = useTranslation(['inputs', 'login']);

  const [isLoading, setIsLoading] = useState(false);
  const [loginSuccess, setLoginSuccess] = useState(false);
  const [selectOrganizationSuccess, setSelectOrganizationSuccess] = useState(false);

  const userFullName = useSelector((store: Store) =>
    store.userRedux.userData?.firstName && store.userRedux.userData?.lastName
      ? `${store.userRedux.userData?.firstName} ${store.userRedux.userData?.lastName}`
      : ''
  );

  useEffect(() => {
    if (selectOrganizationSuccess) history.push(urls.main);
  }, [selectOrganizationSuccess]);

  const submitClickHandler = async ({email, password}: LoginFormValues) => {
    setIsLoading(true);

    try {
      await dispatch(getOrganizationUserByEmailAndPassword(email, password));
      setLoginSuccess(true);
    } catch (error) {
      const loginError = getLoginError(error);

      const isNotFound =
        error.response?.code === 404 || error.response?.code === 422 || loginError === LOGIN_ERROR.NOT_FOUND;

      if (loginError === LOGIN_ERROR.TOO_MANY_REQUESTS) {
        setLoginError(LOGIN_ERROR.TOO_MANY_REQUESTS);
      }

      if (isNotFound) {
        setLoginError(LOGIN_ERROR.NOT_FOUND);
      }

      if (loginError === LOGIN_ERROR.WRONG_PASSWORD) {
        setLoginError(loginError);
      }

      if (loginError === LOGIN_ERROR.INTERRUPTED) {
        setLoginError(loginError);
      } else {
        const {message, code} = getErrorActionCreatorDefaultArguments(error);
        dispatch(setError(message, code));
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      <h1 className={cx('title', styles.title)}>
        {t('login:welcome')}
        {!!userFullName && `, ${userFullName}`}
      </h1>

      {loginSuccess ? (
        <UserOrganizationsSelect
          placeholder={t('inputs:placeholders.selectOrg')}
          classList={['maxWidthNone']}
          setSelectOrganizationSuccess={setSelectOrganizationSuccess}
        />
      ) : (
        <>
          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={submitClickHandler}>
            <LoginFieldsForm isLoading={isLoading} />
          </Formik>

          <CreateAccount linkTo={urls.onboardingOrgSignupAboutYou} disabled={isLoading} />
        </>
      )}
    </div>
  );
};

export default LoginForm;
