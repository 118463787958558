//API
import {API} from 'core/API';
//types
import {BroadcastMessageRequest} from '@joc/api-gateway';
//functions
import {sendFilesToS3Bucket} from 'core/functions';
import {defaultFileMimeTypes} from 'core/constants';

export const MAX_PHOTOS = 10;

export const allowedImageTypes = [
  'image/jpeg',
  'image/png',
  // Streamchat allowed extensions:
  // 'image/bmp',
  // 'image/gif',
  // 'image/webp',
  // 'image/heic',
  // 'image/heic-sequence',
  // 'image/heif',
  // 'image/heif-sequence',
  // 'image/svg+xml',
];

export const getIsUploadedPhotoValid = (photo: File): boolean => {
  const MAX_PHOTO_SIZE_IN_MEGABYTES = 100;
  const megabyteInBytes = 1024 * 1024;
  const photoSizeInMegabytes = photo.size / megabyteInBytes;

  return defaultFileMimeTypes.includes(photo.type) && photoSizeInMegabytes < MAX_PHOTO_SIZE_IN_MEGABYTES;
};

export const getUploadedPhotoInvalidError = (photo: File) => {
  const MAX_PHOTO_SIZE_IN_MEGABYTES = 100;
  const megabyteInBytes = 1024 * 1024;
  const photoSizeInMegabytes = photo.size / megabyteInBytes;

  return photoSizeInMegabytes > MAX_PHOTO_SIZE_IN_MEGABYTES ? 'validation:upload.tooLargeFile100' : '';
};

export const broadcastMessage = async (
  organizationId: number,
  message: string,
  files?: Array<File>,
  groupId?: number
) => {
  const attachments = files?.length ? await sendFilesToS3Bucket(files) : undefined;

  await API.broadcastMessage(
    BroadcastMessageRequest.fromJS({
      groupId,
      organizationId,
      message,
      attachments,
    })
  );
};
