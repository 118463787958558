// react
import {FC} from 'react';
// components
import ReminderTape from 'components/Organization/ReminderTape';
import {CalendarSidebarWrapper} from './CalendarSidebarWrapper';
// mui
import Drawer from '@material-ui/core/Drawer';
import {makeStyles} from '@material-ui/core/styles';
// context
import {useOpportunitiesContext} from 'pages/Organization/Opportunities/context';
// data
import {defaultSidebarConfig} from 'components/Organization/Opportunities/OpportunitiesFilters/OpportunitiesFiltersForm/data';

const useStyles = makeStyles({
  paper: {
    zIndex: 99,
    justifyContent: 'flex-end',
    height: 'calc(100% - 100px)',
    top: 'unset',
    bottom: 0,
  },
});

export const OppoSidebarWithCalendar: FC = () => {
  const classes = useStyles();

  const {
    sidebarVisibility: {isShowCalendar},
    setSidebarVisibility,
  } = useOpportunitiesContext();

  const handleClose = () => {
    setSidebarVisibility(defaultSidebarConfig);
  };

  return (
    <Drawer
      anchor="right"
      variant="persistent"
      classes={{
        paper: classes.paper,
      }}
      open={isShowCalendar}
      onClose={handleClose}
    >
      <CalendarSidebarWrapper handleClose={handleClose}>
        <ReminderTape />
      </CalendarSidebarWrapper>
    </Drawer>
  );
};
