import {FC, Fragment} from 'react';
import cx from 'classnames';
import {useTranslation} from 'react-i18next';
//components
import ArrowBackLink from 'shared/components/ArrowBackLink';
import AboutYouSignupForm from 'components/Organization/Forms/AboutYouSignupForm';
import SignUpContentContainer from '../SignUpContentContainer';
//styles
import styles from './AboutYouSignup.module.scss';

const AboutYouSignup: FC = () => {
  const {t} = useTranslation('login');

  return (
    <Fragment>
      <ArrowBackLink onboardingStyle />
      <SignUpContentContainer title={t('welcome')} subTitle={t('subtitle1')}>
        <div className={styles.container}>
          <div className={styles.title__wrapper}>
            <span className={cx(styles.title, 'title')}>{t('subtitle0')}</span>
          </div>
          <AboutYouSignupForm />
        </div>
      </SignUpContentContainer>
    </Fragment>
  );
};

export default AboutYouSignup;
