import React, {FC, useEffect, useMemo, useState} from 'react';
import moment from 'moment';
import {IChallengeResponse} from '@joc/api-gateway';

import {ReactComponent as ArrowBack} from 'assets/image/mdi_arrow-left.svg';
import {ReactComponent as BannerBackgroundImage} from 'assets/image/challenge-banner.svg';
import {ReactComponent as DescriptionTopConfetti} from 'assets/image/description-top-confetti.svg';
import {ReactComponent as ClockSvg} from 'assets/image/clock-challenges.svg';
import {ReactComponent as ArrowDownSvg} from 'assets/image/finger-arrow-down.svg';
import {ReactComponent as IdeaIcon} from 'assets/image/idea.svg';
import {ReactComponent as PhoneHomeIndicatorImage} from 'assets/image/phone-home-indicator.svg';

import {ReactComponent as DescriptionBottomConfetti} from 'assets/image/description-bottom-confetti.svg';

import Confetti from 'assets/image/confetti.png';

import styles from './ChallengePreview.module.scss';

import {getUserPhotoSrc} from '../../../../../core/functions';
import ButtonDefault from '../../../../../shared/components/Buttons/ButtonsDefault';
import {useDispatch, useSelector} from 'react-redux';
import {attachChallengeParticipant} from '../../../../../redux/challenges-service/actions';
import {Store} from '../../../../../redux/root';

type ChallengesPreviewProps = {
  previewData: Partial<IChallengeResponse> | null;
  onClose: () => void;
  isMobile: boolean;
};

const ChallengesPreview: FC<ChallengesPreviewProps> = ({previewData, onClose, isMobile}) => {
  const dispatch = useDispatch();
  const parsedImagePath = useMemo(() => getUserPhotoSrc(previewData?.imagePath, undefined), [previewData?.imagePath]);
  const [isAttached, setIsAttached] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const handleAttachParticipant = () => {
    if (previewData?.id) dispatch(attachChallengeParticipant(previewData.id));
    setIsAttached(true);
  };

  const challenge = useSelector((store: Store) => store.challengeRedux.challenge);

  useEffect(() => {
    const volunteerId = localStorage.getItem('volunteerId');
    if (challenge?.volunteers?.length && volunteerId) {
      setIsAttached(challenge?.volunteers?.some((volunteer) => Number(volunteer.volunteerId) === Number(volunteerId)));
    }

    setIsLoading(false);
  }, [challenge]);

  return (
    <section style={{width: 464}}>
      {previewData && (
        <section className={styles.preview}>
          <div
            className={styles.preview__confetti}
            style={{
              backgroundImage: `url(${Confetti})`,
            }}
          >
            <div className={styles.preview__confetti_back}>
              <ArrowBack onClick={onClose} />
            </div>
          </div>
          <div className={styles.preview__banner}>
            <BannerBackgroundImage />
            <div className={styles.preview__banner__dataBlock}>
              <div className={styles.preview__banner__dataBlock_title}>{previewData.title}</div>
              <img className={styles.preview__banner__dataBlock_imgBlock} src={parsedImagePath} alt="Challenge" />
            </div>
          </div>

          <div className={styles.preview__description}>
            <div className={styles.preview__description_content}>
              <DescriptionTopConfetti className={styles.preview__description__confetti_top} />
              {(previewData?.description && <section dangerouslySetInnerHTML={{__html: previewData.description}} />) ||
                null}
              <div className={styles.preview__description_deadline}>
                <ClockSvg />
                <span>Deadline: {moment(previewData.endDate).format('MMMM DD, YYYY')}</span>
              </div>
              <DescriptionBottomConfetti className={styles.preview__description__confetti_bottom} />
            </div>
          </div>

          {previewData?.chesedIdeas?.filter((idea) => idea)?.length ? (
            <div className={styles.preview__chesedIdeas}>
              <div className={styles.preview__chesedIdeas_title}>
                <ArrowDownSvg />
                <span>Chesed ideas:</span>
              </div>
              {previewData.chesedIdeas
                ?.filter((idea) => idea)
                .map((idea, index) => (
                  <div className={styles.preview__chesedIdeas_item} key={index}>
                    <IdeaIcon style={{minWidth: 24, minHeight: 24}} />
                    <span>{idea}</span>
                  </div>
                ))}
            </div>
          ) : null}

          <div className={styles.preview__button_wrap}>
            <ButtonDefault
              classList={['primary', 'extra_lg']}
              parentClassName={styles.preview__button}
              clickHandler={handleAttachParticipant}
              disabled={isLoading || isAttached || moment(previewData.startDate).isAfter()}
              title="Let’s go!"
            />
          </div>
          {!isMobile ? <PhoneHomeIndicatorImage style={{marginTop: 49}} /> : null}
        </section>
      )}
    </section>
  );
};

export default ChallengesPreview;
