import {FC} from 'react';
import {Route} from 'react-router';
import {connect, ConnectedProps} from 'react-redux';
//redux
import {Store} from 'redux/root';
import {selectorGetUserRoles} from 'redux/user-service/selector';
//types
import {VOLUNTEER} from '@joc/api-gateway';
//components
import NotFound from 'pages/NotFound';

type RouteGuardWithGuestParentProps = {
  path: string;
  component: FC;
  accessRoles: Array<VOLUNTEER>;
};

const RouteGuardWithGuest: FC<RouteGuardWithGuestProps> = ({
  userRoles,
  path,
  component: Component,
  accessRoles,
}: RouteGuardWithGuestProps) => {
  return (
    <Route exact path={path}>
      {userRoles?.find((i) => i.roleName === accessRoles?.find((e) => e === i.roleName)) || !userRoles?.length ? (
        <Component />
      ) : (
        <NotFound />
      )}
    </Route>
  );
};

const mapStateToProps = (store: Store) => ({
  userRoles: selectorGetUserRoles(store),
});

const connector = connect(mapStateToProps);

type RouteGuardWithGuestProps = ConnectedProps<typeof connector> & RouteGuardWithGuestParentProps;

export default connector(RouteGuardWithGuest);
