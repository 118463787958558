//types
import {
  IClaimSort,
  INVITED_USER_POSSIBLE_SORT,
  ISearchVolunteersSort,
  SEARCH_VOLUNTEER_POSSIBLE_SORT,
  SortDirection,
} from '@joc/api-gateway';

export const getHeaderSortQueryParams = (
  sortDir: SortDirection | undefined,
  sortBy: SEARCH_VOLUNTEER_POSSIBLE_SORT | INVITED_USER_POSSIBLE_SORT,
  isSortDescFirst?: boolean
): ISearchVolunteersSort | IClaimSort => ({
  sortBy,
  sortDir: isSortDescFirst
    ? sortDir === SortDirection.DESC
      ? SortDirection.ASC
      : SortDirection.DESC
    : sortDir === SortDirection.ASC
    ? SortDirection.DESC
    : SortDirection.ASC,
});
