import {ChannelProps} from './ChatSearchResult';
import {OwnUserResponse} from 'stream-chat/dist/types';
import {ORGANISATION_ACTIVE_STATUS, VOLUNTEER_ACTIVE_STATUS} from '@joc/api-gateway/lib/api-client';

export type ChannelsSearchResponse = Array<ChannelProps>;
export interface CustomUserResponseType extends OwnUserResponse {
  type?: string;
}

export type UsersListType = {
  id?: string;
  name?: string;
  imagePath?: string;
  activeStatus?: ORGANISATION_ACTIVE_STATUS | VOLUNTEER_ACTIVE_STATUS;
};

export enum userRole {
  VOLUNTEER = 'volunteer',
  ORGANIZATION = 'organisation',
}
