import {FC, useCallback, useEffect, useState} from 'react';
import {tenthListItemSelector} from '../../../../../shared/components/Buttons/ButtonUpToTop/util';
import {
  GetVolunteerByIdResponse,
  IOpportunityResponse,
  OPPORTUNITY_ACTIVE_STATUS,
  OPPORTUNITY_STATUSES,
  OPPORTUNITY_TYPES,
  OPPORTUNITY_VOLUNTEER_STATUS,
  StatusOpportunityVolunteersRequestStatus,
} from '@joc/api-gateway';
import AvatarCell from '../../../../../shared/components/Table/CellRenderers/AvatarCell';
import {
  convertTimeToHoursAndMinutes,
  formatDate,
  generateLocation,
  getDurationDetails,
  getImageType,
  getOpportunityImagePath,
} from '../../../../../core/functions';
import OpportunityNameCell from '../../../../../shared/components/Table/CellRenderers/OpportunityNameCell';
import styles from '../index.module.scss';
import CellDefault from '../../../../../shared/components/Table/CellRenderers/CellDefault';
import {TABLE_ITEMS_CLASSNAMES} from '../../../../../core/constants';
import FullNameCell from '../../../../../shared/components/Table/CellRenderers/FullNameCell';
import PossibleVolunteersCell from '../../../../../shared/components/Table/CellRenderers/PossibleVolunteersCell';
import cx from 'classnames';
import Loader from '../../../../../shared/components/Loader';
import ConfirmRejectActions from '../../../../../shared/components/Table/CellRenderers/ManualOpportunityActions';
import {TableActionsPopup} from '../../../../TableActionsPopup';
import TableRow from '../../../../../shared/components/Table/TableRow';
import {changeStatusManualOpportunity, changeStatusVolunteers} from '../../../../../redux/opportunities-service/action';
import {useSelector} from 'react-redux';
import {selectorGetOpportunitiesRecords} from '../../../../../redux/opportunities-service/selector';
import {useTranslation} from 'react-i18next';
import {useOpportunitiesContext} from '../../../../../pages/Organization/Opportunities/context';
import useThunkDispatch from '../../../../../hooks/useThunkDispatch';

type OpportunityRowProps = {
  opportunity: IOpportunityResponse;
  isPrintableTable: boolean;
  isSuspended: boolean;
  index: number;
};

const OpportunityRow: FC<OpportunityRowProps> = ({opportunity, isPrintableTable, isSuspended, index}) => {
  const {generalTabClickHandler} = useOpportunitiesContext();
  const dispatch = useThunkDispatch();
  const [isStatusChanging, setStatusChanging] = useState(false);

  const {t} = useTranslation('popup');

  const opportunitiesRecords = useSelector(selectorGetOpportunitiesRecords);

  const confirmOpportunityHandler = (opportunity: IOpportunityResponse) => () => {
    setStatusChanging(true);
    if (opportunity.opportunityType === OPPORTUNITY_TYPES.MANUAL) {
      dispatch(changeStatusManualOpportunity(opportunity.id, OPPORTUNITY_STATUSES.OPEN)).finally(() =>
        setStatusChanging(false)
      );
    } else {
      dispatch(
        changeStatusVolunteers(
          opportunity.id,
          [Number(opportunity?.volunteers?.[0]?.id || 0)],
          StatusOpportunityVolunteersRequestStatus.CONFIRM
        )
      ).finally(() => setStatusChanging(false));
    }
  };

  const rejectOpportunityHandler = (opportunity: IOpportunityResponse) => () => {
    setStatusChanging(true);
    if (opportunity.opportunityType === OPPORTUNITY_TYPES.MANUAL) {
      dispatch(changeStatusManualOpportunity(opportunity.id, OPPORTUNITY_STATUSES.CANCELED)).finally(() =>
        setStatusChanging(false)
      );
    } else {
      dispatch(
        changeStatusVolunteers(
          opportunity.id,
          [Number(opportunity?.volunteers?.[0]?.id || 0)],
          StatusOpportunityVolunteersRequestStatus.REJECT
        )
      ).finally(() => setStatusChanging(false));
    }
  };

  const getTextDate = useCallback(
    (opportunity: IOpportunityResponse) => {
      const date = formatDate(opportunity.startDate);
      const checkIsManual = opportunity.opportunityType !== OPPORTUNITY_TYPES.MANUAL;
      const startDate = checkIsManual ? convertTimeToHoursAndMinutes(opportunity.startDate) : '';
      const endDate = checkIsManual ? convertTimeToHoursAndMinutes(opportunity.endDate) : '';

      if (startDate && endDate) {
        return `${date}\n${startDate}-${endDate}`;
      }

      return date;
    },
    [opportunitiesRecords]
  );

  const isSingleVolunteer = (volunteers: GetVolunteerByIdResponse[]) =>
    volunteers.length === 1 &&
    volunteers.filter((volunteer) => volunteer?.status === OPPORTUNITY_VOLUNTEER_STATUS.PENDING).length === 1;

  return (
    <TableRow
      className={tenthListItemSelector(index)}
      callChild="opportunities"
      suspend={opportunity.opportunityActiveStatus?.status === OPPORTUNITY_ACTIVE_STATUS.SUSPENDED || isSuspended}
    >
      <AvatarCell
        imagePath={getOpportunityImagePath(opportunity)}
        avatarTitle={t('opportunity.pictureOfOppo')}
        popupType={getImageType(opportunity)}
      />
      <OpportunityNameCell
        opportunityName={opportunity.opportunityName}
        opportunityType={opportunity.opportunityType}
        opportunityNameClass={styles.fullname}
        adminId={opportunity.user?.id}
        volunteers={opportunity.volunteers}
        isVerified={opportunity.isVerificated}
        opportunity={opportunity}
      />
      <CellDefault parentClassName={TABLE_ITEMS_CLASSNAMES.date} text={getTextDate(opportunity)} />
      <CellDefault
        parentClassName={TABLE_ITEMS_CLASSNAMES.duration}
        text={opportunity.opportunityType === OPPORTUNITY_TYPES.MANUAL ? getDurationDetails(opportunity.duration) : ''}
      />
      <CellDefault
        parentClassName={TABLE_ITEMS_CLASSNAMES.location}
        text={opportunity?.isVirtual ? opportunity.opportunityUrl : generateLocation(opportunity.address)}
      />
      <CellDefault
        parentClassName={TABLE_ITEMS_CLASSNAMES.hashtag}
        text={opportunity.tagId && opportunity.tag ? `#${opportunity.tag}` : ''}
      />
      <FullNameCell firstName={opportunity.user?.firstName} lastName={opportunity.user?.lastName} disableTextStyle />
      <PossibleVolunteersCell
        opportunity={opportunity}
        volunteers={opportunity.volunteers}
        opportunityId={opportunity.id}
        isApprovalRequired={opportunity.isApprovalRequired}
        opportunityName={opportunity.opportunityName}
        opportunityStatus={opportunity?.status}
        opportunityType={opportunity.opportunityType}
        disableButton={
          opportunity.opportunityActiveStatus?.status === OPPORTUNITY_ACTIVE_STATUS.SUSPENDED || isSuspended
        }
      />
      {!isPrintableTable && (
        <>
          <div className={cx(styles.confirm__actions_visible)}>
            {(opportunity?.status === OPPORTUNITY_STATUSES.PENDING ||
              (opportunity.isApprovalRequired && isSingleVolunteer(opportunity.volunteers || []))) && (
              <>
                {isStatusChanging ? (
                  <Loader loadProps={{size: 24}} />
                ) : (
                  <ConfirmRejectActions
                    confirmHandler={confirmOpportunityHandler(opportunity)}
                    rejectHandler={rejectOpportunityHandler(opportunity)}
                    disabled={
                      opportunity.opportunityActiveStatus?.status === OPPORTUNITY_ACTIVE_STATUS.SUSPENDED || isSuspended
                    }
                  />
                )}
              </>
            )}
          </div>
          <TableActionsPopup
            opportunity={opportunity}
            isSuspended={isSuspended}
            generalTabClickHandler={generalTabClickHandler}
          />
        </>
      )}
    </TableRow>
  );
};

export default OpportunityRow;
