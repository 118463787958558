import {makeStyles} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import {Dispatch, FC, SetStateAction, useState} from 'react';
import {Formik} from 'formik';
import {SearchUsersByOrgRequest} from '@joc/api-gateway/lib/api-client';
// Redux
import {useSelector, useDispatch} from 'react-redux';
import {selectorGetOrgId} from 'redux/organization-service/selector';
import {filterMemberOrg, getOrgMembers, searchMemberOrg} from 'redux/organization-service/actions';
//types
import {ReasonType} from 'pages/Organization/Opportunities/interfaces';
import {MEMBERS_FILTERS_VALUES} from 'core/types/MEMBERS_FILTERS_VALUES';
import {INITIAL_MEMBERS_FILTERS_VALUES} from 'core/constants/INITIAL_MEMBERS_FILTERS_VALUES';
//components
import FiltersHeader from '../../Filters/FiltersHeader';
import {MemberFilterForm} from './MemberFilterForm';
//styles
import styles from './Sidebar.module.scss';

const useStyles = makeStyles({
  paper: {
    zIndex: 99,
  },
});

interface IMemberSidebarProps {
  isOpen: boolean;
  isCloseFilters: boolean;
  changeVisibilityFilters: () => void;
  setIsClearSearch?: Dispatch<SetStateAction<boolean>>;
  handleClose: (event: {}, reason: ReasonType) => void | undefined;
}

export const MemberSidebar: FC<IMemberSidebarProps> = ({
  isOpen,
  handleClose,
  isCloseFilters,
  changeVisibilityFilters,
}) => {
  const dispatch = useDispatch();
  const [initialValues] = useState<MEMBERS_FILTERS_VALUES>(INITIAL_MEMBERS_FILTERS_VALUES);
  const [isClearFilter, setIsClearFilter] = useState<boolean>(false);

  const orgId = useSelector(selectorGetOrgId);

  const handleSubmitForm = (values: {role: string}) => {
    dispatch(filterMemberOrg(orgId.toString(), {roleNames: [values.role.toUpperCase()]} as SearchUsersByOrgRequest));
  };

  const clearFilter = () => {
    setIsClearFilter(true);
    dispatch(getOrgMembers());
  };

  const classes = useStyles();
  return (
    <Drawer
      anchor="right"
      variant="persistent"
      classes={{
        paper: classes.paper,
      }}
      open={isOpen}
      onClose={handleClose}
    >
      <div className={styles.filter}>
        <FiltersHeader changeVisibilityFilters={changeVisibilityFilters} resetForm={clearFilter} />
        <Formik enableReinitialize initialValues={initialValues} onSubmit={handleSubmitForm}>
          <MemberFilterForm
            setIsClearFilter={setIsClearFilter}
            isCloseFilters={isCloseFilters}
            isClearFilter={isClearFilter}
          />
        </Formik>
      </div>
    </Drawer>
  );
};
