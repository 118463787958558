// react
import {FC, useMemo} from 'react';
// helper
import {convertTimeToHoursAndMinutes, formatDate} from 'core/functions';
// styles
import styles from './index.module.scss';

interface ICellDataTimeProps {
  date?: Date | null;
}

export const CellDataTime: FC<ICellDataTimeProps> = ({date}) => {
  const cellData = useMemo(
    () => ({
      date: date ? formatDate(date) : '',
      time: date ? convertTimeToHoursAndMinutes(date) : '',
    }),
    [date]
  );

  return (
    <div className={styles.wrapper}>
      <span className={styles.date}>{cellData.date}</span>
      <span className={styles.time}>{cellData.time}</span>
    </div>
  );
};
