import {FC, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import {useTranslation} from 'react-i18next';
//redux
import {Store} from 'redux/root';
import {getCurrentUser} from 'redux/user-service/actions';
//types
import {VOLUNTEER_ACTIVE_STATUS} from '@joc/api-gateway';
//components
import Appeal from 'shared/components/Appeal/DefaultRenderer';
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
import ChatOk from 'shared/components/ChatOk';
//styles
import styles from './ChatV.module.scss';

const VolunteerChat: FC = () => {
  const userSuspend = useSelector((state: Store) => state.userRedux.userData?.volunteerActiveStatus);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getCurrentUser());
  }, []);

  const {t} = useTranslation(['errors', 'buttons']);

  if (userSuspend && userSuspend !== VOLUNTEER_ACTIVE_STATUS.SUSPENDED)
    return (
      <div className={styles.main}>
        <ChatOk />
      </div>
    );

  return (
    <div className={styles.main}>
      <Appeal stylePensive>
        <div className={styles.content}>
          <span className="title">{t('errors:sorry')}</span>
          <span className={styles.content__message}>{t('errors:cantUseChatSuspended')}</span>
          <ButtonDefault
            classList={['primary', 'md']}
            title={t('buttons:button.back')}
            clickHandler={() => history.goBack()}
          />
        </div>
      </Appeal>
    </div>
  );
};

export default VolunteerChat;
