// react
import {FC, PropsWithChildren, useMemo} from 'react';
// class names
import cx from 'classnames/bind';
// helper
import {getRowClasses} from './helper';
//styles
import styles from './TableRow.module.scss';

const cxStyle = cx.bind(styles);
interface ITableRowProps {
  suspend?: boolean;
  className?: string;
  callChild?: string;
  rejection?: boolean;
  overtariff?: boolean;
}

const TableRow: FC<PropsWithChildren<ITableRowProps>> = ({
  suspend,
  children,
  rejection,
  className,
  callChild,
  overtariff,
}) => {
  const rowClassesOptions = {callChild, suspend, rejection, overtariff};

  const rowClasses = useMemo(
    () => getRowClasses(styles, rowClassesOptions),
    [styles, callChild, suspend, rejection, overtariff]
  );

  return <div className={cxStyle(className, styles.container, rowClasses)}>{children}</div>;
};

export default TableRow;
