import {FC, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
//formik
import {Field, Form, useFormikContext} from 'formik';
import {LoginFormValues} from '../formConfiguration';
//icons
import {ReactComponent as InfoIcon} from 'assets/icons/info.svg';
//components
import Input from 'shared/inputs/Input';
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
import Loader from 'shared/components/Loader';
import RecoveryPasswordLink from 'shared/components/RecoveryPasswordLink';
//styles
import styles from './LoginFieldsForm.module.scss';

type LoginFieldsFormParentsProps = {
  isLoading: boolean;
};

const LoginFieldsForm: FC<LoginFieldsFormParentsProps> = ({isLoading}) => {
  const {t} = useTranslation(['inputs', 'buttons', 'form']);

  const [isFocused, setIsFocused] = useState(false);

  return (
    <Form className={styles.form}>
      <Field name="email" placeholder={t('inputs:placeholders.email')} isDisabledMargin component={Input} />
      <Field
        name="password"
        type="password"
        placeholder={t('inputs:placeholders.password')}
        isDisabledMargin
        component={Input}
        onFocus={() => setIsFocused(true)}
      />

      {isFocused && (
        <div className={styles.form__info}>
          <InfoIcon />
          <p>{t('form:passwordIsCaseSensitive')}</p>
        </div>
      )}

      <RecoveryPasswordLink />

      <div className={styles.button__container}>
        {isLoading ? (
          <Loader />
        ) : (
          <ButtonDefault submitType classList={['primary', 'extra_lg']} title={t('buttons:button.signIn')} />
        )}
      </div>
    </Form>
  );
};

export default LoginFieldsForm;
