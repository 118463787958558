import {RefObject, useRef} from 'react';
import {TableType} from '../components/Organization/Volunteers/Table/interfaces';

export function useScrollTable() {
  const headerTable: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
  const bodyTable: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);

  const scrollHeader = (event: any, type?: TableType) => {
    if (type === TableType.Header && bodyTable.current) {
      bodyTable.current.scrollLeft = event.target.scrollLeft;
    } else if (headerTable.current) {
      headerTable.current.scrollLeft = event.target.scrollLeft;
    }
  };

  return {headerTable, bodyTable, scrollHeader};
}
