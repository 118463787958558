import {FC, useEffect, useMemo, useState} from 'react';
import {connect, ConnectedProps, useDispatch, useSelector} from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
//redux
import {selectorGetOpportunityById} from 'redux/opportunities-service/selector';
import {getOpportunityById, resetCurrentOpportunity} from 'redux/opportunities-service/action';
import {resetError, setError} from 'redux/error-service/action';
import {selectorGetError} from 'redux/error-service/selector';
import {Store} from 'redux/root';
//urls
import {urls} from 'core/appUrls';
//functions
import {sortVolunteersByName} from 'core/functions';
//types
import {OPPORTUNITY_VOLUNTEER_STATUS, IGetVolunteerByIdResponse, OPPORTUNITY_TYPES} from '@joc/api-gateway';
//components
import {Alert} from '@material-ui/lab';
import {Snackbar} from '@material-ui/core';
import ResponseFailure from 'shared/components/ResponseFailure';
import OpportunityContainer from 'components/Volunteer/Opportunities/OpportunitiesList/OpportunityContainer';
import ButtonSwitch from 'shared/components/Buttons/ButtonSwitch';
import ShareSocialPopup from 'components/Volunteer/Opportunities/OpportunitiesList/ShareLinks/ShareSocialPopup';
import ArrowBackLink from 'shared/components/ArrowBackLink';
import VolunteerMainInfo from 'shared/components/VolunteerMainInfo';
import VideoPreviewSquare from 'shared/components/VideoPreviewSquare';
import FeedDetailsView from 'components/Volunteer/FeedDetailsView';
import Loader from 'shared/components/Loader';
import ImageWithPopup from 'shared/components/ImageWithPopup';
import {VolunteerLimit} from 'shared/components/VolunteerLimit/VolunteerLimit';
// icons
import ShareSvg from 'assets/image/share.svg';
//styles
import styles from './FeedViewItemV.module.scss';
import {selectorGetUserData, selectorGetUserOrganizations} from 'redux/user-service/selector';
import {LINKS_URLS} from 'core/constants/LINK_URLS';

const FeedViewItem: FC<FeedViewItemProps> = ({
  opportunityById,
  getOpportunityById,
  setError,
  resetError,
  error,
  userInfo,
}: FeedViewItemProps) => {
  const history = useHistory();
  const {id}: {id: string | undefined} = useParams();
  const {t} = useTranslation(['oppoViewVolunteer', 'buttons', 'messages', 'errors', 'popup']);

  const userOrganizations = useSelector(selectorGetUserOrganizations);
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [isShowPopup, setIsShowPopup] = useState(false);
  const [isViewDetails, setIsViewDetails] = useState(true);
  const [isOpenSnackbar, setIsOpenSnackbar] = useState(false);

  const getCurrentOpportunityById = async (id: number) => {
    try {
      await getOpportunityById(+id);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setError(error?.response?.message || error.message, error.code);
    }
  };

  useEffect(() => {
    if (id) getCurrentOpportunityById(+id);
    return () => {
      dispatch(resetCurrentOpportunity());
    };
  }, [id]);

  const userHasAccessToOpportunity = useMemo(() => {
    if (opportunityById?.opportunityType === OPPORTUNITY_TYPES.PRIVATE) {
      const userOrganizationsIds = userOrganizations?.map((organization) => Number(organization.id)) || [];
      return userOrganizationsIds.includes(Number(opportunityById?.organisation?.id || 0));
    }

    return true;
  }, [userOrganizations, opportunityById]);

  const getOpportunityErrorMessage = useMemo(() => {
    if (error.state && error.message !== 'Reached max volunteers count')
      return (
        <ResponseFailure
          styleTable
          message={error.message}
          buttonTitle={t('buttons:button.goToSignIn')}
          buttonClickHandler={handleResetResponseFailure}
        />
      );

    if (!userHasAccessToOpportunity)
      return (
        <ResponseFailure
          title={t('errors:noOpportunityAccessTitle')}
          message={t('errors:noOpportunityAccessDescription')}
          buttonTitle={t('buttons:button.goToMainPage')}
          buttonClickHandler={() => history.push(urls.main)}
        />
      );

    return null;
  }, [error, userHasAccessToOpportunity]);

  const handleResetError = () => {
    resetError();
  };

  const handleResetResponseFailure = () => {
    resetError();
    history.push(urls.onboardingLoginVolunteer);
  };

  const handleCloseSnackBar = () => {
    setIsOpenSnackbar(false);
  };

  const isAdmin = useMemo(
    () =>
      opportunityById?.volunteers?.length === 1 && opportunityById.user?.id !== opportunityById.volunteers[0].userId,
    [opportunityById]
  );

  const isManualOppo = useMemo(() => opportunityById?.opportunityType === OPPORTUNITY_TYPES.MANUAL, [opportunityById]);
  const isVisibleVolunteersLimit = useMemo(() => opportunityById?.volunteersMaxCount !== null, [opportunityById]);

  const isVisibleShareLinks = useMemo(
    () => isViewDetails && !isManualOppo && opportunityById,
    [isViewDetails, isAdmin, isManualOppo, opportunityById]
  );

  const linkForCopying = useMemo(
    () => `${process.env.REACT_APP_MAIN_URL}${LINKS_URLS.feedViewLink}/${opportunityById?.id}`,
    [opportunityById]
  );

  return (
    <>
      {isLoading ? (
        <Loader loadProps={{parentClassName: styles.loader}} />
      ) : ((error.state && error.message !== 'Reached max volunteers count') || !userHasAccessToOpportunity) &&
        !isLoading ? (
        getOpportunityErrorMessage
      ) : (
        <OpportunityContainer parentClassName={styles.feed__view}>
          <ArrowBackLink parentClassName={styles.content__link_back} />
          <div className={styles.feed__main}>
            <div className={styles.feed__mainHeaderAction}>
              <ButtonSwitch
                switchState={isViewDetails}
                changeSwitch={setIsViewDetails}
                titleOptionFirst={t('oppoViewVolunteer:details')}
                titleOptionSecond={t('oppoViewVolunteer:volunteers')}
              />
              {isVisibleShareLinks && (
                <>
                  <img
                    src={ShareSvg}
                    className={styles.shareLink}
                    alt={t('buttons:button.share2')}
                    onClick={() => setIsShowPopup(true)}
                  />
                  {isShowPopup && (
                    <ShareSocialPopup
                      opportunity={opportunityById}
                      link={linkForCopying}
                      setIsShowPopup={setIsShowPopup}
                      setIsOpenSnackbar={setIsOpenSnackbar}
                    />
                  )}
                </>
              )}
              {isOpenSnackbar && (
                <Snackbar open={isOpenSnackbar} autoHideDuration={2000} onClose={handleCloseSnackBar}>
                  <Alert onClose={handleCloseSnackBar} severity="success">
                    {t('messages:linkCopied')}
                  </Alert>
                </Snackbar>
              )}
            </div>
            <div className={styles.feed__mainContent}>
              {!opportunityById && !isLoading ? (
                <ResponseFailure
                  message={t('errors:noOppo')}
                  buttonTitle={t('buttons:button.back')}
                  buttonClickHandler={() => history.go(-1)}
                />
              ) : (
                opportunityById && (
                  <>
                    {isViewDetails ? (
                      <FeedDetailsView
                        isAdmin={isAdmin}
                        userInfo={userInfo}
                        opportunityData={opportunityById}
                        handleResetError={handleResetError}
                      />
                    ) : (
                      <>
                        <div className={styles.switchView__item}>
                          {isVisibleVolunteersLimit && (
                            <div className={styles.volunteersLimit}>
                              <p className={styles.volunteersLimit__title}>{`${t(
                                'oppoViewVolunteer:volunteersRequired'
                              )}:`}</p>
                              <div className={styles.volunteersLimit__wrapper}>
                                <VolunteerLimit
                                  maxVolunteers={opportunityById?.volunteersMaxCount || 0}
                                  currentVolunteers={opportunityById?.volunteersCount || 0}
                                />
                              </div>
                            </div>
                          )}
                          <div className={styles.wrapFeed__team}>
                            {opportunityById.volunteers &&
                              sortVolunteersByName(opportunityById.volunteers)
                                .filter(
                                  (volunteer) =>
                                    volunteer?.status !== OPPORTUNITY_VOLUNTEER_STATUS.REJECT &&
                                    volunteer?.status !== OPPORTUNITY_VOLUNTEER_STATUS.CANT_MAKE_IT
                                )
                                .map((volunteer: IGetVolunteerByIdResponse) => (
                                  <VolunteerMainInfo
                                    key={volunteer.id}
                                    userImage={volunteer?.imagePath || ''}
                                    userFirstName={volunteer.firstName}
                                    userLastName={volunteer.lastName}
                                    points={volunteer.points}
                                    userGrade={volunteer.diploma.statusName}
                                  />
                                ))}
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )
              )}
            </div>
          </div>
          <div className={styles.feed__sidebar}>
            <div className={styles.feedDetails__user}>{opportunityById?.organisation?.organizationName}</div>
            <div className={styles.feedDetails__description}>
              {opportunityById?.organisation?.organizationDescription}
            </div>
            <div className={styles.feedDetails__organization}>
              <ImageWithPopup
                classNames={styles.organization__logo}
                imagePath={opportunityById?.organisation?.organizationLogoPath}
                alt={opportunityById?.organisation?.organizationName}
                popupTitle={t('popup:profilePicture.organization')}
                disableZeroTop
              />
              {opportunityById?.organisation?.organizationWebSite && (
                <a
                  href={opportunityById?.organisation?.organizationWebSite}
                  className={styles.organization__link}
                  target="blank"
                >
                  {opportunityById?.organisation?.organizationWebSite}
                </a>
              )}
            </div>
            <div className={styles.organization__contact}>
              <div className={styles.organization__title}>{t('oppoViewVolunteer:contactOrg')}</div>
              <a
                className={styles.organization__phone}
                href={`call:${opportunityById?.organisation?.organizationPhoneNumber}`}
              >
                {opportunityById?.organisation?.organizationPhoneNumber}
              </a>
              <a
                className={styles.organization__mail}
                href={`mailto:${opportunityById?.organisation?.organizationEmail}`}
              >
                {opportunityById?.user?.email || ''}
              </a>
            </div>
            {opportunityById?.organisation?.primaryVideo.videoPath && (
              <VideoPreviewSquare
                parrentClassName={styles.feed__mediaContent}
                link={opportunityById?.organisation?.primaryVideo.videoPath}
                videoId={opportunityById?.organisation?.primaryVideo.id}
              />
            )}
          </div>
        </OpportunityContainer>
      )}
    </>
  );
};

const mapStateToProps = (store: Store) => ({
  opportunityById: selectorGetOpportunityById(store),
  userInfo: selectorGetUserData(store),
  error: selectorGetError(store),
});
const mapDispatchToProps = {
  getOpportunityById,
  setError,
  resetError,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type FeedViewItemProps = ConnectedProps<typeof connector>;

export default connector(FeedViewItem);
