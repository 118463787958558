import {OPPORTUNITY_TYPES, WITH_WHO} from '@joc/api-gateway';
import {
  IUpdateManualOpportunityInitialValuesType,
  ManualOpportunityInitialValuesType,
  OpportunityInitialValuesType,
} from 'core/types';

export const OPPORTUNITY_INITIAL_VALUES: OpportunityInitialValuesType = {
  opportunityName: '',
  opportunityDescription: '',
  isApprovalRequired: false,
  startDate: new Date(),
  endDate: new Date(),
  startTime: '',
  endTime: '',
  user: null,
  withWho: [WITH_WHO.BABIES, WITH_WHO.OTHER, WITH_WHO.ADULTS, WITH_WHO.SENIORS, WITH_WHO.KIDS, WITH_WHO.TEENS],
  opportunityType: OPPORTUNITY_TYPES.PUBLIC,
  organisationId: '',
  address: null,
  imagePath: '',
  notifyWhatsapp: false,
};

export const MANUAL_OPPORTUNITY_INITIAL_VALUES: ManualOpportunityInitialValuesType = {
  opportunityName: '',
  opportunityDescription: '',
  isApprovalRequired: false,
  startDate: new Date(),
  endDate: new Date(),
  endTime: 0,
  user: null,
  withWho: [WITH_WHO.BABIES, WITH_WHO.OTHER, WITH_WHO.ADULTS, WITH_WHO.SENIORS, WITH_WHO.KIDS, WITH_WHO.TEENS],
  opportunityType: OPPORTUNITY_TYPES.PUBLIC,
  organisationId: '',
  address: null,
  imagePath: '',
  filePathes: [],
  recipientName: '',
  recipientEmail: '',
  recipientPhoneNumber: '',
};

export const UPDATE_MANUAL_OPPORTUNITY_INITIAL_VALUES: IUpdateManualOpportunityInitialValuesType = {
  opportunityName: '',
  opportunityDescription: '',
  isApprovalRequired: false,
  startDate: new Date(),
  endDate: new Date(),
  endTime: 1,
  user: null,
  opportunityType: OPPORTUNITY_TYPES.PUBLIC,
  organisationId: '',
  organization: {organizationName: '', organizationId: ''},
  address: null,
  filePathes: [],
  recipientName: '',
  recipientEmail: '',
  recipientPhoneNumber: '',
};

export const MANUAL_OPPORTUNITY_INITIAL_MOK_VALUES: ManualOpportunityInitialValuesType = {
  opportunityName: '',
  opportunityDescription: '',
  isApprovalRequired: false,
  startDate: new Date(),
  endDate: new Date(),
  // startTime: moment().format('HH:mm'),
  endTime: 1,
  user: null,
  withWho: [WITH_WHO.BABIES, WITH_WHO.OTHER, WITH_WHO.ADULTS, WITH_WHO.SENIORS, WITH_WHO.KIDS, WITH_WHO.TEENS],
  opportunityType: OPPORTUNITY_TYPES.PUBLIC,
  organisationId: '',
  address: null,
  imagePath: '',
  filePathes: [],
  recipientName: '',
  recipientEmail: '',
  recipientPhoneNumber: '',
};

export const OPPORTUNITY_INITIAL_MOK_VALUES: OpportunityInitialValuesType = {
  opportunityName: '',
  opportunityDescription: '',
  isApprovalRequired: false,
  // manualTimeTracking: false,
  startDate: new Date(),
  endDate: new Date(),
  startTime: '',
  endTime: '',
  user: null,
  withWho: [WITH_WHO.BABIES, WITH_WHO.OTHER, WITH_WHO.ADULTS, WITH_WHO.SENIORS, WITH_WHO.KIDS, WITH_WHO.TEENS],
  opportunityType: OPPORTUNITY_TYPES.PUBLIC,
  organisationId: '',
  address: null,
  imagePath: '',
  filePathes: [],
};

export const OPPORTUNITIES_INITIAL_VALUES_MANUAL: ManualOpportunityInitialValuesType = {
  ...MANUAL_OPPORTUNITY_INITIAL_VALUES,
  opportunityType: OPPORTUNITY_TYPES.MANUAL,
};
export const OPPORTUNITIES_INITIAL_MOK_VALUES_MANUAL: ManualOpportunityInitialValuesType = {
  ...MANUAL_OPPORTUNITY_INITIAL_MOK_VALUES,
  opportunityType: OPPORTUNITY_TYPES.MANUAL,
};

export const OPPORTUNITY_TYPE_NAME = {manual: 'Manual opportunity'};

export const INVITE_VOLUNTEER_MOK_DATA = {
  firstName: 'Jon',
  lastName: 'Doe',
  email: '',
  positionId: 3,
  roleId: 0,
  phoneNumber: undefined,
  timeSpend: undefined,
  gradeId: undefined,
};

export const INVITE_STUDENT_MOK_DATA = {
  firstName: 'Jon',
  lastName: 'Doe',
  email: '',
  positionId: 3,
  roleId: 0,
  phoneNumber: undefined,
  timeSpend: undefined,
  gradeId: undefined,
};

export const INVITE_USER_MOK_DATA = {
  firstName: 'Jon',
  lastName: 'Doe',
  email: '',
  phoneNumber: undefined,
  positionId: 2,
  roleId: 0,
};
