import {ReactNode} from 'react';
import cx from 'classnames';
//constants
import TABLE_STICKY_HEADER_CLASSNAMES from 'core/constants/TABLE_STICKY_HEADER_CLASSNAMES';
//styles
import styles from './StickyHeader.module.scss';

const cxStyle = cx.bind(styles);

type StickyHeaderPropsType = {
  children?: ReactNode;
  headerFor?: string;
};

const StickyHeader = ({children, headerFor}: StickyHeaderPropsType) => (
  <div
    className={cxStyle(styles.stickyHeader, {
      [styles.invited_users]: headerFor === TABLE_STICKY_HEADER_CLASSNAMES.invitedUsers,
    })}
  >
    <div className={styles.stickyHeader__container}>
      <div className={styles.stickyHeader__background} />
      <div className={styles.stickyHeader__content}>{children}</div>
    </div>
  </div>
);

export default StickyHeader;
