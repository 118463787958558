//types
import {DetachAccountPositionRequest} from '@joc/api-gateway';
//API
import {API} from 'core/API';

export const removeMeFromOrganization = async (organizationId: number) => {
  await API.detachMyPosition(
    DetachAccountPositionRequest.fromJS({
      positionId: 3,
      organizationId,
    })
  );
};
