import {FC} from 'react';
//material
import {ButtonBase} from '@material-ui/core';
//styles
import styles from './index.module.scss';

type ButtonOutlinedProps = {
  clickHandler: () => void;
  title: string;
  disabled?: boolean;
};

const ButtonOutlined: FC<ButtonOutlinedProps> = ({title, disabled, clickHandler}: ButtonOutlinedProps) => {
  return (
    <ButtonBase type="button" className={styles.outlined} onClick={clickHandler} disabled={disabled}>
      {title}
    </ButtonBase>
  );
};

export default ButtonOutlined;
