import {Dispatch, FC, SetStateAction, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
//formik
import {Formik} from 'formik';
//redux
import {selectorGetUserData} from 'redux/user-service/selector';
import {useDispatch, useSelector} from 'react-redux';
import {userUpdate} from 'redux/user-service/actions';
import {setError} from 'redux/error-service/action';
//form configuration
import {UserInfoFormValues, getInitalValues, validationSchema} from './formConfiguration';
//types
import {IUserResponse} from '@joc/api-gateway';
//components
import UserForm from './UserForm/UserForm';

type UserInfoFormParentProps = {
  setIsUpdateSuccess: Dispatch<SetStateAction<boolean>>;
  isOrgSuspended?: boolean;
  isDisabledEmail?: boolean;
};

const UserInfoForm: FC<UserInfoFormParentProps> = ({
  setIsUpdateSuccess,
  isOrgSuspended,
  isDisabledEmail,
}: UserInfoFormParentProps) => {
  const {t} = useTranslation('errors');

  const userData = useSelector(selectorGetUserData);

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const initialValues = useMemo(() => getInitalValues(userData), [userData]);

  const submitClickHandler = (userData: IUserResponse | null) => async (values: UserInfoFormValues) => {
    try {
      if (!userData) throw new Error(t('userDataNotFound'));
      setIsLoading(true);
      await dispatch(userUpdate(+userData.id, values));
      setIsUpdateSuccess(true);
    } catch (error) {
      dispatch(setError(error?.response?.message || error.response, error?.response?.code || error.code));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={submitClickHandler(userData)}
    >
      <UserForm isOrgSuspended={isOrgSuspended} isDisabledEmail={isDisabledEmail} isLoading={isLoading} />
    </Formik>
  );
};

export default UserInfoForm;
