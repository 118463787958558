//types
import {IUpdateVolunteerRequest} from '@joc/api-gateway';

export const transformedVolunteerValues = (values: IUpdateVolunteerRequest) => ({
  ...values,
  phoneNumber: values.phoneNumber || null,
});

export const getErrorActionCreatorDefaultArguments = (error: any) => {
  const message = error.response?.message || error.message;
  const code = error.response?.code || error.code;

  return {message, code};
};
