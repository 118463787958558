import {FC} from 'react';
import cx from 'classnames';
//images
import CanceledSvg from 'assets/image/Canceled.svg';
//styles
import styles from './ButtonActionCircle.module.scss';

type ButtonRejectCircleProps = {
  clickHandler: () => void;
  disabled?: boolean;
  title?: string;
  larger?: boolean;
};

const ButtonRejectCircle: FC<ButtonRejectCircleProps> = ({
  clickHandler,
  disabled,
  title,
  larger,
}: ButtonRejectCircleProps) => {
  return (
    <button disabled={disabled} className={!larger ? styles.main : styles.larger} onClick={clickHandler} title={title}>
      <img className={cx(styles.reject, disabled && styles.reject_disabled)} src={CanceledSvg} alt="canceled" />
    </button>
  );
};

export default ButtonRejectCircle;
