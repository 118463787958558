import {BaseSyntheticEvent, Dispatch, FC, SetStateAction} from 'react';
import {useTranslation} from 'react-i18next';
import cx from 'classnames';
//components
import Appeal from '../Appeal/DefaultRenderer';
import PopupContainer from '../PopupContainer';
import ButtonDefault from '../Buttons/ButtonsDefault';
//styles
import styles from './PopupConfirmation.module.scss';

type PopupConfirmationProps = {
  confirmClickHandler: (() => void) | ((e: BaseSyntheticEvent) => void);
  cancelClickHandler: (() => void) | ((e: BaseSyntheticEvent) => void);
  setIsShowPopup: Dispatch<SetStateAction<boolean>>;
  isDisableContentMarginTop?: boolean;
  parentClassName?: string;
  text?: string;
};

const PopupConfirmation: FC<PopupConfirmationProps> = ({
  confirmClickHandler,
  cancelClickHandler,
  setIsShowPopup,
  isDisableContentMarginTop,
  parentClassName,
  text,
}) => {
  const {t} = useTranslation(['messages', 'buttons']);

  return (
    <div className={parentClassName}>
      <PopupContainer
        setIsShowPopup={setIsShowPopup}
        isDisablePadding
        isDisableCloseButton
        isDisableContentMarginTop={isDisableContentMarginTop}
      >
        <Appeal styleThink>
          <h1 className={cx('title', styles.title)}>{t('messages:areYouSure')}</h1>
          <p>{text || t('messages:loseAllUnsavedData')}</p>
          <ButtonDefault
            parentClassName={styles.button_confirm}
            classList={['primary', 'lg']}
            title={t('buttons:button.confirm')}
            clickHandler={confirmClickHandler}
          />
          <ButtonDefault
            classList={['secondary', 'lg']}
            title={t('buttons:button.back')}
            clickHandler={cancelClickHandler}
          />
        </Appeal>
      </PopupContainer>
    </div>
  );
};

export default PopupConfirmation;
