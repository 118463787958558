import {FC, useEffect, useState} from 'react';
import cx from 'classnames';
import {useHistory} from 'react-router';
//translation
import {useTranslation} from 'react-i18next';
//urls
import {urls} from 'core/appUrls';
//constants
import {ACCESS_DENIED_ERROR_TIME} from 'core/constants/ACCESS_DENIED_ERROR_TIME';
//components
import Appeal from 'shared/components/Appeal/DefaultRenderer';
//styles
import styles from './AccessDenied.module.scss';

const orgId = localStorage.getItem('organisationId');
const redirectedUrl = orgId ? urls.onboardingChooseUserType : urls.newMainFeed;

const AccessDenied: FC = () => {
  const history = useHistory();

  const [redirectTimeCount, setRedirectTimeCount] = useState(ACCESS_DENIED_ERROR_TIME / 1000);

  const {t} = useTranslation(['errors', 'messages']);

  useEffect(() => {
    if (redirectTimeCount > 0) setTimeout(() => setRedirectTimeCount(redirectTimeCount - 1), 1000);
    if (redirectTimeCount === 0) setTimeout(() => history.push(redirectedUrl), 1000);

    return () => {
      localStorage.clear();
    };
  }, [redirectTimeCount]);

  return (
    // <OnboardingContentContainer disableOnboardingLogoLink>
    <Appeal styleThink isHeightDisable>
      <div className={cx('title', styles.title)}>{t('errors:sorry')}</div>
      <div>
        {t('messages:accessExpiredRedirectIn')} {redirectTimeCount} {t('messages:seconds')}
      </div>
    </Appeal>
    // </OnboardingContentContainer>
  );
};

export default AccessDenied;
