import {FC, Fragment, useState} from 'react';
import {useHistory} from 'react-router';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import cx from 'classnames';
//redux
import {Store} from 'redux/root';
import {resetError, setError} from 'redux/error-service/action';
import {resetCreateOrgInfo} from 'redux/organization-service/actions';
//API
import {API} from 'core/API';
//urls
import {urls} from 'core/appUrls';
//types
import {CreateNewOrganisationRequest, ICreateNewOrganisationRequest} from '@joc/api-gateway';
//components
import InviteColleaguesSignupForm from 'components/Organization/Forms/InviteColleaguesSignupForm';
import ArrowBackLink from 'shared/components/ArrowBackLink';
import ResponseFailure from 'shared/components/ResponseFailure';
import SignUpContentContainer from '../SignUpContentContainer';
//styles
import styles from './InviteColleaguesSignup.module.scss';

const InviteColleaguesSignup: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const error = useSelector((store: Store) => store.errorRedux.error);
  const orgData = useSelector((store: Store) => store.organizationRedux.createOrganizationInfo);

  const {t} = useTranslation('signUpOrg');

  const handlerResetError = () => {
    dispatch(resetError());
  };

  const requestCreateOrg = async (orgData: ICreateNewOrganisationRequest) => {
    setIsLoading(true);
    try {
      await API.createNewOrganisation(CreateNewOrganisationRequest.fromJS(orgData));
      dispatch(resetCreateOrgInfo());
      setIsLoading(false);
      history.push(urls.onboardingSignupCongrat);
    } catch (error) {
      setIsLoading(false);
      dispatch(setError(error?.response?.message || error.message, error.code));
      resetCreateOrgInfo();
    }
  };

  if (error.state)
    return (
      <ResponseFailure
        message={error.message}
        buttonClickHandler={() => {
          handlerResetError();
          history.push(urls.onboardingOrgSignupAboutYou);
        }}
      />
    );

  return (
    <Fragment>
      <ArrowBackLink onboardingStyle />
      <SignUpContentContainer
        title={t('inviteColleagues.title')}
        subTitle={t('inviteColleagues.subtitle')}
        colleaguesStyle
      >
        <div className={styles.container}>
          <span className={cx('link', styles.skip)} onClick={() => requestCreateOrg(orgData)}>
            {t('inviteColleagues.skip')}
          </span>
          <div className={styles.title__wrapper}>
            <span className={cx(styles.title, 'title')}>{t('inviteColleagues.canDoLater')}</span>
            <span className={styles.sub_title}>{t('inviteColleagues.canDoLater')}</span>
          </div>
          <InviteColleaguesSignupForm requestCreateOrg={requestCreateOrg} isLoading={isLoading} />
        </div>
      </SignUpContentContainer>
    </Fragment>
  );
};

export default InviteColleaguesSignup;
