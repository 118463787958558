import * as Yup from 'yup';
import i18n from 'components/translate';
//types
import {GetLocationIdByAddressRequest, INewCreateUserRequest} from '@joc/api-gateway';
//validation
import {
  birthDatePleaseRequiredSchema,
  emailSchema,
  firstNameSchema,
  genderSchema,
  lastNameSchema,
  orgAddressMandatorySchema,
  phoneNumberSchema,
} from 'core/validation';

export type AboutYouSignUpFormValues = INewCreateUserRequest;

const validationT = i18n.getFixedT(null, 'validation');

export const getInitialValues = (): AboutYouSignUpFormValues => {
  const localStorageData = localStorage.getItem('aboutYou');

  if (localStorageData) return JSON.parse(localStorageData);
  return {
    imagePath: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    // TODO [JF-982]: change to enum positionId
    positionId: 1,
    // TODO: [JF-983] change roleId to enum
    roleId: 4,
    birthDate: undefined,
    // TODO: [JF-984] [new][be] change genderId to enum
    gender: 0,
    // TODO: [be] remove instagram link
    instagramLink: 'instagram.com',
    address: GetLocationIdByAddressRequest.fromJS({
      countryName: '',
      stateName: '',
      cityName: '',
      streetName: '',
      buildingNumber: '',
      zipCode: '',
      pobox: '',
    }),
  };
};

export const validationSchema = Yup.object().shape({
  firstName: firstNameSchema,
  lastName: lastNameSchema,
  phoneNumber: phoneNumberSchema,
  gender: genderSchema,
  address: orgAddressMandatorySchema,
  birthDate: birthDatePleaseRequiredSchema,
  email: emailSchema,
});
