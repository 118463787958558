import qs, {ParsedQs} from 'qs';
import {FullTextSearchUsersByOrg, ISearchUsersByOrgRequest, USER_ORG_ROLES} from '@joc/api-gateway';

export const parseMemberQuery = (query: string): ISearchUsersByOrgRequest | undefined => {
  const data: {fullTextSearch: FullTextSearchUsersByOrg} | ParsedQs = qs.parse(query, {
    ignoreQueryPrefix: true,
  });

  if (Object.keys(data).length === 0 || (Object.keys(data).length === 1 && data.role)) return;

  const searchData: ISearchUsersByOrgRequest = {};
  const fullTextSearch = (data as ISearchUsersByOrgRequest).fullTextSearch;

  if (data.role) {
    searchData.roleNames = [data.role as USER_ORG_ROLES];
  } else {
    searchData.roleNames = [USER_ORG_ROLES.ADMIN, USER_ORG_ROLES.COORDINATOR, USER_ORG_ROLES.MARKETER];
  }

  if (fullTextSearch) {
    searchData.fullTextSearch = fullTextSearch;
  }

  return searchData;
};
