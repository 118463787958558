import {FC} from 'react';
import {useTranslation} from 'react-i18next';
//images
import FilterSvg from 'assets/image/filter.svg';
import ClearSvg from 'assets/image/clear.svg';
import CrossSvg from 'assets/image/cross-button.svg';
//styles
import styles from './FiltersHeader.module.scss';

type FiltersHeaderParentProps = {
  changeVisibilityFilters: () => void;
  resetForm: () => void;
};

const FiltersHeader: FC<FiltersHeaderParentProps> = ({changeVisibilityFilters, resetForm}) => {
  const {t} = useTranslation('form');

  return (
    <div className={styles.main}>
      <div className={styles.wrapper}>
        <div className={styles.title} onClick={changeVisibilityFilters}>
          <img className={styles.title__icon} src={FilterSvg} alt="filter" />
          <span className={styles.title__text}>{t('filter.filtersHeader')}</span>
        </div>
        <div className={styles.clear} onClick={resetForm}>
          <img className={styles.clear__icon} src={ClearSvg} alt="clear" />
          <span className={styles.clear__text}>{t('filter.filtersClear')}</span>
        </div>
      </div>
      <div className={styles.buttonClose} onClick={changeVisibilityFilters}>
        <img className={styles.buttonClose__icon} src={CrossSvg} alt="cross" />
      </div>
    </div>
  );
};

export default FiltersHeader;
