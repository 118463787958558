import {FC, useEffect, useMemo} from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatch} from 'react-redux';
//formik
import {Formik} from 'formik';
//urls
import {urls} from 'core/appUrls';
//redux
import {setAboutOrg} from 'redux/organization-service/actions';
//form configuration
import {getInitialValues, validationSchema, AboutOrgSignupFormValues} from './formConfiguration';
//components
import OrgSignupForm from './OrgSignupForm/OrgSignupForm';

const AboutOrgSignupForm: FC = () => {
  const history = useHistory();

  const dispatch = useDispatch();

  const initialValues = useMemo(() => getInitialValues(), []);

  let timeout: NodeJS.Timeout;

  const submitClickHandler = (values: AboutOrgSignupFormValues) => {
    localStorage.setItem('aboutOrg', JSON.stringify(values));
    dispatch(setAboutOrg(values));
    timeout = setTimeout(() => history.push(urls.onboardingOrgSignupContacts), 200);
  };

  useEffect(() => () => clearTimeout(timeout), []);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={submitClickHandler}
    >
      <OrgSignupForm />
    </Formik>
  );
};

export default AboutOrgSignupForm;
