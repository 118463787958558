import {Dispatch} from 'redux';
import {API} from '../../core/API';
import {ATTACH_USER_TO_CHALLENGE, GET_OPEN_CHALLENGE, SET_ERROR} from '../actions-types';

export const getOpenChallenge =
  () =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const challengeResponse = await API.getOpenChallenge();
      dispatch({
        type: GET_OPEN_CHALLENGE,
        payload: challengeResponse,
      });
    } catch (error: any) {
      // dispatch({type: SET_ERROR, payload: {state: true, message: error?.response?.message || error.message}});
    }
  };

export const attachChallengeParticipant =
  (challengeId: number) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const volunteerId = localStorage.getItem('volunteerId');

      await API.attachParticipant(challengeId);

      dispatch({
        type: ATTACH_USER_TO_CHALLENGE,
        payload: volunteerId,
      });
    } catch (error: any) {
      dispatch({type: SET_ERROR, payload: {state: true, message: error?.response?.message || error.message}});
    }
  };
