import i18n from 'components/translate';
//types
import {SPEND_TIME_GROUP_TYPE} from '@joc/api-gateway';
import {SelectOption} from 'shared/inputs/Select/types';
import {SmartGroupSpendTimeValue} from '../../formConfiguration';

export const initialValue: SmartGroupSpendTimeValue = {
  amount: 1,
  direction: 'moreThan',
  groupType: SPEND_TIME_GROUP_TYPE.TOTAL,
};

export const directionOptions: Array<SelectOption<SmartGroupSpendTimeValue['direction']>> = [
  {title: i18n.t('common:moreThan'), value: 'moreThan'},
  {title: i18n.t('common:lessThan'), value: 'lessThan'},
];

export const groupTypeOptionsSchool = [
  {title: i18n.t('common:total'), value: SPEND_TIME_GROUP_TYPE.TOTAL},
  {title: i18n.t('common:currentYear'), value: SPEND_TIME_GROUP_TYPE.YEAR},
];

export const groupTypeOptionsOrg = [{title: i18n.t('common:total'), value: SPEND_TIME_GROUP_TYPE.TOTAL}];
