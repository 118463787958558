export const getCountFontSize = (word: string) => {
  //in cqw
  const baseSize = 8.3;

  if (word.length > 4) {
    const fontSize = (4 / word.length) * baseSize;
    return `${fontSize}cqw`;
  }

  return `${baseSize}cqw`;
};

export const getIsVolunteerAvatarValid = (avatarUrl: string) => {
  return avatarUrl && avatarUrl.startsWith('https://');
};
