import {FC, useMemo, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {Field, FieldProps, useFormikContext} from 'formik';
//form configuration
import {REPEAT_OPPO_ENDS_TYPE, RepeatOpportunityModalFormValues} from '../../../formConfiguration';
//components
import InputContainer from 'shared/components/FormInputs/InputContainer';
import InputNumber from 'shared/components/FormInputs/InputNumber';
import InputErrorHint from 'shared/components/InputErrorHint';
//styles
import styles from './AfterContent.module.scss';

const AfterContent: FC = () => {
  const {
    setFieldValue,
    values: {amount, endsType},
    errors,
  } = useFormikContext<RepeatOpportunityModalFormValues>();

  const {t} = useTranslation(['form', 'inputs']);

  const afterLabel = t('form:opportunity.after');

  const singleOccurrence = t('form:opportunity.occurrence');
  const manyOccurrences = t('form:opportunity.occurrences');

  const occurrenceInfo = useMemo(
    () => (amount !== undefined && amount <= 1 ? singleOccurrence : manyOccurrences),
    [amount]
  );

  useEffect(() => {
    if (!amount && endsType === REPEAT_OPPO_ENDS_TYPE.ON) {
      setFieldValue('amount', 1);
    }
  }, [amount, endsType]);

  return (
    <div className={styles.wrapper}>
      <label className={styles.text}>{afterLabel}</label>

      <Field name="amount">
        {({field}: FieldProps) => (
          <InputContainer parentClassnames={styles.container}>
            <InputNumber
              id="amount"
              min={1}
              limit={999}
              field={field}
              setFieldValue={setFieldValue}
              parentClassName={styles.input}
              fieldValue={amount}
              isDisabled={endsType === REPEAT_OPPO_ENDS_TYPE.ON}
              withoutPencil
            />
            {!!errors.amount && (
              <div className={styles.upload__error}>
                <InputErrorHint errorText={errors.amount} parentClassName={styles.error} />
              </div>
            )}
          </InputContainer>
        )}
      </Field>

      <span className={styles.text}>{occurrenceInfo}</span>
    </div>
  );
};

export default AfterContent;
