//types
import {SearchCityResponse, SearchStateResponse} from '@joc/api-gateway';

export const getNonEmptyAddresses = (addresses: Array<SearchCityResponse & SearchStateResponse>) =>
  addresses.filter((address) => !!address.cityName && !!address.stateName);

export const getUniqueStates = (states: Array<SearchStateResponse>): Array<SearchStateResponse> =>
  states.filter(
    (current, index, filterArray) => filterArray.findIndex((item) => current.stateId === item.stateId) === index
  );

export const getAddressesStates = (addresses: Array<SearchCityResponse>): Array<SearchStateResponse> =>
  getUniqueStates(addresses.map((item) => SearchStateResponse.fromJS(item)));
