//types
import {GeneralStatisticsResponseItem} from '@joc/api-gateway';
import {IDashboardReducer} from './types';

export const initState: IDashboardReducer = {
  main: {
    opportunitiesTotal: 0,
    opportunitiesPendingVolunteers: 0,
    opportunitiesUpcoming: 0,
    opportunitiesVacancies: 0,
    opportunitiesPast: 0,
    opportunitiesManual: 0,
    volunteersTotal: 0,
    volunteersGroups: 0,
    volunteersCountByTariff: 0,
    totalSpendTime: 0,
    claimsCount: 0,
    countOpportunitiesInSchoolYear: 0,
    hoursInSchoolYear: 0,
  },
  statistics: {
    general: {
      volunteers: GeneralStatisticsResponseItem.fromJS({
        maxValue: 0,
        currentValue: 0,
      }),
      opportunities: GeneralStatisticsResponseItem.fromJS({
        maxValue: 0,
        currentValue: 0,
      }),
      chesedHours: GeneralStatisticsResponseItem.fromJS({
        maxValue: 0,
        currentValue: 0,
      }),
      averageAge: 0,
    },
    usersSignupTraffic: [],
    usersLoginTraffic: [],
    volunteersAvgAge: [],
    studentsSpentTime: [],
    opportunitiesRegularManual: {traffic: [], total: 0},
    volunteersTop: [],
  },
};
