import firebase from 'firebase';
import {Dispatch} from 'redux';
//types
import {FIREBASE_LOGIN_ERROR_CODE, FirebaseLoginError} from 'core/functions/firebase/types';
import {
  AddNotificationTokenRequest,
  CreateExternalUserRequest,
  DeviceType,
  ICreateExternalUserRequest,
  IUpdateUserRequest,
  IUpdateVolunteerRequest,
  UpdateUserRequest,
  UpdateVolunteerRequest,
  VerifyTokenRequest,
} from '@joc/api-gateway';
//api
import {API} from 'core/API';
//redux
import {
  ADD_PUSH_NOTIFICATION_TOKEN,
  CHANGE_NAVBAR_MODE,
  GET_USER,
  REMOVE_PUSH_NOTIFICATION_TOKEN,
  RESET_USER_DATA,
  SET_ERROR,
} from 'redux/actions-types';
//constants
import {urls} from 'core/appUrls';
//helpers
import {transformedVolunteerValues} from 'redux/user-service/helpers';

export const getVolunteerByEmailAndPassword =
  (email: string, password: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const userFirebaseResponse = await firebase.auth().signInWithEmailAndPassword(email, password);
      const userFirebaseToken = await userFirebaseResponse.user?.getIdToken();
      if (userFirebaseToken) {
        const accessTokenResponse = await API.verifyToken(VerifyTokenRequest.fromJS({token: userFirebaseToken}));
        if (accessTokenResponse) {
          localStorage.setItem('accessToken', accessTokenResponse.accessToken);
          const currentUser = await API.getCurrentUser();
          localStorage.setItem('volunteerId', currentUser.volunteerId || '');

          dispatch({type: GET_USER, payload: currentUser});
        }
      }
    } catch (e) {
      throw e;
    }
  };

export const getOrganizationUserByEmailAndPassword =
  (email: string, password: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      await firebase.auth().fetchSignInMethodsForEmail(email);
      const userFirebaseResponse = await firebase.auth().signInWithEmailAndPassword(email, password);
      const userFirebaseToken = await userFirebaseResponse.user?.getIdToken();
      if (userFirebaseToken) {
        const accessTokenResponse = await API.verifyTokenCompany(VerifyTokenRequest.fromJS({token: userFirebaseToken}));
        if (accessTokenResponse) {
          localStorage.setItem('accessToken', accessTokenResponse.accessToken);
          const currentUser = await API.getCurrentUser();
          dispatch({type: GET_USER, payload: currentUser});
        }
      }
    } catch (e) {
      throw e;
    }
  };

export const getUserBySocialProvider = (socialProvider: firebase.auth.AuthProvider) => async (dispatch: Dispatch) => {
  try {
    const {user} = await firebase.auth().signInWithPopup(socialProvider);

    if (!user?.email) {
      const error: FirebaseLoginError<FIREBASE_LOGIN_ERROR_CODE> = {
        code: FIREBASE_LOGIN_ERROR_CODE.USER_NOT_FOUND,
      };

      await user?.delete();
      throw error;
    }

    const token = await user.getIdToken();
    const {accessToken} = await API.verifyToken(VerifyTokenRequest.fromJS({token}));
    localStorage.setItem('accessToken', accessToken);

    const currentUser = await API.getCurrentUser();
    localStorage.setItem('volunteerId', currentUser.volunteerId!);

    dispatch({type: GET_USER, payload: currentUser});
  } catch (error) {
    if (error.code !== FIREBASE_LOGIN_ERROR_CODE.ACCOUNT_EXISTS_WITH_DIFFERENT_CREDENTIAL) {
      throw error;
    } else {
      const providersResponse = await firebase.auth().fetchSignInMethodsForEmail(error.email);
      const message = `An account already exists with the same email address but different sign-in credentials. Please, sign in with ${providersResponse.join(
        ', '
      )} ${providersResponse.length > 1 ? 'accounts' : 'account'}`;
      const customError = {message, code: 502};
      throw customError;
    }
  }
};

export const getCurrentUser = () => async (dispatch: Dispatch) => {
  try {
    const currentUser = await API.getCurrentUser();
    dispatch({type: GET_USER, payload: currentUser});
  } catch (error) {
    if (error?.response?.code === 401 || error?.response?.code === 400) {
      window.location.href = `${window.location.origin}${urls.accessDenied}`;
    }
    dispatch({type: SET_ERROR, payload: {state: true, message: error?.response?.message || error.message}});
  }
};

export const userCreate =
  (userFormData: ICreateExternalUserRequest) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      await API.create(CreateExternalUserRequest.fromJS(userFormData));
      await getOrganizationUserByEmailAndPassword(userFormData.email, userFormData.password);
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        payload: {
          state: true,
          message: error?.response?.message || error.messagee,
          code: error?.response?.code || error.code,
        },
      });
    }
  };

export const userUpdate = (id: number, values: IUpdateUserRequest) => async (dispatch: Dispatch) => {
  try {
    await API.updateUser(id, UpdateUserRequest.fromJS(values));
    const updatedUser = await API.getCurrentUser();
    dispatch({type: GET_USER, payload: updatedUser});
  } catch (error) {
    throw error;
  }
};

export const userVolunteerUpdate = (values: IUpdateVolunteerRequest) => async (dispatch: Dispatch) => {
  try {
    await API.editProfile(transformedVolunteerValues(values) as UpdateVolunteerRequest);
    const updatedUser = await API.getCurrentUser();
    dispatch({type: GET_USER, payload: updatedUser});
  } catch (error) {
    throw error;
  }
};

export const userResetData =
  () =>
  (dispatch: Dispatch): void => {
    dispatch({
      type: RESET_USER_DATA,
    });
  };

export const changeNavbarMode =
  () =>
  (dispatch: Dispatch): void => {
    dispatch({
      type: CHANGE_NAVBAR_MODE,
    });
  };

export const setUserNotificationToken =
  (pushToken: string, type: DeviceType = DeviceType.Web) =>
  async (dispatch: Dispatch) => {
    try {
      await API.addNotificationToken(
        AddNotificationTokenRequest.fromJS({
          token: pushToken,
          deviceType: type,
        })
      );
      const updatedUser = await API.getCurrentUser();
      dispatch({type: ADD_PUSH_NOTIFICATION_TOKEN, payload: updatedUser});
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error({error});
    }
  };

export const deleteUserNotificationToken =
  (type: DeviceType = DeviceType.Web) =>
  async (dispatch: Dispatch) => {
    try {
      await API.deleteNotificationToken(
        AddNotificationTokenRequest.fromJS({
          deviceType: type,
        })
      );
      // const updatedUser = await API.getCurrentUser();
      dispatch({type: REMOVE_PUSH_NOTIFICATION_TOKEN});
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error({error});
    }
  };
