import i18n from 'components/translate';
import {userGradeKey} from 'core/functions/diplomaNames';

export const grade = (userGrade: string | undefined): string =>
  i18n.t(userGrade ? `diploma:userGrade.${userGradeKey(userGrade)}` : 'sections:volunteerInfo.rookie');

export const pointCount = (points: number): string => `${i18n.t('sections:volunteerInfo.points')} ${points || 0}`;

export const timeSpent = (userSpendTime: number | undefined, spendTime: number | undefined): string =>
  `${i18n.t('sections:volunteerInfo.completedHours')} ${
    Math.floor(spendTime! / 3600) || Math.floor(userSpendTime! / 3600) || 0
  } h ${Math.floor((spendTime! % 3600) / 60) || Math.floor((userSpendTime! % 3600) / 60) || 0} m`;
