import {CurrentTab} from './helpers';

export interface InvitePopupState {
  currentTab: CurrentTab;
  preferredTab: CurrentTab | undefined;
  isLoading: boolean;
  isSuccess: boolean;
  isEmpty: boolean;
  customError?: string;
}

export const invitePopupInitial: InvitePopupState = {
  currentTab: CurrentTab.form,
  preferredTab: undefined,
  isLoading: false,
  isSuccess: false,
  isEmpty: false,
  customError: '',
};

export enum InvitePopupAction {
  SET_IS_LOADING = 'setIsLoading',
  SET_IS_SUCCESS = 'setIsSuccess',
  SET_CUSTOM_ERROR = 'setCustomError',
  SET_IS_EMPTY = 'setIsEmpty',
  SET_CURRENT_TAB = 'setCurrentTab',
  SET_PREFERRED_TAB = 'setPreferredTab',
}

interface SetIsLoading {
  type: InvitePopupAction.SET_IS_LOADING;
  payload: boolean;
}

interface SetIsSuccess {
  type: InvitePopupAction.SET_IS_SUCCESS;
  payload: boolean;
}

interface SetCustomError {
  type: InvitePopupAction.SET_CUSTOM_ERROR;
  payload?: string;
}

interface SetIsEmpty {
  type: InvitePopupAction.SET_IS_EMPTY;
  payload: boolean;
}

interface SetCurrentTab {
  type: InvitePopupAction.SET_CURRENT_TAB;
  payload: CurrentTab;
}

interface SetPreferredTab {
  type: InvitePopupAction.SET_PREFERRED_TAB;
  payload?: CurrentTab;
}

type InvitePopupActionType =
  | SetIsLoading
  | SetIsSuccess
  | SetIsEmpty
  | SetCurrentTab
  | SetPreferredTab
  | SetCustomError;

export function invitePopupReducer(state: InvitePopupState, action: InvitePopupActionType) {
  switch (action.type) {
    case InvitePopupAction.SET_CURRENT_TAB: {
      return {...state, currentTab: action.payload};
    }
    case InvitePopupAction.SET_PREFERRED_TAB: {
      return {...state, preferredTab: action.payload};
    }
    case InvitePopupAction.SET_CUSTOM_ERROR: {
      return {...state, customError: action.payload};
    }
    case InvitePopupAction.SET_IS_LOADING: {
      return {...state, isLoading: action.payload};
    }
    case InvitePopupAction.SET_IS_SUCCESS: {
      return {...state, isSuccess: action.payload};
    }
    case InvitePopupAction.SET_IS_EMPTY: {
      return {...state, isEmpty: action.payload};
    }
    default:
      return state;
  }
}
