import {Dispatch, FC, RefObject, SetStateAction, useCallback, useEffect, useMemo, useState} from 'react';
import {useHistory, useLocation} from 'react-router';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import qs from 'qs';
import {useReactToPrint} from 'react-to-print';
//API
import {API} from 'core/API';
//redux
import {setError} from 'redux/error-service/action';
import {selectorGetSmartGroupsAll} from 'redux/smartGroups-service/selector';
import {selectorGetDashboardTariffVolunteers, selectorGetDashboardVolunteers} from 'redux/dashboard-service/selector';
//urls
import {urls} from 'core/appUrls';
//functions
import {parseQueryStringVolunteersSearch} from 'core/functions';
//constants
import {INITIAL_PAGINATION} from 'core/constants';
//types
import {
  BasicVolunteerResponse,
  ISearchVolunteersByGroupRequest,
  ISearchVolunteersRequest,
  ORGANISATION_ACTIVE_STATUS,
  PaginationRequest,
  SearchVolunteerResponse,
  SearchVolunteersByGroupRequest,
  VOLUNTEER,
  VolunteerUserInfo,
} from '@joc/api-gateway';
import {
  selectorGetOrgActiveStatus,
  selectorGetOrgId,
  selectorGetSchoolId,
  selectorPayGateStatus,
} from 'redux/organization-service/selector';
import {selectorGetUserAddress, selectorGetUserRoles} from 'redux/user-service/selector';
//components
import BroadcastInputTextPopup from 'components/Organization/Volunteers/Table/Actions/BroadcastInputTextPopup';
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
import ButtonImage from 'shared/components/Buttons/ButtonImage';
import PopupContainer from 'shared/components/PopupContainer';
import PopupCreate from 'components/Organization/Opportunities/OpportunityPopups/PopupCreate';
import InvitePopup from 'components/Organization/Volunteers/VolunteersPopups/InvitePopup';
import ExportPopup from 'components/Organization/Volunteers/VolunteersPopups/ExportPopup';
//styles
import styles from './Actions.module.scss';
import {Tooltip} from '@material-ui/core';

type ActionsProps = {
  printRef: RefObject<HTMLDivElement>;
  setIsPrintableTable: Dispatch<SetStateAction<boolean>>;
  isLoading: boolean;
  setIsEditingSmartGroup: (isEdit: boolean) => void;
};

const Actions: FC<ActionsProps> = ({setIsPrintableTable, printRef, setIsEditingSmartGroup, isLoading}) => {
  const history = useHistory();
  const location = useLocation();

  const [isShowMessageInputPopup, setIsShowMessageInputPopup] = useState(false);
  const [isShowInvitePopup, setIsShowInvitePopup] = useState(false);
  const [isShowExportPopup, setIsShowExportPopup] = useState(false);
  const [isShowCreateOpportunityPopup, setIsShowCreateOpportunityPopup] = useState(false);
  const [volunteersAll, setVolunteersAll] = useState<Array<BasicVolunteerResponse | VolunteerUserInfo>>();

  const volunteersTotal = useSelector(selectorGetDashboardVolunteers);
  const userRoles = useSelector(selectorGetUserRoles);
  const orgId = useSelector(selectorGetOrgId);
  const schoolId = useSelector(selectorGetSchoolId);
  const orgStatus = useSelector(selectorGetOrgActiveStatus);
  const userAddress = useSelector(selectorGetUserAddress);
  const allSmartGroups = useSelector(selectorGetSmartGroupsAll);

  // Temporary solution with disabling pay gate
  const volunteersCountByTariff = useSelector(selectorGetDashboardTariffVolunteers);
  const payGateStatus = useSelector(selectorPayGateStatus);

  const dispatch = useDispatch();

  const {t} = useTranslation(['errors', 'common', 'messages', 'tooltips', 'sections', 'volunteers']);

  const searchBody: ISearchVolunteersRequest | ISearchVolunteersByGroupRequest = useMemo(
    () => parseQueryStringVolunteersSearch(location.search, userAddress),
    [location.search]
  );

  const GroupNameGenerator = (): string => {
    const groupname = allSmartGroups.find((group) => +group.id === +(searchBody as any)?.groupId)?.groupName;
    return groupname || t('common:titles.all');
  };

  const getVolunteersFullListRequest = useCallback(async () => {
    const groupId = (searchBody as ISearchVolunteersByGroupRequest)?.groupId;

    try {
      if (!orgId) {
        throw new Error(`${t('errors:sorry')} ${t('errors:cantFindOrgId')}`);
      }

      let volunteersAllResponse: SearchVolunteerResponse | Array<VolunteerUserInfo> = [];

      if (groupId) {
        volunteersAllResponse = await API.getAllVolunteersByGroup(
          SearchVolunteersByGroupRequest.fromJS({
            ...searchBody,
            organizationId: +orgId,
          })
        );
        setVolunteersAll(volunteersAllResponse.records);
      } else {
        volunteersAllResponse = await API.getAllVolunteersByOrganization(undefined);
        setVolunteersAll(volunteersAllResponse);
      }
    } catch (error) {
      dispatch(setError(error?.response?.message || error.message, error.code));
    }
  }, [searchBody]);

  useEffect(() => {
    getVolunteersFullListRequest();
  }, [getVolunteersFullListRequest]);

  useEffect(() => {
    return () => localStorage.removeItem('opportunity');
  }, []);

  const handleCreateChatClick = (title: string | undefined, type: string = 'all', message?: string) => {
    const volunteerArray = volunteersAll
      ?.map((volunteer) =>
        volunteer?.chatId
          ? {chatId: volunteer.chatId, chatName: `${volunteer?.firstName} ${volunteer?.lastName}`}
          : {chatId: '', chatName: ''}
      )
      .filter((i) => i.chatId.length);
    if (!volunteerArray?.length) {
      throw Error(t('errors:emptyLength'));
    } else {
      const queryString = message
        ? qs.stringify({members: [...volunteerArray], type, message, title})
        : qs.stringify({members: [...volunteerArray], type, title});
      history.push(`${urls.orgInbox}?${queryString}`);
    }
  };

  const printClickHandler = useReactToPrint({
    onBeforeGetContent: () => setIsPrintableTable(true),
    onAfterPrint: () => setIsPrintableTable(false),
    content: () => printRef.current,
  });
  const inviteClickHandler = () => setIsShowInvitePopup(true);
  const editGroupClickHandler = () => setIsEditingSmartGroup(true);
  const createOppoClickHandler = (isCreate: boolean) => () => setIsShowCreateOpportunityPopup(isCreate);
  const showMessageClickHandler = (isShow: boolean) => () => setIsShowMessageInputPopup(isShow);
  const showExportPopupClickHandler = (isShow: boolean) => () => setIsShowExportPopup(isShow);
  const groupchatClickHandler = () => handleCreateChatClick(GroupNameGenerator());

  const isSuspended = orgStatus?.status === ORGANISATION_ACTIVE_STATUS.SUSPENDED;
  const isCoordinator = userRoles?.some((role) => role.roleName === VOLUNTEER.COORDINATOR);
  const isAdmin = userRoles?.some((role) => role.roleName === VOLUNTEER.ADMIN);
  const groupId = (searchBody as ISearchVolunteersByGroupRequest)?.groupId;
  const suspendedMessage = t('messages:organizationSuspended');

  return (
    <div className={styles.wrapper}>
      {(isAdmin || isCoordinator) && (
        <>
          {groupId && (
            <>
              <ButtonImage
                clickHandler={editGroupClickHandler}
                disabled={isSuspended}
                viewStyle="edit"
                title={!isSuspended ? t(`tooltips:editSmartGroup`) : suspendedMessage}
              />

              <ButtonImage
                clickHandler={createOppoClickHandler(true)}
                viewStyle="heart"
                disabled={(!volunteersAll?.length && !isLoading) || isSuspended}
                title={!isSuspended ? t('volunteers:createOppoFrom') : suspendedMessage}
              />
            </>
          )}
          <ButtonImage
            clickHandler={printClickHandler}
            parentClassName={styles.button_30}
            disabled={!volunteersAll?.length && !isLoading}
            viewStyle="print"
            title={t('tooltips:printList')}
          />
          <div>
            <ButtonImage
              clickHandler={showExportPopupClickHandler(true)}
              disabled={!volunteersAll?.length && !isLoading}
              viewStyle="download"
              title={t('tooltips:exportList')}
            />
            {isShowExportPopup && (
              <ExportPopup
                setIsShowPopup={showExportPopupClickHandler(false)}
                searchBody={searchBody}
                groupname={GroupNameGenerator()}
              />
            )}
          </div>
        </>
      )}
      <div>
        <ButtonDefault
          title={t('sections:table.broadcast')}
          classList={['secondary', 'sm']}
          clickHandler={showMessageClickHandler(true)}
          disabled={!volunteersAll?.length || isSuspended}
        />
        {isShowMessageInputPopup && (
          <BroadcastInputTextPopup closePopupHandler={showMessageClickHandler(false)} groupId={groupId} />
        )}
      </div>

      <Tooltip
        title={
          (volunteersAll?.length || 0) >= 100
            ? 'Please, use Broadcast button for Smart Groups with more than 99 Users'
            : ''
        }
        placement="bottom"
        classes={{tooltip: styles.tooltip, arrow: styles.arrow}}
        arrow
      >
        <div>
          <ButtonDefault
            title={t('sections:table.groupChat')}
            classList={['primary', 'sm']}
            clickHandler={groupchatClickHandler}
            disabled={!volunteersAll?.length || isSuspended || volunteersAll.length >= 100}
          />
        </div>
      </Tooltip>

      {!groupId && (
        <ButtonImage
          clickHandler={inviteClickHandler}
          parentClassName={styles.invite}
          disabled={isSuspended}
          viewStyle="add_volunteer"
          title={!isSuspended ? t(`tooltips:${!!schoolId ? 'inviteStudent' : 'inviteVolunteer'}`) : suspendedMessage}
        />
      )}
      {payGateStatus ? <div className={styles.total}>{`${volunteersTotal} / ${volunteersCountByTariff}`}</div> : <></>}

      {isShowInvitePopup && <InvitePopup setIsShowPopup={setIsShowInvitePopup} />}
      {isShowCreateOpportunityPopup && (
        <PopupContainer setIsShowPopup={setIsShowCreateOpportunityPopup}>
          <PopupCreate setIsShowPopup={createOppoClickHandler(false)} isSmartGroupMode />
        </PopupContainer>
      )}
    </div>
  );
};

export default Actions;
