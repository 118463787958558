import {useState} from 'react';
import {ISearchOpportunityResponse} from '@joc/api-gateway';
import {TableClass} from '../components/Organization/Volunteers/VolunteersPopups/VolunteerPastOpportunitiesPopup/enum';

export const useScrollUpArrow = () => {
  const [isScrollTopVisible, setIsScrollTopVisible] = useState(false);
  const [arrowStyle, setArrowStyle] = useState({});

  const handleScrollEvent = (smartGroupWrapperRef: Element, records: any[] | undefined) => {
    const containerHeight = (smartGroupWrapperRef?.scrollHeight as number) - 900;
    const scrollTop = smartGroupWrapperRef?.scrollTop as number;
    const totalRowCount = records?.length;
    if (!totalRowCount) return;
    const visibleRowCount = Math.floor(containerHeight / totalRowCount);
    const visibleAmountAfter = 10;

    setIsScrollTopVisible(totalRowCount > visibleAmountAfter && scrollTop > visibleRowCount * visibleAmountAfter);
  };

  const handleSetPositionArrow = (smartGroupWrapperRef: Element) => {
    const cord = smartGroupWrapperRef?.getBoundingClientRect();
    setArrowStyle({left: cord && `${cord.right - 50}px`, top: cord && `${cord.bottom - 40}px`});
  };

  const addListener = (
    smartGroupWrapperRef: Element | null,
    volunteerPastOpportunities: ISearchOpportunityResponse | undefined
  ) => {
    if (smartGroupWrapperRef) {
      handleSetPositionArrow(smartGroupWrapperRef);
      smartGroupWrapperRef.addEventListener('scroll', () =>
        handleScrollEvent(smartGroupWrapperRef, volunteerPastOpportunities?.records)
      );

      window.addEventListener('resize', () => handleSetPositionArrow(smartGroupWrapperRef));
    }
  };

  const removeListener = (
    smartGroupWrapperRef: Element | null,
    volunteerPastOpportunities: ISearchOpportunityResponse | undefined
  ) => {
    if (smartGroupWrapperRef) {
      smartGroupWrapperRef.removeEventListener('scroll', () =>
        handleScrollEvent(smartGroupWrapperRef, volunteerPastOpportunities?.records)
      );

      window.addEventListener('resize', () => handleSetPositionArrow(smartGroupWrapperRef));
    }
  };

  const scrollToTop = () => {
    const smartGroupWrapperRef = document.querySelector(`.${TableClass.ScrollContainer}`);
    if (smartGroupWrapperRef) smartGroupWrapperRef.scrollTo({top: 0, behavior: 'smooth'});
  };

  return {isScrollTopVisible, arrowStyle, addListener, removeListener, scrollToTop};
};
