//images
import CrossSvg from 'assets/image/cross-button.svg';
import CrossBlackSvg from 'assets/image/cross-button-black.svg';
//types
import {BUTTON_CROSS_COLOR} from 'core/types';

export const getButtonCrossImage = (color: BUTTON_CROSS_COLOR): string => {
  switch (color) {
    case BUTTON_CROSS_COLOR.BLUE:
      return CrossSvg;

    case BUTTON_CROSS_COLOR.BLACK:
      return CrossBlackSvg;

    default:
      return CrossSvg;
  }
};
