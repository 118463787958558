import * as Yup from 'yup';
//validation
import {emailSchema} from 'core/validation';
//types
import {IValidateUserEmailRequest} from '@joc/api-gateway';

export type SignUpVolunteerFormValues = IValidateUserEmailRequest;

export const initialValues: SignUpVolunteerFormValues = {
  email: '',
};

export const validationSchema = Yup.object().shape({
  email: emailSchema,
});
