import {Dispatch, FC, FocusEvent, SetStateAction, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import cx from 'classnames';
//formik
import {Field, Form, useFormikContext} from 'formik';
//redux
import {Store} from 'redux/root';
//fubctions
import {emailBlurSignUpMainFormV} from 'core/functions/emailBlur';
//components
import Input from 'shared/inputs/Input';
import Loader from 'shared/components/Loader';
import InputPhone from 'shared/components/FormInputs/InputPhone';
import GradeSelect from 'shared/components/SelectDropdown/GradeSelect';
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
import PopupConfirmation from 'shared/components/PopupConfirmation';
import {TimeLimit} from 'components/TimeLimit';
//icons
import {ReactComponent as InfoIcon} from 'assets/icons/info.svg';
//styles
import styles from './InviteForm.module.scss';
import {UserType} from '../helpers';

type InviteFormProps = {
  isLoading: boolean;
  isShowConfirmationChange: boolean;
  setIsShowConfirmationChange: Dispatch<SetStateAction<boolean>>;
  setIsEmpty: (isEmpty: boolean) => void;
  confirmationSubmitClickHandler: () => void;
  isShowPassword?: boolean;
  checkEmailExisting?: boolean;
};

const InviteForm: FC<InviteFormProps> = ({
  isLoading,
  isShowConfirmationChange,
  setIsShowConfirmationChange,
  setIsEmpty,
  confirmationSubmitClickHandler,
  isShowPassword,
  checkEmailExisting,
}) => {
  const schoolId = useSelector((store: Store) => Number(store.organizationRedux.organizationInfo?.schoolId));
  const [errorEmail, setEmailError] = useState('');

  const onBlurEmailHandler = (email: string) =>
    checkEmailExisting && emailBlurSignUpMainFormV(email, setEmailError, UserType.Volonteer);

  const {touched, getFieldMeta, setFieldError, setFieldValue, isValidating} = useFormikContext();

  useEffect(() => {
    if (errorEmail) {
      setFieldError('email', errorEmail);
    }
  }, [isValidating, errorEmail]);

  useEffect(() => {
    setFieldValue('emailIsExists', !!errorEmail);
  }, [errorEmail]);

  useEffect(() => {
    setIsEmpty(!Object.keys(touched).length);
  }, [touched]);

  const {t} = useTranslation(['form', 'inputs', 'buttons']);

  const confirmClickHandler = (): void => {
    confirmationSubmitClickHandler();
    setIsShowConfirmationChange(false);
  };

  return (
    <Form className={styles.form}>
      <>
        <Field
          name="firstName"
          label={`${t('form:user.firstName')} *`}
          placeholder={t('inputs:placeholders.firstName')}
          component={Input}
        />
        <Field
          name="lastName"
          label={`${t('form:user.lastName')} *`}
          placeholder={t('inputs:placeholders.lastName')}
          component={Input}
        />
        <div className={cx(styles.email, styles.mb_16)}>
          <Field
            name="email"
            label={`${t('form:emailAddress')} *`}
            placeholder="example@email.com"
            onBlur={({target}: FocusEvent<HTMLInputElement>) => onBlurEmailHandler(target?.value)}
            parentClassName={styles.mb_0}
            onChange={(event: any) => {
              setFieldValue('email', event.target.value.trim());
            }}
            component={Input}
          />
        </div>
        <Field name="phoneNumber" label={t('form:phoneNumber')} component={InputPhone} />
        <div className={styles.multiple_fields_wrapper}>
          {!!schoolId && (
            <div className={styles.half_field_wrapper}>
              <Field name="gradeId" label={`${t('form:schoolGrade')} *`} component={GradeSelect} />
            </div>
          )}
          <div className={cx(styles.half_field_wrapper, styles.mb_16)}>
            <Field
              name="timeSpend"
              label={t('form:previousHours')}
              placeholder={t('inputs:placeholders.previousHours')}
              parentClassName={!schoolId && styles.mb_4}
              component={TimeLimit}
            />
            {(schoolId && getFieldMeta('timeSpend').touched && (
              <div
                className={cx(
                  styles.half_field_wrapper_warning,
                  schoolId && styles.half_field_wrapper_warning_absolute
                )}
              >
                <InfoIcon />
                <span className={styles.half_field_wrapper_warning_message}>{t('inputs:warnings.previousHours')}</span>
              </div>
            )) || <></>}
          </div>
        </div>
        <Field
          name="studentId"
          label={t('inputs:placeholders.idNumber')}
          placeholder={t('inputs:placeholders.idNumber')}
          component={Input}
        />

        {isShowPassword && (
          <Field
            name="password"
            type="password"
            autocomplete="new-password"
            label={`${t('form:user.password')} *`}
            placeholder={t('inputs:placeholders.password')}
            component={Input}
          />
        )}
        {!isLoading ? (
          <div className={styles.mt_submit_button}>
            <ButtonDefault submitType classList={['primary', 'lg']} title={t('buttons:button.confirm')} />
          </div>
        ) : (
          <Loader />
        )}
        {isShowConfirmationChange && (
          <PopupConfirmation
            confirmClickHandler={confirmClickHandler}
            cancelClickHandler={() => setIsShowConfirmationChange(false)}
            setIsShowPopup={setIsShowConfirmationChange}
          />
        )}
      </>
    </Form>
  );
};

export default InviteForm;
