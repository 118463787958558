import {FC} from 'react';
import {DropzoneRootProps} from 'react-dropzone';
//components
import InputErrorHint from 'shared/components/InputErrorHint';
//styles
import parentStyles from '../index.module.scss';
import styles from './InputPreviewImage.module.scss';

type InputPreviewImagePropsType = {
  value: string;
  getRootProps: () => DropzoneRootProps;
  isDragReject: boolean;
  fieldError?: string;
};

const InputPreviewImage: FC<InputPreviewImagePropsType> = ({
  value,
  getRootProps,
  isDragReject,
  fieldError,
}: InputPreviewImagePropsType) => {
  return (
    <div className={styles.upload__wrapper}>
      <img
        src={`${process.env.REACT_APP_S3_API}/${value}`}
        className={styles.upload_preview}
        {...getRootProps()}
        alt="preview"
      />
      {isDragReject ? (
        <div className={parentStyles.upload__error}>
          <InputErrorHint errorText="You should drop one file, or file type not accepted." />
        </div>
      ) : (
        fieldError && (
          <div className={parentStyles.upload__error}>
            <InputErrorHint errorText={fieldError} />
          </div>
        )
      )}
    </div>
  );
};

export default InputPreviewImage;
