import {FC, useMemo, useState} from 'react';
import MarketingListVideo from '../../../../components/Organization/Marketing/MarketingListVideo';
import PopupContainer from '../../../../shared/components/PopupContainer';
import PopupUploadVideo from '../../../../components/Organization/Marketing/PopupUploadVideo';
import ButtonDefault from '../../../../shared/components/Buttons/ButtonsDefault';
import {ORGANISATION_ACTIVE_STATUS} from '@joc/api-gateway';
import {useSelector} from 'react-redux';
import {selectorGetOrgActiveStatus} from '../../../../redux/organization-service/selector';
import {useTranslation} from 'react-i18next';
import styles from '../Marketing.module.scss';

const Media: FC = () => {
  const {t} = useTranslation(['marketing', 'popup']);

  const [isShowPopup, setIsShowPopup] = useState<boolean>(false);
  const orgStatus = useSelector(selectorGetOrgActiveStatus);

  const isSuspended = useMemo(() => orgStatus?.status === ORGANISATION_ACTIVE_STATUS.SUSPENDED, [orgStatus]);

  return (
    <div>
      <div className={styles.block_title_action_button}>
        <ButtonDefault
          title={t('marketing:uploadMedia')}
          classList={['primary', 'extra_md']}
          clickHandler={() => setIsShowPopup(true)}
          disabled={isSuspended}
        />
      </div>
      <MarketingListVideo setIsShowPopup={setIsShowPopup} />
      {isShowPopup && (
        <PopupContainer setIsShowPopup={setIsShowPopup}>
          <div className={styles.popupTitle}>{t('popup:marketing.uploadStatus')}</div>
          <PopupUploadVideo />
        </PopupContainer>
      )}
    </div>
  );
};

export default Media;
