import {FC, Fragment, useMemo, useState, Dispatch, SetStateAction} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {useTranslation} from 'react-i18next';
//redux
import {Store} from 'redux/root';
import {selectorGetOrgId} from 'redux/organization-service/selector';
import {createSmartGroup} from 'redux/smartGroups-service/actions';
import {resetError, setError} from 'redux/error-service/action';
import {selectorGetError} from 'redux/error-service/selector';
//components
import InputText from 'shared/components/Inputs/InputText';
import Appeal from 'shared/components/Appeal/DefaultRenderer';
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
import Loader from 'shared/components/Loader';
import ResponseFailure from 'shared/components/ResponseFailure';
import CongratsPopup from 'components/Organization/CongratsPopup';
//styles
import styles from './CreateVolunteersSmartGroup.module.scss';

type CreateVolunteersSmartGroupParentProps = {
  smartGroupVolunteers: Array<number>;
  closeHandle: () => void;
  setIsShowPopup: Dispatch<SetStateAction<boolean>>;
};

const CreateVolunteersSmartGroup: FC<CreateVolunteersSmartGroupProps> = ({
  smartGroupVolunteers,
  closeHandle,
  orgId,
  createSmartGroup,
  setIsShowPopup,
  setError,
  resetError,
  error,
}) => {
  const [isLoading, setisLoading] = useState(false);
  const [isCreateSmartGroupSuccess, setIsCreateSmartGroupSuccess] = useState(false);
  const [smartGroupName, setSmartGroupName] = useState('');

  const newSmartGroup = useMemo(() => {
    if (smartGroupName.length && smartGroupVolunteers.length && orgId)
      return {groupName: smartGroupName, volunteers: smartGroupVolunteers, organizationId: orgId};
    return null;
  }, [smartGroupVolunteers, smartGroupName, orgId]);

  const {t} = useTranslation(['popup', 'messages', 'errors', 'buttons', 'inputs']);

  const confirmClickHandler = () => {
    try {
      if (!newSmartGroup) throw new Error(t('errors:checkSelectedVol'));
      setisLoading(true);
      createSmartGroup(newSmartGroup);
      setisLoading(false);
      setIsCreateSmartGroupSuccess(true);
    } catch (error) {
      setisLoading(false);
      setError(error?.response?.message || error.message, error?.response?.code || error.code);
      console.error(error);
    }
  };

  if (error.state)
    return (
      <ResponseFailure
        buttonClickHandler={() => {
          closeHandle();
          resetError();
        }}
        message={error.message}
      />
    );
  if (isCreateSmartGroupSuccess)
    return (
      <CongratsPopup
        doneButtonClickHandler={() => setIsShowPopup(false)}
        subtitle={t('popup:congratsVol.smartGroupCreated')}
        absoluteDisable
      />
    );
  return (
    <Appeal styleThink isHeightDisable>
      <span className={styles.title}>{t('messages:enterNameSmartGroup')}</span>
      <InputText setValue={setSmartGroupName} value={smartGroupName} placeholder={t('inputs:placeholders.fillIn')} />

      <div className={styles.actions}>
        {isLoading ? (
          <Loader />
        ) : (
          <Fragment>
            <ButtonDefault
              classList={['lg', 'primary']}
              title={t('buttons:button.createWhithout+')}
              disabled={!newSmartGroup}
              clickHandler={confirmClickHandler}
            />
            <ButtonDefault
              classList={['lg', 'secondary']}
              title={t('buttons:button.backToTable')}
              clickHandler={closeHandle}
            />
          </Fragment>
        )}
      </div>
    </Appeal>
  );
};

const mapStateToProps = (store: Store) => ({
  orgId: selectorGetOrgId(store),
  error: selectorGetError(store),
});

const mapDispatchToProps = {
  createSmartGroup,
  setError,
  resetError,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type CreateVolunteersSmartGroupProps = ConnectedProps<typeof connector> & CreateVolunteersSmartGroupParentProps;

export default connector(CreateVolunteersSmartGroup);
