//types
import {GetVolunteerByIdResponse, OPPORTUNITY_ACTIVE_STATUS, VOLUNTEER_ACTIVE_STATUS} from '@joc/api-gateway';
//functions
import {isMyOppo, isVolunteerCantMakeIt} from 'core/functions';

export const getIsJoinButtonDisabled = (
  isVolunteerSuspended: boolean,
  userVolunteerId: string | undefined,
  volunteers: Array<GetVolunteerByIdResponse> | undefined,
  opportunityActiveStatus: OPPORTUNITY_ACTIVE_STATUS
) =>
  isVolunteerSuspended ||
  (!!userVolunteerId && isMyOppo(volunteers, userVolunteerId) && !isVolunteerCantMakeIt(volunteers, userVolunteerId)) ||
  opportunityActiveStatus === OPPORTUNITY_ACTIVE_STATUS.SUSPENDED;

export const getIsVolunteerSuspended = (volunteerStatus: VOLUNTEER_ACTIVE_STATUS | undefined) =>
  volunteerStatus === VOLUNTEER_ACTIVE_STATUS.SUSPENDED;
