export const getIsValidAmount = (amount: string) => {
  const isMinimumAmount = +amount >= 1;
  const isBelowMaximumAmount = +amount <= 100_000;

  return isMinimumAmount && isBelowMaximumAmount;
};

export const getIsValidAmountType = (amount: string) => {
  const isNumerical = !isNaN(+amount);
  const hasLeadingZeros = amount.startsWith('0') && (+amount).toString() !== amount;

  return !!amount.length && isNumerical && !hasLeadingZeros;
};
