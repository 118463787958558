import {forwardRef, ReactNode, RefObject, UIEventHandler} from 'react';
import cx from 'classnames';
// Enums
import {TableType} from 'components/Organization/Volunteers/Table/interfaces';
//styles
import styles from './TableBody.module.scss';

type TableBodyProps = {
  children: ReactNode;
  className?: string;
  onScroll?: (event: any, type?: TableType) => void;
  ref?: RefObject<HTMLDivElement>;
};

const TableBody = forwardRef<HTMLDivElement, TableBodyProps>(({children, className, onScroll}: TableBodyProps, ref) => {
  return (
    <div ref={ref} onScroll={onScroll} className={cx(styles.main, className)}>
      {children}
    </div>
  );
});

export default TableBody;
