import {FC} from 'react';
import {Link} from 'react-router-dom';
//translation
import {useTranslation} from 'react-i18next';
import 'moment/locale/he';
//urls
import {urls} from 'core/appUrls';
//components
import UserIconDefault from 'shared/components/UserIconDefault';
import LangSwitch from 'shared/components/HeaderWrapper/LangSwitch';
//styles
import styles from './HeaderLogin.module.scss';

const HeaderLogin: FC = () => {
  const {t} = useTranslation('buttons');

  return (
    <div className={styles.container}>
      <LangSwitch />

      <div className={styles.main}>
        <Link className={styles.link} to={urls.onboardingChooseUserType}>
          <UserIconDefault />
          <div className={styles.linkText}>{t('button.login')}</div>
        </Link>
      </div>
    </div>
  );
};

export default HeaderLogin;
