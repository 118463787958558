import {FC} from 'react';
import cx from 'classnames';
//functions
import {getFileType} from 'core/functions';
//components
import VideoPreviewSquare from 'shared/components/VideoPreviewSquare';
//styles
import styles from './UploadSeveralPhotos.module.scss';

type Item = {
  id: string;
  src: string;
  isChecked: boolean;
  isNew: boolean;
  file?: File;
};

interface IItemPreview {
  item: Item;
  handleItemCheck: (id: Item['id']) => void;
}

const ItemPreview: FC<IItemPreview> = ({item, handleItemCheck}) => {
  const src = item.isNew ? item.src : `${process.env.REACT_APP_S3_API}/${item.src}`;
  const isVideo = item.isNew ? item.file?.type === 'video/mp4' : getFileType(item.src).toLowerCase() === 'mp4';

  return (
    <div>
      <input type="checkbox" id={item.src} />
      <label htmlFor={item.src} className={styles.preview}>
        {isVideo ? (
          <div className={styles.preview} onClick={() => handleItemCheck(item.id)}>
            <VideoPreviewSquare link={src} smallerActionButton />
          </div>
        ) : (
          <img
            className={cx(!!item.src.length && styles.preview)}
            src={src}
            alt="camera"
            onClick={() => handleItemCheck(item.id)}
          />
        )}
      </label>
    </div>
  );
};

export default ItemPreview;
export type {Item};
