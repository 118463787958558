import {FC} from 'react';
import {Snackbar} from '@material-ui/core';
import {Alert, Color} from '@material-ui/lab';

type SnackbarBasicProps = {
  open: boolean;
  handleClose: () => void;
  text: string;
  autoHideDuration?: number;
  alertSeverity: Color;
};

const SnackbarBasic: FC<SnackbarBasicProps> = ({
  handleClose,
  text,
  alertSeverity,
  autoHideDuration = 2000,
  ...props
}: SnackbarBasicProps) => {
  return (
    <Snackbar autoHideDuration={autoHideDuration} onClose={() => handleClose()} {...props}>
      <Alert onClose={() => handleClose()} severity={alertSeverity}>
        {text}
      </Alert>
    </Snackbar>
  );
};

export default SnackbarBasic;
