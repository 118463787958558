import {useTranslation} from 'react-i18next';
import {Dispatch, FC, SetStateAction, useMemo} from 'react';
import qs from 'qs';
//hooks
import {useSearchMember} from 'hooks/useSearchMember';
//components
import InputSearch from 'shared/components/Inputs/InputSearch';
import ButtonImage from 'shared/components/Buttons/ButtonImage';
//styles
import styles from './Menu.module.scss';

type MenuMemberProps = {
  changeVisibilityFilters?: () => void;
  isClearSearch?: boolean;
  setIsClearSearch?: Dispatch<SetStateAction<boolean>>;
};

export const MemberMenu: FC<MenuMemberProps> = ({changeVisibilityFilters, isClearSearch, setIsClearSearch}) => {
  const {t} = useTranslation(['inputs', 'tooltips']);
  const searchChangeHandler = useSearchMember();

  const isAllTab = useMemo(() => {
    const {role} = qs.parse(location.search, {ignoreQueryPrefix: true});
    if (!role) return true;
  }, [location.search]);

  return (
    <div className={styles.container}>
      <InputSearch
        placeholder={t('inputs:placeholders.searchWithDots')}
        classList={['big']}
        changeHandler={searchChangeHandler}
        isClearSearch={isClearSearch}
        setIsClearSearch={setIsClearSearch}
      />
      {isAllTab && (
        <ButtonImage clickHandler={changeVisibilityFilters} viewStyle="filter" title={t('tooltips:filter')} />
      )}
    </div>
  );
};
