// react
import {useMemo} from 'react';
// hooks
import {useModal} from './useModal';
// interface
import {
  IModalsData,
  IModalState,
  IModalsActionsProps,
  IEditHashtagModalData,
  IDeleteHashtagModalData,
  ICreateHashtagModalData,
} from 'components/HashtagModals/interface';

interface IUseTagModalsResponse {
  modalsData: IModalsData;
  modalsState: IModalState;
  modalsActions: IModalsActionsProps;
}

export const useTagModals = (): IUseTagModalsResponse => {
  const {
    isOpen: isDeleteHashtagModalOpen,
    close: closeDeleteHashtagModal,
    open: openDeleteHashtagModal,
    data: deleteHashtagModalData,
  } = useModal<IDeleteHashtagModalData>();

  const {
    isOpen: isEditHashtagModalOpen,
    close: closeEditHashtagModal,
    open: openEditHashtagModal,
    data: editHashtagModalData,
  } = useModal<IEditHashtagModalData>();

  const {
    isOpen: isCreateHashtagsModalOpen,
    close: closeCreateHashtagsModal,
    open: openCreateHashtagsModal,
    data: createHashtagModalData,
  } = useModal<ICreateHashtagModalData>();

  const modalsState = useMemo(
    () => ({
      edit: isEditHashtagModalOpen,
      delete: isDeleteHashtagModalOpen,
      create: isCreateHashtagsModalOpen,
    }),
    [isDeleteHashtagModalOpen, isEditHashtagModalOpen, isCreateHashtagsModalOpen]
  );

  const modalsActions = useMemo(
    () => ({
      edit: {open: openEditHashtagModal, close: closeEditHashtagModal},
      delete: {open: openDeleteHashtagModal, close: closeDeleteHashtagModal},
      create: {open: openCreateHashtagsModal, close: closeCreateHashtagsModal},
    }),
    [
      openEditHashtagModal,
      openDeleteHashtagModal,
      openCreateHashtagsModal,
      closeEditHashtagModal,
      closeDeleteHashtagModal,
      closeCreateHashtagsModal,
    ]
  );

  const modalsData = useMemo(
    () => ({
      edit: {editHashtagModalData},
      create: {createHashtagModalData},
      delete: {deleteHashtagModalData},
    }),
    [editHashtagModalData, createHashtagModalData, deleteHashtagModalData]
  );

  return {modalsState, modalsActions, modalsData};
};
