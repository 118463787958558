import {useEffect, useRef, useState} from 'react';

type UseClickOutsideArgs = {
  initialVisible: boolean;
  // eslint-disable-next-line no-unused-vars
  setFieldTouched?: (field: string, isTouched?: boolean | undefined, shouldValidate?: boolean | undefined) => void;
  fieldName?: string;
};

export const useBeforeRefreshingPage = (callback: () => void) => {
  useEffect(
    () => () => {
      window.onbeforeunload = callback;
    },
    []
  );
};

export const useDebounceValue = (value?: string, delay?: number) => {
  const [delayedValue, setDelayedValue] = useState<string | undefined>(undefined);

  useEffect(() => {
    const handler = setTimeout(() => value !== undefined && setDelayedValue(value), delay || 200);
    return () => clearTimeout(handler);
  }, [value, delay]);

  return delayedValue;
};

export const useClickOutside = ({initialVisible = false, setFieldTouched, fieldName}: UseClickOutsideArgs) => {
  const [isComponentVisible, setIsComponentVisible] = useState(initialVisible);
  const componentRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: Event) => {
    if (isComponentVisible && setFieldTouched && fieldName) setFieldTouched(fieldName, true);
    if (componentRef.current && !componentRef.current.contains(event.target as Node)) {
      setIsComponentVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => document.removeEventListener('click', handleClickOutside, true);
  });

  return {isComponentVisible, componentRef, setIsComponentVisible};
};
