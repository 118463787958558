import {FC, useEffect, useMemo, useState} from 'react';
import {Field, Form, useFormikContext} from 'formik';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
//assets
import {ReactComponent as TrashIcon} from 'assets/image/trash-light.svg';
//redux
import {selectorGetSchoolId} from 'redux/organization-service/selector';
//form configuration
import {
  SmartGroupControl,
  SmartGroupControlName,
  SmartGroupFormValues,
  initialValues as defaultInitialValues,
} from '../formConfiguration';
//helpers
import {
  getAvailableFilterTypes,
  getFilterTypes,
  getFiltersFromValues,
  getIsAddFilterDisabled,
  getIsSubmitDisabled,
} from './helpers';
//components
import FilterRenderer from './FilterRenderer';
import Input from 'shared/inputs/Input';
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
import SelectBase from 'shared/inputs/Select/SelectBase';
//styles
import styles from '../styles.module.scss';

const SmartGroupForm: FC = () => {
  const {t} = useTranslation(['inputs', 'buttons', 'volunteers']);

  const schoolId = useSelector(selectorGetSchoolId);
  const {values, dirty, isValid, setFieldValue, setValues} = useFormikContext<SmartGroupFormValues>();
  const [filters, setFilters] = useState<Array<SmartGroupControl>>([]);

  const filterTypes = useMemo(() => getFilterTypes(schoolId), [schoolId]);
  const isSubmitDisabled = useMemo(() => getIsSubmitDisabled(filters, isValid, values), [filters, isValid, values]);
  useEffect(() => {
    const isValueArePresent = Object.values(values).some((value) => value);

    if (!dirty && isValueArePresent) {
      const newFilters = getFiltersFromValues(values);
      setFilters(newFilters);
    }
  }, [dirty]);

  const isAddFilterDisabled = useMemo(
    () => getIsAddFilterDisabled(filters, values, isValid),
    [filters, values, isValid]
  );

  const handleAddFilter = () => {
    const newFilters = [...filters, {id: crypto.randomUUID()}];
    setFilters(newFilters);
  };

  const handleDeleteFilter = (filterId: SmartGroupControl['id'], filterName: SmartGroupControl['name']) => () => {
    const newFilters = filters.filter(({id}) => id !== filterId);
    setFilters(newFilters);
    if (filterName) setFieldValue(filterName, null);
  };

  const handleChangeControlSelect = (filterId: string) => (value: string) => {
    const filterName = value as SmartGroupControlName;
    const previousFilterName = filters.find(({id}) => id === filterId)?.name;

    if (filterName === 'volunteers') {
      const newFilters = filters.filter(({id}) => filterId === id).map((filter) => ({...filter, name: filterName}));
      setFilters(newFilters);
      setValues({...defaultInitialValues, groupName: values.groupName});
    } else {
      const newFilters = filters.map((filter) => (filterId === filter.id ? {...filter, name: filterName} : filter));
      setFilters(newFilters);
      setFieldValue(filterName, null);

      if (previousFilterName) setFieldValue(previousFilterName, null);

      if (values.volunteers) setFieldValue('volunteers', null);
    }
  };

  return (
    <Form className={styles.form}>
      <Field
        name="groupName"
        component={Input}
        placeholder={t('inputs:placeholders.enterFilterName')}
        label={t('volunteers:createNewSmartFilter.filterName')}
      />
      {filters.map(({id, name}) => (
        <div className={styles.control} key={id}>
          <label className={styles.label}>{t('volunteers:createNewSmartFilter.filterType')}</label>
          <div className={styles.control__filterName}>
            <SelectBase
              value={name}
              className={styles.select}
              onChange={handleChangeControlSelect(id)}
              placeholder={t('volunteers:createNewSmartFilter.select')}
              options={getAvailableFilterTypes(filterTypes, filters, name)}
            />
            <TrashIcon className={styles.delete} onClick={handleDeleteFilter(id, name)} />
          </div>
          {name && <FilterRenderer isSchool={!!schoolId} filterName={name} />}
        </div>
      ))}
      <div className={styles.actions}>
        <ButtonDefault
          clickHandler={handleAddFilter}
          disabled={isAddFilterDisabled}
          classList={['secondary', 'lg']}
          title={t('volunteers:createNewSmartFilter.addFilterType')}
        />
        <ButtonDefault
          submitType
          disabled={isSubmitDisabled}
          classList={['primary', 'lg']}
          title={t('buttons:button.submit')}
        />
      </div>
    </Form>
  );
};

export default SmartGroupForm;
