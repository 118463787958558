import {FC} from 'react';
import {useTranslation} from 'react-i18next';
//types
import {IUserResponse} from '@joc/api-gateway';
//components
import Appeal from 'shared/components/Appeal/DefaultRenderer';
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
import PopupContainer from 'shared/components/PopupContainer';
//styles
import styles from './MessagePopup.module.scss';

type MessagePopupParentProps = {
  setIsShowPopup: () => void;
  data: IUserResponse;
};

const MessagePopup: FC<MessagePopupParentProps> = ({setIsShowPopup, data}: MessagePopupParentProps) => {
  const {t} = useTranslation(['inputs', 'buttons', 'errors']);

  return (
    <PopupContainer setIsShowPopup={setIsShowPopup}>
      <Appeal stylePensive isHeightDisable>
        <div className={styles.content}>
          <span className="title">{t('errors:sorry')}</span>
          <span className={styles.content__message}>
            Fill all information by
            {!data.firstName && `\n${t('inputs:placeholders.name')}`}
            {!data.lastName && `\n${t('inputs:placeholders.surname')}`}
            {!data.birthDate && `\n${t('inputs:placeholders.dob')}`}
            {!data.email && `\n${t('inputs:placeholders.email')}`}
            {!data.phoneNumber && `\n${t('inputs:placeholders.phone')}`}
            {(!data.address?.latitude || !data.address.longitude) && `\n${t('inputs:placeholders.address')}`}
          </span>
          <ButtonDefault
            classList={['primary', 'md']}
            title={t('buttons:button.fillData')}
            clickHandler={setIsShowPopup}
          />
        </div>
      </Appeal>
    </PopupContainer>
  );
};

export default MessagePopup;
