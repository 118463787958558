import {OPPORTUNITIES_FILTERS_VALUES} from 'core/types/index';

export const INIT_OPPORTUNITIES_FILTERS_VALUES: OPPORTUNITIES_FILTERS_VALUES = {
  opportunityLocationType: [],
  withWho: [],
  weekDays: [],
  startTime: null,
  endTime: null,
  fromDate: null,
  toDate: null,
  opportunityTypesList: [],
  status: [],
};
