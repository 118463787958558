import {ATTACH_USER_TO_CHALLENGE, GET_OPEN_CHALLENGE} from '../actions-types';
import {ChallengesActionTypes, IChallengesReducer} from './types';
import {IChallengeResponse} from '@joc/api-gateway';

const initState: IChallengesReducer = {
  challenge: null,
};

const challengesReducer = (state = initState, action: ChallengesActionTypes): IChallengesReducer => {
  const {type, payload}: ChallengesActionTypes = action;

  switch (type) {
    case GET_OPEN_CHALLENGE: {
      return {
        ...state,
        challenge: payload,
      };
    }

    case ATTACH_USER_TO_CHALLENGE: {
      const challenge = {
        ...state.challenge,
        volunteers: [
          ...(state.challenge?.volunteers || []),
          {volunteerId: payload, createDate: new Date().toISOString()},
        ],
      };
      return {
        ...state,
        challenge: challenge as IChallengeResponse,
      };
    }

    default:
      return state;
  }
};

export default challengesReducer;
