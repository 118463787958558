import {useTranslation} from 'react-i18next';
import {ePreventDefault} from 'core/functions';

export const ChatSearchNotFoundUser = () => {
  const {t} = useTranslation(['messages']);

  return (
    <div className="messaging-create-channel__user-result empty" onMouseDown={ePreventDefault}>
      {t('messages:noPeopleFound')}
    </div>
  );
};
