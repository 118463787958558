import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import cx from 'classnames';
//styles
import styles from './ChartHeaderTotal.module.scss';

type ChartHeaderTotalProps = {
  count: number;
  avgMode?: boolean;
  title?: string;
};

const ChartHeaderTotal: FC<ChartHeaderTotalProps> = ({count, avgMode, title}: ChartHeaderTotalProps) => {
  const {t} = useTranslation('common');

  return (
    <div className={cx(styles.main, title && styles.withTitle)}>
      {title ? `${title}: ${count}` : `${t(`titles.${avgMode ? 'average' : 'total'}`)}: ${count}`}
    </div>
  );
};

export default ChartHeaderTotal;
