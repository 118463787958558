import {FC, Dispatch, SetStateAction, useEffect} from 'react';
import {RouteComponentProps} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import cx from 'classnames';
import moment from 'moment';
//formik
import {Field, Form, useFormikContext} from 'formik';
//functions
import {VolunteersFiltersSubmitClickHandler} from 'core/functions/submitClickHandler';
//types
import {VOLUNTEERS_FILTERS_VALUES} from 'core/types';
//constants
import {ageSelectOptions} from 'core/constants/selectOptions';
//constants
import {INITIAL_VOLUNTEERS_FILTERS_VALUES} from 'core/constants';
//components
import {DateTimeFields} from 'components/Organization/Opportunities/OpportunitiesFilters/OpportunitiesFiltersForm/DateTimeFields';
import FiltersContainer from 'components/Organization/Filters/FiltersContainer';
import SelectDropdown from 'shared/components/SelectDropdown/SingleSelect';
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
import GenderSelect from 'shared/components/SelectDropdown/GenderSelect';
import SliderRange from 'shared/components/SliderRange';
//styles
import styles from './VolunteersFiltersForm.module.scss';

export const currentDate = moment().startOf('day');

type VolunteersFiltersFormParentProps = {
  isCloseFilters: boolean;
  schoolId: number;
  history: RouteComponentProps['history'];
  changeVisibilityFilters: () => void;
  setInitialValues: Dispatch<SetStateAction<VOLUNTEERS_FILTERS_VALUES>>;
  setIsClearSearch?: Dispatch<SetStateAction<boolean>>;
};

const VolunteersFiltersForm: FC<VolunteersFiltersFormParentProps> = ({
  isCloseFilters,
  schoolId,
  history,
  changeVisibilityFilters,
  setInitialValues,
  setIsClearSearch,
}) => {
  const {values, resetForm, setFieldValue, isValid} = useFormikContext<VOLUNTEERS_FILTERS_VALUES>();
  const {t} = useTranslation(['form', 'inputs']);

  const setCurrentDate = (values: VOLUNTEERS_FILTERS_VALUES) => {
    const {fromDate: startDate, toDate: endDate} = values;
    if (endDate && !startDate) {
      setFieldValue('fromDate', currentDate);
    }
  };

  useEffect(() => {
    setCurrentDate(values);
  }, [values]);

  const studentAge = t('form:filter.studentAge');
  const volunteerAge = t('form:filter.volunteerAge');

  return (
    <FiltersContainer
      isCloseFilters={isCloseFilters}
      changeVisibilityFilters={changeVisibilityFilters}
      isOrgFilter
      resetForm={() => {
        setInitialValues(INITIAL_VOLUNTEERS_FILTERS_VALUES);
        resetForm();
        VolunteersFiltersSubmitClickHandler(undefined, history);
        setIsClearSearch && setIsClearSearch(true);
      }}
    >
      <Form className={styles.form}>
        <DateTimeFields isReadOnlyInput withoutTime showDateInThePast showDateInTheFuture />
        <div className={styles.form__field}>
          <Field
            name="genderId"
            label={t('form:filter.gender')}
            placeholder={t('inputs:placeholders.selectGender')}
            isDisableHeight
            isDisabledMargin
            isLabelBold
            component={GenderSelect}
          />
        </div>
        <div className={cx(styles.form__field, styles.field_wrap)}>
          <label className={styles.label}>{t('form:filter.howFar')}</label>
          <Field name="howFar" min={0} max={50} isDisabledMargin isDisableHeight component={SliderRange} />
        </div>
        <div className={styles.form__field}>
          <label className={styles.label}>{schoolId ? studentAge : volunteerAge}</label>
          <div className={styles.ageLimit}>
            <div className={styles.labelAge}>{t('form:opportunity.minAge')}</div>
            <Field
              name="minAge"
              placeholder={t('inputs:placeholders.minAge')}
              options={ageSelectOptions.filter((option) => (values.maxAge ? option <= values.maxAge : option))}
              isDisableHeight
              component={SelectDropdown}
            />
            <div className={styles.labelAge}>{t('form:opportunity.maxAge')}</div>
            <Field
              name="maxAge"
              placeholder={t('inputs:placeholders.maxAge')}
              options={ageSelectOptions.filter((option) => (values.minAge ? option >= values.minAge : option))}
              isDisableHeight
              component={SelectDropdown}
            />
          </div>
        </div>
        <ButtonDefault disabled={!isValid} title={t('form:filter.apply')} submitType classList="secondary md" />
      </Form>
    </FiltersContainer>
  );
};

export default VolunteersFiltersForm;
