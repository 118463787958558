import {FC, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
//redux
import {selectorGetOrgSchoolGrade} from 'redux/organization-service/selector';
//API
import {API} from 'core/API';
//types
import {SelectOption} from 'shared/inputs/Select/types';
//form configuration
import {SmartGroupGenderValue, SmartGroupShoolGradeValue} from '../../formConfiguration';
//components
import {Field} from 'formik';
import Select from 'shared/inputs/Select';
import AgeControl from '../AgeControl';
import SpendTimeControl from '../SpendTimeControl';
import LocationControl from '../LocationControl';
import VolunteersControl from '../VolunteersControl';

type FilterRendererProps = {
  filterName: string;
  isSchool?: boolean;
};

const FilterRenderer: FC<FilterRendererProps> = ({filterName, isSchool}) => {
  const {t} = useTranslation(['common', 'form', 'inputs']);

  const schoolGrades = useSelector(selectorGetOrgSchoolGrade);

  const [schoolGradeOptions, setSchoolGradeOptions] = useState<Array<SelectOption<SmartGroupShoolGradeValue>>>([]);
  const [genderOptions, setGenderOptions] = useState<Array<SelectOption<SmartGroupGenderValue>>>([]);

  const getSchoolGradeOptions = async () => {
    const newValue = schoolGrades?.map((grade) => ({
      title: t(`common:ordinal.${grade.id}` as unknown as TemplateStringsArray),
      value: Number(grade.id),
    }));

    if (newValue) setSchoolGradeOptions(newValue);
  };

  const getGenderOptions = async () => {
    const genders = await API.getAllGenders();
    const newValue = genders.map((gender) => ({
      title: t(gender.id === 1 ? 'common:genders.male' : 'common:genders.female'),
      value: Number(gender.id),
    }));

    setGenderOptions(newValue);
  };

  useEffect(() => {
    getSchoolGradeOptions();
    getGenderOptions();
  }, []);

  switch (filterName) {
    case 'ages':
      return <Field name={filterName} disableErrorText isDisableHeight component={AgeControl} />;
    case 'spendTime':
      return (
        <Field isSchool={!!isSchool} name={filterName} disableErrorText isDisableHeight component={SpendTimeControl} />
      );
    case 'locations':
      return <Field name={filterName} disableErrorText isDisableHeight component={LocationControl} />;
    case 'volunteers':
      return <Field name={filterName} component={VolunteersControl} />;
    case 'gender':
      return (
        <Field
          name={filterName}
          options={genderOptions}
          placeholder={t('inputs:placeholders.selectGender')}
          disableErrorText
          isDisableHeight
          component={Select}
        />
      );
    case 'schoolGrades':
      return (
        <Field
          name={filterName}
          options={schoolGradeOptions}
          placeholder={t('inputs:placeholders.selectGrade')}
          disableErrorText
          isDisableHeight
          component={Select}
        />
      );
    default:
      return null;
  }
};

export default FilterRenderer;
