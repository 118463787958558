import {
  RESET_CHAT,
  RESET_CHAT_COUNTER,
  SET_CHAT_CLIENT_CONNECT_STATE,
  SET_CHAT_UNREAD_MESSAGE_COUNT,
  SET_CHAT_USER_TO_CONNECT,
} from 'redux/actions-types';
import {ChatActionTypes, ChatReducerType, UserToConnectType} from './types';

const initState: ChatReducerType = {
  userToConnect: null,
  userConnectState: false,
  unreadCount: 0,
};

const chatReducer = (state = initState, action: ChatActionTypes): ChatReducerType => {
  const {type, payload}: ChatActionTypes = action;

  switch (type) {
    case SET_CHAT_USER_TO_CONNECT:
      return {...state, userToConnect: payload as UserToConnectType};

    case SET_CHAT_CLIENT_CONNECT_STATE:
      return {...state, userConnectState: payload as boolean};

    case SET_CHAT_UNREAD_MESSAGE_COUNT:
      return {
        ...state,
        unreadCount: payload as number,
      };

    case RESET_CHAT_COUNTER:
      return {
        ...state,
        unreadCount: 0,
      };

    case RESET_CHAT:
      return initState;
    default:
      return state;
  }
};

export default chatReducer;
