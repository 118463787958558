import {FC} from 'react';
import cx from 'classnames';
//types
import {WHICH_DAY_FILTER_FORMAT, WHICH_DAY_FILTER_TYPE} from 'core/types';
//helpers
import {Dimension, defaultDayDimension, getDayValue} from './helpers';
//styles
import styles from './DayItem.module.scss';

type DayItemProps = {
  day: string;
  checked?: boolean;
  type: WHICH_DAY_FILTER_TYPE;
  format: WHICH_DAY_FILTER_FORMAT;
  parentClassName?: string;
  dimension?: Dimension;
};

const DayItem: FC<DayItemProps> = ({day, parentClassName, checked, type, format, dimension = defaultDayDimension}) => {
  const dayValue = getDayValue(day, format);

  return (
    <div
      className={cx(styles.main, parentClassName, {
        [styles.checked]: checked,
        [styles.main_blue]: type === WHICH_DAY_FILTER_TYPE.BLUE,
        [styles.checked_blue]: type === WHICH_DAY_FILTER_TYPE.BLUE && checked,
      })}
      style={{...dimension}}
    >
      <span className={cx(styles.placeholder, {[styles.placeholder_blue]: type === WHICH_DAY_FILTER_TYPE.BLUE})}>
        {dayValue}
      </span>
    </div>
  );
};

export default DayItem;
