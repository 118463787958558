import {ComponentProps, FC, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Field, Form, Formik} from 'formik';
import {useTranslation} from 'react-i18next';
//redux
import {resetError, setError} from 'redux/error-service/action';
import {selectorGetError} from 'redux/error-service/selector';
//form configuration
import {RemoveFormValues, initialValues, validationSchema} from './formConfiguration';
//helpers
import {removeMeFromOrganization} from './helpers';
//components
import PopupContainer from 'shared/components/PopupContainer';
import InputSearchOrg from 'components/Organization/InputSearchOrg';
import CongratsPopup from 'components/Organization/CongratsPopup';
import ResponseFailure from 'shared/components/ResponseFailure';
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
import Loader from 'shared/components/Loader';
//styles
import styles from './styles.module.scss';

type RemoveFormProps = Required<Pick<ComponentProps<typeof PopupContainer>, 'closeButtonParentClickHandler'>>;

const RemoveForm: FC<RemoveFormProps> = ({closeButtonParentClickHandler}) => {
  const {t} = useTranslation(['buttons', 'form', 'about']);

  const dispatch = useDispatch();

  const error = useSelector(selectorGetError);

  const [isCongratsPopupVisible, setIsCongratsPopupVisible] = useState(false);

  const submitClickHandler = async (values: RemoveFormValues) => {
    try {
      await removeMeFromOrganization(Number(values.organizationId));
      setIsCongratsPopupVisible(true);
    } catch (error) {
      dispatch(
        setError(
          error?.response?.data?.message || error?.response?.message || error.message,
          error?.response?.data?.code || error?.response?.code || error.code
        )
      );
    }
  };

  const resetClickHandler = () => {
    dispatch(resetError());
  };

  const doneClickHandler = () => {
    closeButtonParentClickHandler();
    window.location.reload();
  };

  if (error.state)
    return (
      <PopupContainer isDisableCloseButton isDisablePadding isContentCenter isDisableContentMarginTop>
        <ResponseFailure
          message={error.message}
          buttonTitle={t('buttons:button.reset')}
          buttonClickHandler={resetClickHandler}
          isReloginDisabled
          isFullHeight
        />
      </PopupContainer>
    );

  if (isCongratsPopupVisible)
    return (
      <PopupContainer isDisableCloseButton isDisablePadding isContentCenter isDisableContentMarginTop>
        <CongratsPopup subtitle={t('about:doneSubtitle')} doneButtonClickHandler={doneClickHandler} isFullHeight />
      </PopupContainer>
    );

  return (
    <PopupContainer
      closeButtonParentClickHandler={closeButtonParentClickHandler}
      isContentCenter
      isDisableContentMarginTop
    >
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={submitClickHandler}>
        {({isSubmitting}) => (
          <Form className={styles.form}>
            <h1 className="title">{t('about:removeTitle')}</h1>
            <Field
              name="organizationId"
              placeholder={t('form:filter.searchOrganization')}
              disabled={isSubmitting}
              isMainOrgHidden
              isDisableHeight
              isDisabledMargin
              isSuspendedEnabled
              component={InputSearchOrg}
            />
            {isSubmitting ? (
              <Loader loadProps={{size: 32, disableCenterStyle: true}} />
            ) : (
              <ButtonDefault title={t('buttons:button.submit')} submitType classList={['primary', 'lg']} />
            )}
          </Form>
        )}
      </Formik>
    </PopupContainer>
  );
};

export default RemoveForm;
