import {Dispatch, FC, SetStateAction, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import cx from 'classnames';
//formik
import {Formik} from 'formik';
//redux
import {userCreate} from 'redux/user-service/actions';
import {setError} from 'redux/error-service/action';
//form configuration
import {LoginFromEmailFormValues, initialValues, validationSchema} from './formConfiguration';
//components
import LoginEmailForm from './LoginEmailForm';
//styles
import styles from './LoginFromEmailForm.module.scss';

type LoginFromEmailFormParentProps = {
  setIsCreateUserSuccess: Dispatch<SetStateAction<boolean>>;
};

const LoginFromEmailForm: FC<LoginFromEmailFormParentProps> = ({setIsCreateUserSuccess}) => {
  const {t} = useTranslation('inputs');

  const location = useLocation();

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [currentInitialValues, setCurrentInitialValues] = useState<LoginFromEmailFormValues>(initialValues);

  useEffect(() => {
    if (location.search) {
      const query = new URLSearchParams(location.search);
      const queryEmail = query.get('email');

      if (queryEmail) {
        const parsQueryEmail = queryEmail
          .trim()
          .split('')
          .map((el) => {
            if (el === ' ') return '+';
            return el;
          })
          .join('');
        setCurrentInitialValues({...currentInitialValues, email: parsQueryEmail});
      }
    }
  }, [location]);

  const submitClickHandler = async (values: LoginFromEmailFormValues) => {
    setIsLoading(true);

    try {
      await dispatch(userCreate(values));
      setIsCreateUserSuccess(true);
    } catch (error) {
      dispatch(setError(error?.response?.message || error.message, error?.response?.code || error.code));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      <span className={cx('title', styles.title)}>{t('placeholders.confirmPassword')}</span>
      <Formik
        initialValues={currentInitialValues}
        validationSchema={validationSchema}
        onSubmit={submitClickHandler}
        enableReinitialize
      >
        <LoginEmailForm isLoading={isLoading} />
      </Formik>
    </div>
  );
};

export default LoginFromEmailForm;
