import {useTranslation} from 'react-i18next';
import {Dispatch, FC, SetStateAction, useEffect} from 'react';
import {Field, Form, useFormikContext} from 'formik';
// Types
import {MEMBERS_FILTERS_VALUES, ROLES_FILTERS} from 'core/types/MEMBERS_FILTERS_VALUES';
// Components
import SingleSelect from 'shared/components/SelectDropdown/SingleSelect';
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
// Styles
import styles from '../Sidebar.module.scss';

const options = [ROLES_FILTERS.ADMIN, ROLES_FILTERS.COORDINATOR, ROLES_FILTERS.MARKETER];

interface MemberFilterFormProps {
  setIsClearFilter: Dispatch<SetStateAction<boolean>>;
  isCloseFilters?: boolean;
  isClearFilter?: boolean;
}

export const MemberFilterForm: FC<MemberFilterFormProps> = ({isCloseFilters, isClearFilter, setIsClearFilter}) => {
  const {t} = useTranslation(['form', 'inputs']);
  const {isValid, dirty, resetForm} = useFormikContext<MEMBERS_FILTERS_VALUES>();

  useEffect(() => {
    if (isCloseFilters || isClearFilter) {
      resetForm();
      setIsClearFilter(false);
    }
  }, [isCloseFilters, isClearFilter]);

  return (
    <Form className={styles.form}>
      <Field
        name="role"
        label={t('form:role')}
        placeholder={t('inputs:placeholders.selectRole')}
        options={options}
        component={SingleSelect}
      />
      <ButtonDefault disabled={!isValid || !dirty} title={t('form:filter.apply')} submitType classList="secondary md" />
    </Form>
  );
};
