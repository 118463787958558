import * as Yup from 'yup';
//validation
import {confirmPasswordSchema, passwordSchema} from 'core/validation';

export type RecoveryFormValues = {
  email: string;
  password: string;
  confirmPassword: string;
};

export const getInitialValues = (email: string): RecoveryFormValues => ({
  email,
  password: '',
  confirmPassword: '',
});

export const validationSchema = Yup.object().shape({
  password: passwordSchema,
  confirmPassword: confirmPasswordSchema,
});
