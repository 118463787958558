import * as Yup from 'yup';
import i18n from 'i18next';
//types
import {IGetInTouchRequest, IUserResponse} from '@joc/api-gateway';
//helpers
import {getAddressString} from 'core/functions/address';

export type GetInTouchFormValues = IGetInTouchRequest & {captcha: string};

export const getInitialValues = (userData: IUserResponse | null): GetInTouchFormValues => {
  return {
    email: userData?.email || '',
    name: userData?.firstName && userData?.lastName ? `${userData.firstName} ${userData?.lastName}` : '',
    country: userData?.address ? getAddressString(userData.address) : '',
    subject: '',
    message: '',
    captcha: '',
  };
};

const validationT = i18n.getFixedT(null, 'validation');

export const validationSchema = Yup.object().shape({
  email: Yup.string().email(validationT('emailSchema.email')).required(validationT('emailSchema.required')),
  subject: Yup.string().required(validationT('getInTouchValidationSchema.required.subject')),
  name: Yup.string().required(validationT('getInTouchValidationSchema.required.name')),
  message: Yup.string().required(validationT('getInTouchValidationSchema.required.message')),
  country: Yup.string().required(validationT('getInTouchValidationSchema.required.country')),
  captcha: Yup.string().required(validationT('getInTouchValidationSchema.required.captcha')).nullable(),
});
