// types
import {IOpportunityResponse, OPPORTUNITY_TYPES} from '@joc/api-gateway';

export const getTransformOppo = (oppo: IOpportunityResponse) => {
  const {MANUAL} = OPPORTUNITY_TYPES;
  const {duration, endDate, opportunityType} = oppo;

  const isManualOpportunity = opportunityType === MANUAL;
  const isFullDay = Math.floor(duration / 3600) % 24 === 0;
  const adjustedEndDate = isManualOpportunity && isFullDay ? new Date(endDate.getTime() - 1000) : endDate;

  return {
    ...oppo,
    endDate: adjustedEndDate,
  };
};
