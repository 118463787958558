import {FC} from 'react';
import {useHistory} from 'react-router';
import {useTranslation} from 'react-i18next';
import cx from 'classnames';
//formik
import {Field, Form, Formik} from 'formik';
//types
import {IDateRange} from '@joc/api-gateway';
//functions
import {StatitsicsFilterFormSubmitClickHandler} from 'core/functions/submitClickHandler';
//components
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
import {DatePicker} from 'shared/components/Pickers/NewUI/DatePicker';
import ButtonCross from 'shared/components/Buttons/ButtonCross';
//styles
import styles from './StatisticsFilterForm.module.scss';

type StatisticsFilterFormProps = {
  closePopupClickHandler: () => void;
  isClosePopup: boolean;
  dateRange: IDateRange;
};

const StatitsicsFilterForm: FC<StatisticsFilterFormProps> = ({
  closePopupClickHandler,
  isClosePopup,
  dateRange,
}: StatisticsFilterFormProps) => {
  const history = useHistory();

  const {t} = useTranslation(['statistics', 'form']);

  return (
    <Formik
      enableReinitialize
      initialValues={dateRange}
      onSubmit={(values) => StatitsicsFilterFormSubmitClickHandler(values, history)}
    >
      <Form className={cx(styles.form, isClosePopup && styles.close)}>
        <ButtonCross clickHandler={closePopupClickHandler} parentClassName={styles.button_cross} />
        <Field name="fromDate" label={t('statistics:startDate')} isDisableHeight availablePast component={DatePicker} />
        <Field
          name="toDate"
          label={t('statistics:endDate')}
          isDisableHeight
          availablePast
          availableFuture
          component={DatePicker}
        />
        <ButtonDefault title={t('form:filter.apply')} classList={['secondary', 'md', 'center']} submitType />
      </Form>
    </Formik>
  );
};

export default StatitsicsFilterForm;
