import qs from 'qs';

export const getNewLocationUrl = (
  volInbox: string,
  chatId: string | undefined,
  organizationName: string | undefined
): string => {
  const queryString = qs.stringify({
    members: [
      {
        chatId,
        chatName: organizationName,
      },
    ],
    type: 'all',
  });

  return `${volInbox}?${queryString}`;
};
