import {FC, useMemo, useState} from 'react';
import firebase from 'firebase';
import {useTranslation} from 'react-i18next';
import {useLocation} from 'react-router';
import qs from 'qs';
//formik
import {Field, Form, useFormikContext} from 'formik';
import {LoginFormVValues} from '../formConfiguration';
//urls
import {urls} from 'core/appUrls';
//types
import {SocialProvider} from 'core/types/index';
import {ClaimJoinParsedQs} from 'core/types/queryStrings';
//icons
import {ReactComponent as InfoIcon} from 'assets/icons/info.svg';
//components
import Loader from 'shared/components/Loader';
import CreateAccount from 'components/Organization/Login/CreateAccount';
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
import RecoveryPasswordLink from 'shared/components/RecoveryPasswordLink';
import OptionsSeparator from '../OptionsSeparator';
import SocialButton from '../SocialButton';
import Input from 'shared/inputs/Input';
//styles
import styles from './LoginVolunteerForm.module.scss';

type LoginVolunteerFormParentProps = {
  isLoading: boolean;
  loginSocial: (provider: firebase.auth.AuthProvider | undefined) => Promise<void>;
  socialProviders: SocialProvider;
};

const LoginVolunteerForm: FC<LoginVolunteerFormParentProps> = ({isLoading, loginSocial, socialProviders}) => {
  const location = useLocation();

  const {t} = useTranslation(['inputs', 'buttons', 'form']);

  const [isFocused, setIsFocused] = useState(false);

  const parsedQs = qs.parse(location.search, {ignoreQueryPrefix: true}) as ClaimJoinParsedQs;

  const signUpUrl = parsedQs.organisationId
    ? `${urls.onboardingSignupVolunteer}${location.search}`
    : urls.onboardingSignupVolunteer;

  return (
    <Form className={styles.form}>
      <Field name="email" placeholder={t('inputs:placeholders.email')} isDisabledMargin component={Input} />

      <Field
        name="password"
        type="password"
        placeholder={t('inputs:placeholders.password')}
        isDisabledMargin
        component={Input}
        onFocus={() => setIsFocused(true)}
      />

      {isFocused && (
        <div className={styles.form__info}>
          <InfoIcon />
          <p>{t('form:passwordIsCaseSensitive')}</p>
        </div>
      )}

      <RecoveryPasswordLink />

      {/*<OptionsSeparator />*/}

      {/*<div className={styles.login_options}>*/}
      {/*  <SocialButton*/}
      {/*    googleType*/}
      {/*    clickHandler={() => loginSocial(socialProviders.googleProvider)}*/}
      {/*    isDisabled={isLoading}*/}
      {/*  />*/}
      {/*</div>*/}

      <div className={styles.button__container}>
        {isLoading ? (
          <Loader />
        ) : (
          <ButtonDefault submitType classList={['primary', 'extra_lg']} title={t('buttons:button.signIn')} />
        )}
      </div>
      <CreateAccount parentClassName={styles.create_account} linkTo={signUpUrl} disabled={isLoading} />
    </Form>
  );
};

export default LoginVolunteerForm;
