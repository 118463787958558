import {FC, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
//urls
import {urls} from 'core/appUrls';
//translation
import {useTranslation} from 'react-i18next';
//components
import ResponseFailure from 'shared/components/ResponseFailure';
//styles
import styles from './ConnectionError.module.scss';

const ConnectionError: FC = () => {
  const history = useHistory();

  const {t} = useTranslation('errors');

  useEffect(() => {
    localStorage.clear();
  }, []);

  const buttonClickHandler = () => history.push(urls.newMainFeed);

  return (
    <div className={styles.wrapper}>
      <ResponseFailure
        message={t('connectionError')}
        buttonClickHandler={buttonClickHandler}
        buttonTitle={t('tryAgain')}
        styleThink
      />
    </div>
  );
};

export default ConnectionError;
