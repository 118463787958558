import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useLocation, useParams} from 'react-router';
import {useTranslation} from 'react-i18next';
import qs from 'qs';
//API
import {API} from 'core/API';
//redux
import {setPublicDashboardData} from 'redux/publicDashboard-service/actions';
//components
import Board from './Board';
//styles
import styles from './styles.module.scss';

type PublicDashboardPageParams = {
  id: string;
};

const PublicDashboardPage = () => {
  const {i18n} = useTranslation();

  const dispatch = useDispatch();

  const {search} = useLocation();

  const {id} = useParams<PublicDashboardPageParams>();

  const setLanguageFromParams = () => {
    const {language} = qs.parse(search, {ignoreQueryPrefix: true});
    i18n.changeLanguage(language as string);
  };

  const getPublicDashboardData = async () => {
    const data = await API.publicDashboard(+id);
    dispatch(setPublicDashboardData(data));
  };

  useEffect(() => {
    setLanguageFromParams();
    getPublicDashboardData();
    const interval = setInterval(() => getPublicDashboardData(), 30000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className={styles.wrapper}>
      <Board />
    </div>
  );
};

export default PublicDashboardPage;
