import {FC, useContext, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Field, FieldProps, Form, useFormikContext} from 'formik';
import {useTranslation} from 'react-i18next';
import cx from 'classnames';
//redux
import {resetFilter, setFullTextSearch} from 'redux/filter-service/actions';
import {resetOpportunities} from 'redux/opportunities-service/action';
import {selectorGetUserAddress, selectorGetUserRoles} from 'redux/user-service/selector';
//context
import {FeedVContext} from 'pages/Volunteers/FeedV';
//types
import {OPPORTUNITIES_FILTERS_VOLUNTEER_VALUES} from 'core/types/index';
import {SEARCH_OPPORTUNITY_POSSIBLE_SEARCH} from '@joc/api-gateway';
//components
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
import WithWhoFilter from 'shared/components/FiltersFields/WithWhoFilter';
import WhichDaysFilter from 'shared/components/FiltersFields/WichDaysFilter';
import FiltersContainer from 'components/Volunteer/Filters/FiltersContainer';
import SliderRange from 'shared/components/SliderRange';
import InputSearchOrg from 'components/Organization/InputSearchOrg';
import InputSearch from 'shared/components/Inputs/InputSearch';
import {DateTimeFields} from 'components/Organization/Opportunities/OpportunitiesFilters/OpportunitiesFiltersForm/DateTimeFields';
//styles
import styles from './OpportunitiesFilters.module.scss';

type VolunteerOpportunitiesFilterFormParentsProps = {
  submitClickHandler: (values: OPPORTUNITIES_FILTERS_VOLUNTEER_VALUES | undefined) => void;
};

const VolunteerOpportunitiesFilterForm: FC<VolunteerOpportunitiesFilterFormParentsProps> = ({submitClickHandler}) => {
  const dispatch = useDispatch();

  const {setFieldValue, values, resetForm, isValid} = useFormikContext<OPPORTUNITIES_FILTERS_VOLUNTEER_VALUES>();

  const {isClearSearch, setIsClearSearch} = useContext(FeedVContext);
  const userAddress = useSelector(selectorGetUserAddress);
  const userRoles = useSelector(selectorGetUserRoles);

  useEffect(() => {
    isClearSearch && resetForm();
  }, [isClearSearch]);

  const {t} = useTranslation(['form', 'common']);

  const resetFormClickHandler = () => {
    resetForm();
    dispatch(resetFilter());
    submitClickHandler(undefined);
    setIsClearSearch?.(true);
  };

  const searchChangeHandler = (value: string): void => {
    dispatch(resetOpportunities());
    dispatch(
      setFullTextSearch({
        value,
        fields: Object.values(SEARCH_OPPORTUNITY_POSSIBLE_SEARCH),
      })
    );
  };

  const applyButtonClickHandler = async () => {
    submitClickHandler(values);
  };

  return (
    <FiltersContainer resetForm={resetFormClickHandler} isVisible disablePaddingSide toggleShow={false}>
      <Form className={styles.form}>
        <div className={styles.formWrap}>
          <div className={styles.input__search}>
            <InputSearch
              placeholder={t('common:placeholders.search')}
              changeHandler={searchChangeHandler}
              isClearSearch={isClearSearch}
              setIsClearSearch={setIsClearSearch}
            />
          </div>
          {userRoles?.length && (
            <div className={cx(styles.form__field, styles.field_picker, styles.search_organisation)}>
              <Field
                name="organisationId"
                label={t('form:filter.whichOrganization')}
                placeholder={t('form:filter.searchOrganization')}
                parentClassName={styles.top}
                isDisableHeight
                isLabelBold
                isClearSearch={isClearSearch}
                setIsClearSearch={setIsClearSearch}
                component={InputSearchOrg}
              />
            </div>
          )}
          <DateTimeFields showDateInThePast showDateInTheFuture />

          <div className={cx(styles.form__field, styles.field_picker)}>
            <div className={cx(styles.form__opportunitie_address, styles.form__location_type)}>
              <Field type="checkbox" name="opportunityLocationType" value="LOCAL">
                {({field}: FieldProps) => (
                  <label htmlFor={`${field.name}1`} className={styles.label_for_button}>
                    <ButtonDefault
                      classList={['secondary', 'no_click', field.checked ? 'secondary_active' : '']}
                      title={t('form:filter.oppoLocal')}
                    />
                    <input type="checkbox" {...field} id={`${field.name}1`} />
                  </label>
                )}
              </Field>
              <Field type="checkbox" name="opportunityLocationType" value="VIRTUAL">
                {({field}: FieldProps) => (
                  <label htmlFor={`${field.name}2`} className={styles.label_for_button}>
                    <ButtonDefault
                      classList={['secondary', 'no_click', field.checked ? 'secondary_active' : '']}
                      title={t('form:filter.oppoVirtual')}
                    />
                    <input type="checkbox" {...field} id={`${field.name}2`} />
                  </label>
                )}
              </Field>
            </div>
          </div>
          <div className={styles.form__field}>
            <WithWhoFilter setFieldValue={setFieldValue} fieldValues={values.withWho} smallStyle />
          </div>
          <div className={styles.form__field}>
            <WhichDaysFilter setFieldValue={setFieldValue} fieldValues={values.weekDays} />
          </div>
          {!!userAddress && !!userAddress.latitude && !!userAddress.longitude && (
            <div className={cx(styles.form__field, styles.field_wrap)}>
              <label className={styles.label}>{t('form:filter.howFar')}</label>
              <Field name="howFar" min={0} max={50} isLabelBold component={SliderRange} />
            </div>
          )}
        </div>
        <ButtonDefault
          disabled={!isValid}
          title={t('form:filter.apply')}
          classList="apply secondary md"
          clickHandler={applyButtonClickHandler}
        />
      </Form>
    </FiltersContainer>
  );
};

export default VolunteerOpportunitiesFilterForm;
