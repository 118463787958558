import {FC} from 'react';
import {useTranslation} from 'react-i18next';
//formik
import {Field, Form} from 'formik';
//components
import Loader from 'shared/components/Loader';
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
import Input from 'shared/inputs/Input';
//styles
import styles from './ChangePasswordForm.module.scss';

type ChangePasswordFormProps = {
  isLoading: boolean;
};

const ChangePasswordForm: FC<ChangePasswordFormProps> = ({isLoading}) => {
  const {t} = useTranslation(['inputs', 'buttons']);

  return (
    <Form className={styles.form}>
      <Field
        name="password"
        type="password"
        placeholder={t('inputs:placeholders.password')}
        isDisabledMargin
        component={Input}
      />

      <Field
        name="confirmPassword"
        type="password"
        placeholder={t('inputs:placeholders.confirmPassword')}
        isDisabledMargin
        component={Input}
      />

      <div className={styles.buttons}>
        {isLoading ? (
          <Loader />
        ) : (
          <ButtonDefault submitType classList={['primary', 'extra_lg']} title={t('buttons:button.submit')} />
        )}
      </div>
    </Form>
  );
};

export default ChangePasswordForm;
