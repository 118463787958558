import {Dispatch, FC, SetStateAction, useRef, useState, useEffect, useMemo} from 'react';
import cx from 'classnames';
import debounce from 'lodash.debounce';
//hooks
import useEffectNoFirstMount from 'hooks/useEffectNoFirstMount';
//styles
import styles from './index.module.scss';

type InputSearchProps = {
  changeHandler: (value: string) => void;
  placeholder: string;
  id?: string;
  classList?: Array<string>;
  parentClass?: string;
  value?: string;
  autoFocus?: boolean;
  isClearSearch?: boolean;
  setIsClearSearch?: Dispatch<SetStateAction<boolean>>;
};

const InputSearch: FC<InputSearchProps> = ({
  placeholder = '',
  id,
  classList,
  changeHandler,
  parentClass,
  autoFocus = false,
  isClearSearch,
  setIsClearSearch,
}: InputSearchProps) => {
  const [inputValue, setInputValue] = useState<string>('');

  const ref = useRef<HTMLInputElement>(null);

  const setFocus = (): void => {
    if (ref && ref.current) {
      ref.current.focus();
    }
  };

  useEffect(() => {
    if (isClearSearch) {
      setInputValue('');
      setIsClearSearch && setIsClearSearch(false);
    }
  }, [isClearSearch]);

  const getData = useMemo(() => debounce(changeHandler, 500), []);

  useEffectNoFirstMount(() => {
    getData(inputValue.trim());

    return () => getData.cancel();
  }, [inputValue]);

  return (
    <div
      className={cx(styles.input__search, parentClass, {
        [styles.big]: classList?.includes('big'),
      })}
    >
      <div className={styles.input__search_icon} onClick={setFocus} />
      <input
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={autoFocus}
        id={id && id}
        type="text"
        value={inputValue || ''}
        placeholder={placeholder}
        ref={ref}
        onChange={(e) => {
          setInputValue(e.target.value);
        }}
      />
    </div>
  );
};

export default InputSearch;
