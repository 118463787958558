// react
import {Dispatch, FC, SetStateAction, useMemo} from 'react';
// other packages
import {useTranslation} from 'react-i18next';
// formik
import {Field, Form, useFormikContext} from 'formik';
// components
import Input from 'shared/inputs/Input';
import Loader from 'shared/components/Loader';
import {TimeLimit} from 'components/TimeLimit';
// import TextEditor from 'shared/components/TextEditor';
import {HashtagField} from 'shared/components/HashtagField';
import ItemGallery from 'components/Volunteer/UploadSeveralPhotos';
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
import {DatePicker} from 'shared/components/Pickers/NewUI/DatePicker';
// types
import {IHashtag} from 'redux/hashtag-service/types';
// interfaces
import {FormikOpportunityMainForm} from './interfaces';
// data
import {CREATE_MANUAL_TRANSLATION_KEYS} from './data';
// styles
import styles from './index.module.scss';
import Editor from '../../../shared/components/Editor';

interface ICreateManualOppFormProps {
  isLoading: boolean;
  options: IHashtag[];
  isVisibleHashtagField: boolean;
  setIsVisibleHashtagField: Dispatch<SetStateAction<boolean>>;
}

export const CreateManualOppForm: FC<ICreateManualOppFormProps> = ({
  options,
  isLoading,
  isVisibleHashtagField,
  setIsVisibleHashtagField,
}) => {
  const {t} = useTranslation(CREATE_MANUAL_TRANSLATION_KEYS);
  const {dirty, errors}: FormikOpportunityMainForm = useFormikContext();

  const handleChangeVisibleHashtag = (checked: boolean, _value?: string | undefined) => {
    setIsVisibleHashtagField?.(checked);
  };

  const isErrorsArePresent = useMemo(() => !!Object.values(errors).length, [errors]);

  return (
    <Form className={styles.form} title={t('form:createManualOpp.title')}>
      <Field
        component={Input}
        label={`${t('form:proposeManOppo.opportunityName')}:*`}
        placeholder={t('inputs:placeholders.oppoName')}
        name="opportunityName"
      />
      {/*<Field*/}
      {/*  name="opportunityDescription"*/}
      {/*  label={`${t('form:opportunity.description')}:*`}*/}
      {/*  placeholder={t('form:opportunity.describeOpportunity')}*/}
      {/*  rows={4}*/}
      {/*  component={TextEditor}*/}
      {/*/>*/}
      <Field
        name="opportunityDescription"
        label={`${t('form:opportunity.description')}:*`}
        placeholder={t('form:opportunity.describeOpportunity')}
        isShowToolbar={false}
        isLabelBold
        defaultFontSize="18px"
        component={Editor}
      />
      <HashtagField
        isVolunteer
        options={options}
        isVisibleHashtagField={isVisibleHashtagField}
        handleChangeVisibleHashtag={handleChangeVisibleHashtag}
      />
      <Field name="filePathes" label={t('form:proposeManOppo.photos')} component={ItemGallery} />
      <div className={styles.form__pickers}>
        <div className={styles.form__pickers_date}>
          <Field
            name="startDate"
            label={t('form:proposeManOppo.date')}
            component={DatePicker}
            availableFuture
            availablePast
          />
        </div>
        <div className={styles.form__pickers_time}>
          <Field name="endTime" label={t('form:proposeManOppo.totalHours')} component={TimeLimit} />
        </div>
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <ButtonDefault submitType classList={['primary', 'lg', 'center']} title={t('buttons:button.submit')} />
      )}
    </Form>
  );
};
