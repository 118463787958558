import {FC, ReactNode} from 'react';
import cx from 'classnames';
//styles
import styles from './WhiteContainer.module.scss';

type WhiteContainerProps = {
  children: ReactNode;
  title: string;
  titleStyles?: Record<string, string>;
  parentClassName?: string;
};

const WhiteContainer: FC<WhiteContainerProps> = ({
  children,
  title,
  parentClassName,
  titleStyles,
}: WhiteContainerProps) => {
  return (
    <div className={cx(styles.main, parentClassName && parentClassName)}>
      <h1 className="title" style={titleStyles}>
        {title}
      </h1>
      {children}
    </div>
  );
};

export default WhiteContainer;
