import {ChangeEvent} from 'react';
//material
import {MaterialUiPickersDate} from '@material-ui/pickers/typings/date';

export type InlineDatePickerFieldChange = {
  (e: ChangeEvent<MaterialUiPickersDate>): void;
  <T = string | ChangeEvent<MaterialUiPickersDate>>(field: T): T extends ChangeEvent<MaterialUiPickersDate>
    ? void
    : (e: string | ChangeEvent<MaterialUiPickersDate>) => void;
};

export type InlineDatePickerValue = Date | null;

export const getDateValue = (fieldValue: InlineDatePickerValue): InlineDatePickerValue =>
  fieldValue ? new Date(fieldValue) : null;
