import {createRef, FC, useContext, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import cx from 'classnames';
//redux
import {selectorGetOrgGeneralType, selectorGetOrgId} from 'redux/organization-service/selector';
import {selectorGetSmartGroupsAll} from 'redux/smartGroups-service/selector';
import {getSmartGroupsAll} from 'redux/smartGroups-service/actions';
import {selectorGetVolunteersTotal} from 'redux/volunteer-service/selector';
import {getVolunteers} from 'redux/volunteer-service/actions';
//assets
import {ReactComponent as BlueArrow} from 'assets/image/selects/blue-arrow.svg';
import {ReactComponent as WhiteArrow} from 'assets/image/selects/white-arrow.svg';
//hooks
import {useClickOutside} from 'core/customHooks';
//types
import {FieldBaseProps} from 'core/types';
import {IGroupsResponse, ORGANISATION_GENERAL_TYPES} from '@joc/api-gateway';
//components
import FieldWrapper from 'shared/inputs/FieldWrapper';
//styles
import styles from './index.module.scss';
import {PastOppoContext} from 'components/Organization/Volunteers/VolunteersPopups/VolunteerPastOpportunitiesPopup';

type SmartGroupSelectProps = FieldBaseProps;

const SmartGroupSelect: FC<SmartGroupSelectProps> = (fieldBaseProps) => {
  const {field, form} = fieldBaseProps;
  const {setFieldValue} = form;
  const isPastOppo = useContext(PastOppoContext);

  const {t} = useTranslation(['messages', 'inputs', 'oppoViewVolunteer', 'common', 'inviteColleagues']);

  const dispatch = useDispatch();

  const orgId = useSelector(selectorGetOrgId);
  const orgGeneralType = useSelector(selectorGetOrgGeneralType);
  const smartGroupsAll = useSelector(selectorGetSmartGroupsAll);
  const volunteersTotal = useSelector(selectorGetVolunteersTotal);

  const [smartFilters, setSmartFilters] = useState<Array<IGroupsResponse>>([]);

  const title = useMemo(() => {
    const groupName = smartFilters.find(
      (item) => item.id.toString().toUpperCase() === field.value?.toString().toUpperCase()
    )?.groupName;
    return groupName || '';
  }, [smartFilters, field.value]);

  const optionRef = useMemo(() => smartFilters.map(() => createRef<HTMLDivElement>()), [smartFilters]);

  const {isComponentVisible, componentRef, setIsComponentVisible} = useClickOutside({initialVisible: false});

  const optionClickHandler = (id: string) => {
    setFieldValue(field.name, id);
    setIsComponentVisible(false);
  };

  const titleClickHandler = () => {
    const valueIndex = smartFilters.findIndex(
      (item) => item.id.toString().toUpperCase() === field.value?.toString().toUpperCase()
    );
    const target = optionRef[valueIndex]?.current;
    if (target)
      // set timeout cause in a first time the scroll doesn't work
      setTimeout(() => {
        target.scrollIntoView({block: 'start'});
      }, 0);
    setIsComponentVisible(!isComponentVisible);
  };

  useEffect(() => {
    if (orgId) {
      dispatch(getSmartGroupsAll(orgId));
      !isPastOppo && dispatch(getVolunteers(orgId, {}));
    }
  }, [orgId]);

  useEffect(() => {
    const allOption = {id: 1, groupName: t('common:titles.all'), total: volunteersTotal};
    setSmartFilters([allOption, ...smartGroupsAll]);
  }, [volunteersTotal, smartGroupsAll]);

  const optionTitle = t(
    orgGeneralType === ORGANISATION_GENERAL_TYPES.SCHOOL ? 'inviteColleagues:students' : 'oppoViewVolunteer:volunteers'
  );

  return (
    <FieldWrapper {...fieldBaseProps}>
      <div
        className={cx(styles.dropdown_select, styles.coordinator, {
          [styles.dropdown_select__active]: isComponentVisible,
        })}
        ref={componentRef}
      >
        <div
          className={cx(styles.dropdown_select__title, {
            [styles.dropdown_select__title_selected]: !!title,
            [styles.disabledLikeInput]: fieldBaseProps.disabled,
          })}
          onClick={titleClickHandler}
        >
          <span>{title || t('inputs:placeholders.selectSmartGroup')}</span>
          {!isComponentVisible ? (
            <BlueArrow className={styles.dropdown_select__title__vector} />
          ) : (
            <WhiteArrow className={styles.dropdown_select__title__vector} />
          )}
        </div>
        <div className={styles.dropdown_select__options}>
          {smartFilters.map((option: IGroupsResponse, index: number) => (
            <div
              key={option.id}
              ref={optionRef[index]}
              className={styles.dropdown_select__options__item}
              onClick={() => optionClickHandler(option.id.toString())}
            >
              <div className={styles.smartGroup_optionTitle}>
                <span className={styles.smartGroup_optionTitle__name}>{option.groupName}</span>
                <span className={styles.smartGroup_optionTitle__count}>
                  {option.total} {optionTitle}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </FieldWrapper>
  );
};

export default SmartGroupSelect;
