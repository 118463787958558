import * as Yup from 'yup';
import {IYearlyGoalRequest} from '@joc/api-gateway/lib/api-client';

export const YearlyGoalsValidationSchema = Yup.object().shape({
  gradeId: Yup.number().notRequired(),
  actYearlyGoal: Yup.number()
    .max(999999, 'Input limit exceeded: Please enter a number with no more than 6 digits.')
    .notRequired(),
  hourYearlyGoal: Yup.number()
    .max(999999, 'Input limit exceeded: Please enter a number with no more than 6 digits.')
    .notRequired(),
});

export const changedGrades = (initialGrades: IYearlyGoalRequest[], gradesToCheck: IYearlyGoalRequest[]) => {
  return gradesToCheck.filter((changedGrade) => {
    const initialGrade = initialGrades.find((grade) => grade.gradeId === changedGrade.gradeId);
    return (
      initialGrade &&
      (changedGrade.gradeId !== initialGrade.gradeId ||
        changedGrade.hourYearlyGoal !== initialGrade.hourYearlyGoal ||
        changedGrade.actYearlyGoal !== initialGrade.actYearlyGoal)
    );
  });
};
