//redux
import {CREATE_SMART_GROUP, DELETE_SMART_GROUP, EDIT_SMART_GROUP, GET_SMART_GROUPS_ALL} from 'redux/actions-types';
//types
import {IGroupsResponse} from '@joc/api-gateway';
import {ISmartGroupReducer, SmartGroupsActionTypes} from './types';

const initState: ISmartGroupReducer = {
  smartGroups: [],
};

const smartGroupsReducer = (state = initState, action: SmartGroupsActionTypes): ISmartGroupReducer => {
  const {type, payload}: SmartGroupsActionTypes = action;

  switch (type) {
    case GET_SMART_GROUPS_ALL: {
      return {...state, smartGroups: (payload as Array<IGroupsResponse>).reverse()};
    }

    case CREATE_SMART_GROUP: {
      state.smartGroups.unshift({
        ...(payload as IGroupsResponse),
        total: (payload as IGroupsResponse)?.customVolunteers?.length || (payload as IGroupsResponse)?.total,
      });
      const data = state.smartGroups;
      return {
        ...state,
        smartGroups: [...data],
      };
    }

    case EDIT_SMART_GROUP: {
      return {
        ...state,
        smartGroups: state.smartGroups.map((group) =>
          group.id === (payload as IGroupsResponse).id ? (payload as IGroupsResponse) : group
        ),
      };
    }

    case DELETE_SMART_GROUP: {
      return {...state, smartGroups: state.smartGroups.filter((group) => group.id !== payload)};
    }

    default:
      return state;
  }
};
export default smartGroupsReducer;
