//types
import {TariffsResponse} from '@joc/api-gateway';

enum TARIFF_FILTER_VALUE {
  FREE = 8,
  SCHOOL = 2,
  DONATE = 1,
}

export const getSortedTariffs = (tariffs: Array<TariffsResponse>): Array<TariffsResponse> =>
  tariffs.sort((tariffA, tariffB) => tariffA.tariffCost - tariffB.tariffCost);

export const getFilteredTariffs = (
  tariffs: Array<TariffsResponse> | null,
  volunteersTotal: number = 0
): Array<TariffsResponse> | null => {
  if (!tariffs) return null;

  const isOrganisation = localStorage.getItem('organisationId');
  const isSchool = localStorage.getItem('school');

  if (isOrganisation)
    return isSchool
      ? tariffs.filter((tariff) => tariff?.id !== TARIFF_FILTER_VALUE.FREE && tariff.id === TARIFF_FILTER_VALUE.SCHOOL)
      : tariffs.filter(
          (tariff) =>
            ![TARIFF_FILTER_VALUE.FREE, TARIFF_FILTER_VALUE.SCHOOL, TARIFF_FILTER_VALUE.DONATE].includes(tariff.id) &&
            tariff.invitesAmount >= volunteersTotal
        );

  if (!isSchool && !isOrganisation) return tariffs.filter((tariff) => tariff.id !== TARIFF_FILTER_VALUE.DONATE);

  return null;
};
