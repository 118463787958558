import {createContext, useContext, Dispatch, SetStateAction} from 'react';
// constants
import {ORGANIZATION_TABS} from 'core/constants';
// interfaces
import {OrganisationTabQueryValue, SidebarVisibility} from './interfaces';

type OpportunitiesContextValues = {
  setIsClearSearch: Dispatch<SetStateAction<boolean>>;
  QueryPreset: Map<ORGANIZATION_TABS, OrganisationTabQueryValue>;
  generalTabClickHandler: () => void;
  isClearSearch: boolean;
  sidebarVisibility: SidebarVisibility;
  setSidebarVisibility: Dispatch<SetStateAction<SidebarVisibility>>;
  isSelectPastDateAllowed: boolean;
  activeTab: ORGANIZATION_TABS;
};

const OpportunitiesContext = createContext<OpportunitiesContextValues>({} as OpportunitiesContextValues);

export const OpportunitiesContextProvider = OpportunitiesContext.Provider;
export const useOpportunitiesContext = () => useContext(OpportunitiesContext);
