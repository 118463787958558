import {FC, Dispatch, SetStateAction, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Formik} from 'formik';
import firebase from 'firebase';
import qs from 'qs';
import cx from 'classnames';
//redux
import {setError} from 'redux/error-service/action';
import {getVolunteerByEmailAndPassword, getUserBySocialProvider} from 'redux/user-service/actions';
//urls
import {urls} from 'core/appUrls';
//functions
import {getLoginError} from 'core/functions/firebase/firebaseSignin';
//form configuration
import {LoginFormVValues, initialValues, validationSchema} from './formConfiguration';
//types
import {LOGIN_ERROR, SocialProvider} from 'core/types/index';
import {ClaimJoinParsedQs} from 'core/types/queryStrings';
//components
import LoginVolunteerForm from './LoginVolunteerForm';
//styles
import styles from './LoginFormV.module.scss';

type LoginFormVParentProps = {
  setLoginError: Dispatch<SetStateAction<LOGIN_ERROR>>;
  setSocialProviders: Dispatch<SetStateAction<SocialProvider | undefined>>;
};

const LoginFormV: FC<LoginFormVParentProps> = ({setLoginError, setSocialProviders}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [socialProviders] = useState<SocialProvider>({
    facebookProvider: new firebase.auth.FacebookAuthProvider().setCustomParameters({
      prompt: 'select_account',
    }),
    googleProvider: new firebase.auth.GoogleAuthProvider().setCustomParameters({
      prompt: 'select_account',
    }),
    appleProvider: new firebase.auth.OAuthProvider('apple.com').setCustomParameters({
      prompt: 'select_account',
    }),
  });

  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const {t} = useTranslation(['errors', 'login']);

  const parsedQs = qs.parse(location.search, {ignoreQueryPrefix: true}) as ClaimJoinParsedQs;

  const loginSocial = async (provider: firebase.auth.AuthProvider | undefined): Promise<void> => {
    setIsLoading(true);
    try {
      if (!provider) {
        throw new Error(t('errors:error'));
      }

      await dispatch(getUserBySocialProvider(provider));

      if (parsedQs.organisationId) {
        history.push(`${urls.onboardingClaimJoin}${location.search}`);
      } else {
        history.push(urls.main);
      }
    } catch (error) {
      const login = getLoginError(error);

      const isNotFound =
        login === LOGIN_ERROR.NOT_FOUND ||
        error?.response?.code === 404 ||
        error?.response?.code === 400 ||
        error?.response?.code === 422;

      if (isNotFound) {
        setLoginError(LOGIN_ERROR.NOT_FOUND);
      }

      if (login === LOGIN_ERROR.WRONG_PASSWORD) {
        setLoginError(login);
      }

      if (login === LOGIN_ERROR.INTERRUPTED) {
        setLoginError(login);
      } else {
        dispatch(setError(error?.response?.message || error.message, error.code));
      }
    } finally {
      setIsLoading(false);
    }
  };

  const submitClickHandler = async (values: LoginFormVValues) => {
    setIsLoading(true);
    try {
      const providersResponse = await firebase.auth().fetchSignInMethodsForEmail(values.email);
      if (!providersResponse.length) {
        setLoginError(LOGIN_ERROR.NOT_FOUND);
        return;
      }
      if (!providersResponse.includes('password')) {
        setLoginError(LOGIN_ERROR.WITHOUT_PASSWORD);
        if (providersResponse.includes('google.com'))
          setSocialProviders({
            ...socialProviders,
            googleProvider: new firebase.auth.GoogleAuthProvider().setCustomParameters({
              prompt: 'select_account',
            }),
          });
        if (providersResponse.includes('facebook.com'))
          setSocialProviders({
            ...socialProviders,
            facebookProvider: new firebase.auth.FacebookAuthProvider().setCustomParameters({
              prompt: 'select_account',
            }),
          });
        if (providersResponse.includes('apple.com'))
          setSocialProviders({
            ...socialProviders,
            appleProvider: new firebase.auth.OAuthProvider('apple.com').setCustomParameters({
              prompt: 'select_account',
            }),
          });
        throw new Error('Your account hasn`t got password');
      }
      await dispatch(getVolunteerByEmailAndPassword(values.email, values.password));
      setIsLoading(false);

      if (parsedQs.organisationId) {
        history.push(`${urls.onboardingClaimJoin}${location.search}`);
      } else {
        history.push(urls.main);
      }
    } catch (error) {
      const loginError = getLoginError(error);

      if (loginError === LOGIN_ERROR.WRONG_PASSWORD) {
        setLoginError(loginError);
        setIsLoading(false);
      }

      if (loginError === LOGIN_ERROR.TOO_MANY_REQUESTS) {
        setLoginError(LOGIN_ERROR.TOO_MANY_REQUESTS);
        setIsLoading(false);
      }

      if (
        loginError === LOGIN_ERROR.NOT_FOUND ||
        error?.response?.code === 404 ||
        error?.response?.code === 400 ||
        error?.response?.code === 422
      ) {
        setLoginError(LOGIN_ERROR.NOT_FOUND);
        setIsLoading(false);
      }
      if (loginError === LOGIN_ERROR.INTERRUPTED) {
        setLoginError(loginError);
        setIsLoading(false);
      } else {
        dispatch(setError(error?.response?.message || error.message, error.code));
        setIsLoading(false);
      }
    }
  };

  return (
    <div className={styles.container}>
      <h1 className={cx('title', styles.title)}>{t('login:welcome')}</h1>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={submitClickHandler}
      >
        <LoginVolunteerForm isLoading={isLoading} loginSocial={loginSocial} socialProviders={socialProviders} />
      </Formik>
    </div>
  );
};

export default LoginFormV;
