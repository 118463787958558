import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {OPPORTUNITY_TYPES} from '@joc/api-gateway';
import {Field, FieldProps} from 'formik';
import cx from 'classnames';
// components
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
//styles
import styles from '../OpportunitiesFiltersForm.module.scss';

interface IByWhoFilterProps {
  schoolId?: number;
}

export const ByWhoFilter: FC<IByWhoFilterProps> = ({schoolId}) => {
  const {t} = useTranslation('form');

  return (
    <>
      <label className={styles.label}>{t('opportunity.byWho')}</label>
      <div className={cx(styles.form__opportunitie_address, styles.form__oppo_type)}>
        <Field
          type="checkbox"
          name="opportunityTypesList"
          value={schoolId ? OPPORTUNITY_TYPES.PRIVATE : `${OPPORTUNITY_TYPES.PRIVATE}, ${OPPORTUNITY_TYPES.PUBLIC}`}
        >
          {({field}: FieldProps) => (
            <label htmlFor={`${field.name}2`} className={styles.label_for_button}>
              <ButtonDefault
                classList={['secondary', 'no_click', field.checked ? 'secondary_active' : '']}
                title={schoolId ? t('filterByWho.school') : t('filterByWho.org')}
              />
              <input type="checkbox" {...field} id={`${field.name}2`} />
            </label>
          )}
        </Field>
        <Field type="checkbox" name="opportunityTypesList" value="MANUAL">
          {({field}: FieldProps) => (
            <label htmlFor={`${field.name}3`} className={styles.label_for_button}>
              <ButtonDefault
                classList={['secondary', 'no_click', field.checked ? 'secondary_active' : '']}
                title={schoolId ? t('filterByWho.student') : t('filterByWho.vol')}
              />
              <input type="checkbox" {...field} id={`${field.name}3`} />
            </label>
          )}
        </Field>
      </div>
    </>
  );
};
