//API
import {API} from 'core/API';
//types
import {IGroupLocationsRequest, ISearchAddressesByTextResponse} from '@joc/api-gateway';
import {GroupType} from 'core/types';

export const generateLocationText = ({countryName, stateName, cityName}: ISearchAddressesByTextResponse) => {
  let result = '';
  if (countryName) result += countryName;
  if (stateName) result += `, ${stateName}`;
  if (cityName) result += `, ${cityName}`;
  return result;
};

export const getLocationFromId = async (id: number, type: string) => {
  const response = type === 'state' ? await API.getState(id) : await API.getCity(id);
  return generateLocationText(response as ISearchAddressesByTextResponse);
};

export const getNewFilterTypes = (groupFilters: Array<GroupType>, filterId: number, address: IGroupLocationsRequest) =>
  groupFilters.map((filter) => (filter.id === filterId ? {...filter, data: [address]} : filter));
