// react
import {FC, useMemo} from 'react';
// translation
import {useTranslation} from 'react-i18next';
// components
import ImageWithPopup from 'shared/components/ImageWithPopup';
//styles
import styles from './index.module.scss';
import VideoPreviewSquare from '../../../../shared/components/VideoPreviewSquare';

interface IFeedDetailsImagesProps {
  links: string[];
}

export const FeedDetailsAttachments: FC<IFeedDetailsImagesProps> = ({links}) => {
  const {t} = useTranslation(['popup']);

  const opportunityAttachments = useMemo(() => {
    return links.reduce(
      (acc, link) => {
        if (
          link
            .slice(link.length - 4)
            .toLowerCase()
            .includes('.mp4')
        ) {
          acc.videos.push(link);
        } else {
          acc.images.push(link);
        }

        return acc;
      },
      {images: [], videos: []} as {images: string[]; videos: string[]}
    );
  }, [links]);

  return (
    <div className={styles.description__imageBlock}>
      {opportunityAttachments.images.map((imagePath, index) => {
        return (
          <div key={index} className={styles.description__imageBlock_image}>
            <ImageWithPopup
              imagePath={imagePath}
              alt="opportunity logo"
              classNames={styles.image_item}
              popupTitle={t('popup:opportunity.pictureOfOppo')}
            />
          </div>
        );
      })}
      {opportunityAttachments.videos.map((link, index) => (
        <VideoPreviewSquare
          parrentClassName={styles.video}
          smallerActionButton
          key={index}
          link={`${process.env.REACT_APP_S3_API}/${link}`}
        />
      ))}
    </div>
  );
};
