import {FC} from 'react';
import {MARKETING_TABS} from '../enums';
import {IMarketingTabsConfig} from '../interfaces';
import cx from 'classnames';
import TabNavItem from '../../../../shared/components/TabNav/TabNavItem';
import TabNav from '../../../../shared/components/TabNav';

type MarketingTabsProps = {
  tabsConfig: IMarketingTabsConfig[];
  activeTab: MARKETING_TABS;
  handleActiveTab: (value: MARKETING_TABS) => void;
  parentClassName?: string;
  parentTabItemClassName?: string;
};

const MarketingTabs: FC<MarketingTabsProps> = ({
  tabsConfig,
  handleActiveTab,
  activeTab,
  parentClassName,
  parentTabItemClassName,
}) => {
  return (
    <TabNav
      className={cx('oppoNav', {
        [parentClassName as string]: !!parentClassName,
      })}
    >
      {tabsConfig?.map(({title, counter, currentTab}) => (
        <TabNavItem
          key={title}
          title={title}
          counter={counter}
          activeTab={activeTab === currentTab}
          clickHandler={() => handleActiveTab(currentTab)}
          parentClassName={parentTabItemClassName}
        />
      ))}
    </TabNav>
  );
};

export default MarketingTabs;
