//functions
import {getTwoDigitNumber} from 'core/functions';

export const getVolunteerWorkHours = (timeInSeconds: number) => {
  const HOUR_IN_SECONDS = 3600;
  const MINUTE_IN_SECONDS = 60;

  const timeInHours = timeInSeconds / HOUR_IN_SECONDS;

  const hours = Math.floor(timeInHours);
  const minutes = Math.floor((timeInHours - hours) * MINUTE_IN_SECONDS);

  return `${getTwoDigitNumber(hours)}:${getTwoDigitNumber(minutes)}`;
};
