//types
import {InvitedUserWithRole} from 'core/types';

export const userInfoKeys: Array<keyof InvitedUserWithRole> = [
  'firstName',
  'lastName',
  'email',
  'phoneNumber',
  'birthDate',
  'genderId',
  'timeSpend',
  'gradeId',
  'positionId',
  'address',
  'roleName',
  'studentId',
];

export enum UserInfoKeyNamesInExcelSheet {
  firstName = 'firstName',
  lastName = 'lastName',
  email = 'email',
  phoneNumber = 'phoneNumber',
  birthDate = 'birthDate',
  genderId = 'gender',
  timeSpend = 'previousCompletedHours',
  gradeId = 'schoolGrade',
  positionId = 'positionId',
  address = 'address',
  roleName = 'role',
  studentId = 'studentId',
}
