export const INITIAL_INVITE_COLLEAGUES = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  positionId: 2,
  roleId: 0,
};

export const INITIAL_INVITE_COLLEAGUES_BY_ADDING = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  positionId: 2,
  roleId: 0,
  password: '',
};
