import {FC, Fragment, useState} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router';
//core
import {urls} from 'core/appUrls';
//redux
import {Store} from 'redux/root';
import {selectorGetError} from 'redux/error-service/selector';
import {resetError} from 'redux/error-service/action';
//components
import ResponseFailure from 'shared/components/ResponseFailure';
import CongratsPopup from 'components/Organization/CongratsPopup';
import WhiteContainer from 'shared/components/WhiteContainer';
import ArrowBackLink from 'shared/components/ArrowBackLink';
import EditVolunteerForm from 'components/Volunteer/EditVolunteerForm';
//styles
import styles from './EditVolunteer.module.scss';

const EditVolunteer: FC<EditVolunteerProps> = ({error, resetError}: EditVolunteerProps) => {
  const history = useHistory();

  const [isUpdateSuccess, setIsUpdateSuccess] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);

  const {t} = useTranslation(['messages', 'errors', 'form']);

  const handleDoneButtonClickCongratsHandler = () => {
    setIsUpdateSuccess(false);
    setIsUpdated(true);

    //update the url if we switched from the forced update popup AddUserBirthdayPopup;
    history.push(urls.volEditAccount);
  };

  return (
    <Fragment>
      <ArrowBackLink parentClassName={styles.nav} goToStep={isUpdated ? -2 : -1} />

      {error.state ? (
        <ResponseFailure
          message={error.message}
          buttonClickHandler={resetError}
          buttonTitle={t('errors:checkAndTryAgain')}
          styleTable
        />
      ) : isUpdateSuccess ? (
        <CongratsPopup
          subtitle={t('messages:accountHasBeenUpdatedSuccessfully')}
          doneButtonClickHandler={handleDoneButtonClickCongratsHandler}
        />
      ) : (
        <WhiteContainer title={t('form:editVolunteer.title')}>
          <EditVolunteerForm setIsUpdateSuccess={setIsUpdateSuccess} />
        </WhiteContainer>
      )}
    </Fragment>
  );
};

const mapStateToProps = (store: Store) => ({
  error: selectorGetError(store),
});

const mapDispatchToProps = {
  resetError,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type EditVolunteerProps = ConnectedProps<typeof connector>;

export default connector(EditVolunteer);
