import {WITH_WHO} from '@joc/api-gateway';

export const EDIT_ADMIN_MANUAL_OPPORTUNITY_INIT_VALUES = {
  user: null,
  endTime: '',
  imagePath: '',
  filePathes: [],
  gender: undefined,
  opportunityName: '',
  isApprovalRequired: false,
  opportunityDescription: '',
  withWho: [WITH_WHO.BABIES, WITH_WHO.OTHER, WITH_WHO.ADULTS, WITH_WHO.SENIORS, WITH_WHO.KIDS, WITH_WHO.TEENS],
};
