import {FC, useContext, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router';
import {useTranslation} from 'react-i18next';
import qs from 'qs';
import cx from 'classnames';
//redux
import {editSmartGroup} from 'redux/smartGroups-service/actions';
import {selectorGetSmartGroupsAll} from 'redux/smartGroups-service/selector';
import {selectorGetOrgId, selectorGetSchoolId} from 'redux/organization-service/selector';
//context
import {VolunteersPageContext} from 'pages/Organization/Volunteers';
//form configuration
import {SmartGroupFormValues, validationSchema} from '../formConfiguration';
//helpers
import {getCurrentInitialValues} from './helpers';
import {getGroupsRequestFromSmartGroupFormValues} from '../helpers';
//components
import {Formik} from 'formik';
import ResponseFailure from 'shared/components/ResponseFailure';
import SmartGroupForm from '../SmartGroupForm';
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
import ButtonUpToTop from 'shared/components/Buttons/ButtonUpToTop';
//styles
import styles from '../styles.module.scss';

const SmartGroupEdit: FC = () => {
  const {t} = useTranslation(['volunteers', 'messages', 'buttons']);

  const dispatch = useDispatch();

  const {search} = useLocation();

  const {groupId} = qs.parse(search, {ignoreQueryPrefix: true});

  const {setIsEditingSmartGroup} = useContext(VolunteersPageContext);

  const organizationId = useSelector(selectorGetOrgId);
  const schoolId = useSelector(selectorGetSchoolId);
  const groupsAll = useSelector(selectorGetSmartGroupsAll);

  const currentInitialValues = useMemo(() => getCurrentInitialValues(groupsAll, +groupId!), [groupsAll, groupId]);

  const [isShowCongratulations, setIsShowCongratulations] = useState(false);

  const submitClickHandler = (values: SmartGroupFormValues) => {
    const groupRequest = getGroupsRequestFromSmartGroupFormValues(values, organizationId, schoolId?.toString());
    dispatch(editSmartGroup(+groupId!, groupRequest));
    setIsShowCongratulations(true);
  };

  const doneClickHandler = () => {
    setIsEditingSmartGroup(false);
    setIsShowCongratulations(false);
  };

  const cancelClickHandler = () => {
    setIsEditingSmartGroup(false);
  };

  if (isShowCongratulations)
    return (
      <ResponseFailure
        styleCongrats
        title={t('messages:congratulations')}
        message={t('messages:smartGroupSuccessfullyEdited')}
        buttonTitle={t('buttons:button.done')}
        buttonClickHandler={doneClickHandler}
      />
    );

  return (
    <div className={cx(styles.wrapper, 'smartGroupWrapperRef')}>
      <h2 className={cx(styles.title, 'title')}>{`${t('volunteers:editSmartFilter.title')}: ${
        currentInitialValues.groupName
      }`}</h2>
      <Formik
        initialValues={currentInitialValues}
        validationSchema={validationSchema}
        onSubmit={submitClickHandler}
        enableReinitialize
        validateOnMount
      >
        <SmartGroupForm />
      </Formik>
      <ButtonDefault
        classList={['secondary', 'lg']}
        title={t('buttons:button.cancel')}
        clickHandler={cancelClickHandler}
      />
      <ButtonUpToTop containerSelector="smartGroupWrapperRef" isSmartGroup />
    </div>
  );
};

export default SmartGroupEdit;
