import {FC} from 'react';
import cx from 'classnames';
//components
import TabNavItemActions from './TabNavItemActions';
//styles
import styles from './TabNavItem.module.scss';

type TabNavItemPropsType = {
  counter?: number | null;
  title: string;
  activeTab?: boolean;
  clickHandler: () => void;
  parentClassName?: string;
  isSmartGroup?: boolean;
  isSignupRequests?: boolean;
  groupId?: number;
};

const TabNavItem: FC<TabNavItemPropsType> = ({
  title,
  counter,
  clickHandler,
  activeTab,
  parentClassName,
  isSmartGroup,
  isSignupRequests,
  groupId,
}: TabNavItemPropsType) => {
  return (
    <div
      className={cx(styles.item, activeTab && styles.active, parentClassName && parentClassName, {
        [styles.smart_group]: isSmartGroup,
      })}
      onClick={clickHandler}
    >
      {isSmartGroup && <TabNavItemActions groupId={groupId} />}
      <span
        className={cx(styles.title, {[styles.smart_group]: isSmartGroup, [styles.signup_requests]: isSignupRequests})}
      >
        {title}
      </span>
      {(!!counter || counter === 0) && <div className={styles.item__counter}>{counter}</div>}
    </div>
  );
};

export default TabNavItem;
