import {
  FullTextSearchOpportunity,
  PaginationRequest,
  SearchOpportunitiesSort,
  SEARCH_OPPORTUNITIES_POSSIBLE_SORT,
  SortDirection,
} from '@joc/api-gateway';
import {
  RESET_FILTER,
  SET_FILTER,
  SET_PAGINATION,
  SET_SEARCH,
  SET_SORT,
  SET_TAB_FILTER,
  SET_VOLUNTEER_TAB_FILTER,
} from 'redux/actions-types';
import {FilterActionTypes, ISearchOppoRequest} from './types';

const initState = {
  pagination: PaginationRequest.fromJS({skip: 0, take: 10}),
  sort: SearchOpportunitiesSort.fromJS({
    sortBy: SEARCH_OPPORTUNITIES_POSSIBLE_SORT.StartDayStartTime,
    sortDir: SortDirection.DESC,
  }),
  volunteerStatus: [],
  status: [],
  opportunityTypesList: [],
  withWho: [],
  weekDays: [],
  startTime: null,
  endTime: null,
  fromDate: null,
  toDate: null,
  fullTextSearch: FullTextSearchOpportunity.fromJS({
    fields: ['opportunityName', 'opportunityDescription', 'opportunityUrl', 'addressCoordinator'],
    value: '',
  }),
};

const filterReducer = (state = initState, action: FilterActionTypes): ISearchOppoRequest => {
  const {type, payload}: FilterActionTypes = action;
  switch (type) {
    case SET_FILTER: {
      const fullTextSearch = FullTextSearchOpportunity.fromJS({
        ...payload,
        fullTextSearch: {...payload.fullTextSearch, value: undefined},
      });

      return {
        ...state,
        ...payload,
        fullTextSearch,
      };
    }
    case SET_TAB_FILTER: {
      const fullTextSearch = FullTextSearchOpportunity.fromJS({
        ...payload,
        fullTextSearch: {...payload.fullTextSearch, value: undefined},
      });
      return {
        ...initState,
        ...payload,
        fullTextSearch,
      };
    }
    case SET_VOLUNTEER_TAB_FILTER: {
      return {
        ...initState,
        ...payload,
        fullTextSearch: state.fullTextSearch,
      };
    }
    case SET_PAGINATION: {
      return {...state, pagination: PaginationRequest.fromJS({skip: payload, take: 10})};
    }
    case SET_SORT: {
      return {...state, sort: SearchOpportunitiesSort.fromJS(payload)};
    }
    case SET_SEARCH: {
      const fullTextSearch = FullTextSearchOpportunity.fromJS({
        ...payload,
        fullTextSearch: {...payload, value: payload.value === '' ? undefined : payload.value},
      });

      return {
        ...state,
        fullTextSearch,
        pagination: initState.pagination,
      };
    }
    case RESET_FILTER:
      return initState;
    default:
      return state;
  }
};

export default filterReducer;
