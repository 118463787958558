import {FC} from 'react';
import {useTranslation} from 'react-i18next';
//types
import {IInviteUserRequest, USER_ORG_ROLES} from '@joc/api-gateway';
import {InvitedUserColleagues} from 'core/types';
//constants
import {TABLE_HEADERS, TABLE_ITEMS_CLASSNAMES} from 'core/constants';
import TABLE_STICKY_HEADER_CLASSNAMES from 'core/constants/TABLE_STICKY_HEADER_CLASSNAMES';
//components
import CellDefault from 'shared/components/Table/CellRenderers/CellDefault';
import FullNameCell from 'shared/components/Table/CellRenderers/FullNameCell';
import TableBody from 'shared/components/Table/TableBody';
import TableHead from 'shared/components/Table/TableHead';
import TableHeadCell from 'shared/components/Table/TableHead/TableHeadCell';
import TableMain from 'shared/components/Table/TableMain';
import TableRow from 'shared/components/Table/TableRow';
import StickyHeader from 'shared/components/Table/TableHead/StickyHeader';

type InvitedColleaguesTableProps = {
  colleagues: Array<IInviteUserRequest>;
};

const InvitedColleaguesTable: FC<InvitedColleaguesTableProps> = ({colleagues}: InvitedColleaguesTableProps) => {
  const {t} = useTranslation('members');

  const getTranslatedRoleName = (roleName: string | undefined) => {
    if (!roleName) return '-';
    switch (roleName.toUpperCase()) {
      case USER_ORG_ROLES.ADMIN:
        return t('roles.admin');

      case USER_ORG_ROLES.MARKETER:
        return t('roles.marketer');

      case USER_ORG_ROLES.COORDINATOR:
        return t('roles.coordinator');

      case USER_ORG_ROLES.VOLUNTEER:
        return t('roles.volunteer');

      default:
        return '-';
    }
  };

  return (
    <TableMain>
      <StickyHeader headerFor={TABLE_STICKY_HEADER_CLASSNAMES.invitedUsers}>
        <TableHead>
          <TableHeadCell itemClassName={TABLE_ITEMS_CLASSNAMES.fullName} text={TABLE_HEADERS.fullName} />
          <TableHeadCell itemClassName={TABLE_ITEMS_CLASSNAMES.email} text={TABLE_HEADERS.email} />
          <TableHeadCell itemClassName={TABLE_ITEMS_CLASSNAMES.phone} text={TABLE_HEADERS.phoneNumber} />
          <TableHeadCell itemClassName={TABLE_ITEMS_CLASSNAMES.role} text={TABLE_HEADERS.role} />
          {/* <TableHeadCell itemClassName={TABLE_ITEMS_CLASSNAMES.position} text="Position" isSortDisabled /> */}
        </TableHead>
      </StickyHeader>
      <TableBody>
        {colleagues.map((volunteer: IInviteUserRequest | InvitedUserColleagues, idx) => (
          <TableRow key={idx}>
            <FullNameCell firstName={volunteer.firstName} lastName={volunteer.lastName} />
            <CellDefault parentClassName={TABLE_ITEMS_CLASSNAMES.email} text={volunteer.email} />
            <CellDefault parentClassName={TABLE_ITEMS_CLASSNAMES.phone} text={volunteer.phoneNumber} />
            <CellDefault
              parentClassName={TABLE_ITEMS_CLASSNAMES.role}
              text={getTranslatedRoleName((volunteer as InvitedUserColleagues)?.roleName)}
            />
            {/* <CellDefault parentClassName={TABLE_ITEMS_CLASSNAMES.position} text={volunteer.positionId} /> */}
          </TableRow>
        ))}
      </TableBody>
    </TableMain>
  );
};

export default InvitedColleaguesTable;
