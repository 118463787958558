import qs from 'qs';
//urls
import {urls} from 'core/appUrls';
//types
import {IRolesResponse, VOLUNTEER} from '@joc/api-gateway';
//constants
import {ORGANIZATION_TABS} from 'core/constants';

export const getRedirectUrlWithTabHelper = (tab: ORGANIZATION_TABS): string => {
  const tabValue = qs.stringify({tab});

  return `${urls.orgOpportunities}?${tabValue}`;
};

export const getIsUserAdminOrCoordinator = (userRoles: Array<IRolesResponse> | undefined): IRolesResponse | null =>
  userRoles?.find((userRole) => userRole.roleName === VOLUNTEER.ADMIN || userRole.roleName === VOLUNTEER.COORDINATOR) ||
  null;

export const getOrganisationInfo = (
  volunteersTotal: number,
  volunteersCountByTariff: number,
  payGateStatus: boolean
): string => (payGateStatus ? `${volunteersTotal} / ${volunteersCountByTariff}` : `${volunteersTotal}`);
