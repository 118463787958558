import {FC} from 'react';
import cx from 'classnames';
//styles
import styles from './index.module.scss';

export interface IVolunteerLimitProps {
  maxVolunteers: number;
  currentVolunteers: number;
  isCentrePosition?: boolean;
}

export const VolunteerLimit: FC<IVolunteerLimitProps> = ({maxVolunteers, currentVolunteers, isCentrePosition}) => {
  return (
    <div className={cx(styles.limit, isCentrePosition && styles.centered)}>
      <span className={styles.limit__text}>{`${currentVolunteers}/${maxVolunteers} max`}</span>
    </div>
  );
};
