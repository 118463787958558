import {ChangeEvent, FC, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
//helpers
import {getIsValidAmount, getIsValidAmountType} from './helpers';
//components
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
import InputErrorHint from 'shared/components/InputErrorHint';
//styles
import styles from './CustomDonation.module.scss';

type CustomDonationProps = {
  submitHandler: (amount: number) => () => void;
};

const CustomDonation: FC<CustomDonationProps> = ({submitHandler}) => {
  const {t} = useTranslation(['inputs', 'validation']);

  const [amount, setAmount] = useState('1');

  const isValidAmountType = useMemo(() => getIsValidAmountType(amount), [amount]);
  const isValidAmount = useMemo(() => isValidAmountType && getIsValidAmount(amount), [amount, isValidAmountType]);
  const areValidationErrors = useMemo(() => !isValidAmountType || !isValidAmount, [isValidAmountType, isValidAmount]);
  const validationErrorMessage = useMemo(
    () => t(`validation:donate.${!isValidAmountType ? 'invalidAmountType' : 'invalidAmount'}`),
    [isValidAmountType, isValidAmount]
  );

  const ref = useRef<HTMLInputElement>(null);

  const setFocus = (): void => {
    if (ref && ref.current) ref.current.focus();
  };

  const inputChangeHandler = (ev: ChangeEvent<HTMLInputElement>) => {
    const newAmount = ev.target.value;
    const [, decimalPlaces] = newAmount.split('.');

    const isValid = !decimalPlaces || (decimalPlaces && decimalPlaces.length <= 2);

    if (isValid) setAmount(ev.target.value);
  };

  return (
    <>
      <div className={styles.input__text}>
        <input ref={ref} value={amount} onChange={inputChangeHandler} />
        {amount !== undefined && !areValidationErrors && <div className={styles.input__text_edit} onClick={setFocus} />}
        {areValidationErrors && <InputErrorHint errorText={validationErrorMessage} />}
      </div>
      <ButtonDefault
        clickHandler={submitHandler(+amount)}
        title={t('inputs:placeholders.myVariant')}
        classList={['primary', 'lg']}
        disabled={areValidationErrors}
      />
    </>
  );
};

export default CustomDonation;
