import {FC, useState} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {useTranslation} from 'react-i18next';
//redux
import {Store} from 'redux/root';
import {selectorGetOrgId} from 'redux/organization-service/selector';
import {setCurrentOrgVideo, unsetCurrentOrgVideo, deleteOrgVideo} from 'redux/organization-service/actions';
//components
import PopupSmallWhite from 'shared/components/PopupSmallWhite';
//styles
import styles from './VideoActionsPopup.module.scss';

type VideoActionsPopupParentProps = {
  videoId: number;
  disabled: boolean;
};

const VideoActionsPopup: FC<VideoActionsPopupProps> = ({
  setCurrentOrgVideo,
  unsetCurrentOrgVideo,
  deleteOrgVideo,
  orgId,
  videoId,
  disabled,
}: VideoActionsPopupProps) => {
  const [isShowPopup, setIsShowPopup] = useState(false);

  const {t} = useTranslation(['popup', 'buttons']);

  return (
    <PopupSmallWhite
      parentClassName={styles.actions}
      isShowPopup={isShowPopup}
      withButton
      popupClickHandler={() => (!disabled ? setIsShowPopup(!isShowPopup) : setIsShowPopup(false))}
    >
      {orgId && (
        <div
          className={styles.action}
          onClick={() => {
            setCurrentOrgVideo(videoId);
            setIsShowPopup(false);
          }}
        >
          {t('popup:videoActions.setAsActive')}
        </div>
      )}
      {orgId && (
        <div
          className={styles.action}
          onClick={() => {
            unsetCurrentOrgVideo();
            setIsShowPopup(false);
          }}
        >
          {t('popup:videoActions.unsetAsActive')}
        </div>
      )}
      {orgId && (
        <div
          className={styles.action}
          onClick={() => {
            deleteOrgVideo(videoId);
            setIsShowPopup(false);
          }}
        >
          {t('buttons:button.delete')}
        </div>
      )}
    </PopupSmallWhite>
  );
};

const mapStateToProps = (store: Store) => ({
  orgId: selectorGetOrgId(store),
});
const mapDispatchToProps = {
  setCurrentOrgVideo,
  unsetCurrentOrgVideo,
  deleteOrgVideo,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type VideoActionsPopupProps = ConnectedProps<typeof connector> & VideoActionsPopupParentProps;

export default connector(VideoActionsPopup);
