import {FC} from 'react';
import {useHistory} from 'react-router';
//translation
import {useTranslation} from 'react-i18next';
//components
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
import AppealDefaultRenderer from 'shared/components/Appeal/DefaultRenderer';
//urls
import {urls} from 'core/appUrls';
//styles
import styles from './cookiepage.module.scss';

const SimpleCongrat: FC = () => {
  const history = useHistory();

  const {t} = useTranslation(['messages', 'buttons']);

  return (
    <AppealDefaultRenderer styleCongrats>
      <div className={styles.appeal}>
        <h1 className="title">{t('messages:congratulations')}</h1>

        <ButtonDefault
          classList={['primary', 'default']}
          title={t('buttons:button.goToLogin')}
          clickHandler={() => history.push(urls.onboardingChooseUserType)}
        />
      </div>
    </AppealDefaultRenderer>
  );
};

export default SimpleCongrat;
