import {FC} from 'react';
import cx from 'classnames';
import {useHistory} from 'react-router';
//translation
import {useTranslation} from 'react-i18next';
//components
import Appeal from 'shared/components/Appeal/DefaultRenderer';
import OnboardingContentContainer from 'shared/components/OnboardingContentContainer';
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
//styles
import styles from './NotFound.module.scss';

const NotFound: FC = () => {
  const history = useHistory();

  const {t} = useTranslation('buttons');

  return (
    <OnboardingContentContainer disableOnboardingLogoLink>
      <Appeal styleThink isHeightDisable>
        <div className={cx('title', styles.title)}>404 Not found</div>
        <ButtonDefault
          classList={['lg', 'primary']}
          parentClassName={styles.button}
          title={t('button.back')}
          clickHandler={() => history.go(-1)}
        />
      </Appeal>
    </OnboardingContentContainer>
  );
};

export default NotFound;
