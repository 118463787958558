import {FC, useContext, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import cx from 'classnames';
//redux
import {selectorGetSchoolId} from 'redux/organization-service/selector';
//components
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
import SelectableVolunteersTable from './SelectableVolunteersTable/Table';
//styles
import styles from './styles.module.scss';
import {FieldProps} from 'formik';
import {SmartGroupFormValues} from '../../formConfiguration';

type VolunteersControlProps = FieldProps<SmartGroupFormValues['volunteers']>;

const VolunteersControl: FC<VolunteersControlProps> = ({field}) => {
  const schoolId = useSelector(selectorGetSchoolId);

  const [isShowSelectableVolunteersTable, setIsShowSelectableVolunteersTable] = useState(false);
  const [checkedVolunteersId, setCheckedVolunteersId] = useState<Array<number>>([]);

  const {t} = useTranslation(['volunteers', 'inviteColleagues', 'buttons']);

  useEffect(() => {
    if (field.value) setCheckedVolunteersId(field.value);
  }, []);

  return (
    <div
      className={cx(styles.container, {
        [styles.container_tableMode]: isShowSelectableVolunteersTable,
      })}
    >
      <div className={styles.container__field}>
        {!isShowSelectableVolunteersTable && !!checkedVolunteersId?.length && (
          <div className={styles.fieldInfo}>
            {checkedVolunteersId.length}
            {checkedVolunteersId.length > 1
              ? !!schoolId
                ? ` ${t('inviteColleagues:students')} `
                : ` ${t('inviteColleagues:volunteers')} `
              : !!schoolId
              ? ` ${t('inviteColleagues:student')} `
              : ` ${t('inviteColleagues:volunteer')} `}
            {t('volunteers:selected')}
          </div>
        )}

        {!isShowSelectableVolunteersTable && (
          <ButtonDefault
            title={
              field.value?.length
                ? `${t('buttons:button.view')} / ${t('buttons:button.edit')}`
                : !!schoolId
                ? t('volunteers:createNewSmartFilter.selectStudents')
                : t('volunteers:createNewSmartFilter.selectVolunteers')
            }
            classList={['secondary', 'lg']}
            clickHandler={() => setIsShowSelectableVolunteersTable(true)}
          />
        )}
      </div>
      {isShowSelectableVolunteersTable && (
        <SelectableVolunteersTable
          isEditSmartGroup={!!field.value?.length}
          setIsShowSelectableVolunteersTable={setIsShowSelectableVolunteersTable}
          checkedVolunteersId={checkedVolunteersId}
          setCheckedVolunteersId={setCheckedVolunteersId}
        />
      )}
    </div>
  );
};

export default VolunteersControl;
