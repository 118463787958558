export const scrollToElementById = (ref: string) => {
  const element = document.querySelector(`[id="${ref}"]`);
  if (element) {
    element.classList.add('errorBorder');
    element.scrollIntoView({behavior: 'smooth'});
  }
};

export const removeErrorBorderClassById = (ref: string) => {
  const element = document.querySelector(`[id="${ref}"]`);
  if (element && element.classList.contains('errorBorder')) element.classList.remove('errorBorder');
};
