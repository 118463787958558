import {BaseSyntheticEvent, FC, Fragment, useState} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {useTranslation} from 'react-i18next';
import cx from 'classnames';
//API
import {ORGANISATION_ACTIVE_STATUS} from '@joc/api-gateway';
//redux
import {Store} from 'redux/root';
import {selectorGetOrgActiveStatus, selectorGetOrgId} from 'redux/organization-service/selector';
import {deleteSmartGroup} from 'redux/smartGroups-service/actions';
//assets
import ButtonCross from 'assets/image/cross-button.svg';
//components
import PopupConfirmation from 'shared/components/PopupConfirmation';
//styles
import styles from './TabNavItemActions.module.scss';

type TabNavItemActionsParentProps = {
  groupId: number | undefined;
};

const TabNavItemActions: FC<TabNavItemActionsProps> = ({
  groupId,
  deleteSmartGroup,
  orgId,
  orgStatus,
}: TabNavItemActionsProps) => {
  const {t} = useTranslation(['messages']);

  const [isShowPopup, setIsShowPopup] = useState(false);

  const cancelClickHandler = (e: BaseSyntheticEvent) => {
    e.stopPropagation();
    setIsShowPopup(false);
  };

  const confirmClickHandler = (e: BaseSyntheticEvent) => {
    e.stopPropagation();
    if (orgId) deleteSmartGroup(groupId, orgId);
  };

  const deleteClickHandler = (e: BaseSyntheticEvent) => {
    e.stopPropagation();
    setIsShowPopup(true);
  };

  return (
    <>
      <div
        className={cx(styles.button_close, {
          [styles.disabled]: orgStatus?.status === ORGANISATION_ACTIVE_STATUS.SUSPENDED,
        })}
        onClick={deleteClickHandler}
      >
        <img src={ButtonCross} className={styles.button_close__icon} alt="close" />
      </div>
      {isShowPopup && (
        <PopupConfirmation
          cancelClickHandler={(e) => cancelClickHandler(e)}
          confirmClickHandler={(e) => confirmClickHandler(e)}
          setIsShowPopup={setIsShowPopup}
          text={t('messages:wantToDeleteSmartGroup')}
          parentClassName={styles.popup}
        />
      )}
    </>
  );
};

const mapStateToProps = (store: Store) => ({
  orgId: selectorGetOrgId(store),
  orgStatus: selectorGetOrgActiveStatus(store),
});

const mapDispatchToProps = {
  deleteSmartGroup,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type TabNavItemActionsProps = ConnectedProps<typeof connector> & TabNavItemActionsParentProps;

export default connector(TabNavItemActions);
