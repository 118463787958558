import {SET_PUSH_MESSAGE} from 'redux/actions-types';
import {IPushMessageReducer, MessagePayload, PushMessageActionTypes} from './types';

const initState: IPushMessageReducer = {
  notification: {body: '', title: ''},
};

const pushesReducer = (state = initState, action: PushMessageActionTypes): IPushMessageReducer => {
  const {type, payload}: PushMessageActionTypes = action;

  switch (type) {
    case SET_PUSH_MESSAGE: {
      const {body, title} = payload as MessagePayload;

      return {
        notification: {
          body,
          title,
        },
      };
    }
    default:
      return state;
  }
};

export default pushesReducer;
