import {createStore, applyMiddleware, compose, combineReducers} from 'redux';
import thunk from 'redux-thunk';
import {composeWithDevTools} from 'redux-devtools-extension';
//reducers
import dashboardReducer from './dashboard-service/reducer';
import errorReducer from './error-service/reducer';
import opportunitiesReducer from './opportunities-service/reducer';
import organizationReducer from './organization-service/reducer';
import userReducer from './user-service/reducer';
import volunteersReducer from './volunteer-service/reducer';
import smartGroupsReducer from './smartGroups-service/reducer';
import pushesReducer from './push-service/reducer';
import chatReducer from './chat-service/reducer';
import invitesReducer from './invites-service/reducer';
import filterReducer from './filter-service/reducer';
import signupRequestsReducer from './signupRequests-service/reducer';
import publicDashboardReducer from './publicDashboard-service/reducer';
import opportunityHashtagsReducer from './hashtag-service/reducer';
import loadingReducer from './loading-service/reducer';
import challengesReducer from './challenges-service/reducers';
import statusReducer from './status-service/reducers';

const rootReducer = combineReducers({
  // Add other reducers
  userRedux: userReducer,
  organizationRedux: organizationReducer,
  opportunitiesRedux: opportunitiesReducer,
  volunteersRedux: volunteersReducer,
  dashboardRedux: dashboardReducer,
  smartGroupsRedux: smartGroupsReducer,
  errorRedux: errorReducer,
  pushesRedux: pushesReducer,
  chatRedux: chatReducer,
  invitesRedux: invitesReducer,
  filterRedux: filterReducer,
  signupRequestsRedux: signupRequestsReducer,
  publicDashboardRedux: publicDashboardReducer,
  hashTagRedux: opportunityHashtagsReducer,
  loadingRedux: loadingReducer,
  challengeRedux: challengesReducer,
  statusRedux: statusReducer,
});

// Configure redux
const composeEnhancers = compose;
// const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk, logger)));
const store = createStore(
  rootReducer,
  composeWithDevTools(
    composeEnhancers(
      applyMiddleware(
        thunk
        // logger // lets us dispatch() functions
      )
    )
  )
);
export type Store = ReturnType<typeof rootReducer>;

export default store;
