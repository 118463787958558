//types
import {IBasicVolunteerResponse} from '@joc/api-gateway';
import {Store} from 'redux/root';

export const selectorGetVolunteersAllRecords = (store: Store): Array<IBasicVolunteerResponse> => {
  return store.volunteersRedux.volunteers.records;
};

export const selectorGetVolunteersChatId = (store: Store): Array<string> => {
  return store.volunteersRedux.volunteers.records.map((el) => (el?.chatId ? el.chatId : '')).filter((i) => i.length);
};

export const selectorGetVolunteersInitChat = (store: Store): Array<{chatId: string; chatName: string}> => {
  return store.volunteersRedux.volunteers.records
    .map((el) =>
      el?.chatId ? {chatId: el.chatId, chatName: `${el?.firstName} ${el?.lastName}`} : {chatId: '', chatName: ''}
    )
    .filter((i) => i.chatId.length);
};

export const selectorGetVolunteersTotal = (store: Store): number => {
  return store.volunteersRedux.volunteers.total;
};
