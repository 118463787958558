import {FC, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import cx from 'classnames';
//redux
import {createSmartGroup} from 'redux/smartGroups-service/actions';
import {selectorGetOrgId, selectorGetSchoolId} from 'redux/organization-service/selector';
//form configuration
import {SmartGroupFormValues, initialValues, validationSchema} from '../formConfiguration';
//helpers
import {getGroupsRequestFromSmartGroupFormValues} from '../helpers';
//components
import {Formik} from 'formik';
import SmartGroupForm from '../SmartGroupForm';
import ResponseFailure from 'shared/components/ResponseFailure';
import ButtonUpToTop from 'shared/components/Buttons/ButtonUpToTop';
//styles
import styles from '../styles.module.scss';

const SmartGroupCreate: FC = () => {
  const {t} = useTranslation(['volunteers', 'messages', 'buttons']);

  const dispatch = useDispatch();

  const organizationId = useSelector(selectorGetOrgId);
  const schoolId = useSelector(selectorGetSchoolId);

  const [isShowCongratulations, setIsShowCongratulations] = useState(false);

  const submitClickHandler = (values: SmartGroupFormValues) => {
    const groupRequest = getGroupsRequestFromSmartGroupFormValues(values, organizationId, schoolId?.toString());
    dispatch(createSmartGroup(groupRequest));
    setIsShowCongratulations(true);
  };

  const doneClickHandler = () => {
    setIsShowCongratulations(false);
  };

  if (isShowCongratulations)
    return (
      <ResponseFailure
        styleCongrats
        title={t('messages:congratulations')}
        message={t('messages:smartGroupSuccessfullyEdited')}
        buttonTitle={t('buttons:button.done')}
        buttonClickHandler={doneClickHandler}
      />
    );

  return (
    <div className={cx(styles.wrapper, 'smartGroupWrapperRef')}>
      <h2 className="title">{t('volunteers:createNewSmartFilter.title')}</h2>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={submitClickHandler}
        validateOnMount
      >
        <SmartGroupForm />
      </Formik>
      <ButtonUpToTop containerSelector="smartGroupWrapperRef" isSmartGroup />
    </div>
  );
};

export default SmartGroupCreate;
