import * as Yup from 'yup';
//validation
import {passwordSchema, confirmPasswordSchema} from 'core/validation';

export type LoginFromEmailFormValues = {
  email: string;
  password: string;
  confirmPassword: string;
};

export const initialValues = {
  email: '',
  password: '',
  confirmPassword: '',
};

export const validationSchema = Yup.object().shape({
  password: passwordSchema,
  confirmPassword: confirmPasswordSchema,
});
