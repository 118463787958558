export enum MESSAGES_FILTER_OPTION_VALUE {
  UNREAD = 'unread',
}

export type MessagesFilterOption = {
  checked: boolean;
  value: MESSAGES_FILTER_OPTION_VALUE;
};

export type MemberType = {chatId: string; chatName: string};
