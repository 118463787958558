import {FC} from 'react';
import {Link} from 'react-router-dom';
import cx from 'classnames';
//images
import {ReactComponent as TrashSvg} from 'assets/image/trash-light.svg';
import {ReactComponent as DownloadSvg} from 'assets/image/download.svg';
//styles
import styles from './FileStatusBar.module.scss';

interface FileProps extends File {
  invalid?: boolean;
}
type FileStatusBarProps = {
  fileSizeCounter?: (data: number) => string;
  removeFile?: (data: string) => void;
  openImageModal?: (data: FileProps) => void;
  data?: FileProps;
  fileSize?: string;
  fileName?: string;
  errorMessage?: string;
};

const FileStatusBar: FC<FileStatusBarProps> = ({
  data,
  errorMessage,
  fileSize,
  fileName,
  removeFile,
  fileSizeCounter,
  openImageModal,
}: FileStatusBarProps) => {
  return (
    <div className={styles.fileStatusBar}>
      <div
        className={styles.wrap}
        onClick={
          data && !data.invalid
            ? () => openImageModal && openImageModal(data)
            : () => removeFile && data && removeFile(data.name)
        }
      >
        <div className={styles.fileWrap}>
          <div className={styles.fileTypeLogo} />
          <div className={cx(styles.fileName, data && data.invalid && styles.fileError)}>
            {data ? data.name : fileName}
          </div>
        </div>
        {data && data.invalid && <div className={styles.fileErrorMessage}>({errorMessage})</div>}
        <div className={styles.fileSize}>{fileSizeCounter && data ? fileSizeCounter(data.size) : fileSize || ''}</div>
      </div>
      {removeFile ? (
        <div className={styles.fileRemove} onClick={() => removeFile && data && removeFile(data.name)}>
          <TrashSvg />
        </div>
      ) : (
        <Link to="/" className={styles.fileRemove}>
          <DownloadSvg />
        </Link>
      )}
    </div>
  );
};

export default FileStatusBar;
