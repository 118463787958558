import * as Yup from 'yup';
//validation
import {emailSchema, passwordSchema} from 'core/validation';

export type LoginFormValues = {
  email: string;
  password: string;
};

export const initialValues: LoginFormValues = {
  email: '',
  password: '',
};

export const validationSchema = Yup.object().shape({
  email: emailSchema,
  password: passwordSchema,
});
