import {FC, Fragment} from 'react';
import {useTranslation} from 'react-i18next';
//formik
import {Field, FieldProps, Form, FormikErrors, useFormikContext} from 'formik';
// Components
import Loader from 'shared/components/Loader';
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
import InputErrorHint from 'shared/components/InputErrorHint';
import DropzoneVideo from '../DropzoneVideo';

type videoType = {video: File | null};

type UploadVideoFormParentProps = {
  isLoading: boolean;
};

const UploadVideoForm: FC<UploadVideoFormParentProps> = ({isLoading}: UploadVideoFormParentProps) => {
  const {errors, values, setFieldValue} = useFormikContext();

  const {t} = useTranslation('buttons');

  return (
    <Form>
      <Field name="video">
        {({field}: FieldProps) => (
          <Fragment>
            <DropzoneVideo maxSize={5} setFieldValue={setFieldValue} fieldName={field.name} fieldValue={field.value} />
            {(errors as FormikErrors<videoType>).video && (
              <InputErrorHint errorText={(errors as FormikErrors<videoType>).video} />
            )}
          </Fragment>
        )}
      </Field>

      {isLoading ? (
        <Loader loadProps={{disableCenterStyle: true}} />
      ) : (
        <ButtonDefault
          submitType
          title={t('button.publishNow')}
          classList={['primary', 'lg']}
          disabled={!(values as {video: File | null})?.video}
        />
      )}
    </Form>
  );
};

export default UploadVideoForm;
