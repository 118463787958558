import {FC, useEffect, useState} from 'react';
// material ui
import {Alert, Box, Typography, Snackbar, LinearProgress} from '@mui/material';
//redux
import {useDispatch, useSelector} from 'react-redux';
import {Store} from 'redux/root';
import {hideUploadInfoAlert} from 'redux/loading-service/actions';
//components
import SnackbarBasic from './SnackbarBasic';
import i18n from 'components/translate';
import {LoadingPayload} from 'redux/loading-service/types';
//icons
import {ReactComponent as UploadFileIcon} from 'assets/icons/upload-file.svg';
import {Color} from '@material-ui/lab';

const SnackbarUploadInfo: FC = () => {
  const dispatch = useDispatch();
  const [alertCompletedText, setalertCompletedText] = useState<string>();
  const [alertCompletedSeverity, setAlertCompletedSeverity] = useState<Color>();
  const validationT = i18n.getFixedT(null, 'validation');

  const {progress, showSuccess, showError} = useSelector((store: Store) => store.loadingRedux);

  useEffect(() => {
    if (showSuccess) {
      setalertCompletedText(validationT('upload.success'));
      setAlertCompletedSeverity('success');
    }

    if (showError) {
      setalertCompletedText(validationT('upload.failed'));
      setAlertCompletedSeverity('error');
    }
  }, [showSuccess, showError]);

  const handleCloseAlertInfo = () => {
    dispatch(hideUploadInfoAlert());
  };

  const ProgressItem = (progress: LoadingPayload) => (
    <>
      {progress.percent ? (
        <Box sx={{display: 'flex', alignItems: 'center'}}>
          <UploadFileIcon
            style={{fill: progress.percent === 100 ? '#2e7d32' : '#4b5ef5', width: 20, height: 20, marginLeft: '-3px'}}
          />
          <Box sx={{minWidth: 100, mr: 1}}>
            <LinearProgress
              variant="determinate"
              sx={{'.MuiLinearProgress-barColorPrimary': {backgroundColor: '#4b5ef5'}}}
              color={progress.percent === 100 ? 'success' : 'primary'}
              value={progress.percent}
            />
          </Box>
          <Box sx={{minWidth: 35}}>
            <Typography
              variant="body2"
              sx={{color: progress.percent === 100 ? '#2e7d32' : '#4b5ef5'}}
            >{`${progress.percent}%`}</Typography>
          </Box>
        </Box>
      ) : (
        <></>
      )}
    </>
  );

  return (
    <>
      <Snackbar anchorOrigin={{vertical: 'bottom', horizontal: 'center'}} open={!!progress.length}>
        <Alert
          closeText=""
          sx={{
            '&.MuiPaper-root': {backgroundColor: '#f1f3ff'},
            '.MuiAlert-icon': {alignItems: 'center', svg: {fill: '#4b5ef5'}},
            // '.MuiAlert-message': {padding: 'unset'},
          }}
          severity="info"
        >
          {(progress.length && (
            <Box sx={{display: 'flex', flexDirection: 'column'}}>
              <Typography variant="body2" color="primary" sx={{color: '#4b5ef5'}}>
                {progress.length > 1 ? 'Files Upload' : 'File upload'}:
              </Typography>
              {progress.map((progressItem) => ProgressItem(progressItem))}
            </Box>
          )) || <></>}
        </Alert>
      </Snackbar>

      {(alertCompletedText && alertCompletedSeverity && (
        <SnackbarBasic
          open={showSuccess || showError}
          handleClose={handleCloseAlertInfo}
          text={alertCompletedText}
          autoHideDuration={4000}
          alertSeverity={alertCompletedSeverity}
        />
      )) || <></>}
    </>
  );
};

export default SnackbarUploadInfo;
