import {FC} from 'react';
import {useTranslation} from 'react-i18next';
//functions
import {getTextDirection} from 'core/functions';
//formik
import {Field, FieldProps, Form, useFormikContext} from 'formik';
//form configuration
import {GetInTouchFormValues} from '../formConfiguration';
//components
import Input from 'shared/inputs/Input';
import TextArea from 'shared/inputs/TextArea';
import ReCAPTCHA from 'react-google-recaptcha';
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
import InputErrorHint from 'shared/components/InputErrorHint';
import Loader from 'shared/components/Loader';
import GoogleApiAutoCompleat from 'shared/components/GoogleApiAutoComplete';
//styles
import styles from './GetInTouchForm.module.scss';

type GetInTouchFormParentsProps = {isLoading: boolean};

const GetInTouchForm: FC<GetInTouchFormParentsProps> = ({isLoading}) => {
  const {errors, setFieldValue} = useFormikContext<GetInTouchFormValues>();

  const {t} = useTranslation(['form', 'buttons', 'inputs']);

  return (
    <Form className={styles.form__block}>
      <Field name="subject" placeholder={t('form:getInTouch.whatsThisAbout')} isDisabledMargin component={Input} />
      <Field name="name" placeholder={t('form:getInTouch.fullNameRequired')} isDisabledMargin component={Input} />
      <Field name="email" placeholder={`${t('form:email')}*`} isDisabledMargin component={Input} />
      <Field name="country">
        {({field, ...fieldProps}: FieldProps) => (
          <GoogleApiAutoCompleat
            selectHandler={(country) =>
              setFieldValue(field.name, `${country.countryName} ${country.stateName} ${country.cityName}`)
            }
            isDisabledMargin
            field={field}
            placeholder={`${t('inputs:placeholders.address')}*`}
            {...fieldProps}
          />
        )}
      </Field>
      <Field
        name="message"
        parentClassName={styles.textarea}
        placeholder={t('form:getInTouch.messageRequired')}
        isDisabledMargin
        component={TextArea}
      />
      <div className={styles.captchaContainer}>
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_API_KEY || ''}
          onChange={(value) => setFieldValue('captcha', value)}
        />
        {errors.captcha && <InputErrorHint errorText={errors.captcha} />}
      </div>
      {isLoading ? (
        <Loader loadProps={{disableCenterStyle: true}} />
      ) : (
        <ButtonDefault submitType title={t('buttons:button.submit')} classList={['primary', 'lg']} />
      )}
    </Form>
  );
};

export default GetInTouchForm;
