import {NOT_ACCEPTED_INVITES_COUNT, RESEND_INVITE_VOLUNTEER, SET_VOLUNTEERS_INVITED} from '../actions-types';
import {IInvitesResponse, InvitesActionTypes} from './types';

const initState: IInvitesResponse = {response: false, invitesCount: {invitesCount: 0}};

const invitesReducer = (state = initState, action: InvitesActionTypes): IInvitesResponse => {
  const {type, payload}: InvitesActionTypes = action;
  switch (type) {
    case RESEND_INVITE_VOLUNTEER: {
      return {...state, response: payload};
    }
    case NOT_ACCEPTED_INVITES_COUNT: {
      return {...state, invitesCount: payload};
    }
    case SET_VOLUNTEERS_INVITED: {
      return initState;
    }
    default:
      return state;
  }
};

export default invitesReducer;
