import qs from 'qs';
// constants
import {ORGANIZATION_TABS, TABLE_HEADERS, TABLE_ITEMS_CLASSNAMES} from 'core/constants';

export const getTableHeadStatusConfig = (schoolId?: number) => {
  const {studentStatus, volunteerStatus} = TABLE_ITEMS_CLASSNAMES;
  const {studentStatus: studentHeaderText, volunteerStatus: volunteerHeaderText} = TABLE_HEADERS;

  return {
    className: schoolId ? studentStatus : volunteerStatus,
    text: schoolId ? studentHeaderText : volunteerHeaderText,
  };
};

export const getCurrentTabFromQuery = (query: string): ORGANIZATION_TABS | null => {
  const parsedQuery = qs.parse(query, {ignoreQueryPrefix: true});

  return !!parsedQuery.tab ? (parsedQuery.tab as ORGANIZATION_TABS) : null;
};
