//types
import {IRepeatableOpportunityRequest, WEEKDAYS} from '@joc/api-gateway';
import {RecurrenceLabel, WEEK} from './RepeatOpportunityForm/RepeatsEvery/helpers';
import moment from 'moment';

export enum REPEAT_OPPO_ENDS_TYPE {
  NONE = 'none',
  ON = 'on',
  AFTER = 'after',
}

export type RepeatOpportunityModalFormValues = Omit<IRepeatableOpportunityRequest, 'frequency' | 'daysOfWeek'> & {
  frequency: RecurrenceLabel;
  weekDays?: Array<WEEKDAYS>;
  endsType: REPEAT_OPPO_ENDS_TYPE;
};

export const getCurrentWeekDay = (): WEEKDAYS =>
  Object.values(WEEKDAYS).find(
    (weekday) => weekday.toLowerCase() === moment().locale('en-US').format('dddd').toLocaleLowerCase()
  ) || WEEKDAYS.MONDAY;

export const initialValues: RepeatOpportunityModalFormValues = {
  frequency: WEEK,
  interval: 1,
  endDate: undefined,
  amount: 1,
  weekDays: [getCurrentWeekDay()],
  endsType: REPEAT_OPPO_ENDS_TYPE.NONE,
};
