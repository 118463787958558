import {FC, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Formik} from 'formik';
import qs from 'qs';
//types
import {IVerifyManualOpportunityInitialValues} from 'core/types';
//helpers
import {verifyManualOpportunity} from './helpers';
//components
import DefaultRenderer from 'shared/components/Appeal/DefaultRenderer';
import VerifyManualOpportunityForm from './VerifyManualOpportunityForm';
//styles
import styles from './VerifyManualOpportunity.module.scss';

type QueryParametersType = {
  opportunityId: string;
  authorName: string;
  spendTime: string;
};

const VerifyManualOpportunity: FC = () => {
  const {search} = useLocation();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const {t} = useTranslation('messages');

  const initialValues: IVerifyManualOpportunityInitialValues = {
    recipientSignatureImage: '',
  };

  const {opportunityId, authorName, spendTime} = qs.parse(search, {ignoreQueryPrefix: true}) as QueryParametersType;

  const onSubmit = async (values: IVerifyManualOpportunityInitialValues) => {
    setIsLoading(true);
    try {
      await verifyManualOpportunity(opportunityId, values.recipientSignatureImage);
      setIsSubmitted(true);
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.main}>
      {isSubmitted ? (
        <DefaultRenderer styleCongrats isHeightDisable styleTable>
          <h2>{t('thankYou')}</h2>
        </DefaultRenderer>
      ) : errorMessage ? (
        <DefaultRenderer stylePensive isHeightDisable styleTable>
          <h3>{errorMessage}</h3>
        </DefaultRenderer>
      ) : (
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
          <VerifyManualOpportunityForm authorName={authorName} spendTime={spendTime} isLoading={isLoading} />
        </Formik>
      )}
    </div>
  );
};

export default VerifyManualOpportunity;
