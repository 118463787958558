import {FC, useEffect} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {useHistory} from 'react-router';
import {useTranslation} from 'react-i18next';
//types
import {ORGANISATION_ACTIVE_STATUS} from '@joc/api-gateway';
//redux
import {Store} from 'redux/root';
import {selectorGetOrgActiveStatus} from 'redux/organization-service/selector';
//urls
import {urls} from 'core/appUrls';
//components
import ChatOk from 'shared/components/ChatOk';
//styles
import styles from './Inbox.module.scss';

const InboxPage: FC<InboxPageProps> = ({orgStatus}: InboxPageProps) => {
  const history = useHistory();

  const {t} = useTranslation('chat');

  useEffect(() => {
    if (orgStatus?.status === ORGANISATION_ACTIVE_STATUS.SUSPENDED) {
      history.push(urls.orgDashboard);
    }
  }, [history]);

  return (
    <div className={styles.container}>
      <h1 className="title">{t('inbox')}</h1>
      <div className="container">
        <ChatOk isOrg />
      </div>
    </div>
  );
};

const mapStateToProps = (store: Store) => ({
  orgStatus: selectorGetOrgActiveStatus(store),
});

const connector = connect(mapStateToProps);

type InboxPageProps = ConnectedProps<typeof connector>;

export default connector(InboxPage);
