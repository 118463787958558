import {createContext, useContext} from 'react';
import {IOrganisationTinyResponse} from '@joc/api-gateway/lib/api-client';

type VolunteerInfoContextValue = {
  activeOrganisations?: Array<IOrganisationTinyResponse>;
  isOrganisationReadonly: boolean;
  isStudentIdReadonly: boolean;
};

const VolunteerInfoContext = createContext<VolunteerInfoContextValue>({
  isOrganisationReadonly: false,
  isStudentIdReadonly: false,
});

export const VolunteerInfoContextProvider = VolunteerInfoContext.Provider;
export const useVolunteerInfoContext = () => useContext(VolunteerInfoContext);
export const getVolunteerInfoContextValue = (value: VolunteerInfoContextValue) => value;
