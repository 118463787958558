import {FC} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {Redirect, Route} from 'react-router';
//redux
import {Store} from 'redux/root';
import {selectorGetUserRolesByOrg} from 'redux/user-service/selector';
//types
import {VOLUNTEER} from '@joc/api-gateway';
//constants
import {urls} from 'core/appUrls';
//components
import NotFound from 'pages/NotFound';
import Loader from 'shared/components/Loader';

type RouteGuardLoginRequiredParentProps = {
  path: string;
  component: FC;
  accessRoles: Array<VOLUNTEER>;
};

const RouteGuardLoginRequired: FC<RouteGuardLoginRequiredProps> = ({
  component: Component,
  path,
  accessRoles,
  userRoles,
}) => {
  const localStorageAccessToken = localStorage.getItem('accessToken');

  return (
    <Route exact path={path}>
      {localStorageAccessToken ? (
        !!userRoles ? (
          userRoles.find((i) => i.roleName === accessRoles?.find((e) => e === i.roleName)) || !userRoles?.length ? (
            <Component />
          ) : (
            <Redirect to={urls.onboardingChooseUserType} />
          )
        ) : (
          <Loader loadProps={{stylePageCenter: true}} />
        )
      ) : (
        <Redirect to={urls.onboardingChooseUserType} />
      )}
    </Route>
  );
};

const mapStateToProps = (store: Store) => ({
  userRoles: selectorGetUserRolesByOrg(store),
});

const connector = connect(mapStateToProps);

type RouteGuardLoginRequiredProps = ConnectedProps<typeof connector> & RouteGuardLoginRequiredParentProps;

export default connector(RouteGuardLoginRequired);
