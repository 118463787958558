import {FC, useEffect, useMemo, useState} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {useTranslation} from 'react-i18next';
import cx from 'classnames';
//redux
import {Store} from 'redux/root';
import {selectorGetOrgId, selectorGetOrgName} from 'redux/organization-service/selector';
import {
  selectorGetUserFullName,
  selectorGetUserImage,
  selectorGetUserOrganizationsWithRoles,
  selectorGetUserRoles,
} from 'redux/user-service/selector';
//functions
import {setUserPhotoSrc, sortUserOrganisation} from 'core/functions';
//types
import {USER_ORG_ROLES} from '@joc/api-gateway';
//components
import UserOrganizationsSelect from 'shared/components/SelectDropdown/UserOrganizationsSelect';
//styles
import styles from './OrganizationInfo.module.scss';

type OrganizationInfoParentProps = {
  parentClassName: string;
};

const OrganizationInfo: FC<OrganizationInfoProps> = ({
  userFullName,
  userRoles,
  orgName,
  userImage,
  parentClassName,
  orgId,
  userOrganizationsWithRoles,
}) => {
  const [userRolesCurrentOrg, setUserRolesCurrentOrg] = useState<Array<string> | undefined>([]);

  const {t} = useTranslation('members');

  useEffect(() => {
    const rolesInCurrewntOrg = userRoles?.filter((user) => user.organizationId && +user.organizationId === orgId);
    const listUserRoles = rolesInCurrewntOrg?.map((user) => {
      switch (user.roleName) {
        case USER_ORG_ROLES.ADMIN:
          return t('roles.admin');

        case USER_ORG_ROLES.MARKETER:
          return t('roles.marketer');

        case USER_ORG_ROLES.COORDINATOR:
          return t('roles.coordinator');

        case USER_ORG_ROLES.VOLUNTEER:
          return t('roles.volunteer');

        default:
          return user.roleName.toLowerCase();
      }
    });

    setUserRolesCurrentOrg(listUserRoles);
  }, []);

  const orgListWithRoles = useMemo(() => {
    if (userOrganizationsWithRoles) return sortUserOrganisation(userOrganizationsWithRoles);
  }, [userOrganizationsWithRoles]);
  return (
    <div className={cx(styles.team__user, parentClassName && parentClassName)}>
      <div className={styles.user__description}>
        <div className={styles.user__name}>{orgName}</div>
      </div>
      <div className={styles.user__description_user}>
        <div className={styles.user__info}>
          <img className={styles.user__logo} src={setUserPhotoSrc(userImage)} alt={orgName} />
          <div className={styles.organizationUser_FullName}>{userFullName}</div>
        </div>
        <div className={styles.organisationUser_description}>
          <div className={styles.ico} />
          <div className={styles.roles}>{userRolesCurrentOrg?.join(', ')}</div>
        </div>
      </div>
      {orgListWithRoles?.length && orgListWithRoles?.length > 1 ? (
        <UserOrganizationsSelect isRedirect isFullHeight />
      ) : (
        ''
      )}
    </div>
  );
};

const mapStateToProps = (store: Store) => ({
  orgName: selectorGetOrgName(store),
  userImage: selectorGetUserImage(store),
  userFullName: selectorGetUserFullName(store),
  orgId: selectorGetOrgId(store),
  userRoles: selectorGetUserRoles(store),
  userOrganizationsWithRoles: selectorGetUserOrganizationsWithRoles(store),
});
const connector = connect(mapStateToProps);

type OrganizationInfoProps = ConnectedProps<typeof connector> & OrganizationInfoParentProps;

export default connector(OrganizationInfo);
