import {FC, useState, MouseEvent} from 'react';
import {IStatusResponse} from '@joc/api-gateway';
import {IconButton} from '@mui/material';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
// redux
import {useDispatch} from 'react-redux';
// icons
import {ReactComponent as ThreeDotsSvg} from '../../../../../../assets/icons/menu.svg';
import {ReactComponent as PreviewSvg} from '../../../../../../assets/icons/preview.svg';
import {ReactComponent as EditSvg} from '../../../../../../assets/icons/edit.svg';
import {ReactComponent as DeleteSvg} from '../../../../../../assets/icons/trash.svg';
// styles
import styles from './StatusActions.module.scss';
import {deleteStatus} from '../../../../../../redux/status-service/actions';
import StatusesPopup from '../../StatusesPopup';
import DialogStyled from '../../../../../../shared/components/Dialog';
import StatusPreview from '../../StatusPreview';
import ButtonCross from '../../../../../../shared/components/Buttons/ButtonCross';

type StatusActionsTypes = {
  status: IStatusResponse;
  onEditAction?: (status: IStatusResponse) => void;
  onPreviewAction?: (status: IStatusResponse) => void;
};

const StatusAction: FC<StatusActionsTypes> = ({status, onEditAction, onPreviewAction}) => {
  const dispatch = useDispatch();
  const [isShowStatusPopup, setIsShowStatusPopup] = useState(false);
  const [isShowStatusPreview, setIsShowStatusPreview] = useState(false);

  const [openItemId, setOpenItemId] = useState<number | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleEditStatus = () => {
    onEditAction && onEditAction(status);
    setIsShowStatusPopup(true);
    handleActionsButtonClose();
  };

  const handlePreviewStatus = () => {
    onPreviewAction && onPreviewAction(status);
    setIsShowStatusPreview(true);
    handleActionsButtonClose();
  };

  const handleDeleteStatus = () => {
    dispatch(deleteStatus({id: status.id}));
    handleActionsButtonClose();
  };

  const handleActionsButtonClose = () => {
    setAnchorEl(null);
    setOpenItemId(null);
  };

  const handleActionsButtonClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpenItemId(status.id);
  };

  const handleCloseStatusPopup = () => setIsShowStatusPopup(false);
  const handleCloseStatusPreview = () => setIsShowStatusPreview(false);

  return (
    <>
      <IconButton
        aria-label="actions"
        aria-controls={openItemId === status.id ? 'long-menu' : undefined}
        onClick={(event) => handleActionsButtonClick(event)}
      >
        <ThreeDotsSvg />
      </IconButton>
      <Menu
        open={openItemId === status.id}
        anchorEl={anchorEl}
        onClose={handleActionsButtonClose}
        MenuListProps={{
          className: styles.actionsList,
        }}
        PaperProps={{
          className: styles.paper,
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem className={styles.menuItem} onClick={handlePreviewStatus}>
          <div className={styles.actionsContainer}>
            <PreviewSvg title="Preview" />
            <span className={styles.actionsText}>Preview</span>
          </div>
        </MenuItem>
        <MenuItem className={styles.menuItem} onClick={handleEditStatus}>
          <div className={styles.actionsContainer}>
            <EditSvg title="Edit status" />
            <span className={styles.actionsText}>Edit</span>
          </div>
        </MenuItem>
        <MenuItem className={styles.menuItem} onClick={handleDeleteStatus}>
          <div className={styles.actionsContainer}>
            <DeleteSvg title="Delete status" />
            <span className={styles.actionsText}>Delete</span>
          </div>
        </MenuItem>
      </Menu>
      {isShowStatusPopup && (
        <StatusesPopup status={status} isModalOpen={isShowStatusPopup} onClose={handleCloseStatusPopup} />
      )}
      {isShowStatusPreview && (
        <DialogStyled open={isShowStatusPreview} maxWidth="xl">
          <div className={styles.dialog__header}>
            <ButtonCross clickHandler={handleCloseStatusPreview} parentClassName={styles.dialog__header_button} />
          </div>
          <div className={styles.dialog__content}>
            <StatusPreview status={status} />
          </div>
        </DialogStyled>
      )}
    </>
  );
};

export default StatusAction;
