import {FC, useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
//redux
import {getOpportunitiesByVolunteer, resetOpportunities} from 'redux/opportunities-service/action';
import {resetError, setError} from 'redux/error-service/action';
import {getCurrentUser} from 'redux/user-service/actions';
import {selectorGetFilter} from 'redux/filter-service/selector';
import {selectorGetError} from 'redux/error-service/selector';
import {selectorGetUserAddress, selectorGetUserVolunteerId} from 'redux/user-service/selector';
import {selectorGetOpportunitiesRecords, selectorGetOpportunitiesTotal} from 'redux/opportunities-service/selector';
import {setPaginationSkip} from 'redux/filter-service/actions';
//constants
import {VOLUNTEER_TABS} from 'core/constants';
//types
import {ISearchOpportunitiesRequest} from '@joc/api-gateway';
//helpers
import {getOppoRequestBody, getPopupConfig, parseQueryString} from './helpers';
//components
import Loader from 'shared/components/Loader';
import OpportunitiesList from 'components/Volunteer/Opportunities/OpportunitiesList';
import ResponseFailure from 'shared/components/ResponseFailure';
import OpportunityContainer from 'components/Volunteer/Opportunities/OpportunitiesList/OpportunityContainer';
import {MyFeedSidebar} from './MyFeedSideBar';
import {dataTabs} from './data';
//styles
import styles from './MyFeedV.module.scss';
import {urls} from 'core/appUrls';

const MyFeedPage: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const {t} = useTranslation(['myChesed', 'messages', 'buttons']);

  const [isLoading, setIsLoading] = useState(true);
  const [currentTab, setCurrentTab] = useState<string | null>(null);
  const [defaultRequestBody, setDefaultRequestBody] = useState<ISearchOpportunitiesRequest>();

  const error = useSelector(selectorGetError);
  const filter = useSelector(selectorGetFilter);
  const userAddress = useSelector(selectorGetUserAddress);
  const volunteerId = useSelector(selectorGetUserVolunteerId);
  const opportunitiesTotal = useSelector(selectorGetOpportunitiesTotal);
  const opportunitiesRecords = useSelector(selectorGetOpportunitiesRecords);

  const getOpportunities = useCallback(
    async (volunteerId: string | number) => {
      setIsLoading(true);
      try {
        const {tabName} = parseQueryString(location.search);
        const oppoRequestBody = getOppoRequestBody(volunteerId, filter?.pagination, tabName, defaultRequestBody);
        await dispatch(getOpportunitiesByVolunteer(userAddress, oppoRequestBody));
        setIsLoading(false);
      } catch (error) {
        dispatch(setError(error?.response?.message || error.message, error.code));
        setIsLoading(false);
      }
    },
    [filter?.pagination, defaultRequestBody]
  );

  useEffect(() => {
    if (defaultRequestBody !== undefined && volunteerId) getOpportunities(volunteerId);
  }, [getOpportunities]);

  useEffect(() => {
    if (volunteerId) {
      dispatch(resetOpportunities());
      dispatch(setPaginationSkip(0));
      setDefaultRequestBody(parseQueryString(location.search));
    }
  }, [location.search, volunteerId]);

  useEffect(() => {
    dispatch(getCurrentUser());

    return () => {
      dispatch(resetOpportunities());
    };
  }, []);

  const handleResetPage = () => {
    dispatch(resetError());
    history.push({search: ''});
  };

  const handleGoToCreateManualOppPage = () => {
    history.push(urls.volManualOpportunity);
  };

  const totalOpportunities = useMemo(
    () =>
      `${opportunitiesTotal} ${t('myChesed:chesed')} ${
        opportunitiesTotal === 1 ? t('myChesed:opportunity') : t('myChesed:opportunities')
      }`,
    [opportunitiesTotal]
  );

  const isCompletedTab = useMemo(() => currentTab === VOLUNTEER_TABS.COMPLETED || currentTab == null, [currentTab]);

  const isShowErrorPopup = useMemo(
    () => !isLoading && error.state && error.message !== 'Reached max volunteers count',
    [isLoading, error]
  );

  const configPopup = useMemo(
    () => getPopupConfig(currentTab, dataTabs, handleGoToCreateManualOppPage, t),
    [currentTab, dataTabs, handleGoToCreateManualOppPage, getPopupConfig]
  );

  const isHidePopupButton = useMemo(() => !Boolean(configPopup.buttonText), [configPopup]);

  return (
    <OpportunityContainer>
      <MyFeedSidebar currentTab={currentTab} setCurrentTab={setCurrentTab} totalOpportunities={totalOpportunities} />
      <div className={styles.feed__main}>
        {!opportunitiesRecords.length && isLoading ? (
          <div className={styles.loader}>
            <Loader />
          </div>
        ) : isShowErrorPopup ? (
          <ResponseFailure
            styleTable
            message={error.message || t('messages:noRelevantListings')}
            buttonTitle={t('buttons:button.getAll')}
            buttonClickHandler={handleResetPage}
          />
        ) : !opportunitiesRecords.length && !isLoading ? (
          <ResponseFailure
            styleTable
            isHeightVisibility
            message={configPopup.textTitle}
            buttonUnshow={isHidePopupButton}
            buttonClickHandler={configPopup.fn}
            buttonTitle={configPopup.buttonText}
          />
        ) : (
          <OpportunitiesList
            isLoading={isLoading}
            isVolunteerOpportunities
            isCompletedTab={isCompletedTab}
            buttonFailureClickHandler={handleResetPage}
          />
        )}
      </div>
    </OpportunityContainer>
  );
};

export default MyFeedPage;
