import {FC, Fragment} from 'react';
import cx from 'classnames';
import {useTranslation} from 'react-i18next';
//formik
import {FieldArray} from 'formik';
//types
import {WITH_WHO} from '@joc/api-gateway';
//components
import WhoItem from './WhoItem';
//styles
import styles from './WithWhoFilter.module.scss';

type WithWhoFilterProps = {
  setFieldValue: (field: string, value: Array<WITH_WHO>, shouldValidate?: boolean | undefined) => void;
  fieldValues: Array<WITH_WHO>;
  smallStyle?: boolean;
  disabled?: boolean;
};

const WithWhoFilter: FC<WithWhoFilterProps> = ({
  fieldValues,
  setFieldValue,
  smallStyle,
  disabled,
}: WithWhoFilterProps) => {
  const {t} = useTranslation('form');

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.label}>{t('filterWithWho.title')}</div>
        <span className={styles.label__action} onClick={() => !disabled && setFieldValue('withWho', [])}>
          {t('filterWithWho.clear')}
        </span>
      </div>

      <div className={styles.who}>
        <FieldArray
          name="withWho"
          render={(arrayHelpers) => (
            <Fragment>
              <label key={WITH_WHO.BABIES} className={cx(smallStyle && styles.small)}>
                <input
                  className={styles.input}
                  name="withWho"
                  type="checkbox"
                  value={WITH_WHO.BABIES}
                  checked={fieldValues?.includes(WITH_WHO.BABIES)}
                  disabled={disabled}
                  onChange={(e) => {
                    if (e.target.checked) {
                      arrayHelpers.push(WITH_WHO.BABIES);
                    } else {
                      const idx = fieldValues.indexOf(WITH_WHO.BABIES);
                      arrayHelpers.remove(idx);
                    }
                  }}
                />
                <WhoItem who={WITH_WHO.BABIES} checked={fieldValues?.includes(WITH_WHO.BABIES)} />
              </label>
              <label key={WITH_WHO.KIDS} className={cx(smallStyle && styles.small)}>
                <input
                  className={styles.input}
                  name="withWho"
                  type="checkbox"
                  value={WITH_WHO.KIDS}
                  checked={fieldValues?.includes(WITH_WHO.KIDS)}
                  disabled={disabled}
                  onChange={(e) => {
                    if (e.target.checked) {
                      arrayHelpers.push(WITH_WHO.KIDS);
                    } else {
                      const idx = fieldValues.indexOf(WITH_WHO.KIDS);
                      arrayHelpers.remove(idx);
                    }
                  }}
                />
                <WhoItem who={WITH_WHO.KIDS} checked={fieldValues?.includes(WITH_WHO.KIDS)} />
              </label>
              <label key={WITH_WHO.TEENS} className={cx(smallStyle && styles.small)}>
                <input
                  className={styles.input}
                  name="withWho"
                  type="checkbox"
                  value={WITH_WHO.TEENS}
                  checked={fieldValues?.includes(WITH_WHO.TEENS)}
                  disabled={disabled}
                  onChange={(e) => {
                    if (e.target.checked) {
                      arrayHelpers.push(WITH_WHO.TEENS);
                    } else {
                      const idx = fieldValues.indexOf(WITH_WHO.TEENS);
                      arrayHelpers.remove(idx);
                    }
                  }}
                />
                <WhoItem who={WITH_WHO.TEENS} checked={fieldValues?.includes(WITH_WHO.TEENS)} />
              </label>
              <label key={WITH_WHO.ADULTS} className={cx(smallStyle && styles.small)}>
                <input
                  className={styles.input}
                  name="withWho"
                  type="checkbox"
                  value={WITH_WHO.ADULTS}
                  checked={fieldValues?.includes(WITH_WHO.ADULTS)}
                  disabled={disabled}
                  onChange={(e) => {
                    if (e.target.checked) {
                      arrayHelpers.push(WITH_WHO.ADULTS);
                    } else {
                      const idx = fieldValues.indexOf(WITH_WHO.ADULTS);
                      arrayHelpers.remove(idx);
                    }
                  }}
                />
                <WhoItem who={WITH_WHO.ADULTS} checked={fieldValues?.includes(WITH_WHO.ADULTS)} />
              </label>
              <label key={WITH_WHO.SENIORS} className={cx(smallStyle && styles.small)}>
                <input
                  className={styles.input}
                  name="withWho"
                  type="checkbox"
                  value={WITH_WHO.SENIORS}
                  checked={fieldValues?.includes(WITH_WHO.SENIORS)}
                  disabled={disabled}
                  onChange={(e) => {
                    if (e.target.checked) {
                      arrayHelpers.push(WITH_WHO.SENIORS);
                    } else {
                      const idx = fieldValues.indexOf(WITH_WHO.SENIORS);
                      arrayHelpers.remove(idx);
                    }
                  }}
                />
                <WhoItem who={WITH_WHO.SENIORS} checked={fieldValues?.includes(WITH_WHO.SENIORS)} />
              </label>
              <label key={WITH_WHO.OTHER} className={cx(smallStyle && styles.small)}>
                <input
                  className={styles.input}
                  name="withWho"
                  type="checkbox"
                  value={WITH_WHO.OTHER}
                  checked={fieldValues?.includes(WITH_WHO.OTHER)}
                  disabled={disabled}
                  onChange={(e) => {
                    if (e.target.checked) {
                      arrayHelpers.push(WITH_WHO.OTHER);
                    } else {
                      const idx = fieldValues.indexOf(WITH_WHO.OTHER);
                      arrayHelpers.remove(idx);
                    }
                  }}
                />
                <WhoItem who={WITH_WHO.OTHER} checked={fieldValues?.includes(WITH_WHO.OTHER)} />
              </label>
            </Fragment>
          )}
        />
      </div>
    </div>
  );
};

export default WithWhoFilter;
