import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {Form, useFormikContext} from 'formik';
//helpers
import {getVolunteerWorkHours} from './helpers';
//types
import {IVerifyManualOpportunityInitialValues} from 'core/types';
//components
import Loader from 'shared/components/Loader';
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
import VerifyManualOpportunitySignature from 'components/Volunteer/VerificationSignature';
//styles
import styles from './VerifyManualOpportunityForm.module.scss';

interface IVerifyManualOpportunityForm {
  authorName: string;
  spendTime: string;
  isLoading: boolean;
}

const VerifyManualOpportunityForm: FC<IVerifyManualOpportunityForm> = ({authorName, spendTime, isLoading}) => {
  const {values, dirty, setFieldValue, resetForm} = useFormikContext<IVerifyManualOpportunityInitialValues>();

  const {t} = useTranslation(['form', 'common', 'buttons']);

  const handleSignatureReset = () => {
    resetForm();
  };

  return (
    <Form className={styles.form}>
      <div className={styles.form__title}>
        {`${t('form:confirmManOppo.iConfirmThat')} `}
        {authorName}
        {` ${t('form:confirmManOppo.completed')} `}
        <span className={styles.form__title_blue}>
          {getVolunteerWorkHours(+spendTime)}
          {` ${t(+spendTime === 1 ? 'common:time.hour' : 'common:time.hours')} `}
        </span>
        {t('form:confirmManOppo.ofVolunteerWork')}
      </div>
      <VerifyManualOpportunitySignature
        fieldName="recipientSignatureImage"
        fieldValue={values.recipientSignatureImage}
        setFieldValue={setFieldValue}
      />
      <div className={styles.form__media}>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <button type="button" className={styles.form__button_underlined} onClick={handleSignatureReset}>
              {t('buttons:button.reset')}
            </button>
            <ButtonDefault
              submitType
              classList={['primary', 'md', 'right']}
              title={t('buttons:button.submit')}
              disabled={!dirty}
            />
          </>
        )}
      </div>
    </Form>
  );
};

export default VerifyManualOpportunityForm;
