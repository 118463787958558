import {FC, useMemo} from 'react';
//formik
import {Formik, useFormikContext} from 'formik';
//validation
import {recurrenceFormValidationSchema} from 'core/validation';
//form configuration
import {RepeatOpportunityModalFormValues, initialValues} from './formConfiguration';
import {FormikOpportunityMainForm} from '../..';
//helpers
import {getRecurrenceValues, getRepeatOpportunityModalFormValues} from './helpers';
//components
import RepeatOpportunityForm from './RepeatOpportunityForm';
import PopupSmallWhite from 'shared/components/PopupSmallWhite';
//styles
import styles from './RepeatOpportunityModal.module.scss';

type RepeatOpportunityModalProps = {
  isRepeatOpportunityModalOpen: boolean;
  popupClickHandler: () => void;
};

const RepeatOpportunityModal: FC<RepeatOpportunityModalProps> = ({popupClickHandler, isRepeatOpportunityModalOpen}) => {
  const {values, setFieldValue}: FormikOpportunityMainForm = useFormikContext();

  const submitClickHandler = (values: RepeatOpportunityModalFormValues) => {
    const recurrenceValues = getRepeatOpportunityModalFormValues(values);
    setFieldValue('repeatableOpportunity', recurrenceValues);
    popupClickHandler();
  };

  const formikInitialValues = useMemo(
    () => (!!values.repeatableOpportunity ? getRecurrenceValues(values.repeatableOpportunity) : initialValues),
    [values.repeatableOpportunity]
  );

  return (
    <PopupSmallWhite
      parentClassName={styles.popup}
      popupClickHandler={popupClickHandler}
      isShowPopup={isRepeatOpportunityModalOpen}
      withoutOverlay
    >
      <Formik
        initialValues={formikInitialValues}
        validationSchema={recurrenceFormValidationSchema}
        onSubmit={submitClickHandler}
        validateOnMount
      >
        <RepeatOpportunityForm />
      </Formik>
    </PopupSmallWhite>
  );
};

export default RepeatOpportunityModal;
