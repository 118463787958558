import {FC, useState, useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import cx from 'classnames';
//types
import {
  IGroupLocationsRequest,
  ISearchAddressesByTextResponse,
  SearchCityResponse,
  SearchStateResponse,
} from '@joc/api-gateway/lib/api-client';
//API
import {API} from 'core/API';
//hooks
import {useDebounceValue} from 'core/customHooks';
//helpers
import {generateLocationText} from '../helpers';
import {getAddressesStates, getNonEmptyAddresses} from './helpers';
//styles
import styles from './InputSearchLocation.module.scss';

type InputSearchLocationProps = {
  selectHandler: (value?: IGroupLocationsRequest) => void;
  placeholder: string;
  id?: string;
  fieldValue?: string;
};

const InputSearchLocation: FC<InputSearchLocationProps> = ({
  placeholder = '',
  id,
  fieldValue,
  selectHandler,
}: InputSearchLocationProps) => {
  const [isShowDropdown, setIsShowDropdown] = useState({isShow: false, isClose: false});
  const [addressList, setAddressList] = useState<Array<SearchStateResponse | SearchCityResponse>>();
  const [isResultSelected, setResultSelected] = useState(false);
  const [inputValue, setInputValue] = useState<string | undefined>(undefined);

  const debouncedValue = useDebounceValue(inputValue, 500);

  useEffect(() => {
    if (debouncedValue !== undefined) changeHandlerInput(debouncedValue);
  }, [debouncedValue]);

  useEffect(() => {
    if (fieldValue && inputValue === undefined) {
      setInputValue(fieldValue);
      setResultSelected(true);
    }
    if (!fieldValue && inputValue !== undefined) setInputValue('');
  }, [fieldValue]);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => document.removeEventListener('click', handleClickOutside, true);
  }, [isShowDropdown]);

  const inputRef = useRef<HTMLInputElement>(null);

  const {t} = useTranslation('volunteers');

  const changeHandlerInput = async (inputText: string) => {
    if (!inputText) selectHandler(undefined);
    if (isResultSelected) return;
    try {
      const searchAvailableLocationsText = inputText.split(' ').join(',');
      const response = await API.searchAvailableVolunteerLocationsByText(searchAvailableLocationsText, undefined);

      const addresses = getNonEmptyAddresses(response);
      const addressesStatesOnly = getAddressesStates(addresses);

      setAddressList([...addresses, ...addressesStatesOnly]);
      setIsShowDropdown({isShow: false, isClose: true});
      setIsShowDropdown({isShow: true, isClose: false});
    } catch (error) {
      console.error(error);
    }
  };

  const selectLocation = (address: ISearchAddressesByTextResponse) => {
    setIsShowDropdown({isShow: true, isClose: true});
    setInputValue(generateLocationText(address));
    selectHandler({
      stateId: address.stateId as number,
      addressId: address.id,
      countryId: address.countryId,
      ...(address.cityId && {cityId: address.cityId as number}),
    });
    setTimeout(() => {
      setIsShowDropdown({isClose: false, isShow: false});
      setAddressList([]);
    }, 200);
    setResultSelected(true);
  };

  const setFocus = (): void => {
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
      if (addressList?.length) {
        setTimeout(() => setIsShowDropdown({isShow: true, isClose: false}), 200);
      }
    }
  };

  const handleClickOutside = (event: Event) => {
    if (inputRef.current && !inputRef.current.contains(event.target as Node) && isShowDropdown.isShow) {
      setIsShowDropdown({isShow: true, isClose: true});
      setTimeout(() => setIsShowDropdown({isClose: false, isShow: false}), 200);
    }
  };

  return (
    <div className={styles.input__search}>
      <div className={styles.input__search_icon} onClick={setFocus} />
      <input
        id={id && id}
        type="text"
        value={inputValue || ''}
        placeholder={placeholder}
        ref={inputRef}
        onChange={(e) => setInputValue(e.target.value)}
        onClick={() => {
          if (inputRef && inputRef.current && addressList?.length) setIsShowDropdown({isClose: false, isShow: true});
          setResultSelected(false);
        }}
      />
      {isShowDropdown.isShow && (
        <div className={styles.dropWrap}>
          <div className={styles.listWrapper}>
            {addressList && addressList.length > 0
              ? addressList.map((el) => (
                  <div
                    key={el.id}
                    className={cx(styles.dropDown, isShowDropdown.isClose && styles.close)}
                    onClick={() => selectLocation(el as ISearchAddressesByTextResponse)}
                  >
                    {generateLocationText(el as ISearchAddressesByTextResponse)}
                  </div>
                ))
              : isShowDropdown.isShow && (
                  <div className={cx(styles.dropDown, isShowDropdown.isClose && styles.close)}>
                    {t('createNewSmartFilter.addressNotFound')}
                  </div>
                )}
          </div>
        </div>
      )}
    </div>
  );
};

export default InputSearchLocation;
