import {FC} from 'react';
import {useTranslation} from 'react-i18next';
//formik
import {Field, Form, useFormikContext} from 'formik';
//form configuration
import {UserInfoFormValues} from '../formConfiguration';
//components
import Input from 'shared/inputs/Input';
import Loader from 'shared/components/Loader';
import InputPhone from 'shared/components/FormInputs/InputPhone';
import UploadPhoto from 'components/Volunteer/UploadPhoto';
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
//styles
import styles from './UserForm.module.scss';

type UserFormParentProps = {
  isLoading: boolean;
  isOrgSuspended?: boolean;
  isDisabledEmail?: boolean;
};

const UserForm: FC<UserFormParentProps> = ({isLoading, isOrgSuspended, isDisabledEmail}) => {
  const {dirty} = useFormikContext<UserInfoFormValues>();

  const {t} = useTranslation(['form', 'messages', 'inputs', 'buttons']);

  return (
    <Form className={styles.form} title={isOrgSuspended ? t('messages:organizationSuspended') : ''}>
      <Field
        name="imagePath"
        disabled={isOrgSuspended}
        title={t(!isOrgSuspended ? 'form:uploadImage' : 'messages:organizationSuspended')}
        parentClassName={styles.upload_photo}
        component={UploadPhoto}
      />
      <Field
        name="firstName"
        label={t('form:user.firstName')}
        placeholder={t('inputs:placeholders.myNameIs')}
        disabled={isOrgSuspended}
        isLabelBold
        component={Input}
      />
      <Field
        name="lastName"
        label={t('form:user.lastName')}
        placeholder={t('inputs:placeholders.lastName')}
        disabled={isOrgSuspended}
        isLabelBold
        component={Input}
      />
      <Field
        name="email"
        label={t('form:email')}
        placeholder={t('inputs:placeholders.fillIn')}
        disabled={isOrgSuspended || isDisabledEmail}
        isLabelBold
        component={Input}
      />
      <Field
        name="phoneNumber"
        label={t('form:phoneNumber')}
        disabled={isOrgSuspended}
        isLabelBold
        component={InputPhone}
      />
      {isLoading ? (
        <Loader loadProps={{disableCenterStyle: true}} />
      ) : (
        <ButtonDefault
          submitType
          classList={['primary', 'lg']}
          title={t('buttons:button.confirm')}
          disabled={!dirty || (!isOrgSuspended && isOrgSuspended)}
        />
      )}
    </Form>
  );
};

export default UserForm;
