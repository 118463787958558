import {FC} from 'react';
// types
import {IHashtag} from 'redux/hashtag-service/types';
// components
import {Hashtag} from './Hashtag';
// interfaces
import {IDeleteHashtagModalData, IEditHashtagModalData} from 'components/HashtagModals/interface';
//styles
import styles from './index.module.scss';

interface IHashtagsProps {
  subtitle: string;
  hashtags: IHashtag[];
  handleOpenEditHashtagModal: (data?: IEditHashtagModalData | undefined) => void;
  handleOpenDeleteHashtagModal: (data?: IDeleteHashtagModalData | undefined) => void;
}

export const Hashtags: FC<IHashtagsProps> = ({
  subtitle,
  hashtags,
  handleOpenEditHashtagModal,
  handleOpenDeleteHashtagModal,
}) => {
  return (
    <div className={styles.hashtags}>
      <h3 className={styles.hashtags__title}>{subtitle}</h3>
      <div className={styles.hashtags__content}>
        <div className={styles.hashtags__list}>
          <div className={styles.hashtags__list_wrapper}>
            {hashtags.map(({id, name}) => {
              return (
                <Hashtag
                  id={id}
                  key={id}
                  name={name}
                  handleOpenEditHashtagModal={handleOpenEditHashtagModal}
                  handleOpenDeleteHashtagModal={handleOpenDeleteHashtagModal}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
