export enum DASHBOARD_TAB {
  UpcomingOpportunities = 'UpcomingOpportunities',
  PendingVolunteerApproval = 'Pending Approval',
  Vacancies = 'Vacancies',
  Volunteers = 'Volunteers',
  Students = 'Students',
  SmartGroups = 'Smart Groups',
}

export enum DASHBOARD_ITEM_IMAGE {
  RING,
  HEART,
  USER,
  USER_GROUP,
  HAMMER,
}
