import {FC} from 'react';
//components
import PolicyInfo from 'pages/Policy/PolicyInfo';
import WhiteContainer from 'shared/components/WhiteContainer';
import PopupContainer from 'shared/components/PopupContainer';

type PrivacyPolicyPopupProps = {
  setIsShowPopup: () => void;
};

const PrivacyPolicyPopup: FC<PrivacyPolicyPopupProps> = ({setIsShowPopup}: PrivacyPolicyPopupProps) => {
  return (
    <PopupContainer setIsShowPopup={setIsShowPopup} isFixedStyle isDisablePadding isDisableContentMarginTop>
      <WhiteContainer title="Privacy Policy">
        <PolicyInfo />
      </WhiteContainer>
    </PopupContainer>
  );
};

export default PrivacyPolicyPopup;
