import i18n from 'components/translate';

export const GROUP_FILTER_TYPES = {
  locations: 'locations',
  ages: 'ages',
  gender: 'gender',
  schoolGrades: 'schoolGrades',
  spendTime: 'spendTime',
  customVolunteers: 'customVolunteers',
};

export const GROUP_FILTER_PLACEHOLDERS = {
  locations: i18n.t('tableHeaders:location'),
  ages: i18n.t('tableHeaders:age'),
  gender: i18n.t('tableHeaders:gender'),
  schoolGrades: i18n.t('tableHeaders:grade'),
  spendTime: i18n.t('tableHeaders:timeSpend'),
  customVolunteers: i18n.t('tableHeaders:createOwn'),
};

export const SMART_GROUP_SCHOOL_FILTER_TYPES = [
  {filterType: GROUP_FILTER_TYPES.locations, placeholder: GROUP_FILTER_PLACEHOLDERS.locations},
  {filterType: GROUP_FILTER_TYPES.ages, placeholder: GROUP_FILTER_PLACEHOLDERS.ages},
  {filterType: GROUP_FILTER_TYPES.gender, placeholder: GROUP_FILTER_PLACEHOLDERS.gender},
  {filterType: GROUP_FILTER_TYPES.schoolGrades, placeholder: GROUP_FILTER_PLACEHOLDERS.schoolGrades},
  {filterType: GROUP_FILTER_TYPES.spendTime, placeholder: GROUP_FILTER_PLACEHOLDERS.spendTime},
  {filterType: GROUP_FILTER_TYPES.customVolunteers, placeholder: GROUP_FILTER_PLACEHOLDERS.customVolunteers},
];
export const SMART_GROUP_FILTER_TYPES = SMART_GROUP_SCHOOL_FILTER_TYPES.filter(
  (filter) => filter.filterType !== GROUP_FILTER_TYPES.schoolGrades
);
