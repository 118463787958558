import {Channel, ChannelFilters, ChannelOptions, ChannelSort, DefaultGenerics} from 'stream-chat';
//types
import {UserToConnectType} from 'redux/chat-service/types';
import {MESSAGES_FILTER_OPTION_VALUE, MemberType, MessagesFilterOption} from './types';
//translate
import i18n from 'components/translate';

export const MAX_CHAT_CREATE_LIMIT = 59;

export const options: ChannelOptions = {state: true, watch: true, presence: true, limit: 10};

export const sort: ChannelSort = {
  has_unread: -1,
  last_message_at: -1,
  created_at: -1,
  updated_at: -1,
  cid: 1,
};

export const defaultMessagesFilterOptions: Array<MessagesFilterOption> = [
  {value: MESSAGES_FILTER_OPTION_VALUE.UNREAD, checked: false},
];

export const getBroadcastingMessageText = (
  membersAmount: number,
  amountOfBroadcastBatchGroups: number,
  currentBatchGroupIndex: number
) =>
  `${i18n.t('chat:broadcastingInfo.broadcastingMessages')} (${membersAmount}). ${i18n.t(
    'chat:broadcastingInfo.pleaseDontCloseWindow'
  )} (${amountOfBroadcastBatchGroups - currentBatchGroupIndex} ${i18n.t('chat:broadcastingInfo.minutesLeft')})`;

// Array of Promises to create chats with users
export const bulkChatCreate = async (
  members: Array<MemberType>,
  chatCreateHandler: (member: MemberType, isUploadAttachments?: boolean) => Promise<void>,
  currentBatchGroupIndex: number,
  attachmentsToBroadcast?: Array<File>
): Promise<Array<Promise<void>>> => {
  let createChannelRequestMembers = members;

  // Current limit for sending message to a chat and uploading images is 60 per minute (in total)
  // To bypass the image uploading limit, images are uploaded a single time to the first created chat
  // The first chat has to be awaited or else the images will not be set
  // Then, uploaded images are set to the broadcast object and sent to the rest of users
  if (attachmentsToBroadcast && currentBatchGroupIndex === 0) {
    await chatCreateHandler(members[0], true);
    createChannelRequestMembers = members.slice(1);
  }

  return createChannelRequestMembers.map((member) => chatCreateHandler(member));
};

export const getMembersBatchGroup = (members: Array<MemberType>, currentBatchGroupIndex: number): Array<MemberType> => {
  return [...members].slice(
    currentBatchGroupIndex * MAX_CHAT_CREATE_LIMIT,
    currentBatchGroupIndex * MAX_CHAT_CREATE_LIMIT + MAX_CHAT_CREATE_LIMIT
  );
};

export const getChannelRenderFilterFn = (isUnread: boolean) => (channels: Array<Channel<DefaultGenerics>>) => {
  if (isUnread) return channels.filter((channel) => channel.countUnread() > 0);
  return channels;
};

export const getFilters = (userToConnect: UserToConnectType | null): ChannelFilters<DefaultGenerics> | undefined => {
  if (!userToConnect) {
    return undefined;
  }

  return {
    type: 'messaging',
    members: {$in: [userToConnect.id!]},
  };
};

export const getIsUnread = (messagesFilterOptions: Array<MessagesFilterOption>) => {
  return messagesFilterOptions.some(
    (filterOption) => filterOption.value === MESSAGES_FILTER_OPTION_VALUE.UNREAD && filterOption.checked
  );
};
