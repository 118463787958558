import {FC} from 'react';
//types
import {FieldBaseProps} from 'core/types';
import {SelectOption} from './types';
//components
import FieldWrapper from '../FieldWrapper';
import SelectBase from './SelectBase';

type SelectProps = FieldBaseProps<{
  options: Array<SelectOption>;
  placeholder?: string;
  isOrganization?: boolean;
}>;

const Select: FC<SelectProps> = ({isOrganization = false, options, placeholder, ...fieldBaseProps}) => {
  const {field, form, disabled} = fieldBaseProps;
  const {setFieldValue, setFieldTouched} = form;

  const selectChangeHandler = (value: SelectOption['value']) => {
    setFieldValue(field.name, value);
  };

  const selectBlurHandler = () => {
    setFieldTouched(field.name, true);
  };

  return (
    <FieldWrapper {...fieldBaseProps}>
      <SelectBase
        id={field.name}
        options={options}
        disabled={disabled}
        value={field.value}
        placeholder={placeholder}
        onBlur={selectBlurHandler}
        onChange={selectChangeHandler}
        isOrganization={isOrganization}
      />
    </FieldWrapper>
  );
};
export default Select;
