import {Dispatch, FC, SetStateAction, useMemo, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
//redux
import {setError} from 'redux/error-service/action';
import {resetCreateOrgInfo, setContactOrg} from 'redux/organization-service/actions';
import {selectorGetCreateOrgInfo} from 'redux/organization-service/selector';
//formik
import {Formik} from 'formik';
//API
import {API} from 'core/API';
//urls
import {urls} from 'core/appUrls';
//types
import {CreateNewOrganisationRequest, GetLocationIdByAddressRequest} from '@joc/api-gateway';
import {SIGNUP_ERROR} from 'core/types';
//form configuration
import {ContactOrgSignupFormValues, getInitialValues, validationSchema} from './formConfiguration';
//components
import ContactSignupForm from './ContactSignupForm';

type ContactOrgSignupFormProps = {
  setSignupError: Dispatch<SetStateAction<SIGNUP_ERROR>>;
};

const ContactOrgSignupForm: FC<ContactOrgSignupFormProps> = ({setSignupError}) => {
  const history = useHistory();

  const dispatch = useDispatch();

  const orgData = useSelector(selectorGetCreateOrgInfo);

  const [isLoading, setIsLoading] = useState(false);

  const initialValues = useMemo(() => getInitialValues(), []);

  const submitClickHandler = async (values: ContactOrgSignupFormValues) => {
    dispatch(setContactOrg(values));
    orgData.orgData.organizationEmail = values.organizationEmail;
    orgData.orgData.organizationPhoneNumber = values.organizationPhoneNumber;
    orgData.orgData.organizationWebSite = values.organizationWebSite;
    orgData.orgData.organisationAddress = GetLocationIdByAddressRequest.fromJS(values.address);
    setIsLoading(true);

    try {
      if (orgData.owner.imagePath === '') {
        orgData.owner.imagePath = undefined;
      }

      const result = await API.createNewOrganisation(CreateNewOrganisationRequest.fromJS(orgData));
      localStorage.setItem('organisationId', `${result.id}`);
      result.organisationGeneralType === 'SCHOOL' && localStorage.setItem('school', 'true');
      dispatch(resetCreateOrgInfo());
      setIsLoading(false);
      history.push(urls.tariffPlans);
    } catch (error) {
      if (error?.response?.code === 409) {
        setSignupError(SIGNUP_ERROR.EMAIL_ALREADY_EXISTS);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        dispatch(setError(error?.response?.message || error.message, error.code));
        dispatch(resetCreateOrgInfo());
      }
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={submitClickHandler}
    >
      <ContactSignupForm isLoading={isLoading} />
    </Formik>
  );
};

export default ContactOrgSignupForm;
