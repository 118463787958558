import {FC, MouseEvent, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router';
import {Formik} from 'formik';
//API
import {API} from 'core/API';
//redux
import {setError} from 'redux/error-service/action';
//urls
import {urls} from 'core/appUrls';
//types
import {IOrganisationTinyResponse} from '@joc/api-gateway';
//context
import {getVolunteerInfoContextValue, VolunteerInfoContextProvider} from './context';
//form configuration
import {iniitalValues, VolunteerInfoFormValues, validationSchema} from './formConfiguration';
//helpers
import {createClaim, getIsParsedOrganisationActive, registerVolunteer} from './helpers';
//components
import Terms from './TermsPopup';
import CookiePopup from './CookiePopup';
import PrivacyPolicyPopup from './PrivacyPolicyPopup';
import VolunteerInfoMainForm from './VolunteerInfoMainForm';

const VolunteerInfoForm: FC = () => {
  const history = useHistory();

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [isShowTermsPopup, setIsShowTermsPopup] = useState(false);
  const [isShowCookieUsePopup, setIsShowCookieUsePopup] = useState(false);
  const [isShowPrivacyPolicyPopup, setIsShowPrivacyPolicyPopup] = useState(false);
  const [localStorageUserData] = useState(localStorage.getItem('userFirebaseInfo'));
  const [currentInitialValues, setCurrentInitialValues] = useState<VolunteerInfoFormValues>(iniitalValues);
  const [activeOrganisations, setActiveOrganisations] = useState<Array<IOrganisationTinyResponse>>();
  const [isOrganisationReadonly, setIsOrganisationReadonly] = useState(false);
  const [isStudentIdReadonly, setIsStudentIdReadonly] = useState(false);

  const termsClickHandler = (e: MouseEvent) => {
    e.preventDefault();
    setIsShowTermsPopup(!isShowTermsPopup);
  };
  const cookieUseClickHandler = (e: MouseEvent) => {
    e.preventDefault();
    setIsShowCookieUsePopup(!isShowCookieUsePopup);
  };
  const privacyPolicyClickHandler = (e: MouseEvent) => {
    e.preventDefault();
    setIsShowPrivacyPolicyPopup(!isShowPrivacyPolicyPopup);
  };

  useEffect(() => {
    (async () => {
      try {
        const newActiveOrganisations = await API.getAllOrganisationsByGuest(undefined);
        setActiveOrganisations(newActiveOrganisations);
      } catch (error) {
        dispatch(setError(error?.response?.message || error.message, error?.response?.code || error.code));
      }
    })();
  }, []);

  useEffect(() => {
    if (activeOrganisations && localStorageUserData) {
      const parsedData = JSON.parse(localStorageUserData) as VolunteerInfoFormValues;
      const isParsedOrganisationActive = getIsParsedOrganisationActive(activeOrganisations, parsedData);
      const isStudentIdExist = !!parsedData?.studentId && parsedData?.studentId !== 'null';

      if (isParsedOrganisationActive) {
        setCurrentInitialValues({
          ...currentInitialValues,
          ...parsedData,
          studentId: isStudentIdExist ? parsedData.studentId : '',
        });
        setIsOrganisationReadonly(true);
        setIsStudentIdReadonly(isStudentIdExist);
      } else {
        setCurrentInitialValues({...currentInitialValues, ...parsedData, organisationId: '', studentId: ''});
      }
    }
  }, [activeOrganisations, localStorageUserData]);

  const submitClickHandler = async (vs: VolunteerInfoFormValues) => {
    setIsLoading(true);
    try {
      const values: VolunteerInfoFormValues = {
        ...vs,
        firstName: vs.firstName?.trim(),
        lastName: vs.lastName?.trim(),
      };
      localStorage.setItem('userFirebaseInfo', JSON.stringify(values));
      const keys = Object.keys(values);
      const objValues = Object.values(values).map((value) => (value === '' ? undefined : value));
      // eslint-disable-next-line no-return-assign
      keys.forEach((key, ind) => ((values as any)[key] = objValues[ind]));
      // TODO: [JF-1138] switch api endpoint to 1140 if this is user

      if (localStorageUserData && JSON.parse(localStorageUserData).invitationId) {
        await registerVolunteer(values);
        localStorage.setItem('volunteer', 'true');
        history.push(`${urls.onboardingLoginFromEmail}?email=${values.email}`);
      } else {
        await createClaim(values);
        history.push(urls.onboardingClaimPending);
      }
    } catch (error) {
      dispatch(setError(error?.response?.message || error.message, error?.response?.code || error.code));
    } finally {
      setIsLoading(false);
    }
  };

  const volunteerInfoContextValue = getVolunteerInfoContextValue({
    activeOrganisations,
    isOrganisationReadonly,
    isStudentIdReadonly,
  });

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={currentInitialValues}
        validationSchema={validationSchema}
        onSubmit={submitClickHandler}
      >
        <VolunteerInfoContextProvider value={volunteerInfoContextValue}>
          <VolunteerInfoMainForm
            isLoading={isLoading}
            termsClickHandler={termsClickHandler}
            cookieUseClickHandler={cookieUseClickHandler}
            privacyPolicyClickHandler={privacyPolicyClickHandler}
          />
        </VolunteerInfoContextProvider>
      </Formik>

      {isShowTermsPopup && <Terms setIsShowPopup={() => setIsShowTermsPopup(!isShowTermsPopup)} />}
      {isShowCookieUsePopup && <CookiePopup setIsShowPopup={() => setIsShowCookieUsePopup(!isShowCookieUsePopup)} />}
      {isShowPrivacyPolicyPopup && (
        <PrivacyPolicyPopup setIsShowPopup={() => setIsShowPrivacyPolicyPopup(!isShowPrivacyPolicyPopup)} />
      )}
    </div>
  );
};

export default VolunteerInfoForm;
