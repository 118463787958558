import {FC} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {Route} from 'react-router';
//redux
import {Store} from 'redux/root';
import {selectorGetUserRoles} from 'redux/user-service/selector';
//types
import {VOLUNTEER} from '@joc/api-gateway';
//components
import NotFound from 'pages/NotFound';

type RouteGuardParentProps = {
  path: string;
  component: FC;
  accessRoles: Array<VOLUNTEER>;
};

const RouteGuard: FC<RouteGuardProps> = ({component: Component, path, accessRoles, userRoles}) => {
  return (
    <Route exact path={path}>
      {userRoles?.find((i) => i.roleName === accessRoles?.find((e) => e === i.roleName)) ? <Component /> : <NotFound />}
    </Route>
  );
};

const mapStateToProps = (store: Store) => ({
  userRoles: selectorGetUserRoles(store),
});

const connector = connect(mapStateToProps);

type RouteGuardProps = ConnectedProps<typeof connector> & RouteGuardParentProps;

export default connector(RouteGuard);
