// types
import {IOpportunityHashtagsReducer, OpportunityHashtagActionTypes} from './types';
import {CREATE_HASHTAG, DELETE_HASHTAG, EDIT_HASHTAG, GET_ALL_HASHTAGS} from 'redux/actions-types';

const initState: IOpportunityHashtagsReducer = {
  hashtags: [],
};

const opportunityHashtagsReducer = (
  state = initState,
  action: OpportunityHashtagActionTypes
): IOpportunityHashtagsReducer => {
  const {type, payload}: OpportunityHashtagActionTypes = action;

  switch (type) {
    case GET_ALL_HASHTAGS:
      return {
        ...state,
        hashtags: payload,
      };

    case CREATE_HASHTAG:
      return {
        ...state,
        hashtags: [...state.hashtags, payload],
      };

    case DELETE_HASHTAG:
      return {
        ...state,
        hashtags: state.hashtags.filter((hashtag) => hashtag.id !== payload.id),
      };

    case EDIT_HASHTAG:
      return {
        ...state,
        hashtags: state.hashtags.map((hashtag) =>
          hashtag.id === payload.id ? {...hashtag, name: payload.name} : hashtag
        ),
      };

    default:
      return state;
  }
};

export default opportunityHashtagsReducer;
