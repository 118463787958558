import {FC, ReactNode} from 'react';
import {useTranslation} from 'react-i18next';
import cx from 'classnames';
//components
import Appeal from 'shared/components/Appeal/DefaultRenderer';
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
//styles
import styles from './CongratsPopup.module.scss';

type CongratsPopupProps = {
  doneButtonClickHandler: () => void;
  subtitle: string;
  isFullHeight?: boolean;
  absoluteDisable?: boolean;
  children?: ReactNode;
  withConfirm?: boolean;
  buttonTitle?: string;
  classNames?: string;
  isConfirmMarginTop?: boolean;
  isNeedMobileHideConfirm?: boolean;
};

const CongratsPopup: FC<CongratsPopupProps> = ({
  doneButtonClickHandler,
  subtitle,
  isFullHeight,
  absoluteDisable,
  children,
  withConfirm = true,
  buttonTitle,
  classNames = '',
  isConfirmMarginTop,
  isNeedMobileHideConfirm = false,
}: CongratsPopupProps) => {
  const {t} = useTranslation(['buttons', 'messages']);

  return (
    <div className={cx(styles.container, absoluteDisable && styles.absolute_disable)}>
      <Appeal isHeightDisable={!isFullHeight} isFullHeight={isFullHeight}>
        <div className={cx(styles.appeal, classNames)}>
          <span className="title">{t('messages:congratulations')}</span>
          <span className={styles.subtitle}>{subtitle}</span>
        </div>
        {children}
        {withConfirm && (
          <div
            className={cx(styles.options, styles.confirm, {
              [styles.confirm_hide]: isNeedMobileHideConfirm,
              [styles.isConfirmMarginTop]: isConfirmMarginTop,
            })}
          >
            <ButtonDefault
              classList={['lg', 'primary']}
              title={buttonTitle || t('buttons:button.done')}
              clickHandler={doneButtonClickHandler}
            />
          </div>
        )}
      </Appeal>
    </div>
  );
};

export default CongratsPopup;
