import {FC} from 'react';
import {useSelector} from 'react-redux';
//redux
import {Store} from 'redux/root';
import {selectorGetOpportunitiesRecords} from 'redux/opportunities-service/selector';
//components
import OpportunityItem from './OpportunityItem';

interface IOpportunitiesItemsProps {
  isCompletedTab?: boolean;
  buttonFailureClickHandler?: () => void;
}

const OpportunitiesItems: FC<IOpportunitiesItemsProps> = ({buttonFailureClickHandler, isCompletedTab}) => {
  const opportunitiesRecords = useSelector((store: Store) => selectorGetOpportunitiesRecords(store));
  return (
    <>
      {opportunitiesRecords.map((opportunity) => (
        <OpportunityItem
          isCompletedTab={isCompletedTab}
          buttonFailureClickHandler={buttonFailureClickHandler}
          opportunity={opportunity}
          key={opportunity.id}
        />
      ))}
    </>
  );
};

export default OpportunitiesItems;
