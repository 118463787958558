// redux
import {Dispatch} from 'redux';
//types
import {
  RESET_FILTER,
  SET_PAGINATION,
  SET_SEARCH,
  SET_SORT,
  SET_TAB_FILTER,
  SET_VOLUNTEER_TAB_FILTER,
} from 'redux/actions-types';
import {TabFilterRequest, TabFilterType} from 'core/types';
import {IGetLocationAddressByIdResponse, DateRange} from '@joc/api-gateway';
//helpers
import {getOpportunityTypesList, getStartDay, getWhatTime, getHowFar, getFilterOrgId} from './helpers';

const filterPreparation = (
  searchBody: TabFilterType,
  userAddress?: IGetLocationAddressByIdResponse
): TabFilterRequest => {
  return {
    ...searchBody,
    organisationId: getFilterOrgId(searchBody.organisationId),
    opportunityTypesList: getOpportunityTypesList(searchBody),
    startDay: getStartDay(searchBody) as DateRange,
    whatTime: getWhatTime(searchBody),
    howFar: getHowFar(searchBody, userAddress),
    fromDate: undefined,
    toDate: undefined,
    startTime: undefined,
    endTime: undefined,
  };
};

const filterPreparationOrg = (
  searchBody: TabFilterType,
  userAddress?: IGetLocationAddressByIdResponse
): TabFilterRequest => {
  return {
    ...searchBody,
    organisationId: getFilterOrgId(searchBody.organisationId),
    opportunityTypesList: getOpportunityTypesList(searchBody),
    startDay: DateRange.fromJS(searchBody?.startDay),
    whatTime: getWhatTime(searchBody),
    howFar: getHowFar(searchBody, userAddress),
    fromDate: undefined,
    toDate: undefined,
    startTime: undefined,
    endTime: undefined,
  };
};

export const setTabFilter =
  (searchBody: any, userAddress?: IGetLocationAddressByIdResponse) => (dispatch: Dispatch) => {
    const requestBody = filterPreparation(searchBody, userAddress);
    dispatch({
      type: SET_TAB_FILTER,
      payload: requestBody,
    });
  };

export const setTabFilterOrg =
  (searchBody: any, userAddress?: IGetLocationAddressByIdResponse) => (dispatch: Dispatch) => {
    const requestBody = filterPreparationOrg(searchBody, userAddress);
    dispatch({
      type: SET_TAB_FILTER,
      payload: requestBody,
    });
  };

export const setVolunteerTabFilter =
  (searchBody: TabFilterType, userAddress?: IGetLocationAddressByIdResponse) => (dispatch: Dispatch) => {
    const requestBody = filterPreparation(searchBody, userAddress);
    dispatch({
      type: SET_VOLUNTEER_TAB_FILTER,
      payload: requestBody,
    });
  };

export const setFullTextSearch = (searchBody: any) => (dispatch: Dispatch) => {
  dispatch({type: SET_SEARCH, payload: searchBody});
};

export const setSort = (sortBody: any) => (dispatch: Dispatch) => {
  dispatch({type: SET_SORT, payload: sortBody});
};

export const setPaginationSkip = (skip: number) => (dispatch: Dispatch) => {
  dispatch({type: SET_PAGINATION, payload: skip});
};

export const resetFilter = () => (dispatch: Dispatch) => {
  dispatch({
    type: RESET_FILTER,
    payload: undefined,
  });
};
