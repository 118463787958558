export const overTariffCount = (
  volunteersTotal: number,
  volunteersCountByTariff: number,
  payGateStatus: boolean,
  length?: number
) => {
  if (length && payGateStatus)
    return length + volunteersTotal - volunteersCountByTariff > 0
      ? volunteersCountByTariff - volunteersTotal
      : undefined;
};
