import {createRef, FC, useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {selectorGetUserData} from 'redux/user-service/selector';
import {useTranslation} from 'react-i18next';
import cx from 'classnames';
//API
import {API} from 'core/API';
//redux
import {selectorGetOrgId} from 'redux/organization-service/selector';
import {selectorGetCoordinators} from 'redux/opportunities-service/selector';
import {useClickOutside} from 'core/customHooks';
//assets
import {ReactComponent as BlueArrow} from 'assets/image/selects/blue-arrow.svg';
import {ReactComponent as WhiteArrow} from 'assets/image/selects/white-arrow.svg';
//types
import {FieldBaseProps} from 'core/types';
import {SearchUsersByOrgRequest, USER_ORG_ROLES, UserResponse} from '@joc/api-gateway';
//components
import FieldWrapper from 'shared/inputs/FieldWrapper';
//styles
import styles from './index.module.scss';

type CoordinatorSelectProps = FieldBaseProps;

const CoordinatorSelect: FC<CoordinatorSelectProps> = (fieldBaseProps) => {
  const {t} = useTranslation('form');

  const {field, form} = fieldBaseProps;
  const {setFieldValue, setFieldTouched} = form;

  const orgId = useSelector(selectorGetOrgId);
  const coordinators = useSelector(selectorGetCoordinators);

  const userInfo = useSelector(selectorGetUserData);

  const [options, setOptions] = useState<Array<UserResponse>>([]);

  const isUserCoordinatorOrAdmin = useMemo(
    () =>
      !!userInfo?.roles?.find(
        ({roleName}) => roleName === USER_ORG_ROLES.ADMIN || roleName === USER_ORG_ROLES.COORDINATOR
      ),
    [userInfo]
  );

  const userOppo = useMemo(() => options.find((item) => +item.id === field.value), [field.value, options]);

  const title = useMemo(() => {
    if (!!userOppo) return `${userOppo.firstName} ${userOppo.lastName}`;
  }, [userOppo, isUserCoordinatorOrAdmin]);

  useEffect(() => {
    if (!userOppo && !!options.length) setFieldValue(field.name, null);
  }, [userOppo, options]);

  const optionRef = useMemo(() => options.map(() => createRef<HTMLDivElement>()), [options]);

  const {isComponentVisible, componentRef, setIsComponentVisible} = useClickOutside({
    initialVisible: false,
    setFieldTouched,
    fieldName: field.name,
  });

  const optionClickHandler = (id: number): void => {
    setFieldValue(field.name, id);
    setIsComponentVisible(false);
  };

  const titleClickHandler = () => {
    const valueIndex = options.findIndex((i) => +i.id === field.value);
    const target = optionRef[valueIndex]?.current;
    if (target)
      // set timeout cause in a first time the scroll doesn't work
      setTimeout(() => {
        target.scrollIntoView({block: 'start'});
      }, 0);
    if (isComponentVisible) setFieldTouched(field.name, true);
    setIsComponentVisible(!isComponentVisible);
  };

  const getCoordinators = async (organizationId: number) => {
    if (coordinators.length) {
      setOptions(coordinators);
      return;
    }

    try {
      const orgReqBody = {roleNames: [USER_ORG_ROLES.COORDINATOR, USER_ORG_ROLES.ADMIN]};
      const response = await API.organisationSearch(String(organizationId), SearchUsersByOrgRequest.fromJS(orgReqBody));
      setOptions(response.records);
    } catch (error) {
      console.error(error);
      setOptions([]);
    }
  };

  useEffect(() => {
    if (orgId) {
      getCoordinators(orgId);
    }
  }, [orgId]);

  return (
    <FieldWrapper {...fieldBaseProps}>
      <div
        className={cx(styles.dropdown_select, {
          [styles.dropdown_select__active]: isComponentVisible,
        })}
        ref={componentRef}
      >
        <div
          className={cx(styles.dropdown_select__title, {
            [styles.dropdown_select__title_selected]: !!title,
            [styles.disabledLikeInput]: fieldBaseProps.disabled,
          })}
          onClick={options.length !== 1 ? () => titleClickHandler() : undefined}
        >
          <span>{title || t('opportunity.chooseCoordinator')}</span>
          {options.length !== 1 &&
            (!isComponentVisible ? (
              <BlueArrow className={styles.dropdown_select__title__vector} />
            ) : (
              <WhiteArrow className={styles.dropdown_select__title__vector} />
            ))}
        </div>
        <div className={styles.dropdown_select__options}>
          {options.map((option: UserResponse, index: number) => (
            <div
              key={option.id}
              ref={optionRef[index]}
              className={styles.dropdown_select__options__item_coordinator}
              onClick={() => optionClickHandler(+option.id)}
            >
              <p
                className={styles.dropdown_select__options__item__text_coordinator}
              >{`${option.firstName} ${option.lastName}`}</p>
              <p className={styles.dropdown_select__options__item__email_coordinator}>{option.email}</p>
            </div>
          ))}
        </div>
      </div>
    </FieldWrapper>
  );
};

export default CoordinatorSelect;
