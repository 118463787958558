import axios from 'axios';
//API
import {API} from 'core/API';
//types
import {CreateUploadLinkRequest} from '@joc/api-gateway/lib/api-client';
import {VerificateManualOpportunityRequest} from '@joc/api-gateway';
//functions
import {convertBase64ToBlob} from 'core/functions';

export const verifyManualOpportunity = async (opportunityId: string, recipientSignatureImage: string) => {
  const signatureBlob = await convertBase64ToBlob(recipientSignatureImage);

  const signatureFileName = `signature-${opportunityId}.${signatureBlob.photo.type.split('/')[1]}`;

  const signatureFile = new File([signatureBlob.photo], signatureFileName, {type: signatureBlob.photo.type});

  const preSignedUrl = await API.createPreSignedUrl(
    CreateUploadLinkRequest.fromJS({
      fileName: signatureFile.name,
      fileType: signatureFile.type,
    })
  );

  await axios.put(preSignedUrl.upoloadLink, signatureFile, {
    headers: {
      'Access-Control-Allow-Origin': null,
      'Content-Type': signatureFile.type,
    },
  });

  await API.verificateManualOpportunity(
    +opportunityId,
    VerificateManualOpportunityRequest.fromJS({recipientSignatureImage: preSignedUrl.newFileName})
  );
};
