import {useEffect} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import qs from 'qs';
//redux
import {selectorGetUserData} from 'redux/user-service/selector';
import {selectorGetError} from 'redux/error-service/selector';
import {resetError, setError} from 'redux/error-service/action';
//types
import {ClaimJoinParsedQs} from 'core/types/queryStrings';
//constants
import {urls} from 'core/appUrls';
//helpers
import {joinToOrganisationByLink, getClaimCongratulationsLink} from './helpers';
//components
import Loader from 'shared/components/Loader';
import ResponseFailure from 'shared/components/ResponseFailure';

const Claim = () => {
  const {t} = useTranslation(['messages', 'buttons', 'form']);

  const dispatch = useDispatch();

  const history = useHistory();

  const location = useLocation();

  const userData = useSelector(selectorGetUserData);
  const error = useSelector(selectorGetError);

  const accessToken = localStorage.getItem('accessToken');
  const {organisationId} = qs.parse(location.search, {ignoreQueryPrefix: true}) as ClaimJoinParsedQs;

  useEffect(() => {
    if (accessToken) {
      if (userData) {
        (async () => {
          try {
            const {wasAttachedToOrg} = await joinToOrganisationByLink(userData, organisationId);
            const claimCongratulationsLink = await getClaimCongratulationsLink(organisationId, wasAttachedToOrg);
            history.push(claimCongratulationsLink);
          } catch (error) {
            dispatch(setError(error?.response?.message || error.message, error?.response?.code || error.code));
          }
        })();
      }
    } else {
      history.push(`${urls.onboardingLoginVolunteer}${location.search}`);
    }
  }, [userData]);

  const loginClickHandler = () => {
    dispatch(resetError());
    history.push(`${urls.onboardingLoginVolunteer}${location.search}`);
  };

  if (error.state)
    return (
      <ResponseFailure
        message={error.message}
        buttonClickHandler={loginClickHandler}
        buttonTitle={t('buttons:button.backToLogIn')}
      />
    );

  return <Loader loadProps={{stylePageCenter: true}} />;
};

export default Claim;
