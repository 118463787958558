import {FC, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
//redux
import {selectorGetOpportunitiesRecords, selectorGetOpportunitiesTotal} from 'redux/opportunities-service/selector';
import {setPaginationSkip} from 'redux/filter-service/actions';
//context
import {OpportunitiesListContext, OpportunitiesListContextType} from './OpportunitiesListContext';
//types
import {PaginationValues} from 'core/types';
//components
import Observer from 'shared/components/Observer';
import OpportunitiesItems from './OpportunitiesItems';

type OpportunitiesListProps = {
  isLoading: boolean;
  isCompletedTab?: boolean;
  isVolunteerOpportunities?: boolean;
  buttonFailureClickHandler?: () => void;
};

const OpportunitiesList: FC<OpportunitiesListProps> = ({
  isLoading,
  isCompletedTab = false,
  isVolunteerOpportunities,
  buttonFailureClickHandler,
}) => {
  const dispatch = useDispatch();

  const [errorMsg, setErrorMsg] = useState('');

  const opportunitiesRecords = useSelector(selectorGetOpportunitiesRecords);
  const opportunitiesTotal = useSelector(selectorGetOpportunitiesTotal);

  const opportunitiesListContextValue: OpportunitiesListContextType = {
    setErrorMsg,
    errorMsg,
    isVolunteerOpportunities,
  };

  const paginationSkipHandler = ({skip}: PaginationValues) => dispatch(setPaginationSkip(skip));

  return (
    <OpportunitiesListContext.Provider value={opportunitiesListContextValue}>
      <OpportunitiesItems isCompletedTab={isCompletedTab} buttonFailureClickHandler={buttonFailureClickHandler} />
      {opportunitiesRecords.length < opportunitiesTotal && !errorMsg && (
        <Observer
          paginationSkip={opportunitiesRecords.length}
          setPagination={paginationSkipHandler}
          isLoading={isLoading}
        />
      )}
    </OpportunitiesListContext.Provider>
  );
};

export default OpportunitiesList;
