import {VOLUNTEERS_FILTERS_VALUES} from 'core/types/index';

export const INITIAL_VOLUNTEERS_FILTERS_VALUES: VOLUNTEERS_FILTERS_VALUES = {
  minAge: 0,
  maxAge: 0,
  genderId: 0,
  howFar: 0,
  fromDate: null,
  toDate: null,
};
