import {Dispatch, FC, SetStateAction, useEffect, useMemo} from 'react';
//types
import {
  OPPORTUNITY_VOLUNTEER_STATUS,
  GetVolunteerByIdResponse,
  IOpportunityResponse,
  OPPORTUNITY_STATUSES,
  OPPORTUNITY_TYPES,
} from '@joc/api-gateway';
//components
import LotsVolunteers from './PossibleVolunteersRenderer/LotsVolunteers';
import SingleVolunteer from './PossibleVolunteersRenderer/SingleVolunteer';
//styles
import styles from './PossibleVolunteersItem.module.scss';
import {VolunteerLimit} from '../VolunteerLimit/VolunteerLimit';

type PossibleVolunteersItemProps = {
  volunteers: Array<GetVolunteerByIdResponse> | undefined;
  opportunityId: number;
  isApprovalRequired: boolean;
  opportunityName: string;
  opportunityStatus: OPPORTUNITY_STATUSES;
  actionsDisable?: boolean;
  setIsPreviewOpen?: Dispatch<SetStateAction<boolean>>;
  opportunityType: OPPORTUNITY_TYPES;
  isViewVolunteersDisable?: boolean;
  isShowVolunteerLimit?: boolean;
  opportunity: IOpportunityResponse;
};

const PossibleVolunteersItem: FC<PossibleVolunteersItemProps> = ({
  opportunity,
  volunteers,
  opportunityId,
  isApprovalRequired,
  opportunityName,
  actionsDisable,
  setIsPreviewOpen,
  opportunityStatus,
  opportunityType,
  isViewVolunteersDisable,
  isShowVolunteerLimit,
}: PossibleVolunteersItemProps) => {
  const activeVolunteers = volunteers?.filter((volunteer) => volunteer?.status !== OPPORTUNITY_VOLUNTEER_STATUS.REJECT);

  const volunteersOrOrganization = isViewVolunteersDisable ? activeVolunteers?.length === 1 : volunteers?.length === 1;

  const isVisibleVolunteersLimit = useMemo(
    () => Boolean(opportunity.volunteersMaxCount) && isShowVolunteerLimit,
    [opportunity]
  );

  const filteredVolunteerPhoto = useMemo(
    () =>
      activeVolunteers?.filter((volunteer) => volunteer?.status !== OPPORTUNITY_VOLUNTEER_STATUS.CANT_MAKE_IT)[0]
        ?.imagePath || '',
    [activeVolunteers]
  );

  const volunteerName = (firstName = '', lastName = '') => `${firstName} ${lastName}`.trim();

  if (!activeVolunteers || !activeVolunteers.length)
    return (
      <div className={styles.empty}>
        {isVisibleVolunteersLimit && (
          <VolunteerLimit
            isCentrePosition
            maxVolunteers={opportunity.volunteersMaxCount || 0}
            currentVolunteers={opportunity.volunteersCount || 0}
          />
        )}
      </div>
    );

  if (volunteersOrOrganization)
    return (
      <SingleVolunteer
        opportunity={opportunity}
        isShowVolunteerLimit={isShowVolunteerLimit}
        opportunityName={opportunityName}
        volunteerId={activeVolunteers[0].id}
        opportunityId={opportunityId}
        isApprovalRequired={isApprovalRequired}
        photo={filteredVolunteerPhoto}
        volunteerName={volunteerName(activeVolunteers[0]?.firstName, activeVolunteers[0]?.lastName)}
        volunteersArray={volunteers || []}
        volunteerStatus={activeVolunteers[0]?.status}
        isOptionsDisable={actionsDisable}
        opportunityStatus={opportunityStatus}
        setIsPreviewOpen={setIsPreviewOpen}
        volunteerCreatedDate={activeVolunteers[0].createDate}
        volunteerOrganizations={activeVolunteers[0].organizations}
        opportunityType={opportunityType}
        isViewVolunteersDisable={isViewVolunteersDisable}
      />
    );

  return (
    <LotsVolunteers
      opportunity={opportunity}
      isShowVolunteerLimit={isShowVolunteerLimit}
      opportunityName={opportunityName}
      opportunityId={opportunityId}
      volunteersArray={volunteers || []}
      isOptionsDisable={actionsDisable}
      setIsPreviewOpen={setIsPreviewOpen}
      opportunityStatus={opportunityStatus}
      opportunityType={opportunityType}
      isViewVolunteersDisable={isViewVolunteersDisable}
      isApprovalRequired={isApprovalRequired}
      activeVolunteers={isViewVolunteersDisable ? activeVolunteers : volunteers}
    />
  );
};

export default PossibleVolunteersItem;
