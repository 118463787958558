import qs from 'qs';
//types
import {IDateRange} from '@joc/api-gateway';

const dateMonthAgo = new Date(new Date().setMonth(new Date().getMonth() - 1));

export const getDefaultDateRange = (startYearDate?: Date, endYearDate?: Date) => {
  let fromDate = dateMonthAgo;
  let toDate = new Date();

  if (startYearDate && endYearDate) {
    const localTimeZone = new Date().getTimezoneOffset();
    fromDate = new Date(startYearDate.getTime() + localTimeZone * 60000);
    toDate = new Date(endYearDate.getTime() + localTimeZone * 60000);
  }

  return {fromDate, toDate};
};

export const getParsedDateRange = (urlToParse: string): IDateRange | null => {
  const parsedDateRange = qs.parse(urlToParse, {ignoreQueryPrefix: true});
  return parsedDateRange.fromDate && parsedDateRange.toDate
    ? {
        fromDate: new Date(parsedDateRange.fromDate as string),
        toDate: new Date(parsedDateRange.toDate as string),
      }
    : null;
};
