import {SmartGroupFormValues} from '../../formConfiguration';

export const ageTemplate = new Array(66).fill(0).map((_value, index) => index + 10);

const getMinOptions = (ageMax?: number) => {
  if (ageMax) return ageTemplate.filter((age) => age <= ageMax);
  return ageTemplate;
};

const getMaxOptions = (ageMin?: number) => {
  if (ageMin) return ageTemplate.filter((age) => age >= ageMin);
  return ageTemplate;
};

export const getAgeOptions = (value?: SmartGroupFormValues['ages']) => ({
  minOptions: getMinOptions(value?.max).map((age) => ({value: String(age), title: String(age)})),
  maxOptions: getMaxOptions(value?.min).map((age) => ({value: String(age), title: String(age)})),
});
