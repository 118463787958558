//i18n
import i18n from 'components/translate';

const validationT = i18n.getFixedT(null, 'validation');

export const WEEK = validationT('form:opportunity.weeks');
export const DAY = validationT('form:opportunity.days');
export const MONTH = validationT('form:opportunity.months');

export type RecurrenceLabel = typeof WEEK | typeof DAY | typeof MONTH;

export const frequencyLabels: Array<RecurrenceLabel> = [DAY, WEEK, MONTH];
