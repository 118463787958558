import React, {FC} from 'react';
import cx from 'classnames';
import Appeal from 'shared/components/Appeal/DefaultRenderer';
import OnboardingContentContainer from 'shared/components/OnboardingContentContainer';
import styles from './BadGateway.module.scss';

const BadGateway: FC = () => {
  return (
    <OnboardingContentContainer disableOnboardingLogoLink>
      <Appeal styleThink isHeightDisable>
        <div className={cx('title', styles.title)}>502 Bad Gateway</div>
      </Appeal>
    </OnboardingContentContainer>
  );
};

export default BadGateway;
