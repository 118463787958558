import {FC, useCallback, useEffect, useRef} from 'react';
//constants
import {INITIAL_PAGINATION} from 'core/constants';
//types
import {PaginationValues} from 'core/types';
//components
import Loader from '../Loader';
//styles
import styles from './Observer.module.scss';

type ObserverParentProps = {
  paginationSkip: number;
  setPagination: (pagination: PaginationValues) => void;
  isLoading: boolean;
};

const Observer: FC<ObserverParentProps> = ({paginationSkip, setPagination, isLoading}) => {
  const observerRef = useRef<HTMLTableRowElement>(null);

  const addMoreOpportunities = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      entries.map((entry: IntersectionObserverEntry) => {
        if (entries[0].intersectionRatio <= 0) return;
        if (entry.isIntersecting) {
          setPagination({skip: paginationSkip, take: INITIAL_PAGINATION.take});
        }
      });
    },
    [paginationSkip]
  );

  useEffect(() => {
    const observerOptions = {
      threshold: 1,
    };
    const observer = new IntersectionObserver(addMoreOpportunities, observerOptions);
    if (observerRef.current && paginationSkip) observer.observe(observerRef.current);

    return () => {
      if (observerRef.current) observer.unobserve(observerRef.current);
    };
  }, [paginationSkip]);

  return (
    <div className={styles.container}>
      <div ref={observerRef} className={styles.observer} />
      {isLoading && <Loader loadProps={{parentClassName: styles.loader}} />}
    </div>
  );
};

export default Observer;
