import {forwardRef, useCallback, useRef} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
//redux
import {
  selectorGetCountOpportunitiesInSchoolYear,
  selectorGetDashboardOpportunitiesAll,
  selectorGetDashboardOpportunitiesPending,
  selectorGetDashboardOpportunitiesTotalHours,
  selectorGetDashboardOpportunitiesUpcoming,
  selectorGetDashboardOpportunitiesVacancies,
  selectorGetDashboardSmartGroups,
  selectorGetDashboardTariffVolunteers,
  selectorGetDashboardVolunteers,
  selectorGetDashboardVolunteersClaimsCount,
  selectorGetVolunteersHoursThisYear,
} from 'redux/dashboard-service/selector';
import {selectorGetUserRoles} from 'redux/user-service/selector';
import {
  selectorGetOrgGeneralType,
  selectorGetSchoolId,
  selectorPayGateStatus,
} from 'redux/organization-service/selector';
//urls
import {urls} from 'core/appUrls';
//constants
import {ORGANIZATION_TABS} from 'core/constants';
//types
import {DASHBOARD_ITEM_IMAGE} from 'core/types';
//helpers
import {getIsUserAdminOrCoordinator, getOrganisationInfo, getRedirectUrlWithTabHelper} from './helpers';
//components
import ReminderTape from 'components/Organization/ReminderTape';
import DashboardItem from '../DashboardItem';
//styles
import styles from './DashboardTable.module.scss';
import {ORGANISATION_GENERAL_TYPES} from '@joc/api-gateway';

const DashboardTable = forwardRef<HTMLDivElement>(() => {
  const {t} = useTranslation(['common', 'chesedOpportunities', 'students', 'volunteers']);

  const ref = useRef(null);

  const opportunitiesTotal = useSelector(selectorGetDashboardOpportunitiesAll);
  const opportunitiesUpcoming = useSelector(selectorGetDashboardOpportunitiesUpcoming);
  const opportunitiesPending = useSelector(selectorGetDashboardOpportunitiesPending);
  const opportunitiesVacancies = useSelector(selectorGetDashboardOpportunitiesVacancies);
  const countOpportunitiesInSchoolYear = useSelector(selectorGetCountOpportunitiesInSchoolYear);
  const totalHours = useSelector(selectorGetDashboardOpportunitiesTotalHours);
  const volunteersGroups = useSelector(selectorGetDashboardSmartGroups);
  const volunteersTotal = useSelector(selectorGetDashboardVolunteers);

  // Temporary solution with disabling pay gate
  const volunteersCountByTariff = useSelector(selectorGetDashboardTariffVolunteers);
  const payGateStatus = useSelector(selectorPayGateStatus);

  const userRoles = useSelector(selectorGetUserRoles);
  const schoolId = useSelector(selectorGetSchoolId);
  const signUpRequestsCount = useSelector(selectorGetDashboardVolunteersClaimsCount);
  const hoursInSchoolYear = useSelector(selectorGetVolunteersHoursThisYear);
  const orgGeneralType = useSelector(selectorGetOrgGeneralType);

  const getRedirectUrlWithTab = useCallback((tab: ORGANIZATION_TABS) => getRedirectUrlWithTabHelper(tab), []);
  const volunteersSignUpRequestsUrl = `${urls.orgVolunteers}?showSignupRequests=true`;

  const isUserAdminOrCoordinator = getIsUserAdminOrCoordinator(userRoles);
  const organisationInfo = getOrganisationInfo(volunteersTotal, volunteersCountByTariff, payGateStatus);

  const organisationTitle = t(`${schoolId ? 'students' : 'volunteers'}:title`);
  const totalHoursTitle = t('common:titles.totalHours');
  const smartGroupsTitle = t('common:titles.smartGroups');
  const vacanciesTitle = t('chesedOpportunities:vacancies');
  const pendingTitle = t('chesedOpportunities:pendingApproval');
  const upcomingTitle = t('common:titles.upcomingOpportunities');
  const totalOpportunitiesTitle = t('common:titles.totalOpportunities');
  const chesedOpportunitiesTitle = t('chesedOpportunities:title');
  const signUpRequestsTitle = t('volunteers:signupRequests');
  const thisYearTitle = t('chesedOpportunities:thisYear');

  const isSchool = orgGeneralType === ORGANISATION_GENERAL_TYPES.SCHOOL;

  return (
    <div ref={ref} className={styles.container}>
      <div className={styles.dashboard__grid}>
        <div className={styles.dashboard__main}>
          <div className={styles.dashboard__row}>
            <div className={styles.dashboard__rowTitle}>{chesedOpportunitiesTitle}</div>

            <div className={styles.dashboard__rowMain}>
              <DashboardItem
                title={totalOpportunitiesTitle}
                image={DASHBOARD_ITEM_IMAGE.HEART}
                count={opportunitiesTotal}
                urlTo={getRedirectUrlWithTab(ORGANIZATION_TABS.ALL)}
                isAdditionalFields={isSchool}
                additionalTitle={thisYearTitle}
                additionalCount={countOpportunitiesInSchoolYear}
              />
              <DashboardItem
                title={upcomingTitle}
                image={DASHBOARD_ITEM_IMAGE.HEART}
                count={opportunitiesUpcoming}
                urlTo={getRedirectUrlWithTab(ORGANIZATION_TABS.UPCOMING)}
              />
              <DashboardItem
                title={pendingTitle}
                count={opportunitiesPending}
                urlTo={getRedirectUrlWithTab(ORGANIZATION_TABS.PENDING)}
              />
              <DashboardItem
                title={vacanciesTitle}
                image={DASHBOARD_ITEM_IMAGE.RING}
                count={opportunitiesVacancies}
                urlTo={getRedirectUrlWithTab(ORGANIZATION_TABS.VACANCIES)}
              />
            </div>
          </div>

          <div className={styles.dashboard__row}>
            <div className={styles.dashboard__rowTitle}>{organisationTitle}</div>

            <div className={styles.dashboard__rowMain}>
              <DashboardItem
                title={totalHoursTitle}
                image={DASHBOARD_ITEM_IMAGE.USER}
                count={totalHours}
                urlTo={urls.orgStatistics}
                isAdditionalFields={isSchool}
                additionalTitle={thisYearTitle}
                additionalCount={hoursInSchoolYear}
              />
              <DashboardItem
                title={organisationTitle}
                image={DASHBOARD_ITEM_IMAGE.USER}
                count={organisationInfo}
                urlTo={urls.orgVolunteers}
              />
              <DashboardItem
                title={smartGroupsTitle}
                image={DASHBOARD_ITEM_IMAGE.USER_GROUP}
                count={volunteersGroups}
                urlTo={urls.orgVolunteers}
              />
              {signUpRequestsCount ? (
                <DashboardItem
                  title={signUpRequestsTitle}
                  image={DASHBOARD_ITEM_IMAGE.USER}
                  count={signUpRequestsCount}
                  urlTo={volunteersSignUpRequestsUrl}
                />
              ) : null}
            </div>
          </div>
        </div>

        {isUserAdminOrCoordinator && (
          <div className={styles.dashboard__sidebar}>
            <ReminderTape />
          </div>
        )}
      </div>
    </div>
  );
});

DashboardTable.displayName = 'DashboardTable';

export default DashboardTable;
