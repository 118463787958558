import {FC} from 'react';
import {useTranslation} from 'react-i18next';
//functions
import {distanceViewer} from 'core/functions';
//types
import {IMAGE_WITH_POPUP_TYPE} from 'core/types';
import {IOpportunityResponse} from '@joc/api-gateway';
//helpers
import {getVolunteerImagePath} from './helpers';
//components
import ImageWithPopup from 'shared/components/ImageWithPopup';
//styles
import styles from './Renderers.module.scss';

type ImageProps = {
  type: IMAGE_WITH_POPUP_TYPE;
  imagePath?: string;
  organizationLogoPath?: string;
  isManual?: boolean;
};

export const Image: FC<ImageProps> = ({imagePath, organizationLogoPath, type, isManual}) => {
  const {t} = useTranslation('popup');

  return (
    <div className={styles.image}>
      {imagePath ? (
        <>
          <ImageWithPopup
            imagePath={imagePath}
            classNames={styles.image__logo}
            alt="opportunity logo"
            popupTitle={t('opportunity.pictureOfOppo')}
            type={type}
          />
          <ImageWithPopup
            imagePath={organizationLogoPath}
            classNames={styles.image__logo_min}
            alt="organization opportunity logo"
            popupTitle={t('profilePicture.organization')}
            type={type}
          />
        </>
      ) : (
        <>
          <ImageWithPopup
            imagePath={getVolunteerImagePath(type, organizationLogoPath)}
            classNames={styles.image__logo}
            alt="organization opportunity logo"
            type={type}
            popupTitle={t('profilePicture.organization')}
          />
          {isManual && (
            <ImageWithPopup
              imagePath={organizationLogoPath}
              classNames={styles.image__logo_min}
              alt="organization opportunity logo"
              type={type}
              popupTitle={t('profilePicture.organization')}
            />
          )}
        </>
      )}
    </div>
  );
};

type DistanceInfoProps = {
  userVolunteerId: string | undefined;
  opportunity: IOpportunityResponse;
};

export const DistanceInfo: FC<DistanceInfoProps> = ({userVolunteerId, opportunity}) => {
  const distanceViewerInfo = distanceViewer(userVolunteerId, opportunity);
  const isShowDistanceViewerInfo = distanceViewerInfo !== '';

  return <>{isShowDistanceViewerInfo && distanceViewerInfo}</>;
};
