import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import cx from 'classnames';
//assets
import {ReactComponent as JOCLogoSVG} from 'assets/image/logo2.svg';
//functions
import {getFileType} from 'core/functions';
//redux
import {selectorGetPublicDashboardData} from 'redux/publicDashboard-service/selector';
//helpers
import {getCountFontSize} from './helpers';
//components
import UserIconDefault from 'shared/components/UserIconDefault';
//styles
import styles from './styles.module.scss';
import {useLocation} from 'react-router';

const Board = () => {
  const location = useLocation();
  const {t} = useTranslation(['common', 'tableHeaders', 'chesed']);

  const {
    title,
    subTitle,
    mediaPathes,
    organizationAvatar,
    organizationName,
    opportunitiesCount,
    spendTimeByOrganization,
    spendTimeByAll,
    recentlyOppoName,
    recentlyVolunteerAvatar,
    recentlyVolunteerGrade,
    recentlyVolunteerName,
  } = useSelector(selectorGetPublicDashboardData);

  const [isAvatarValid, setIsAvatarValid] = useState(false);
  const [isSchool, setIsSchool] = useState(false);

  useEffect(() => {
    setIsAvatarValid(!!recentlyVolunteerAvatar);
  }, [recentlyVolunteerAvatar]);

  useEffect(() => {
    if (location.search) {
      const query = new URLSearchParams(location.search);
      const queryEmail = query.get('type');
      setIsSchool(queryEmail === 'school');
    }
  }, [location.search]);

  const imgErrorHandler = () => {
    setIsAvatarValid(false);
  };

  const mediaPathType = getFileType(mediaPathes[0]);

  return (
    <div className={styles.container}>
      <div className={styles.board}>
        <div className={styles.top}>
          <div className={styles.top__organization}>
            <img className={styles.top__organization__avatar} src={organizationAvatar} alt="avatar" />
            <span className={styles.top__organization__name} title={organizationName}>
              {organizationName}
            </span>
          </div>
          <div className={styles.top__title} title={title}>
            {title}
          </div>
        </div>

        <div className={cx(styles.card, styles.card_first)}>
          <span className={styles.card__title}>{t('common:titles.totalHours')}</span>
          <span
            className={styles.card__count}
            title={spendTimeByOrganization.toString()}
            style={{fontSize: getCountFontSize(spendTimeByOrganization.toString())}}
          >
            {spendTimeByOrganization}
          </span>
        </div>

        <div className={cx(styles.card, styles.card_second)}>
          <span className={styles.card__title}>{t('common:titles.totalOpportunities')}</span>
          <span
            className={styles.card__count}
            title={opportunitiesCount.toString()}
            style={{fontSize: getCountFontSize(opportunitiesCount.toString())}}
          >
            {opportunitiesCount}
          </span>
        </div>

        <div className={cx(styles.card, styles.card_third)}>
          <span className={styles.card__title}>{t('common:titles.worldWideHours')}</span>
          <span
            className={styles.card__count}
            title={spendTimeByAll.toString()}
            style={{fontSize: getCountFontSize(spendTimeByAll.toString())}}
          >
            {spendTimeByAll}
          </span>
        </div>

        <div className={cx(styles.card, styles.card_forth)}>
          <span className={styles.card__title}>
            {isSchool ? t('common:titles.studentSpotlight') : t('common:titles.volunteersSpotlight')}
          </span>
          {recentlyVolunteerName ? (
            <div className={styles.card__volunteer}>
              {isAvatarValid ? (
                <img
                  className={styles.card__volunteer__avatar}
                  src={recentlyVolunteerAvatar}
                  alt="volunteer avatar"
                  onError={imgErrorHandler}
                />
              ) : (
                <UserIconDefault parentClassName={styles.card__volunteer__avatar} />
              )}
              <div className={styles.card__volunteer__info}>
                <span className={styles.card__volunteer__name} title={recentlyVolunteerName}>
                  {recentlyVolunteerName}
                </span>
                {recentlyVolunteerGrade ? (
                  <span className={styles.card__volunteer__grade}>
                    {`${t('tableHeaders:gradeId')}: ${recentlyVolunteerGrade}`}
                  </span>
                ) : null}
              </div>
            </div>
          ) : null}
          {recentlyOppoName ? (
            <span className={styles.card__subTitle} title={recentlyOppoName}>
              <span className={styles.card__subTitle_bold}>{`${t('chesed:opportunity')}: `}</span>
              {recentlyOppoName}
            </span>
          ) : null}
        </div>

        <div className={styles.bottom}>
          <JOCLogoSVG className={styles.bottom__avatar} />
          <span className={styles.bottom__subTitle} title={subTitle}>
            {subTitle}
          </span>
        </div>
        <div className={styles.media}>
          {mediaPathType === 'mp4' ? (
            <video className={styles.media__file} muted autoPlay loop>
              <source src={mediaPathes[0]} type="video/mp4" />
            </video>
          ) : (
            <img className={styles.media__file} src={mediaPathes[0]} alt="dashboard media" />
          )}
        </div>
      </div>
    </div>
  );
};

export default Board;
