import {FC, MouseEvent} from 'react';
import {useTranslation} from 'react-i18next';
//formik
import {Field, FieldMetaProps, Form, useFormikContext} from 'formik';
//constants
import {TERMS_TYPE} from 'core/constants';
//types
import {Terms, getIsSubmitDisabled} from './helpers';
//component
import Checkbox from 'shared/components/Checkbox';
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
import {DatePicker} from 'shared/components/Pickers/NewUI/DatePicker';
import GenderSelect from 'shared/components/SelectDropdown/GenderSelect';
import InputPhone from 'shared/components/FormInputs/InputPhone';
import GoogleApiAutoCompleat from 'shared/components/GoogleApiAutoComplete';
import Loader from 'shared/components/Loader';
import UploadPhoto from 'components/Volunteer/UploadPhoto';
import InputOrgGuest from 'components/Volunteer/InputOrgGuest';
import {TermsPolicyElement} from './Renderers';
import Input from 'shared/inputs/Input';
//styles
import styles from './VolunteerInfoMainForm.module.scss';
import {useVolunteerInfoContext} from '../context';

type VolunteerInfoMainFormParentsProps = {
  isLoading: boolean;
  termsClickHandler: (e: MouseEvent) => void;
  cookieUseClickHandler: (e: MouseEvent) => void;
  privacyPolicyClickHandler: (e: MouseEvent) => void;
};

const VolunteerInfoMainForm: FC<VolunteerInfoMainFormParentsProps> = ({
  isLoading,
  termsClickHandler,
  cookieUseClickHandler,
  privacyPolicyClickHandler,
}) => {
  const {t} = useTranslation(['signUpUser', 'tableHeaders', 'form', 'buttons', 'inputs']);
  const {setFieldValue, setFieldTouched, errors} = useFormikContext();
  const {isStudentIdReadonly} = useVolunteerInfoContext();

  const changeTerms = (fieldName: string, e: boolean) => {
    setFieldValue(fieldName, e);
    setFieldTouched(fieldName, true);
  };

  return (
    <Form className={styles.form}>
      <Field name="imagePath" parentClassName={styles.upload_photo} optional component={UploadPhoto} />
      <Field
        name="firstName"
        placeholder={t('inputs:new_placeholders.firstName')}
        smallLabel={`${t('form:user.firstName')}`}
        isDisabledMargin
        component={Input}
      />
      <Field
        name="lastName"
        placeholder={t('inputs:new_placeholders.lastName')}
        smallLabel={`${t('form:user.lastName')}`}
        isDisabledMargin
        component={Input}
      />
      <Field
        name="phoneNumber"
        placeholder="(000) 000-0000"
        smallLabel={`${t('inputs:placeholders.phone')} (${t('signUpUser:optional')})`}
        isDisabledMargin
        isAboutYou
        component={InputPhone}
      />
      <Field
        name="email"
        placeholder={t('signUpUser:email')}
        smallLabel={t('signUpUser:email')}
        isDisabledMargin
        component={Input}
      />
      <Field
        name="birthDate"
        placeholder="MM / DD / YYYY"
        smallLabel={`${t('inputs:labels.birthday')} (${t('signUpUser:optional')})`}
        parentClassName={styles.birthDate}
        disableFuture
        isDisabledMargin
        availablePast
        component={DatePicker}
      />
      <Field
        name="gender"
        placeholder={t('tableHeaders:select')}
        smallLabel={`${t('inputs:placeholders.gender')} (${t('signUpUser:optional')})`}
        parentClassName={styles.gender}
        isDisabledMargin
        component={GenderSelect}
      />
      <Field
        name="address"
        placeholder={t('inputs:new_placeholders.address')}
        smallLabel={`${t('signUpUser:location')} (${t('signUpUser:optional')})`}
        isDisabledMargin
        isAddressErrorText
        isInputValidation
        component={GoogleApiAutoCompleat}
      />
      <Field
        name="organisationId"
        placeholder={t('tableHeaders:select')}
        smallLabel={`${t('inputs:placeholders.school')}/${t('inputs:placeholders.organization')}`}
        isDisabledMargin
        component={InputOrgGuest}
      />

      <Field
        name="studentId"
        placeholder={t('inputs:placeholders.idNumber')}
        smallLabel={`${t('inputs:placeholders.idNumber')} (${t('signUpUser:optional')})`}
        component={Input}
        disabled={isStudentIdReadonly}
      />

      <div className={styles.terms}>
        <Field name="correctInfo">
          {({meta}: {meta: FieldMetaProps<boolean>}) => (
            <Checkbox
              changeHandle={(e) => changeTerms('correctInfo', e)}
              error={meta.touched && !!meta.error && !meta.value}
              label={t('signUpUser:confirmInfo')}
            />
          )}
        </Field>
        <Field name="privacy">
          {({meta}: {meta: FieldMetaProps<boolean>}) => (
            <Checkbox
              changeHandle={(e) => changeTerms('privacy', e)}
              error={meta.touched && !!meta.error && !meta.value}
              label={
                <TermsPolicyElement
                  termsClickHandler={termsClickHandler}
                  cookieUseClickHandler={cookieUseClickHandler}
                  privacyPolicyClickHandler={privacyPolicyClickHandler}
                />
              }
            />
          )}
        </Field>
        {/* <Checkbox
          changeHandle={(e) => setTerms({...terms, attendVolunteering: e})}
          label={t('signUpUser:confirmNotify')}
        /> */}
      </div>
      {isLoading ? (
        <Loader loadProps={{disableCenterStyle: true}} />
      ) : (
        <ButtonDefault submitType classList={['primary', 'md']} title={t('buttons:button.submit')} />
      )}
    </Form>
  );
};

export default VolunteerInfoMainForm;
