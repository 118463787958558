const {forEach, isEqual} = require('lodash');

export function getDiffOpportunities<T = {[x: string]: any}>(oldOppo: T, newOppo: T): T {
  const diff: T = {} as T;

  forEach(newOppo, (value: T[keyof T], key: keyof T) => {
    if (!isEqual(value, oldOppo[key])) {
      diff[key] = value;
    }
  });

  return diff;
}
