import {FC, Fragment, useEffect, useMemo, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import {useTranslation} from 'react-i18next';
import {Formik} from 'formik';
import moment from 'moment';
//redux
import {Store} from 'redux/root';
import {getOpportunityById, resetCurrentOpportunity, updateManualOpportunity} from 'redux/opportunities-service/action';
import {resetError, setError} from 'redux/error-service/action';
import {selectorGetOpportunitiesRecords, selectorGetOpportunityById} from 'redux/opportunities-service/selector';
//constants
import {UPDATE_MANUAL_OPPORTUNITY_INITIAL_VALUES} from 'core/constants';
//urls
import {urls} from 'core/appUrls';
//validation
import {dynamicManualOppoValidation, opportunityManualValidationSchema} from 'core/validation';
//types
import {IOpportunityResponse, UpdateManualOpportunityRequest} from '@joc/api-gateway';
//components
import ArrowBackLink from 'shared/components/ArrowBackLink';
import Appeal from 'shared/components/Appeal/DefaultRenderer';
import ResponseFailure from 'shared/components/ResponseFailure';
import CongratsPopup from 'components/Organization/CongratsPopup';
import {Item} from 'components/Volunteer/UploadSeveralPhotos/ItemPreview';
import ManualOpportunityMainForm from '../CreateManualOppoV/ManualOpportunityMainForm';
//styles
import styles from './UpdateManualOpportunity.module.scss';
import {getOpportunitySettingsForVolunteer} from '../../../redux/organization-service/actions';
import {
  selectorGetOppoSettingsForVolunteer,
  selectorLoadedStatusOppoSettingsForVolunteer,
} from '../../../redux/organization-service/selector';
import {
  IOrganisationOpportunitySettingsResponse,
  OrganisationOpportunitySettingsResponse,
} from '@joc/api-gateway/lib/api-client';
import {selectorGetHashtags} from '../../../redux/hashtag-service/selectors';

function convertFromStringToItem(filePathes: string[]): Item[] {
  return filePathes.map((path) => ({
    id: path,
    src: path,
    isChecked: false,
    isNew: false,
  }));
}

const UpdateManualOpportunity: FC = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const {t} = useTranslation(['errors', 'buttons', 'messages', 'form']);

  const [isLoading, setIsLoading] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [initValues, setInitValues] = useState<any>({
    ...UPDATE_MANUAL_OPPORTUNITY_INITIAL_VALUES,
    organisationId: '',
    endTime: '',
  });
  const orgHashtags = useSelector(selectorGetHashtags);

  const [isVisibleHashtagField, setIsVisibleHashtagField] = useState<boolean>(!!initValues.tagId);

  const error = useSelector((store: Store) => store.errorRedux.error);
  const opportunitiesRecords = useSelector(selectorGetOpportunitiesRecords);
  const opportunitiesCurrentRecord = useSelector(selectorGetOpportunityById);
  const oppoSettingsForVolunteer = useSelector(selectorGetOppoSettingsForVolunteer);
  const isLoadedOppoSettingsForVolunteer = useSelector(selectorLoadedStatusOppoSettingsForVolunteer);

  const oppoId = location.search.substring(1);
  const [processOpportunity, setProcessOpportunity] = useState<IOpportunityResponse>();

  useEffect(() => {
    // if (
    //   opportunitiesRecords.some((oppo) => {
    //     // @ts-ignore
    //     return oppo.id === oppoId;
    //   })
    // ) {
    //   const foundOppo = opportunitiesRecords.find((oppo: IOpportunityResponse) => {
    //     return +oppo.id === +oppoId;
    //   });
    //
    //   setProcessOpportunity(foundOppo);
    //
    //   setInitValues({
    //     ...foundOppo,
    //     tagId: !!foundOppo?.tagId ? Number(foundOppo.tagId) : null,
    //     filePathes: convertFromStringToItem(foundOppo!.filePathes),
    //     organisationId: foundOppo?.organisation?.id || 'null',
    //     endTime: +moment.duration(foundOppo?.duration, 'seconds').asHours(),
    //     recipientEmail: foundOppo?.recipientEmail ? foundOppo.recipientEmail : '',
    //     recipientPhoneNumber: foundOppo?.recipientPhoneNumber ? foundOppo.recipientPhoneNumber : '',
    //   });
    //   setIsVisibleHashtagField(!!foundOppo?.tagId);
    // } else {
    dispatch(getOpportunityById(+oppoId));
    // }
  }, [oppoId]);

  useEffect(() => {
    if (opportunitiesCurrentRecord && Object.keys(opportunitiesCurrentRecord).length) {
      setInitValues({
        ...opportunitiesCurrentRecord,
        tagId: !!opportunitiesCurrentRecord?.tagId ? Number(opportunitiesCurrentRecord.tagId) : null,
        filePathes: convertFromStringToItem(opportunitiesCurrentRecord!.filePathes),
        organisationId: opportunitiesCurrentRecord.organisation?.id || 'null',
        endTime: +moment.duration(opportunitiesCurrentRecord?.duration, 'seconds').asHours(),
        recipientEmail: opportunitiesCurrentRecord?.recipientEmail ? opportunitiesCurrentRecord.recipientEmail : '',
        recipientPhoneNumber: opportunitiesCurrentRecord?.recipientPhoneNumber
          ? opportunitiesCurrentRecord.recipientPhoneNumber
          : '',
      });

      setProcessOpportunity(opportunitiesCurrentRecord);
      setIsVisibleHashtagField(!!opportunitiesCurrentRecord?.tagId);
      setIsLoading(false);
    }
  }, [opportunitiesCurrentRecord]);

  useEffect(() => {
    return () => {
      dispatch(resetCurrentOpportunity());
    };
  }, []);

  const updateClickHandler = async (values: UpdateManualOpportunityRequest): Promise<void> => {
    setIsLoading(true);

    const body = document.createElement('body');
    body.innerHTML = values.opportunityDescription || '';

    const valuesToUpdate = {
      ...values,
      opportunityDescription: values.opportunityDescription ? body.outerHTML : '',
    };

    try {
      await dispatch(updateManualOpportunity(+oppoId, valuesToUpdate as unknown as UpdateManualOpportunityRequest));
      setIsUpdated(true);
    } catch (error) {
      console.error(error);
      dispatch(setError(error?.response?.message.join(',') || error.message, error?.response?.code || error.code));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!oppoSettingsForVolunteer.length && !isLoadedOppoSettingsForVolunteer) {
      dispatch(getOpportunitySettingsForVolunteer());
    }
  }, [oppoSettingsForVolunteer]);

  const mandatoryFields = useMemo<OrganisationOpportunitySettingsResponse | null>(() => {
    if (oppoSettingsForVolunteer.length && Object.keys(processOpportunity || {}).length) {
      const orgSettings = oppoSettingsForVolunteer.find(
        (settings) =>
          Number(settings.opportunitySettings?.organisationId) === Number(processOpportunity?.organisation?.id)
      )?.opportunitySettings;

      if (orgSettings) {
        return Object.assign(
          {},
          ...(Object.keys(orgSettings) as Array<keyof IOrganisationOpportunitySettingsResponse>).map((key) => {
            if (key === 'mandatoryTag') {
              return {[key]: orgSettings[key] && !!orgHashtags.length && processOpportunity?.organisation?.id};
            }

            return {[key]: orgSettings[key]};
          })
        );
      }
    }

    return null;
  }, [oppoSettingsForVolunteer, orgHashtags, processOpportunity]);

  const validation = useMemo(() => {
    return dynamicManualOppoValidation(mandatoryFields || ({} as unknown as IOrganisationOpportunitySettingsResponse));
  }, [mandatoryFields]);

  const handlerResetError = () => dispatch(resetError());

  return (
    <Fragment>
      <ArrowBackLink parentClassName={styles.nav} />
      {error.state ? (
        <ResponseFailure
          message={error.message}
          buttonTitle={t('errors:checkAndTryAgain')}
          buttonClickHandler={handlerResetError}
        />
      ) : isUpdated ? (
        <CongratsPopup
          buttonTitle={t('buttons:button.done')}
          subtitle={t('messages:updatedManualChesedOppo')}
          doneButtonClickHandler={() => history.push(urls.volMyFeed)}
        />
      ) : (
        <Appeal styleContact>
          <div className={styles.title}>{t('form:updateManOppo.editTitle')}</div>
          <div className={styles.form__container}>
            <Formik
              enableReinitialize
              initialValues={initValues}
              validationSchema={validation}
              validateOnChange
              onSubmit={(values) => {
                // @ts-ignore
                updateClickHandler(values as UpdateManualOpportunityRequest);
              }}
            >
              <ManualOpportunityMainForm
                isUpdate
                isLoading={isLoading}
                isVisibleHashtagField={isVisibleHashtagField}
                setIsVisibleHashtagField={setIsVisibleHashtagField}
                mandatoryFields={mandatoryFields}
              />
            </Formik>
          </div>
        </Appeal>
      )}
    </Fragment>
  );
};

export default UpdateManualOpportunity;
