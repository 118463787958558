import {FC, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Formik} from 'formik';
//redux
import {setVolunteerTabFilter} from 'redux/filter-service/actions';
import {selectorGetUserAddress} from 'redux/user-service/selector';
import {resetOpportunities} from 'redux/opportunities-service/action';
//constants
import {INITIAL_OPPORTUNITIES_FILTERS_VOLUNTEER, INITIAL_PAGINATION} from 'core/constants';
//types
import {OPPORTUNITIES_FILTERS_VOLUNTEER_VALUES} from 'core/types/index';
//components
import {removeEmptyProps} from 'core/functions';
import VolunteerOpportunitiesFilterForm from './VolunteerOpportunitiesFilterForm';
import {DateRangeFiltersValidationSchema} from 'core/validation';

const OpportunitiesFilters: FC = () => {
  const dispatch = useDispatch();

  const userAddress = useSelector(selectorGetUserAddress);

  const [initialValues, setInitialValues] = useState<OPPORTUNITIES_FILTERS_VOLUNTEER_VALUES>(
    INITIAL_OPPORTUNITIES_FILTERS_VOLUNTEER
  );

  const submitClickHandlerOpportunitiesFiltersVolunteers = (
    values: OPPORTUNITIES_FILTERS_VOLUNTEER_VALUES | undefined
  ): void => {
    dispatch(resetOpportunities());
    dispatch(setVolunteerTabFilter(removeEmptyProps({...values, pagination: INITIAL_PAGINATION}), userAddress));
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={DateRangeFiltersValidationSchema}
      onSubmit={submitClickHandlerOpportunitiesFiltersVolunteers}
    >
      <VolunteerOpportunitiesFilterForm submitClickHandler={submitClickHandlerOpportunitiesFiltersVolunteers} />
    </Formik>
  );
};

export default OpportunitiesFilters;
