// functions
import {formatDate, getFileType} from 'core/functions';
// data
import {oneSecond} from './data';

export const getRecipientContactDetails = (recipientEmail?: string, recipientPhoneNumber?: string) =>
  recipientEmail && recipientPhoneNumber
    ? `${recipientEmail}, ${recipientPhoneNumber}`
    : recipientEmail || recipientPhoneNumber;

const checkingIsFullDay = (duration: number) => {
  return Math.floor(duration / 3600) % 24 === 0;
};

export const getOppoDate = (startDate: Date, endDate: Date, duration: number) => {
  const isFullDay = checkingIsFullDay(duration);

  const isVisibleEndDate = Math.floor(duration / 3600) >= 24;
  if (isVisibleEndDate) {
    if (isFullDay) {
      const adjustedEndDate = new Date(endDate.getTime() - oneSecond);

      return `${formatDate(startDate, 'MM/DD/YYYY')} - ${formatDate(adjustedEndDate, 'MM/DD/YYYY')}`;
    }
    return `${formatDate(startDate, 'MM/DD/YYYY')} - ${formatDate(endDate, 'MM/DD/YYYY')}`;
  }
  return `${formatDate(startDate, 'MM/DD/YYYY')}`;
};

export const getTheFileType = (filePaths: string[]) => {
  return filePaths.map((item) => getFileType(item || ''));
};
