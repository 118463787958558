import {Store} from 'redux/root';
//types
import {IUserClaimResponse} from '@joc/api-gateway';

export const selectorSignupRequests = (store: Store): Array<IUserClaimResponse> => {
  return store.signupRequestsRedux.signupRequests.records;
};

export const selectorSignupRequestsFound = (store: Store): number => {
  return store.signupRequestsRedux.signupRequests.total;
};

export const selectorTotalSignupRequests = (store: Store): number => {
  return store.signupRequestsRedux.totalSignupRequests;
};
