import {FC, useEffect, useState} from 'react';
import {connect, ConnectedProps, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
//redux
import {Store} from 'redux/root';
import {selectorGetOrgId, selectorGetSchoolId} from 'redux/organization-service/selector';
//API
import {API} from 'core/API';
//types
import {BasicVolunteerResponse, IBasicVolunteerResponse, IDateRange} from '@joc/api-gateway';
//components
import VolunteerMainInfo from 'shared/components/VolunteerMainInfo';
import Loader from 'shared/components/Loader';
import ResponseFailure from 'shared/components/ResponseFailure';
import WhiteContainer from 'shared/components/WhiteContainer';
//styles
import parentStyles from 'pages/Organization/Statistics/Statistics.module.scss';
import styles from './TopVolunteers.module.scss';

type TopVolunteersParentProps = {
  dateRange: IDateRange | null;
};

const TopVolunteers: FC<TopVolunteersProps> = ({orgId, dateRange}: TopVolunteersProps) => {
  const {toDate, fromDate} = {...dateRange};

  const [isLoading, setIsLoading] = useState(false);
  const [customError, setCustomError] = useState('');
  const [topVolunteers, setTopVolunteers] = useState<Array<BasicVolunteerResponse>>([]);

  const schoolId = useSelector(selectorGetSchoolId);

  useEffect(() => {
    if (orgId) getTopVolunteers();
  }, [orgId, fromDate, toDate]);

  const {t} = useTranslation(['statistics', 'errors']);

  const getTopVolunteers = async () => {
    setIsLoading(true);
    setCustomError('');
    try {
      if (!orgId) throw new Error(`${t('errors:sorry')} ${t('errors:cantFindOrgId')}`);
      const topVolunteersResponse = await API.topVolunteers(fromDate, toDate, orgId.toString());
      setTopVolunteers(topVolunteersResponse);
    } catch (error) {
      setCustomError(error?.response?.message || error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const title = schoolId ? t('statistics:topStudents') : t('statistics:topVolunteers');

  return (
    <WhiteContainer parentClassName={parentStyles.container_block} title={title}>
      {isLoading ? (
        <Loader />
      ) : customError.length && !isLoading ? (
        <ResponseFailure
          styleTable
          message={customError}
          buttonTitle={t('errors:tryAgain')}
          buttonClickHandler={getTopVolunteers}
        />
      ) : !topVolunteers.length && !isLoading ? (
        <ResponseFailure styleTable message={t('errors:haventGotData')} buttonUnshow />
      ) : (
        <div className={styles.list}>
          {topVolunteers.map((volunteer: IBasicVolunteerResponse, i: number) => (
            <div className={styles.listItem} key={volunteer.id}>
              {i + 1}
              <VolunteerMainInfo
                userImage={volunteer?.imagePath || ''}
                userFirstName={volunteer.firstName}
                userLastName={volunteer.lastName}
                points={volunteer.points}
                spendTime={volunteer.spendTime}
                userGrade={volunteer.diploma.statusName}
                parentClass={styles.noborder}
              />
            </div>
          ))}
        </div>
      )}
    </WhiteContainer>
  );
};

const mapStateToProps = (store: Store) => ({
  orgId: selectorGetOrgId(store),
});

const connector = connect(mapStateToProps);

type TopVolunteersProps = ConnectedProps<typeof connector> & TopVolunteersParentProps;

export default connector(TopVolunteers);
