import {FC} from 'react';
import cx from 'classnames';
//images
import {ReactComponent as ChesedSvg} from 'assets/image/onboarding/chesed.svg';
import {ReactComponent as ColleaguesSvg} from 'assets/image/onboarding/Colleagues.svg';
//styles
import styles from './AppealSignup.module.scss';

type AppealPropsType = {
  signupColleaguesStyle?: boolean;
  subTitle: string;
  title: string;
  parentClassName?: string;
};

const Appeal: FC<AppealPropsType> = ({signupColleaguesStyle, subTitle, title, parentClassName}: AppealPropsType) => {
  return (
    <div className={cx(styles.content, parentClassName)}>
      <div className={styles.container_gradient} />
      {!signupColleaguesStyle ? <ChesedSvg /> : <ColleaguesSvg />}
      <div className={cx('title', styles.content__title)}>{title}</div>
      <div
        className={cx(styles.content__subtitle, {
          [styles.content__title_colleagues]: signupColleaguesStyle,
        })}
      >
        {subTitle}
      </div>
    </div>
  );
};

export default Appeal;
