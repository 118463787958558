import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import cx from 'classnames';
//formik
import {Field, useFormikContext} from 'formik';
//form configuration
import {REPEAT_OPPO_ENDS_TYPE, RepeatOpportunityModalFormValues} from '../../../formConfiguration';
//components
import InlineDatePicker from 'shared/components/Pickers/InlineDatePicker';
//styles
import styles from './OnContent.module.scss';

const OnContent: FC = () => {
  const {
    values: {endsType},
  } = useFormikContext<RepeatOpportunityModalFormValues>();

  const {t} = useTranslation(['form', 'signUpUser']);

  const onLabel = t('form:opportunity.on');

  return (
    <div className={styles.wrapper}>
      <label className={styles.label}>{onLabel}</label>
      <Field
        name="endDate"
        isSmallHeight
        parentClassName={cx(styles.container, styles.endDate)}
        availableFuture
        component={InlineDatePicker}
        disabled={endsType === REPEAT_OPPO_ENDS_TYPE.AFTER}
      />
    </div>
  );
};

export default OnContent;
