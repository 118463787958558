import {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';
//formik
import {Field, Form} from 'formik';
//icons
import {ReactComponent as InfoIcon} from 'assets/icons/info.svg';
//components
import Input from 'shared/inputs/Input';
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
import Loader from 'shared/components/Loader';
//styles
import styles from './LoginEmailForm.module.scss';

type LoginEmailFormParetProps = {
  isLoading: boolean;
};

const LoginEmailForm: FC<LoginEmailFormParetProps> = ({isLoading}) => {
  const {t} = useTranslation(['inputs', 'buttons', 'form']);

  const [isPasswordFocused, setIsPasswordFocused] = useState(false);

  const handleSetFocusedPassword = () => setIsPasswordFocused(true);

  return (
    <Form className={styles.form}>
      <Field name="email" isDisabledMargin disabled component={Input} />
      <Field
        name="password"
        type="password"
        placeholder={t('inputs:placeholders.password')}
        isDisabledMargin
        onFocus={handleSetFocusedPassword}
        component={Input}
      />
      <Field
        name="confirmPassword"
        type="password"
        placeholder={t('inputs:placeholders.confirmPassword')}
        isDisabledMargin
        onFocus={handleSetFocusedPassword}
        component={Input}
      />

      {isPasswordFocused && (
        <div className={styles.form__info}>
          <InfoIcon />
          <p>{t('form:passwordIsCaseSensitive')}</p>
        </div>
      )}

      {isLoading ? (
        <Loader />
      ) : (
        <div className={styles.form__button}>
          <ButtonDefault submitType classList={['primary', 'extra_lg']} title={t('buttons:button.letsGo')} />
        </div>
      )}
    </Form>
  );
};

export default LoginEmailForm;
