import {IDateRange} from '@joc/api-gateway';
import {API} from 'core/API';
import {Dispatch} from 'redux';
import {
  GET_DASHBOARD_INFO,
  GET_OPPORTUNITIES_REGULAR_MANUAL,
  GET_STATISTICS_GENERAL,
  GET_USERS_GRADE_TOTAL_TIME_TRAFFIC,
  GET_USERS_LOGIN_TRAFFIC,
  GET_USERS_SIGNUP_TRAFFIC,
  GET_VOLUNTEERS_AVG_AGE,
  SET_ERROR,
} from 'redux/actions-types';

export const getDashboardInfo =
  (orgId: number) =>
  async (dispatch: Dispatch): Promise<void> => {
    if (orgId) {
      try {
        const response = await API.dashboard(orgId, undefined);
        dispatch({
          type: GET_DASHBOARD_INFO,
          payload: response,
        });
      } catch (error) {
        dispatch({type: SET_ERROR, payload: {state: true, message: error.message}});
      }
    }
  };

export const getStatisticsGeneral = (dateRange: IDateRange | null) => async (dispatch: Dispatch) => {
  try {
    const {fromDate, toDate} = {...dateRange};
    const response = await API.organisationStatisticsGeneral(fromDate, toDate, undefined);
    dispatch({type: GET_STATISTICS_GENERAL, payload: response});
  } catch (error) {
    throw error;
  }
};

export const getUsersLoginTraffic = (dateRange: IDateRange) => async (dispatch: Dispatch) => {
  try {
    const {fromDate, toDate} = dateRange;
    const response = await API.organisationStatisticsUserLogins(fromDate, toDate, undefined);
    dispatch({type: GET_USERS_LOGIN_TRAFFIC, payload: response});
  } catch (error) {
    throw error;
  }
};

export const getUsersGradeTimeSpent = (dateRange: IDateRange | null) => async (dispatch: Dispatch) => {
  try {
    const {fromDate, toDate} = {...dateRange};
    const response = await API.organisationStatisticsVolunteersSpendTime(fromDate, toDate, undefined);
    dispatch({type: GET_USERS_GRADE_TOTAL_TIME_TRAFFIC, payload: response});
  } catch (error) {
    throw error;
  }
};

export const getUsersSignupTraffic = (dateRange: IDateRange) => async (dispatch: Dispatch) => {
  try {
    const {fromDate, toDate} = dateRange;
    const response = await API.organisationStatisticsUsers(fromDate, toDate, undefined);
    dispatch({type: GET_USERS_SIGNUP_TRAFFIC, payload: response});
  } catch (error) {
    throw error;
  }
};

export const getVolunteersAvgAge = (dateRange: IDateRange) => async (dispatch: Dispatch) => {
  try {
    const {fromDate, toDate} = dateRange;
    const response = await API.organisationStatisticsVolunteersAvgAge(fromDate, toDate, undefined);
    dispatch({type: GET_VOLUNTEERS_AVG_AGE, payload: response});
  } catch (error) {
    throw error;
  }
};

export const getOpportunitiesRegularManual = (dateRange: IDateRange) => async (dispatch: Dispatch) => {
  try {
    const {fromDate, toDate} = dateRange;
    const responseTraffic = await API.totalOpportunitiesStatistics(fromDate, toDate, undefined);
    // const responseTotal = await API.searchOpportunitiesByOrganisation(
    // 	undefined,
    // 	SearchOpportunitiesRequest.fromJS({
    // 		pagination: { skip: 0, take: 1 },
    // 		status: [OPPORTUNITY_STATUSES.CLOSED],
    // 		dateRange,
    // 	})
    // );
    // TODO: add total regular/manual
    dispatch({
      type: GET_OPPORTUNITIES_REGULAR_MANUAL,
      payload: {traffic: responseTraffic, total: 0},
    });
  } catch (error) {
    throw error;
  }
};
