type PaymentData = {
  currency: string;
  amount: number;
  organizationId: number;
  tariffId: number;
  cancelUrl: string;
};

export enum tariffTypesIds {
  Donate = 1,
  School,
  Organization100,
  Organization200,
  Organization300,
  Organization400,
  Organization500,
  OrganizationFree,
}

export const getTariffAmount = (isSchool: boolean, tariffCost: number, defCountOfStudents: string): number =>
  isSchool ? +tariffCost * +defCountOfStudents : +tariffCost;

export const getPaymentData = (amount: number, orgId: string, id: number): PaymentData => ({
  currency: 'USD',
  amount,
  organizationId: +orgId,
  tariffId: id,
  cancelUrl: window.location.href,
});

export const getPriceUnit = (
  tariffId: number,
  defCountOfStudents: string,
  invitesAmount: number,
  period: string,
  personOrPersons: string,
  persons: string
) =>
  tariffId === tariffTypesIds.School
    ? `${defCountOfStudents} ${personOrPersons}/\n${period}`
    : `${invitesAmount} ${persons}/\n${period}`;

export const getPriceNumber = (tariffCost: number, defCountOfStudents: string) => tariffCost * +defCountOfStudents;
