//types
import {IOpportunityResponse} from '@joc/api-gateway';
//constants
import {ORGANIZATION_TABS} from 'core/constants';

export const getIsRefreshRequired = (opportunitiesRecords: Array<IOpportunityResponse>, activeTab: ORGANIZATION_TABS) =>
  opportunitiesRecords.some((opportunity) =>
    opportunity.opportunityType === 'MANUAL'
      ? opportunity.status !== 'PENDING'
      : opportunity.volunteers?.every((volunteer) => volunteer.status !== 'PENDING')
  ) && activeTab === 'PENDING';
