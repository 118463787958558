// react
import {FC} from 'react';
// icons
import {ReactComponent as HashtagIcon} from 'assets/icons/hashtag.svg';
//styles
import styles from './index.module.scss';

interface IHashtagOptionProps {
  option: string;
}

export const HashtagOption: FC<IHashtagOptionProps> = ({option}) => {
  return (
    <div className={styles.hashtag__option}>
      <div className={styles.hashtag__option__image}>
        <HashtagIcon />
      </div>
      <span>{option}</span>
    </div>
  );
};
