enum USER_GRADE {
  'Level 1' = 'level1',
  'Level 2' = 'level2',
  'Level 3' = 'level3',
  'Level 4' = 'level4',
  'Level 5' = 'level5',
  'Level 6' = 'level6',
  'Level 7' = 'level7',
  'Level 8' = 'level8',
  'Level 9' = 'level9',
  'Level 10' = 'level10',
  'Level 11' = 'level11',
  'Level 12' = 'level12',
  'Level 13' = 'level13',
  'Level 14' = 'level14',
  'Level 15' = 'level15',
  'Level 16' = 'level16',
  'Level 17' = 'level17',
  'Level 18' = 'level18',
  'Level 19' = 'level19',
  'Level 20' = 'level20',
  'Level 21' = 'level21',
  'Elementary School' = 'elementarySchool',
  'High School' = 'highSchool',
  'College' = 'college',
  'University' = 'university',
  'Dr Good Diploma' = 'drGoodDiploma',
}

export const userGradeKey = (userGrade: string): USER_GRADE => USER_GRADE[userGrade as keyof typeof USER_GRADE];
