export function getOperatingSystem(window: any) {
  // let operatingSystem = 'Not known';
  if (window.navigator.appVersion.indexOf('Win') !== -1) {
    return 'Windows OS';
  }
  if (window.navigator.appVersion.indexOf('Mac') !== -1) {
    return 'MacOS';
  }
  if (window.navigator.appVersion.indexOf('X11') !== -1) {
    return 'UNIX OS';
  }
  if (window.navigator.appVersion.indexOf('like Mac') !== -1) {
    return 'iOS';
  }
  if (window.navigator.appVersion.indexOf('Android') !== -1) {
    return 'Android';
  }
  if (window.navigator.appVersion.indexOf('Linux') !== -1) {
    return 'Linux OS';
  }

  return 'Not known';
}

export function getStoreLink(window: any) {
  switch (getOperatingSystem(window)) {
    case 'Android':
      return {os: 'Android', link: process.env.REACT_APP_PLAYMARKET_LINK};
    case 'iOS':
      return {os: 'iOS', link: process.env.REACT_APP_APPSTORE_LINK};
    // case 'MacOS':
    // 	return { os: 'iOS', link: process.env.REACT_APP_APPSTORE_LINK };
    default:
      return {os: 'not mobile', link: '#'};
  }
}

export function getBrowser(window: any) {
  let currentBrowser = 'Not known';
  if (window.navigator.userAgent.indexOf('Chrome') !== -1) {
    currentBrowser = 'Google Chrome';
  } else if (window.navigator.userAgent.indexOf('Firefox') !== -1) {
    currentBrowser = 'Mozilla Firefox';
  } else if (window.navigator.userAgent.indexOf('MSIE') !== -1) {
    currentBrowser = 'Internet Explorer';
  } else if (window.navigator.userAgent.indexOf('Edge') !== -1) {
    currentBrowser = 'Edge';
  } else if (window.navigator.userAgent.indexOf('Safari') !== -1) {
    currentBrowser = 'Safari';
  } else if (window.navigator.userAgent.indexOf('Opera') !== -1) {
    currentBrowser = 'Opera';
  } else if (window.navigator.userAgent.indexOf('Opera') !== -1) {
    currentBrowser = 'YaBrowser';
  } else {
    currentBrowser = 'Others';
  }

  return currentBrowser;
}
