import {FC, useContext} from 'react';
import {Formik} from 'formik';
import {useSelector} from 'react-redux';
//types
import {IBasicVolunteerResponse, UpdatePasswordRequest} from '@joc/api-gateway';
//API
import {API} from 'core/API';
//redux
import {selectorGetOrgId, selectorGetSchoolId} from 'redux/organization-service/selector';
//form configuration
import {EditVolunteerFormValues, getInitialValues, validationSchema} from './formConfiguration';
//functions
import {getNullInsteadOfEmptyFields} from 'core/functions';
//context
import {VolunteersPageContext} from 'pages/Organization/Volunteers';
//components
import EditVolunteerMainForm from './EditVolunteeerMainForm';

type EditVolunteerFormProps = {
  volunteer: IBasicVolunteerResponse;
};

const EditVolunteerForm: FC<EditVolunteerFormProps> = ({volunteer}) => {
  const {refreshVolunteers} = useContext(VolunteersPageContext);

  const schoolId = useSelector(selectorGetSchoolId);
  const organizationId = useSelector(selectorGetOrgId);

  const submitClickHandler = async ({password, ...values}: EditVolunteerFormValues) => {
    const formatedValues = {...values, gradeId: values.gradeId ? String(values.gradeId) : undefined};
    //to purge not required fields (as phoneNumber, address, birthDate) we need to set them to null, despite the UpdateVolunteerRequest class
    const body = getNullInsteadOfEmptyFields(formatedValues);
    await API.updateVolunteer(Number(volunteer.id), String(organizationId), body as unknown as UpdatePasswordRequest);

    if (password) {
      const volunteerById = await API.getVolunteerById(Number(volunteer.id));
      await API.updatePasswordByAdmin(
        Number(volunteerById.userId),
        String(organizationId),
        UpdatePasswordRequest.fromJS({password})
      );
    }

    refreshVolunteers();
  };

  const initialValues = getInitialValues(volunteer, schoolId);

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={submitClickHandler}>
      <EditVolunteerMainForm />
    </Formik>
  );
};
export default EditVolunteerForm;
