import * as Yup from 'yup';
//types
import {IUpdateVolunteerRequest, IUserResponse} from '@joc/api-gateway';
//validation
import {
  addressMandatorySchema,
  birthDatePleaseRequiredSchema,
  emailSchema,
  firstNameSchema,
  genderSchema,
  lastNameSchema,
  phoneNumberSchema,
  studentIdSchema,
} from 'core/validation';

export type EditVolunteerFormValues = IUpdateVolunteerRequest;
export type UserDataWithStudentId = IUserResponse;

export const getInitialValues = (userData: UserDataWithStudentId | null): EditVolunteerFormValues => ({
  firstName: userData?.firstName,
  lastName: userData?.lastName,
  phoneNumber: userData?.phoneNumber,
  birthDate: (userData?.birthDate && new Date(userData.birthDate)) || undefined,
  gender: userData?.gender?.id,
  address: userData?.address,
  imagePath: userData?.imagePath,
  email: userData?.email,
  studentId: userData?.studentId,
});

export const validationSchema = Yup.object().shape({
  firstName: firstNameSchema,
  lastName: lastNameSchema,
  email: emailSchema,
  phoneNumber: phoneNumberSchema,
  birthDate: birthDatePleaseRequiredSchema,
  gender: genderSchema,
  address: addressMandatorySchema,
  studentId: studentIdSchema.notRequired(),
});
