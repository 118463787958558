// react
import {FC, useEffect, useState, PropsWithChildren} from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation} from 'react-router';
import qs from 'qs';
// constants
import {BUTTON_ICONS} from 'core/constants';
// hooks
import {useTagModals} from 'hooks/useTagModals';
//context
import {useOpportunitiesContext} from 'pages/Organization/Opportunities/context';
//components
import {TagModals} from 'components/HashtagModals';
import ButtonImage from 'shared/components/Buttons/ButtonImage';
import InputSearch from 'shared/components/Inputs/InputSearch_new';
//styles
import styles from './TableMenu.module.scss';

interface ITableMenuProps {
  searchChangeHandler: (value: string) => void;
}

const TableMenu: FC<PropsWithChildren<ITableMenuProps>> = ({children, searchChangeHandler}) => {
  const location = useLocation();
  const {t} = useTranslation(['inputs', 'tooltips']);
  const orgId = localStorage.getItem('organisationId');

  const {modalsState, modalsActions, modalsData} = useTagModals();

  const [isDisableFilters, setIsDisableFilters] = useState(false);

  const {isClearSearch, setIsClearSearch, setSidebarVisibility} = useOpportunitiesContext();

  useEffect(() => {
    const queryParams = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });
    queryParams?.groupId ? setIsDisableFilters(true) : setIsDisableFilters(false);
  }, [location.search]);

  const filterClickHandler = () =>
    setSidebarVisibility(({isShowFilter}) => ({isShowCalendar: false, isShowFilter: !isShowFilter}));

  const handleOpenHashtagModal = () => {
    if (!!orgId) {
      modalsActions.create.open({orgId});
    }
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.filter}>
          <InputSearch
            classList={['big']}
            changeHandler={searchChangeHandler}
            setIsClearSearch={setIsClearSearch}
            isClearSearch={isClearSearch as boolean}
            placeholder={t('inputs:placeholders.searchWithDots')}
          />
          <ButtonImage
            disabled={isDisableFilters}
            title={t('tooltips:filter')}
            viewStyle={BUTTON_ICONS.filter}
            clickHandler={filterClickHandler}
          />
          <ButtonImage
            title={t('tooltips:hashtags')}
            viewStyle={BUTTON_ICONS.hashtag}
            clickHandler={handleOpenHashtagModal}
            parentClassName={styles.button__hashtag}
          />
        </div>
        {children && children}
      </div>
      <TagModals orgId={orgId} modalsState={modalsState} modalsActions={modalsActions} modalsData={modalsData} />
    </>
  );
};

export default TableMenu;
