import {ChangeEventHandler, FC, TextareaHTMLAttributes, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
//types
import {FieldBaseProps} from 'core/types/field';
//functions
import {getTextDirection} from 'core/functions';
//components
import FieldWrapper from 'shared/inputs/FieldWrapper';
//styles
import styles from './styles.module.scss';

type TextAreaProps = FieldBaseProps<
  Pick<TextareaHTMLAttributes<HTMLTextAreaElement>, 'id' | 'placeholder' | 'disabled' | 'rows' | 'cols'> & {
    value?: string | number;
    onChange?: (value: string | number) => void;
    isPencilHidden?: boolean;
  }
>;

const TextArea: FC<TextAreaProps> = ({
  value,
  onChange,
  id,
  placeholder,
  rows = 4,
  cols,
  isPencilHidden,
  ...fieldBaseProps
}) => {
  const {i18n} = useTranslation();

  const {field, disabled} = fieldBaseProps;

  const textareaValue = useMemo(() => value || (field.value as typeof value), [value, field]);

  const textareaChangeHandler: ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    if (onChange) {
      const newValue = e.target.value;
      onChange(newValue);
      return;
    }

    field.onChange(e);
  };

  const textDirection = getTextDirection(i18n.language);

  const isPencilVisible = field.value && !disabled && !isPencilHidden;

  return (
    <FieldWrapper {...fieldBaseProps}>
      <div className={styles.container}>
        <textarea
          dir={textDirection}
          className={styles.textarea}
          {...field}
          id={id || field.name}
          value={textareaValue}
          onChange={textareaChangeHandler}
          placeholder={placeholder}
          disabled={disabled}
          rows={rows}
          cols={cols}
        />
        {isPencilVisible && <label className={styles.edit} htmlFor={field.name} dir={textDirection} />}
      </div>
    </FieldWrapper>
  );
};
export default TextArea;
