import {IDiplomasResponse} from '@joc/api-gateway';
import i18n from 'components/translate';
import {userGradeKey} from 'core/functions/diplomaNames';
import {diplomaMaximumLevel} from './data';

type NearestPoints = {points: number; nextLevel: string};

const countNearestPoints = (
  allDiplomas: Array<IDiplomasResponse>,
  userPoints: number | undefined,
  isDiplomaStatus = false
): NearestPoints => {
  const lastDiplomaPoints = allDiplomas[allDiplomas.length - 1]?.points;

  if (userPoints && lastDiplomaPoints && userPoints > lastDiplomaPoints) {
    return {points: 0, nextLevel: allDiplomas[allDiplomas.length - 1]?.statusName || ''};
  }

  const nextStage =
    allDiplomas.find(({points}) => {
      return points && userPoints && points > userPoints;
    }) || allDiplomas[1];

  const indexNextStage = allDiplomas.indexOf(nextStage);
  const currentStage = allDiplomas[indexNextStage - 1];
  const points = nextStage.points && userPoints ? nextStage.points - userPoints : nextStage.points || 0;

  const nextLevel = isDiplomaStatus ? currentStage?.statusName || '' : nextStage?.statusName || '';

  return {points, nextLevel};
};

export const getStatusName = (name: string | undefined): string => {
  return name ? i18n.t(`diploma:userGrade.${userGradeKey(name)}`) : '';
};

export const nextLevel = (
  allDiplomas: IDiplomasResponse[] | undefined,
  userPoints: number | undefined,
  isDiplomaStatus = false
) => allDiplomas && countNearestPoints(allDiplomas, userPoints, isDiplomaStatus);

export const points = (userPoints: number | undefined): string => `${userPoints} ${i18n.t('diploma:points')}`;

export const hoursSpent = (userSpendTime: number): string =>
  `${Math.round(userSpendTime / 3600)} ${i18n.t('diploma:hoursSpent')}`;

export const pointsLeft = (nextLevel: NearestPoints | undefined, isDiplomaStatus = false): string => {
  if (!nextLevel?.points && nextLevel) {
    return diplomaMaximumLevel;
  }

  if (isDiplomaStatus) {
    return getStatusName(nextLevel?.nextLevel);
  }

  if (nextLevel?.nextLevel === 'Level 21') {
    return `\u200f${nextLevel?.points} ${i18n.t('diploma:pointsToThe')} ${i18n.t('diploma:maxLevel')}`;
  }

  return `\u200f${nextLevel?.points} ${i18n.t('diploma:pointsToThe')} ${getStatusName(nextLevel?.nextLevel)}`;
};
