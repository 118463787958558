import {FC, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import cx from 'classnames';
import {FacebookMessengerShareButton, FacebookShareButton, WhatsappShareButton} from 'react-share';
//functions
import {getDynamicLink} from 'core/functions';
// types
import {IOpportunityResponse} from '@joc/api-gateway';
//assets
import {ReactComponent as FacebookIcon} from 'assets/image/facebook.svg';
import {ReactComponent as WhatsappIcon} from 'assets/image/whatsapp.svg';
import {ReactComponent as MessageIcon} from 'assets/image/imessage.svg';
import {ReactComponent as InstagramIcon} from 'assets/image/instagram.svg';
import {ReactComponent as CopyIcon} from 'assets/image/copy.svg';
// helpers
import {getConfiguredLink, getLinkContent} from './helper';
//styles
import styles from './ShareSocialPopup.module.scss';

interface IShareSocialPopupProps {
  setIsShowPopup: (a: boolean) => void;
  setIsOpenSnackbar: (a: boolean) => void;
  link: string;
  opportunity?: IOpportunityResponse;
  className?: string;
  closePopup?: () => void;
}

const ShareSocialPopup: FC<IShareSocialPopupProps> = ({
  setIsShowPopup,
  setIsOpenSnackbar,
  link,
  opportunity,
  className,
  closePopup,
}) => {
  const {t} = useTranslation('buttons');

  const ref = useRef<HTMLDivElement>(null);

  const [shareLink, setShareLink] = useState('');
  const [whatsappMessage, setWhatsappMessage] = useState('');

  useEffect(() => {
    (async () => {
      const {shortDynamicLink} = await getDynamicLink(link);
      setShareLink(shortDynamicLink);
    })();
  }, [link]);

  useEffect(() => {
    const linkSetup = getConfiguredLink(shareLink, opportunity);
    const linkContent = getLinkContent(linkSetup, shareLink);
    setWhatsappMessage(`https://api.whatsapp.com/send?text=${encodeURIComponent(linkContent as string)}`);
  }, [shareLink]);

  const handleClickOutside = (event: Event) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setIsShowPopup(false);
    }
  };

  const handleCopyLink = async () => {
    if (closePopup) closePopup();
    const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

    const linkSetup = getConfiguredLink(shareLink, opportunity);
    const linkContent = getLinkContent(linkSetup, shareLink);
    try {
      if (linkContent) {
        if (isFirefox) {
          await navigator.clipboard.writeText(linkContent);
        } else {
          const blob = new Blob([linkContent], {type: 'text/plain'});
          const item = new ClipboardItem({'text/plain': blob});
          await navigator.clipboard.write([item]);
        }

        setIsOpenSnackbar(true);
        setIsShowPopup(false);
      }
    } catch (error) {
      console.error('Unable to copy content to clipboard', error);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => document.removeEventListener('click', handleClickOutside, true);
  }, []);

  return (
    <div ref={ref} className={cx(styles.share_dd, className)}>
      <div className={styles.share_ttl}>{t('button.share')}</div>
      <a href={whatsappMessage} target="_blank" className={styles.share_viaItem} rel="noreferrer">
        <WhatsappIcon className={styles.viaItem__ico} />
        <div className={styles.viaItem__ttl}>WhatsApp</div>
      </a>
      <FacebookMessengerShareButton
        resetButtonStyle={false}
        appId={`${process.env.REACT_APP_FACEBOOK_APP_ID}`}
        className={styles.share_viaItem}
        url={shareLink}
      >
        <MessageIcon className={styles.viaItem__ico} />
        <div className={styles.viaItem__ttl}>iMessage</div>
      </FacebookMessengerShareButton>
      <FacebookShareButton resetButtonStyle={false} className={styles.share_viaItem} url={shareLink}>
        <FacebookIcon className={styles.viaItem__ico} />
        <div className={styles.viaItem__ttl}>Facebook</div>
      </FacebookShareButton>
      <div className={cx(styles.share_viaItem, styles.disabled)}>
        <InstagramIcon className={styles.viaItem__ico} />
        <div className={styles.viaItem__ttl}>Instagram</div>
      </div>
      <div className={styles.share_viaItem} onClick={handleCopyLink}>
        <CopyIcon className={styles.viaItem__ico} />
        <div className={styles.viaItem__ttl}>{t('button.copyLink')}</div>
      </div>
    </div>
  );
};

export default ShareSocialPopup;
