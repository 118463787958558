//types
import {IGroupsResponse} from '@joc/api-gateway';
//form configuration
import {initialValues} from '../formConfiguration';
//helpers
import {getSmartGroupValuesFromGroupsResponse} from '../helpers';

export const getCurrentInitialValues = (groupsAll: Array<IGroupsResponse>, groupId: number) => {
  const groupResponse = groupsAll.find((group) => +groupId === +group.id);
  const currentInitialValues = groupResponse ? getSmartGroupValuesFromGroupsResponse(groupResponse) : initialValues;

  return currentInitialValues;
};
