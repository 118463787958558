import {Dispatch, SetStateAction, createContext, useContext} from 'react';
//types
import {MessagesFilterOption} from './types';

export type MessagesFilterContextValue = {
  messagesFilterOptions: Array<MessagesFilterOption>;
  setMessagesFilterOptions: Dispatch<SetStateAction<Array<MessagesFilterOption>>> | null;
  isMessagesFilterVisible: boolean;
  setIsMessagesFilterVisible: Dispatch<SetStateAction<boolean>> | null;
};

export const defaultMessagesFilterContextValue: MessagesFilterContextValue = {
  messagesFilterOptions: [],
  setMessagesFilterOptions: null,
  isMessagesFilterVisible: false,
  setIsMessagesFilterVisible: null,
};

export const MessagesFilterContext = createContext(defaultMessagesFilterContextValue);

export const MessagesFilterContextProvider = MessagesFilterContext.Provider;

export const useMessagesFilterContext = () => useContext(MessagesFilterContext);

export const getMessagesContextValue = (value: MessagesFilterContextValue) => value;
