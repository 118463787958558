import {FC, Fragment, useMemo, useState, Dispatch, SetStateAction} from 'react';
import {useTranslation} from 'react-i18next';
import firebase from 'firebase';
//types
import {SocialProvider} from 'core/types';
//components
import SocialButton from 'components/Volunteer/LoginFormV/SocialButton';
import Appeal from 'shared/components/Appeal/DefaultRenderer';
import Loader from 'shared/components/Loader';
import InputText from 'shared/components/Inputs/InputText';
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
import InputErrorHint from 'shared/components/InputErrorHint';
import InputContainer from 'shared/components/FormInputs/InputContainer';
//styles
import styles from './PopupAddPasswordProvider.module.scss';

type PopupAddPasswordProviderParentProps = {
  socialProviders: SocialProvider | undefined;
  setIsShowLoginWithCredentional: Dispatch<SetStateAction<boolean>>;
};

const PopupAddPasswordProvider: FC<PopupAddPasswordProviderParentProps> = ({
  socialProviders,
  setIsShowLoginWithCredentional,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [userPassword, setUserPassword] = useState('');
  const [userConfirmedPassword, setUserConfirmedPassword] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [isAddPasswordSuccess, setIsAddPasswordSuccess] = useState(false);
  const [isShowPasswordInput, setIsShowPasswordInput] = useState(false);

  const isButtonDisabled = useMemo(
    () =>
      userPassword.length < 8 ||
      userConfirmedPassword.length < 8 ||
      userPassword !== userConfirmedPassword ||
      isLoading,
    [userConfirmedPassword, userPassword, isLoading]
  );

  const {t} = useTranslation(['messages', 'buttons', 'errors', 'inputs', 'login']);

  const loginSocial = async (provider: firebase.auth.AuthProvider | undefined): Promise<void> => {
    setIsLoading(true);
    try {
      if (!provider) throw new Error(t('errors:error'));
      const firebaseUserResponse = await firebase.auth().signInWithPopup(provider);
      setIsShowPasswordInput(true);
      setUserEmail(firebaseUserResponse?.user?.email || '');
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      // setError(error?.response?.message || error.message, error.code);
    }
  };

  const confirmPassword = async () => {
    setIsLoading(true);

    try {
      if (!userEmail || !userPassword) throw new Error(t('errors:error'));
      const userCredential = await firebase.auth.EmailAuthProvider.credential(userEmail, userPassword);
      await firebase.auth().currentUser?.linkWithCredential(userCredential);
      setIsAddPasswordSuccess(true);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      // setError(error?.response?.message || error.message, error.code);
    }
  };

  return (
    <Appeal styleCongrats={isAddPasswordSuccess} styleThink>
      <div className={styles.content}>
        {isAddPasswordSuccess ? (
          <Fragment>
            <h1 className="title">{t('messages:congratulations')}</h1>
            <span className={styles.subtitle}>{t('messages:passwordChanged')}</span>
            <ButtonDefault
              parentClassName={styles.button}
              classList={['primary']}
              title={t('buttons:button.backToLogIn')}
              clickHandler={() => setIsShowLoginWithCredentional(false)}
            />
          </Fragment>
        ) : isShowPasswordInput ? (
          <div className={styles.password_form}>
            <h1 className="title">{t('messages:setNewPass')}</h1>
            <InputContainer isDisabledMargin isDisableHeight>
              <InputText
                type="password"
                value={userPassword}
                setValue={setUserPassword}
                placeholder={t('inputs:placeholders.password')}
              />
              <InputText
                type="password"
                value={userConfirmedPassword}
                setValue={setUserConfirmedPassword}
                placeholder={t('inputs:placeholders.confirmPassword')}
              />
              {(!!userPassword.length || !!userConfirmedPassword.length) &&
                (userPassword.length < 8 || userConfirmedPassword.length < 8) && (
                  <InputErrorHint errorText={t('errors:passwordLength')} />
                )}
            </InputContainer>
            {isLoading ? (
              <Loader loadProps={{parentClassName: styles.loader}} />
            ) : (
              <ButtonDefault
                parentClassName={styles.button}
                classList={['primary']}
                title={t('buttons:button.letsGo')}
                disabled={isButtonDisabled}
                clickHandler={() => confirmPassword()}
              />
            )}
          </div>
        ) : (
          <Fragment>
            <h1 className="title">{t('login:titleWrongLogin')}</h1>
            <span className={styles.subtitle}>{t('login:possibleLogin')}</span>
            {socialProviders?.googleProvider && (
              <SocialButton
                googleType
                clickHandler={() => loginSocial(socialProviders?.googleProvider)}
                isDisabled={isLoading}
              />
            )}
            {socialProviders?.facebookProvider && (
              <SocialButton
                facebookType
                clickHandler={() => loginSocial(socialProviders?.facebookProvider)}
                isDisabled={isLoading}
              />
            )}
            {socialProviders?.appleProvider && (
              <SocialButton
                appleType
                clickHandler={() => loginSocial(socialProviders?.appleProvider)}
                isDisabled={isLoading}
              />
            )}
            {isLoading && <Loader loadProps={{parentClassName: styles.loader}} />}
          </Fragment>
        )}
      </div>
    </Appeal>
  );
};

export default PopupAddPasswordProvider;
