import update from 'immutability-helper';
import {
  GetVolunteerByIdResponse,
  ICountsOpportunitiesResponse,
  IOpportunityResponse,
  OPPORTUNITY_VOLUNTEER_STATUS,
  OpportunityResponse,
} from '@joc/api-gateway';
import {UserResponse} from '@joc/api-gateway/lib/api-client';
import {
  CANTMAKEIT,
  CHANGE_STATUS_MANUAL_OPPORTUNITY,
  CREATE_OPPORTUNITY,
  DELETE_OPPORTUNITY,
  GET_COORDINATORS_BY_ID,
  GET_OPPORTUNITIES,
  GET_OPPORTUNITIES_COUNT,
  GET_OPPORTUNITIES_REMINDER_TAPE,
  GET_OPPORTUNITY_BY_ID,
  JOIN_TO_OPPORTUNITY_BY_VOLUNTEER,
  REFRESH_PENDING_OPPORTUNITIES,
  RESET_CURRENT_OPPORTUNITY,
  RESET_IS_NEED_REFRESH,
  RESET_OPPORTUNITIES_RECORDS,
  SET_IS_NEED_REFRESH,
  UPDATE_CURRENT_OPPORTUNITY,
  UPDATE_MANUAL_OPPORTUNITY,
  UPDATE_OPPORTUNITY,
} from '../actions-types';
import {
  ICantMakeItPayload,
  IChangeStatusManualOpportunityPayload,
  IOpportuinitiesPayload,
  IOpportunitiesReducer,
  OpportunitiesActionTypes,
} from './types';
import {UpdateCurrentOpportunityPayload} from 'core/types';
//helpers
import {getCurrentOpportunity, getCurrentOpportunityIndex, getPendingApprovalOpportunities} from './helpers';

const initState: IOpportunitiesReducer = {
  opportunitiesAll: {
    records: [],
    total: 0,
  },
  opportunitiesCount: {
    opportunitiesTotal: 0,
    opportunitiesUpcoming: 0,
    opportunitiesPendingVolunteers: 0,
    opportunitiesVacancies: 0,
    opportunitiesPast: 0,
    opportunitiesManual: 0,
    opportunitiesRejected: 0,
  },
  coordinators: [],
  currentOpportunity: undefined,
  opportunitiesReminderTape: [],
  isNeedRefresh: false,
};

const opportunitiesReducer = (state = initState, action: OpportunitiesActionTypes): IOpportunitiesReducer => {
  const {type, payload}: OpportunitiesActionTypes = action;

  switch (type) {
    case GET_OPPORTUNITIES:
      return {
        ...state,
        opportunitiesAll: {
          records: [...state.opportunitiesAll.records, ...(payload as IOpportuinitiesPayload).records],
          total: (payload as IOpportuinitiesPayload).total,
        },
      };
    case GET_OPPORTUNITY_BY_ID:
      return {
        ...state,
        currentOpportunity: payload as IOpportunityResponse,
      };
    case RESET_CURRENT_OPPORTUNITY:
      return {
        ...state,
        currentOpportunity: initState.currentOpportunity,
      };

    case GET_COORDINATORS_BY_ID:
      return {
        ...state,
        coordinators: payload as UserResponse[],
      };

    case UPDATE_CURRENT_OPPORTUNITY:
      const {
        data: {records, total},
        opportunityId,
      } = payload as UpdateCurrentOpportunityPayload;

      const currentOpportunity = getCurrentOpportunity(records, opportunityId);
      const opportunityIndex = getCurrentOpportunityIndex(state.opportunitiesAll.records, opportunityId);

      if (currentOpportunity) {
        return update(state, {
          currentOpportunity: {$set: currentOpportunity},
          opportunitiesAll: {
            records: {
              [opportunityIndex]: {$set: currentOpportunity as OpportunityResponse},
            },
            total: {$set: total},
          },
        });
      }
      return {...state};

    case RESET_OPPORTUNITIES_RECORDS: {
      return {...state, opportunitiesAll: initState.opportunitiesAll};
    }

    case GET_OPPORTUNITIES_REMINDER_TAPE:
      return {...state, opportunitiesReminderTape: (payload as IOpportuinitiesPayload).records};

    case CREATE_OPPORTUNITY: {
      return {
        ...state,
        opportunitiesAll: {
          records: [payload as OpportunityResponse, ...state.opportunitiesAll.records],
          total: state.opportunitiesAll.total + 1,
        },
        opportunitiesReminderTape: [payload as OpportunityResponse, ...state.opportunitiesReminderTape],
      };
    }

    case UPDATE_OPPORTUNITY: {
      const index = state.opportunitiesAll.records.findIndex((i) => i.id === (payload as OpportunityResponse).id);
      const reminderTapeIndex = state.opportunitiesReminderTape.findIndex(
        (i) => i.id === (payload as OpportunityResponse).id
      );
      state.opportunitiesAll.records[index] = payload;
      const newState = {
        ...state,
        opportunitiesAll: {...state.opportunitiesAll, records: [...state.opportunitiesAll.records]},
      };
      if (index >= 0 && reminderTapeIndex >= 0) {
        return update(newState, {
          opportunitiesReminderTape: {[reminderTapeIndex]: {$set: payload as OpportunityResponse}},
        });
      }
      if (index >= 0 && reminderTapeIndex < 0) {
        return newState;
      }
      if (index < 0 && reminderTapeIndex >= 0) {
        return update(state, {
          opportunitiesReminderTape: {[reminderTapeIndex]: {$set: payload as OpportunityResponse}},
        });
      }

      return state;
    }

    case UPDATE_MANUAL_OPPORTUNITY: {
      const index = state.opportunitiesAll.records.findIndex((i) => i.id === (payload as OpportunityResponse).id);
      const reminderTapeIndex = state.opportunitiesReminderTape.findIndex(
        (i) => i.id === (payload as OpportunityResponse).id
      );
      state.opportunitiesAll.records[index] = payload;
      const newState = {
        ...state,
        opportunitiesAll: {...state.opportunitiesAll, records: [...state.opportunitiesAll.records]},
      };
      if (index >= 0 && reminderTapeIndex >= 0) {
        return update(newState, {
          opportunitiesReminderTape: {[reminderTapeIndex]: {$set: payload as OpportunityResponse}},
        });
      }
      if (index >= 0 && reminderTapeIndex < 0) {
        return newState;
      }
      if (index < 0 && reminderTapeIndex >= 0) {
        return update(state, {
          opportunitiesReminderTape: {[reminderTapeIndex]: {$set: payload as OpportunityResponse}},
        });
      }
      return state;
    }
    case DELETE_OPPORTUNITY:
      return {
        ...state,
        opportunitiesAll: {
          records: state.opportunitiesAll.records.filter((i) => i.id !== payload),
          total: state.opportunitiesAll.total - 1,
        },
        opportunitiesReminderTape: state.opportunitiesReminderTape.filter((oppo) => oppo.id !== payload),
      };
    case GET_OPPORTUNITIES_COUNT:
      return {...state, opportunitiesCount: payload as ICountsOpportunitiesResponse};
    case CANTMAKEIT: {
      const opportunityIndex = state.opportunitiesAll.records.findIndex(
        (opportunity) => opportunity.id === (payload as ICantMakeItPayload).id
      );
      const opportunityData = state.opportunitiesAll.records[opportunityIndex];
      const volunteerIndex = opportunityData?.volunteers?.findIndex(
        (volunteer) => volunteer.id === (payload as ICantMakeItPayload).userVolunteerId
      );
      const volunteersData = opportunityData?.volunteers;
      if (volunteerIndex !== undefined && volunteersData) {
        let newData: IOpportunityResponse = {...opportunityData};
        if (volunteersData[volunteerIndex]?.status === OPPORTUNITY_VOLUNTEER_STATUS.CANT_MAKE_IT) {
          newData = {
            ...opportunityData,
            volunteers:
              opportunityData?.volunteers?.map((volunteer) => {
                return volunteer.id === (payload as ICantMakeItPayload).userVolunteerId
                  ? ({
                      ...volunteer,
                      status: OPPORTUNITY_VOLUNTEER_STATUS.CONFIRM,
                    } as GetVolunteerByIdResponse)
                  : volunteer;
              }) || undefined,
          };
        } else {
          newData = {
            ...opportunityData,
            volunteers:
              opportunityData?.volunteers?.map((volunteer) => {
                return volunteer.id === (payload as ICantMakeItPayload).userVolunteerId
                  ? ({
                      ...volunteer,
                      status: OPPORTUNITY_VOLUNTEER_STATUS.CANT_MAKE_IT,
                    } as GetVolunteerByIdResponse)
                  : volunteer;
              }) || undefined,
          };
        }
        return update(state, {
          opportunitiesAll: {
            records: {
              [opportunityIndex]: {$set: newData},
            },
          },
        });
      }
      return state;
    }
    case CHANGE_STATUS_MANUAL_OPPORTUNITY: {
      const index = state.opportunitiesAll.records.findIndex(
        (i) => +i.id === +(payload as IChangeStatusManualOpportunityPayload).id
      );
      const updatedOpportunity = {
        ...state.opportunitiesAll.records[index],
        status: (payload as IChangeStatusManualOpportunityPayload).opportunityStatus,
      };
      return update(state, {
        opportunitiesAll: {records: {[index]: {$set: updatedOpportunity}}},
      });
    }
    case JOIN_TO_OPPORTUNITY_BY_VOLUNTEER: {
      return {...state, currentOpportunity: payload as IOpportunityResponse};
    }

    case REFRESH_PENDING_OPPORTUNITIES: {
      const newRecords = getPendingApprovalOpportunities(state.opportunitiesAll.records);

      return update(state, {
        opportunitiesAll: {records: {$set: newRecords}},
      });
    }
    case SET_IS_NEED_REFRESH: {
      return {...state, isNeedRefresh: true};
    }
    case RESET_IS_NEED_REFRESH: {
      return {...state, isNeedRefresh: false};
    }

    default:
      return state;
  }
};

export default opportunitiesReducer;
