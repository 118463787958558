import {Dispatch, FC, SetStateAction, useEffect, useState} from 'react';
import {connect, ConnectedProps, useDispatch} from 'react-redux';
import {useHistory} from 'react-router';
import cx from 'classnames';
//redux
import {Store} from 'redux/root';
import {selectorGetUserOrganizationsWithRoles} from 'redux/user-service/selector';
import {getOrgInfo, resetGradesSettings} from 'redux/organization-service/actions';
import {selectorGetOrgName} from 'redux/organization-service/selector';
//assets
import {ReactComponent as BlueArrow} from 'assets/image/selects/blue-arrow.svg';
import {ReactComponent as WhiteArrow} from 'assets/image/selects/white-arrow.svg';
//urls
import {urls} from 'core/appUrls';
//functions
import {sortUserOrganisation} from 'core/functions';
//hooks
import {useClickOutside} from 'core/customHooks';
//types
import {OrganisationResponse, ORGANISATION_ACTIVE_STATUS} from '@joc/api-gateway';
//components
import Loader from 'shared/components/Loader';
//styles
import styles from './index.module.scss';
import {chatClient, resetChatCounter} from 'redux/chat-service/actions';

type UserOrganizationsSelectParentProps = {
  id?: string;
  placeholder?: string;
  classList?: Array<string>;
  setSelectOrganizationSuccess?: Dispatch<SetStateAction<boolean>>;
  isRedirect?: boolean;
  isFullHeight?: boolean;
};

const UserOrganizationsSelect: FC<UserOrganizationsSelectProps> = ({
  id,
  placeholder,
  getOrgInfo,
  selectedOrgName,
  classList,
  setSelectOrganizationSuccess,
  userOrganizationsWithRoles,
  isRedirect,
  isFullHeight,
}: UserOrganizationsSelectProps) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [options, setOptions] = useState<Array<OrganisationResponse | undefined> | undefined>([]);

  const {isComponentVisible, componentRef, setIsComponentVisible} = useClickOutside({
    initialVisible: false,
  });

  useEffect(() => {
    if (userOrganizationsWithRoles) {
      const sortedUserOrganisation = sortUserOrganisation(userOrganizationsWithRoles);
      if (sortedUserOrganisation?.length === 1) {
        setTimeout(() => optionClickHandler(sortedUserOrganisation[0]!.id), 700);
      } else {
        setOptions(sortedUserOrganisation);
        setIsLoading(false);
      }
    }
  }, [userOrganizationsWithRoles]);

  const optionClickHandler = async (id: string): Promise<void> => {
    try {
      await getOrgInfo(id);
      dispatch(resetChatCounter());
      dispatch(resetGradesSettings());
      localStorage.setItem('organisationId', id);
      setIsComponentVisible(false);
      setSelectOrganizationSuccess && setSelectOrganizationSuccess(true);
      isRedirect && history.push(urls.orgDashboard);
      chatClient.disconnectUser();
    } catch (error) {
      setSelectOrganizationSuccess && setSelectOrganizationSuccess(false);
    }
  };

  if (isLoading) return <Loader />;

  return (
    <div
      id={id}
      className={cx(styles.dropdown_select, {
        [styles.md]: classList?.includes('md'),
        [styles.maxWidthNone]: classList?.includes('maxWidthNone'),
        [styles.dropdown_select__active]: isComponentVisible,
      })}
      ref={componentRef}
    >
      <div
        className={cx(styles.dropdown_select__title, {
          [styles.dropdown_select__title_selected]: !!selectedOrgName?.length,
        })}
        onClick={(event) => {
          event.stopPropagation();
          setIsComponentVisible(!isComponentVisible);
        }}
      >
        <span>{selectedOrgName?.length ? selectedOrgName : placeholder}</span>
        {!isComponentVisible ? (
          <BlueArrow className={styles.dropdown_select__title__vector} />
        ) : (
          <WhiteArrow className={styles.dropdown_select__title__vector} />
        )}
      </div>
      <div
        className={cx(styles.dropdown_select__options, {
          [styles.dropdown_select__options_center]: classList?.includes('option_center'),
          [styles.fullHeight]: isFullHeight,
        })}
      >
        {options &&
          options.map((option) => (
            <div
              title={option?.organizationName}
              key={option?.id}
              className={cx(styles.dropdown_select__options__item, {
                [styles.dropdown_select__options__item_notActive]:
                  option?.organizationActiveStatus?.status !== ORGANISATION_ACTIVE_STATUS.ACTIVE,
              })}
              onClick={() => optionClickHandler(option!.id)}
            >
              <span className={styles.dropdown_select__options__item__text}>{option?.organizationName}</span>
            </div>
          ))}
      </div>
    </div>
  );
};

const mapStateToProps = (store: Store) => ({
  selectedOrgName: selectorGetOrgName(store),
  userOrganizationsWithRoles: selectorGetUserOrganizationsWithRoles(store),
});
const mapDispatchToProps = {
  getOrgInfo,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type UserOrganizationsSelectProps = ConnectedProps<typeof connector> & UserOrganizationsSelectParentProps;

export default connector(UserOrganizationsSelect);
