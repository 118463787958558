import {FC, Fragment, useEffect, useState} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {useHistory} from 'react-router';
//translation
import {useTranslation} from 'react-i18next';
//redux
import {Store} from 'redux/root';
import {selectorGetError} from 'redux/error-service/selector';
import {resetError} from 'redux/error-service/action';
//urls
import {MarketUrls, urls} from 'core/appUrls';
//functions
import {getStoreLink} from 'core/functions/checkBrowser';
//images
import {ReactComponent as AppStoreSvg} from 'assets/image/appstore.svg';
import {ReactComponent as GooglePlaySvg} from 'assets/image/googleplay.svg';
//components
import LoginFromEmailForm from 'components/Organization/Login/LoginFromEmailForm';
import Appeal from 'shared/components/Appeal/DefaultRenderer';
import ArrowBackLink from 'shared/components/ArrowBackLink';
import ResponseFailure from 'shared/components/ResponseFailure';
import CongratsPopup from 'components/Organization/CongratsPopup';
//styles
import styles from './LoginFromEmail.module.scss';
import cx from 'classnames';

const LoginFromEmail: FC<LoginFromEmailProps> = ({error, resetError}) => {
  const history = useHistory();

  const [isCreateUserSuccess, setIsCreateUserSuccess] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const {t} = useTranslation('messages');
  const [isVolunteer, setIsVolunteer] = useState(false);

  useEffect(() => {
    setIsVolunteer(!!localStorage.getItem('volunteer'));
    getStoreLink(window).link !== '#' && setIsMobile(true);

    return () => localStorage.clear();
  }, []);

  const openMarket = (link: string) => window.open(link, '_blank');

  return (
    <Fragment>
      {error.state ? (
        <ResponseFailure message={error.message} buttonClickHandler={resetError} />
      ) : isCreateUserSuccess ? (
        <CongratsPopup
          subtitle={t('accountHasBeenCreatedSuccessfully')}
          doneButtonClickHandler={() => {
            !isMobile && history.push(urls.onboardingChooseUserType);
          }}
          isFullHeight
          absoluteDisable
          withConfirm={!isMobile}
          buttonTitle={!isVolunteer ? 'Continue in WEB' : ''}
          isNeedMobileHideConfirm
          isConfirmMarginTop={!isVolunteer}
        >
          <AppStoreSvg
            className={cx(styles.icon, {[styles.allowIcon]: !isVolunteer})}
            onClick={() => openMarket(isVolunteer ? MarketUrls.AppStore : MarketUrls.AppStoreAdmin)}
          />
          <GooglePlaySvg
            className={cx(styles.icon, {[styles.allowIcon]: !isVolunteer})}
            onClick={() => openMarket(isVolunteer ? MarketUrls.PlayMarket : MarketUrls.PlayMarketAdmin)}
          />
        </CongratsPopup>
      ) : (
        <div className={styles.content}>
          <ArrowBackLink />
          <Appeal>
            <LoginFromEmailForm setIsCreateUserSuccess={setIsCreateUserSuccess} />
          </Appeal>
        </div>
      )}
    </Fragment>
  );
};

const mapStateToProps = (store: Store) => ({
  error: selectorGetError(store),
});

const mapDispatchToProps = {
  resetError,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type LoginFromEmailProps = ConnectedProps<typeof connector>;

export default connector(LoginFromEmail);
