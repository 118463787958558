import {FC, useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
//types
import {IDateRange} from '@joc/api-gateway';
//redux
import {Store} from 'redux/root';
import {connect, ConnectedProps} from 'react-redux';
import {getUsersGradeTimeSpent} from 'redux/dashboard-service/action';
import {
  selectorGetStatisticsStudentsAvgSpentTotal,
  selectorGetStatisticsUsersGradeTimeSpent,
} from 'redux/dashboard-service/selector';
//components
import WhiteContainer from 'shared/components/WhiteContainer';
import ResponseFailure from 'shared/components/ResponseFailure';
import {GradeTimeSpentBarChart} from '../GradeTimeSpentBarChart';
import ChartHeaderTotal from '../ChartHeaderTotal';
//styles
import styles from 'pages/Organization/Statistics/Statistics.module.scss';

type UsersGradeTimeSpentParentProps = {
  dateRange: IDateRange | null;
};

const UsersGradeTimeSpent: FC<UsersGradeTimeSpentProps> = ({
  UsersGradeTimeSpent,
  getUsersGradeTimeSpent,
  dateRange,
  studentsAverageSpendTime,
}: UsersGradeTimeSpentProps) => {
  const {toDate, fromDate} = {...dateRange};

  const [customError, setCustomError] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const {t} = useTranslation(['statistics', 'errors']);

  const getUsersGradeTimeSpentStatistics = useCallback(async () => {
    setIsLoading(true);
    setCustomError('');
    try {
      await getUsersGradeTimeSpent(dateRange);
    } catch (error) {
      setCustomError(error?.response?.message || error.message);
    } finally {
      setIsLoading(false);
    }
  }, [toDate, fromDate]);

  useEffect(() => {
    getUsersGradeTimeSpentStatistics();
  }, [getUsersGradeTimeSpentStatistics]);

  return (
    <WhiteContainer
      parentClassName={styles.container_block}
      title={t('statistics:userGradeTimeSpent.chesedHoursPerGrade')}
    >
      <ChartHeaderTotal avgMode count={studentsAverageSpendTime} />
      {customError.length && !isLoading ? (
        <ResponseFailure
          message={customError}
          buttonTitle={t('errors:tryAgain')}
          buttonClickHandler={getUsersGradeTimeSpentStatistics}
          styleTable
        />
      ) : (
        <GradeTimeSpentBarChart usersGradeTimeSpent={UsersGradeTimeSpent} isLoading={isLoading} />
      )}
    </WhiteContainer>
  );
};

const mapStateToProps = (store: Store) => ({
  UsersGradeTimeSpent: selectorGetStatisticsUsersGradeTimeSpent(store),
  studentsAverageSpendTime: selectorGetStatisticsStudentsAvgSpentTotal(store),
});

const mapDispatchToProps = {
  getUsersGradeTimeSpent,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type UsersGradeTimeSpentProps = ConnectedProps<typeof connector> & UsersGradeTimeSpentParentProps;

export default connector(UsersGradeTimeSpent);
