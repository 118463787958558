import {Dispatch} from 'redux';
import {RESET_ERROR, SET_ERROR} from 'redux/actions-types';

export const setError =
  (message: string | Array<string>, code: number) =>
  (dispatch: Dispatch): void => {
    if (Array.isArray(message)) {
      dispatch({
        type: SET_ERROR,
        payload: {state: true, message: (message as Array<string>).join(', '), code},
      });
    } else {
      dispatch({
        type: SET_ERROR,
        payload: {state: true, message, code},
      });
    }
  };

export const resetError =
  () =>
  (dispatch: Dispatch): void => {
    dispatch({
      type: RESET_ERROR,
      payload: {state: false, message: ''},
    });
  };
