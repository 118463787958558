import {FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
//helpers
import {CUSTOM_PHONE_INPUT_ID, inputPhoneMasks} from './helpers';
//types
import {FieldBaseProps} from 'core/types';
//components
import FieldWrapper from 'shared/inputs/FieldWrapper';
import PhoneInput, {CountryData} from 'react-phone-input-2';
//styles
import 'react-phone-input-2/lib/material.css';
import styles from './InputPhone.module.scss';

type InputPhoneProps = FieldBaseProps & {placeholder?: string; isAboutYou?: boolean};

const InputPhone: FC<InputPhoneProps> = (fieldBaseProps) => {
  const {field, form, disabled} = fieldBaseProps;
  const {setFieldValue, setFieldTouched} = form;

  const [isShowEdit, setIsShowEdit] = useState(false);

  // Placeholder only for US in About You Volunteer
  const [isShowPlaceholder, setisShowPlaceholder] = useState(false);

  const setFocus = () => {
    const element: HTMLInputElement | null = document.querySelector(`#${CUSTOM_PHONE_INPUT_ID}`);
    element?.focus();
  };

  useEffect(() => {
    setIsShowEdit(true);
  }, []);

  useEffect(() => {
    setisShowPlaceholder(!!fieldBaseProps?.isAboutYou);
  }, [fieldBaseProps?.isAboutYou]);

  const {t} = useTranslation('inputs');

  const onChangeHandler = (value: string, country: CountryData) => {
    setisShowPlaceholder(!!fieldBaseProps?.isAboutYou && country.countryCode === 'us' && value === country.dialCode);
    !value.length ? setFieldValue(field.name, '') : setFieldValue(field.name, `+${value}`.trim());
  };

  const onBlurHandler = () => {
    setFieldTouched(field.name, true);
    setIsShowEdit(true);
  };

  return (
    <FieldWrapper {...fieldBaseProps}>
      <div className={styles.PhoneInputWrapper}>
        {isShowPlaceholder && (
          <span className={styles.PhonePlaceholderUS} onClick={setFocus}>
            (000) 000-0000
          </span>
        )}
        <PhoneInput
          placeholder={fieldBaseProps?.placeholder || t('placeholders.phone')}
          specialLabel=""
          preferredCountries={['us', 'il']}
          containerClass={styles.phone}
          inputClass={styles.phone__input}
          dropdownClass={styles.flag__dropdown}
          buttonClass={styles.flag__button}
          onBlur={onBlurHandler}
          onChange={onChangeHandler}
          onFocus={() => setIsShowEdit(false)}
          value={field.value}
          country="us"
          disabled={disabled}
          inputProps={{id: CUSTOM_PHONE_INPUT_ID, autocomplete: 'off'}}
          masks={inputPhoneMasks}
        />
        {field.value && isShowEdit && !disabled && <div className={styles.phone__input_edit} onClick={setFocus} />}
      </div>
    </FieldWrapper>
  );
};

export default InputPhone;
