import {FC} from 'react';
import {useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import cx from 'classnames';
//images
import {ReactComponent as BackArrowSvg} from 'assets/image/selects/blue-arrow.svg';
//styles
import styles from './ArrowBackLink.module.scss';

type ArrowBackLinkPropsType = {
  parentClassName?: string;
  onboardingStyle?: boolean;
  goToStep?: number;
  backToDashboard?: boolean;
};

const ArrowBackLink: FC<ArrowBackLinkPropsType> = ({
  parentClassName,
  onboardingStyle,
  goToStep,
  backToDashboard = false,
}: ArrowBackLinkPropsType) => {
  const history = useHistory();

  const {t} = useTranslation('buttons');

  const handleClick = () => {
    if (backToDashboard) {
      history.push('/');
    } else {
      history.go(goToStep || -1);
    }
  };

  return (
    <div
      className={cx(styles.container, 'link', parentClassName && parentClassName, {
        [styles.onboarding]: onboardingStyle,
      })}
      onClick={handleClick}
    >
      <BackArrowSvg />
      <span>{t('button.back')}</span>
    </div>
  );
};

export default ArrowBackLink;
