import grade1 from '!file-loader!assets/image/level/Level01.svg';
import grade2 from '!file-loader!assets/image/level/Level02.svg';
import grade3 from '!file-loader!assets/image/level/Level03.svg';
import grade4 from '!file-loader!assets/image/level/Level04.svg';
import grade5 from '!file-loader!assets/image/level/Level05.svg';
import grade6 from '!file-loader!assets/image/level/Level06.svg';
import grade7 from '!file-loader!assets/image/level/Level07.svg';
import grade8 from '!file-loader!assets/image/level/Level08.svg';
import grade9 from '!file-loader!assets/image/level/Level09.svg';
import grade10 from '!file-loader!assets/image/level/Level10.svg';
import grade11 from '!file-loader!assets/image/level/Level11.svg';
import grade12 from '!file-loader!assets/image/level/Level12.svg';
import grade13 from '!file-loader!assets/image/level/Level13.svg';
import grade14 from '!file-loader!assets/image/level/Level14.svg';
import grade15 from '!file-loader!assets/image/level/Level15.svg';
import grade16 from '!file-loader!assets/image/level/Level16.svg';
import grade17 from '!file-loader!assets/image/level/Level17.svg';
import grade18 from '!file-loader!assets/image/level/Level18.svg';
import grade19 from '!file-loader!assets/image/level/Level19.svg';
import grade20 from '!file-loader!assets/image/level/Level20.svg';

export const GRADE_IMAGES = {
  grade1,
  grade2,
  grade3,
  grade4,
  grade5,
  grade6,
  grade7,
  grade8,
  grade9,
  grade10,
  grade11,
  grade12,
  grade13,
  grade14,
  grade15,
  grade16,
  grade17,
  grade18,
  grade19,
  grade20,
};
