// days diff count
export const VOLUNTEER_BADGE_DATE_DIFF = 7;

// export const VOLUNTEERS_FULL_TEXT_SEARCH_FIELDS = [
// 	'id',
// 	'firstName',
// 	'lastName',
// 	'email',
// 	'phoneNumber',
// 	'address',
// 	'role',
// 	'birthDate',
// 	'gender',
// 	'instagramLink',
// 	'imagePath',
// 	'points',
// ];
