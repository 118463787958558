import moment from 'moment';
import {GetLocationIdByAddressRequest, INewCreateUserRequest} from '@joc/api-gateway';

export const initialValuesAboutYouSignupForm: INewCreateUserRequest = {
  imagePath: '',
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  // TODO [JF-982]: change to enum positionId
  positionId: 1,
  // TODO: [JF-983] change roleId to enum
  roleId: 4,
  birthDate: undefined,
  address: GetLocationIdByAddressRequest.fromJS({
    countryName: '',
    stateName: '',
    cityName: '',
    streetName: '',
    buildingNumber: '',
    zipCode: '',
    pobox: '',
  }),
  // TODO: [JF-984] [new][be] change genderId to enum
  gender: 0,
  // TODO: [be] remove instagram link
  instagramLink: 'instagram.com',
};

export const mokValuesAboutYouSignupForm: INewCreateUserRequest = {
  imagePath: '401d3c60-c0d3-4bd6-9099-c370b8668c17-53-min.jpeg',
  firstName: 'Joan',
  lastName: 'Doe',
  email: '',
  phoneNumber: '+380681684122',
  positionId: 1,
  roleId: 4,
  birthDate: moment().subtract(18, 'years').toDate(),
  address: GetLocationIdByAddressRequest.fromJS({
    countryName: 'Canada',
    stateName: 'Ontario',
    cityName: 'Richmond Hill',
    streetName: 'Highway 7',
    buildingNumber: '600',
    zipCode: 'L4B 1B2',
    pobox: 'L4B 1B2',
  }),
  gender: 2,
  instagramLink: 'instagram.com',
};
