import * as Yup from 'yup';

import i18n from 'components/translate';

export type RecoverySendEmailFormValues = {
  email: string;
};

export const initialValues: RecoverySendEmailFormValues = {
  email: '',
};

const validationT = i18n.getFixedT(null, 'validation');

export const validationSchema = Yup.object().shape({
  email: Yup.string().email(validationT('emailSchema.email')).required(validationT('emailSchema.required')),
});
