import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import cx from 'classnames';
//formik
import {Field, useFormikContext} from 'formik';
//types
import {RepeatOpportunityModalFormValues} from '../../formConfiguration';
//helpers
import {frequencyLabels} from './helpers';
//components
import Input from 'shared/inputs/Input';
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
//styles
import styles from './RepeatsEvery.module.scss';
import InputErrorHint from 'shared/components/InputErrorHint';

const RepeatsEvery: FC = () => {
  const {
    setFieldValue,
    values: {frequency},
    errors: {interval},
  } = useFormikContext<RepeatOpportunityModalFormValues>();

  const {t} = useTranslation(['form', 'inputs']);

  const repeatsLabel = t('form:opportunity.repeatsEvery');

  const setNewFrequencyClickHandler = (frequency: string) => {
    setFieldValue('frequency', frequency);
  };

  return (
    <div>
      <label>{repeatsLabel}</label>

      <div className={styles.content__wrapper}>
        <div>
          <Field
            name="interval"
            type="number"
            min={1}
            max={999}
            parentClassName={cx(styles.container, styles.input)}
            isPencilHidden
            disableErrorText
            component={Input}
          />

          {!!interval && (
            <div className={styles.upload__error}>
              <InputErrorHint errorText={interval} parentClassName={styles.error} />
            </div>
          )}
        </div>

        {frequencyLabels.map((frequencyLabel, index) => (
          <ButtonDefault
            key={frequencyLabel + index}
            title={frequencyLabel}
            parentClassName={cx(styles.button, {[styles.button_active]: frequencyLabel === frequency})}
            clickHandler={() => setNewFrequencyClickHandler(frequencyLabel)}
            classList={['primary', 'md']}
          />
        ))}
      </div>
    </div>
  );
};

export default RepeatsEvery;
