import {IOpportunityResponse} from '@joc/api-gateway';
import {Item} from 'components/Volunteer/UploadSeveralPhotos/ItemPreview';
import moment from 'moment';

export function convertFromStringToItem(filePathes: string[]): Item[] {
  return filePathes.map((path) => ({
    id: path,
    src: path,
    isChecked: false,
    isNew: false,
  }));
}

export const getFormInitialValues = (opportunityData: IOpportunityResponse, userId?: number) => {
  return {
    ...opportunityData,
    user: Number(opportunityData.user?.id),
    opportunityName: opportunityData.opportunityName,
    opportunityDescription: opportunityData.opportunityDescription,
    filePathes: convertFromStringToItem(opportunityData!.filePathes),
    endTime: +moment.duration(opportunityData?.duration, 'seconds').asHours(),
    gender: undefined,
    tagId: opportunityData.tagId ? Number(opportunityData.tagId) : null,
  };
};

export const getVolunteerFullName = (oppo?: IOpportunityResponse) => {
  const {volunteers} = oppo ?? {};
  const firstVolunteer = volunteers?.[0];
  const firstName = firstVolunteer?.firstName || '';
  const lastName = firstVolunteer?.lastName || '';
  return `${firstName} ${lastName}`;
};
