import {FC, useMemo} from 'react';
//images
import {ReactComponent as CalendarSvg} from 'assets/image/calendar.svg';
//functions
import {generateLocation, getDurationDetailsWithDate} from 'core/functions';
//types
import {IOpportunityResponse} from '@joc/api-gateway'; //helpers
import {getDateDetails, isManualOppo} from './helpers';
//styles
import styles from './DateDetails.module.scss';
import {LocationInfo} from './LocationInfo';
import {VirtualInfo} from './VirtualInfo';

interface IDateDetailsProps {
  opportunityInfo: IOpportunityResponse;
}

const DateDetails: FC<IDateDetailsProps> = ({opportunityInfo}) => {
  const {
    address,
    endDate,
    duration,
    isVirtual,
    startDate,
    opportunityType,
    opportunityUrl: virtualInfo,
  } = opportunityInfo;

  const timeInfo = useMemo(
    () =>
      isManualOppo(opportunityType)
        ? getDurationDetailsWithDate(duration, endDate, startDate, 'medium')
        : getDateDetails(startDate, endDate),
    [opportunityType, duration, endDate, startDate]
  );

  const locationText = generateLocation(address);

  const dateDetailsInfoText = useMemo(
    () => (isVirtual ? virtualInfo : locationText),
    [isVirtual, virtualInfo, locationText]
  );

  return (
    <div className={styles.date__details}>
      <div className={styles.date__details_when}>
        <div className={styles.date__ico}>
          <CalendarSvg />
        </div>

        <div className={styles.date__text}>{timeInfo}</div>
      </div>

      {address && !isVirtual ? <LocationInfo text={dateDetailsInfoText} /> : <VirtualInfo text={dateDetailsInfoText} />}
    </div>
  );
};

export default DateDetails;
