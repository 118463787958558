// react
import {FC} from 'react';
import {useTranslation} from 'react-i18next';
// interfaces
import {CreateManualOppModalData} from '../interfaces';
// types
import {IBasicVolunteerResponse} from '@joc/api-gateway';
// icons
import {ReactComponent as PlusIcon} from 'assets/icons/plus.svg';
// components
import EditVolunteerCell from 'shared/components/Table/CellRenderers/EditVolunteerCell';
//styles
import styles from './index.module.scss';

interface IAdminTableBodyCellProps {
  refreshVolunteers: () => void;
  volunteer: IBasicVolunteerResponse;
  openCreateManualOppModal: (data?: CreateManualOppModalData | undefined) => void;
}

export const AdminTableBodyCell: FC<IAdminTableBodyCellProps> = ({
  volunteer,
  refreshVolunteers,
  openCreateManualOppModal,
}) => {
  const {t} = useTranslation(['popup', 'buttons', 'tooltips']);

  const handleAddManualOpp = () => {
    const {firstName, lastName, id: volunteerId} = volunteer;
    const volunteerName = `${firstName || ''} ${lastName || ''}`;
    openCreateManualOppModal({volunteerId, volunteerName, refreshVolunteers});
  };

  return (
    <div className={styles.adminCell__wrapper}>
      <EditVolunteerCell volunteer={volunteer} />
      <PlusIcon onClick={handleAddManualOpp} title={t('tooltips:createOppo')} className={styles.adminCell__add_icon} />
    </div>
  );
};
