import {hourMinLimit, maxLimit} from './data';
import {IDisabledButtonState, ITime} from './interfaces';
import {Dispatch, SetStateAction} from 'react';

export const checkButtonState = (
  isDisabled: boolean,
  time: number,
  type: 'hour' | 'minute',
  hourTime: number,
  isMinusButton?: boolean
) => {
  return isDisabled || isMinusButton
    ? type === 'hour'
      ? time <= hourMinLimit
      : hourTime === hourMinLimit && time === 0
    : time >= (type === 'hour' ? maxLimit : hourTime && hourTime === maxLimit ? 0 : 1000);
};

export const transformInMillisecond = (time: number) => {
  const millisecondTime = time * 3600;
  const hour = Math.floor(millisecondTime / 3600);
  const minute = Math.floor((millisecondTime % 3600) / 60);

  return {hour, minute};
};

export const transformInHourFormat = (time: {hour: number; minute: number}): number => {
  const {hour, minute} = time;
  const totalSeconds = hour * 3600 + minute * 60;
  return totalSeconds / 3600;
};

export const handleCheckingAllButtonsForDisabled = (
  time: ITime,
  disabled: boolean,
  setButtonDisabledState: Dispatch<SetStateAction<IDisabledButtonState>>
) => {
  setButtonDisabledState({
    isMinusActionHour: checkButtonState(disabled, time.hour, 'hour', time.hour, true),
    isPlusActionHour: checkButtonState(disabled, time.hour, 'hour', time.hour, false),
    isMinusActionMinute: checkButtonState(disabled, time.minute, 'minute', time.hour, true),
    isPlusActionMinute: checkButtonState(disabled, time.minute, 'minute', time.hour, false),
  });
};
