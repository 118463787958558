import {ComponentType} from 'react';
import {TypingIndicatorProps, TypingIndicator} from 'stream-chat-react';
//components
import UserChatAvatar from '../UserChatAvatar';

export const CustomTypingIndicator: ComponentType<TypingIndicatorProps> = () => (
  <>
    <TypingIndicator Avatar={UserChatAvatar} avatarSize={32} />
  </>
);
