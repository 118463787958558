import {createContext, Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useState} from 'react';
import cx from 'classnames';
import {useTranslation} from 'react-i18next';
import {values, mergeWith, keyBy, sortBy} from 'lodash';
//functions
import {convertTimeToHoursAndMinutes, formatDate, generateLocation} from 'core/functions';
import {useScrollUpArrow} from 'hooks/useScrollUpArrow';
//API
import {API} from 'core/API';
//redux
import {useDispatch, useSelector} from 'react-redux';
import {setError} from 'redux/error-service/action';
import {
  selectorGetOrgActiveStatus,
  selectorGetOrgId,
  selectorGetOrgInfo,
  selectorGetSchoolId,
} from 'redux/organization-service/selector';
//images
import {ReactComponent as ArrowSvg} from 'assets/icons/arrows-up-circle.svg';
//types
import {
  OPPORTUNITY_VOLUNTEER_STATUS,
  IOpportunityResponse,
  ISearchOpportunitiesRequest,
  ISearchOpportunityResponse,
  OPPORTUNITY_STATUSES,
  PaginationRequest,
  SearchOpportunitiesRequest,
  SearchOpportunitiesSort,
  SearchOpportunityResponse,
  SEARCH_OPPORTUNITIES_POSSIBLE_SORT,
  SortDirection,
  OPPORTUNITY_TYPES,
  ORGANISATION_GENERAL_TYPES,
  ORGANISATION_ACTIVE_STATUS,
} from '@joc/api-gateway';
import {TableClass} from './enum';
import {PossibleSortBy} from 'shared/components/Table/TableHead/TableHeadCell/helpers';
import {SelectOption} from 'shared/inputs/Select/types';
//constants
import {
  TABLE_HEADERS,
  TABLE_ITEMS_CLASSNAMES,
  INITIAL_PAGINATION,
  TABLE_HEADER_CLASSNAMES,
  TABLE_ROW_CLASSNAMES,
} from 'core/constants';
//helpers
import {OpportunitiesStartDayFrom, getFilteredOppo, getOpportunitiesStartDay, getVolunteerTimeSpend} from './helpers';
import {getExportedPastOppo} from '../ExportPopup/helpers';
import {useOpportunitiesContext} from 'pages/Organization/Opportunities/context';
//components
import CellDefault from 'shared/components/Table/CellRenderers/CellDefault';
import FullNameCell from 'shared/components/Table/CellRenderers/FullNameCell';
import OpportunityNameCell from 'shared/components/Table/CellRenderers/OpportunityNameCell';
import TableBody from 'shared/components/Table/TableBody';
import TableHead from 'shared/components/Table/TableHead';
import TableHeadCell from 'shared/components/Table/TableHead/TableHeadCell';
import TableMain from 'shared/components/Table/TableMain';
import TableRow from 'shared/components/Table/TableRow';
import PopupContainer from 'shared/components/PopupContainer';
import ResponseFailure from 'shared/components/ResponseFailure';
import Loader from 'shared/components/Loader';
import Observer from 'shared/components/Observer';
import StickyHeader from 'shared/components/Table/TableHead/StickyHeader';
import TableHeadCellWithSort from 'shared/components/Table/TableHead/TableHeadCell/TableHeadCellWithSort';
import SelectBase from 'shared/inputs/Select/SelectBase';
import ButtonImage from 'shared/components/Buttons/ButtonImage';
import {TableActionsPopup} from 'components/TableActionsPopup';

//styles
import styles from './VolunteerPastOpportunitiesPopup.module.scss';
import {selectorGetOpportunitiesIsNeedRefresh} from 'redux/opportunities-service/selector';
import {resetIsNeedRefresh} from 'redux/opportunities-service/action';

type VolunteerPastOpportunitiesParentPopupProps = {
  volunteerId: number;
  setIsShowPopup: Dispatch<SetStateAction<boolean>>;
  volunteerFullName: string;
};

export const PastOppoContext = createContext<boolean>(false);

const VolunteerPastOpportunitiesPopup: FC<VolunteerPastOpportunitiesParentPopupProps> = ({
  volunteerId,
  setIsShowPopup,
  volunteerFullName,
}) => {
  const dispatch = useDispatch();
  const {t, i18n} = useTranslation(['errors', 'popup', 'chesedOpportunities', 'common']);
  const {isScrollTopVisible, arrowStyle, addListener, removeListener, scrollToTop} = useScrollUpArrow();

  const schoolId = useSelector(selectorGetSchoolId);
  const orgId = useSelector(selectorGetOrgId);

  const orgInfo = useSelector(selectorGetOrgInfo);
  const orgStatus = useSelector(selectorGetOrgActiveStatus);
  const isNeedRefresh = useSelector(selectorGetOpportunitiesIsNeedRefresh);

  const {generalTabClickHandler} = useOpportunitiesContext();

  const isSuspended = useMemo(() => orgStatus?.status === ORGANISATION_ACTIVE_STATUS.SUSPENDED, [orgStatus]);

  const [volunteerPastOpportunities, setVolunteerPastOpportunities] = useState<
    ISearchOpportunityResponse | undefined
  >();

  const [isLoading, setIsLoading] = useState(false);
  const [customError, setCustomError] = useState('');
  const [pagination, setPagination] = useState(INITIAL_PAGINATION);
  const [sort, setSort] = useState<SearchOpportunitiesSort>();
  const [opportunitiesStartDayFrom, setOpportunitiesStartDayFrom] = useState<OpportunitiesStartDayFrom>('total');

  const startDaySelectOptions: Array<SelectOption<OpportunitiesStartDayFrom>> = [
    {title: t('chesedOpportunities:thisYear'), value: 'thisYear'},
    {title: t('common:titles.totalOpportunities'), value: 'total'},
  ];

  const startDaySelectChangeHandler = (value: string) => {
    setVolunteerPastOpportunities(undefined);
    setPagination(INITIAL_PAGINATION);
    setOpportunitiesStartDayFrom(value as OpportunitiesStartDayFrom);
  };

  const getTextDate = useCallback(
    (opportunity: IOpportunityResponse) => {
      const date = formatDate(opportunity.startDate);
      const checkIsManual = opportunity.opportunityType !== OPPORTUNITY_TYPES.MANUAL;
      const startDate = checkIsManual ? convertTimeToHoursAndMinutes(opportunity.startDate) : '';
      const endDate = checkIsManual ? convertTimeToHoursAndMinutes(opportunity.endDate) : '';

      if (startDate && endDate) {
        return `${date}\n${startDate}-${endDate}`;
      }

      return date;
    },
    [volunteerPastOpportunities?.records]
  );

  const locale = i18n.language;

  useEffect(() => {
    if (isNeedRefresh && volunteerPastOpportunities?.records.length)
      setPagination({skip: 0, take: volunteerPastOpportunities?.records.length});
  }, [isNeedRefresh]);

  const volunteerPastOpportunitiesRequestBody: ISearchOpportunitiesRequest | null = useMemo(() => {
    if (!volunteerId) return null;

    const {CLOSED} = OPPORTUNITY_STATUSES;
    const {CONFIRM} = OPPORTUNITY_VOLUNTEER_STATUS;

    return {
      sort,
      volunteerId,
      status: [CLOSED],
      volunteerStatus: [CONFIRM],
      pagination: PaginationRequest.fromJS(pagination),
      organisationId: !schoolId ? Number(orgInfo?.id) : undefined,
      startDay: getOpportunitiesStartDay(opportunitiesStartDayFrom, orgInfo),
    };
  }, [pagination, volunteerId, sort, opportunitiesStartDayFrom, schoolId, orgInfo]);

  const getVolunteerPastOpportunitiesByOrg = useCallback(async () => {
    setIsLoading(true);
    try {
      if (!volunteerPastOpportunitiesRequestBody)
        throw new Error(`${t('errors:sorry')} ${t('errors:cantFindCurrentVolunteer')}`);

      const opportunitiesResponse = await API.searchOpportunitiesByOrganisation(
        undefined,
        undefined,
        SearchOpportunitiesRequest.fromJS(volunteerPastOpportunitiesRequestBody)
      );

      const totalOppo = values(
        mergeWith(
          keyBy(volunteerPastOpportunities?.records || [], 'id'),
          keyBy(opportunitiesResponse.records, 'id'),
          (objValue, srcValue) => {
            if (objValue && srcValue) {
              return {...objValue, ...srcValue};
            }
          }
        )
      )
        .filter((oppo) => {
          if (isNeedRefresh) {
            return opportunitiesResponse.records.some((oppoRes) => Number(oppoRes.id) === Number(oppo.id));
          }

          return oppo;
        })
        .sort((a, b) => Number(b.id) - Number(a.id));

      setVolunteerPastOpportunities({
        total: opportunitiesResponse.total,
        records: getFilteredOppo(totalOppo, volunteerId),
        pagination: SearchOpportunityResponse.fromJS(pagination),
      });

      if (isNeedRefresh) {
        dispatch(resetIsNeedRefresh());
      }

      setIsLoading(false);
    } catch (error) {
      setCustomError(error?.response?.message || error.message);
      setIsLoading(false);
    }
  }, [volunteerPastOpportunitiesRequestBody]);

  const setSortByField = useCallback(
    (sortBy: PossibleSortBy) => {
      setVolunteerPastOpportunities(undefined);
      setPagination(INITIAL_PAGINATION);
      setSort(
        SearchOpportunitiesSort.fromJS({
          sortBy,
          sortDir: sort?.sortDir === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC,
        })
      );
    },
    [sort]
  );

  const buttonDownloadClickHandler = async () => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      if (!accessToken) throw new Error(`${t('errors:sorry')} ${t('errors:cantFindAccessToken')}`);

      if (!volunteerPastOpportunitiesRequestBody?.volunteerId)
        throw new Error(`${t('errors:sorry')} ${t('errors:cantFindOrgId')}`);

      const {pagination, ...requestBody} = volunteerPastOpportunitiesRequestBody;
      await getExportedPastOppo(requestBody, accessToken, orgId, volunteerFullName, schoolId);
    } catch (error) {
      console.error(error);
      dispatch(setError(error.message, error.code));
    }
  };

  useEffect(() => {
    getVolunteerPastOpportunitiesByOrg();
  }, [getVolunteerPastOpportunitiesByOrg]);

  useEffect(() => {
    const smartGroupWrapperRef = document.querySelector(`.${TableClass.ScrollContainer}`);

    addListener(smartGroupWrapperRef, volunteerPastOpportunities);

    return () => {
      removeListener(smartGroupWrapperRef, volunteerPastOpportunities);
    };
  }, [volunteerPastOpportunities]);

  return (
    <div className={styles.main}>
      <PopupContainer
        containerClassName={TableClass.ScrollContainer}
        isContentFullWidth
        setIsShowPopup={setIsShowPopup}
      >
        <div className={styles.top}>
          <h2 className={cx('title', styles.title)}>{`${
            locale === 'en-US' ? `${volunteerFullName}'s` : volunteerFullName
          } ${t('popup:volunteer.pastOpportunities')}`}</h2>
          <div className={styles.top__container}>
            {!!volunteerPastOpportunities?.records?.length && (
              <ButtonImage clickHandler={buttonDownloadClickHandler} viewStyle="download" />
            )}
            {orgInfo?.organisationGeneralType === ORGANISATION_GENERAL_TYPES.SCHOOL && (
              <SelectBase
                className={styles.select}
                value={opportunitiesStartDayFrom}
                options={startDaySelectOptions}
                onChange={startDaySelectChangeHandler}
              />
            )}
          </div>
        </div>
        {isLoading && !volunteerPastOpportunities?.records?.length ? (
          <Loader loadProps={{stylePageCenter: true}} />
        ) : !isLoading && !volunteerPastOpportunities?.records?.length ? (
          <ResponseFailure message={t('errors:cantFindVolunteersClosedOpps')} buttonUnshow styleTable />
        ) : (
          <TableMain className={TableClass.TableMain}>
            <StickyHeader>
              <TableHead callChild={TABLE_HEADER_CLASSNAMES.past_oppo}>
                <TableHeadCellWithSort
                  itemClassName={TABLE_ITEMS_CLASSNAMES.chesedName}
                  text={TABLE_HEADERS.chesedName}
                  clickSortHandler={setSortByField}
                  sortProps={{sort, ownSortBy: SEARCH_OPPORTUNITIES_POSSIBLE_SORT.OpportunityName}}
                />
                <TableHeadCellWithSort
                  itemClassName={TABLE_ITEMS_CLASSNAMES.date}
                  text={TABLE_HEADERS.date}
                  clickSortHandler={setSortByField}
                  sortProps={{sort, ownSortBy: SEARCH_OPPORTUNITIES_POSSIBLE_SORT.StartDay}}
                />
                <TableHeadCellWithSort
                  itemClassName={TABLE_ITEMS_CLASSNAMES.completedHours}
                  text={TABLE_HEADERS.completedHours}
                  clickSortHandler={setSortByField}
                  sortProps={{sort, ownSortBy: SEARCH_OPPORTUNITIES_POSSIBLE_SORT.VolunteerSpendTime}}
                />
                <TableHeadCell itemClassName={TABLE_ITEMS_CLASSNAMES.location} text={TABLE_HEADERS.location} />
                <TableHeadCell itemClassName={TABLE_ITEMS_CLASSNAMES.coordinator} text={TABLE_HEADERS.coordinator} />
                <TableHeadCellWithSort
                  itemClassName={TABLE_ITEMS_CLASSNAMES.organization}
                  text={TABLE_HEADERS.organization}
                  clickSortHandler={setSortByField}
                  sortProps={{sort, ownSortBy: SEARCH_OPPORTUNITIES_POSSIBLE_SORT.Organisation}}
                />
                <TableHeadCell itemClassName={TABLE_ITEMS_CLASSNAMES.options} />
              </TableHead>
            </StickyHeader>
            <TableBody>
              {volunteerPastOpportunities?.records.map((opportunity: IOpportunityResponse) => {
                return (
                  <TableRow key={opportunity.id} callChild={TABLE_ROW_CLASSNAMES.past_oppo}>
                    <PastOppoContext.Provider value>
                      <OpportunityNameCell
                        opportunityName={opportunity.opportunityName}
                        opportunityType={opportunity.opportunityType}
                        opportunityNameClass={styles.fullname}
                        adminId={opportunity.user?.id}
                        opportunity={opportunity}
                        volunteers={opportunity.volunteers}
                        isVerified={opportunity.isVerificated}
                      />
                    </PastOppoContext.Provider>
                    <CellDefault parentClassName={TABLE_ITEMS_CLASSNAMES.date} text={getTextDate(opportunity)} />
                    <CellDefault
                      className={styles.completed_hours}
                      parentClassName={TABLE_ITEMS_CLASSNAMES.duration}
                      text={getVolunteerTimeSpend(opportunity, volunteerId)}
                    />
                    <CellDefault
                      parentClassName={TABLE_ITEMS_CLASSNAMES.location}
                      text={opportunity?.isVirtual ? opportunity.opportunityUrl : generateLocation(opportunity.address)}
                    />
                    <FullNameCell
                      firstName={opportunity.user?.firstName}
                      lastName={opportunity.user?.lastName}
                      disableTextStyle
                    />
                    <CellDefault
                      parentClassName={TABLE_ITEMS_CLASSNAMES.organization}
                      text={
                        opportunity.organisation?.organizationName
                          ? opportunity.organisation?.organizationName
                          : schoolId
                          ? 'Private'
                          : ''
                      }
                    />
                    <PastOppoContext.Provider value>
                      <TableActionsPopup
                        opportunity={opportunity}
                        isSuspended={isSuspended}
                        generalTabClickHandler={generalTabClickHandler}
                        isPastOppoView
                      />
                    </PastOppoContext.Provider>
                  </TableRow>
                );
              })}
            </TableBody>
          </TableMain>
        )}
        {!!volunteerPastOpportunities?.records?.length &&
          volunteerPastOpportunities.records.length !== volunteerPastOpportunities?.total &&
          !customError.length && (
            <Observer
              paginationSkip={volunteerPastOpportunities.records.length}
              setPagination={setPagination}
              isLoading={isLoading}
            />
          )}
        <div
          onClick={scrollToTop}
          style={arrowStyle}
          className={cx(styles.scrollIcon, {[styles.scrollIcon_visible]: isScrollTopVisible})}
        >
          <ArrowSvg />
        </div>
      </PopupContainer>
    </div>
  );
};

export default VolunteerPastOpportunitiesPopup;
