import {FC} from 'react';
import {useHistory} from 'react-router';
import {useTranslation} from 'react-i18next';
import cx from 'classnames';
//images
import {ReactComponent as PhoneSvg} from 'assets/image/phone.svg';
import Chat from 'assets/image/chat2.svg';
//urls
import {urls} from 'core/appUrls';
//functions
import {setFullName} from 'core/functions';
//helpers
import {getNewLocationUrl} from './helpers';
//styles
import styles from './VolunteerContact.module.scss';

type VolunteerContactProps = {
  firstName: string | undefined;
  lastName: string | undefined;
  isVolunteerSuspended: boolean;
  phoneNumber?: string;
  chatId: string | undefined;
  organizationName: string | undefined;
};

const VolunteerContact: FC<VolunteerContactProps> = ({
  firstName,
  lastName,
  isVolunteerSuspended,
  phoneNumber,
  chatId,
  organizationName,
}) => {
  const history = useHistory();

  const {t} = useTranslation(['oppoViewVolunteer', 'buttons']);

  const createChat = () => {
    const newLocation = getNewLocationUrl(urls.volInbox, chatId, organizationName);
    history.push(newLocation);
  };

  const fullName = setFullName(firstName, lastName);

  return (
    <div className={styles.mainContent}>
      <span>{t('oppoViewVolunteer:personOfContact')}</span>
      <span className={styles.userName} title={fullName}>
        {fullName}
      </span>
      <img
        className={cx(styles.mainContent__messageLink, {[styles.disabled]: isVolunteerSuspended})}
        src={Chat}
        alt="contact person"
        title={t('buttons:button.contactByMessage')}
        onClick={createChat}
      />
      {phoneNumber && (
        <a
          className={styles.mainContent__contactLink}
          href={`call:${phoneNumber}`}
          title={t('buttons:button.contactByPhone')}
        >
          <PhoneSvg /> {phoneNumber}
        </a>
      )}
    </div>
  );
};

export default VolunteerContact;
