import {FC} from 'react';
import {ReactComponent as LocationSvg} from 'assets/image/location.svg';
// styles
import styles from './index.module.scss';

interface ILocationInfoProps {
  text: string;
}

export const LocationInfo: FC<ILocationInfoProps> = ({text}) => {
  return (
    <div className={styles.date__details_where}>
      <div className={styles.date__ico}>
        <LocationSvg />
      </div>
      <div className={styles.date__text}>{text}</div>
    </div>
  );
};
