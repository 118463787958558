import {FC} from 'react';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import cx from 'classnames';
//urls
import {urls} from 'core/appUrls';
//styles
import styles from './RecoveryPasswordLink.module.scss';

const RecoveryPasswordLink: FC = () => {
  const {t} = useTranslation('buttons');

  return (
    <div className={styles.wrapper}>
      <Link className={cx('link', styles.main)} to={urls.onboardingRecoveryPassword}>
        {t('button.passwordRecovery')}
      </Link>
    </div>
  );
};

export default RecoveryPasswordLink;
