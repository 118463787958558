//react
import {FC, PropsWithChildren, useMemo} from 'react';
// class names
import cx from 'classnames/bind';
// helper
import {getTableHeadClasses} from './helper';
//styles
import styles from './TableHead.module.scss';

interface ITableHeadProps {
  className?: string;
  callChild?: string;
}

const cxStyle = cx.bind(styles);

const TableHeader: FC<PropsWithChildren<ITableHeadProps>> = ({children, className, callChild}) => {
  const tableHeadClasses = useMemo(() => getTableHeadClasses(styles, callChild), [styles, callChild]);

  return <div className={cxStyle(className, styles.container, tableHeadClasses)}>{children}</div>;
};

export default TableHeader;
