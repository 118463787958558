// types
import {ConfiguredLink} from './types';
import {GetLocationAddressByIdResponse, IOpportunityResponse} from '@joc/api-gateway';
// date
import moment from 'moment';
import {appStoreLink, linkMapBaseUrl, playStoreLink} from './data';

export const getLinkDate = (startDate?: Date, endDate?: Date): string => {
  if (!startDate && !endDate) {
    return '';
  }

  return `${moment(startDate).format('DD MMM  YYYY')}`;
};

export const getLinkTime = (startDate?: Date, endDate?: Date): string => {
  if (!startDate && !endDate) {
    return '';
  }

  return `${moment(startDate).format('hh:mm A')} - ${moment(endDate).format('hh:mm A')}`;
};

export const getLinkAdditionalInfo = (link: string) => {
  return `New to JustOneChesed?
Download the JustOneChesed app and sign up
\u{1F916}${playStoreLink}
\u{1F34F}${appStoreLink} and then come back
to this message and click ${link}`;
};

export const getLinkMapsLocation = (isVirtual: boolean, address?: GetLocationAddressByIdResponse) => {
  if ((!address?.longitude && !address?.latitude) || isVirtual) {
    return '';
  }

  return `${linkMapBaseUrl}${address.latitude},${address.longitude}`;
};

export const getLinkLocation = (isVirtual: boolean, address?: GetLocationAddressByIdResponse) => {
  const location = !!address ? `${address.countryName} ${address.stateName} ${address.cityName}` : '';

  return isVirtual ? null : location;
};

export const getConfiguredLink = (shareLink: string, opportunity?: IOpportunityResponse) => {
  if (!opportunity) {
    return null;
  }

  const {opportunityName, address, startDate, endDate, isVirtual, opportunityUrl} = opportunity;

  return {
    linkOppoName: opportunityName,
    linkOppoDate: getLinkDate(startDate, endDate),
    linkOppoTime: getLinkTime(startDate, endDate),
    linkAdditionalInfo: getLinkAdditionalInfo(shareLink),
    linkMapLocation: getLinkMapsLocation(isVirtual, address),
    linkOppoLocation: getLinkLocation(isVirtual, address),
    linkOppoUrl: opportunityUrl,
    linkOppoIsVirtual: isVirtual,
  };
};

export const getLinkContent = (linkSetup: ConfiguredLink, shareLink: string) => {
  return linkSetup
    ? linkSetup.linkOppoLocation && linkSetup.linkMapLocation
      ? `\u{2B50} ${linkSetup.linkOppoName}
\u{1F4C6} ${linkSetup.linkOppoDate}
${linkSetup.linkOppoTime}
\u{1F517} ${shareLink}
\u{1F4CD} ${linkSetup.linkOppoLocation}
${linkSetup.linkMapLocation}

${linkSetup.linkAdditionalInfo}`
      : `\u{2B50} ${linkSetup.linkOppoName}
\u{1F4C6} ${linkSetup.linkOppoDate}
${linkSetup.linkOppoTime}
\u{1F517} ${shareLink}
${linkSetup.linkOppoIsVirtual && `\u{1F310} Virtual ${linkSetup.linkOppoUrl ? `/ ${linkSetup.linkOppoUrl}` : ''}`}

${linkSetup.linkAdditionalInfo}`
    : null;
};
