import {ChangeEvent, FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import cx from 'classnames';
//translate
import i18n from 'components/translate';
//API
import {API} from 'core/API';
//assets
import UploadIcon from 'assets/image/input-photo.svg';
//functions
import {convertFileSizeToMb, getImageTypeErrorText, validateFileType} from 'core/functions';
//constants
import {defaultPhotoTypesWithoutImage, IMAGE_SIZE_MAX} from 'core/constants';
//types
import {FieldBaseProps} from 'core/types';
//components
import Loader from 'shared/components/Loader';
import InputErrorHint from 'shared/components/InputErrorHint';
import ImageWithPopup from 'shared/components/ImageWithPopup';
import FieldWrapper from 'shared/inputs/FieldWrapper';
//styles
import styles from './UploadPhoto.module.scss';

type UploadPhotoProps = FieldBaseProps<{
  big?: boolean;
  title?: string;
  acceptableTypes?: string;
  opportunityPhoto?: boolean;
  optional?: boolean;
}>;

const UploadPhoto: FC<UploadPhotoProps> = ({
  big,
  title,
  acceptableTypes,
  opportunityPhoto,
  optional,
  ...fieldBaseProps
}: UploadPhotoProps) => {
  const {field, form, disabled} = fieldBaseProps;
  const {setFieldValue, setFieldTouched} = form;

  const [photoName, setPhotoName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [customError, setCustomError] = useState('');

  const {t} = useTranslation(['inputs', 'popup', 'signUpUser']);

  useEffect(() => {
    const photoName = localStorage.getItem('userPhoto');
    !!photoName && setFieldValue(field.name, photoName);

    return () => localStorage.removeItem('userPhoto');
  }, []);

  useEffect(() => {
    if (photoName.length) {
      localStorage.setItem('userPhoto', photoName);
      setFieldValue(field.name, photoName);
    }
  }, [photoName]);

  const validationT = i18n.getFixedT(null, 'validation');

  const deleteImage = () => setFieldValue(field.name, '');

  const changeHandler = async (event: ChangeEvent<HTMLInputElement>): Promise<void> => {
    event.preventDefault();

    if (event.target && event.target.files && event.target.files[0]) {
      setCustomError('');

      const photo = event.target.files[0];
      const fileName = event.target.files[0].name;
      const fileSize = event.target.files[0].size;

      setFieldTouched(field.name, true);

      const imageSize = convertFileSizeToMb(fileSize);

      if (imageSize >= IMAGE_SIZE_MAX) {
        const sizeError = validationT('upload.tooLarge');
        setCustomError(`${sizeError} 5 MB`);
        return;
      }

      const isValidType = validateFileType(photo);

      if (!isValidType) {
        const formatError = validationT('upload.invalidFormat');
        const error = getImageTypeErrorText(formatError, defaultPhotoTypesWithoutImage);
        setCustomError(error);
        return;
      }

      try {
        setIsLoading(true);

        const response = await API.uploadPhoto({data: photo, fileName});
        if (response?.fileName?.length) setPhotoName(response.fileName);

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        setCustomError(error?.response?.message || error.message);
      }
    }
  };

  const photoPopupTitle = opportunityPhoto ? t('popup:opportunity.pictureOfOppo') : t('popup:profilePicture.user');

  const id = 'userPhoto';

  return (
    <FieldWrapper {...fieldBaseProps}>
      <div className={cx(styles.container, big && styles.big)} title={title}>
        <div className={cx(styles.input__wrapper, big && styles.big)}>
          <input
            id={id}
            className={styles.input}
            type="file"
            accept={acceptableTypes}
            onChange={(event) => changeHandler(event)}
            disabled={disabled}
          />
          <div className={styles.upload__wrapper}>
            {isLoading ? (
              <Loader />
            ) : field.value ? (
              <ImageWithPopup
                classNames={cx(!!field.value?.length && styles.preview)}
                imagePath={field.value}
                alt="uploaded photo"
                popupTitle={photoPopupTitle}
                disableZeroTop
              />
            ) : (
              <img src={UploadIcon} alt="" />
            )}
          </div>
        </div>
        <div className={styles.upload__options}>
          <div className={styles.option__item_upload}>
            <label className={styles.option__item} htmlFor={id}>
              {t('inputs:photoInput.upload')}
            </label>
            <span>{optional ? `(${t('signUpUser:optional')})` : ''}</span>
          </div>

          {field.value && (
            <label className={styles.option__item} htmlFor="" onClick={deleteImage}>
              {t('inputs:photoInput.delete')}
            </label>
          )}
        </div>
      </div>
      {customError && <InputErrorHint errorText={customError} />}
    </FieldWrapper>
  );
};

export default UploadPhoto;
