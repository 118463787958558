import {FC, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import cx from 'classnames';
//API
import {API} from 'core/API';
//redux
import {Store} from 'redux/root';
import {selectorGetUserVolunteerId} from 'redux/user-service/selector';
//types
import {ClockInRequest, ClockOutRequest} from '@joc/api-gateway';
//assets
import ClockSvg from 'assets/image/clock-blue.svg';
//components
import SwitchCheckbox from 'shared/components/SwitchCheckbox';
//styles
import styles from './ClockAction.module.scss';
import {ClockActionHelperText} from './ClockActionHelperText/ClockActionHelperText';

type ClockActionProps = {
  isDisabled: boolean;
  isClockedIn: boolean;
  opportunityId: number;
};

const ClockAction: FC<ClockActionProps> = ({isClockedIn, opportunityId, isDisabled}: ClockActionProps) => {
  const {t} = useTranslation('myChesed');

  const [isShowHelperText, setIsShowHelperText] = useState(false);

  const volunteerId = useSelector((store: Store) => selectorGetUserVolunteerId(store));

  const [checked, setChecked] = useState<boolean>(isClockedIn);

  const clockTitle = useMemo(() => t(`${checked ? 'clockOut' : 'clockIn'}`), [checked]);

  const clockIn = async (opportunityId: number, volunteerId: string) => {
    try {
      await API.clockIn(opportunityId, ClockInRequest.fromJS({volunteerId}));
    } catch (error) {
      console.error(error);
    }
  };

  const clockOut = async (opportunityId: number, volunteerId: string) => {
    try {
      await API.clockOut(opportunityId, ClockOutRequest.fromJS({volunteerId}));
    } catch (error) {
      console.error(error);
    }
  };

  const toggleChecked = (opportunityId: number, volunteerId: string, checked: boolean) => {
    if (!checked) {
      clockIn(opportunityId, volunteerId);
    } else {
      clockOut(opportunityId, volunteerId);
    }
    setChecked((prevState) => !prevState);
  };

  const switchClickHandler = () => {
    volunteerId && toggleChecked(opportunityId, volunteerId, checked);
  };

  const handleClockInClick = () => isDisabled && setIsShowHelperText(true);

  return (
    <div className={cx(styles.clock, isDisabled && styles.clock__disabled)} onClick={handleClockInClick}>
      <div className={styles.clock__info}>
        <img src={ClockSvg} className={styles.clock__icon} alt="Clock" />
        <div className={styles.clock__text}>{clockTitle}</div>
      </div>

      <SwitchCheckbox
        isDisabled={isDisabled}
        isChecked={checked}
        clickHandler={switchClickHandler}
        parentClassName={cx(
          styles.checkbox,
          isDisabled && styles.checkbox__disabled,
          checked && styles.checkbox__checked
        )}
      />
      {isShowHelperText && <ClockActionHelperText text={t('infoMessage')} />}
    </div>
  );
};

export default ClockAction;
