import {FC, Fragment, useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
//formik
import {Formik} from 'formik';
//redux
import {Store} from 'redux/root';
import {setError} from 'redux/error-service/action';
import {setInvitedColleaguesOrg} from 'redux/organization-service/actions';
//API
import {API} from 'core/API';
//validation
import {inviteUsersValidationSchema} from 'core/validation';
//types
import {ICreateNewOrganisationRequest, IInviteUserRequest} from '@joc/api-gateway';
//constants
import {INITIAL_INVITE_COLLEAGUES_SIGNUP} from 'core/constants';
//components
import InputImportList from 'shared/components/FormInputs/InputImportList';
import InvColleaguesSignupForm from './InvColleaguesSignupForm/InvColleaguesSignupForm';

type InviteColleaguesSignupFormparentProps = {
  requestCreateOrg: (orgData: ICreateNewOrganisationRequest) => Promise<void>;
  isLoading: boolean;
};

const InviteColleaguesSignupForm: FC<InviteColleaguesSignupFormparentProps> = ({
  requestCreateOrg,
  isLoading,
}: InviteColleaguesSignupFormparentProps) => {
  const [formInitialValues, setFormInitialValues] = useState<IInviteUserRequest>(INITIAL_INVITE_COLLEAGUES_SIGNUP);
  const [invitedColleaguesList, setInvitedColleaguesList] = useState<Array<IInviteUserRequest>>();

  const orgData = useSelector((store: Store) => store.organizationRedux.createOrganizationInfo);
  const schoolId = useSelector((store: Store) => store.organizationRedux.organizationInfo?.schoolId);

  const dispatch = useDispatch();

  const {t} = useTranslation('errors');

  const submitClickHandler = useCallback(
    async (values: IInviteUserRequest) => {
      // TODO:  [JF-1111] look inviteColleagues
      // if (!values?.email) setInvitedColleaguesList(undefined);
      try {
        const roles = await API.getAllRoles();
        const positions = await API.getAllPositions();
        const invitedColleaguesListWithRolesId = invitedColleaguesList?.every((i) => typeof i.roleId === 'number');
        const colleaguesListWithPositionAndRoles = invitedColleaguesListWithRolesId
          ? invitedColleaguesList
          : invitedColleaguesList?.map((user) => {
              const userRoleId = roles.find(
                // TODO: REMOVE ANY
                (i) => i.roleName.toUpperCase() === (user as any).roleId.toUpperCase()
              )?.id;
              const userPositionId = positions.find(
                // TODO: REMOVE ANY
                (i) => i.organizationRoleName.toUpperCase() === (user as any).positionId.toUpperCase()
              )?.id;
              if (userRoleId && userPositionId)
                return {
                  ...user,
                  roleId: userRoleId,
                  positionId: userPositionId,
                };
              if (user.phoneNumber === '')
                return {
                  ...user,
                  firstName: user.firstName.trim(),
                  lastName: user.lastName.trim(),
                  phoneNumber: undefined,
                  roleId: userRoleId,
                  positionId: userPositionId,
                };
            });
        const requestColleaguesData =
          colleaguesListWithPositionAndRoles?.length && Object.values(values).every((i) => !!i)
            ? [values, ...colleaguesListWithPositionAndRoles]
            : !colleaguesListWithPositionAndRoles?.length && Object.values(values).every((i) => !!i)
            ? [values]
            : colleaguesListWithPositionAndRoles?.length && !Object.values(values).every((i) => !!i)
            ? [...colleaguesListWithPositionAndRoles]
            : null;
        if (!requestColleaguesData) throw new Error(t('youHaventAnyColleagues'));
        localStorage.setItem('invitedColleaguesForm', JSON.stringify(values));
        localStorage.setItem('invitedColleaguesList', JSON.stringify(invitedColleaguesList));
        dispatch(setInvitedColleaguesOrg(requestColleaguesData as Array<IInviteUserRequest>));
      } catch (error) {
        dispatch(setError(error?.response?.message || error.message, error?.response?.code || error.code));
      }
    },
    [invitedColleaguesList]
  );

  useEffect(() => {
    const localStorageDataForm = localStorage.getItem('invitedColleaguesForm');
    const localStorageDataList = localStorage.getItem('invitedColleaguesList');
    if (localStorageDataForm) setFormInitialValues(JSON.parse(localStorageDataForm));
    if (localStorageDataList) setInvitedColleaguesList(JSON.parse(localStorageDataList));
  }, []);

  useEffect(() => {
    if (orgData.invitedUsers?.length) requestCreateOrg(orgData);
  }, [orgData]);

  return (
    <Fragment>
      <InputImportList
        setStateValue={setInvitedColleaguesList}
        listLength={invitedColleaguesList?.length}
        invitedUserType="colleague"
        exampleFilePath={
          !!schoolId
            ? `${process.env.PUBLIC_URL}/assets/documents/inviteSchoolVolunteerTemplate.xlsx`
            : `${process.env.PUBLIC_URL}/assets/documents/inviteColleaguesTemplate.xlsx`
        }
      />
      <Formik
        enableReinitialize
        initialValues={formInitialValues}
        // TODO: [JF-1100] [fe] check possibility to check xls by yup
        validationSchema={invitedColleaguesList?.length ? null : inviteUsersValidationSchema}
        onSubmit={(values) => submitClickHandler(values)}
      >
        <InvColleaguesSignupForm isLoading={isLoading} />
      </Formik>
    </Fragment>
  );
};

export default InviteColleaguesSignupForm;
