import {FC, useState, Dispatch, SetStateAction} from 'react';
import {useDispatch} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Formik} from 'formik';
import cx from 'classnames';
import qs from 'qs';
//API
import {API} from 'core/API';
//redux
import {setError} from 'redux/error-service/action';
//types
import {ValidateUserEmailRequest} from '@joc/api-gateway';
import {ClaimJoinParsedQs} from 'core/types/queryStrings';
import {SIGNUP_ERROR} from 'core/types';
//form configuration
import {SignUpVolunteerFormValues, initialValues, validationSchema} from './formConfiguration';
//components
import SignUpMainFormV from './SignUpMainFormV';
//styles
import styles from './SignUpFormV.module.scss';

type SignUpFormVParentProps = {
  setIsShowAboutYouForm: Dispatch<SetStateAction<boolean>>;
  setSignupError: Dispatch<SetStateAction<SIGNUP_ERROR>>;
};

const SignUpFormV: FC<SignUpFormVParentProps> = ({setIsShowAboutYouForm, setSignupError}) => {
  const {t} = useTranslation('signUpUser');

  const dispatch = useDispatch();

  const location = useLocation();

  const [isLoading, setIsLoading] = useState(false);

  const parsedQs = qs.parse(location.search, {ignoreQueryPrefix: true}) as ClaimJoinParsedQs;

  const submitClickHandler = async (values: SignUpVolunteerFormValues) => {
    setIsLoading(true);
    try {
      if (values.email) await API.validateUser(ValidateUserEmailRequest.fromJS({email: values.email}));

      if (parsedQs.organisationId) {
        const newValues = {
          ...values,
          organisationId: parsedQs.organisationId,
          studentId: parsedQs.studentId,
          birthDate: parsedQs.birthDate,
        };
        localStorage.setItem('userFirebaseInfo', JSON.stringify(newValues));
      } else {
        localStorage.setItem('userFirebaseInfo', JSON.stringify(values));
      }
      setIsShowAboutYouForm(true);
    } catch (error) {
      if (error?.response?.code === 409) {
        setSignupError(SIGNUP_ERROR.EMAIL_ALREADY_EXISTS);
        setIsLoading(false);
      } else {
        dispatch(setError(error?.response?.message || error.message, error.code));
        setIsLoading(false);
      }
    }
  };

  return (
    <div className={styles.container}>
      <h1 className={cx('title', styles.title)}>{t('newUser')}</h1>

      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={submitClickHandler}
      >
        <SignUpMainFormV
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          setIsShowAboutYouForm={setIsShowAboutYouForm}
        />
      </Formik>
    </div>
  );
};

export default SignUpFormV;
