import {FC, useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {connect, ConnectedProps, useDispatch, useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router';
import {useTranslation} from 'react-i18next';
import qs from 'qs';
import cx from 'classnames';
//redux
import {Store} from 'redux/root';
import {resetError} from 'redux/error-service/action';
import {selectorGetUserRoles} from 'redux/user-service/selector';
import {selectorGetCoordinators} from 'redux/opportunities-service/selector';
import {getCoordinators} from 'redux/opportunities-service/action';
//types
import {
  IBasicVolunteerResponse,
  IFullTextSearchVolunteers,
  ISearchVolunteersSort,
  ORGANISATION_ACTIVE_STATUS,
  ORGANISATION_GENERAL_TYPES,
  SEARCH_VOLUNTEER_POSSIBLE_SEARCH,
  SEARCH_VOLUNTEER_POSSIBLE_SORT,
  VOLUNTEER,
  VOLUNTEER_ACTIVE_STATUS,
} from '@joc/api-gateway';
// interfaces
import {CreateManualOppModalData, TableTotalAmount, TableType} from './interfaces';
//functions
import {
  calculateUserAge,
  convertTimeFromSecondsToHours,
  generateGenderName,
  generateLocation,
  getAmountOppoOrganization,
  getAmountOppoSchool,
  getVolunteerTotalSpendTimeByCurrentOrg,
  setFullName,
  setSearchOrSortQueryString,
} from 'core/functions';
import {tenthListItemSelector} from 'shared/components/Buttons/ButtonUpToTop/util';
//constants
import {INITIAL_PAGINATION, TABLE_HEADERS, TABLE_ITEMS_CLASSNAMES} from 'core/constants';
import {DEFAULT_SCROLLABLE_CONTAINER} from 'shared/components/Buttons/ButtonUpToTop/constants';
//helpers
import {getHeaderSortQueryParams} from '../helpers';
import {PossibleSortBy} from 'shared/components/Table/TableHead/TableHeadCell/helpers';
//components
import Actions from './Actions';
import {useModal} from 'hooks/useModal';
import {useScrollTable} from 'hooks/useScrollTable';
import Loader from 'shared/components/Loader';
import Observer from 'shared/components/Observer';
import TableRow from 'shared/components/Table/TableRow';
import TableHead from 'shared/components/Table/TableHead';
import TableMain from 'shared/components/Table/TableMain';
import TableBody from 'shared/components/Table/TableBody';
import TableMenu from 'components/Organization/TableMenu';
import ButtonUpToTop from 'shared/components/Buttons/ButtonUpToTop';
import ResponseFailure from 'shared/components/ResponseFailure';
import AvatarCell from 'shared/components/Table/CellRenderers/AvatarCell';
import {AdminTableBodyCell} from './AdminTableBodyCell/AdminTableBodyCell';
import TableHeadCell from 'shared/components/Table/TableHead/TableHeadCell';
import CellDefault from 'shared/components/Table/CellRenderers/CellDefault';
import FullNameCell from 'shared/components/Table/CellRenderers/FullNameCell';
import {CellDataTime} from 'shared/components/Table/CellRenderers/CellDataTime';
import {VolunteerInfoCell} from 'shared/components/Table/CellRenderers/VolunteerInfoCell';
import {VolunteersPageContext, VolunteersPageContextType} from 'pages/Organization/Volunteers';
import {CreateManualOppPopup} from 'components/Organization/CreateManualOppoPopup/CreateManualOppoPopup';
import TableHeadCellWithSort from 'shared/components/Table/TableHead/TableHeadCell/TableHeadCellWithSort';
//styles
import styles from './Table.module.scss';

const VolunteersTable: FC<VolunteersTableProps> = ({resetError}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const {t} = useTranslation(['volunteers', 'errors', 'buttons', 'popup']);

  const {
    isOpen: isOpenCreateManualOppModal,
    close: closeCreateManualOppModal,
    open: openCreateManualOppModal,
    data: createManualOppModalData,
  } = useModal<CreateManualOppModalData>();

  const {headerTable, bodyTable, scrollHeader} = useScrollTable();

  const printRef = useRef<HTMLDivElement>(null);

  const [isPrintableTable, setIsPrintableTable] = useState(false);
  const [optionTable, setOptionTable] = useState(TableTotalAmount.Year);

  const volunteersAllRecords = useSelector((store: Store) => store.volunteersRedux.volunteers.records);
  const volunteersTotal = useSelector((store: Store) => store.volunteersRedux.volunteers.total);
  const error = useSelector((store: Store) => store.errorRedux.error);
  const orgId = useSelector((store: Store) => Number(store.organizationRedux.organizationInfo?.id));
  const schoolId = useSelector((store: Store) => Number(store.organizationRedux.organizationInfo?.schoolId));
  const orgStatus = useSelector((store: Store) => store.organizationRedux.organizationInfo?.organizationActiveStatus);
  const orgGeneralType = useSelector(
    (store: Store) => store.organizationRedux.organizationInfo?.organisationGeneralType
  );
  const coordinators = useSelector(selectorGetCoordinators);
  const userRoles = useSelector(selectorGetUserRoles);

  const {
    isLoading,
    sort,
    setPagination,
    changeVisibilityFilters,
    isClearSearch,
    setIsClearSearch,
    refreshVolunteers,
    setIsEditingSmartGroup,
    isEditingSmartGroup,
  }: VolunteersPageContextType = useContext(VolunteersPageContext);

  const getStudentGrade = useCallback(
    (volunteer: IBasicVolunteerResponse): string =>
      volunteer.volunteerSchoolsGrades?.find((school) => +school.schoolId === schoolId)?.gradeId || '',
    [schoolId]
  );

  const getYearTimeSpend = useCallback(
    (volunteer: IBasicVolunteerResponse): number | string => {
      const seconds = volunteer.yearSpendTime;

      return seconds ? convertTimeFromSecondsToHours(seconds) : '0h';
    },
    [orgId]
  );

  const getTimeSpend = useCallback(
    (volunteer: IBasicVolunteerResponse): string | number =>
      orgGeneralType === ORGANISATION_GENERAL_TYPES.SCHOOL
        ? convertTimeFromSecondsToHours(volunteer.spendTime)
        : getVolunteerTotalSpendTimeByCurrentOrg(orgId, volunteer?.volunteerSpendTime),
    [orgGeneralType, orgId]
  );

  const getAmountOppo = useCallback(
    (volunteer: IBasicVolunteerResponse): string | number =>
      orgGeneralType === ORGANISATION_GENERAL_TYPES.SCHOOL
        ? getAmountOppoSchool(volunteer, optionTable)
        : getAmountOppoOrganization(orgId, volunteer?.volunteerSpendTime),
    [orgGeneralType, orgId, optionTable]
  );

  const getCurrentHours = (volunteer: IBasicVolunteerResponse): string | number => {
    return optionTable === TableTotalAmount.Year ? getYearTimeSpend(volunteer) : getTimeSpend(volunteer);
  };

  const getHeaderTextCurrentHours = useMemo(() => {
    return optionTable === TableTotalAmount.Year ? TABLE_HEADERS.yearTimeSpend : TABLE_HEADERS.totalTimeSpendMonthly;
  }, [optionTable]);

  const getSortedTypeCurrentHours = useMemo(() => {
    return optionTable === TableTotalAmount.Year
      ? SEARCH_VOLUNTEER_POSSIBLE_SORT.YearSpendTime
      : SEARCH_VOLUNTEER_POSSIBLE_SORT.SpendTime;
  }, [optionTable]);

  const getSortedAmount = useMemo(() => {
    return orgGeneralType === ORGANISATION_GENERAL_TYPES.SCHOOL
      ? optionTable === TableTotalAmount.Total
        ? SEARCH_VOLUNTEER_POSSIBLE_SORT.OpportunityAmount
        : SEARCH_VOLUNTEER_POSSIBLE_SORT.YearOpportunityAmount
      : SEARCH_VOLUNTEER_POSSIBLE_SORT.OpportunityAmount;
  }, [optionTable, orgGeneralType]);

  useEffect(() => {
    if (!coordinators.length) dispatch(getCoordinators(orgId));
  }, []);

  const buttonFailureClickHandler = async () => {
    resetError();
    history.push({search: ''});
    setPagination(INITIAL_PAGINATION);
  };

  const appendQueryString = (
    newQueryParams: IFullTextSearchVolunteers | ISearchVolunteersSort,
    searchBy: string
  ): void => {
    const searchQuery = setSearchOrSortQueryString(location.search, newQueryParams, searchBy);
    history.push({search: searchQuery});
  };

  const searchVolunteersChangeHandler = (value: string): void => {
    const fullTextSearchParams: IFullTextSearchVolunteers = {
      value,
      fields: Object.values(SEARCH_VOLUNTEER_POSSIBLE_SEARCH),
    };
    if (fullTextSearchParams.value.length) {
      appendQueryString(fullTextSearchParams, 'fullTextSearch');
    } else {
      const searchParams = qs.parse(location.search, {ignoreQueryPrefix: true});
      delete searchParams.fullTextSearch;
      history.push({search: qs.stringify(searchParams)});
    }
  };

  const headerSortClickHandler = (sortBy: PossibleSortBy) => {
    const newQueryParams = getHeaderSortQueryParams(sort?.sortDir, sortBy as SEARCH_VOLUNTEER_POSSIBLE_SORT);
    appendQueryString(newQueryParams as ISearchVolunteersSort, 'sort');
  };
  const headerSortDescFirstClickHandler = (sortBy: PossibleSortBy) => {
    const newQueryParams = getHeaderSortQueryParams(sort?.sortDir, sortBy as SEARCH_VOLUNTEER_POSSIBLE_SORT, true);
    appendQueryString(newQueryParams as ISearchVolunteersSort, 'sort');
  };

  const isUserAdmin = userRoles?.some((role) => role.roleName === VOLUNTEER.ADMIN);

  const tableCallChild = orgGeneralType === ORGANISATION_GENERAL_TYPES.SCHOOL ? 'students' : 'volunteers';
  const isOrgSuspended = orgStatus?.status === ORGANISATION_ACTIVE_STATUS.SUSPENDED;
  const monthlySortBy =
    orgGeneralType === ORGANISATION_GENERAL_TYPES.SCHOOL
      ? SEARCH_VOLUNTEER_POSSIBLE_SORT.SpendTime
      : SEARCH_VOLUNTEER_POSSIBLE_SORT.OrganisationSpendTime;

  return (
    <>
      <div className={styles.container}>
        <div className={styles.table}>
          <TableMenu
            searchChangeHandler={searchVolunteersChangeHandler}
            changeVisibilityFilters={changeVisibilityFilters}
            isClearSearch={isClearSearch}
            setIsClearSearch={setIsClearSearch}
          >
            <Actions
              setIsEditingSmartGroup={setIsEditingSmartGroup}
              setIsPrintableTable={setIsPrintableTable}
              printRef={printRef}
              isLoading={isLoading}
            />
          </TableMenu>
          {!volunteersAllRecords.length && isLoading ? (
            <div className={styles.loader}>
              <Loader />
            </div>
          ) : error.state && !isLoading ? (
            <ResponseFailure
              styleTable
              message={error.message}
              buttonClickHandler={buttonFailureClickHandler}
              buttonTitle={t('buttons:button.getAll')}
            />
          ) : !isLoading && !volunteersAllRecords.length ? (
            <ResponseFailure styleTable message={t('errors:couldntFindVolunteers')} buttonUnshow />
          ) : (
            <TableMain ref={printRef} className={styles.main}>
              <div
                ref={headerTable}
                className={styles.main__header}
                onScroll={(event) => scrollHeader(event, TableType.Header)}
              >
                <TableHead callChild={tableCallChild}>
                  <TableHeadCell itemClassName={TABLE_ITEMS_CLASSNAMES.avatar} text={TABLE_HEADERS.avatar} />
                  <TableHeadCellWithSort
                    itemClassName={TABLE_ITEMS_CLASSNAMES.fullName}
                    text={TABLE_HEADERS.fullName}
                    clickSortHandler={headerSortClickHandler}
                    sortProps={{sort, ownSortBy: SEARCH_VOLUNTEER_POSSIBLE_SORT.FirstLastName}}
                  />
                  <TableHeadCellWithSort
                    itemClassName={TABLE_ITEMS_CLASSNAMES.email}
                    text={TABLE_HEADERS.email}
                    clickSortHandler={headerSortClickHandler}
                    sortProps={{sort, ownSortBy: SEARCH_VOLUNTEER_POSSIBLE_SORT.Email}}
                  />
                  <TableHeadCellWithSort
                    itemClassName={TABLE_ITEMS_CLASSNAMES.age}
                    text={TABLE_HEADERS.age}
                    clickSortHandler={headerSortClickHandler}
                    sortProps={{sort, ownSortBy: SEARCH_VOLUNTEER_POSSIBLE_SORT.BirthDate}}
                  />
                  <TableHeadCellWithSort
                    itemClassName={TABLE_ITEMS_CLASSNAMES.gender}
                    text={TABLE_HEADERS.gender}
                    clickSortHandler={headerSortClickHandler}
                    sortProps={{sort, ownSortBy: SEARCH_VOLUNTEER_POSSIBLE_SORT.Gender}}
                  />
                  <TableHeadCell itemClassName={TABLE_ITEMS_CLASSNAMES.location} text={TABLE_HEADERS.location} />
                  {orgGeneralType === ORGANISATION_GENERAL_TYPES.SCHOOL && (
                    <TableHeadCellWithSort
                      itemClassName={TABLE_ITEMS_CLASSNAMES.grade}
                      text={TABLE_HEADERS.grade}
                      sortProps={{sort, ownSortBy: SEARCH_VOLUNTEER_POSSIBLE_SORT.SchoolGrade}}
                      clickSortHandler={headerSortClickHandler}
                    />
                  )}
                  {orgGeneralType === ORGANISATION_GENERAL_TYPES.SCHOOL ? (
                    <TableHeadCellWithSort
                      itemClassName={TABLE_ITEMS_CLASSNAMES.yearTimeSpend}
                      text={getHeaderTextCurrentHours}
                      sortProps={{sort, ownSortBy: getSortedTypeCurrentHours}}
                      optionValue={optionTable}
                      clickOptionHandler={setOptionTable}
                      clickSortHandler={headerSortClickHandler}
                    />
                  ) : (
                    <TableHeadCellWithSort
                      itemClassName={TABLE_ITEMS_CLASSNAMES.total}
                      text={TABLE_HEADERS.totalTimeSpendMonthly}
                      clickSortHandler={headerSortDescFirstClickHandler}
                      sortProps={{
                        sort,
                        ownSortBy: monthlySortBy,
                      }}
                    />
                  )}
                  <TableHeadCellWithSort
                    itemClassName={TABLE_ITEMS_CLASSNAMES.amountOppo}
                    text={TABLE_HEADERS.amountOppo}
                    sortProps={{sort, ownSortBy: getSortedAmount}}
                    clickSortHandler={headerSortClickHandler}
                  />
                  <TableHeadCellWithSort
                    itemClassName={TABLE_ITEMS_CLASSNAMES.lastActivity}
                    text={TABLE_HEADERS.lastActivity}
                    clickSortHandler={headerSortDescFirstClickHandler}
                    sortProps={{
                      sort,
                      ownSortBy: SEARCH_VOLUNTEER_POSSIBLE_SORT.LastSignInAt,
                    }}
                  />
                  {!isPrintableTable && <TableHeadCell itemClassName={TABLE_ITEMS_CLASSNAMES.past_opportunities} />}
                  {!isPrintableTable && <TableHeadCell itemClassName={TABLE_ITEMS_CLASSNAMES.options} />}
                  {isUserAdmin ? <TableHeadCell /> : null}
                </TableHead>
              </div>
              <TableBody ref={bodyTable} onScroll={scrollHeader} className={styles.main__body}>
                {volunteersAllRecords.map((volunteer: IBasicVolunteerResponse, index: number) => (
                  <TableRow
                    key={volunteer.id}
                    callChild={tableCallChild}
                    className={cx(styles.table__row, tenthListItemSelector(index))}
                    suspend={volunteer.volunteerActiveStatus?.status === VOLUNTEER_ACTIVE_STATUS.SUSPENDED}
                  >
                    <AvatarCell
                      imagePath={volunteer?.imagePath || ''}
                      avatarTitle={t(`popup:profilePicture.${schoolId ? 'student' : 'volunteer'}`)}
                    />
                    <FullNameCell
                      firstName={volunteer.firstName}
                      lastName={volunteer.lastName}
                      createDate={volunteer.createDate}
                      parentClassName={styles.fullname}
                    />
                    <CellDefault parentClassName={TABLE_ITEMS_CLASSNAMES.email} text={volunteer.email || ''} isCopy />
                    <CellDefault
                      parentClassName={TABLE_ITEMS_CLASSNAMES.age}
                      text={calculateUserAge(volunteer.birthDate) || t('volunteers:noData')}
                    />
                    <CellDefault
                      parentClassName={TABLE_ITEMS_CLASSNAMES.gender}
                      text={generateGenderName(volunteer.gender?.genderName)}
                    />
                    <CellDefault
                      parentClassName={TABLE_ITEMS_CLASSNAMES.location}
                      text={generateLocation(volunteer.address)}
                    />
                    {orgGeneralType === ORGANISATION_GENERAL_TYPES.SCHOOL && (
                      <CellDefault parentClassName={TABLE_ITEMS_CLASSNAMES.grade} text={getStudentGrade(volunteer)} />
                    )}
                    {orgGeneralType === ORGANISATION_GENERAL_TYPES.SCHOOL ? (
                      <CellDefault
                        parentClassName={TABLE_ITEMS_CLASSNAMES.yearTimeSpend}
                        text={getCurrentHours(volunteer)}
                      />
                    ) : (
                      <CellDefault parentClassName={TABLE_ITEMS_CLASSNAMES.total} text={getTimeSpend(volunteer)} />
                    )}
                    <CellDefault parentClassName={TABLE_ITEMS_CLASSNAMES.amountOppo} text={getAmountOppo(volunteer)} />
                    <CellDataTime date={volunteer.lastSignInAt} />
                    {!isPrintableTable && (
                      <VolunteerInfoCell
                        userPhoneNumber={volunteer?.phoneNumber}
                        volunteerId={+volunteer.id}
                        volunteerFullName={setFullName(volunteer?.firstName, volunteer?.lastName)}
                        volunteerChatId={volunteer.chatId || t('volunteers:createNewSmartFilter.noVolID')}
                        isSuspended={
                          isOrgSuspended ||
                          volunteer?.volunteerActiveStatus?.status === VOLUNTEER_ACTIVE_STATUS.SUSPENDED
                        }
                      />
                    )}
                    {isUserAdmin && (
                      <AdminTableBodyCell
                        volunteer={volunteer}
                        refreshVolunteers={refreshVolunteers}
                        openCreateManualOppModal={openCreateManualOppModal}
                      />
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </TableMain>
          )}
          {volunteersAllRecords.length !== volunteersTotal && !error.state && (
            <Observer
              paginationSkip={volunteersAllRecords.length}
              setPagination={setPagination}
              isLoading={isLoading}
            />
          )}
        </div>
        <ButtonUpToTop containerSelector={DEFAULT_SCROLLABLE_CONTAINER} />
      </div>
      {isOpenCreateManualOppModal && (
        <CreateManualOppPopup data={createManualOppModalData} setIsOpenPopup={closeCreateManualOppModal} />
      )}
    </>
  );
};

const mapDispatchToProps = {
  resetError,
};

const connector = connect(null, mapDispatchToProps);

type VolunteersTableProps = ConnectedProps<typeof connector>;

export default connector(VolunteersTable);
