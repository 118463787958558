export const INITIAL_INVITE = {
  firstName: '',
  lastName: '',
  email: '',
  positionId: 3,
  roleId: 0,
  phoneNumber: undefined,
  timeSpend: 0,
  gradeId: undefined,
  studentId: undefined,
};

export const INITIAL_INVITE_ADDING = {
  firstName: '',
  lastName: '',
  email: '',
  emailIsExists: false,
  positionId: 3,
  roleId: 0,
  phoneNumber: undefined,
  timeSpend: 0,
  gradeId: undefined,
  studentId: undefined,
  password: '',
};
