import {FC, MouseEvent} from 'react';
import {useTranslation} from 'react-i18next';
//styles
import styles from './Renderers.module.scss';

type TermsPolicyElementProps = {
  termsClickHandler: (e: MouseEvent) => void;
  cookieUseClickHandler: (e: MouseEvent) => void;
  privacyPolicyClickHandler: (e: MouseEvent) => void;
};

export const TermsPolicyElement: FC<TermsPolicyElementProps> = ({
  termsClickHandler,
  cookieUseClickHandler,
  privacyPolicyClickHandler,
}) => {
  const {t} = useTranslation('signUpUser');

  const termsChangeHandler = (event: MouseEvent) => termsClickHandler(event);
  const cookieChangeHandler = (event: MouseEvent) => cookieUseClickHandler(event);
  const privacyChangeHandler = (event: MouseEvent) => privacyPolicyClickHandler(event);

  return (
    <span>
      {t('agree')}
      <span className={styles.link} onClick={termsChangeHandler}>
        {t('terms')}
      </span>
      &
      <span className={styles.link} onClick={cookieChangeHandler}>
        {t('cookie')}
      </span>
      &
      <span className={styles.link} onClick={privacyChangeHandler}>
        {t('policy')}
      </span>
    </span>
  );
};
