// react
import {FC} from 'react';
import {useDispatch} from 'react-redux';
// mui
import Dialog from '@material-ui/core/Dialog';
// interfaces
import {IModalsData} from 'components/HashtagModals/interface';
// actions
import {deleteHashtag} from 'redux/hashtag-service/actions';
// components
import {CloseButton} from 'shared/components/CloseButton';
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
// styles
import styles from './index.module.scss';
import {ThunkAction} from 'redux-thunk';
import {Store} from 'redux/root';
import {AnyAction} from 'redux';

interface IDeleteModalProps {
  title: string;
  isOpen: boolean;
  subtitle: string;
  orgId: string | null;
  modalsData: IModalsData;
  handleClose: () => void;
  buttonTextCancel: string;
  buttonTextConfirm: string;
  startLoading: <T>(action: ThunkAction<T, Store, void, AnyAction>) => Promise<T>;
}

export const DeleteModal: FC<IDeleteModalProps> = ({
  orgId,
  title,
  isOpen,
  subtitle,
  modalsData,
  handleClose,
  startLoading,
  buttonTextCancel,
  buttonTextConfirm,
}) => {
  const {
    delete: {deleteHashtagModalData},
  } = modalsData;

  const handleDelete = async () => {
    if (orgId && deleteHashtagModalData?.id) {
      const {id: hashtagId} = deleteHashtagModalData;

      startLoading(deleteHashtag(orgId, hashtagId));
      handleClose();
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      className={styles.delete_hashtag__modal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className={styles.content}>
        <div className={styles.content__wrapper}>
          <CloseButton onClick={handleClose} classNames={styles.content__close__button} />
          <h4 className={styles.content__title}>{title}</h4>
          <p className={styles.content__subtitle}>{subtitle}</p>
          <div className={styles.content__button__wrapper}>
            <ButtonDefault classList={['primary', 'sm']} title={buttonTextConfirm} clickHandler={handleDelete} />
            <ButtonDefault classList={['secondary', 'sm']} title={buttonTextCancel} clickHandler={handleClose} />
          </div>
        </div>
      </div>
    </Dialog>
  );
};
