//redux
import {
  APPROVE_SIGNUP_REQUEST,
  DECLINE_SIGNUP_REQUEST,
  GET_SIGNUP_REQUESTS_ALL,
  GET_SIGNUP_REQUESTS_TOTAL,
  RESET_SIGNUP_REQUESTS,
} from 'redux/actions-types';
//types
import {ISignupRequestsReducer, SignupRequestsActionTypes, SignupRequestsPayload} from './types';

const initState: ISignupRequestsReducer = {
  signupRequests: {records: [], total: 0},
  totalSignupRequests: 0,
};

const signupRequestsReducer = (state = initState, action: SignupRequestsActionTypes): ISignupRequestsReducer => {
  const {type, payload}: SignupRequestsActionTypes = action;

  switch (type) {
    case GET_SIGNUP_REQUESTS_ALL: {
      return {
        ...state,
        signupRequests: {
          records: [...state.signupRequests.records, ...(payload as SignupRequestsPayload).records],
          total: (payload as SignupRequestsPayload).total,
        },
      };
    }
    case GET_SIGNUP_REQUESTS_TOTAL: {
      return {
        ...state,
        totalSignupRequests: payload,
      };
    }
    case APPROVE_SIGNUP_REQUEST:
    case DECLINE_SIGNUP_REQUEST: {
      return {
        ...state,
        signupRequests: {
          records: state.signupRequests.records.filter((signupRequest) => signupRequest.id !== payload),
          total: state.signupRequests.total - 1,
        },
        totalSignupRequests: state.totalSignupRequests - 1,
      };
    }
    case RESET_SIGNUP_REQUESTS: {
      return {...initState, totalSignupRequests: state.totalSignupRequests};
    }

    default:
      return state;
  }
};
export default signupRequestsReducer;
