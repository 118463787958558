// types
import {IOpportunityResponse} from '@joc/api-gateway';
import {convertTimeToHoursAndMinutes, getDurationDetailsWithDate} from 'core/functions';

export const getAdminOppo = (opportunity: IOpportunityResponse) => {
  const {volunteers, user} = opportunity;

  return !!(volunteers?.length === 1 && volunteers[0].userId !== user?.id);
};

export const getTimeOppo = (opportunity: IOpportunityResponse, isManualOpportunity: boolean) => {
  const {duration, startDate, endDate} = opportunity;
  const isVisibleEndDate = Math.floor(duration / 3600) >= 24;

  return isVisibleEndDate && isManualOpportunity
    ? getDurationDetailsWithDate(duration, endDate, startDate, 'medium')
    : `${convertTimeToHoursAndMinutes(opportunity.startDate)} - ${convertTimeToHoursAndMinutes(opportunity.endDate)}`;
};
