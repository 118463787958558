//types
import {
  ICreateOpportunityRequest,
  IGetLocationIdByAddressRequest,
  IUpdateManualOpportunityRequest,
  OPPORTUNITY_TYPES,
  RECURRENCE_POSSIBLE_UPDATE,
  RepeatableOpportunityRequest,
  WITH_WHO,
} from '@joc/api-gateway';

export enum JOIN_STATE {
  NONE,
  FAIL,
  SUCCESS,
}

export type OpportunityInitialValuesType = Omit<
  ICreateOpportunityRequest,
  | 'isApprovalRequired'
  | 'startDate'
  | 'endDate'
  | 'user'
  | 'address'
  | 'opportunityType'
  | 'startTime'
  | 'endTime'
  | 'smartGroupId'
> & {
  isApprovalRequired: boolean | null;
  startDate: Date | null;
  endDate: Date | null | number;
  user: number | null;
  address?: IGetLocationIdByAddressRequest | null;
  opportunityType: OPPORTUNITY_TYPES | null;
  startTime: string;
  endTime: string;
  manualTimeTracking?: boolean;
  smartGroupId?: number;
  repeatableOpportunity?: RepeatableOpportunityRequest;
  recurrencePossibleUpdate?: RECURRENCE_POSSIBLE_UPDATE;
  volunteersMaxCount?: number;
  tagId?: number | null;
};

export type ManualOpportunityInitialValuesType = Omit<
  ICreateOpportunityRequest,
  'isApprovalRequired' | 'startDate' | 'endDate' | 'user' | 'address' | 'opportunityType' | 'startTime' | 'endTime'
> & {
  isApprovalRequired: boolean | null;
  startDate: Date | null;
  endDate: Date | null | number | string;
  user: number | null;
  address?: IGetLocationIdByAddressRequest | null;
  opportunityType: OPPORTUNITY_TYPES | null;
  endTime: number | string;
  filePathes: any;
  recipientName?: string;
  recipientEmail?: string;
  recipientPhoneNumber?: string;
  tagId?: number | null;
};

export type IUpdateOpportunityInitialValues = {
  opportunityName?: string;
  opportunityDescription?: string;
  isApprovalRequired: boolean | null;
  manualTimeTracking?: boolean;
  startDate?: Date | null;
  userMinAge?: number;
  userMaxAge?: number;
  opportunityUrl?: string;
  isVirtual?: boolean;
  user?: number | null;
  imagePath?: string;
  withWho: WITH_WHO[];
  opportunityType?: OPPORTUNITY_TYPES | null;
  address?: IGetLocationIdByAddressRequest | null;
  gender?: number;
  opportunityPoints?: number;
  volunteerRank?: number;
  startTime?: string;
  endTime?: string;
  smartGroupId?: string;
  tagId: number | null;
  repeatableOpportunity?: RepeatableOpportunityRequest;
  recurrencePossibleUpdate: RECURRENCE_POSSIBLE_UPDATE;
  volunteersMaxCount?: number;
};

export type IUpdateManualOpportunityInitialValuesType = Omit<
  IUpdateManualOpportunityRequest,
  | 'isApprovalRequired'
  | 'startDate'
  | 'endDate'
  | 'user'
  | 'address'
  | 'opportunityType'
  | 'startTime'
  | 'endTime'
  | 'organisationId'
> & {
  isApprovalRequired: boolean | null;
  startDate: Date | null;
  endDate: Date | null | number | string;
  user: number | null;
  address?: IGetLocationIdByAddressRequest | null;
  opportunityType: OPPORTUNITY_TYPES | null;
  organization: {organizationName: string; organizationId: string | undefined};
  // startTime: string;
  endTime: number | string;
  filePathes: Array<string>;
  // organisationId: string;
  recipientName?: string;
  recipientEmail?: string;
  recipientPhoneNumber?: string;
  organisationId: string | number | undefined;
};

export interface IVerifyManualOpportunityInitialValues {
  recipientSignatureImage: string;
}
