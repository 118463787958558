export const TABLE_ITEMS_CLASSNAMES = {
  organization: 'organization',
  hashtag: 'hashtag',
  avatar: 'avatar',
  logo: 'logo',
  fullName: 'fullName',
  age: 'age',
  email: 'email',
  phone: 'phone',
  gender: 'gender',
  location: 'location',
  forms: 'forms',
  monthly: 'monthly',
  total: 'total',
  date: 'date',
  time: 'time',
  options: 'options',
  chesedName: 'chesedName',
  coordinator: 'coordinator',
  volunteers: 'volunteers',
  volunteers_actions: 'volunteers_actions',
  checkbox: 'checkbox',
  position: 'position',
  role: 'role',
  grade: 'schoolGrade',
  past_opportunities: 'past_opportunities',
  duration: 'duration',
  yearTimeSpend: 'yearTimeSpend',
  amountOppo: 'amountOppo',
  completedHours: 'completedHours',
  volunteerStatus: 'volunteerStatus',
  studentStatus: 'studentStatus',
  lastActivity: 'lastActivity',
};
