// react
import {FC} from 'react';
// translation
import {useTranslation} from 'react-i18next';
//styles
import styles from './index.module.scss';
import {TextDirectionType} from '../types';

interface IFeedDetailsAdditionalInfoProps {
  textInfo: string;
  textDirection: TextDirectionType;
}

export const FeedDetailsAdditionalInfo: FC<IFeedDetailsAdditionalInfoProps> = ({textInfo, textDirection}) => {
  const {t, i18n} = useTranslation(['form']);

  const mainContentSubtitle = `${t('form:opportunity.additionalInfo')}:`;

  return (
    <div className={styles.additionalInfo}>
      <span className={styles.mainContent__subtitle}>{mainContentSubtitle}</span>
      <p className={styles.additionalInfo__text} dir={textDirection}>
        {textInfo}
      </p>
    </div>
  );
};
