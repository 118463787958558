import {FC, ReactNode} from 'react';
import cx from 'classnames';
//styles
import styles from './OpportunityContainer.module.scss';

type OpportunityContainerProps = {
  children: ReactNode;
  parentClassName?: string;
};

const OpportunityContainer: FC<OpportunityContainerProps> = ({
  children,
  parentClassName,
}: OpportunityContainerProps) => {
  return (
    <div className={cx(parentClassName && parentClassName)}>
      <div className={styles.container}>{children}</div>
    </div>
  );
};

export default OpportunityContainer;
