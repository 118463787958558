import {FC, Fragment, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Formik} from 'formik';
//API
import {API} from 'core/API';
//redux
import {selectorGetUserData} from 'redux/user-service/selector';
import {setError} from 'redux/error-service/action';
//types
import {GetInTouchRequest} from '@joc/api-gateway/lib/api-client';
//form configuration
import {GetInTouchFormValues, getInitialValues, validationSchema} from './formConfiguration';
//components
import Appeal from 'shared/components/Appeal/DefaultRenderer';
import ArrowBackLink from 'shared/components/ArrowBackLink';
import CongratsPopup from 'components/Organization/CongratsPopup';
import GetInTouchForm from './GetInTouchForm';
//styles
import styles from './GetInTouch.module.scss';

const GetInTouch: FC = () => {
  const {t} = useTranslation(['getInTouch', 'messages']);

  const dispatch = useDispatch();

  const userData = useSelector(selectorGetUserData);

  const [isLoading, setIsLoading] = useState(false);
  const [isShown, setIsShown] = useState(false);

  const initialValues = useMemo(() => getInitialValues(userData), [userData]);

  const submitClickHandler = async (values: GetInTouchFormValues) => {
    try {
      setIsLoading(true);
      await API.sendInTouchEmail(undefined, GetInTouchRequest.fromJS(values));
      setIsShown(true);
    } catch (error) {
      dispatch(setError(error?.response?.message || error.message, error?.response?.code || error.code));
    } finally {
      setIsLoading(false);
    }
  };

  if (isShown)
    return (
      <CongratsPopup
        doneButtonClickHandler={() => setIsShown(false)}
        subtitle={t('messages:messageWasSent')}
        absoluteDisable
      />
    );

  return (
    <Fragment>
      <ArrowBackLink parentClassName={styles.nav} />
      <Appeal styleContact>
        <div className={styles.title}>{t('getInTouch:title')}</div>
        <div className={styles.description}>{t('getInTouch:fillOutForm')}</div>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={submitClickHandler}
        >
          <GetInTouchForm isLoading={isLoading} />
        </Formik>
      </Appeal>
    </Fragment>
  );
};

export default GetInTouch;
