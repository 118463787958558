import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {useFormikContext} from 'formik';
//form configuration
import {RepeatOpportunityModalFormValues} from '../formConfiguration';
//constants
import {WEEK} from './RepeatsEvery/helpers';
//components
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
import RepeatsEvery from './RepeatsEvery';
import RepeatsOn from './RepeatsOn';
import RepeatsEnds from './RepeatEnds';
//styles
import styles from './RepeatOpportunityForm.module.scss';

const RepeatOpportunityForm: FC = () => {
  const {t} = useTranslation('form');

  const {
    submitForm,
    values: {frequency},
    isValid,
  } = useFormikContext<RepeatOpportunityModalFormValues>();

  return (
    <div className={styles.form}>
      <RepeatsEvery />
      {frequency === WEEK && <RepeatsOn />}
      <RepeatsEnds />
      <ButtonDefault
        clickHandler={submitForm}
        submitType
        title={t('opportunity.save')}
        classList={['primary', 'sm']}
        disabled={!isValid}
        parentClassName={styles.submit}
      />
    </div>
  );
};

export default RepeatOpportunityForm;
