import {FC, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import cx from 'classnames';
//data
import {currentDate, fieldFromDate} from '../data';
//redux
import {useSelector} from 'react-redux';
import {selectorGetSchoolId} from 'redux/organization-service/selector';
//interfaces
import {IDateRangeSelector} from '../interfaces';
//components
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
import {DateTimeFields} from 'components/Organization/Opportunities/OpportunitiesFilters/OpportunitiesFiltersForm/DateTimeFields';
//formik
import {Form, useFormikContext} from 'formik';
//styles
import styles from './index.module.scss';

type ExportPopupDateRangeFormProps = {
  isShowDateRangeSelects: boolean;
};

export const ExportPopupDateRangeForm: FC<ExportPopupDateRangeFormProps> = ({isShowDateRangeSelects}) => {
  const {t} = useTranslation(['sections']);

  const schoolId = useSelector(selectorGetSchoolId);

  const {values, setFieldValue} = useFormikContext<IDateRangeSelector>();

  const setCurrentDate = (values: IDateRangeSelector) => {
    const {fromDate: startDate, toDate: endDate} = values;
    if (endDate && !startDate) {
      setFieldValue(fieldFromDate, currentDate);
    }
  };

  useEffect(() => {
    setCurrentDate(values);
  }, [values]);

  return (
    <Form className={cx(styles.form, !schoolId && styles.form__school)}>
      {isShowDateRangeSelects && <DateTimeFields isReadOnlyInput withoutTime showDateInThePast showDateInTheFuture />}

      <div className={styles.form__button}>
        <ButtonDefault submitType title={t('sections:table.exportList.export')} classList={['primary', 'sm']} />
      </div>
    </Form>
  );
};
