import i18n from 'components/translate';
//form configuration
import {SmartGroupControl, SmartGroupControlName, SmartGroupFormValues} from '../formConfiguration';
//types
import {SelectOption} from 'shared/inputs/Select/types';
import {Entry} from 'core/types';

export const initialFilterTypes: Array<SelectOption<SmartGroupControlName>> = [
  {title: i18n.t('tableHeaders:gender'), value: 'gender'},
  {title: i18n.t('tableHeaders:grade'), value: 'schoolGrades'},
  {title: i18n.t('tableHeaders:age'), value: 'ages'},
  {title: i18n.t('tableHeaders:total'), value: 'spendTime'},
  {title: i18n.t('tableHeaders:location'), value: 'locations'},
  {title: i18n.t('tableHeaders:createOwn'), value: 'volunteers'},
];

export const getFilterTypes = (schoolId?: number) => {
  if (schoolId) return initialFilterTypes;
  return initialFilterTypes.filter((filterType) => filterType.value !== 'schoolGrades');
};

export const getIsAddFilterDisabled = (
  filters: Array<SmartGroupControl>,
  values: SmartGroupFormValues,
  isValid: boolean
) => filters.some((filter) => !filter.name || !values[filter.name]) || !isValid || !!values.volunteers;

export const getIsSubmitDisabled = (
  filters: Array<SmartGroupControl>,
  isValid: boolean,
  values: SmartGroupFormValues
) => !isValid || !filters.length || filters.some((filter) => !filter.name || !values[filter.name]);

export const getAvailableFilterTypes = (
  filterTypes: Array<SelectOption<SmartGroupControlName>>,
  filters: Array<SmartGroupControl>,
  filterName?: SmartGroupControlName
) =>
  filterTypes.filter(
    (filterType) => !filters.map((filter) => filter.name).includes(filterType.value) || filterName === filterType.value
  );

export const getFiltersFromValues = (values: SmartGroupFormValues): Array<SmartGroupControl> =>
  Object.entries(values)
    .filter((entry) => {
      const [key, value] = entry as Entry<SmartGroupFormValues>;
      return key !== 'groupName' && value !== null;
    })
    .map((entry) => {
      const [key] = entry as Entry<Omit<SmartGroupFormValues, 'groupName'>>;
      return {
        id: crypto.randomUUID(),
        name: key,
      };
    });
