import {FC} from 'react';
import {Link, useLocation} from 'react-router-dom';
import cx from 'classnames';
//images
import LogoJpg from 'assets/image/logo-new.svg';
//urls
import {urls} from 'core/appUrls';
//styles
import styles from './Logo.module.scss';

type LogoProps = {
  isOnboarding?: boolean;
};

const Logo: FC<LogoProps> = ({isOnboarding}: LogoProps) => {
  const {pathname} = useLocation();

  return (
    <Link
      className={cx(styles.logo, styles.logo__container, {
        [styles.logo_disable]: pathname === urls.onboardingConnectionError,
      })}
      to={isOnboarding ? urls.newMainFeed : urls.main}
    >
      <img width={70} height={60} src={LogoJpg} alt="logo" />
    </Link>
  );
};

export default Logo;
