import {FC} from 'react';
import {useTranslation} from 'react-i18next';
//formik
import {Form, Field, useFormikContext} from 'formik';
//form configuration
import {OrgInfoFormValues} from '../formConfiguration';
//components
import Input from 'shared/inputs/Input';
import TextArea from 'shared/inputs/TextArea';
import Loader from 'shared/components/Loader';
import InputLogo from 'components/Organization/InputLogo';
import InputPhone from 'shared/components/FormInputs/InputPhone';
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
import GoogleApiAutoCompleat from 'shared/components/GoogleApiAutoComplete';
//styles
import styles from './OrganizationForm.module.scss';

type OrganizationFormProps = {
  isLoading: boolean;
  isOrgSuspended?: boolean;
};

const OrganizationForm: FC<OrganizationFormProps> = ({isOrgSuspended, isLoading}) => {
  const {dirty} = useFormikContext<OrgInfoFormValues>();

  const {t} = useTranslation(['form', 'messages', 'inputs', 'buttons']);

  return (
    <Form className={styles.form} title={isOrgSuspended ? t('messages:organizationSuspended') : ''}>
      <Field
        name="organizationName"
        label={t('form:organization.name')}
        placeholder={t('inputs:placeholders.fillIn')}
        disabled={isOrgSuspended}
        isLabelBold
        component={Input}
      />
      <Field
        name="organizationLogoPath"
        label={t('form:organization.addLogo')}
        disabled={isOrgSuspended}
        uploadLogoStyle
        component={InputLogo}
      />
      <Field
        name="organizationDescription"
        label={t('form:organization.about')}
        placeholder={t('inputs:placeholders.fillIn')}
        disabled={isOrgSuspended}
        textAreaStyle
        isLabelBold
        component={TextArea}
      />
      <Field
        name="organizationPhoneNumber"
        label={t('form:phoneNumber')}
        disabled={isOrgSuspended}
        isLabelBold
        component={InputPhone}
      />
      <Field
        name="organizationEmail"
        label={t('form:emailAddress')}
        placeholder={t('inputs:placeholders.fillIn')}
        disabled={isOrgSuspended}
        isLabelBold
        component={Input}
      />
      <Field
        name="organizationWebSite"
        label={t('form:websiteUrl')}
        placeholder={t('inputs:placeholders.fillIn')}
        disabled={isOrgSuspended}
        isLabelBold
        component={Input}
      />
      <Field
        name="address"
        label={t('form:location')}
        disabled={isOrgSuspended}
        isAddressErrorText
        isLabelBold
        isInputValidation
        component={GoogleApiAutoCompleat}
      />
      {isLoading ? (
        <Loader loadProps={{disableCenterStyle: true}} />
      ) : (
        <ButtonDefault
          submitType
          classList={['primary', 'lg']}
          title={t('buttons:button.confirm')}
          disabled={!dirty || isOrgSuspended}
        />
      )}
    </Form>
  );
};

export default OrganizationForm;
