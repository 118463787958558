import {useRef, FC, useState, Fragment, ChangeEvent} from 'react';
import {useTranslation} from 'react-i18next';
import cx from 'classnames';
import Dropzone from 'react-dropzone';
//components
import FileStatusBar from 'shared/components/Dropzone/FileStatusBar';
import VideoPreviewSquare from 'shared/components/VideoPreviewSquare';
import InputErrorHint from 'shared/components/InputErrorHint';
//styles
import styles from './DropzoneVideo.module.scss';

type DropzoneVideoProps = {
  setFieldValue: (field: string, value: File | null, shouldValidate?: boolean | undefined) => void;
  maxSize: number;
  fieldName: string;
  fieldValue: File | null;
};
const kiloBite = 1024;

const DropzoneVideo: FC<DropzoneVideoProps> = ({maxSize, setFieldValue, fieldName, fieldValue}: DropzoneVideoProps) => {
  const [customError, setCustomError] = useState('');

  const fileInputRef = useRef<HTMLInputElement>(null);

  const {t} = useTranslation(['form', 'marketing']);

  const setFileSizeHint = (size: number) => {
    if (size === 0) {
      return `0 ${t('marketing:bites')}`;
    }
    const sizes = [
      `${t('marketing:bites')}`,
      `${t('marketing:kb')}`,
      `${t('marketing:mb')}`,
      `${t('marketing:gb')}`,
      `${t('marketing:tb')}`,
    ];
    const i = Math.floor(Math.log(size) / Math.log(kiloBite));
    return `${parseFloat((size / kiloBite ** i).toFixed(2))} ${sizes[i]}`;
  };

  const changeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setCustomError('');
    const file = e.target.files && e.target.files[0];
    if (file) {
      if (file.size > maxSize * kiloBite * kiloBite) {
        setCustomError(`${t('form:fileSize')} 5 MB`);
        return;
      }
      setFieldValue(fieldName, file);
    }
  };

  const dropHandler = (acceptedFiles: Array<File>) => {
    setCustomError('');
    if (acceptedFiles?.length) {
      const file = acceptedFiles[0];
      if (file) {
        if (file.size > maxSize * kiloBite * kiloBite) {
          setCustomError(`${t('form:fileSize')} 5 MB`);
          return;
        }
        setFieldValue(fieldName, file);
      }
    }
  };

  const removeFile = () => {
    setFieldValue(fieldName, null);
  };

  return (
    <div className={styles.container}>
      <div className={styles.drop_info}>
        {t('form:fileInput.dragAndDropYourFileOr')}
        <label htmlFor="video" className={styles.label}>
          {t('form:fileInput.browseFile')}
        </label>
      </div>

      <Dropzone accept="video/mp4,video/x-m4v" multiple={false} onDrop={(acceptedFiles) => dropHandler(acceptedFiles)}>
        {({getRootProps, isDragReject, fileRejections}) => (
          <Fragment>
            {fieldValue ? (
              <div className={cx(styles.drop__zone, styles.drop__zone_preview)} {...getRootProps()}>
                <input
                  id="video"
                  ref={fileInputRef}
                  className={styles.file_input}
                  type="file"
                  accept="video/mp4,video/x-m4v"
                  onChange={changeHandler}
                />

                <VideoPreviewSquare link={window.URL.createObjectURL(fieldValue)} />
              </div>
            ) : (
              <label htmlFor="video">
                <div className={styles.drop__zone} {...getRootProps()}>
                  <div className={styles.icon_upload} />
                  <div className={styles.file_properties}>
                    {t('form:fileInput.maxFileSize')}{' '}
                    {maxSize ? `${setFileSizeHint(maxSize * kiloBite * kiloBite)}` : `5 ${t('marketing:mb')}`}
                  </div>
                  <input
                    id="video"
                    ref={fileInputRef}
                    className={styles.file_input}
                    type="file"
                    accept="video/mp4,video/x-m4v"
                    onChange={changeHandler}
                  />
                </div>
              </label>
            )}
            {isDragReject ? (
              <Fragment>
                {fileRejections.map(
                  (i, index) =>
                    index === 0 && <InputErrorHint key={i.errors[0].message} errorText={i.errors[0].message} />
                )}
              </Fragment>
            ) : (
              customError && <InputErrorHint errorText={customError} />
            )}
          </Fragment>
        )}
      </Dropzone>

      <div className={styles.fileDisplayContainer}>
        {fieldValue && <FileStatusBar data={fieldValue} removeFile={removeFile} fileSizeCounter={setFileSizeHint} />}
      </div>
    </div>
  );
};

export default DropzoneVideo;
