//types
import {IOpportunityResponse, OpportunityResponse} from '@joc/api-gateway';

export const getCurrentOpportunity = (records: Array<OpportunityResponse>, opportunityId: number) =>
  records.find((opportunity) => opportunity.id === opportunityId);

export const getCurrentOpportunityIndex = (records: Array<IOpportunityResponse>, opportunityId: number) =>
  records.findIndex((opportunity) => opportunity.id === opportunityId);

export const getPendingApprovalOpportunities = (records: Array<IOpportunityResponse>) =>
  records.filter((opportunity) =>
    opportunity.opportunityType === 'MANUAL'
      ? opportunity.status === 'PENDING'
      : opportunity.volunteers?.some((volunteer) => volunteer.status === 'PENDING')
  );
