import * as Yup from 'yup';
//vallidation
import {organisationIdSchema} from 'core/validation';

export type RemoveFormValues = {
  organizationId: string;
};

export const initialValues: RemoveFormValues = {
  organizationId: '',
};

export const validationSchema = Yup.object().shape({
  organizationId: organisationIdSchema,
});
