import {createRef, FC, useEffect, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import cx from 'classnames';
//assets
import {ReactComponent as BlueArrow} from 'assets/image/selects/blue-arrow.svg';
import {ReactComponent as WhiteArrow} from 'assets/image/selects/white-arrow.svg';
//hooks
import {useClickOutside} from 'core/customHooks';
//types
import {ORGANISATION_GENERAL_TYPES} from '@joc/api-gateway/lib/api-client';
//components
import FieldWrapper from 'shared/inputs/FieldWrapper';
//types
import {FieldBaseProps} from 'core/types';
//styles
import styles from './index.module.scss';

type SingleSelectProps = FieldBaseProps<{
  options: Array<string | number>;
  placeholder?: string;
  classList?: Array<string | number>;
}>;

const SingleSelect: FC<SingleSelectProps> = ({placeholder, options, classList, ...fieldBaseProps}) => {
  const {field, form} = fieldBaseProps;
  const {setFieldValue, setFieldTouched} = form;

  const optionRef = useMemo(() => options.map(() => createRef<HTMLDivElement>()), [options]);

  const {isComponentVisible, componentRef, setIsComponentVisible} = useClickOutside({
    initialVisible: false,
    setFieldTouched,
    fieldName: field.name,
  });

  const {t} = useTranslation('inputs');

  const optionClickHandler = (option: string | number): void => {
    setIsComponentVisible(false);
    setFieldValue(field.name, option);
  };

  const titleClickHandler = () => {
    const valueIndex = options.findIndex((i) => i === field.value);
    const target = optionRef[valueIndex]?.current;
    if (target)
      // set timeout cause in a first time the scroll doesn't work
      setTimeout(() => {
        target.scrollIntoView();
      }, 0);

    if (isComponentVisible) setFieldTouched(field.name, true);
    setIsComponentVisible(!isComponentVisible);
  };

  return (
    <FieldWrapper {...fieldBaseProps}>
      <div
        id={field.name}
        className={cx(styles.dropdown_select, {
          [styles.md]: classList?.includes('md'),
          [styles.rectangle]: classList?.includes('rectangle'),
          [styles.dropdown_select__active]: isComponentVisible,
        })}
        ref={componentRef}
      >
        <div
          className={cx(styles.dropdown_select__title, {
            [styles.dropdown_select__title_selected]: !!field.value,
            [styles.disabledLikeInput]: fieldBaseProps.disabled,
          })}
          onClick={titleClickHandler}
        >
          <span>
            {field.value
              ? field.value === ORGANISATION_GENERAL_TYPES.OTHER
                ? t('placeholders.organization')
                : field.value === ORGANISATION_GENERAL_TYPES.SCHOOL
                ? t('placeholders.school')
                : field.value
              : placeholder}
          </span>
          {!isComponentVisible ? (
            <BlueArrow className={styles.dropdown_select__title__vector} />
          ) : (
            <WhiteArrow className={styles.dropdown_select__title__vector} />
          )}
        </div>
        <div
          className={cx(styles.dropdown_select__options, {
            [styles.dropdown_select__options_center]: classList?.includes('option_center'),
          })}
        >
          {options.map((option: string | number | ORGANISATION_GENERAL_TYPES, index) => (
            <div
              key={option}
              ref={optionRef[index]}
              className={styles.dropdown_select__options__item}
              onClick={() => optionClickHandler(option)}
            >
              {option === ORGANISATION_GENERAL_TYPES.OTHER
                ? t('placeholders.organization')
                : option === ORGANISATION_GENERAL_TYPES.SCHOOL
                ? t('placeholders.school')
                : option}
            </div>
          ))}
        </div>
      </div>
    </FieldWrapper>
  );
};

export default SingleSelect;
