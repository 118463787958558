import {FC, useCallback, useEffect, useState} from 'react';
import qs from 'qs';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router';
//translation
import {useTranslation} from 'react-i18next';
//redux
import {Store} from 'redux/root';
import {getOrgMembers, resetOrgMembers} from 'redux/organization-service/actions';
//types
import {VOLUNTEER} from '@joc/api-gateway';
//components
import MembersTable from 'components/Organization/Members/Table';
import TabNav from 'shared/components/TabNav';
import TabNavItem from 'shared/components/TabNav/TabNavItem';
import {MemberMenu} from 'components/Organization/Members/Menu';
import {MemberSidebar} from 'components/Organization/Members/Sidebar';
//styles
import styles from './Members.module.scss';

const MembersPage: FC = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const {t} = useTranslation(['members', 'common']);

  const [isClearSearch, setIsClearSearch] = useState(false);
  const [isShowFilter, setIsShowFilter] = useState(false);

  const countAll = useSelector((store: Store) => store.organizationRedux.organizationMembers.all.length);
  const countAdmins = useSelector((store: Store) => store.organizationRedux.organizationMembers.admins.length);
  const countMarketers = useSelector((store: Store) => store.organizationRedux.organizationMembers.marketers.length);
  const countCoordinators = useSelector(
    (store: Store) => store.organizationRedux.organizationMembers.coordinators.length
  );

  useEffect(() => {
    dispatch(getOrgMembers());
    return () => {
      dispatch(resetOrgMembers());
    };
  }, []);

  const changeVisibilityFilters = useCallback(() => {
    setIsShowFilter(!isShowFilter);
  }, [isShowFilter]);

  const appendQueryString = (query: {role: string} | undefined) => {
    setIsClearSearch(true);
    history.push({search: !query ? '' : qs.stringify(query)});

    if (isShowFilter) {
      dispatch(getOrgMembers());
      setIsShowFilter(false);
    }
  };

  const isTabActive = (queryString: string, activeRole: VOLUNTEER | null) => {
    const {role} = qs.parse(location.search, {ignoreQueryPrefix: true});
    if (!role && !activeRole) return true;
    return role === activeRole;
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <h1 className="title">{t('members:title')}</h1>
        <div className={styles.content}>
          <div className={styles.content__main}>
            <TabNav>
              <TabNavItem
                counter={countAll}
                title={t('common:titles.all')}
                activeTab={isTabActive(location.search, null)}
                clickHandler={() => appendQueryString(undefined)}
              />
              <TabNavItem
                counter={countAdmins}
                title={t('members:navTabs.admins')}
                activeTab={isTabActive(location.search, VOLUNTEER.ADMIN)}
                clickHandler={() => appendQueryString({role: VOLUNTEER.ADMIN})}
              />
              <TabNavItem
                counter={countCoordinators}
                title={t('members:navTabs.coordinators')}
                activeTab={isTabActive(location.search, VOLUNTEER.COORDINATOR)}
                clickHandler={() => appendQueryString({role: VOLUNTEER.COORDINATOR})}
              />

              <TabNavItem
                counter={countMarketers}
                title={t('members:navTabs.marketers')}
                activeTab={isTabActive(location.search, VOLUNTEER.MARKETER)}
                clickHandler={() => appendQueryString({role: VOLUNTEER.MARKETER})}
              />
            </TabNav>
            <MemberMenu
              changeVisibilityFilters={changeVisibilityFilters}
              isClearSearch={isClearSearch}
              setIsClearSearch={setIsClearSearch}
            />
            <MembersTable />
            <MemberSidebar
              isOpen={isShowFilter}
              changeVisibilityFilters={changeVisibilityFilters}
              isCloseFilters={!isShowFilter}
              handleClose={changeVisibilityFilters}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MembersPage;
