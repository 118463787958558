import {FC} from 'react';

import {isImage, isVideo} from '../../helpers';
import styles from './StatusMedia.module.scss';

type StatusMediaProps = {
  url?: string;
  altText?: string;
};

const StatusMedia: FC<StatusMediaProps> = ({url, altText = 'Media'}) => {
  if (url) {
    if (isImage(url)) {
      return (
        <div className={styles.statusMedia}>
          <img src={url} className={styles.statusMedia_circle} alt={altText} />
        </div>
      );
    }

    if (isVideo(url)) {
      return (
        <div className={styles.statusMedia}>
          <div className={styles.statusMedia_video}>
            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
            <video className={styles.statusMedia_circle} src={url} muted />
            <div className={styles.statusMedia_play}>&#9658;</div>
          </div>
        </div>
      );
    }
  }

  return <p>Unsupported media type</p>;
};

export default StatusMedia;
