//types
import {IOrganisationResponse} from '@joc/api-gateway';

export const getPreviousOrganisationName = (fieldValue: string, userOrganisations: Array<IOrganisationResponse>) =>
  userOrganisations.find((organisation) => organisation.id === fieldValue)?.organizationName;

export const getVolunteerOrganisations = (userOrganisations: Array<IOrganisationResponse> = []) =>
  userOrganisations.reduce((accumulator, currentOrgValue) => {
    if (!accumulator.some(({id}) => id === currentOrgValue.id)) accumulator.push(currentOrgValue);
    return accumulator;
  }, [] as IOrganisationResponse[]);

export const getFilteredOrganisations = (inputValue: string, userOrganisations: Array<IOrganisationResponse>) =>
  userOrganisations.filter((option) => option.organizationName.toLowerCase().includes(inputValue.toLowerCase().trim()));

export const getIsSelectedValueDirty = (
  inputValue: string,
  fieldValue: string,
  userOrganisations: Array<IOrganisationResponse>
) => inputValue.trim() !== userOrganisations.find((organisation) => organisation.id === fieldValue)?.organizationName;
