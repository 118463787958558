import {FC, PropsWithChildren} from 'react';
// icons
import {ReactComponent as CrossSvg} from 'assets/image/cross-button.svg';
// styles
import styles from './index.module.scss';

interface ICalendarSidebarWrapperProps {
  handleClose: () => void;
}

export const CalendarSidebarWrapper: FC<PropsWithChildren<ICalendarSidebarWrapperProps>> = ({
  children,
  handleClose,
}) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.close} onClick={handleClose}>
        <CrossSvg />
      </div>
      {children}
    </div>
  );
};
