import {FC} from 'react';
import cx from 'classnames';
//components
import {Switch} from '@material-ui/core';
//styles
import './index.scss';

type SwitchCheckboxParentProps = {
  isChecked: boolean;
  clickHandler: () => void;
  id?: string;
  isDisabled?: boolean;
  parentClassName?: string;
};

const SwitchCheckbox: FC<SwitchCheckboxParentProps> = ({clickHandler, isChecked, id, isDisabled, parentClassName}) => (
  <Switch
    disabled={isDisabled}
    className={cx(parentClassName, 'name')}
    name={id}
    id={id}
    checked={isChecked}
    onClick={clickHandler}
  />
);

export default SwitchCheckbox;
