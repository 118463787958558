import {FC} from 'react';
import {useHistory} from 'react-router';
//translation
import {useTranslation} from 'react-i18next';
//components
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
//styles
import styles from './RecoveryCongrats.module.scss';

type RecoveryCongratsProps = {
  title: string;
  isRecoverySuccess?: boolean;
};

const RecoveryCongrats: FC<RecoveryCongratsProps> = ({title, isRecoverySuccess}: RecoveryCongratsProps) => {
  const history = useHistory();

  const {t} = useTranslation('buttons');

  return (
    <div className={styles.content}>
      <span className={styles.title}>{title}</span>
      {isRecoverySuccess && (
        <ButtonDefault
          title={t('button.backToLogIn')}
          classList={['primary', 'extra_lg']}
          clickHandler={() => history.go(-1)}
        />
      )}
    </div>
  );
};

export default RecoveryCongrats;
