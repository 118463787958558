import {FC, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import cx from 'classnames';
//formik
import {FieldArray} from 'formik';
//types
import {WEEKDAYS} from '@joc/api-gateway';
import {WHICH_DAY_FILTER_FORMAT, WHICH_DAY_FILTER_TYPE} from 'core/types';
import {Dimension} from './DayItem/helpers';
//components
import DayItem from './DayItem';
//styles
import styles from './WhichDaysFilter.module.scss';
import {firstDayOfWeek} from './helper';

type WhichDaysFilterProps = {
  setFieldValue: (field: string, value: Array<WEEKDAYS>, shouldValidate?: boolean | undefined) => void;
  fieldValues?: Array<WEEKDAYS>;
  type?: WHICH_DAY_FILTER_TYPE;
  format?: WHICH_DAY_FILTER_FORMAT;
  withoutLabel?: boolean;
  parentClassName?: string;
  dimension?: Dimension;
};

const WhichDaysFilter: FC<WhichDaysFilterProps> = ({
  fieldValues,
  setFieldValue,
  type = WHICH_DAY_FILTER_TYPE.DEFAULT,
  format = WHICH_DAY_FILTER_FORMAT.TWO_LETTERS,
  withoutLabel = false,
  parentClassName,
  dimension,
}) => {
  const {t, i18n} = useTranslation('form');
  const locale = i18n.language.toLowerCase();
  const [weekStartDay, setWeekStartDay] = useState(firstDayOfWeek(locale));

  useEffect(() => {
    setWeekStartDay(firstDayOfWeek(locale));
  }, [locale]);

  const weekArray = useMemo(() => {
    return Object.values(WEEKDAYS).reduce((initSortedDaysArray, day) => {
      initSortedDaysArray.push(day);
      if (weekStartDay === WEEKDAYS.SUNDAY && day === WEEKDAYS.SUNDAY) {
        initSortedDaysArray = initSortedDaysArray.filter((day) => day !== WEEKDAYS.SUNDAY);
        initSortedDaysArray.unshift(day);
      }
      return initSortedDaysArray;
    }, [] as WEEKDAYS[]);
  }, [weekStartDay]);

  const handleClearDaysFilter = () => {
    setFieldValue('weekDays', []);
  };

  return (
    <div className={styles.container}>
      {!withoutLabel && (
        <div className={styles.header}>
          <div className={styles.label}>{t('filterWhichDay.title')}</div>
          <span className={styles.label__action} onClick={handleClearDaysFilter}>
            {t('filterWhichDay.clear')}
          </span>
        </div>
      )}

      <div className={cx(styles.who, parentClassName)}>
        <FieldArray
          name="weekDays"
          render={(arrayHelpers) =>
            weekArray.map((day) => (
              <label key={day}>
                <input
                  className={styles.input}
                  name="weekDays"
                  type="checkbox"
                  value={day}
                  checked={fieldValues?.includes(day)}
                  onChange={(e) => {
                    if (e.target.checked) {
                      arrayHelpers.push(day);
                    } else if (fieldValues) {
                      const idx = fieldValues.indexOf(day);
                      arrayHelpers.remove(idx);
                    }
                  }}
                />
                <DayItem
                  day={t(`filterWhichDay.${day}`)}
                  checked={fieldValues?.includes(day)}
                  type={type}
                  format={format}
                  dimension={dimension}
                />
              </label>
            ))
          }
        />
      </div>
    </div>
  );
};

export default WhichDaysFilter;
