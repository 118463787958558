import {FC, MouseEvent, MouseEventHandler, useEffect, useState} from 'react';
import cx from 'classnames';
//functions
import {getDefaultImage, setUserPhotoSrc} from 'core/functions';
//types
import {IMAGE_WITH_POPUP_TYPE} from 'core/types';
//helpers
import {getIsImageEnlargeable} from './helpers';
//components
import ZoomPhotoPopup from 'shared/components/ZoomPhotoPopup';
//styles
import styles from './ImageWithPopup.module.scss';

type ImageWithPopupProps = {
  imagePath: string | undefined;
  onClick?: MouseEventHandler;
  classNames?: string;
  alt?: string;
  popupTitle?: string;
  disablePopup?: boolean;
  type?: IMAGE_WITH_POPUP_TYPE;
  // If header overlaps content, set true to adjust popup position
  disableZeroTop?: boolean;
};

const ImageWithPopup: FC<ImageWithPopupProps> = ({
  imagePath,
  classNames,
  alt,
  popupTitle,
  onClick,
  disableZeroTop,
  disablePopup,
  type = IMAGE_WITH_POPUP_TYPE.DEFAULT,
}) => {
  const defaultImage = getDefaultImage(type);

  const [imgUrl, setImgUrl] = useState<string>(defaultImage);
  const [isShowPhotoPopup, setIsShowPhotoPopup] = useState(false);

  const isEnlargeable = getIsImageEnlargeable(imgUrl, defaultImage, !!disablePopup);

  useEffect(() => {
    const photoSrc = setUserPhotoSrc(imagePath, type);
    setImgUrl(photoSrc);
  }, [imagePath]);

  const imageClickHandler = (event: MouseEvent<HTMLElement>): void => {
    onClick?.(event);
    if (isEnlargeable) setIsShowPhotoPopup(true);
  };

  const imgErrorHandler = () => {
    setImgUrl(defaultImage);
  };

  return (
    <>
      <img
        src={imgUrl || defaultImage}
        alt={alt || 'avatar'}
        className={cx({[styles.enlargeable]: isEnlargeable}, classNames)}
        onClick={imageClickHandler}
        onError={imgErrorHandler}
      />
      <ZoomPhotoPopup
        isShowPopup={isShowPhotoPopup}
        urlPhoto={imgUrl}
        setIsShowPopup={setIsShowPhotoPopup}
        title={popupTitle}
        isPhotoStyleUpdating={!!disableZeroTop}
      />
    </>
  );
};

export default ImageWithPopup;
