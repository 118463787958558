import {FC, useCallback, useEffect, useState} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {getUsersSignupTraffic} from 'redux/dashboard-service/action';
//redux
import {Store} from 'redux/root';
import {
  selectorGetStatisticsUsersSignupTraffic,
  selectorGetStatisticsUsersSignupTrafficTotal,
} from 'redux/dashboard-service/selector';
//types
import {IDateRange} from '@joc/api-gateway';
//components
import WhiteContainer from 'shared/components/WhiteContainer';
import ResponseFailure from 'shared/components/ResponseFailure';
import UserTrafficChart from '../UserTrafficChart';
import ChartHeaderTotal from '../ChartHeaderTotal';
//styles
import styles from 'pages/Organization/Statistics/Statistics.module.scss';

type UsersSignupTrafficParentProps = {
  dateRange: IDateRange;
};

const UsersSignupTraffic: FC<UsersSignupTrafficProps> = ({
  usersSignupTraffic,
  getUsersSignupTraffic,
  dateRange,
  usersSignupTotal,
}: UsersSignupTrafficProps) => {
  const {toDate, fromDate} = dateRange;

  const [customError, setCustomError] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const {t} = useTranslation(['statistics', 'errors']);

  const getUsersSignupStatistics = useCallback(async () => {
    setCustomError('');
    setIsLoading(true);
    try {
      await getUsersSignupTraffic(dateRange);
    } catch (error) {
      setCustomError(error?.response?.message || error.message);
    } finally {
      setIsLoading(false);
    }
  }, [toDate, fromDate]);

  useEffect(() => {
    getUsersSignupStatistics();
  }, [getUsersSignupStatistics]);

  return (
    <WhiteContainer parentClassName={styles.container_block} title={t('statistics:usersTrafficSignup.signupTraffic')}>
      <ChartHeaderTotal count={usersSignupTotal} />
      {customError.length && !isLoading ? (
        <ResponseFailure
          message={customError}
          buttonTitle={t('errors:tryAgain')}
          buttonClickHandler={getUsersSignupStatistics}
          styleTable
        />
      ) : (
        <UserTrafficChart usersTraffic={usersSignupTraffic} isLoading={isLoading} />
      )}
    </WhiteContainer>
  );
};

const mapStateToProps = (store: Store) => ({
  usersSignupTraffic: selectorGetStatisticsUsersSignupTraffic(store),
  usersSignupTotal: selectorGetStatisticsUsersSignupTrafficTotal(store),
});

const mapDispatchToProps = {
  getUsersSignupTraffic,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type UsersSignupTrafficProps = ConnectedProps<typeof connector> & UsersSignupTrafficParentProps;

export default connector(UsersSignupTraffic);
