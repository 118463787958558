import moment from 'moment';
import i18n from 'i18next';
//functions
import {getIsEnglish} from 'core/functions';

export type PickerValuesType = {
  hours: number | null;
  minutes: number | null;
  meridiem: MERIDIEM | null;
};

export enum MERIDIEM {
  AM = 'AM',
  PM = 'PM',
}

export const timePickerInitialValue: PickerValuesType = {
  hours: null,
  minutes: null,
  meridiem: null,
};

export const timeInputFormat = {
  a: '[0-1]',
  b: '[0-9]',
  c: '[0-5]',
  d: '[0-9]',
  e: '[APap]',
};

export const timeInputMask = 'ab:cd eM';

export const convertInputValueToFieldValue = (inputValue: string) => {
  const isEmpty = !inputValue || inputValue === '__:__ _M';

  if (isEmpty) return '';
  return moment(inputValue, 'hh:mm A', 'en', true).format('HH:mm');
};

export const convertPickerValueToInputValue = (timePickerValue: PickerValuesType) => {
  const hours = timePickerValue.hours
    ? timePickerValue.hours <= 9
      ? `0${timePickerValue.hours}`
      : timePickerValue.hours
    : '__';

  const minutes =
    timePickerValue.minutes !== null
      ? timePickerValue.minutes <= 9
        ? `0${timePickerValue.minutes}`
        : timePickerValue.minutes
      : '__';

  return timePickerValue.hours && (timePickerValue.minutes || timePickerValue.minutes === 0)
    ? `${hours}:${minutes} ${timePickerValue.meridiem}`
    : '';
};

export const getIsPickerValuesValid = (timePickerValue: PickerValuesType) =>
  timePickerValue.minutes !== null &&
  !isNaN(timePickerValue.minutes) &&
  timePickerValue.hours !== null &&
  !isNaN(timePickerValue.hours);

export const convertFieldValueToPickerValue = (fieldValue: string): PickerValuesType => {
  const isValid = moment(fieldValue, 'HH:mm', 'en', true).isValid();

  if (!isValid) return timePickerInitialValue;

  const date = moment().format('YYYY-MM-DD');
  const formattedDate = moment(`${date} ${fieldValue}`, 'YYYY-MM-DD HH:mm').format('h:m A');

  const time = formattedDate.split(':');
  const hours = +time[0];

  const minutes = +time[1]?.split(' ')[0];
  const meridiem = +moment(`${date} ${fieldValue}`, 'YYYY-MM-DD HH:mm').hours() < 12 ? MERIDIEM.AM : MERIDIEM.PM;

  return {hours, minutes, meridiem};
};

export const getFormatDateToHourAndMinute = (date: Date) => {
  return moment(date, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)').format('HH:mm');
};

export const getOpportunityValidTime = (startTimeDate: Date, endTimeDate: Date) => {
  const startTime = getFormatDateToHourAndMinute(startTimeDate);
  let endTime = getFormatDateToHourAndMinute(endTimeDate);

  if (startTime === endTime) {
    endTime = moment(endTimeDate, 'HH:mm').add(1, 'hours').format('HH:mm');
  }

  return {startTime, endTime};
};

export const convertPickerValueToFieldValue = (pickerValue: PickerValuesType) => {
  const currentLanguage = i18n.language;
  const isEnglish = getIsEnglish(currentLanguage);

  if (!isEnglish) moment.locale('en');

  const time = `${pickerValue.hours}:${pickerValue.minutes} ${pickerValue.meridiem}`;
  const date = moment().format('YYYY-MM-DD');
  const result = moment(`${date} ${time}`, 'YYYY-MM-DD h:m A').format('HH:mm');

  if (!isEnglish) moment.locale(currentLanguage);

  return result;
};
