import {OPPORTUNITIES_FILTERS_VOLUNTEER_VALUES} from 'core/types/index';

export const INITIAL_OPPORTUNITIES_FILTERS_VOLUNTEER: OPPORTUNITIES_FILTERS_VOLUNTEER_VALUES = {
  opportunityLocationType: [],
  withWho: [],
  weekDays: [],
  startTime: null,
  endTime: null,
  fromDate: null,
  toDate: null,
  howFar: 0,
  organisationId: undefined,
};
