import {FC} from 'react';
import cx from 'classnames';
//helpers
import {CommonProps} from './helpers';
//styles
import styles from './TableHeadCell.module.scss';

type TableHeadCellPropsType = CommonProps;

const TableHeadCell: FC<TableHeadCellPropsType> = ({text, itemClassName, children}) => (
  <div className={cx(styles.main, itemClassName && styles[itemClassName])}>
    {children || <span className={styles.text}>{text}</span>}
  </div>
);

export default TableHeadCell;
