import {FC, Dispatch, SetStateAction, useEffect, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import cx from 'classnames';
//scroll
import {clearAllBodyScrollLocks, disableBodyScroll} from 'body-scroll-lock';
//types
import {JOIN_STATE} from 'core/types';
//components
import Appeal from 'shared/components/Appeal/DefaultRenderer';
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
//styles
import styles from './PopupJoinCongrats.module.scss';

type PopupJoinCongratsProps = {
  setJoinState: Dispatch<SetStateAction<JOIN_STATE>>;
  isApprovalRequired: boolean;
};

const PopupJoinCongrats: FC<PopupJoinCongratsProps> = ({setJoinState, isApprovalRequired}: PopupJoinCongratsProps) => {
  const {t} = useTranslation(['messages', 'buttons']);

  const message = useMemo(
    () => (isApprovalRequired ? t('messages:orgIsReviewingProfile') : t('messages:youHaveBeenAddedToOppo')),
    [isApprovalRequired]
  );

  useEffect(() => {
    disableBodyScroll(document.body, {reserveScrollBarGap: true});

    return () => clearAllBodyScrollLocks();
  }, []);

  const confirmButtonClickHandler = () => setJoinState(JOIN_STATE.NONE);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Appeal styleJoin>
          <h2 className={cx('title', styles.content__title)}>{t('messages:thankForJoin')}</h2>

          <div>{message}</div>

          <ButtonDefault
            classList={['primary']}
            title={t('buttons:button.confirm')}
            clickHandler={confirmButtonClickHandler}
          />
        </Appeal>
      </div>
    </div>
  );
};

export default PopupJoinCongrats;
