import {Dispatch, FC, SetStateAction, useState} from 'react';
import {useTranslation} from 'react-i18next';
import cx from 'classnames';
//components
import Appeal from 'shared/components/Appeal/DefaultRenderer';
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
//styles
import styles from './PopupCongrats.module.scss';

type PopupCongratsProps = {
  setIsShowPopup: Dispatch<SetStateAction<boolean>>;
  setIsSubmited: Dispatch<SetStateAction<boolean>>;
  setIsShowUpdatePopup?: Dispatch<SetStateAction<boolean>>;
  setIsShowParentPopup?: Dispatch<SetStateAction<boolean>>;
  createOneMoreClickHandler?: () => void;
  isUpdateMode?: boolean;
};

const PopupCongrats: FC<PopupCongratsProps> = ({
  setIsShowPopup,
  setIsSubmited,
  setIsShowUpdatePopup,
  setIsShowParentPopup,
  createOneMoreClickHandler,
  isUpdateMode,
}) => {
  const [isClosePopup, setIsClosePopup] = useState<boolean>(false);

  const {t} = useTranslation(['popup', 'messages', 'buttons']);

  const doneButtonClickHandler = () => {
    setIsClosePopup(true);
    setTimeout(() => {
      setIsShowPopup(false);
      if (setIsShowParentPopup) setIsShowParentPopup(false);
    }, 200);
  };

  const editButtonClickHandler = () => {
    setIsClosePopup(true);
    setTimeout(() => {
      setIsSubmited(false);
      setIsShowUpdatePopup && setIsShowUpdatePopup(true);
    }, 200);
  };

  const createClickHandler = () => {
    setIsClosePopup(true);
    createOneMoreClickHandler && setTimeout(createOneMoreClickHandler, 200);
  };

  return (
    <div
      className={cx(styles.container, {
        [styles.close]: isClosePopup,
      })}
    >
      <Appeal isHeightDisable>
        <div className={styles.appeal}>
          <span className="title">{t('messages:congratulations')}</span>
          <span className={styles.subtitle}>
            {isUpdateMode ? t('popup:congratsOppo.hasBeenUpdated') : t('popup:congratsOppo.hasBeenCreated')}
          </span>
          <span className={styles.subtitle}>
            {isUpdateMode ? t('popup:congratsOppo.pleaseCheck') : t('popup:congratsOppo.canFindIt')}
          </span>
        </div>
      </Appeal>
      <div className={styles.options}>
        <ButtonDefault
          classList={['lg', 'primary']}
          title={t('buttons:button.done')}
          clickHandler={doneButtonClickHandler}
        />
        <ButtonDefault
          classList={['lg', 'secondary']}
          title={t('buttons:button.viewEdit')}
          clickHandler={editButtonClickHandler}
        />
        {!isUpdateMode && (
          <ButtonDefault
            classList={['lg', 'secondary']}
            title={t('buttons:button.createMore')}
            clickHandler={createClickHandler}
          />
        )}
      </div>
    </div>
  );
};

export default PopupCongrats;
