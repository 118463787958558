import {Dispatch, FC, SetStateAction, useState} from 'react';
import firebase from 'firebase';
import cx from 'classnames';
//formik
import {Field, Form, Formik} from 'formik';
//translation
import {useTranslation} from 'react-i18next';
//form configuration
import {RecoverySendEmailFormValues, initialValues, validationSchema} from './formConfiguration';
//components
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
import Loader from 'shared/components/Loader';
import Input from 'shared/inputs/Input';
//styles
import styles from './RecoverySendEmailForm.module.scss';

type RecoverySendEmailFormProps = {
  setSentEmailSuccess: Dispatch<SetStateAction<boolean>>;
  setResponseError: Dispatch<SetStateAction<boolean>>;
  setErrorMessage: Dispatch<SetStateAction<string>>;
  setIsNoPasswordProvider: Dispatch<SetStateAction<boolean>>;
};

const RecoverySendEmailForm: FC<RecoverySendEmailFormProps> = ({
  setSentEmailSuccess,
  setResponseError,
  setErrorMessage,
  setIsNoPasswordProvider,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const {t} = useTranslation(['inputs', 'messages', 'buttons']);

  const submitClickHandler = async (values: RecoverySendEmailFormValues) => {
    const {email} = values;
    try {
      setIsLoading(true);
      const signInMethodsResponse = await firebase.auth().fetchSignInMethodsForEmail(email);
      if (!signInMethodsResponse.includes('password')) {
        setResponseError(true);
        setErrorMessage(
          `${t('messages:haventPasswordSignin')} ${signInMethodsResponse.join(', ')} ${
            signInMethodsResponse.length > 1 ? t('messages:accounts') : t('messages:account')
          }, ${t('messages:or')}`
        );
        setIsNoPasswordProvider(true);
      } else {
        await firebase.auth().sendPasswordResetEmail(values.email);
        setSentEmailSuccess(true);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      setResponseError(true);
      setErrorMessage(error?.response?.message || error.message);
    }
  };

  return (
    <div className={styles.container}>
      <h1 className={cx('title', styles.title)}>{t('messages:fogotPasswordTitle')}</h1>
      <span className={styles.subTitle}>{t('messages:fogotPasswordMessage')}</span>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={submitClickHandler}>
        <Form className={styles.form}>
          <Field name="email" placeholder={t('inputs:placeholders.email')} isDisabledMargin component={Input} />
          {isLoading ? (
            <Loader />
          ) : (
            <ButtonDefault submitType classList={['primary', 'extra_lg']} title={t('buttons:button.sendEmail')} />
          )}
        </Form>
      </Formik>
    </div>
  );
};

export default RecoverySendEmailForm;
