import {FC, ReactNode, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {Channel} from 'stream-chat';
import {useChatContext} from 'stream-chat-react';
//constants
import {BUTTON_ICONS} from 'core/constants';
//context
import {useMessagesFilterContext} from '../../context';
//components
import ChatSearch from '../ChatSearch';
import ButtonImage from 'shared/components/Buttons/ButtonImage';

type ListHeaderWrapperProps = {
  channel?: Channel;
  isOrg?: boolean;
  children: ReactNode;
};

const ListHeaderWrapper: FC<ListHeaderWrapperProps> = ({channel, isOrg, children}) => {
  const {setActiveChannel} = useChatContext();
  const {setIsMessagesFilterVisible} = useMessagesFilterContext();

  const {t} = useTranslation(['chat', 'errors', 'tooltips']);

  useEffect(() => {
    if (channel) setActiveChannel(channel);
  }, [channel]);

  const filterClickHandler = () => {
    setIsMessagesFilterVisible!((previousState) => !previousState);
  };

  return (
    <div className="messaging__channel-list">
      <div className="messaging__channel-list__header">
        {isOrg && <div className="messaging__channel-list__title">{t('chat:chats')}</div>}
        <div className="messaging__channel-list__controls">
          <ChatSearch />
          <ButtonImage
            viewStyle={BUTTON_ICONS.filter}
            clickHandler={filterClickHandler}
            title={t('tooltips:chatFilter')}
          />
        </div>
      </div>
      {children}
    </div>
  );
};

export default ListHeaderWrapper;
