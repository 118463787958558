import {FC, ReactNode, useMemo} from 'react';
import cx from 'classnames';
import {useTranslation} from 'react-i18next';
//functions
import {setFullName} from 'core/functions';
//components
import VolunteerBadges from '../../Badges/VolunteerBadges';

// images
import {ReactComponent as InfoIcon} from 'assets/icons/info.svg';

//styles
import styles from './FullNameCell.module.scss';
import defaultStyles from '../CellDefault.module.scss';
import Tooltip from '@mui/material/Tooltip';

type FullNameCellProps = {
  firstName: string | undefined;
  lastName: string | undefined;
  createDate?: Date;
  disableTextStyle?: boolean;
  isShowMemberBadge?: boolean;
  parentClassName?: string;
  warning?: string | ReactNode;
};

const FullNameCell: FC<FullNameCellProps> = ({
  firstName,
  lastName,
  createDate,
  disableTextStyle,
  isShowMemberBadge,
  parentClassName,
  warning,
}: FullNameCellProps) => {
  const {
    i18n: {language},
  } = useTranslation();

  const fullName = useMemo(() => setFullName(firstName, lastName), [firstName, lastName]);

  return (
    <div className={cx(styles.fullName, defaultStyles.main, parentClassName)}>
      <div className={styles.fullName__content}>
        <VolunteerBadges createDate={createDate} isShowMemberBadge={isShowMemberBadge} language={language} />
        <span className={cx(!disableTextStyle && styles.text)} title={fullName}>
          {warning && (
            <>
              <Tooltip title={warning}>
                <InfoIcon className={defaultStyles.warning} />
              </Tooltip>
            </>
          )}
          <span>{fullName}</span>
        </span>
      </div>
    </div>
  );
};

export default FullNameCell;
