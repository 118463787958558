//types
import {
  GroupAgesRequest,
  GroupGenderRequest,
  GroupLocationsRequest,
  GroupSchoolGradesRequest,
  GroupSpendTimeRequest,
  GroupsRequest,
  IGroupSpendTimeResponse,
  IGroupsResponse,
} from '@joc/api-gateway';
import {Entry} from 'core/types';
//form configuration
import {SmartGroupFormValues} from './formConfiguration';
//functions
import {trimEmptySpacesText} from 'core/functions';

export const getGroupsRequestFromSmartGroupFormValues = (
  {groupName, spendTime, ages, gender, schoolGrades, locations, volunteers}: SmartGroupFormValues,
  organizationId: number,
  schoolId?: string
): GroupsRequest => {
  return GroupsRequest.fromJS({
    organizationId,
    groupName: trimEmptySpacesText(groupName),
    locations: locations !== null ? [GroupLocationsRequest.fromJS(locations)] : undefined,
    ages: ages !== null ? [GroupAgesRequest.fromJS(ages)] : undefined,
    gender: gender !== null ? GroupGenderRequest.fromJS({genderId: gender}) : undefined,
    spendTime:
      spendTime !== null
        ? GroupSpendTimeRequest.fromJS({
            [spendTime.direction]: spendTime.amount,
            groupType: spendTime.groupType,
          })
        : undefined,
    schoolGrades:
      schoolGrades !== null ? [GroupSchoolGradesRequest.fromJS({gradeId: schoolGrades, schoolId})] : undefined,
    volunteers: volunteers ?? undefined,
  });
};

const getSpendTimeValueFromSpendTimeResponse = (
  spendTimeResponse: IGroupSpendTimeResponse
): SmartGroupFormValues['spendTime'] => {
  const {groupType} = spendTimeResponse;

  const [spendTime] = Object.entries(spendTimeResponse)
    .filter((entry) => {
      const [key, value] = entry as Entry<IGroupSpendTimeResponse>;
      return key !== 'id' && typeof value === 'number';
    })
    .map((entry): SmartGroupFormValues['spendTime'] => {
      const [direction, amount] = entry as Entry<Omit<IGroupSpendTimeResponse, 'id'>>;
      return {direction, amount, groupType};
    });
  return spendTime;
};

export const getSmartGroupValuesFromGroupsResponse = ({
  groupName,
  customVolunteers,
  ages,
  spendTime,
  locations,
  schoolGrades,
  gender,
}: IGroupsResponse): SmartGroupFormValues => ({
  groupName,
  locations: locations && locations.length ? locations[0] : null,
  ages: ages && ages.length ? ages[0] : null,
  gender: gender ? Number(gender.genderId) : null,
  spendTime: spendTime ? getSpendTimeValueFromSpendTimeResponse(spendTime) : null,
  schoolGrades: schoolGrades && schoolGrades.length ? +schoolGrades[0].gradeId : null,
  volunteers:
    //TODO: fix response volunteers value to match the type (actually, response volunteers type isn`t Array<number>)
    //customVolunteers && customVolunteers.length ? customVolunteers : null,
    //@ts-ignore
    customVolunteers && customVolunteers.length ? customVolunteers.map((volunteer) => +volunteer.volunteer.id) : null,
});
