import cx from 'classnames';
import {useTranslation} from 'react-i18next';

//styles
import styles from './index.module.scss';
import {FC} from 'react';
import {ACTION_BUTTON} from './interfaces';

interface IOppoActionItemProps {
  icon: string;
  text: ACTION_BUTTON;
  isDisabled?: boolean;
  handleClick?: () => void;
}

export const OppoActionItem: FC<IOppoActionItemProps> = ({icon, text, isDisabled, handleClick}) => {
  const {t} = useTranslation(['myChesed', 'messages', 'buttons']);

  return (
    <div
      onClick={handleClick || (() => {})}
      className={cx(styles.action, isDisabled && styles.disabled)}
      title={t(`buttons:button.${text}`)}
    >
      <div className={styles.action__icon}>
        <img src={icon} alt="action icon" />
      </div>
      <span className={styles.action__text}>{t(`buttons:button.${text}`)}</span>
    </div>
  );
};
