import {IOrganisationPublicDashboardResponse} from '@joc/api-gateway';
import {Dispatch} from 'redux';
//action types
import {SET_PUBLIC_DASHBOARD_DATA} from 'redux/actions-types';

export const setPublicDashboardData = (data: IOrganisationPublicDashboardResponse) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_PUBLIC_DASHBOARD_DATA,
    payload: data,
  });
};
