import axios, {AxiosRequestConfig} from 'axios';
import {saveAs} from 'file-saver';
//types
import {
  DateRange,
  IExportVolunteersByGroupRequest,
  IExportVolunteersRequest,
  ISearchOpportunitiesRequest,
  ISearchVolunteersByGroupRequest,
  ISearchVolunteersRequest,
  SearchVolunteersByGroupRequest,
  SearchVolunteersRequest,
} from '@joc/api-gateway';
import moment, {Moment} from 'moment';

export const getExportedVolunteers = async (
  searchBody: ISearchVolunteersRequest | ISearchVolunteersByGroupRequest,
  includeOpportunities: boolean,
  orgId: number,
  accessToken: string,
  oppoDateRange?: DateRange
): Promise<Blob> => {
  const requestConfig: AxiosRequestConfig = {
    method: 'POST',
    responseType: 'blob',
    headers: {
      'x-organization-id': orgId,
      Authorization: `Bearer ${accessToken}`,
    },
  };

  let volunteersData: Blob;

  if ((searchBody as ISearchVolunteersByGroupRequest).groupId) {
    const requestBody: IExportVolunteersByGroupRequest = {
      searchBody: SearchVolunteersByGroupRequest.fromJS({...searchBody, organizationId: orgId}),
      includeOpportunities,
    };

    volunteersData = (
      await axios(`${process.env.REACT_APP_JOC_API}/volunteer/exportVolunteersByGroupXlsx`, {
        ...requestConfig,
        data: requestBody,
      })
    ).data;
  } else {
    const {createdDate, ...newSearchBody} = searchBody as ISearchVolunteersRequest;

    const checkOppoDateRange = !!oppoDateRange && Object.values(oppoDateRange).some((range) => Boolean(range));

    const requestBody: IExportVolunteersRequest = {
      searchBody: SearchVolunteersRequest.fromJS(newSearchBody),
      includeOpportunities,
    };

    if (checkOppoDateRange) {
      requestBody.oppoDateRange = oppoDateRange;
    }

    volunteersData = (await axios.post(`${process.env.REACT_APP_JOC_API}/volunteer/export`, requestBody, requestConfig))
      .data;
  }

  return volunteersData;
};

function transformNameString(input: string) {
  // Step 1: Replace all spaces, periods, commas, and hyphens with underscores
  let transformedString = input.replace(/[ .,\\-]/g, '_');

  // Step 2: Remove the last underscore if the original input ended with a space or period
  if (input.endsWith(' ') || input.endsWith('.') || input.endsWith(',') || input.endsWith('-')) {
    transformedString = transformedString.slice(0, -1);
  }

  return transformedString;
}

export const getExportedPastOppo = async (
  bodyRequest: Omit<ISearchOpportunitiesRequest, 'pagination'>,
  accessToken: string,
  id: number | undefined,
  name: string,
  schoolId: number | undefined
): Promise<void> => {
  const timeZone = `${moment(new Date()).format('Z')}`;

  const requestConfig: AxiosRequestConfig = {
    method: 'POST',
    responseType: 'blob',
    headers: {
      'x-organization-id': id,
      'x-time-zone-offset': timeZone.toString(),
      Authorization: `Bearer ${accessToken}`,
    },
  };

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_JOC_API}/opportunities/export-opportunities-by-organisation`,
      bodyRequest,
      requestConfig
    );

    const volunteersData = new Blob([response.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    const exportedListName = `${transformNameString(name.slice(0, 80))}_${
      !!schoolId ? 'students' : 'volunteers'
    }_list.xlsx`;

    const downloadLink = document.createElement('a');
    const blobUrl = window.URL.createObjectURL(volunteersData);

    downloadLink.href = blobUrl;
    downloadLink.download = exportedListName;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    window.URL.revokeObjectURL(blobUrl);
  } catch (error) {
    console.error('Error downloading file:', error);
  }
};

export const getTransformDate = (fromDate?: Moment | null, toDate?: Moment | null) => {
  const localTimeZone = new Date().getTimezoneOffset();

  const transformFromDate = fromDate ? new Date(String(fromDate)) : null;
  const transformToDate = toDate ? new Date(String(toDate)) : null;

  const dateFromWithTimeZone = transformFromDate ? new Date(transformFromDate.getTime() + localTimeZone * 60000) : null;
  const dateToWithTimeZone = transformToDate ? new Date(transformToDate.getTime() + localTimeZone * 60000) : null;

  const newFromDate = dateFromWithTimeZone ? moment(dateFromWithTimeZone).startOf('day').toISOString() : null;
  const newToDate = dateToWithTimeZone ? moment(dateToWithTimeZone).endOf('day').toISOString() : null;

  return {fromDate: newFromDate, toDate: newToDate};
};
