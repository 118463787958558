import {FC} from 'react';
// styles
import styles from './index.module.scss';

interface IVirtualInfo {
  text: string;
}

export const VirtualInfo: FC<IVirtualInfo> = ({text}) => {
  return (
    <div className={styles.virtualInfo}>
      <div className={styles.virtualInfo__text}>{text}</div>
    </div>
  );
};
