import {FC, Fragment, useEffect, useState} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {useTranslation} from 'react-i18next';
//redux
import {Store} from 'redux/root';
import {selectorGetError} from 'redux/error-service/selector';
import {resetError} from 'redux/error-service/action';
//types
import {SIGNUP_ERROR} from 'core/types';
//components
import VolunteerInfoForm from 'components/Volunteer/SignUpFormV/AboutYouSignupFormV/VolunteerInfoForm';
import ArrowBackLink from 'shared/components/ArrowBackLink';
import WhiteContainer from 'shared/components/WhiteContainer';
import ResponseFailure from 'shared/components/ResponseFailure';

const AboutYouSignupFormV: FC<AboutYouSignupFormVProps> = ({error, resetError}: AboutYouSignupFormVProps) => {
  const {t} = useTranslation(['signUpUser', 'validation']);

  const [signUpError, setSignUpError] = useState(SIGNUP_ERROR.NONE);

  useEffect(() => {
    if (error.code === 400 || error.code === 409) setSignUpError(SIGNUP_ERROR.EMAIL_ALREADY_EXISTS);
  }, [error.code]);

  const resetErrorHandler = () => {
    resetError();
    setSignUpError(SIGNUP_ERROR.NONE);
  };

  return (
    <>
      {signUpError === SIGNUP_ERROR.EMAIL_ALREADY_EXISTS ? (
        <ResponseFailure message={t('validation:emailSchema.exists')} buttonClickHandler={resetErrorHandler} />
      ) : error.state ? (
        <ResponseFailure message={error.message} buttonClickHandler={resetErrorHandler} />
      ) : (
        <>
          <ArrowBackLink onboardingStyle />
          <WhiteContainer title={t('signUpUser:title')}>
            <VolunteerInfoForm />
          </WhiteContainer>
        </>
      )}
    </>
  );
};

const mapStateToProps = (store: Store) => ({
  error: selectorGetError(store),
});

const mapDispatchToProps = {
  resetError,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type AboutYouSignupFormVProps = ConnectedProps<typeof connector>;

export default connector(AboutYouSignupFormV);
