import i18n from 'i18next';
//types
import {ORGANISATION_GENERAL_TYPES} from '@joc/api-gateway';
import {ClaimCongratulationsParsedQs} from 'core/types/queryStrings';

export const getSubtitleInterpolationText = (parsedQs: ClaimCongratulationsParsedQs) => ({
  organisationName: parsedQs.organisationName,
  volunteer: (parsedQs.organisationGeneralType === ORGANISATION_GENERAL_TYPES.SCHOOL
    ? i18n.t('form:filterByWho.student')
    : i18n.t('form:filterByWho.vol')
  ).toLowerCase(),
  organisation: (parsedQs.organisationGeneralType === ORGANISATION_GENERAL_TYPES.SCHOOL
    ? i18n.t('form:filterByWho.school')
    : i18n.t('form:filterByWho.org')
  ).toLowerCase(),
});
