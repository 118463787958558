import {FC, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router';
import {Formik} from 'formik';
import qs from 'qs';
//redux
import {selectorGetUserData} from 'redux/user-service/selector';
import {selectorGetVolunteerActiveStatus} from 'redux/chat-service/selector';
import {userVolunteerUpdate} from 'redux/user-service/actions';
import {setError} from 'redux/error-service/action';
//types
import {VOLUNTEER_ACTIVE_STATUS} from '@joc/api-gateway';
//form configuration
import {EditVolunteerFormValues, getInitialValues, validationSchema} from './formConfiguration';
//components
import MessagePopup from './MessagePopup';
import EditVolunteerMainForm from './EditVolunteerMainForm';
//styles
import styles from './EditVolunteerForm.module.scss';

type EditVolunteerFormparentProps = {
  setIsUpdateSuccess: React.Dispatch<React.SetStateAction<boolean>>;
};

const EditVolunteerForm: FC<EditVolunteerFormparentProps> = ({setIsUpdateSuccess}) => {
  const location = useLocation();

  const dispatch = useDispatch();

  const userData = useSelector(selectorGetUserData);
  const volunteerStatus = useSelector(selectorGetVolunteerActiveStatus);

  const [fullfilled, setFullfilled] = useState<boolean>();
  const [isLoading, setIsLoading] = useState(false);

  const isVolunteerSuspended = useMemo(() => volunteerStatus === VOLUNTEER_ACTIVE_STATUS.SUSPENDED, [volunteerStatus]);

  const initialValues = useMemo(() => getInitialValues(userData), [userData]);

  useEffect(() => {
    const queryparams = qs.parse(location.search, {ignoreQueryPrefix: true});
    if (queryparams?.fullfilled) setFullfilled(!!queryparams.fullfilled);
  }, []);

  const submitClickHandler = async (values: EditVolunteerFormValues) => {
    const valuesToUpdate = {
      ...values,
      firstName: values.firstName?.trim(),
      lastName: values.lastName?.trim(),
      address:
        typeof values?.address === 'object' && Object.hasOwn(values?.address as object, 'text') ? null : values.address,
    };

    try {
      setIsLoading(true);
      await dispatch(userVolunteerUpdate(valuesToUpdate as EditVolunteerFormValues));
      setIsUpdateSuccess(true);
    } catch (error) {
      dispatch(setError(error?.response?.message || error.message, error?.response?.code || error.code));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.main}>
      {fullfilled && true && userData && <MessagePopup setIsShowPopup={() => setFullfilled(false)} data={userData} />}
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={submitClickHandler}
      >
        <EditVolunteerMainForm isVolunteerSuspended={isVolunteerSuspended} isLoading={isLoading} />
      </Formik>
    </div>
  );
};

export default EditVolunteerForm;
