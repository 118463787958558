// react
import {FC} from 'react';
// data
import {tagsConfig} from './data';
// hooks
import {useLoading} from 'hooks/useLoading';
// interface
import {IModalState, IModalsActionsProps, IModalsData} from './interface';
// components
import {DeleteModal} from 'shared/components/Modals/DeleteModal';
import {EditHashtagModal} from 'components/HashtagModals/EditHashtagModal';
import {CreateHashtagModal} from 'components/HashtagModals/CreateHashtagModal';

interface ITagsModalsProps {
  orgId: string | null;
  modalsData: IModalsData;
  modalsState: IModalState;
  modalsActions: IModalsActionsProps;
}

export const TagModals: FC<ITagsModalsProps> = ({modalsState, modalsActions, modalsData, orgId}) => {
  const {isLoading, startLoading} = useLoading(true);

  return (
    <>
      <CreateHashtagModal
        orgId={orgId}
        isLoading={isLoading}
        modalsData={modalsData}
        startLoading={startLoading}
        isOpen={modalsState.create}
        modalsActions={modalsActions}
        title={tagsConfig.create.title}
        subtitle={tagsConfig.create.subtitle}
      />
      <EditHashtagModal
        orgId={orgId}
        modalsData={modalsData}
        isOpen={modalsState.edit}
        startLoading={startLoading}
        title={tagsConfig.edit.title}
        handleClose={modalsActions.edit.close}
        fieldPlaceholder={tagsConfig.edit.placeholder}
      />
      <DeleteModal
        orgId={orgId}
        modalsData={modalsData}
        isOpen={modalsState.delete}
        startLoading={startLoading}
        title={tagsConfig.delete.title}
        subtitle={tagsConfig.delete.subtitle}
        handleClose={modalsActions.delete.close}
        buttonTextCancel={tagsConfig.delete.buttonTextCancel}
        buttonTextConfirm={tagsConfig.delete.buttonTextConfirm}
      />
    </>
  );
};
