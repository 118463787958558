import {Dispatch, FC, SetStateAction, useEffect, useState} from 'react';
import {useHistory} from 'react-router';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import cx from 'classnames';
import qs from 'qs';
//assets
import {ReactComponent as All} from 'assets/image/volFilter/all.svg';
import {ReactComponent as CompletedIcon} from 'assets/image/volFilter/completed.svg';
import {ReactComponent as Upcoming} from 'assets/image/volFilter/upcoming.svg';
import {ReactComponent as Rejected} from 'assets/image/volFilter/rejected.svg';
import {ReactComponent as CantMakeIt} from 'assets/image/volFilter/cantmakeit.svg';
import {ReactComponent as Past} from 'assets/image/volFilter/past.svg';
import {ReactComponent as Manual} from 'assets/image/volFilter/manual.svg';
//constants
import {
  AllTabQueryPreset,
  CantMakeItTabQueryPreset,
  CompletedTabQueryPreset,
  ManualTabQueryPreset,
  PastTabQueryPreset,
  RejectedTabQueryPreset,
  UpcomingTabQueryPreset,
  VOLUNTEER_TABS,
} from 'core/constants';
//styles
import styles from './FilterTags.module.scss';

interface IFilterTagsProps {
  currentTab: string | null;
  setCurrentTab: Dispatch<SetStateAction<string | null>>;
}

const FilterTags: FC<IFilterTagsProps> = ({setCurrentTab, currentTab}) => {
  const history = useHistory();

  const tabsSwitch = (preset: any) => {
    history.push({
      search: qs.stringify(preset),
    });
  };

  useEffect(() => {
    const queryValue = new URLSearchParams(location.search);
    const tabName = queryValue.get('tabName');
    setCurrentTab(tabName);
  }, [location.search]);

  const {t} = useTranslation('myChesed');

  return (
    <ul>
      <li
        className={cx(styles.feedStatus, {
          [styles.active]: currentTab === VOLUNTEER_TABS.ALL,
        })}
        onClick={() => tabsSwitch(AllTabQueryPreset)}
      >
        <div className={styles.feedStatus_wrapper}>
          <div className={styles.feedStatus__icon}>
            <All />
          </div>
          <div className={styles.feedStatus__text}>{t('statusAll')}</div>
        </div>
      </li>
      <li
        className={cx(styles.feedStatus, {
          [styles.active]: currentTab === VOLUNTEER_TABS.COMPLETED || currentTab === null,
        })}
        onClick={() => tabsSwitch(CompletedTabQueryPreset)}
      >
        <div className={styles.feedStatus_wrapper}>
          <div className={styles.feedStatus__icon}>
            <CompletedIcon />
          </div>
          <div className={styles.feedStatus__text}>{t('statusCompleted')}</div>
        </div>
      </li>
      <li
        className={cx(styles.feedStatus, {
          [styles.active]: currentTab === VOLUNTEER_TABS.UPCOMING,
        })}
        onClick={() =>
          tabsSwitch({
            ...UpcomingTabQueryPreset,
            startDay: {
              fromDate: `${moment().format('YYYY-MM-DDThh:mm:ss')}.000Z`,
            },
          })
        }
      >
        <div className={styles.feedStatus_wrapper}>
          <div className={styles.feedStatus__icon}>
            <Upcoming />
          </div>
          <div className={styles.feedStatus__text}>{t('upcoming')}</div>
        </div>
      </li>
      <li
        className={cx(styles.feedStatus, {
          [styles.active]: currentTab === VOLUNTEER_TABS.MANUAL,
        })}
        onClick={() => tabsSwitch(ManualTabQueryPreset)}
      >
        <div className={styles.feedStatus_wrapper}>
          <div className={styles.feedStatus__icon}>
            <Manual />
          </div>
          <div className={styles.feedStatus__text}>{t('manual')}</div>
        </div>
      </li>
      <li
        className={cx(styles.feedStatus, {
          [styles.active]: currentTab === VOLUNTEER_TABS.REJECTED,
        })}
        onClick={() => tabsSwitch(RejectedTabQueryPreset)}
      >
        <div className={styles.feedStatus_wrapper}>
          <div className={styles.feedStatus__icon}>
            <Rejected />
          </div>
          <div className={styles.feedStatus__text}>{t('rejected')}</div>
        </div>
      </li>
      <li
        className={cx(styles.feedStatus, {
          [styles.active]: currentTab === VOLUNTEER_TABS.CANT_MAKE_IT,
        })}
        onClick={() => tabsSwitch(CantMakeItTabQueryPreset)}
      >
        <div className={styles.feedStatus_wrapper}>
          <div className={styles.feedStatus__icon}>
            <CantMakeIt />
          </div>
          <div className={styles.feedStatus__text}>{t('cantMake')}</div>
        </div>
      </li>
      <li
        className={cx(styles.feedStatus, {
          [styles.active]: currentTab === VOLUNTEER_TABS.PAST,
        })}
        onClick={() => tabsSwitch(PastTabQueryPreset)}
      >
        <div className={styles.feedStatus_wrapper}>
          <div className={styles.feedStatus__icon}>
            <Past />
          </div>
          <div className={styles.feedStatus__text}>{t('pastOppo')}</div>
        </div>
      </li>
    </ul>
  );
};

export default FilterTags;
