import {FC, useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import QueryString from 'qs';
//API
import {API} from 'core/API';
//redux
import {Store} from 'redux/root';
import {getCurrentUser} from 'redux/user-service/actions';
//urls
import {MarketUrls, urls} from 'core/appUrls';
//images
import {ReactComponent as AppStoreSvg} from 'assets/image/appstore.svg';
import {ReactComponent as GooglePlaySvg} from 'assets/image/googleplay.svg';
//types
import {INewCreateUserRequest, SavePaymentRequest, VOLUNTEER} from '@joc/api-gateway';
// components
import Appeal from 'shared/components/Appeal/DefaultRenderer';
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
// styles
import styles from './PaymentCongrats.module.scss';

const PaymentCongrats: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const volunteerId = localStorage.getItem('volunteerId');
  const organisationId = localStorage.getItem('organisationId');
  const aboutYou: INewCreateUserRequest = JSON.parse(localStorage.getItem('aboutYou') || '{}');
  const accessToken = localStorage.getItem('accessToken');

  const [isLoading, setIsLoading] = useState(true);

  const userRoles = useSelector((store: Store) => store.userRedux.userData?.roles);

  useEffect(() => {
    const localStorageAccessToken = localStorage.getItem('accessToken');
    const {search} = location;
    const {id} = QueryString.parse(search, {ignoreQueryPrefix: true});
    if (localStorageAccessToken) dispatch(getCurrentUser());
    if (id) {
      savePaymentTransaction(id as string);
    } else {
      setIsLoading(false);
    }
  }, []);

  const {t} = useTranslation(['messages', 'buttons']);

  const savePaymentTransaction = async (id: string) => {
    setIsLoading(true);
    try {
      await API.savePaymentInfo(SavePaymentRequest.fromJS({sessionId: id}));
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error('🚀 ~ file: index.tsx ~ line 34 ~ savePaymentTransaction ~ error', error);
    }
  };

  const buttonClickHandler = async () => {
    if (accessToken) {
      if (userRoles?.length) {
        return userRoles?.find((role) => role.roleName === VOLUNTEER.VOLUNTEER) && volunteerId
          ? history.push(urls.volFeed)
          : userRoles?.find(
              (role) =>
                role.roleName === VOLUNTEER.ADMIN ||
                role.roleName === VOLUNTEER.COORDINATOR ||
                role.roleName === VOLUNTEER.MARKETER
            )
          ? history.push(urls.orgDashboard)
          : history.push(urls.volFeed);
      }

      if (Object.keys(aboutYou).length) {
        const roles = await API.getAllRoles();
        const roleName = roles.find((role) => Number(role.id) === Number(aboutYou.roleId))?.roleName;

        if (volunteerId && roleName === VOLUNTEER.VOLUNTEER) {
          return history.push(urls.volFeed);
        }

        if (
          organisationId &&
          roleName &&
          (roleName === VOLUNTEER.ADMIN || roleName === VOLUNTEER.COORDINATOR || roleName === VOLUNTEER.MARKETER)
        ) {
          return history.push(urls.orgDashboard);
        }
      }

      return history.push(urls.onboardingChooseUserType);
    }

    return history.push(urls.onboardingChooseUserType);
  };

  const openMarket = (link: string) => window.open(link, '_blank');

  return (
    <Appeal styleCongrats>
      <div className={styles.appeal}>
        <span className="title">{t('messages:thankYou')}</span>
        <span className={styles.subtitle}>{t('messages:paymentSuccessful')}</span>
      </div>

      <div className={styles.options}>
        <ButtonDefault
          classList={['lg', 'primary']}
          disabled={isLoading}
          title={t('buttons:button.done')}
          clickHandler={buttonClickHandler}
        />
      </div>

      <AppStoreSvg className={styles.icon} onClick={() => openMarket(MarketUrls.AppStoreAdmin)} />
      <GooglePlaySvg className={styles.icon} onClick={() => openMarket(MarketUrls.PlayMarketAdmin)} />
    </Appeal>
  );
};

export default PaymentCongrats;
