import {Field, FieldProps} from 'formik';
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
import cx from 'classnames';
import {useTranslation} from 'react-i18next';
//styles
import styles from '../OpportunitiesFiltersForm.module.scss';

export const LocationFields = () => {
  const {t} = useTranslation('form');
  return (
    <>
      <label className={styles.label}>{t('opportunity.localType')}</label>
      <div className={cx(styles.form__opportunitie_address, styles.form__location_type)}>
        <Field type="checkbox" name="opportunityLocationType" value="LOCAL">
          {({field}: FieldProps) => (
            <label htmlFor={`${field.name}1`} className={styles.label_for_button}>
              <ButtonDefault
                classList={['secondary', 'no_click', field.checked ? 'secondary_active' : '']}
                title={t('opportunity.local')}
              />
              <input type="checkbox" {...field} id={`${field.name}1`} />
            </label>
          )}
        </Field>
        <Field type="checkbox" name="opportunityLocationType" value="VIRTUAL">
          {({field}: FieldProps) => (
            <label htmlFor={`${field.name}2`} className={styles.label_for_button}>
              <ButtonDefault
                classList={['secondary', 'no_click', field.checked ? 'secondary_active' : '']}
                title={t('opportunity.virtual')}
              />
              <input type="checkbox" {...field} id={`${field.name}2`} />
            </label>
          )}
        </Field>
      </div>
    </>
  );
};
