import {FC, Fragment, useEffect, useState} from 'react';
import {useHistory, useLocation} from 'react-router';
import qs from 'qs';
//translation
import {useTranslation} from 'react-i18next';
//urls
import {urls} from 'core/appUrls';
//functions
import {getStoreLink} from 'core/functions/checkBrowser';
//images
import AppStore from 'assets/image/apple.svg';
import GooglePlay from 'assets/image/google.svg';
//components
import CongratsPopup from 'components/Organization/CongratsPopup';
//styles
import styles from './AcceptInviteCongrats.module.scss';

export const AcceptInviteCongrats: FC = () => {
  const history = useHistory();

  const {search} = useLocation();

  const [orgName, setOrgName] = useState('new');
  const [isMobile, setIsMobile] = useState(false);

  const {t} = useTranslation(['messages', 'buttons']);

  useEffect(() => {
    const orgName = qs.parse(search, {ignoreQueryPrefix: true})?.orgName;
    if (orgName) setOrgName(orgName as string);
    getStoreLink(window).link !== '#' && setIsMobile(true);
    localStorage.clear();
  }, []);

  return (
    <Fragment>
      <CongratsPopup
        subtitle={`${t('messages:youHaveBeenAddedToA')} ${orgName} ${t('messages:organization')}`}
        doneButtonClickHandler={() => {
          history.push(urls.onboardingChooseUserType);
        }}
        isFullHeight
        absoluteDisable
        withConfirm={!isMobile}
        buttonTitle={t('buttons:button.goToLogin')}
      >
        {/* <div className={styles.link_text}>
					Please download our mobile App
					<a href={process.env.REACT_APP_APPSTORE_LINK}>
						<img src={AppStore} alt="AppStore" />
					</a>
					<br />
					or
					<br />
					<a href={process.env.REACT_APP_PLAYMARKET_LINK}>
						<img src={GooglePlay} alt="GooglePlay" />
					</a>
					<br />
					<br />
				</div> */}
      </CongratsPopup>
    </Fragment>
  );
};
