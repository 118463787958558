import {useState, useEffect, FC, ChangeEvent, Dispatch, SetStateAction} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import cx from 'classnames';
//redux
import {Store} from 'redux/root';
import {selectorGetUserOrganizations} from 'redux/user-service/selector';
//types
import {ORGANISATION_ACTIVE_STATUS} from '@joc/api-gateway/lib/api-client';
import {IOrganisationResponse} from '@joc/api-gateway';
import {FieldBaseProps} from 'core/types/field';
//helpers
import {
  getFilteredOrganisations,
  getIsSelectedValueDirty,
  getPreviousOrganisationName,
  getVolunteerOrganisations,
} from './helpers';
//components
import FieldWrapper from 'shared/inputs/FieldWrapper';
//styles
import styles from './InputSearchOrg.module.scss';

const mainOrganizationId = '22';

type InputSearchOrgProps = FieldBaseProps<{
  placeholder?: string;
  isMainOrgHidden?: boolean;
  isSuspendedEnabled?: boolean;
  isClearSearch?: boolean;
  setIsClearSearch?: Dispatch<SetStateAction<boolean>>;
}>;

const InputSearchOrg: FC<InputSearchOrgProps> = ({
  isClearSearch,
  setIsClearSearch,
  placeholder,
  isMainOrgHidden,
  isSuspendedEnabled,
  ...fieldBaseProps
}) => {
  const {field, form, disabled} = fieldBaseProps;
  const {setFieldValue, setFieldTouched} = form;
  const {t} = useTranslation('common');

  const userOrganisations = useSelector((store: Store) =>
    getVolunteerOrganisations(selectorGetUserOrganizations(store))
  );

  const [inputValue, setInputValue] = useState<string>('');
  const [options, setOptions] = useState<Array<IOrganisationResponse>>([]);
  const [isOptionsOpen, setIsOptionsOpen] = useState<boolean>(false);
  const [isInitialValueAssigned, setIsInitialValueAssigned] = useState<boolean>(false);

  useEffect(() => {
    if (isClearSearch) {
      setInputValue('');
      setOptions([]);
    }
  }, [isClearSearch]);

  useEffect(() => {
    if (field.value && userOrganisations && !isInitialValueAssigned) {
      const previousValue = getPreviousOrganisationName(field.value, userOrganisations);
      if (previousValue) {
        setInputValue(previousValue);
        setIsInitialValueAssigned(true);
      }
    }
  }, [field.value, isInitialValueAssigned]);

  useEffect(() => {
    if (userOrganisations) {
      const currentUserOrganizations = isMainOrgHidden
        ? userOrganisations.filter((organization) => organization.id !== mainOrganizationId)
        : userOrganisations;

      if (inputValue) {
        const isSelectedValueDirty = getIsSelectedValueDirty(inputValue, field.value, currentUserOrganizations);
        if (isSelectedValueDirty) setFieldValue(field.name, '');

        const filteredOrganisations = getFilteredOrganisations(inputValue, currentUserOrganizations);
        setOptions(filteredOrganisations);
      } else {
        setFieldValue(field.name, '');
        setOptions(currentUserOrganizations);
      }
    }
  }, [inputValue]);

  const inputFocusHandler = () => {
    setIsOptionsOpen(true);
    setIsClearSearch?.(false);
  };

  const inputBlurHandler = () => {
    setFieldTouched(field.name, true);
    setIsOptionsOpen(false);
  };

  const inputChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    setIsClearSearch?.(false);
    setIsOptionsOpen(true);
  };

  const optionClickHandler = (option: IOrganisationResponse) => () => {
    if (isSuspendedEnabled || option.organizationActiveStatus?.status !== ORGANISATION_ACTIVE_STATUS.SUSPENDED) {
      setFieldValue(field.name, option.id);
      setInputValue(option.organizationName);
      setIsOptionsOpen(false);
      if (setFieldTouched) setFieldTouched(field.name, true);
    }
  };

  return (
    <FieldWrapper {...fieldBaseProps}>
      <div className={styles.input__search}>
        <label className={styles.input__search_icon} htmlFor={field.name} />
        <input
          id={field.name}
          type="text"
          autoComplete="off"
          value={inputValue}
          placeholder={placeholder}
          onChange={inputChangeHandler}
          onFocus={inputFocusHandler}
          onBlur={inputBlurHandler}
          disabled={disabled}
        />
        {!disabled && isOptionsOpen && (
          <div className={styles.dropWrap}>
            <div className={styles.dropContainer}>
              {options.length ? (
                options.map((option) => (
                  <div
                    key={option.id}
                    className={cx(
                      styles.dropDown,
                      !isSuspendedEnabled &&
                        option.organizationActiveStatus?.status === ORGANISATION_ACTIVE_STATUS.SUSPENDED &&
                        styles.suspended
                    )}
                    onMouseDown={optionClickHandler(option)}
                  >
                    <div className={styles.dropDown_Item}>
                      <img
                        src={`${process.env.REACT_APP_S3_API}/${option.organizationLogoPath}`}
                        alt="logo organization"
                      />
                      {option.organizationActiveStatus?.status === ORGANISATION_ACTIVE_STATUS.SUSPENDED
                        ? `${option.organizationName} | ${t('suspended')}`
                        : option.organizationName}
                    </div>
                  </div>
                ))
              ) : (
                <div className={styles.dropDown}>{t('noOrgFound')}</div>
              )}
            </div>
          </div>
        )}
      </div>
    </FieldWrapper>
  );
};

export default InputSearchOrg;
