import {Dispatch} from 'redux';
//API
import {API} from 'core/API';
//types
import {GET_VOLUNTEERS_ALL, GET_VOLUNTEERS_BY_GROUP, RESET_VOLUNTEERS, SET_ERROR} from 'redux/actions-types';
import {
  ISearchVolunteersByGroupRequest,
  ISearchVolunteersRequest,
  SearchVolunteersByGroupRequest,
  SearchVolunteersRequest,
} from '@joc/api-gateway';
//functions
import {getIsEmptyPaginationSkip} from 'core/functions';

export const getVolunteers =
  (orgId: number, searchBody: ISearchVolunteersRequest) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const volunteersResponse = await API.searchVolunteers(
        orgId.toString(),
        SearchVolunteersRequest.fromJS(searchBody)
      );

      const isPaginationSkipEmpty = getIsEmptyPaginationSkip(searchBody.pagination);
      if (isPaginationSkipEmpty) dispatch({type: RESET_VOLUNTEERS});

      dispatch({
        type: GET_VOLUNTEERS_ALL,
        payload: {records: volunteersResponse.records, total: volunteersResponse.total},
      });
    } catch (error) {
      dispatch({type: SET_ERROR, payload: {state: true, message: error?.response?.message || error.message}});
    }
  };

export const getVolunteersByGroup = (requestBody: ISearchVolunteersByGroupRequest) => async (dispatch: Dispatch) => {
  try {
    const volunteersResponse = await API.getAllVolunteersByGroup(SearchVolunteersByGroupRequest.fromJS(requestBody));

    const isPaginationSkipEmpty = getIsEmptyPaginationSkip(requestBody.pagination);
    if (isPaginationSkipEmpty) dispatch({type: RESET_VOLUNTEERS});

    dispatch({
      type: GET_VOLUNTEERS_BY_GROUP,
      payload: {records: volunteersResponse.records, total: volunteersResponse.total},
    });
    return volunteersResponse;
  } catch (error) {
    throw error;
  }
};

export const resetVolunteers =
  () =>
  (dispatch: Dispatch): void => {
    dispatch({
      type: RESET_VOLUNTEERS,
    });
  };
