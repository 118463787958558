//API
import {API} from 'core/API';
//types
import {IOrganisationTinyResponse} from '@joc/api-gateway/lib/api-client';
import {VOLUNTEER_VALUES} from 'core/types';
import {RegisterVolunteerRequest, UserClaimRequest} from '@joc/api-gateway';

export const getIsParsedOrganisationActive = (
  activeOrganisations: Array<IOrganisationTinyResponse>,
  parsedData: VOLUNTEER_VALUES
) => activeOrganisations.some((organisation) => organisation.id === parsedData.organisationId);

export const registerVolunteer = async (values: VOLUNTEER_VALUES) => {
  const valuesToCreate = {
    ...values,
    address:
      typeof values?.address === 'object' && Object.hasOwn(values?.address as object, 'text') ? null : values.address,
  };
  const requestBody = RegisterVolunteerRequest.fromJS({...valuesToCreate});

  await API.registerVolunteer(requestBody);
  localStorage.removeItem('userFirebaseInfo');
};

export const getClaimValues = ({gender, ...values}: VOLUNTEER_VALUES) => {
  if (gender) return {...values, genderId: gender};
  return values;
};

export const createClaim = async (values: VOLUNTEER_VALUES) => {
  const {organisationId, ...claimValues} = getClaimValues(values);
  const valuesToCreate = {
    ...claimValues,
    address:
      typeof values?.address === 'object' && Object.hasOwn(values?.address as object, 'text') ? null : values.address,
  };
  const requestBody = UserClaimRequest.fromJS({...valuesToCreate, organisationId: Number(organisationId)});

  await API.createClaim(requestBody);
  localStorage.removeItem('userFirebaseInfo');
};
