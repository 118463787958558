import {ChangeEvent, FC} from 'react';
import cx from 'classnames';
//styles
import styles from './index.module.scss';

interface CheckboxProps {
  changeHandle?: (checked: boolean, value?: string) => void;
  disabled?: boolean;
  required?: boolean;
  label?: string | JSX.Element;
  value?: string;
  defaultChecked?: boolean;
  classNames?: string;
  error?: boolean;
}

const Checkbox: FC<CheckboxProps> = ({
  disabled,
  required,
  changeHandle,
  label,
  value,
  defaultChecked,
  classNames,
  error,
}: CheckboxProps) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    changeHandle && changeHandle(e.target.checked, value);
  };

  return (
    <label className={cx(styles.container, classNames)}>
      <input
        type="checkbox"
        onChange={handleChange}
        required={required}
        value={value}
        disabled={disabled}
        checked={defaultChecked}
      />

      <span className={cx(styles.checkmark, error ? styles.error : '')} />
      {label}
    </label>
  );
};

export default Checkbox;
