import {FC} from 'react';
import cx from 'classnames';
//constants
import {BUTTON_ICONS} from 'core/constants';
//helpers
import {setIcon} from './helpers';
//styles
import styles from './ButtonImage.module.scss';

type ButtonImageProps = {
  clickHandler: (() => void) | undefined;
  viewStyle: string;
  disabled?: boolean;
  parentClassName?: string;
  title?: string;
};

const ButtonImage: FC<ButtonImageProps> = ({
  viewStyle,
  disabled,
  parentClassName,
  clickHandler,
  title,
}: ButtonImageProps) => {
  return (
    <button
      className={cx(styles.main, parentClassName && parentClassName, {
        [styles.heart]: viewStyle === BUTTON_ICONS.heart,
        [styles.pencil]: viewStyle === BUTTON_ICONS.pencil,
        [styles.edit]: viewStyle === BUTTON_ICONS.edit,
        [styles.share]: viewStyle === BUTTON_ICONS.share,
        [styles.trash]: viewStyle === BUTTON_ICONS.trash,
        [styles.user]: viewStyle === BUTTON_ICONS.user,
        [styles.filter]: viewStyle === BUTTON_ICONS.filter,
        [styles.eye]: viewStyle === BUTTON_ICONS.eye,
        [styles.add_image]: viewStyle === BUTTON_ICONS.add_image,
        [styles.calendar]: viewStyle === BUTTON_ICONS.calendar,
        [styles.add_volunteer]: viewStyle === BUTTON_ICONS.add_volunteer,
        [styles.send]: viewStyle === BUTTON_ICONS.send,
        [styles.clip]: viewStyle === BUTTON_ICONS.clip,
        [styles.disabled]: disabled,
      })}
      onClick={clickHandler}
      disabled={disabled}
      title={title}
    >
      <img className={styles.icon} src={setIcon(viewStyle)} alt={viewStyle} />
    </button>
  );
};

export default ButtonImage;
