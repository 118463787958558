import {FC} from 'react';
import {useTranslation} from 'react-i18next';
//formik
import {Field, Form} from 'formik';
//components
import Input from 'shared/inputs/Input';
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
import GoogleApiAutoCompleat from 'shared/components/GoogleApiAutoComplete';
import InputPhone from 'shared/components/FormInputs/InputPhone';
import Loader from 'shared/components/Loader';
//styles
import styles from './ContactSignupForm.module.scss';

type ContactSignupFormParentProps = {
  isLoading: boolean;
};

const ContactSignupForm: FC<ContactSignupFormParentProps> = ({isLoading}) => {
  const {t} = useTranslation(['inputs', 'signUpOrg', 'buttons', 'form']);

  return (
    <Form className={styles.form}>
      <Field name="organizationPhoneNumber" label={t('form:phoneNumber')} component={InputPhone} />
      <Field
        name="organizationEmail"
        label={t('form:email')}
        placeholder={t('inputs:placeholders.orgEmail')}
        component={Input}
      />
      <Field
        name="organizationWebSite"
        label={t('form:websiteAddress')}
        placeholder={t('inputs:placeholders.websiteUrl')}
        component={Input}
      />
      <Field
        name="address"
        label={t('form:address')}
        placeholder={t('inputs:placeholders.enterAddress')}
        isAddressErrorText
        isInputValidation
        component={GoogleApiAutoCompleat}
      />
      {isLoading ? (
        <Loader loadProps={{disableCenterStyle: true}} />
      ) : (
        <ButtonDefault submitType classList={['primary', 'lg']} title={t('buttons:button.confirm')} />
      )}
    </Form>
  );
};

export default ContactSignupForm;
