import {IUserResponse} from '@joc/api-gateway';
import {checkIsFullFilledData} from 'core/functions';
import update from 'immutability-helper';
import {
  ADD_PUSH_NOTIFICATION_TOKEN,
  CHANGE_NAVBAR_MODE,
  GET_USER,
  REMOVE_PUSH_NOTIFICATION_TOKEN,
  RESET_USER_DATA,
} from 'redux/actions-types';
import {IUserReducer, UserActionTypes} from './types';

const initState: IUserReducer = {
  userData: null,
  navbarHiddenMode: false,
  fullFilledData: undefined,
  // isTokenError,
};

const userReducer = (state = initState, action: UserActionTypes): IUserReducer => {
  const {type, payload}: UserActionTypes = action;
  switch (type) {
    case GET_USER: {
      const isFullFilled = checkIsFullFilledData(payload as IUserResponse);
      return {...state, userData: payload as IUserResponse, fullFilledData: isFullFilled};
    }

    // 	return update(state, { userData: { $set: payload as IUserResponse } });
    case ADD_PUSH_NOTIFICATION_TOKEN: {
      const userTokens = (payload as IUserResponse)?.notificationTokens;
      return update(state, {
        userData: {notificationTokens: {$set: userTokens}},
      });
    }
    case REMOVE_PUSH_NOTIFICATION_TOKEN:
      return {...state, userData: payload as IUserResponse};
    case RESET_USER_DATA:
      return initState;
    case CHANGE_NAVBAR_MODE:
      return {...state, navbarHiddenMode: !state.navbarHiddenMode};
    default:
      return state;
  }
};

export default userReducer;
