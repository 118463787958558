import qs from 'qs';
//urls
import {urls} from 'core/appUrls';
//constants
import {ORGANIZATION_TABS} from 'core/constants';

export const getTotalOpportunitiesLink = () => {
  const totalOpportunitiesQueryString = qs.stringify({tab: ORGANIZATION_TABS.ALL});
  return `${urls.orgOpportunities}?${totalOpportunitiesQueryString}`;
};
