import {FC} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import qs from 'qs';
//urls
import {urls} from 'core/appUrls';
//redux
import {getCurrentUser} from 'redux/user-service/actions';
//types
import {ClaimCongratulationsParsedQs} from 'core/types/queryStrings';
//components
import Appeal from 'shared/components/Appeal/DefaultRenderer';
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
//helpers
import {getSubtitleInterpolationText} from './helpers';
//styles
import styles from '../styles.module.scss';

const ClaimCongratulations: FC = () => {
  const {t} = useTranslation(['messages', 'buttons', 'form']);

  const dispatch = useDispatch();

  const history = useHistory();

  const location = useLocation();

  const parsedQs = qs.parse(location.search, {ignoreQueryPrefix: true}) as unknown as ClaimCongratulationsParsedQs;

  const mainClickHandler = () => {
    dispatch(getCurrentUser());
    history.push(urls.main);
  };

  const subtitleInterpolationText = getSubtitleInterpolationText(parsedQs);

  return (
    <Appeal styleCongrats>
      <div className={styles.content}>
        {parsedQs.wasAttachedToOrg === 'true' ? (
          <>
            <h1 className="title">{t('messages:congratulations')}</h1>
            <div className={styles.subtitle}>{t('messages:addedToOrganisation', subtitleInterpolationText)}</div>
          </>
        ) : (
          <div className={styles.subtitle}>{t('messages:youAlreadyVolunteer', subtitleInterpolationText)}</div>
        )}
        <div className={styles.button__container}>
          <ButtonDefault
            title={t('buttons:button.goToMainPage')}
            classList={['primary', 'extra_lg']}
            clickHandler={mainClickHandler}
          />
        </div>
      </div>
    </Appeal>
  );
};

export default ClaimCongratulations;
