import {FC, useState, useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import cx from 'classnames';
import qs from 'qs';
//redux
import {resetFilter, setTabFilterOrg} from 'redux/filter-service/actions';
import {selectorGetOpportunitiesCount} from 'redux/opportunities-service/selector';
import {resetOpportunities} from 'redux/opportunities-service/action';
import {resetError} from 'redux/error-service/action';
// interfaces
import {SidebarVisibility} from './interfaces';
//hooks
import useEffectNoFirstMount from 'hooks/useEffectNoFirstMount';
import {useHistory, useLocation} from 'react-router';
//context
import {OpportunitiesContextProvider} from './context';
//constants
import {ORGANIZATION_TABS} from 'core/constants';
import {DEFAULT_SCROLLABLE_CONTAINER} from 'shared/components/Buttons/ButtonUpToTop/constants';
//helpers
import {QueryPreset, getInitialTab, getTabCounter} from './helpers';
//components
import {OppoTabs} from './OppoTabs';
import {OppoSidebar} from './OppoSidebar';
import {OppoSidebarWithCalendar} from './OppoSidebarWithCalendar';
import OpportunitiesTable from 'components/Organization/Opportunities/Table';
// data
import {TABS_TITLES, initialSidebarVisibility, queryDataConfig} from './data';
//styles
import styles from './index.module.scss';

const OpportunitiesPage: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {search} = useLocation();

  const [sidebarVisibility, setSidebarVisibility] = useState<SidebarVisibility>(initialSidebarVisibility);
  const [isSelectPastDateAllowed, setIsSelectPastDateAllowed] = useState<boolean>(true);
  const [activeTab, setActiveTab] = useState<ORGANIZATION_TABS>(getInitialTab(search));
  const [isClearSearch, setIsClearSearch] = useState<boolean>(false);

  const opportunitiesCount = useSelector(selectorGetOpportunitiesCount);

  useEffectNoFirstMount(() => generalTabClickHandler(), [activeTab]);

  useEffect(() => {
    setInitFilterValue();

    return () => {
      generalTabClickHandler();
      dispatch(resetFilter());
    };
  }, []);

  const setInitFilterValue = async () => {
    const tabFilterValue = QueryPreset.get(activeTab);
    dispatch(setTabFilterOrg(tabFilterValue));
  };

  const handleReset = async () => {
    setIsClearSearch(true);
    dispatch(resetError());
    dispatch(resetOpportunities());
  };

  const generalTabClickHandler = async () => {
    handleReset();
    setIsSelectPastDateAllowed(activeTab !== ORGANIZATION_TABS.UPCOMING);
    dispatch(setTabFilterOrg(QueryPreset.get(activeTab)));
  };

  const setTabUrl = (tab: ORGANIZATION_TABS) => {
    const queryData = qs.parse(search, queryDataConfig);
    const newQueryString = qs.stringify({...queryData, tab});
    history.replace({search: newQueryString});
  };

  const handleActiveTab = (value: ORGANIZATION_TABS) => {
    setActiveTab(value);
    setTabUrl(value);
  };

  const contextValue = {
    activeTab,
    QueryPreset,
    isClearSearch,
    setIsClearSearch,
    sidebarVisibility,
    setSidebarVisibility,
    generalTabClickHandler,
    isSelectPastDateAllowed,
  };

  const tabsConfig = useMemo(() => getTabCounter(opportunitiesCount), [opportunitiesCount, getTabCounter]);

  return (
    <OpportunitiesContextProvider value={contextValue}>
      <div className={cx(styles.wrapper, DEFAULT_SCROLLABLE_CONTAINER)}>
        <div className={styles.container}>
          <h1 className="title">{TABS_TITLES.opportunities}</h1>
          <OppoTabs activeTab={activeTab} tabsConfig={tabsConfig} handleActiveTab={handleActiveTab} />
          <OpportunitiesTable />
        </div>
        <OppoSidebar />
        <OppoSidebarWithCalendar />
      </div>
    </OpportunitiesContextProvider>
  );
};

export default OpportunitiesPage;
