import {useState} from 'react';

export interface UseModalResponse<T> {
  isOpen: boolean;
  open: (data?: T) => void;
  close: () => void;
  data: T | null;
  setModalData: (data: T | null) => void;
}

export const useModal = <T extends Record<string, any>>(autoOpen = false): UseModalResponse<T> => {
  const [isOpen, setIsOpen] = useState<boolean>(autoOpen);
  const [data, setModalData] = useState<T | null>(null);

  const open = (data?: T) => {
    if (data) {
      setModalData(data);
    }

    setIsOpen(true);
  };

  const close = () => {
    setModalData(null);
    setIsOpen(false);
  };

  return {
    isOpen,
    open,
    close,
    data,
    setModalData,
  };
};
