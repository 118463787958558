import {Dispatch, FC, SetStateAction} from 'react';
import {useTranslation} from 'react-i18next';
//types
import {GroupType} from 'core/types';
//components
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
//styles
import styles from './Actions.module.scss';
import {useFormikContext} from 'formik';
import {SmartGroupFormValues} from 'components/Organization/Volunteers/SmartGroup/formConfiguration';

type ActionsProps = {
  isEditSmartGroup?: boolean;
  closeTableClickHandler: () => void;
  checkedVolunteersId: Array<number>;
  currentCheckedVolunteersId: Array<number>;
  setCheckedVolunteersId: Dispatch<SetStateAction<Array<number>>>;
};

const Actions: FC<ActionsProps> = ({
  isEditSmartGroup,
  closeTableClickHandler,
  setCheckedVolunteersId,
  checkedVolunteersId,
  currentCheckedVolunteersId,
}: ActionsProps) => {
  const {setFieldValue} = useFormikContext<SmartGroupFormValues>();

  const cancelClickHandler = () => {
    setCheckedVolunteersId(checkedVolunteersId);
    closeTableClickHandler();
  };
  const {t} = useTranslation('buttons');

  const createGroupClickHandler = () => {
    if (currentCheckedVolunteersId.length) {
      setCheckedVolunteersId(currentCheckedVolunteersId);
      const fieldName: keyof SmartGroupFormValues = 'volunteers';
      setFieldValue(fieldName, currentCheckedVolunteersId);
    }
    closeTableClickHandler();
  };

  return (
    <div className={styles.wrapper}>
      <ButtonDefault title={t('button.cancel')} classList={['secondary', 'md']} clickHandler={cancelClickHandler} />

      <ButtonDefault
        disabled={!currentCheckedVolunteersId.length}
        title={isEditSmartGroup ? 'Edit group' : t('button.createGroup')}
        classList={['primary', 'md']}
        clickHandler={createGroupClickHandler}
      />
    </div>
  );
};

export default Actions;
