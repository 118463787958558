import {BaseSyntheticEvent, FC} from 'react';
import cx from 'classnames';
//material
import {ButtonBase} from '@material-ui/core';
//styles
import styles from './index.module.scss';

interface ButtonDefaultProps {
  title: string;
  classList: string | Array<string>;
  parentClassName?: string;
  disabled?: boolean;
  submitType?: boolean;
  clickHandler?: (() => void) | ((e: BaseSyntheticEvent) => void);
}

const ButtonDefault: FC<ButtonDefaultProps> = ({
  title,
  classList,
  disabled,
  submitType,
  clickHandler,
  parentClassName,
}: ButtonDefaultProps) => {
  return (
    <ButtonBase
      type={submitType ? 'submit' : 'button'}
      className={cx(
        styles.btn_default,
        {
          [styles.primary]: classList.includes('primary'),
          [styles.orange]: classList.includes('orange'),
          [styles.grey]: classList.includes('grey'),
          [styles.submit]: classList.includes('submit'),
          [styles.secondary]: classList.includes('secondary'),
          [styles.secondary_active]: classList.includes('secondary_active'),
          [styles.publish]: classList.includes('publish'),
          [styles.lg]: classList.includes('lg'),
          [styles.extra_lg]: classList.includes('extra_lg'),
          [styles.md]: classList.includes('md'),
          [styles.sm]: classList.includes('sm'),
          [styles.nano]: classList.includes('nano'),
          [styles.extra_md]: classList.includes('extra_md'),
          [styles.center]: classList.includes('center'),
          [styles.apply]: classList.includes('apply'),
          [styles.outline_border]: classList.includes('outline_border'),
          [styles.not_active]: classList.includes('not_active'),
          [styles.no_click]: classList.includes('no_click'),
          [styles.disabled]: disabled,
          [styles.text]: classList.includes('text'),
          [styles.text_disabled]: classList.includes('text') && disabled,
        },
        parentClassName && parentClassName
      )}
      disabled={disabled}
      onClick={clickHandler}
      title={title}
    >
      {title}
    </ButtonBase>
  );
};
export default ButtonDefault;
