// react
import {useState} from 'react';
// interfaces
import {IDisabledButtonState, ITime} from 'components/TimeLimit/interfaces';
// helpers
import {periodMinute} from 'pages/Volunteers/CreateManualOppoV/ManualOpportunityMainForm/ManualOpportunityInput/helpers';
// data
import {periodHour} from 'components/TimeLimit/data';

export const useTimeLimit = () => {
  const [time, setTime] = useState<ITime>({hour: 0, minute: 0});

  const [buttonDisabledState, setButtonDisabledState] = useState<IDisabledButtonState>({
    isMinusActionHour: false,
    isPlusActionHour: false,
    isMinusActionMinute: false,
    isPlusActionMinute: false,
  });

  /**
   * Actions
   */

  const handleMinusHourAction = () => {
    if (!buttonDisabledState.isMinusActionHour) {
      setTime((prevState) => ({...prevState, hour: prevState.hour - periodHour}));
    }
  };

  const handlePlusHourAction = () => {
    if (!buttonDisabledState.isPlusActionHour) {
      setTime((prevState) => ({...prevState, hour: prevState.hour + periodHour}));
    }
  };

  const handleMinusMinuteAction = () => {
    if (!buttonDisabledState.isMinusActionMinute) {
      setTime((prevState) => ({...prevState, minute: prevState.minute - periodMinute}));
    }
  };

  const handlePlusMinuteAction = () => {
    if (!buttonDisabledState.isPlusActionMinute) {
      setTime((prevState) => ({
        hour: prevState.minute >= 45 ? prevState.hour + periodHour : prevState.hour,
        minute: prevState.minute >= 45 ? 0 : prevState.minute + periodMinute,
      }));
    }
  };

  //

  return {
    time,
    setTime,
    buttonDisabledState,
    setButtonDisabledState,
    handleMinusHourAction,
    handlePlusHourAction,
    handleMinusMinuteAction,
    handlePlusMinuteAction,
  };
};
