import {FC} from 'react';
// components
import TabNav from 'shared/components/TabNav';
import TabNavItem from 'shared/components/TabNav/TabNavItem';
// constants
import {ORGANIZATION_TABS} from 'core/constants';
// interfaces
import {ITabsConfig} from '../interfaces';

interface IOppoTabsProps {
  tabsConfig: ITabsConfig[];
  activeTab: ORGANIZATION_TABS;
  handleActiveTab: (value: ORGANIZATION_TABS) => void;
}

export const OppoTabs: FC<IOppoTabsProps> = ({tabsConfig, handleActiveTab, activeTab}) => {
  return (
    <TabNav className="oppoNav">
      {tabsConfig?.map(({title, counter, currentTab}) => {
        return (
          <TabNavItem
            key={title}
            counter={counter}
            title={title}
            activeTab={activeTab === currentTab}
            clickHandler={() => handleActiveTab(currentTab)}
          />
        );
      })}
    </TabNav>
  );
};
