import {FC, SetStateAction, Dispatch} from 'react';
import {useTranslation} from 'react-i18next';
import cx from 'classnames';
//types
import {ITariffsResponse} from '@joc/api-gateway';
//helpers
import {getPriceNumber, getPriceUnit, tariffTypesIds} from './helpers';
//components
import InputText from 'shared/components/Inputs/InputText';
//styles
import styles from './Renderers.module.scss';

type TariffPlanContentProps = {
  tariff: ITariffsResponse;
  defCountOfStudents: string;
  setDefCountOfStudents: Dispatch<SetStateAction<string>>;
};

const TariffPlanContent: FC<TariffPlanContentProps> = ({tariff, defCountOfStudents, setDefCountOfStudents}) => {
  const {t} = useTranslation('tariffPlans');

  const {tariffName, tariffCost, period, invitesAmount, id: tariffId} = tariff;

  const placeholder = t('enterStudentsAmount');
  const personOrPersons = t('personOrPersons');
  const persons = t('persons');

  const priceUnit = getPriceUnit(tariffId, defCountOfStudents, invitesAmount, period, personOrPersons, persons);

  const priceNumber = getPriceNumber(tariffCost, defCountOfStudents);

  return (
    <>
      {tariffId === tariffTypesIds.School ? (
        <>
          <div className={cx(styles.item__toping)} />
          <span className={styles.item__title}>{tariffName}</span>
          <InputText
            type="number"
            setValue={setDefCountOfStudents}
            placeholder={placeholder}
            value={`${defCountOfStudents}`}
          />

          <div className={styles.item__price}>
            <span className={styles.item__price__number}>$ {priceNumber}</span>
            <div className={styles.item__price__unit}>{priceUnit}</div>
          </div>
        </>
      ) : (
        <>
          <div className={cx(styles.item__toping)} />
          <span className={styles.item__title}>{tariffName}</span>

          <div className={styles.item__price}>
            <span className={styles.item__price__number}>$ {tariffCost}</span>
            <div className={styles.item__price__unit}>{priceUnit}</div>
          </div>
        </>
      )}
    </>
  );
};

export default TariffPlanContent;
