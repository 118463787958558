import {FC, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import cx from 'classnames';
//types
import {IBasicVolunteerResponse} from '@joc/api-gateway';
//redux
import {selectorGetSchoolId} from 'redux/organization-service/selector';
//constants
import {BUTTON_ICONS} from 'core/constants';
//components
import ButtonImage from 'shared/components/Buttons/ButtonImage';
import PopupContainer from 'shared/components/PopupContainer';
import EditVolunteerForm from 'components/Organization/Forms/EditVolunteerForm';
//styles
import styles from './styles.module.scss';

type EditVolunteerCellProps = {
  volunteer: IBasicVolunteerResponse;
};

const EditVolunteerCell: FC<EditVolunteerCellProps> = ({volunteer}) => {
  const {t} = useTranslation(['popup', 'buttons', 'tooltips']);

  const schoolId = useSelector(selectorGetSchoolId);

  const [isShowPopup, setIsShowPopup] = useState(false);

  const showPopupClickHandler = () => {
    setIsShowPopup(!isShowPopup);
  };

  const componentTitles = useMemo(
    () => ({
      button: t(`tooltips:${schoolId ? 'editStudent' : 'editVolunteer'}`),
      popup: t(`popup:volunteer.${schoolId ? 'editStudent' : 'editVolunteer'}`),
    }),
    [schoolId]
  );

  return (
    <>
      <ButtonImage
        clickHandler={showPopupClickHandler}
        viewStyle={BUTTON_ICONS.pencil}
        title={componentTitles.button}
      />
      {isShowPopup ? (
        <PopupContainer closeButtonParentClickHandler={showPopupClickHandler}>
          <h2 className={cx('title', styles.title)}>{componentTitles.popup}</h2>
          <EditVolunteerForm volunteer={volunteer} />
        </PopupContainer>
      ) : null}
    </>
  );
};

export default EditVolunteerCell;
