import {urls} from 'core/appUrls';
import {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';

const urlIsAllow = [urls.onboardingSignupVolunteer];

export const useWindowSize = () => {
  const location = useLocation();

  const [isHide, setIsHide] = useState(false);

  useEffect(() => {
    if (urlIsAllow.find((url) => url === location.pathname)) {
      setIsHide(true);
    } else {
      setIsHide(false);
    }
  }, [location]);

  return isHide;
};
