import {FC} from 'react';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import cx from 'classnames';
//styles
import styles from './CreateAccount.module.scss';

type CreateAccountParentProps = {
  linkTo: string;
  parentClassName?: string;
  disabled?: boolean;
};

const CreateAccount: FC<CreateAccountParentProps> = ({linkTo, parentClassName, disabled}: CreateAccountParentProps) => {
  const {t} = useTranslation(['login', 'buttons']);

  return (
    <div className={cx(styles.container, parentClassName && parentClassName, disabled && styles.disabled)}>
      <span className={styles.createAccount__title}>{t('login:questionAccount')}</span>
      <Link to={linkTo} className={cx('link', styles.createAccount__link)}>
        {t('buttons:button.signUp')}
      </Link>
    </div>
  );
};

export default CreateAccount;
