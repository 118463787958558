import {Dispatch} from 'redux';
import {SET_END_UPLOAD, UPDATE_PROGRESS, HIDE_UPLOAD_ALERT_INFO, SET_ERROR_UPLOAD} from 'redux/actions-types';
import {LoadingPayload} from './types';

export const updateProgress =
  (progress: LoadingPayload) =>
  (dispatch: Dispatch): void => {
    dispatch({
      type: UPDATE_PROGRESS,
      payload: progress,
    });
  };

export const endUpload =
  () =>
  (dispatch: Dispatch): void => {
    dispatch({
      type: SET_END_UPLOAD,
    });
  };

export const errorUpload =
  () =>
  (dispatch: Dispatch): void => {
    dispatch({
      type: SET_ERROR_UPLOAD,
    });
  };

export const hideUploadInfoAlert =
  () =>
  (dispatch: Dispatch): void => {
    dispatch({
      type: HIDE_UPLOAD_ALERT_INFO,
    });
  };
