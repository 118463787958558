import {useTranslation} from 'react-i18next';
import {Channel} from 'stream-chat';
import {Avatar} from 'stream-chat-react';
//redux
import {chatClient} from 'redux/chat-service/actions';
//functions
import {ePreventDefault, setUserPhotoSrc} from 'core/functions';
//types
import {VOLUNTEER_ACTIVE_STATUS} from '@joc/api-gateway';
//styles
import './ChatSearch.global.scss';
import {UsersListType} from './types';

export type ChannelProps = Channel;

type ChannelSearchResultProps = {
  setActiveChannel: any;
  member: UsersListType;
  userId: string;
};

export const ChatSearchJocApiResult = ({setActiveChannel, member, userId}: ChannelSearchResultProps) => {
  const createChannel = async () => {
    const channel = await chatClient.channel('messaging', {
      members: [member.id as string, userId as string],
      name: member.name as string,
    });
    await channel.watch();
    setActiveChannel(channel);
  };

  const {t} = useTranslation('common');

  return (
    <li
      className={`messaging-create-channel__user-result ${
        member.activeStatus === VOLUNTEER_ACTIVE_STATUS.SUSPENDED && 'suspendResultsInChat'
      }`}
      style={{justifyContent: 'flex-start'}}
      onClick={member.activeStatus === VOLUNTEER_ACTIVE_STATUS.SUSPENDED ? () => {} : createChannel}
      onMouseDown={ePreventDefault}
    >
      <Avatar image={setUserPhotoSrc(member.imagePath)} size={50} />
      <div className="messaging-create-channel__user-result__details">
        {member.activeStatus === VOLUNTEER_ACTIVE_STATUS.SUSPENDED ? `${member.name} | ${t('suspended')}` : member.name}
      </div>
    </li>
  );
};
