import {FC, Fragment, useEffect, useState} from 'react';
import cx from 'classnames';
import {useHistory} from 'react-router';
import {useDispatch, useSelector} from 'react-redux';
//translation
import {useTranslation} from 'react-i18next';
//redux
import {Store} from 'redux/root';
import {resetError} from 'redux/error-service/action';
//urls
import {urls} from 'core/appUrls';
//types
import {SIGNUP_ERROR} from 'core/types';
//components
import ArrowBackLink from 'shared/components/ArrowBackLink';
import ContactOrgSignupForm from 'components/Organization/Forms/ContactOrgSignupForm';
import ResponseFailure from 'shared/components/ResponseFailure';
import SignUpContentContainer from '../SignUpContentContainer';
//styles
import styles from './ContactOrgSignup.module.scss';

const ContactOrgSignup: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [signupError, setSignupError] = useState<SIGNUP_ERROR>(SIGNUP_ERROR.NONE);

  const {t} = useTranslation(['login', 'errors', 'validation']);

  const error = useSelector((store: Store) => store.errorRedux.error);

  const resetErrorHelper = () => {
    dispatch(resetError());
    setSignupError(SIGNUP_ERROR.NONE);
    history.push(urls.onboardingOrgSignupAboutYou);
  };

  useEffect(() => {
    if (error.code === 400) setSignupError(SIGNUP_ERROR.ORGANIZATION_NOT_FOUND);
    if (error.code === 409) setSignupError(SIGNUP_ERROR.EMAIL_ALREADY_EXISTS);
  }, [error.code]);

  return signupError === SIGNUP_ERROR.EMAIL_ALREADY_EXISTS ? (
    <ResponseFailure
      message={t('validation:emailSchema.exists')}
      buttonClickHandler={resetErrorHelper}
      buttonTitle={t('errors:tryAgain')}
      styleThink
    />
  ) : signupError === SIGNUP_ERROR.ORGANIZATION_NOT_FOUND ? (
    <ResponseFailure
      message={t('errors:orgDoesNotExist')}
      buttonClickHandler={resetErrorHelper}
      buttonTitle={t('errors:tryAgain')}
      styleThink
    />
  ) : error.state ? (
    <ResponseFailure
      message={error.message}
      buttonTitle={t('errors:tryAgain')}
      buttonClickHandler={resetErrorHelper}
      styleThink
    />
  ) : (
    <Fragment>
      <ArrowBackLink onboardingStyle />
      <SignUpContentContainer title={t('login:welcome')} subTitle={t('login:subtitle4')}>
        <div className={styles.container}>
          <div className={styles.title__wrapper}>
            <span className={cx(styles.title, 'title')}>{t('login:subtitle5')}</span>
            <span className={styles.sub_title}>{t('login:subtitle6')}</span>
            <ContactOrgSignupForm setSignupError={setSignupError} />
          </div>
        </div>
      </SignUpContentContainer>
    </Fragment>
  );
};

export default ContactOrgSignup;
