import * as Yup from 'yup';
//types
import {IGetLocationIdByAddressRequest} from '@joc/api-gateway';
//validation
import {
  firstNameSchema,
  lastNameSchema,
  emailSchema,
  phoneNotRequiredNumberSchema,
  birthNotRequiredDateSchema,
  genderNotRequiredSchema,
  notRequiredVolunteerAddressSchema,
  organisationIdSchema,
  studentIdSchema,
} from 'core/validation';

export type VolunteerInfoFormValues = {
  fireBaseUserId?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber: string;
  organisationId: string;
  birthDate: Date | null;
  gender: number | null;
  instagramLink: string;
  imagePath: string;
  address: IGetLocationIdByAddressRequest;
  studentId?: string;
  correctInfo: boolean;
  privacy: boolean;
};

export const iniitalValues: VolunteerInfoFormValues = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  birthDate: null,
  gender: null,
  instagramLink: '',
  imagePath: '',
  address: {
    countryName: '',
    stateName: '',
    cityName: '',
    streetName: '',
    buildingNumber: '',
    zipCode: '',
    pobox: '',
    longitude: 0,
    latitude: 0,
  },
  organisationId: '',
  studentId: '',
  correctInfo: false,
  privacy: false,
};

export const validationSchema = Yup.object().shape({
  firstName: firstNameSchema,
  lastName: lastNameSchema,
  email: emailSchema,
  phoneNumber: phoneNotRequiredNumberSchema,
  birthDate: birthNotRequiredDateSchema,
  gender: genderNotRequiredSchema,
  address: notRequiredVolunteerAddressSchema,
  organisationId: organisationIdSchema,
  studentId: studentIdSchema.notRequired(),
  correctInfo: Yup.boolean().isTrue().required(),
  privacy: Yup.boolean().isTrue().required(),
});
