import {ChannelMemberResponse} from 'stream-chat';
import {setUserPhotoSrc} from 'core/functions';

export {ChannelInfoIcon} from './ChannelInfoIcon';
export {ChannelSaveIcon} from './ChannelSaveIcon';
export {CloseThreadIcon} from './CloseThreadIcon';
export {CommandIcon} from './CommandIcon';
export {CreateChannelIcon} from './CreateChannelIcon';
export {EmojiIcon} from './EmojiIcon';
export {HamburgerIcon} from './HamburgerIcon';
export {LightningBoltSmall} from './LightningBoltSmall';
export {SendIcon} from './SendIcon';
export {XButton} from './XButton';
export {XButtonBackground} from './XButtonBackground';

export const getCleanImage = (member: ChannelMemberResponse) => {
  return setUserPhotoSrc(member?.user?.image as string);
};
