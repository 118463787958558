export const BUTTON_ICONS = {
  pencil: 'pencil',
  download: 'download',
  print: 'print',
  heart: 'heart',
  user: 'user',
  trash: 'trash',
  share: 'share',
  filter: 'filter',
  eye: 'eye',
  edit: 'edit',
  add_image: 'add_image',
  calendar: 'calendar',
  add_volunteer: 'add_volunteer',
  send: 'send',
  clip: 'clip',
  hashtag: 'hashtag',
};
