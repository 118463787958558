import {FC, useState} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {useHistory} from 'react-router';
//translation
import {useTranslation} from 'react-i18next';
//types
import {ORGANISATION_ACTIVE_STATUS} from '@joc/api-gateway';
//redux
import {Store} from 'redux/root';
import {selectorGetOrgActiveStatus} from 'redux/organization-service/selector';
import {selectorGetError} from 'redux/error-service/selector';
import {resetError} from 'redux/error-service/action';
//components
import WhiteContainer from 'shared/components/WhiteContainer';
import UserInfoForm from 'components/Organization/EditForms/UserInfoForm';
import ResponseFailure from 'shared/components/ResponseFailure';
import CongratsPopup from 'components/Organization/CongratsPopup';
//styles
import styles from './EditAccount.module.scss';

const EditAccount: FC<EditAccountProps> = ({error, resetError, orgStatus}: EditAccountProps) => {
  const history = useHistory();

  const [isUpdateSuccess, setIsUpdateSuccess] = useState(false);

  const {t} = useTranslation(['editAccount', 'errors', 'messages']);

  const isOrgSuspended = orgStatus?.status === ORGANISATION_ACTIVE_STATUS.SUSPENDED;

  return (
    <div className={styles.container}>
      {error.state ? (
        <ResponseFailure
          message={error.message}
          buttonClickHandler={resetError}
          buttonTitle={t('errors:checkAndTryAgain')}
          styleTable
        />
      ) : isUpdateSuccess ? (
        <CongratsPopup
          subtitle={t('messages:accountHasBeenUpdatedSuccessfully')}
          doneButtonClickHandler={() => {
            setIsUpdateSuccess(false);
            history.goBack();
          }}
        />
      ) : (
        <WhiteContainer title={t('editAccount:title')} parentClassName={styles.containerEdit}>
          <UserInfoForm setIsUpdateSuccess={setIsUpdateSuccess} isOrgSuspended={isOrgSuspended} isDisabledEmail />
        </WhiteContainer>
      )}
    </div>
  );
};

const mapStateToProps = (store: Store) => ({
  error: selectorGetError(store),
  orgStatus: selectorGetOrgActiveStatus(store),
});

const mapDispatchToProps = {
  resetError,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type EditAccountProps = ConnectedProps<typeof connector>;

export default connector(EditAccount);
