import {FC, useMemo} from 'react';
//assets
import VerifiedBadge from 'assets/image/badges/en/verified.svg';
import VerifiedBadgeHE from 'assets/image/badges/he/verified.svg';
//functions
import {getIsHebrew} from 'core/functions';
//styles
import styles from './OpportunityBadges.module.scss';

type OpportunityBadgesProps = {
  language?: string;
};

const OpportunityBadges: FC<OpportunityBadgesProps> = ({language}: OpportunityBadgesProps) => {
  const verifiedBadgeSvg = useMemo(() => (getIsHebrew(language) ? VerifiedBadgeHE : VerifiedBadge), [language]);

  return (
    <div className={styles.badges}>
      <img className={styles.badge_verified} src={verifiedBadgeSvg} alt="badge" />
    </div>
  );
};

export default OpportunityBadges;
