import {Dispatch, FC, RefObject, SetStateAction, useState} from 'react';
import axios, {AxiosResponse} from 'axios';
import {useReactToPrint} from 'react-to-print';
import {saveAs} from 'file-saver';
import {useTranslation} from 'react-i18next';
//redux
import {Store} from 'redux/root';
import {setError} from 'redux/error-service/action';
import {connect, ConnectedProps} from 'react-redux';
import {selectorGetOrgId} from 'redux/organization-service/selector';
import {selectorGetOpportunitiesRecords} from 'redux/opportunities-service/selector';
//context
import {useOpportunitiesContext} from 'pages/Organization/Opportunities/context';
//functions
import {removeEmptyProps} from 'core/functions';
//types
import {ISearchOpportunitiesRequest} from '@joc/api-gateway';
//components
import ButtonImage from 'shared/components/Buttons/ButtonImage';
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
import PopupCreate from '../../OpportunityPopups/PopupCreate';
import PopupMain from '../../OpportunityPopups/PopupMain';
//styles
import styles from './Actions.module.scss';

type ActionsParentProps = {
  printRef: RefObject<HTMLTableElement>;
  setIsPrintableTable: Dispatch<SetStateAction<boolean>>;
  isLoading: boolean;
  requestBody: ISearchOpportunitiesRequest | null;
  disabled?: boolean;
};

const Actions: FC<ActionsProps> = ({
  printRef,
  setIsPrintableTable,
  opportunitiesRecords,
  isLoading,
  orgId,
  requestBody,
  setError,
  disabled,
}: ActionsProps) => {
  const [isShowCreatePopup, setIsShowCreatePopup] = useState(false);

  const {setSidebarVisibility} = useOpportunitiesContext();

  const {t} = useTranslation(['errors', 'tooltips', 'buttons']);

  const buttonDownloadClickHandler = async () => {
    try {
      if (!orgId) throw new Error(`${t('errors:sorry')} ${t('errors:cantFindOrgId')}`);

      delete requestBody?.pagination;
      delete requestBody?.sort;
      if (!requestBody?.fullTextSearch?.value) delete requestBody?.fullTextSearch;
      requestBody = removeEmptyProps(requestBody);
      // TODO: create helper function (first arg: requestBody, second one: object, which we will add later)

      const accessToken = localStorage.getItem('accessToken');

      if (!accessToken) throw new Error(`${t('errors:sorry')} ${t('errors:cantFindAccessToken')}`);

      const opportunitiesResponse: AxiosResponse<Blob> = await axios(
        `${process.env.REACT_APP_JOC_API}/opportunities/export-opportunities-by-organisation`,
        {
          method: 'POST',
          responseType: 'blob',
          headers: {
            'x-organization-id': orgId,
            Authorization: `Bearer ${accessToken}`,
          },
          data: {...requestBody, organisationId: orgId},
        }
      );

      saveAs(opportunitiesResponse.data, `${!!requestBody?.status ? requestBody?.status : 'ALL'}_opportunities_list`);
    } catch (error) {
      setError(error?.response?.message || error.message, error.code);
    }
  };

  const calendarClickHandler = () =>
    setSidebarVisibility(({isShowCalendar}) => ({isShowFilter: false, isShowCalendar: !isShowCalendar}));

  const handlePrint = useReactToPrint({
    onBeforeGetContent: () => setIsPrintableTable(true),
    onAfterPrint: () => setIsPrintableTable(false),
    content: () => printRef.current,
  });

  return (
    <div className={styles.wrapper}>
      <ButtonImage
        clickHandler={handlePrint}
        disabled={!opportunitiesRecords.length && !isLoading}
        viewStyle="print"
        title={t('tooltips:printList')}
      />
      <ButtonImage
        clickHandler={buttonDownloadClickHandler}
        disabled={!opportunitiesRecords.length && !isLoading}
        viewStyle="download"
        title={t('tooltips:exportList')}
      />
      <ButtonImage
        clickHandler={calendarClickHandler}
        disabled={!opportunitiesRecords.length && !isLoading}
        viewStyle="calendar"
        title={t('tooltips:calendar')}
      />
      <ButtonDefault
        title={t('buttons:button.create')}
        classList={['primary', 'sm']}
        clickHandler={() => setIsShowCreatePopup(true)}
        disabled={disabled}
      />
      {isShowCreatePopup && (
        <PopupMain setIsShowPopup={setIsShowCreatePopup}>
          <PopupCreate setIsShowPopup={setIsShowCreatePopup} />
        </PopupMain>
      )}
    </div>
  );
};

const mapStateToProps = (store: Store) => ({
  opportunitiesRecords: selectorGetOpportunitiesRecords(store),
  orgId: selectorGetOrgId(store),
});

const mapDispatchToProps = {
  setError,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type ActionsProps = ConnectedProps<typeof connector> & ActionsParentProps;

export default connector(Actions);
