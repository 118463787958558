import {FC, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
//types
import {FieldBaseProps} from 'core/types';
//form configuration
import {SmartGroupFormValues} from '../../formConfiguration';
//helpers
import {getAgeOptions} from './helpers';
//components
import FieldWrapper from 'shared/inputs/FieldWrapper';
import SelectBase from 'shared/inputs/Select/SelectBase';
//styles
import styles from '../../styles.module.scss';

type AgeControlProps = FieldBaseProps<{}, SmartGroupFormValues['ages']>;

const AgeControl: FC<AgeControlProps> = (fieldBaseProps) => {
  const {field, form} = fieldBaseProps;
  const {setFieldValue, setFieldTouched} = form;
  const {min, max} = field.value || {};

  const {t} = useTranslation(['messages', 'inputs']);

  const {minOptions, maxOptions} = useMemo(() => getAgeOptions(field.value), [field.value]);

  const conrolBlurHandler = () => {
    setFieldTouched(field.name, true);
  };

  const minChangeHandler = (value: string) => {
    conrolBlurHandler();
    setFieldValue(field.name, {...field.value, min: +value});
  };

  const maxChangeHandler = (value: string) => {
    conrolBlurHandler();
    setFieldValue(field.name, {...field.value, max: +value});
  };

  return (
    <FieldWrapper {...fieldBaseProps}>
      <div className={styles.control__age}>
        <SelectBase
          value={min?.toString()}
          placeholder={t('inputs:placeholders.minAge')}
          options={minOptions}
          onBlur={conrolBlurHandler}
          onChange={minChangeHandler}
        />
        <span>{t('messages:to')}</span>
        <SelectBase
          value={max?.toString()}
          placeholder={t('inputs:placeholders.maxAge')}
          options={maxOptions}
          onBlur={conrolBlurHandler}
          onChange={maxChangeHandler}
        />
      </div>
    </FieldWrapper>
  );
};

export default AgeControl;
