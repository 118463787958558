//urls
import {urls} from 'core/appUrls';
//functions
import {getDynamicLink} from 'core/functions';

export const getInviteLink = async (organizationId: number) => {
  const url = `${process.env.REACT_APP_MAIN_URL}${urls.onboardingClaimJoin}?organisationId%3D${organizationId}`;
  const {shortDynamicLink} = await getDynamicLink(url);
  return shortDynamicLink;
};
