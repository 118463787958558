import {FC} from 'react';
//translation
import {useTranslation} from 'react-i18next';
//components
import ArrowBackLink from 'shared/components/ArrowBackLink';
import WhiteContainer from 'shared/components/WhiteContainer';
import PolicyInfo from './PolicyInfo';
//styles
import styles from './Policy.module.scss';

const Policy: FC = () => {
  const {t} = useTranslation('policy');

  return (
    <>
      <ArrowBackLink parentClassName={styles.nav} />
      <WhiteContainer title={t('title')}>
        <PolicyInfo />
      </WhiteContainer>
    </>
  );
};
export default Policy;
