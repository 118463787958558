import {FC, useState, useRef, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
//types
import {IDateRange} from '@joc/api-gateway';
//components
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
import StatitsicsFilterForm from './StatisticsFilterForm';
//styles
import styles from './StatisticsFilter.module.scss';

type StatisticsFilterProps = {
  dateRange: IDateRange;
};

const StatisticsFilter: FC<StatisticsFilterProps> = ({dateRange}) => {
  const [isShowFilterPopup, setIsShowFilterPopup] = useState(false);
  const [isClosePopup, setIsClosePopup] = useState(false);

  const formRef = useRef<HTMLDivElement>(null);

  const {t} = useTranslation('statistics');

  const closePopupClickHandler = () => {
    setIsClosePopup(true);
    setTimeout(() => {
      setIsShowFilterPopup(false);
      setIsClosePopup(false);
    }, 200);
  };

  const handleClickOutside = (event: Event) => {
    if (formRef.current && !formRef.current.contains(event.target as Node)) {
      // closePopupClickHandler();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => document.removeEventListener('click', handleClickOutside, true);
  }, [isClosePopup]);

  return (
    <div ref={formRef} className={styles.main}>
      <ButtonDefault
        clickHandler={() => {
          setIsClosePopup(false);
          setIsShowFilterPopup(true);
        }}
        title={t('changeDateRange')}
        classList={['primary', 'md']}
      />
      {isShowFilterPopup && (
        <StatitsicsFilterForm
          closePopupClickHandler={closePopupClickHandler}
          isClosePopup={isClosePopup}
          dateRange={dateRange}
        />
      )}
    </div>
  );
};

export default StatisticsFilter;
