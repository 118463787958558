// react
import {MouseEvent, FC, useState} from 'react';
import {useTranslation} from 'react-i18next';
// components
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
// mui
import Menu from '@material-ui/core/Menu';
import {makeStyles} from '@material-ui/core/styles';
// icons
import {ReactComponent as ChatIcon} from 'assets/icons/chat.svg';
import {ReactComponent as CopyIcon} from 'assets/icons/copy.svg';
// data
import {menuItemText} from './data';
//styles
import styles from './index.module.scss';

const useStyles = makeStyles({
  paper: {
    boxShadow: 'none',
    border: '1px solid rgba(75, 94, 245, 0.5)',
    borderRadius: '10px',
  },
  list: {
    maxWidth: '150px',
    width: '100%',
    height: '100%',
    paddingTop: '0px',
    paddingBottom: '0px',
  },
  root: {
    minHeight: '32px',
    display: 'flex',
    alignItems: 'center',
  },
});

interface IUserInfoPopupProps {
  userFullName: string;
  isSuspended?: boolean;
  userPhoneNumber?: string;
  handleClose: () => void;
  anchorEl: HTMLElement | null;
  handleCreateChat: () => void;
  handleClick: (event: MouseEvent<HTMLButtonElement>) => void;
}

export const UserInfoPopup: FC<IUserInfoPopupProps> = ({
  anchorEl,
  handleClose,
  handleClick,
  userFullName,
  userPhoneNumber,
  handleCreateChat,
}) => {
  const classes = useStyles();
  const {t} = useTranslation(['tooltips', 'common', 'messages', 'errors', 'buttons']);

  const [isCopied, SetIsCopied] = useState<boolean>(false);

  const handleCopy = () => {
    const cb = navigator.clipboard;
    cb.writeText(userPhoneNumber !== undefined ? userPhoneNumber.toString() : t('errors:errorSimple'));
    SetIsCopied(true);
    setTimeout(() => SetIsCopied(false), 500);
  };

  return (
    <div>
      <Button
        title={menuItemText.contact}
        className={styles.outlined}
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        {menuItemText.contact}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        onClose={handleClose}
        open={Boolean(anchorEl)}
        classes={{
          paper: classes.paper,
          list: classes.list,
        }}
      >
        <div>
          {userPhoneNumber && (
            <MenuItem
              className={styles.menu__item}
              onClick={handleCopy}
              classes={{
                root: classes.root,
              }}
            >
              <>
                {!isCopied ? (
                  <>
                    <div className={styles.copy} title={menuItemText.copyToClipboard}>
                      <CopyIcon className={styles.copy__icon} />
                    </div>
                    <span className={styles.copy__text}>{userPhoneNumber}</span>
                  </>
                ) : (
                  <span className={styles.copy__text}>{menuItemText.copied}</span>
                )}
              </>
            </MenuItem>
          )}
          <MenuItem
            className={styles.menu__item}
            onClick={handleCreateChat}
            classes={{
              root: classes.root,
            }}
          >
            <ChatIcon className={styles.chat__icon} />
            <span className={styles.chat__text}>{userFullName}</span>
          </MenuItem>
        </div>
      </Menu>
    </div>
  );
};
