import moment from 'moment';
//translate
import i18n from 'components/translate';
//types
import {IUserResponse, OrganisationResponse, UserOrganizationsResponse, VOLUNTEER} from '@joc/api-gateway';
//constants
import {VOLUNTEER_BADGE_DATE_DIFF} from 'core/constants';

export const calculateUserAge = (birthday: Date): string => {
  const age = Math.abs(moment().diff(moment(birthday), 'years')).toString();
  return +age ? age : i18n.t('common:noAge');
};

export const generateGenderName = (genderName: string | undefined): string =>
  genderName ? genderName.slice(0, 1).toUpperCase() : i18n.t('volunteers:noData');

export const checkIsFullFilledData = (userData: IUserResponse) =>
  userData.role?.roleName !== VOLUNTEER.VOLUNTEER
    ? undefined
    : !!(
        userData.address?.latitude &&
        userData.address?.longitude &&
        userData.birthDate &&
        userData.firstName &&
        userData.lastName &&
        userData.email &&
        userData.phoneNumber
      );

export const sortUserOrganisation = (
  userOrganizationsWithRoles: UserOrganizationsResponse[]
): OrganisationResponse[] => {
  const sortedArray = userOrganizationsWithRoles.reduce((accumulator, currentValue) => {
    !accumulator.some((i) => i.id === currentValue.organizationId) &&
      currentValue?.position?.organizationRoleName !== 'Volunteer' &&
      accumulator.push(currentValue.organization!);
    return accumulator.sort((a, b) => {
      if (a.organizationName.toUpperCase() < b.organizationName.toUpperCase()) return -1;
      if (a.organizationName.toUpperCase() > b.organizationName.toUpperCase()) return 1;
      return 0;
    });
  }, [] as OrganisationResponse[]);
  return sortedArray;
};

export const isShowNewBadge = (userCreatedDate: Date): boolean => {
  const currentDate = moment();
  return moment(currentDate).diff(moment(userCreatedDate), 'days') <= VOLUNTEER_BADGE_DATE_DIFF;
};

export const setFullName = (firstName: string | undefined, lastName: string | undefined): string => {
  let fullName = '';
  if (firstName) fullName += firstName;
  if (lastName) fullName += ` ${lastName}`;
  return fullName;
};
