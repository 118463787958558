import {FC, useRef} from 'react';
//formik
import {FieldInputProps} from 'formik';
//styles
import styles from './index.module.scss';

type InputTextProps = {
  field: FieldInputProps<string>;
  placeholder?: string;
  isDisabled?: boolean;
  id?: string;
  fieldValue?: string;
  onBlur?: (value: string) => void;
};

const InputText: FC<InputTextProps> = ({placeholder, isDisabled, field, id, fieldValue, onBlur}: InputTextProps) => {
  const ref = useRef<HTMLInputElement>(null);

  const setFocus = (): void => {
    if (ref && ref.current) {
      ref.current.focus();
    }
  };

  const customOnBlur = (e: any) => {
    onBlur && onBlur(field.value);
    field && field.onBlur(e);
  };

  return (
    <div className={styles.input__text}>
      <input
        id={id && id}
        type="text"
        placeholder={placeholder || ''}
        {...field}
        onBlur={customOnBlur}
        ref={ref}
        disabled={isDisabled}
        value={fieldValue}
      />
      {field && !!field.value && !isDisabled && <div className={styles.input__text_edit} onClick={setFocus} />}
    </div>
  );
};

export default InputText;
