import {FC, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
//moment
import MomentUtils from '@date-io/moment';
import moment, {Moment} from 'moment';
import 'moment/locale/he';
import cx from 'classnames';
//material
import {MaterialUiPickersDate} from '@material-ui/pickers/typings/date';
import {ThemeProvider} from '@material-ui/core';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import {muiDateTinePickerTheme} from '../MUI_THEME';
//images
import {ReactComponent as ArrowSvg} from 'assets/image/button-arrow.svg';
//types
import {FieldBaseProps} from 'core/types';
//helpers
import {getDateValue, InlineDatePickerFieldChange} from './helpers';
//components
import FieldWrapper from 'shared/inputs/FieldWrapper';
//styles
import styles from './InlineDatePicker.module.scss';

type InlineDatePickerPropsType = FieldBaseProps<{
  isReadOnlyInput?: boolean;
  availablePast?: boolean;
  availableFuture?: boolean;
  placeholder?: string;
  fieldChange?: InlineDatePickerFieldChange;
}>;

const InlineDatePicker: FC<InlineDatePickerPropsType> = ({
  isReadOnlyInput = false,
  availablePast,
  availableFuture,
  placeholder,
  fieldChange,
  ...fieldBaseProps
}) => {
  const {field, form, disabled, parentClassName} = fieldBaseProps;
  const {setFieldValue, setFieldTouched} = form;
  const {t, i18n} = useTranslation('inputs');

  const value = useMemo(() => getDateValue(field.value), [field.value]);

  const pickerChangeHandler = (date: MaterialUiPickersDate) => {
    pickerBlurHandler();
    setFieldValue(field.name, date as unknown as Moment);
  };

  const pickerBlurHandler = () => {
    setFieldTouched(field.name, true);
  };

  const locale = i18n.language;
  return (
    <FieldWrapper {...fieldBaseProps}>
      <div className={cx(styles.datepicker, parentClassName && parentClassName)}>
        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={locale}>
          <ThemeProvider theme={muiDateTinePickerTheme}>
            <KeyboardDatePicker
              autoOk
              animateYearScrolling
              variant="inline"
              openTo="date"
              value={value}
              className={cx(!value && styles.datePicker__emptyLabel, isReadOnlyInput && styles.readOnly__input)}
              onChange={pickerChangeHandler}
              onBlur={pickerBlurHandler}
              placeholder={placeholder || t('placeholders.selectDate')}
              disablePast={!availablePast}
              disableFuture={!availableFuture}
              format="MM/DD/yyyy"
              helperText=""
              disabled={disabled}
              rightArrowIcon={
                <ArrowSvg
                  style={{width: '6px', height: '12px', transform: 'rotate(180deg)'}}
                  className={styles.button_arrow}
                />
              }
              leftArrowIcon={<ArrowSvg style={{width: '16px', height: '12px'}} className={styles.button_arrow} />}
              InputProps={{readOnly: isReadOnlyInput}}
            />
          </ThemeProvider>
        </MuiPickersUtilsProvider>
      </div>
    </FieldWrapper>
  );
};

export default InlineDatePicker;
