import React, {FC, useEffect, useRef, useState} from 'react';
import cx from 'classnames';
//redux
import {Store} from 'redux/root';
import {connect, ConnectedProps} from 'react-redux';
import {addOrgVideoCountWatch} from 'redux/organization-service/actions';
import {selectorGetOrgId} from 'redux/organization-service/selector';
//helpers
import {getSafariPreviewSupportLink} from './helpers';
//components
import Loader from '../Loader';
//styles
import styles from './VideoPreviewSquare.module.scss';

type VideoPreviewSquareParentProps = {
  parrentClassName?: string;
  link: string;
  setVideoTime?: React.Dispatch<React.SetStateAction<number>>;
  videoId?: number;
  smallerActionButton?: boolean;
};
const VideoPreviewSquare: FC<VideoPreviewSquareProps> = ({
  parrentClassName,
  link,
  setVideoTime,
  videoId,
  addOrgVideoCountWatch,
  orgId,
  smallerActionButton,
}: VideoPreviewSquareProps) => {
  const [isShowPlayButton, setIsShowPlayButton] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  const videoLink = getSafariPreviewSupportLink(link);

  const videoClickHandler = () => {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play();
        setIsShowPlayButton(false);
        videoId && !orgId && addOrgVideoCountWatch(videoId);
      } else {
        videoRef.current.pause();
        setIsShowPlayButton(true);
      }
    }
  };

  useEffect(() => {
    if (videoRef.current && setVideoTime && isShowPlayButton) {
      videoRef.current.onloadstart = () => setIsLoading(true);

      videoRef.current.onloadedmetadata = () => {
        const duration = videoRef.current?.duration;
        duration && setVideoTime(duration);
        setIsLoading(false);
      };
    }
  }, [videoRef.current, isLoading]);

  return (
    <div className={cx(parrentClassName && parrentClassName, styles.video__wrapper)}>
      {isLoading && <Loader loadProps={{stylePageCenter: true}} />}
      <video
        className={styles.player}
        ref={videoRef}
        onClick={videoClickHandler}
        muted
        src={videoLink}
        onEnded={() => setIsShowPlayButton(true)}
      />
      {isShowPlayButton && !isLoading && (
        <div
          onClick={videoClickHandler}
          className={smallerActionButton ? styles.smaller : styles.video__actionButton}
        />
      )}
    </div>
  );
};

const mapStateToProps = (store: Store) => ({
  orgId: selectorGetOrgId(store),
});
const mapDispatchToProps = {
  addOrgVideoCountWatch,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type VideoPreviewSquareProps = ConnectedProps<typeof connector> & VideoPreviewSquareParentProps;

export default connector(VideoPreviewSquare);
