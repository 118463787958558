//form configuration
import {REPEAT_OPPO_ENDS_TYPE, RepeatOpportunityModalFormValues} from './formConfiguration';
//types
import {IRepeatableOpportunityRequest, RECURRENCE_FREQUENCY, RECURRENCE_WEEK_DAYS, WEEKDAYS} from '@joc/api-gateway';
//helpers
import {DAY, MONTH, RecurrenceLabel, WEEK} from './RepeatOpportunityForm/RepeatsEvery/helpers';

export const getRepeatOpportunityModalFormValues = (
  values: RepeatOpportunityModalFormValues
): IRepeatableOpportunityRequest => {
  const {frequency, interval, endDate, amount, weekDays, endsType} = values;

  const newFrequency =
    frequencyOptions.find((option) => option?.label === frequency)?.value || RECURRENCE_FREQUENCY.WEEKLY;

  const newWeekDays: Array<RECURRENCE_WEEK_DAYS> | undefined =
    weekDays && weekDays.length >= 1 && frequency === WEEK ? convertWeekDaysToRecurrenceWeeksDays(weekDays) : undefined;

  const newEndDate = endsType === REPEAT_OPPO_ENDS_TYPE.ON ? endDate : undefined;

  const newAmount = endsType === REPEAT_OPPO_ENDS_TYPE.AFTER ? amount : undefined;

  return {
    frequency: newFrequency,
    interval,
    endDate: newEndDate,
    amount: newAmount,
    daysOfWeek: newWeekDays,
  };
};

type RecurrenceOption = {
  label: RecurrenceLabel;
  value: RECURRENCE_FREQUENCY;
};

const frequencyOptions: Array<RecurrenceOption> = [
  {label: DAY, value: RECURRENCE_FREQUENCY.DAILY},
  {label: WEEK, value: RECURRENCE_FREQUENCY.WEEKLY},
  {label: MONTH, value: RECURRENCE_FREQUENCY.MONTHLY},
];

export const getRecurrenceValues = (values: IRepeatableOpportunityRequest): RepeatOpportunityModalFormValues => {
  const {frequency, interval, endDate, amount, daysOfWeek} = values;

  const newFrequency = frequencyOptions.find((option) => option?.value === frequency)?.label || WEEK;

  const weekDays =
    daysOfWeek && daysOfWeek.length >= 1 && newFrequency === WEEK
      ? convertRecurrenceWeeksDaysToWeekDays(daysOfWeek)
      : undefined;

  const endsType = !!endDate
    ? REPEAT_OPPO_ENDS_TYPE.ON
    : !!amount
    ? REPEAT_OPPO_ENDS_TYPE.AFTER
    : REPEAT_OPPO_ENDS_TYPE.NONE;

  return {frequency: newFrequency, interval, endDate, amount, weekDays, endsType};
};

export function convertWeekDaysToRecurrenceWeeksDays(daysOfWeek: Array<WEEKDAYS>): Array<RECURRENCE_WEEK_DAYS> {
  return daysOfWeek.map((day) => {
    switch (day) {
      case WEEKDAYS.MONDAY:
        return RECURRENCE_WEEK_DAYS.MO;

      case WEEKDAYS.TUESDAY:
        return RECURRENCE_WEEK_DAYS.TU;

      case WEEKDAYS.WEDNESDAY:
        return RECURRENCE_WEEK_DAYS.WE;

      case WEEKDAYS.THURSDAY:
        return RECURRENCE_WEEK_DAYS.TH;

      case WEEKDAYS.FRIDAY:
        return RECURRENCE_WEEK_DAYS.FR;

      case WEEKDAYS.SATURDAY:
        return RECURRENCE_WEEK_DAYS.SA;

      case WEEKDAYS.SUNDAY:
        return RECURRENCE_WEEK_DAYS.SU;

      default:
        return RECURRENCE_WEEK_DAYS.MO;
    }
  });
}

export function convertRecurrenceWeeksDaysToWeekDays(
  daysOfWeek: Array<RECURRENCE_WEEK_DAYS> | undefined
): Array<WEEKDAYS> {
  return (
    daysOfWeek?.map((day) => {
      switch (day) {
        case RECURRENCE_WEEK_DAYS.MO:
          return WEEKDAYS.MONDAY;

        case RECURRENCE_WEEK_DAYS.TU:
          return WEEKDAYS.TUESDAY;

        case RECURRENCE_WEEK_DAYS.WE:
          return WEEKDAYS.WEDNESDAY;

        case RECURRENCE_WEEK_DAYS.TH:
          return WEEKDAYS.THURSDAY;

        case RECURRENCE_WEEK_DAYS.FR:
          return WEEKDAYS.FRIDAY;

        case RECURRENCE_WEEK_DAYS.SA:
          return WEEKDAYS.SATURDAY;

        case RECURRENCE_WEEK_DAYS.SU:
          return WEEKDAYS.SUNDAY;

        default:
          return WEEKDAYS.MONDAY;
      }
    }) || []
  );
}
