import {Dispatch, FC, SetStateAction, useContext, useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router';
import qs from 'qs';
//images
import brokenImage from 'assets/image/broken-image.png';
import doneImage from 'assets/image/done.svg';
//redux
import {changeStatusManualOpportunity} from 'redux/opportunities-service/action';
import {selectorGetSchoolId} from 'redux/organization-service/selector';
//urls
import {urls} from 'core/appUrls';
//types
import {IOpportunityResponse, OPPORTUNITY_STATUSES} from '@joc/api-gateway';
//functions
import {getDurationDetails, setFullName, setUserPhotoSrc, getTextDirection} from 'core/functions';
import {PastOppoContext} from 'components/Organization/Volunteers/VolunteersPopups/VolunteerPastOpportunitiesPopup';
//components
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
import ButtonConfirmCircle from 'shared/components/Buttons/ButtonActionCircle/ButtonConfirmCircle';
import ButtonRejectCircle from 'shared/components/Buttons/ButtonActionCircle/ButtonRejectCircle';
import VideoPreviewSquare from 'shared/components/VideoPreviewSquare';
import ImageWithPopup from 'shared/components/ImageWithPopup';
// helpers
import {getOppoDate, getTheFileType} from './helpers';
//styles
import styles from './ManualOpportunityDetails.module.scss';

type ManualOpportunityDetailsPopupProps = {
  setIsShowUpdateOpportunityPopup: Dispatch<SetStateAction<boolean>>;
  opportunityData: IOpportunityResponse;
  createMode?: boolean;
};

const ManualOpportunityDetailsPopup: FC<ManualOpportunityDetailsPopupProps> = ({
  opportunityData,
  setIsShowUpdateOpportunityPopup,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isPast = useContext(PastOppoContext);
  const {t, i18n} = useTranslation(['popup', 'svgTexts', 'tooltips', 'form']);

  const textDirection = getTextDirection(i18n.language);

  const isSchool = !!useSelector(selectorGetSchoolId);

  const isAdmin = useMemo(
    () =>
      !!(opportunityData.volunteers?.length === 1 && opportunityData.volunteers[0].userId !== opportunityData.user?.id),
    [opportunityData]
  );

  const handleCreateChat = () => {
    const user = isAdmin ? opportunityData?.volunteers?.[0] : opportunityData.user;

    if (user && Object.keys(user).length) {
      const queryString = qs.stringify({
        members: [
          {
            chatId: user?.chatId,
            chatName: setFullName(user?.firstName, user?.lastName),
          },
        ],
        type: 'all',
      });
      history.push(`${urls.orgInbox}?${queryString}`);
    }
  };

  const handleConfirm = () => {
    dispatch(changeStatusManualOpportunity(opportunityData.id, OPPORTUNITY_STATUSES.OPEN));
    setIsShowUpdateOpportunityPopup(false);
  };

  const handleReject = () => dispatch(changeStatusManualOpportunity(opportunityData.id, OPPORTUNITY_STATUSES.CANCELED));

  const fileType = useMemo(() => getTheFileType(opportunityData.filePathes), [opportunityData.filePathes]);
  const totalHours = useMemo(() => getDurationDetails(opportunityData.duration, 'long'), [opportunityData.duration]);
  const oppoDate = useMemo(
    () => getOppoDate(opportunityData.startDate, opportunityData.endDate, opportunityData.duration),
    [opportunityData.startDate, opportunityData.endDate, opportunityData.duration]
  );

  return (
    <>
      <div className={styles.caption}>
        <h4 className={styles.title}>{opportunityData.opportunityName}</h4>
        <div className={styles.divider} />
        <h4 className={styles.subtitle}>{`${opportunityData.user?.firstName} ${opportunityData.user?.lastName}`}</h4>
      </div>
      <div className={styles.images}>
        {opportunityData.filePathes.length ? (
          opportunityData.filePathes?.map((photo, i) => (
            <div key={photo} className={styles.image_container}>
              {fileType[i].toLowerCase() === 'mp4' ? (
                <VideoPreviewSquare link={`${process.env.REACT_APP_S3_API}/${photo}`} smallerActionButton />
              ) : (
                <ImageWithPopup
                  alt="uploaded file"
                  classNames={styles.image}
                  imagePath={photo}
                  popupTitle={t('popup:opportunity.pictureOfOppo')}
                  disableZeroTop
                />
              )}
            </div>
          ))
        ) : (
          <div className={styles.image_container}>
            <img alt="broken" className={styles.broken_image} src={brokenImage} />
          </div>
        )}
      </div>
      {opportunityData.tagId && opportunityData.tag && (
        <p className={styles.caption__hashtag}>{`#${opportunityData.tag}`}</p>
      )}
      <span className={styles.mainContent__subtitle}>{`${t('form:opportunity.description')}:`}</span>
      <div
        dir={textDirection}
        className={styles.subtitle__description}
        dangerouslySetInnerHTML={{__html: opportunityData.opportunityDescription.trim()}}
      />
      <div className={styles.details_container}>
        <div className={styles.main_data}>
          <span className={styles.main_data__label}>{t('popup:manualOppDetails.date')}</span>
          <span className={styles.main_data__text}>{oppoDate}</span>
        </div>
        <div className={styles.main_data}>
          <span className={styles.main_data__label}>{t('popup:manualOppDetails.duration')}</span>
          <span className={styles.main_data__text}>{totalHours}</span>
        </div>
        {opportunityData.recipientName && (
          <div className={styles.main_data}>
            <span className={styles.main_data__label}>{t('popup:manualOppDetails.recipientName')}</span>
            <span className={styles.main_data__text}>{opportunityData.recipientName}</span>
          </div>
        )}
        {opportunityData.recipientEmail && (
          <div className={styles.main_data}>
            <span className={styles.main_data__label}>{t('popup:manualOppDetails.recipientEmail')}</span>
            <span className={styles.main_data__text}>{opportunityData.recipientEmail}</span>
          </div>
        )}
        {opportunityData.recipientPhoneNumber && (
          <div className={styles.main_data}>
            <span className={styles.main_data__label}>{t('popup:manualOppDetails.recipientPhoneNumber')}</span>
            <span className={styles.main_data__text}>{opportunityData.recipientPhoneNumber}</span>
          </div>
        )}
      </div>
      <div className={styles.bottom}>
        {opportunityData.recipientSignatureImage && (
          <div className={styles.signature}>
            <img
              alt="signature"
              className={styles.signature__image}
              src={setUserPhotoSrc(opportunityData.recipientSignatureImage)}
            />
            <div className={styles.signature__description}>
              <img src={doneImage} alt="verified" />
              <span>{t('svgTexts:badges.verified')}</span>
            </div>
          </div>
        )}
        <div className={styles.actions}>
          {opportunityData?.status === OPPORTUNITY_STATUSES.PENDING && (
            <div className={styles.actions__confirmation}>
              <ButtonConfirmCircle clickHandler={handleConfirm} title={t('tooltips:confirm')} larger />
              <ButtonRejectCircle clickHandler={handleReject} title={t('tooltips:reject')} larger />
            </div>
          )}
          <ButtonDefault
            parentClassName={isPast ? styles.full__width : ''}
            title={t(`popup:manualOppDetails.${isSchool ? 'contactStudent' : 'contactVolunteer'}`)}
            classList={['primary', 'lg']}
            clickHandler={handleCreateChat}
          />
        </div>
      </div>
    </>
  );
};

export default ManualOpportunityDetailsPopup;
