import qs from 'qs';
//types
import {
  AgesRange,
  HowFar,
  IClaimSort,
  IFullTextSearchClaim,
  IFullTextSearchOpportunity,
  IFullTextSearchVolunteers,
  IGetLocationAddressByIdResponse,
  ISearchOpportunitiesByGuestRequest,
  ISearchOpportunitiesRequest,
  ISearchOpportunitiesSort,
  ISearchVolunteersByGroupRequest,
  ISearchVolunteersRequest,
  ISearchVolunteersSort,
} from '@joc/api-gateway';
import {metersInAMile} from 'core/constants';
import {IFullTextSearchUsersForAdmin} from '@joc/api-gateway/lib/api-client';

type NewParams =
  | IFullTextSearchVolunteers
  | IFullTextSearchOpportunity
  | ISearchOpportunitiesSort
  | ISearchVolunteersSort
  | IFullTextSearchClaim
  | IClaimSort
  | IFullTextSearchUsersForAdmin;

export const setSearchOrSortQueryString = (queryString: string, newParams: NewParams, searchBy: string): string => {
  const searchParams = qs.parse(queryString, {
    ignoreQueryPrefix: true,
  });
  const newQueryParams = {...searchParams, [searchBy]: {...newParams}};
  return qs.stringify(newQueryParams);
};

export const parseQueryStringVolunteersSearch = (
  search: string,
  userLocation: IGetLocationAddressByIdResponse | undefined
): ISearchVolunteersRequest | ISearchVolunteersByGroupRequest => {
  let searchParams: ISearchVolunteersRequest | ISearchVolunteersByGroupRequest = qs.parse(search, {
    ignoreQueryPrefix: true,
  });
  const groupId = (searchParams as ISearchVolunteersByGroupRequest).groupId;
  const genderId = (searchParams as ISearchVolunteersRequest).genderId;
  const howFar = (searchParams as ISearchVolunteersRequest).howFar;
  const ages = (searchParams as ISearchVolunteersRequest).ages;
  const createdDate = (searchParams as ISearchVolunteersRequest).createdDate;

  if (ages)
    searchParams = {
      ...searchParams,
      ages: ages.map((ageRange) => {
        return AgesRange.fromJS({min: +ageRange.min, max: +ageRange.max});
      }),
    };

  if (groupId) searchParams = {...searchParams, groupId: +groupId};

  if (genderId) searchParams = {...searchParams, genderId: +genderId};

  if (createdDate) searchParams = {...searchParams};

  if (howFar)
    searchParams = {
      ...searchParams,
      howFar: HowFar.fromJS({
        latitude: userLocation?.latitude || 0,
        longitude: userLocation?.longitude || 0,
        distance: +howFar * 1609.524, // to mile
      }),
    };
  return searchParams;
};

export const parseFilterParamsQueryString = (
  search: string,
  userAddress: IGetLocationAddressByIdResponse | undefined
): ISearchOpportunitiesByGuestRequest | ISearchOpportunitiesRequest => {
  const searchParams: any = qs.parse(search, {ignoreQueryPrefix: true});
  if (searchParams.howFar && userAddress)
    searchParams.howFar = {
      latitude: userAddress.latitude,
      longitude: userAddress.longitude,
      distance: searchParams.howFar * metersInAMile,
    };
  if (searchParams.organisationId) {
    searchParams.organisationId = +searchParams.organisationId;
  }
  // eslint-disable-next-line no-unneeded-ternary
  if (Object.keys(searchParams).includes('showVacancies') && searchParams.showVacancies === 'true')
    searchParams.showVacancies = true;
  if (searchParams.isVirtual) searchParams.isVirtual = !!searchParams.isVirtual;
  if (!searchParams.endDate) delete searchParams.endDate;
  if (searchParams.fromDate && searchParams.toDate)
    searchParams.startDay = {
      fromDate: searchParams.fromDate,
      toDate: searchParams.toDate,
    };
  return searchParams;
};

export const convertSearchToObject = (search: string) => {
  return Object.fromEntries(
    search
      .slice(1)
      .split('&')
      .map((param) => param.split('='))
  );
};
