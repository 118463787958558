import {ChangeEvent, ChangeEventHandler, FC, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import cx from 'classnames';
//redux
import {selectorGetOrgId} from 'redux/organization-service/selector';
//constants
import {BUTTON_ICONS, defaultFileMimeTypes, defaultPhotoTypes} from 'core/constants';
//helpers
import {broadcastMessage, getIsUploadedPhotoValid, getUploadedPhotoInvalidError, MAX_PHOTOS} from './helpers';
//images
import ButtonCross from 'assets/image/cross-button.svg';
//components
import ButtonImage from 'shared/components/Buttons/ButtonImage';
import PopupContainer from 'shared/components/PopupContainer';
import Loader from 'shared/components/Loader';
//styles
import styles from './BroadcastInputTextPopup.module.scss';
import {getTextDirection} from 'core/functions';

type BroadcastInputTextPopupProps = {
  closePopupHandler: () => void;
  groupId?: number;
};

const BroadcastInputTextPopup: FC<BroadcastInputTextPopupProps> = ({closePopupHandler, groupId}) => {
  const [inputValue, setInputValue] = useState('');
  const [isShowOptions, setIsShowOptions] = useState<boolean>(false);
  const [uploadedPhotos, setUploadedPhotos] = useState<Array<File>>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [fileError, setFileError] = useState('');

  const orgId = useSelector(selectorGetOrgId);

  const selectRef = useRef<HTMLDivElement>(null);
  const photoInputRef = useRef<HTMLInputElement>(null);

  const {t, i18n} = useTranslation(['inputs', 'buttons', 'validation']);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => document.removeEventListener('click', handleClickOutside, true);
  }, [isShowOptions]);

  const handleClickOutside = (event: Event) => {
    if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
      setIsShowOptions(false);
      closePopupHandler();
    }
  };

  const openFileUpload = () => photoInputRef.current?.click();

  const handleUploadImages = (event: ChangeEvent<HTMLInputElement>): void => {
    event.preventDefault();
    setFileError('');

    const selectedFiles = event.target.files;

    if (selectedFiles) {
      const photos = Array.from(selectedFiles)
        .slice(0, MAX_PHOTOS - uploadedPhotos.length)
        .filter(getIsUploadedPhotoValid);

      Array.from(selectedFiles).forEach((file) => {
        const error = getUploadedPhotoInvalidError(file);
        if (error) {
          setFileError(t(error));
        }
      });

      setUploadedPhotos((prevState: Array<File>) => [...prevState, ...photos]);
    }
  };

  const removePhotoHandler = (index: number) => (): void => {
    setFileError('');
    setUploadedPhotos((prevState: Array<File>) => prevState.filter((_, previousIndex) => previousIndex !== index));
  };

  const sendClickHandler = async () => {
    setIsLoading(true);
    await broadcastMessage(orgId, inputValue, uploadedPhotos, groupId);
    setIsLoading(false);
    closePopupHandler();
  };

  const inputChangeHandler: ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    const newInputValue = e.target.value;
    setInputValue(newInputValue);
  };

  const textDirection = getTextDirection(i18n.language);

  const isInputFileDisabled = uploadedPhotos.length >= MAX_PHOTOS;
  const isButtonSendDisabled = !inputValue.trim().length;

  return (
    <PopupContainer isDisableCloseButton isBroadcastStyle>
      <div className={styles.wrapper} ref={selectRef}>
        <div className={styles.main}>
          <textarea
            className={styles.textarea}
            placeholder={`${t('inputs:placeholders.typeMessage')}...`}
            value={inputValue || ''}
            onChange={inputChangeHandler}
            rows={10}
            name="q"
            id="w"
            dir={textDirection}
          />
        </div>
        <div
          className={cx(styles.bottom, {
            [styles.bottom_error]: fileError,
          })}
        >
          <input
            className={styles.hidden}
            type="file"
            onChange={handleUploadImages}
            ref={photoInputRef}
            accept={defaultFileMimeTypes}
            multiple
          />
          <ButtonImage viewStyle={BUTTON_ICONS.clip} clickHandler={openFileUpload} disabled={isInputFileDisabled} />

          {uploadedPhotos.length ? (
            <div className={styles.imagePreview}>
              {uploadedPhotos.map((photo, index) => (
                <div className={styles.imagePreview__uploaded} key={photo.name + index}>
                  {defaultPhotoTypes.includes(photo.type) ? (
                    <img src={URL.createObjectURL(photo)} alt="" />
                  ) : (
                    // eslint-disable-next-line jsx-a11y/media-has-caption
                    <video src={URL.createObjectURL(photo)} />
                  )}
                  <div className={styles.imagePreview__uploaded__buttonRemove} onClick={removePhotoHandler(index)}>
                    <img src={ButtonCross} className={styles.imagePreview__uploaded__buttonRemove__icon} alt="" />
                  </div>
                </div>
              ))}
            </div>
          ) : null}

          {isLoading ? (
            <Loader loadProps={{size: 30, parentClassName: styles.loader}} />
          ) : (
            <ButtonImage
              viewStyle={BUTTON_ICONS.send}
              clickHandler={sendClickHandler}
              disabled={isButtonSendDisabled}
            />
          )}
          {fileError && <span className={styles.error}>{fileError}</span>}
        </div>
      </div>
    </PopupContainer>
  );
};

export default BroadcastInputTextPopup;
