import {FC, createRef, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import cx from 'classnames';
//images
import SearchSvg from 'assets/image/inputs/search.svg';
//functions
import {addressToObj, generateLocation} from 'core/functions';
//types
import {FieldBaseProps} from 'core/types';
import {IGetLocationIdByAddressRequest} from '@joc/api-gateway';
//components
import FieldWrapper from 'shared/inputs/FieldWrapper';
import Autocomplete from 'react-google-autocomplete';
//styles
import './GoogleApiAutoCompleat.scss';

type InputSearchProps = FieldBaseProps<{
  placeholder?: string;
  defaultValue?: string;
  isInputValidation?: boolean;
  selectHandler?: (address: IGetLocationIdByAddressRequest) => void;
}>;

const GoogleApiAutoCompleat: FC<InputSearchProps> = ({
  placeholder,
  defaultValue,
  isInputValidation,
  selectHandler,
  ...fieldBaseProps
}: InputSearchProps) => {
  const autocompleteRef = createRef<HTMLInputElement>();
  const {field, form, disabled, dir} = fieldBaseProps;
  const {setFieldValue, setFieldTouched} = form;

  const [isActive, setIsActive] = useState(false);
  const {t} = useTranslation('inputs');

  const changeHandler = (value: string) => {
    if (!value.length) setIsActive(true);
    setFieldValue(field.name, isInputValidation ? {text: value} : '');
  };

  useEffect(() => {
    if (placeholder) {
      autocompleteRef.current?.setAttribute('placeholder', placeholder);
    }
  }, [autocompleteRef]);

  return (
    <FieldWrapper {...fieldBaseProps}>
      <div className={cx('wrap', {disabled})}>
        <img src={SearchSvg} className="search_icon" alt="" dir={dir} />
        <Autocomplete
          className={cx('googleAct', isActive && 'googleAct-active', {disabled})}
          apiKey={`${process.env.REACT_APP_GOOGLE_API_KEY}`}
          onPlaceSelected={(place) => {
            const placeObj = addressToObj(place);
            if (selectHandler) {
              selectHandler(placeObj);
            } else {
              setFieldValue(field.name, placeObj);
            }
          }}
          ref={autocompleteRef}
          onBlur={() => setFieldTouched(field.name, true)}
          onChange={(e) => changeHandler(e.currentTarget.value)}
          options={{
            types: ['establishment', 'geocode'],
            // https://developers.google.com/maps/documentation/places/web-service/supported_types
            // componentRestrictions: { country: 'ru' },
            // defaultValue="Amsterdam"
          }}
          defaultValue={defaultValue || generateLocation(field.value)}
          dir={dir}
        />
      </div>
    </FieldWrapper>
  );
};

export default GoogleApiAutoCompleat;
