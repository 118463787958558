import {FC, useState} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {useTranslation} from 'react-i18next';
import cx from 'classnames';
import moment from 'moment';
//redux
import {Store} from 'redux/root';
import {selectorGetOrgActiveStatus, selectorGetOrgInfo} from 'redux/organization-service/selector';
//images
import {ReactComponent as EyeSvg} from 'assets/image/eye.svg';
import {ReactComponent as CalendarSvg} from 'assets/image/calendar.svg';
import {ReactComponent as ClockSvg} from 'assets/image/clock_big.svg';
//functions
import {converTimeToMinutesAndSeconds, setFullName} from 'core/functions';
//types
import {IVideoResponse, ORGANISATION_ACTIVE_STATUS} from '@joc/api-gateway';
//components
import VideoPreviewSquare from 'shared/components/VideoPreviewSquare';
import VideoActionsPopup from '../VideoActionsPopup';
import ImageWithPopup from 'shared/components/ImageWithPopup';
//styles
import styles from './index.module.scss';

type MarketingVideoPreviewParentProps = {
  videoData: IVideoResponse;
};

const MarketingVideoPreview: FC<MarketingVideoPreviewProps> = ({
  videoData,
  orgData,
  orgStatus,
}: MarketingVideoPreviewProps) => {
  const [videoTime, setVideoTime] = useState(0);

  const {t} = useTranslation(['marketing', 'popup']);

  const isSuspended = orgStatus?.status === ORGANISATION_ACTIVE_STATUS.SUSPENDED;

  const noDate = t('marketing:noDate');

  return (
    <div className={styles.marketing_item}>
      <div className={styles.item_header}>
        <div className={styles.item_user_post}>
          {videoData.id === orgData?.primaryVideo.id && <div className={styles.isCurrent} />}
          <div className={styles.user_logo}>
            <ImageWithPopup imagePath={videoData.user.imagePath} popupTitle={t('popup:profilePicture.user')} />
          </div>
          <div className={styles.user_username}>{setFullName(videoData.user.firstName, videoData.user.lastName)}</div>
        </div>
        <VideoActionsPopup videoId={videoData.id} disabled={isSuspended} />
      </div>
      <VideoPreviewSquare
        parrentClassName={styles.item_main}
        link={videoData.videoPath}
        videoId={videoData.id}
        setVideoTime={setVideoTime}
      />
      <div className={styles.item_footer}>
        <div className={cx(styles.item_count, styles.item_watch)}>
          <EyeSvg className={styles.item_ico} />
          <span className={styles.item_data}>{videoData.metadata.watchedCount}</span>
        </div>
        <div className={cx(styles.item_count, styles.item_calendar)}>
          <CalendarSvg className={styles.item_ico} />
          <span className={styles.item_data}>
            {moment(videoData.metadata.createDate).format(process.env.REACT_APP_DATE_FORMAT) || noDate}
          </span>
        </div>
        <div className={cx(styles.item_count, styles.item_time)}>
          <ClockSvg className={styles.item_ico} />
          <span className={styles.item_data}>{videoTime ? converTimeToMinutesAndSeconds(videoTime) : '00:00'}</span>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (store: Store) => ({
  orgData: selectorGetOrgInfo(store),
  orgStatus: selectorGetOrgActiveStatus(store),
});

const connector = connect(mapStateToProps);

type MarketingVideoPreviewProps = ConnectedProps<typeof connector> & MarketingVideoPreviewParentProps;

export default connector(MarketingVideoPreview);
