import {FC, useEffect, useMemo, useState} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import cx from 'classnames';
import moment, {Moment} from 'moment';
import {useTranslation} from 'react-i18next';
//redux
import {selectorGetOrgId} from 'redux/organization-service/selector';
import {selectorGetOpportunitiesReminderTape} from 'redux/opportunities-service/selector';
import {getOpportunitiesReminderTape} from 'redux/opportunities-service/action';
//types
import {Store} from 'redux/root';
import {IDateRange, IOpportunityResponse} from '@joc/api-gateway';
//components
import Loader from 'shared/components/Loader';
import StaticDatePicker from 'shared/components/Pickers/Calendar';
import ResponseFailure from 'shared/components/ResponseFailure';
import ReminderTapeItem from './ReminderTapeItem';
// context
import {useOpportunitiesContext} from 'pages/Organization/Opportunities/context';
// helper
import {getTransformOppo} from './helper';
// constants
import {ORGANIZATION_TABS} from 'core/constants';
//styles
import styles from './index.module.scss';

type ReminderTapeParentProps = {
  isClose?: boolean;
};

const ReminderTape: FC<ReminderTapeProps> = ({
  organisationId,
  isClose,
  getOpportunitiesReminderTape,
  opportunities,
}: ReminderTapeProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState({state: false, message: ''});
  const [selectedDate, setSelectedDate] = useState<Date | Moment>(new Date());
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [dateRange, setDateRange] = useState<IDateRange>({
    fromDate: new Date(new Date(selectedDate as Date).getFullYear(), new Date(selectedDate as Date).getMonth(), 1),
    toDate: new Date(new Date(selectedDate as Date).getFullYear(), new Date(selectedDate as Date).getMonth() + 1, 1),
  });

  const {activeTab, isSelectPastDateAllowed} = useOpportunitiesContext();

  const {t} = useTranslation(['errors', 'reminderTape']);

  const filteredOpportunitiesByDay = useMemo(() => {
    return opportunities
      .map((opportunity: IOpportunityResponse) => getTransformOppo(opportunity))
      .filter(({endDate}) => moment(endDate).format('DD/MM/YY') === moment(selectedDate).format('DD/MM/YY'));
  }, [selectedDate, opportunities]);

  const getOpportunities = async () => {
    setIsLoading(true);
    try {
      if (!organisationId) throw new Error(t('errors:cantFindOrgId'));
      await getOpportunitiesReminderTape(organisationId, dateRange);
      setIsLoading(false);
    } catch (error) {
      setError({state: true, message: error?.response?.message || error.message});
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (organisationId && dateRange) getOpportunities();
  }, [organisationId, dateRange]);

  if (error.state && !isLoading)
    return (
      <ResponseFailure
        styleTable
        message={error.message}
        buttonClickHandler={() => {
          setError({state: false, message: ''});
          getOpportunities();
        }}
      />
    );

  return (
    <div className={cx(styles.container, isPreviewOpen && styles.disableSticky)}>
      <StaticDatePicker
        selectedDate={selectedDate as Date}
        setSelectedDate={setSelectedDate}
        opportunitiesCurrentMonth={opportunities}
        monthChangeHandler={setDateRange}
        isDisabledPastDate={activeTab === ORGANIZATION_TABS.UPCOMING && !isSelectPastDateAllowed}
      />

      <div className={styles.date_selected}>
        {moment(selectedDate).format('MMM D')}, {t('reminderTape:opportunities')}
      </div>
      {isLoading && !error.state ? (
        <Loader loadProps={{parentClassName: styles.loader}} />
      ) : !filteredOpportunitiesByDay.length ? (
        <div className={styles.placeholder}>{t('reminderTape:noOpportunityToday')}</div>
      ) : (
        <div className={styles.tape_list}>
          {filteredOpportunitiesByDay.map((opportunity) => (
            <ReminderTapeItem key={opportunity.id} opportunity={opportunity} setIsPreviewOpen={setIsPreviewOpen} />
          ))}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (store: Store) => ({
  organisationId: selectorGetOrgId(store),
  opportunities: selectorGetOpportunitiesReminderTape(store),
});

const mapDispatchToProps = {
  getOpportunitiesReminderTape,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReminderTapeProps = ConnectedProps<typeof connector> & ReminderTapeParentProps;

export default connector(ReminderTape);
