import {FC, useContext, useEffect} from 'react';
import {connect, ConnectedProps, useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router';
import qs from 'qs';
import cx from 'classnames';
import {useTranslation} from 'react-i18next';
//redux
import {resetError} from 'redux/error-service/action';
import {selectorGetError} from 'redux/error-service/selector';
import {selectorSignupRequests, selectorSignupRequestsFound} from 'redux/signupRequests-service/selector';
import {
  approveSignupRequest,
  declineSignupRequest,
  resetSignupRequests,
  getSignupRequestsTotal,
} from 'redux/signupRequests-service/actions';
import {selectorGetOrgId} from 'redux/organization-service/selector';
//types
import {
  IClaimSort,
  IFullTextSearchClaim,
  INVITED_USER_POSSIBLE_SEARCH,
  INVITED_USER_POSSIBLE_SORT,
  IUserClaimResponse,
} from '@joc/api-gateway';
import {PossibleSortBy} from 'shared/components/Table/TableHead/TableHeadCell/helpers';
//functions
import {calculateUserAge, generateLocation, setSearchOrSortQueryString} from 'core/functions';
import {tenthListItemSelector} from 'shared/components/Buttons/ButtonUpToTop/util';
//constants
import {INITIAL_PAGINATION, TABLE_ITEMS_CLASSNAMES, TABLE_HEADERS} from 'core/constants';
import {DEFAULT_SCROLLABLE_CONTAINER} from 'shared/components/Buttons/ButtonUpToTop/constants';
//helpers
import {getHeaderSortQueryParams} from '../helpers';
//components
import TableHead from 'shared/components/Table/TableHead';
import TableHeadCell from 'shared/components/Table/TableHead/TableHeadCell';
import TableRow from 'shared/components/Table/TableRow';
import TableMain from 'shared/components/Table/TableMain';
import TableBody from 'shared/components/Table/TableBody';
import Loader from 'shared/components/Loader';
import ResponseFailure from 'shared/components/ResponseFailure';
import TableMenu from 'components/Organization/TableMenu';
import AvatarCell from 'shared/components/Table/CellRenderers/AvatarCell';
import FullNameCell from 'shared/components/Table/CellRenderers/FullNameCell';
import CellDefault from 'shared/components/Table/CellRenderers/CellDefault';
import Observer from 'shared/components/Observer';
import {VolunteersPageContext, VolunteersPageContextType} from 'pages/Organization/Volunteers';
import ConfirmRejectActions from 'shared/components/Table/CellRenderers/ManualOpportunityActions';
import TableHeadCellWithSort from 'shared/components/Table/TableHead/TableHeadCell/TableHeadCellWithSort';
import ButtonUpToTop from 'shared/components/Buttons/ButtonUpToTop';
//styles
import styles from '../Table/Table.module.scss';

const SignupRequestsTable: FC<SignupRequestsTableProps> = ({
  resetError,
  approveSignupRequest,
  declineSignupRequest,
  resetSignupRequests,
  getSignupRequestsTotal,
}) => {
  const signupRequests = useSelector(selectorSignupRequests);
  const signupRequestsFound = useSelector(selectorSignupRequestsFound);
  const orgId = useSelector(selectorGetOrgId);
  const error = useSelector(selectorGetError);

  const history = useHistory();
  const location = useLocation();

  const {t} = useTranslation(['volunteers', 'errors', 'buttons', 'popup', 'common']);

  const {isLoading, sort, setPagination, isClearSearch, setIsClearSearch}: VolunteersPageContextType =
    useContext(VolunteersPageContext);

  useEffect(() => {
    if (orgId) getSignupRequestsTotal(orgId);
    return () => {
      resetSignupRequests();
    };
  }, [orgId]);

  const buttonFailureClickHandler = async () => {
    resetError();
    history.push({search: ''});
    setPagination(INITIAL_PAGINATION);
  };

  const appendQueryString = (newQueryParams: IFullTextSearchClaim | IClaimSort, searchBy: string): void => {
    const searchQuery = setSearchOrSortQueryString(location.search, newQueryParams, searchBy);
    history.push({search: searchQuery});
  };

  const searchVolunteersChangeHandler = (value: string): void => {
    const fullTextSearchParams: IFullTextSearchClaim = {
      value,
      fields: Object.values(INVITED_USER_POSSIBLE_SEARCH),
    };
    if (fullTextSearchParams.value.length) {
      appendQueryString(fullTextSearchParams, 'fullTextSearch');
    } else {
      const searchParams = qs.parse(location.search, {ignoreQueryPrefix: true});
      delete searchParams.fullTextSearch;
      history.push({search: qs.stringify(searchParams)});
    }
  };

  const headerSortClickHandler = (sortBy: PossibleSortBy) => {
    const newQueryParams = getHeaderSortQueryParams(sort?.sortDir, sortBy as INVITED_USER_POSSIBLE_SORT);
    appendQueryString(newQueryParams as IClaimSort, 'sort');
  };

  return (
    <div className={styles.container}>
      <div className={styles.table}>
        <TableMenu
          searchChangeHandler={searchVolunteersChangeHandler}
          isClearSearch={isClearSearch}
          setIsClearSearch={setIsClearSearch}
          parentClassName={styles.hideFilter}
          disableFilter
        />
        {!signupRequests.length && isLoading ? (
          <div className={styles.loader}>
            <Loader />
          </div>
        ) : error.state && !isLoading ? (
          <ResponseFailure
            styleTable
            message={error.message}
            buttonClickHandler={buttonFailureClickHandler}
            buttonTitle={t('buttons:button.getAll')}
          />
        ) : !isLoading && !signupRequests.length ? (
          <ResponseFailure
            styleTable
            message={t('errors:couldntFindOpps')}
            buttonUnshow
            parentClassName={styles.empty__list__message}
            title=" "
          />
        ) : (
          <TableMain>
            <TableHead callChild="signup_requests">
              <TableHeadCell itemClassName={TABLE_ITEMS_CLASSNAMES.avatar} text={TABLE_HEADERS.avatar} />
              <TableHeadCellWithSort
                itemClassName={TABLE_ITEMS_CLASSNAMES.fullName}
                text={TABLE_HEADERS.fullName}
                clickSortHandler={headerSortClickHandler}
                sortProps={{sort, ownSortBy: INVITED_USER_POSSIBLE_SORT.FirstNameLastName}}
              />
              <TableHeadCellWithSort
                itemClassName={TABLE_ITEMS_CLASSNAMES.email}
                text={TABLE_HEADERS.email}
                clickSortHandler={headerSortClickHandler}
                sortProps={{sort, ownSortBy: INVITED_USER_POSSIBLE_SORT.Email}}
              />
              <TableHeadCellWithSort
                itemClassName={TABLE_ITEMS_CLASSNAMES.phone}
                text={TABLE_HEADERS.phoneNumber}
                clickSortHandler={headerSortClickHandler}
                sortProps={{sort, ownSortBy: INVITED_USER_POSSIBLE_SORT.PhoneNumber}}
              />
              <TableHeadCell itemClassName={TABLE_ITEMS_CLASSNAMES.age} text={TABLE_HEADERS.age} />
              <TableHeadCell itemClassName={TABLE_ITEMS_CLASSNAMES.gender} text={TABLE_HEADERS.gender} />
              <TableHeadCell itemClassName={TABLE_ITEMS_CLASSNAMES.location} text={TABLE_HEADERS.location} />
              <TableHeadCell itemClassName={TABLE_ITEMS_CLASSNAMES.options} />
            </TableHead>
            <TableBody>
              {signupRequests.map((signupRequestUser: IUserClaimResponse, index: number) => (
                <TableRow
                  key={signupRequestUser.id}
                  callChild="signup_requests"
                  className={cx(styles.table__row, tenthListItemSelector(index))}
                >
                  <AvatarCell
                    imagePath={signupRequestUser?.imagePath || ''}
                    avatarTitle={t(`popup:profilePicture.user`)}
                  />
                  <FullNameCell
                    parentClassName={styles.fullname}
                    firstName={signupRequestUser.firstName}
                    lastName={signupRequestUser.lastName}
                  />
                  <CellDefault
                    parentClassName={TABLE_ITEMS_CLASSNAMES.email}
                    text={signupRequestUser.email || ''}
                    isCopy
                  />
                  <CellDefault
                    parentClassName={TABLE_ITEMS_CLASSNAMES.phone}
                    text={signupRequestUser.phoneNumber || ''}
                    isCopy
                  />
                  <CellDefault
                    parentClassName={TABLE_ITEMS_CLASSNAMES.age}
                    text={
                      signupRequestUser.birthDate
                        ? calculateUserAge(signupRequestUser.birthDate)
                        : t('volunteers:noData')
                    }
                  />
                  <CellDefault
                    parentClassName={TABLE_ITEMS_CLASSNAMES.gender}
                    text={
                      signupRequestUser.genderId
                        ? t(`common:genders.${signupRequestUser.genderId === 1 ? 'm' : 'f'}`)
                        : t('volunteers:noData')
                    }
                  />
                  <CellDefault
                    parentClassName={TABLE_ITEMS_CLASSNAMES.location}
                    text={generateLocation(signupRequestUser.address)}
                  />
                  <ConfirmRejectActions
                    confirmHandler={() => approveSignupRequest(signupRequestUser.id)}
                    rejectHandler={() => declineSignupRequest(signupRequestUser.id)}
                  />
                </TableRow>
              ))}
            </TableBody>
          </TableMain>
        )}
        {!!signupRequests.length && signupRequests.length !== signupRequestsFound && !error.state && (
          <Observer paginationSkip={signupRequests.length} setPagination={setPagination} isLoading={isLoading} />
        )}
      </div>
      <ButtonUpToTop containerSelector={DEFAULT_SCROLLABLE_CONTAINER} />
    </div>
  );
};

const mapDispatchToProps = {
  resetError,
  approveSignupRequest,
  declineSignupRequest,
  resetSignupRequests,
  getSignupRequestsTotal,
};

const connector = connect(null, mapDispatchToProps);

type SignupRequestsTableProps = ConnectedProps<typeof connector>;

export default connector(SignupRequestsTable);
