import {FC, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
//formik
import {Formik} from 'formik';
//redux
import {Store} from 'redux/root';
import {resetError, setError} from 'redux/error-service/action';
import {uploadOrgVideo} from 'redux/organization-service/actions';
//constants
import {INITIAL_UPLOAD_VIDEO} from 'core/constants';
//components
import ResponseFailure from 'shared/components/ResponseFailure';
import CongratsPopup from 'components/Organization/CongratsPopup';
import UploadVideoForm from './UploadVideoForm/UploadVideoForm';

const PopupUploadVideo: FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isUploadSuccess, setIsUploadSuccess] = useState(false);

  const error = useSelector((store: Store) => store.errorRedux.error);

  const dispatch = useDispatch();

  const handlerResetError = () => dispatch(resetError());

  const {t} = useTranslation('popup');

  const popupUploadVideoSubmitClickHandler = async (values: {video: File | null}) => {
    try {
      setIsLoading(true);
      values.video && dispatch(uploadOrgVideo(values.video));
      setIsLoading(false);
      setIsUploadSuccess(true);
    } catch (error) {
      setIsLoading(false);
      dispatch(setError(error?.response?.message || error.message, error?.response?.code || error.code));
      console.error(error);
    }
  };

  if (error.state) return <ResponseFailure message={error.message} buttonClickHandler={handlerResetError} styleTable />;

  if (isUploadSuccess)
    return (
      <CongratsPopup
        doneButtonClickHandler={() => setIsUploadSuccess(false)}
        subtitle={t('congratsOppo.videoHasBeenUploaded')}
        absoluteDisable
      />
    );

  return (
    <Formik initialValues={INITIAL_UPLOAD_VIDEO} onSubmit={(values) => popupUploadVideoSubmitClickHandler(values)}>
      <UploadVideoForm isLoading={isLoading} />
    </Formik>
  );
};

export default PopupUploadVideo;
