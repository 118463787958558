import {FC} from 'react';
import cx from 'classnames';
import * as Yup from 'yup';
import {Field, Form, Formik} from 'formik';
import {useTranslation} from 'react-i18next';
//validation schema
import {passwordSchema} from 'core/validation';
//components
import PopupContainer from 'shared/components/PopupContainer';
import Appeal from 'shared/components/Appeal/DefaultRenderer';
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
import Input from 'shared/inputs/Input';
//styles
import styles from './AddByList.module.scss';

type ChangeDefaultPasswordPopupProps = {
  onSetPassword: (value: string) => void;
  onClose: () => void;
  inputType?: 'text' | 'password';
};

const ChangeDefaultPasswordPopup: FC<ChangeDefaultPasswordPopupProps> = ({
  onSetPassword,
  onClose,
  inputType = 'password',
}) => {
  const {t} = useTranslation(['messages', 'buttons', 'inputs']);

  const initValues = {password: ''};
  const validationSchema = Yup.object().shape({
    password: passwordSchema,
  });

  return (
    <div>
      <PopupContainer parentClassName={styles.popup} isBroadcastStyle closeButtonParentClickHandler={onClose}>
        <div className={styles.content}>
          <Appeal styleThink isHeightDisable={false} isFullHeight />
          <h1 className={cx('title')}>{t('messages:setPassword')}</h1>
          <p>{t('messages:setPasswordForStudents')}</p>

          <Formik
            enableReinitialize
            initialValues={initValues}
            validationSchema={validationSchema}
            onSubmit={(values) => onSetPassword(values.password)}
          >
            <Form className={styles.form}>
              <Field
                name="password"
                type={inputType}
                placeholder={t('inputs:placeholders.password')}
                isDisabledMargin
                autocomplete="off"
                component={Input}
              />

              <ButtonDefault
                submitType
                parentClassName={styles.button}
                classList={['primary', 'extra_lg']}
                title={t('buttons:button.submit')}
              />
            </Form>
          </Formik>
        </div>
      </PopupContainer>
    </div>
  );
};

export default ChangeDefaultPasswordPopup;
