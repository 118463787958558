import * as Yup from 'yup';
//validation
import {emailSchema, passwordSchema} from 'core/validation';

export type ChangeDefaultPasswordFormValues = {
  email: string;
  password: string;
  confirmPassword: string;
};

export const validationSchema = Yup.object().shape({
  email: emailSchema,
  password: passwordSchema,
  confirmPassword: Yup.string()
    .required('Confirm Password is required')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
});
