import {FC, useEffect, useRef} from 'react';
import cx from 'classnames';
//formik
import {FieldInputProps} from 'formik';
//styles
import styles from './index.module.scss';

type InputNumberProps = {
  setFieldValue: (field: string, value: number | undefined) => void;
  field: FieldInputProps<string>;
  placeholder?: string;
  isDisabled?: boolean;
  id?: string;
  fieldValue?: number;
  minus?: boolean;
  limit?: number;
  parentClassName?: string;
  withoutPencil?: boolean;
  min?: number;
};

let timeout: NodeJS.Timeout;

const InputNumber: FC<InputNumberProps> = ({
  placeholder,
  isDisabled,
  field,
  id,
  setFieldValue,
  fieldValue,
  minus,
  limit,
  parentClassName,
  min = 0,
  withoutPencil = false,
}) => {
  const ref = useRef<HTMLInputElement>(null);

  const setFocus = (): void => {
    if (ref && ref.current) ref.current.focus();
  };

  useEffect(() => {
    if (!!fieldValue && !!field && !!setFieldValue) {
      window.clearTimeout(timeout);
      timeout = setTimeout(() => {
        setFieldValue(field?.name, fieldValue || undefined);
      }, 1500);
    }
  }, [fieldValue, setFieldValue]);

  return (
    <div className={cx(styles.input__text, parentClassName && parentClassName)}>
      <input
        id={id && id}
        type="number"
        placeholder={placeholder || ''}
        {...field}
        ref={ref}
        disabled={isDisabled}
        value={fieldValue}
        min={minus ? undefined : min}
        max={limit}
      />
      {!withoutPencil && field && !!field.value && !isDisabled && (
        <div className={styles.input__text_edit} onClick={setFocus} />
      )}
    </div>
  );
};

export default InputNumber;
