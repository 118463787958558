// constants
import {TABLE_ROW_CLASSNAMES} from 'core/constants';
// types
import {TStyles} from '../types';
// interfaces
import {IGetRowClassesOptions} from './interfaces';

export const getRowClasses = (styles: TStyles, options: IGetRowClassesOptions = {}) => {
  const {callChild = '', suspend = false, rejection = false, overtariff = false} = options;

  const rowClassNameKey = TABLE_ROW_CLASSNAMES[callChild as keyof typeof TABLE_ROW_CLASSNAMES] || '';
  const rowClassNameValue = styles[rowClassNameKey];

  return {
    [rowClassNameKey]: rowClassNameValue,
    [styles.overtariffColor]: overtariff,
    [styles.notactiveClass]: rejection,
    [styles.suspend]: suspend,
  };
};
