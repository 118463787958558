// react
import {FC, useEffect} from 'react';
import {AnyAction} from 'redux';
import {Store} from 'redux/root';
import {useSelector} from 'react-redux';
import {ThunkAction} from 'redux-thunk';
// mui
import Dialog from '@material-ui/core/Dialog';
// selectors
import {selectorGetHashtags} from 'redux/hashtag-service/selectors';
// actions
import {getAllHashtags} from 'redux/hashtag-service/actions';
// interfaces
import {IModalsActionsProps, IModalsData} from '../interface';
// components
import {Hashtags} from './Hashtags';
import Loader from 'shared/components/Loader';
import {CloseButton} from 'shared/components/CloseButton';
import {CreateHashtagSection} from './CreateHashtagSection';
// icons
import {ReactComponent as MissingHashtagsIcon} from 'assets/image/onboarding/thanks-for-contact.svg';
//styles
import styles from './index.module.scss';

interface ICreateHashtagModalProps {
  title: string;
  isOpen: boolean;
  subtitle: string;
  isLoading: boolean;
  orgId: string | null;
  modalsData: IModalsData;
  modalsActions: IModalsActionsProps;
  startLoading: <T>(action: ThunkAction<T, Store, void, AnyAction>) => Promise<T>;
}

export const CreateHashtagModal: FC<ICreateHashtagModalProps> = ({
  orgId,
  title,
  isOpen,
  subtitle,
  isLoading,
  modalsData,
  startLoading,
  modalsActions,
}) => {
  const {
    create: {createHashtagModalData},
  } = modalsData;

  const orgHashtags = useSelector(selectorGetHashtags);

  const getAllOrgHashtags = async (orgId: string) => {
    await startLoading(getAllHashtags(orgId));
  };

  useEffect(() => {
    if (createHashtagModalData?.orgId) {
      const {orgId} = createHashtagModalData;
      getAllOrgHashtags(orgId);
    }
  }, [createHashtagModalData?.orgId, orgHashtags.length]);

  return (
    <Dialog
      open={isOpen}
      className={styles.createTag__modal}
      onClose={modalsActions.create.close}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className={styles.content}>
        <CloseButton onClick={modalsActions.create.close} classNames={styles.content__close__button} />
        <div className={styles.content__background__image} />
        <CreateHashtagSection startLoading={startLoading} orgId={orgId} title={title} options={orgHashtags} />
        {!isLoading ? (
          orgHashtags.length ? (
            <Hashtags
              subtitle={subtitle}
              hashtags={orgHashtags}
              handleOpenEditHashtagModal={modalsActions.edit.open}
              handleOpenDeleteHashtagModal={modalsActions.delete.open}
            />
          ) : (
            <div className={styles.content__missingHashtags__section}>
              <MissingHashtagsIcon />
              <h3 className={styles.content__missingHashtags__section__title}>No categories available</h3>
            </div>
          )
        ) : (
          <Loader loadProps={{parentClassName: styles.content__loader}} />
        )}
      </div>
    </Dialog>
  );
};
