import {FC} from 'react';
import {useTranslation} from 'react-i18next';
//formik
import {Field, Form} from 'formik';
//types
import {ORGANISATION_GENERAL_TYPES} from '@joc/api-gateway';
//components
import Input from 'shared/inputs/Input';
import TextArea from 'shared/inputs/TextArea';
import InputLogo from 'components/Organization/InputLogo';
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
import SingleSelect from 'shared/components/SelectDropdown/SingleSelect';
//styles
import styles from './OrgSignupForm.module.scss';

const OrgSignupForm: FC = () => {
  const {t} = useTranslation(['signUpOrg', 'inputs', 'buttons', 'login']);

  return (
    <Form className={styles.form}>
      <Field
        name="organizationName"
        label={t('signUpOrg:orgName')}
        placeholder={t('inputs:placeholders.orgName')}
        component={Input}
      />
      <Field
        name="organisationGeneralType"
        label={t('signUpOrg:orgType')}
        placeholder={t('inputs:placeholders.selectTypeOfOrg')}
        options={[ORGANISATION_GENERAL_TYPES.OTHER, ORGANISATION_GENERAL_TYPES.SCHOOL]}
        component={SingleSelect}
      />
      <Field
        name="organizationLogoPath"
        label={t('signUpOrg:orgLogo')}
        uploadLogoStyle
        disableErrorText
        component={InputLogo}
      />
      <Field
        name="organizationDescription"
        label={t('signUpOrg:aboutOrg')}
        placeholder={t('login:subtitle2')}
        textAreaStyle
        rows={3}
        component={TextArea}
      />
      <ButtonDefault submitType classList={['primary', 'lg']} title={t('buttons:button.next')} />
    </Form>
  );
};

export default OrgSignupForm;
