import {Dispatch} from 'redux';
//API
import {API} from 'core/API';
//redux
import {
  APPROVE_SIGNUP_REQUEST,
  DECLINE_SIGNUP_REQUEST,
  GET_SIGNUP_REQUESTS_ALL,
  GET_SIGNUP_REQUESTS_TOTAL,
  RESET_SIGNUP_REQUESTS,
  SET_ERROR,
} from 'redux/actions-types';
//functions
import {getIsEmptyPaginationSkip} from 'core/functions';
//types
import {INVITATION_ACCEPTED_STATUS, ISearchUserClaimsRequest, SearchUserClaimsRequest} from '@joc/api-gateway';

export const getSignupRequestsAll =
  (orgId: number, searchBody?: ISearchUserClaimsRequest) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const response = await API.searchClaims(
        orgId.toString(),
        SearchUserClaimsRequest.fromJS({
          ...searchBody,
          isAccepted: [INVITATION_ACCEPTED_STATUS.NotAccepted],
        })
      );
      const isPaginationSkipEmpty = getIsEmptyPaginationSkip(searchBody?.pagination);
      if (isPaginationSkipEmpty) dispatch({type: RESET_SIGNUP_REQUESTS});
      dispatch({type: GET_SIGNUP_REQUESTS_ALL, payload: response});
    } catch (error) {
      dispatch({type: SET_ERROR, payload: {state: true, message: error?.response?.message || error.message}});
    }
  };

export const getSignupRequestsTotal =
  (orgId: number) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const response = await API.searchClaims(
        orgId.toString(),
        SearchUserClaimsRequest.fromJS({
          pagination: {skip: 0, take: 1},
          isAccepted: [INVITATION_ACCEPTED_STATUS.NotAccepted],
        })
      );
      dispatch({type: GET_SIGNUP_REQUESTS_TOTAL, payload: response.total});
    } catch (error) {
      dispatch({type: SET_ERROR, payload: {state: true, message: error?.response?.message || error.message}});
    }
  };

export const approveSignupRequest = (id: string) => async (dispatch: Dispatch) => {
  try {
    await API.acceptClaim(id);
    dispatch({type: APPROVE_SIGNUP_REQUEST, payload: id});
  } catch (error) {
    dispatch({
      type: SET_ERROR,
      payload: {state: true, message: error?.response?.message || error?.response?.message || error.message},
    });
  }
};

export const declineSignupRequest = (id: string) => async (dispatch: Dispatch) => {
  try {
    await API.declineClaim(id);
    dispatch({type: DECLINE_SIGNUP_REQUEST, payload: id});
  } catch (error) {
    dispatch({
      type: SET_ERROR,
      payload: {state: true, message: error?.response?.message || error?.response?.message || error.message},
    });
  }
};

export const resetSignupRequests =
  () =>
  (dispatch: Dispatch): void => {
    dispatch({
      type: RESET_SIGNUP_REQUESTS,
    });
  };
