//constants
import {BUTTON_ICONS} from 'core/constants';
//images
import DownloadSvg from 'assets/image/download.svg';
import HeartSvg from 'assets/image/heart.svg';
import PencilSvg from 'assets/image/pencil-light.svg';
import PrintSvg from 'assets/image/print.svg';
import ShareSvg from 'assets/image/share-light.svg';
import TrashSvg from 'assets/image/trash-light.svg';
import UserCircleSvg from 'assets/image/user-circle.svg';
import FilterSvg from 'assets/image/filter.svg';
import EyeSvg from 'assets/image/eye-lavender.svg';
import EditSVG from 'assets/image/editMO.svg';
import AddImageSVG from 'assets/image/add-image.svg';
import CalendarOppoSVG from 'assets/image/calendar-oppo.svg';
import AddVolunteerSVG from 'assets/image/add-volunteer.svg';
import SendSVG from 'assets/image/send-blue.svg';
import ClipSVG from 'assets/image/clip.svg';
import HashtagSVG from 'assets/icons/hashtag.svg';

export const setIcon = (style: string) => {
  switch (style) {
    case BUTTON_ICONS.download:
      return DownloadSvg;
    case BUTTON_ICONS.heart:
      return HeartSvg;
    case BUTTON_ICONS.edit:
      return EditSVG;
    case BUTTON_ICONS.pencil:
      return PencilSvg;
    case BUTTON_ICONS.print:
      return PrintSvg;
    case BUTTON_ICONS.share:
      return ShareSvg;
    case BUTTON_ICONS.trash:
      return TrashSvg;
    case BUTTON_ICONS.user:
      return UserCircleSvg;
    case BUTTON_ICONS.filter:
      return FilterSvg;
    case BUTTON_ICONS.eye:
      return EyeSvg;
    case BUTTON_ICONS.add_image:
      return AddImageSVG;
    case BUTTON_ICONS.calendar:
      return CalendarOppoSVG;
    case BUTTON_ICONS.add_volunteer:
      return AddVolunteerSVG;
    case BUTTON_ICONS.send:
      return SendSVG;
    case BUTTON_ICONS.clip:
      return ClipSVG;
    case BUTTON_ICONS.hashtag:
      return HashtagSVG;
    default:
      break;
  }
};
