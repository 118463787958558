// react
import {FC, useEffect, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
// other packages
import cx from 'classnames';
//formik
import {Form, useFormikContext} from 'formik';
//redux
import {selectorGetSchoolId} from 'redux/organization-service/selector';
//context
import {useOpportunitiesContext} from 'pages/Organization/Opportunities/context';
//types
import {OPPORTUNITIES_FILTERS_VALUES} from 'core/types';
//components
import {ByWhoFilter} from './ByWhoFields';
import {LocationFields} from './LocationFields';
import {DateTimeFields} from './DateTimeFields';
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
import WithWhoFilter from 'shared/components/FiltersFields/WithWhoFilter';
import WhichDaysFilter from 'shared/components/FiltersFields/WichDaysFilter';
import FiltersContainer from 'components/Organization/Filters/FiltersContainer';
// data
import {currentDate, defaultSidebarConfig, startDateFieldName} from './data';
// constants
import {ORGANIZATION_TABS} from 'core/constants';
//styles
import styles from './OpportunitiesFiltersForm.module.scss';

type OpportunitiesFiltersFormParentProps = {
  onSubmit: (values?: OPPORTUNITIES_FILTERS_VALUES) => void;
};

const OpportunitiesFiltersForm: FC<OpportunitiesFiltersFormParentProps> = ({onSubmit}) => {
  const {t} = useTranslation('form');

  const schoolId = useSelector(selectorGetSchoolId);

  const {values, setFieldValue, resetForm, isValid} = useFormikContext<OPPORTUNITIES_FILTERS_VALUES>();

  const {
    activeTab,
    isClearSearch,
    setSidebarVisibility,
    generalTabClickHandler,
    isSelectPastDateAllowed,
    sidebarVisibility: {isShowFilter},
  } = useOpportunitiesContext();

  const setCurrentDate = (currentTab: ORGANIZATION_TABS, values: OPPORTUNITIES_FILTERS_VALUES) => {
    const {fromDate: startDate, toDate: endDate} = values;

    if (endDate && !startDate) {
      currentTab === ORGANIZATION_TABS.PAST
        ? setFieldValue(startDateFieldName, endDate)
        : setFieldValue(startDateFieldName, currentDate);
    }
  };

  useEffect(() => {
    setCurrentDate(activeTab, values);
  }, [values]);

  useEffect(() => {
    isClearSearch && resetForm();
  }, [isClearSearch]);

  const handleResetForm = () => {
    resetForm();
    onSubmit(undefined);
    generalTabClickHandler?.();
  };

  const handleClose = () => {
    setSidebarVisibility(defaultSidebarConfig);
  };

  const isVisibleDateInTePast = useMemo(
    () => isSelectPastDateAllowed && activeTab !== ORGANIZATION_TABS.UPCOMING,
    [isSelectPastDateAllowed, activeTab]
  );

  const isVisibleDataInTheFuture = useMemo(() => activeTab !== ORGANIZATION_TABS.PAST, [activeTab]);

  return (
    <FiltersContainer
      isOrgFilter
      resetForm={handleResetForm}
      isCloseFilters={!isShowFilter}
      changeVisibilityFilters={handleClose}
    >
      <Form className={styles.form}>
        <DateTimeFields showDateInThePast={isVisibleDateInTePast} showDateInTheFuture={isVisibleDataInTheFuture} />
        <LocationFields />
        <div className={styles.form__field}>
          <WithWhoFilter setFieldValue={setFieldValue} fieldValues={values.withWho} smallStyle />
        </div>
        <ByWhoFilter schoolId={schoolId} />
        <div className={cx(styles.form__field, styles.pre__button)}>
          <WhichDaysFilter setFieldValue={setFieldValue} fieldValues={values.weekDays} />
        </div>
        <ButtonDefault disabled={!isValid} title={t('filter.apply')} submitType classList="secondary md" />
      </Form>
    </FiltersContainer>
  );
};

export default OpportunitiesFiltersForm;
