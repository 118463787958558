import {FC, useEffect, useState} from 'react';
import {useHistory, useLocation} from 'react-router';
import qs from 'qs';
//translation
import {useTranslation} from 'react-i18next';
//urls
import {urls} from 'core/appUrls';
//images
import AppStore from 'assets/image/apple.svg';
import GooglePlay from 'assets/image/google.svg';
//components
import Appeal from 'shared/components/Appeal/DefaultRenderer';
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
//styles
import styles from './AcceptInviteError.module.scss';

export const AcceptInviteError: FC = () => {
  const history = useHistory();

  const {search} = useLocation();

  const {t} = useTranslation(['errors', 'messages', 'buttons']);

  const [message, setMessage] = useState<string>(t('errors:errorContactOrgAdmin'));

  useEffect(() => {
    const message = qs.parse(search, {ignoreQueryPrefix: true})?.message;
    if (message) setMessage(message as string);
    localStorage.clear();
  }, []);

  return (
    <Appeal stylePensive>
      <div className={styles.content}>
        <span className="title">{t('errors:sorry')}</span>
        <span className={styles.subtitle}>{message}</span>
        <div className={styles.link_text}>
          {t('messages:downloadMobileApp')}
          <a href={process.env.REACT_APP_APPSTORE_LINK}>
            <img src={AppStore} alt="AppStore" />
          </a>
          <br />
          {t('messages:or')}
          <br />
          <a href={process.env.REACT_APP_PLAYMARKET_LINK}>
            <img src={GooglePlay} alt="GooglePlay" />
          </a>
          <br />
          <br />
        </div>
        <ButtonDefault
          classList={['primary']}
          title={t('buttons:button.goToLogin')}
          clickHandler={() => history.push(urls.onboardingChooseUserType)}
        />
      </div>
    </Appeal>
  );
};
