//types
import {UPDATE_PROGRESS, SET_END_UPLOAD, HIDE_UPLOAD_ALERT_INFO, SET_ERROR_UPLOAD} from 'redux/actions-types';
import {ILoadingReducer, LoadingActionTypes, LoadingPayload} from './types';

const initState: ILoadingReducer = {
  showSuccess: false,
  showError: false,
  progress: [],
};

const loadingReducer = (state = initState, action: LoadingActionTypes): ILoadingReducer => {
  const {type, payload}: LoadingActionTypes = action;

  switch (type) {
    case UPDATE_PROGRESS:
      const progress = [...state.progress];
      const index = progress.findIndex((item) => item.itemId === payload.itemId);

      if (index !== -1) {
        progress[index] = payload;
      } else {
        progress.push(payload);
      }

      if (!progress.filter((p) => p.percent < 100).length) {
        progress.splice(0, progress.length);
      }

      return {
        ...state,
        progress,
      };

    case SET_END_UPLOAD: {
      return {
        ...state,
        showSuccess: true,
      };
    }

    case SET_ERROR_UPLOAD: {
      return {
        ...initState,
        showError: true,
      };
    }

    case HIDE_UPLOAD_ALERT_INFO: {
      return initState;
    }

    default:
      return state;
  }
};

export default loadingReducer;
