import {createRef, FC, useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import i18next from 'components/translate';
import cx from 'classnames';
//API
import {IGendersResponse} from '@joc/api-gateway';
//assets
import {ReactComponent as BlueArrow} from 'assets/image/selects/blue-arrow.svg';
import {ReactComponent as WhiteArrow} from 'assets/image/selects/white-arrow.svg';
//core
import {API} from 'core/API';
import {useClickOutside} from 'core/customHooks';
//types
import {FieldBaseProps} from 'core/types';
//components
import FieldWrapper from 'shared/inputs/FieldWrapper';
import Loader from 'shared/components/Loader';
//styles
import styles from './index.module.scss';

type GenderSelectTypes = FieldBaseProps<{
  placeholder?: string;
  bothGenders?: boolean;
  classList?: Array<string>;
}>;

const GenderSelect: FC<GenderSelectTypes> = ({
  placeholder = i18next.t('inputs:placeholders.selectGender'),
  bothGenders,
  classList,
  ...fieldBaseProps
}) => {
  const {field, form, disabled, parentClassName} = fieldBaseProps;
  const {setFieldValue, setFieldTouched, touched} = form;

  const [options, setOptions] = useState<Array<IGendersResponse>>([]);
  const [error, setError] = useState(false);

  const {t} = useTranslation(['common', 'errors']);

  const title = useMemo(() => {
    if (options.length && field.value) {
      return options.find((option) => option.id.toString() === field.value.toString())?.genderName;
    }
    if (options.length && field.value === undefined && bothGenders && !touched[field.name]) {
      return t('common:genders.bothGenders');
    }
    if (bothGenders && touched[field.name]) {
      return t('common:genders.bothGenders');
    }

    return '';
  }, [field.value, options, bothGenders, touched[field.name]]);

  const optionRef = useMemo(() => options.map(() => createRef<HTMLDivElement>()), [options]);

  const {isComponentVisible, componentRef, setIsComponentVisible} = useClickOutside({
    initialVisible: false,
    setFieldTouched,
    fieldName: field.name,
  });

  const optionClickHandler = (id: number): void => {
    setFieldValue(field.name, id);
    setIsComponentVisible(false);
  };

  const titleClickHandler = () => {
    const valueIndex = options.findIndex((i) => i.id === field.value);
    const target = optionRef[valueIndex]?.current;

    if (target)
      setTimeout(() => {
        target.scrollIntoView();
      }, 0);

    if (isComponentVisible) setFieldTouched(field.name, true);

    setIsComponentVisible(!isComponentVisible);
  };

  const getGenders = useCallback(async () => {
    try {
      setError(false);
      const response = await API.getAllGenders();
      if (response.length) {
        //@ts-ignore
        return setOptions([
          ...response.map((option) => ({
            ...option,
            genderName: t(option.id === 1 ? 'common:genders.male' : 'common:genders.female'),
          })),
          ...(bothGenders ? [{id: null, genderName: t('common:genders.bothGenders')}] : []),
        ]);
      }
    } catch (error) {
      setError(true);
    }
  }, [bothGenders]);

  useEffect(() => {
    getGenders();
  }, []);

  useEffect(() => {
    if (field.value) setFieldValue(field.name, field.value);
  }, [field.value]);

  if (error) return <div onClick={() => getGenders()}>{t('errors:somethingWentWrongTryAgain')}</div>;

  if (!options.length) return <Loader loadProps={{size: 30}} />;

  return (
    <FieldWrapper {...fieldBaseProps}>
      <div
        className={cx(styles.dropdown_select, parentClassName && parentClassName, {
          [styles.md]: classList?.includes('md'),
          [styles.dropdown_select__active]: isComponentVisible,
          [styles.disabled]: disabled,
        })}
        ref={componentRef}
      >
        <div
          className={cx(styles.dropdown_select__title, {
            [styles.dropdown_select__title_selected]:
              !!field.value ||
              (bothGenders && touched[field.name]) ||
              (field.value === undefined && bothGenders && !field.value),
          })}
          onClick={titleClickHandler}
        >
          <span>{title || placeholder}</span>
          {!isComponentVisible ? (
            <BlueArrow className={styles.dropdown_select__title__vector} />
          ) : (
            <WhiteArrow className={styles.dropdown_select__title__vector} />
          )}
        </div>
        <div className={styles.dropdown_select__options}>
          {options.map((option: IGendersResponse) => (
            <div
              key={option.id}
              className={styles.dropdown_select__options__item}
              onClick={() => optionClickHandler(option.id)}
            >
              {option.genderName}
            </div>
          ))}
        </div>
      </div>
    </FieldWrapper>
  );
};

export default GenderSelect;
