import {ChangeEvent, FC, InputHTMLAttributes, useEffect, useMemo, useRef, useState} from 'react';
import InputErrorHint from '../InputErrorHint';
import {
  defaultFileMimeTypes,
  defaultPhotoTypesWithoutImage,
  defaultVideoMimeTypes,
  IMAGE_SIZE_MAX,
} from '../../../core/constants';

import {ReactComponent as UploadIcon} from 'assets/icons/upload-lg.svg';

import {getUserPhotoSrc} from './helpers';
import styles from './MediaInput.module.scss';
import cx from 'classnames';
import FieldWrapper from 'shared/inputs/FieldWrapper';
import {FieldBaseProps} from '../../../core/types';
import {convertFileSizeToMb, getImageTypeErrorText, validateFileType} from '../../../core/functions';
import {API} from '../../../core/API';
import i18n from 'i18next';
import {isVideo} from '../../../pages/Organization/Marketing/Statuses/helpers';

type MediaInputProps = FieldBaseProps<
  Pick<InputHTMLAttributes<HTMLInputElement>, 'type' | 'placeholder' | 'min' | 'max' | 'onKeyPress'> & {
    label: string;
    placeholder?: string;
    maxMegabyteSize?: number;
    toS3bucket?: boolean;
  }
>;

const MediaInput: FC<MediaInputProps> = ({placeholder, ...fieldBaseProps}) => {
  const {field, form} = fieldBaseProps;
  const inputRef = useRef<HTMLInputElement>(null);
  const validationT = i18n.getFixedT(null, 'validation');
  const [isLoading, setIsLoading] = useState(false);

  const mediaUrl = useMemo(
    () => (field.value instanceof File ? URL.createObjectURL(field.value) : (field.value as string)),
    [field.value]
  );

  useEffect(() => {
    return () => {
      if (mediaUrl) URL.revokeObjectURL(mediaUrl);
    };
  }, []);

  const deleteMedia = async () => {
    await form.setFieldTouched(field.name, true);
    await form.setFieldValue(field.name, '');
    if (mediaUrl) URL.revokeObjectURL(mediaUrl);
  };

  const selectMediaHandler = () => {
    if (inputRef?.current) {
      inputRef.current.click();
    }
  };

  const changeMediaHandler = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const file = event.target.files?.item(0);
    if (file) {
      form.setFieldTouched(field.name, true, true).finally();
      form.setFieldValue(field.name, file).finally();
    }
  };

  return (
    <FieldWrapper {...fieldBaseProps}>
      {field.value ? (
        <div className={styles.mediaInput}>
          <div className={styles.mediaInput__image_hover} onClick={deleteMedia}>
            {isVideo(field.value) ? (
              // eslint-disable-next-line jsx-a11y/media-has-caption
              <video className={cx(styles.mediaInput__image)} src={mediaUrl} muted />
            ) : (
              <img className={cx(styles.mediaInput__image)} src={mediaUrl} alt={placeholder || ''} />
            )}
          </div>
          <span className={styles.mediaInput_placeholder}>{placeholder}</span>
        </div>
      ) : (
        <div className={styles.mediaInput}>
          <UploadIcon className={styles.mediaInput__image} onClick={selectMediaHandler} />
          <span className={styles.mediaInput_placeholder}>{placeholder}</span>
        </div>
      )}
      <input
        ref={inputRef}
        id="imagePath"
        type="file"
        hidden
        accept={defaultFileMimeTypes}
        onChange={changeMediaHandler}
      />
    </FieldWrapper>
  );
};

export default MediaInput;
