//types
import {WHICH_DAY_FILTER_FORMAT} from 'core/types';

export const getDayValue = (day: string, format: WHICH_DAY_FILTER_FORMAT): string => {
  switch (format) {
    case WHICH_DAY_FILTER_FORMAT.TWO_LETTERS:
      return day;

    case WHICH_DAY_FILTER_FORMAT.ONE_LETTER:
      return day.at(0) as string;

    default:
      return day;
  }
};

export type Dimension = {
  width: number;
  height: number;
};

export const defaultDayDimension: Dimension = {
  width: 31,
  height: 36,
};
