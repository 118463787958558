import {FC} from 'react';
import {useSelector} from 'react-redux';
import cx from 'classnames';
import {useTranslation} from 'react-i18next';
//redux
import {Store} from 'redux/root';
import {selectorGetUserSpendTime} from 'redux/user-service/selector';
import {selectorGetSchoolId} from 'redux/organization-service/selector';
//assets
import {ReactComponent as SkillIcoSvg} from 'assets/image/skill-ico-male.svg';
//functions
import {setFullName} from 'core/functions';
// helpers
import {grade, pointCount, timeSpent} from './helpers';
//components
import ImageWithPopup from '../ImageWithPopup';
//styles
import styles from './VolunteerMainInfo.module.scss';

type VolunteerMainInfoProps = {
  parentClass?: string;
  points: number;
  spendTime?: number;
  userImage: string;
  userFirstName?: string;
  userLastName?: string;
  userGrade?: string;
  disableAvatarPopup?: boolean;
};

const VolunteerMainInfo: FC<VolunteerMainInfoProps> = ({
  parentClass,
  points,
  spendTime,
  userImage,
  userFirstName,
  userLastName,
  userGrade,
  disableAvatarPopup,
}: VolunteerMainInfoProps) => {
  const userSpendTime = useSelector(selectorGetUserSpendTime);
  const schoolId = useSelector(selectorGetSchoolId);

  const {t} = useTranslation('popup');

  return (
    <div className={cx(styles.team__user, parentClass)}>
      <ImageWithPopup
        classNames={styles.user__logo}
        imagePath={userImage}
        popupTitle={t(`profilePicture.${schoolId ? 'student' : 'volunteer'}`)}
        disablePopup={!!disableAvatarPopup}
      />
      <div className={styles.user__description}>
        <div className={styles.user__name} title={setFullName(userFirstName, userLastName)}>
          {setFullName(userFirstName, userLastName)}
        </div>
        <div className={styles.user__skill}>
          <div className={styles.skill__ico}>
            <SkillIcoSvg />
          </div>
          <div className={styles.skill__grade}>{grade(userGrade)}</div>
        </div>
        <div className={styles.skill__pointCount}>{pointCount(points)}</div>
        {(!!spendTime || !!userSpendTime) && (
          <div className={styles.volunteerSpendTime}>{timeSpent(userSpendTime, spendTime)}</div>
        )}
      </div>
    </div>
  );
};

export default VolunteerMainInfo;
