// types
import {IVolunteersSpendTimeResponse} from '@joc/api-gateway';
// translation
import i18n from 'components/translate';

export const getTransformChartData = (usersGradeTimeSpent: IVolunteersSpendTimeResponse[]) => {
  return usersGradeTimeSpent.map(({gradeId, spendTime}) => {
    return {
      name: `${i18n.t('statistics:userGradeTimeSpent.grade')} ${
        gradeId || i18n.t('statistics:userGradeTimeSpent.gradeUndefined')
      }`,
      [i18n.t('common:titles.totalHours')]: spendTime,
    };
  });
};
