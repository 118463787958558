import {FC, useEffect, useState} from 'react';
import {useLocation} from 'react-router';
//translation
import {useTranslation} from 'react-i18next';
//components
import RecoverySendEmailForm from 'pages/RecoveryPassword/RecoverySendEmailForm';
import Appeal from 'shared/components/Appeal/DefaultRenderer';
import ArrowBackLink from 'shared/components/ArrowBackLink';
import RecoveryForm from 'pages/RecoveryPassword/RecoveryForm';
import RecoveryCongrats from 'pages/RecoveryPassword/RecoveryCongrats';
import ResponseFailure from 'shared/components/ResponseFailure';
//styles
import styles from './RecoveryPassword.module.scss';

const RecoveryPassword: FC = () => {
  const [sentEmailSuccess, setSentEmailSuccess] = useState(false);
  const [isRecoverySuccess, setIsRecoverySuccess] = useState(false);
  const [queryEmail, setQueryEmail] = useState('');
  const [queryFirebaseCode, setQueryFirebaseCode] = useState('');
  const [responseError, setResponseError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isNoPasswordProvider, setIsNoPasswordProvider] = useState(false);
  // const [recoveredEmail, setRecoveredEmail] = useState('');

  const location = useLocation();

  const {t} = useTranslation(['buttons', 'popup']);

  useEffect(() => {
    if (location.search.length) {
      const query = new URLSearchParams(location.search);
      const queryEmail = query.get('email');
      const queryFirebaseCode = query.get('oobCode');
      queryEmail && setQueryEmail(queryEmail);
      queryFirebaseCode && setQueryFirebaseCode(queryFirebaseCode);
    }
  }, [location]);

  if (responseError)
    return (
      <ResponseFailure
        message={errorMessage}
        buttonTitle={isNoPasswordProvider ? t('buttons:button.addPassword') : t('buttons:button.tryAgain')}
        buttonClickHandler={() =>
          // isNoPasswordProvider && recoveredEmail
          // 	? history.push(`${urls.onboardingLoginFromEmail}?email=${recoveredEmail}`)
          // 	:
          setResponseError(false)
        }
      />
    );

  return (
    <div className={styles.content}>
      {!sentEmailSuccess && <ArrowBackLink />}
      <Appeal styleRecoveryCongrats={sentEmailSuccess || isRecoverySuccess} stylePensive={!queryEmail}>
        {queryEmail && !isRecoverySuccess ? (
          <RecoveryForm
            userEmail={queryEmail}
            queryFirebaseCode={queryFirebaseCode}
            setIsRecoverySuccess={setIsRecoverySuccess}
            setResponseError={setResponseError}
            setErrorMessage={setErrorMessage}
          />
        ) : sentEmailSuccess ? (
          <RecoveryCongrats title={t('popup:congratsOppo.recoveryPesswordEmailSent')} />
        ) : isRecoverySuccess ? (
          <RecoveryCongrats
            title={t('popup:congratsOppo.passwordSuccessfullyChanged')}
            isRecoverySuccess={isRecoverySuccess}
          />
        ) : (
          <RecoverySendEmailForm
            setSentEmailSuccess={setSentEmailSuccess}
            setResponseError={setResponseError}
            setErrorMessage={setErrorMessage}
            setIsNoPasswordProvider={setIsNoPasswordProvider}
            // setRecoveredEmail={setRecoveredEmail}
          />
        )}
      </Appeal>
    </div>
  );
};

export default RecoveryPassword;
