import {ChangeEvent, FC, useEffect, useMemo} from 'react';
//types
import {IGroupSpendTimeRequest} from '@joc/api-gateway';
import {FieldBaseProps} from 'core/types';
//helpers
import {directionOptions, groupTypeOptionsOrg, groupTypeOptionsSchool, initialValue} from './helpers';
//components
import FieldWrapper from 'shared/inputs/FieldWrapper';
import InputBase from 'shared/inputs/Input/InputBase';
import SelectBase from 'shared/inputs/Select/SelectBase';
//styles
import styles from '../../styles.module.scss';

type SpendTimeControlProps = FieldBaseProps<{
  isSchool?: boolean;
}>;

const SpendTimeControl: FC<SpendTimeControlProps> = ({isSchool, ...fieldBaseProps}) => {
  const {field, form} = fieldBaseProps;
  const {setFieldValue, setFieldTouched} = form;
  const {direction, amount, groupType} = field.value || initialValue;

  useEffect(() => {
    if (!field.value) setFieldValue(field.name, {direction, amount, groupType});
  }, []);

  const handleControlBlur = () => {
    setFieldTouched(field.name, true);
  };

  const handleChangePeriod = (value: string) => {
    handleControlBlur();
    setFieldValue(field.name, {...field.value, groupType: value});
  };

  const handleChangeDirection = (value: string) => {
    handleControlBlur();
    setFieldValue(field.name, {...field.value, direction: value as keyof IGroupSpendTimeRequest});
  };

  const handleChangeAmount = (e: ChangeEvent<HTMLInputElement>) => {
    handleControlBlur();
    setFieldValue(field.name, {...field.value, amount: e.target.value});
  };

  const groupTypeOptions = useMemo(() => (isSchool ? groupTypeOptionsSchool : groupTypeOptionsOrg), [isSchool]);

  return (
    <FieldWrapper {...fieldBaseProps}>
      <div className={styles.control__spendTime}>
        <SelectBase
          value={groupType}
          filterDisabled={groupTypeOptions.length <= 1}
          options={groupTypeOptions}
          onBlur={handleControlBlur}
          onChange={handleChangePeriod}
        />
        <SelectBase
          value={direction}
          options={directionOptions}
          onBlur={handleControlBlur}
          onChange={handleChangeDirection}
        />
        <InputBase
          min={0}
          type="number"
          value={amount}
          isPencilHidden
          onBlur={handleControlBlur}
          onChange={handleChangeAmount}
        />
      </div>
    </FieldWrapper>
  );
};

export default SpendTimeControl;
