import {FC} from 'react';
import {ChannelMemberResponse} from 'stream-chat';
//functions
import {getCleanImage} from 'shared/components/RandomImage';
//components
import CustomAvatar from 'shared/components/ChatOk/ChatComponents/CustomAvatar';

type AvatarGroupProps = {members: Array<ChannelMemberResponse>};

const AvatarGroup: FC<AvatarGroupProps> = ({members}) => {
  switch (members.length) {
    case 1:
      return <CustomAvatar image={getCleanImage(members[0])} size={57} />;

    case 2:
      return (
        <div className="channel-preview__avatars two">
          <span>
            <CustomAvatar image={getCleanImage(members[0])} shape="square" size={57} />
          </span>
          <span>
            <CustomAvatar image={getCleanImage(members[1])} shape="square" size={57} />
          </span>
        </div>
      );

    case 3:
      return (
        <div className="channel-preview__avatars three">
          <span>
            <CustomAvatar image={getCleanImage(members[0])} shape="square" size={57} />
          </span>
          <span>
            <CustomAvatar image={getCleanImage(members[1])} shape="square" size={20} />
            <CustomAvatar image={getCleanImage(members[2])} shape="square" size={20} />
          </span>
        </div>
      );

    default:
      return (
        <div className="channel-preview__avatars">
          <span>
            <CustomAvatar image={getCleanImage(members[members.length - 1])} shape="square" size={20} />
            <CustomAvatar image={getCleanImage(members[members.length - 2])} shape="square" size={20} />
          </span>
          <span>
            <CustomAvatar image={getCleanImage(members[members.length - 3])} shape="square" size={20} />
            <CustomAvatar image={getCleanImage(members[members.length - 4])} shape="square" size={20} />
          </span>
        </div>
      );
  }
};

export default AvatarGroup;
