import {FC, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {useLocation} from 'react-router';
import qs from 'qs';
import {useTranslation} from 'react-i18next';
//redux
import {Store} from 'redux/root';
//types
import {IUserResponse, VOLUNTEER} from '@joc/api-gateway';
//constants
import {TABLE_HEADERS, TABLE_ITEMS_CLASSNAMES, TABLE_ROW_CLASSNAMES} from 'core/constants';
//components
import AvatarCell from 'shared/components/Table/CellRenderers/AvatarCell';
import CellDefault from 'shared/components/Table/CellRenderers/CellDefault';
import FullNameCell from 'shared/components/Table/CellRenderers/FullNameCell';
import TableBody from 'shared/components/Table/TableBody';
import TableHead from 'shared/components/Table/TableHead';
import TableHeadCell from 'shared/components/Table/TableHead/TableHeadCell';
import TableMain from 'shared/components/Table/TableMain';
import TableRow from 'shared/components/Table/TableRow';
import Loader from 'shared/components/Loader';
import ResponseFailure from 'shared/components/ResponseFailure';
//styles
import styles from './index.module.scss';

const MembersTable: FC = () => {
  const location = useLocation();

  const adminsList = useSelector((store: Store) => store.organizationRedux.organizationMembers.admins);
  const coordinatorsList = useSelector((store: Store) => store.organizationRedux.organizationMembers.coordinators);
  const marketersList = useSelector((store: Store) => store.organizationRedux.organizationMembers.marketers);
  const allMembersList = useSelector((store: Store) => store.organizationRedux.organizationMembers.all);
  const isLoading = useSelector((store: Store) => store.organizationRedux.organizationMembers.isLoading);

  const membersRecords = useMemo(() => {
    const {search} = location;
    const {role} = qs.parse(search, {ignoreQueryPrefix: true});

    switch (role) {
      case VOLUNTEER.ADMIN:
        return adminsList;
      case VOLUNTEER.COORDINATOR:
        return coordinatorsList;
      case VOLUNTEER.MARKETER:
        return marketersList;
      default:
        return allMembersList;
    }
  }, [location.search, adminsList, coordinatorsList, marketersList, allMembersList]);

  const {t} = useTranslation(['popup', 'errors']);

  return (
    <>
      {!membersRecords.length && isLoading ? (
        <Loader loadProps={{parentClassName: styles.loader}} />
      ) : !isLoading && !membersRecords.length ? (
        <ResponseFailure
          styleTable
          message={<span className={styles.error_message}>{t('errors:couldntFindMembers')}</span>}
          buttonUnshow
        />
      ) : (
        <TableMain>
          <TableHead className={styles.table__header}>
            <TableHeadCell itemClassName={TABLE_ITEMS_CLASSNAMES.avatar} text={TABLE_HEADERS.logo} />
            <TableHeadCell itemClassName={TABLE_ITEMS_CLASSNAMES.chesedName} text={TABLE_HEADERS.fullName} />
            <TableHeadCell itemClassName={TABLE_ITEMS_CLASSNAMES.email} text={TABLE_HEADERS.email} />
            <TableHeadCell itemClassName={TABLE_ITEMS_CLASSNAMES.phone} text={TABLE_HEADERS.phoneNumber} />
          </TableHead>
          <TableBody className={styles.table__body}>
            {membersRecords.map((member: IUserResponse) => {
              return (
                <TableRow callChild={TABLE_ROW_CLASSNAMES.members} className={styles.table__row} key={member.id}>
                  <AvatarCell imagePath={member.imagePath} avatarTitle={t('popup:profilePicture.user')} />
                  <FullNameCell
                    parentClassName={styles.table__name}
                    firstName={member.firstName}
                    lastName={member.lastName}
                  />
                  <CellDefault parentClassName={TABLE_ITEMS_CLASSNAMES.email} text={member.email || ''} />
                  <CellDefault parentClassName={TABLE_ITEMS_CLASSNAMES.phone} text={member.phoneNumber || ''} />
                </TableRow>
              );
            })}
          </TableBody>
        </TableMain>
      )}
    </>
  );
};

export default MembersTable;
