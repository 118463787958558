import {ChangeEvent, FC, useState} from 'react';
import {useTranslation} from 'react-i18next';
//material
import {FormControlLabel, Modal, Radio, RadioGroup} from '@material-ui/core';
//types
import {RECURRENCE_POSSIBLE_UPDATE} from '@joc/api-gateway';
//helpers
import {updateOpportunityOptions} from './helpers';
//components
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
//styles
import styles from './RepeatedActionTypeModal.module.scss';

type RepeatedActionTypeModalProps = {
  closePopupClickHandler: () => void;
  handleSubmit: (updateType: RECURRENCE_POSSIBLE_UPDATE) => void;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  isModalOpen: boolean;
  isDelete?: boolean;
};

const RepeatedActionTypeModal: FC<RepeatedActionTypeModalProps> = ({
  closePopupClickHandler,
  handleSubmit,
  setFieldValue,
  isModalOpen,
  isDelete,
}) => {
  const {t} = useTranslation(['form', 'buttons']);

  const [updateType, setUpdateType] = useState(RECURRENCE_POSSIBLE_UPDATE.FUTURE);

  const submitClickHandler = () => handleSubmit(updateType);

  const onUpdateTypeChangeHandler = (recurrenceType: ChangeEvent<HTMLInputElement>) => {
    const {value} = recurrenceType.target;
    if (setFieldValue) setFieldValue('recurrencePossibleUpdate', value);
    setUpdateType(value as RECURRENCE_POSSIBLE_UPDATE);
  };

  const title = isDelete
    ? t('form:opportunity.deleteRepeatOpportunity')
    : t('form:opportunity.updateRepeatOpportunity');

  return (
    <div className={styles.popup__wrapper}>
      <Modal open={isModalOpen} onClose={closePopupClickHandler}>
        <div className={styles.popup}>
          <span className={styles.title}>{title}</span>

          <RadioGroup onChange={onUpdateTypeChangeHandler} value={updateType} className={styles.group}>
            {updateOpportunityOptions.map(({label, value}) => (
              <FormControlLabel
                value={value}
                control={<Radio />}
                label={label}
                key={value + label}
                className={styles.label}
              />
            ))}
          </RadioGroup>

          <div className={styles.button__wrapper}>
            <ButtonDefault
              classList={['md', 'text']}
              title={t('buttons:button.cancel')}
              clickHandler={closePopupClickHandler}
              parentClassName={styles.button}
            />

            <ButtonDefault
              classList={['md', 'text']}
              title={t('buttons:button.save')}
              clickHandler={submitClickHandler}
              parentClassName={styles.button}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default RepeatedActionTypeModal;
