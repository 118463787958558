import {FC, ReactNode} from 'react';
import {NavLink} from 'react-router-dom';
//styles
import styles from './HeaderNavItem.module.scss';

type HeaderNavItemProps = {
  linkto: string;
  linktext: string;
  children: ReactNode;
  active?: boolean;
};

const HeaderNavItem: FC<HeaderNavItemProps> = ({linkto, linktext, children}: HeaderNavItemProps) => {
  return (
    <NavLink activeClassName={styles.headerNav__item_active} className={styles.headerNav__item} to={linkto} exact>
      {children}
      <div className={styles.headerNav__text}>{linktext}</div>
    </NavLink>
  );
};

export default HeaderNavItem;
