import {forwardRef, ReactNode, RefObject} from 'react';
import cx from 'classnames';
//styles
import styles from './TableMain.module.scss';

type TableMainProps = {
  children: ReactNode;
  className?: string;
  ref?: RefObject<HTMLDivElement>;
};

const TableMain = forwardRef<HTMLDivElement, TableMainProps>(({children, className}: TableMainProps, ref) => {
  return (
    <div ref={ref} className={cx(styles.main, className)}>
      {children}
    </div>
  );
});

TableMain.displayName = 'TableMain';

export default TableMain;
