import {FC} from 'react';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Link, useHistory} from 'react-router-dom';
import firebase from 'firebase';
import cx from 'classnames';
//redux
import {userResetData, deleteUserNotificationToken} from 'redux/user-service/actions';
import {resetOrgInfo} from 'redux/organization-service/actions';
import {resetFilter} from 'redux/filter-service/actions';
import {chatClient, resetChat} from 'redux/chat-service/actions';
//urls
import {urls} from 'core/appUrls';
//styles
import styles from './AccountMenuDropdownItem.module.scss';

type AccountMenuDropdownItemParentProps = {
  text: string;
  img: string;
  link?: string;
  logout?: boolean;
  disabled?: boolean;
  isBlank?: boolean;
};

const AccountMenuDropdownItem: FC<AccountMenuDropdownItemParentProps> = ({
  link,
  text,
  img,
  logout = false,
  disabled,
  isBlank,
}: AccountMenuDropdownItemParentProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {t} = useTranslation('messages');

  const clickHandler = async () => {
    try {
      dispatch(deleteUserNotificationToken());
      dispatch(userResetData());
      dispatch(resetOrgInfo());
      dispatch(resetChat());
      dispatch(resetFilter());
      await firebase.auth().signOut();
      localStorage.clear();
      history.push(urls.onboardingChooseUserType);
      chatClient.disconnectUser();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {!logout && link ? (
        <Link
          className={cx(styles.userMenu__item, {[styles.disabled]: disabled})}
          to={link}
          target={isBlank ? '_blank' : undefined}
        >
          <div className={styles.userMenu__ico}>
            <img src={img} alt="" />
          </div>
          <div className={styles.userMenu__text}>{text}</div>
        </Link>
      ) : (
        <div className={styles.userMenu__item} onClick={clickHandler}>
          <div className={styles.userMenu__ico}>
            <img src={img} alt="" />
          </div>

          <div className={styles.userMenu__text}>
            {disabled && <span>{t('youSuspended')}</span>}
            {text}
          </div>
        </div>
      )}
    </>
  );
};

export default AccountMenuDropdownItem;
