//types
import {IGetLocationAddressByIdResponse} from '@joc/api-gateway';

export const getAddressString = (address: IGetLocationAddressByIdResponse) => {
  const addressKeys = ['streetName', 'stateName', 'countryName'];

  const addressArray = Object.entries(address)
    .filter((entry) => {
      const [key, value] = entry;
      if (addressKeys.includes(key) && value) return true;
    })
    .map((entry) => entry.at(1));

  const addressSring = addressArray.join(', ');

  return addressSring;
};
