import update from 'immutability-helper';
import {
  IInviteUserRequest,
  INewCreateOrganizationRequest,
  INewCreateUserRequest,
  InviteUserRequest,
  IOrganisationResponse,
  IVideoResponse,
  NewCreateOrganizationRequest,
  NewCreateUserRequest,
  VideoMetadataResponse,
  VideoResponse,
} from '@joc/api-gateway';
import {
  GET_ORG_INFO,
  GET_OPPO_SETTINGS,
  GET_ORG_VIDEOS,
  RESET_CREATE_ORG_INFO,
  RESET_ORG_INFO,
  SET_ORG_ABOUT_ORG,
  SET_ORG_ABOUT_YOU,
  SET_ORG_CONTACT,
  SET_ORG_INVITED_COLLEAGUES,
  UPLOAD_ORG_VIDEO,
  SET_CURRENT_VIDEO,
  UNSET_CURRENT_VIDEO,
  DELETE_CURRENT_VIDEO,
  ADD_VIDEO_COUNT_WATCH,
  GET_ORG_MEMBERS,
  RESET_ORG_MEMBERS,
  SEARCH_ORG_MEMBERS,
  SET_IS_LOADING_SEARCH_ORG_MEMBERS,
  GET_OPPO_SETTINGS_FOR_VOLUNTEER,
  GET_GRADE_SETTINGS,
  SET_IS_LOADING_GRADES,
} from '../actions-types';
import {ContactOrg, GetMembersPayload, IOrganizationReducer, OrgActionTypes} from './types';
import {ORGANISATION_ACTIVE_STATUS} from '@joc/api-gateway/lib/api-client';

const initState: IOrganizationReducer = {
  createOrganizationInfo: {
    invitedUsers: undefined,
    owner: NewCreateUserRequest.fromJS({
      firstName: '',
      lastName: '',
      imagePath: '',
      email: '',
      phoneNumber: '',
      positionId: 0,
      roleId: 0,
      birthDate: '',
      address: {
        countryName: '',
        stateName: '',
        cityName: '',
        streetName: '',
        buildingNumber: '',
        zipCode: '',
        pobox: '',
      },
      gender: 0,
    }),
    orgData: NewCreateOrganizationRequest.fromJS({
      organizationName: '',
      organisationGeneralType: '',
      organizationLogoPath: '',
      organizationDescription: '',
      organizationPhoneNumber: '',
      organizationEmail: '',
      organizationWebSite: '',
      organisationAddress: {
        countryName: '',
        stateName: '',
        cityName: '',
        streetName: '',
        buildingNumber: '',
        zipCode: '',
        pobox: '',
      },
    }),
  },
  organizationInfo: null,
  organizationRoles: [],
  organizationPositions: [],
  organizationVideos: [],
  organizationMembers: {
    all: [],
    admins: [],
    coordinators: [],
    marketers: [],
    isLoading: false,
  },
  opportunitySettings: null,
  oppoSettingsForVolunteer: [],
  isLoadedOppoSettingsForVolunteer: false,
  grades: [],
  isLoadingGrades: false,
  payGateStatus: false,
};

const organizationReducer = (state = initState, action: OrgActionTypes): IOrganizationReducer => {
  const {type, payload}: OrgActionTypes = action;

  switch (type) {
    case SET_ORG_ABOUT_YOU:
      return {
        ...state,
        createOrganizationInfo: {
          ...state.createOrganizationInfo,
          owner: NewCreateUserRequest.fromJS({
            ...state.createOrganizationInfo.owner,
            ...(payload as INewCreateUserRequest),
          }),
        },
      };
    case SET_ORG_ABOUT_ORG:
      return {
        ...state,
        createOrganizationInfo: {
          ...state.createOrganizationInfo,
          orgData: NewCreateOrganizationRequest.fromJS({
            ...state.createOrganizationInfo.orgData,
            ...(payload as INewCreateOrganizationRequest),
          }),
        },
      };
    case SET_ORG_CONTACT: {
      return {
        ...state,
        createOrganizationInfo: {
          ...state.createOrganizationInfo,
          orgData: NewCreateOrganizationRequest.fromJS({
            ...state.createOrganizationInfo.orgData,
            ...(payload as ContactOrg),
          }),
        },
      };
      // return {
      // 	...state,
      // 	createOrganizationInfo: { ...state.createOrganizationInfo, ...(payload as ContactOrg) },
      // };
    }
    case SET_ORG_INVITED_COLLEAGUES: {
      return {
        ...state,
        createOrganizationInfo: {
          ...state.createOrganizationInfo,
          invitedUsers: state.createOrganizationInfo.invitedUsers?.length
            ? [...state.createOrganizationInfo.invitedUsers, ...(payload as Array<IInviteUserRequest>)].map((el) =>
                InviteUserRequest.fromJS(el)
              )
            : [...(payload as Array<IInviteUserRequest>)].map((el) => InviteUserRequest.fromJS(el)),
          // InviteUsersOrganizationRequest
        },
      };
    }
    case RESET_CREATE_ORG_INFO:
      return {...state, createOrganizationInfo: {...initState.createOrganizationInfo}};
    case GET_ORG_INFO:
      return {
        ...state,
        organizationInfo: payload as IOrganisationResponse,
        payGateStatus: payload.organizationActiveStatus?.status !== ORGANISATION_ACTIVE_STATUS.ACTIVE,
      };
    case GET_OPPO_SETTINGS:
      return {...state, opportunitySettings: payload};
    case GET_GRADE_SETTINGS:
      return {...state, grades: payload};
    case GET_OPPO_SETTINGS_FOR_VOLUNTEER:
      return {...state, oppoSettingsForVolunteer: payload, isLoadedOppoSettingsForVolunteer: true};
    case GET_ORG_VIDEOS:
      return {...state, organizationVideos: payload as Array<IVideoResponse>};
    case UPLOAD_ORG_VIDEO:
      return {
        ...state,
        organizationInfo: {
          ...(state.organizationInfo as IOrganisationResponse),
          primaryVideo: payload as VideoResponse,
        },
        organizationVideos: [payload as IVideoResponse, ...state.organizationVideos],
      };
    case SET_CURRENT_VIDEO:
      return {
        ...state,
        organizationInfo: {
          ...(state.organizationInfo as IOrganisationResponse),
          primaryVideo: {
            ...state.organizationInfo?.primaryVideo,
            id: payload as number,
          } as VideoResponse,
        },
      };
    case UNSET_CURRENT_VIDEO:
      return {
        ...state,
        organizationInfo: {
          ...(state.organizationInfo as IOrganisationResponse),
          primaryVideo: {
            ...state.organizationInfo?.primaryVideo,
            id: payload as number,
          } as VideoResponse,
        },
      };
    case DELETE_CURRENT_VIDEO:
      return {
        ...state,
        organizationVideos: state.organizationVideos.filter((el) => +el.id !== (payload as number)),
      };

    case ADD_VIDEO_COUNT_WATCH: {
      const index = state.organizationVideos.findIndex((i) => i.id === (payload as number));
      if (index.toString() !== '-1')
        return update(state, {
          organizationVideos: {
            [index]: {
              $set: {
                ...state.organizationVideos[index],
                metadata: VideoMetadataResponse.fromJS({
                  ...state.organizationVideos[index].metadata,
                  watchedCount: state.organizationVideos[index].metadata.watchedCount + 1,
                }),
              },
            },
          },
        });
      return state;
    }

    case GET_ORG_MEMBERS:
      return {...state, organizationMembers: payload as GetMembersPayload};

    case SET_IS_LOADING_SEARCH_ORG_MEMBERS:
      return {...state, organizationMembers: {...state.organizationMembers, ...{isLoading: payload}}};

    case SEARCH_ORG_MEMBERS:
      return {...state, organizationMembers: {...state.organizationMembers, ...payload}};

    case RESET_ORG_MEMBERS:
      return {...state, organizationMembers: initState.organizationMembers};

    case RESET_ORG_INFO: {
      return initState;
    }

    case SET_IS_LOADING_GRADES: {
      return {...state, isLoadingGrades: payload};
    }
    default:
      return state;
  }
};

export default organizationReducer;
