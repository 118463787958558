import {FC, useEffect} from 'react';
import {Field, Form, useFormikContext} from 'formik';
import moment from 'moment/moment';
import cx from 'classnames';
//components
import Input from '../../../../../../shared/inputs/Input';
import {DatePicker} from '../../../../../../shared/components/Pickers/NewUI/DatePicker';
import {TimePicker} from '../../../../../../shared/components/Pickers/NewUI/TimePicker';
import MediaInput from '../../../../../../shared/components/MediaInput';
// helpers
import {IStatusResponse} from '@joc/api-gateway';
// styles
import styles from './StatusForm.module.scss';

type StatusFormProps = {
  currentValues: (values: Partial<IStatusResponse>) => void;
  formState: (state: {isValid: boolean; dirty: boolean}) => void;
};

const StatusForm: FC<StatusFormProps> = ({currentValues, formState}) => {
  const {values, isValid, dirty, setFieldTouched} = useFormikContext<Partial<IStatusResponse> & {startTime: string}>();

  useEffect(() => {
    const convertedStartDate = values.startTime
      ? moment(values.startDate)
          .set({
            hour: parseInt(values.startTime.split(':')[0], 10),
            minute: parseInt(values.startTime.split(':')[1], 10),
          })
          .toDate()
      : moment(values.startDate).toDate();

    currentValues({...values, startDate: convertedStartDate} as Partial<IStatusResponse>);
  }, [values]);

  useEffect(() => {
    setFieldTouched('link', true).finally();
    setFieldTouched('linkTitle', true).finally();

    formState({isValid, dirty});
  }, [isValid, dirty]);

  return (
    <Form className={styles.statusForm}>
      <Field
        name="mediaPath"
        label="Photo or Video"
        maxMegabyteSize={100}
        toS3bucket
        isRequired
        placeholder="Photos are displayed for 60 seconds, Videos - 1 minute and 30 seconds"
        parentClassName={styles.statusForm__feild}
        component={MediaInput}
      />

      <Field
        name="caption"
        label="Status caption"
        placeholder="Status caption "
        parentClassName={styles.statusForm__feild}
        component={Input}
      />
      <Field
        name="linkTitle"
        label="Link title"
        placeholder="Link title"
        parentClassName={styles.statusForm__feild}
        component={Input}
      />
      <Field
        name="link"
        label="URL-Link"
        placeholder="https://"
        parentClassName={styles.statusForm__feild}
        component={Input}
      />

      <div className={styles.statusForm__pickers}>
        <Field
          name="startDate"
          label="Publication Date"
          availableFuture
          disablePast
          parentClassName={cx(styles.statusForm__feild, styles.statusForm__pickers__field)}
          component={DatePicker}
        />
        <Field
          name="startTime"
          label="Publication time"
          parentClassName={cx(styles.statusForm__feild, styles.statusForm__pickers__field)}
          disablePast
          component={TimePicker}
        />
      </div>
    </Form>
  );
};

export default StatusForm;
