import {FC} from 'react';
//components
import Checkbox from 'shared/components/Checkbox';
//styles
import styles from './CheckboxVolunteerCell.module.scss';

type CheckboxVolunteerCellProps = {
  changeHandle: (checked: boolean, value?: string) => void;
  volunteerId: number;
  defaultChecked: boolean;
  disabled?: boolean;
};

const CheckboxVolunteerCell: FC<CheckboxVolunteerCellProps> = ({
  volunteerId,
  changeHandle,
  defaultChecked,
  disabled,
}: CheckboxVolunteerCellProps) => {
  return (
    <div className={styles.main}>
      <Checkbox
        value={String(volunteerId)}
        changeHandle={changeHandle}
        defaultChecked={defaultChecked}
        disabled={disabled}
      />
    </div>
  );
};

export default CheckboxVolunteerCell;
