//types
import {LanguageName} from 'core/types';
//constants
import {EN_US, HE_IS, languages} from 'core/constants';

export const getCurrentLanguageId = (currentLanguage: LanguageName): number => {
  switch (currentLanguage) {
    case EN_US:
      return languages.en.id;

    case HE_IS:
      return languages.he.id;

    default:
      return languages.en.id;
  }
};

export const getIsHebrew = (lang: string | undefined) => (lang as LanguageName) === HE_IS;

export const getIsEnglish = (lang: string | undefined) => (lang as LanguageName) === EN_US;

export const getTextDirection = (lang?: string) => {
  const textDirection = getIsHebrew(lang) ? 'rtl' : 'ltr';
  const localStorageLanguage = localStorage.getItem('i18nextLng');

  return textDirection;
};
