import {FC} from 'react';
//assets
import {ReactComponent as GoogleSvg} from 'assets/image/social/google.svg';
import {ReactComponent as FacebookSvg} from 'assets/image/social/facebook.svg';
import {ReactComponent as AppleSvg} from 'assets/image/social/apple.svg';
//styles
import styles from './SocialButton.module.scss';

type SocialButtonProps = {
  googleType?: boolean;
  facebookType?: boolean;
  appleType?: boolean;
  clickHandler: () => void;
  isDisabled: boolean;
};

const SocialButton: FC<SocialButtonProps> = ({
  googleType,
  facebookType,
  appleType,
  clickHandler,
  isDisabled,
}: SocialButtonProps) => {
  return (
    <button type="button" className={styles.main} onClick={clickHandler} disabled={isDisabled}>
      {googleType ? (
        <GoogleSvg className={styles.social} />
      ) : facebookType ? (
        <FacebookSvg className={styles.social} />
      ) : appleType ? (
        <AppleSvg className={styles.social} />
      ) : null}
    </button>
  );
};

export default SocialButton;
