import {BaseSyntheticEvent, FC} from 'react';
import cx from 'classnames';
//styles
import styles from './index.module.scss';

interface ButtonDotProps {
  clickHandler: (() => void) | ((e: BaseSyntheticEvent) => void) | undefined;
  classList?: string | Array<string>;
  disabled?: boolean;
}

const ButtonDots: FC<ButtonDotProps> = ({classList, disabled, clickHandler}) => {
  return (
    <button
      className={cx(styles.btn__dotDefault, disabled && styles.btn__dotDefault_disabled, classList)}
      disabled={disabled}
      onClick={clickHandler}
    >
      <div className={styles.dot} />
      <div className={styles.dot} />
      <div className={styles.dot} />
    </button>
  );
};
export default ButtonDots;
