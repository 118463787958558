import {Dispatch, FC, SetStateAction, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import cx from 'classnames';
//scroll
import {clearAllBodyScrollLocks, disableBodyScroll} from 'body-scroll-lock';
//types
import {JOIN_STATE} from 'core/types';
//components
import Appeal from 'shared/components/Appeal/DefaultRenderer';
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
//styles
import styles from './PopupJoinFall.module.scss';

type PopupJoinFallProps = {
  setJoinState: Dispatch<SetStateAction<JOIN_STATE>>;
  message: string;
};

const PopupJoinFall: FC<PopupJoinFallProps> = ({setJoinState, message}: PopupJoinFallProps) => {
  useEffect(() => {
    disableBodyScroll(document.body, {reserveScrollBarGap: true});

    return () => clearAllBodyScrollLocks();
  }, []);

  const {t} = useTranslation(['buttons', 'errors']);

  const confirmButtonClickHandler = () => setJoinState(JOIN_STATE.NONE);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Appeal styleThink>
          <h2 className={cx('title', styles.content__title)}>{t('errors:errorWasHappened')}</h2>

          <div>{message}</div>

          <ButtonDefault
            classList={['primary']}
            title={t('buttons:button.confirm')}
            clickHandler={confirmButtonClickHandler}
          />
        </Appeal>
      </div>
    </div>
  );
};

export default PopupJoinFall;
