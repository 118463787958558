import {FC, useEffect, useState} from 'react';
import {useHistory} from 'react-router';
//urls
import {urls} from 'core/appUrls';
//translation
import {useTranslation} from 'react-i18next';
//components
import AppealDefaultRenderer from 'shared/components/Appeal/DefaultRenderer';
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
//styles
import styles from './SingupCongratulation.module.scss';

const SignUpCongratulation: FC = () => {
  const [socialSignup] = useState(!!localStorage.getItem('socialSignup'));

  const history = useHistory();

  const {t} = useTranslation(['buttons', 'messages']);

  useEffect(() => {
    localStorage.clear();
  }, []);

  return (
    <AppealDefaultRenderer styleCongrats>
      <div className={styles.appeal}>
        <h1 className="title">{t('messages:congratulations')}</h1>
        <div className={styles.appeal__subtitle}>
          {t('messages:youArePart')} <br /> {t('messages:jocExclusiveMembers')}
          <br />
          <br />
          {!socialSignup && t('messages:checkEmail')}
        </div>
        {socialSignup && (
          <ButtonDefault
            classList={['primary', 'default']}
            title={t('buttons:button.goToLogin')}
            clickHandler={() => history.push(urls.onboardingLoginVolunteer)}
          />
        )}
      </div>
    </AppealDefaultRenderer>
  );
};

export default SignUpCongratulation;
