// react
import {FC, useState, MouseEvent} from 'react';
import qs from 'qs';
// translation
import {useTranslation} from 'react-i18next';
// constants
import {urls} from 'core/appUrls';
// react-router
import {useHistory} from 'react-router';
// components
import Button from '@material-ui/core/Button';
import {UserInfoPopup} from './UserInfoPopup';
import VolunteerPastOpportunitiesPopup from 'components/Organization/Volunteers/VolunteersPopups/VolunteerPastOpportunitiesPopup';
// styles
import styles from './index.module.scss';

interface IVolunteerInfoCellProps {
  volunteerId: number;
  isSuspended?: boolean;
  volunteerChatId: string;
  userPhoneNumber?: string;
  volunteerFullName: string;
}

export const VolunteerInfoCell: FC<IVolunteerInfoCellProps> = ({
  isSuspended,
  volunteerId,
  userPhoneNumber,
  volunteerChatId,
  volunteerFullName,
}) => {
  const history = useHistory();
  const {t} = useTranslation(['buttons', 'messages', 'sections']);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isPastOppoPopupOpen, setIsPastOppoPopupOpen] = useState(false);

  const handleOpenUserInfoPopup = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseUserInfoPopup = () => {
    setAnchorEl(null);
  };

  const handleCreateChat = () => {
    const queryString = qs.stringify({
      members: [{chatId: volunteerChatId, chatName: volunteerFullName}],
      type: 'all',
    });

    history.push(`${urls.orgInbox}?${queryString}`);
  };

  return (
    <div className={styles.wrapper}>
      <div
        className={styles.past__oppo}
        title={t(!isSuspended ? 'sections:table.pastOpportunities' : 'messages:organizationSuspended')}
      >
        <Button
          className={styles.past__oppo_button}
          title={t('sections:table.pastOpportunities')}
          onClick={() => setIsPastOppoPopupOpen(true)}
        >
          {t('sections:table.pastOpportunities')}
        </Button>
        {isPastOppoPopupOpen && (
          <VolunteerPastOpportunitiesPopup
            volunteerId={volunteerId}
            setIsShowPopup={setIsPastOppoPopupOpen}
            volunteerFullName={volunteerFullName}
          />
        )}
      </div>
      <UserInfoPopup
        anchorEl={anchorEl}
        isSuspended={isSuspended}
        userFullName={volunteerFullName}
        userPhoneNumber={userPhoneNumber}
        handleCreateChat={handleCreateChat}
        handleClick={handleOpenUserInfoPopup}
        handleClose={handleCloseUserInfoPopup}
      />
    </div>
  );
};
