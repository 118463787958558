import {FC, Fragment, useState} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
//API
import {API} from 'core/API';
//types
import {selectorGetUserVolunteerId} from 'redux/user-service/selector';
//types
import {CreatePaymentRequest, CURRENCIES} from '@joc/api-gateway';
//components
import WhiteContainer from 'shared/components/WhiteContainer';
import ArrowBackLink from 'shared/components/ArrowBackLink';
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
import PopupContainer from 'shared/components/PopupContainer';
import CustomDonation from './CustomDonation';
import RemoveForm from './RemoveForm';
//styles
import styles from './About.module.scss';

const donationAmountOptions = [1, 10, 100, 1000];

const About: FC = () => {
  const {t} = useTranslation(['about', 'buttons', 'popup', 'inputs']);

  const volunteerId = useSelector(selectorGetUserVolunteerId);

  const [isOpenSelectPopup, setIsOpenSelectPopup] = useState(false);
  const [isRemovePopupVisible, setIsRemovePopupVsisble] = useState(false);

  const redirectToDonate = async (sum: number) => {
    try {
      const redirectUrlResponse = await API.createPayment(
        CreatePaymentRequest.fromJS({
          currency: CURRENCIES.USD,
          amount: sum,
          tariffId: 1,
          cancelUrl: window.location.href,
        })
      );
      window.location.href = `${redirectUrlResponse}`;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const removeClickHandler = () => {
    setIsRemovePopupVsisble(!isRemovePopupVisible);
  };

  const chooseDonationAmountHandler = (donationAmount: number) => () => redirectToDonate(donationAmount);

  return (
    <Fragment>
      <ArrowBackLink parentClassName={styles.nav} />
      <WhiteContainer title={t('about:title')}>
        <div className={styles.content}>
          <div className={styles.article__text}>{t('about:paragraph1')}</div>
          <div className={styles.article__text}>{t('about:paragraph2')}</div>
          <div className={styles.article__text}>{t('about:paragraph3')}</div>
        </div>
        <div className={styles.buttonGroup}>
          <ButtonDefault
            clickHandler={() => setIsOpenSelectPopup(true)}
            title={t('buttons:button.donate')}
            classList={['primary', 'lg']}
          />
          {volunteerId ? (
            <ButtonDefault
              title={t('buttons:button.remove')}
              classList={['secondary', 'lg']}
              clickHandler={removeClickHandler}
            />
          ) : null}
        </div>
      </WhiteContainer>
      {isRemovePopupVisible && <RemoveForm closeButtonParentClickHandler={removeClickHandler} />}
      {isOpenSelectPopup && (
        <PopupContainer setIsShowPopup={setIsOpenSelectPopup} parentClassName={styles.popup}>
          <h3>{t('popup:donate.title')}</h3>
          <div className={styles.donationOptions}>
            {donationAmountOptions.map((donationAmount, index) => (
              <ButtonDefault
                clickHandler={chooseDonationAmountHandler(donationAmount)}
                title={`$${donationAmount}`}
                classList={['primary', 'lg']}
                key={index}
              />
            ))}
            <CustomDonation submitHandler={chooseDonationAmountHandler} />
          </div>
        </PopupContainer>
      )}
    </Fragment>
  );
};

export default About;
