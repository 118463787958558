//types
import {
  IGeneralStatisticsResponse,
  IGetVolunteerByIdResponse,
  IOrganisationDashboardResponse,
  IUsersStatisticsResponse,
  IVolunteersAvgAgeResponse,
  IVolunteersSpendTimeResponse,
} from '@joc/api-gateway';
import {DashboardActionTypes, IDashboardReducer, OpportunitiesRegularManualPayload} from './types';
//action types
import {
  GET_DASHBOARD_INFO,
  GET_OPPORTUNITIES_REGULAR_MANUAL,
  GET_STATISTICS_GENERAL,
  GET_USERS_LOGIN_TRAFFIC,
  GET_USERS_SIGNUP_TRAFFIC,
  GET_USERS_GRADE_TOTAL_TIME_TRAFFIC,
  GET_VOLUNTEERS_AVG_AGE,
  GET_VOLUNTEERS_TOP,
} from '../actions-types';
//helpers
import {initState} from './helpers';

const dashboardReducer = (state = initState, action: DashboardActionTypes): IDashboardReducer => {
  const {type, payload}: DashboardActionTypes = action;

  switch (type) {
    case GET_DASHBOARD_INFO:
      return {...state, main: payload as IOrganisationDashboardResponse};
    case GET_STATISTICS_GENERAL:
      return {...state, statistics: {...state.statistics, general: payload as IGeneralStatisticsResponse}};
    case GET_USERS_LOGIN_TRAFFIC:
      return {
        ...state,
        statistics: {...state.statistics, usersLoginTraffic: payload as Array<IUsersStatisticsResponse>},
      };
    case GET_USERS_SIGNUP_TRAFFIC:
      return {
        ...state,
        statistics: {...state.statistics, usersSignupTraffic: payload as Array<IUsersStatisticsResponse>},
      };
    case GET_USERS_GRADE_TOTAL_TIME_TRAFFIC:
      return {
        ...state,
        statistics: {...state.statistics, studentsSpentTime: payload as Array<IVolunteersSpendTimeResponse>},
      };
    case GET_VOLUNTEERS_AVG_AGE:
      return {
        ...state,
        statistics: {...state.statistics, volunteersAvgAge: payload as Array<IVolunteersAvgAgeResponse>},
      };
    case GET_OPPORTUNITIES_REGULAR_MANUAL:
      return {
        ...state,
        statistics: {
          ...state.statistics,
          opportunitiesRegularManual: {
            traffic: (payload as OpportunitiesRegularManualPayload).traffic,
            total: (payload as OpportunitiesRegularManualPayload).total,
          },
        },
      };
    case GET_VOLUNTEERS_TOP: {
      return {
        ...state,
        statistics: {...state.statistics, volunteersTop: payload as Array<IGetVolunteerByIdResponse>},
      };
    }
    default:
      return state;
  }
};

export default dashboardReducer;
