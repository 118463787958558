import {FC, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import {LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer} from 'recharts';
//types
import {IUsersStatisticsResponse} from '@joc/api-gateway';
//components
import ResponseFailure from 'shared/components/ResponseFailure';
import Loader from 'shared/components/Loader';

type UserTrafficChartProps = {
  usersTraffic: Array<IUsersStatisticsResponse>;
  isLoading: boolean;
};

const UserTrafficChart: FC<UserTrafficChartProps> = ({usersTraffic, isLoading}) => {
  const convertedData = useMemo(() => {
    return usersTraffic.length
      ? usersTraffic.map((i) => {
          return {date: moment(i.date).format(process.env.REACT_APP_DATE_FORMAT), count: +i.count};
        })
      : [];
  }, [usersTraffic]);

  const {t} = useTranslation('errors');

  return (
    <ResponsiveContainer width="100%" height={300}>
      {isLoading ? (
        <Loader loadProps={{stylePageCenter: true}} />
      ) : !convertedData.length && !isLoading ? (
        <ResponseFailure message={t('haventGotData')} styleTable buttonUnshow />
      ) : (
        <LineChart
          data={convertedData}
          margin={{
            top: 10,
            right: 30,
            left: 20,
            bottom: 10,
          }}
        >
          <CartesianGrid strokeDasharray="3 5" />
          <XAxis dataKey="data" />
          <YAxis />
          <Tooltip />
          <Line connectNulls type="monotone" dataKey="count" stroke="#4b5ef5" activeDot={{r: 8}} />
        </LineChart>
      )}
    </ResponsiveContainer>
  );
};

export default UserTrafficChart;
