import {Dispatch, FC, ReactNode, SetStateAction, useEffect, useState} from 'react';
import {useLocation} from 'react-router';
import qs from 'qs';
import {useTranslation} from 'react-i18next';
import cx from 'classnames';
//components
import InputSearch from 'shared/components/Inputs/InputSearch';
import ButtonImage from 'shared/components/Buttons/ButtonImage';
//styles
import styles from './TableMenu.module.scss';

type TableMenuProps = {
  searchChangeHandler: (value: string) => void;
  changeVisibilityFilters?: () => void;
  children?: ReactNode;
  isClearSearch?: boolean;
  setIsClearSearch?: Dispatch<SetStateAction<boolean>>;
  disableFilter?: boolean;
  parentClassName?: string;
};

const TableMenu: FC<TableMenuProps> = ({
  changeVisibilityFilters,
  children,
  searchChangeHandler,
  isClearSearch,
  setIsClearSearch,
  disableFilter,
  parentClassName,
}: TableMenuProps) => {
  const location = useLocation();

  const [isDisableFilters, setIsDisableFilters] = useState(!!disableFilter);

  useEffect(() => {
    const queryParams = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });
    if (!disableFilter) queryParams?.groupId ? setIsDisableFilters(true) : setIsDisableFilters(false);
  }, [location.search, disableFilter]);

  const {t} = useTranslation(['inputs', 'tooltips']);

  return (
    <div className={cx(styles.container, parentClassName)}>
      <div className={styles.filter}>
        <InputSearch
          placeholder={t('inputs:placeholders.searchWithDots')}
          classList={['big']}
          changeHandler={searchChangeHandler}
          isClearSearch={isClearSearch}
          setIsClearSearch={setIsClearSearch}
        />
        <ButtonImage
          clickHandler={changeVisibilityFilters}
          disabled={isDisableFilters}
          viewStyle="filter"
          title={t('tooltips:filter')}
        />
      </div>
      {children && children}
    </div>
  );
};

export default TableMenu;
