import {createRef, FC, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import i18n from 'components/translate';
import cx from 'classnames';
//API
import {API} from 'core/API';
//assets
import {ReactComponent as BlueArrow} from 'assets/image/selects/blue-arrow.svg';
import {ReactComponent as WhiteArrow} from 'assets/image/selects/white-arrow.svg';
//hooks
import {useClickOutside} from 'core/customHooks';
//types
import {FieldBaseProps} from 'core/types';
import {IRolesResponse, USER_ORG_ROLES} from '@joc/api-gateway';
//components
import FieldWrapper from 'shared/inputs/FieldWrapper';
//styles
import styles from './index.module.scss';

type RolesSelectProps = FieldBaseProps<{
  placeholder?: string;
  classList?: Array<string>;
}>;

const RolesSelect: FC<RolesSelectProps> = ({
  placeholder = i18n.t('inputs:placeholders.selectRole'),
  classList,
  ...fieldBaseProps
}) => {
  const {field, form, disabled} = fieldBaseProps;
  const {setFieldValue, setFieldTouched} = form;

  const [options, setOptions] = useState<Array<IRolesResponse>>([]);

  const title = useMemo(() => {
    if (field.value) return options.find((option) => option.id.toString() === field.value.toString())?.roleName;
    return '';
  }, [field.value]);
  const optionRef = useMemo(() => options.map(() => createRef<HTMLDivElement>()), [options]);

  const {isComponentVisible, componentRef, setIsComponentVisible} = useClickOutside({
    initialVisible: false,
    setFieldTouched,
    fieldName: field.name,
  });

  const {t} = useTranslation('members');

  const getOrganizationRoles = async () => {
    const rolesResponse = await API.getAllRoles();

    if (rolesResponse.length) {
      const roles = rolesResponse
        .filter(
          (user) =>
            user.roleName === USER_ORG_ROLES.COORDINATOR ||
            user.roleName === USER_ORG_ROLES.ADMIN ||
            user.roleName === USER_ORG_ROLES.MARKETER
        )
        .map((user) => {
          switch (user.roleName) {
            case USER_ORG_ROLES.COORDINATOR:
              return {...user, roleName: t('roles.coordinator')};

            case USER_ORG_ROLES.ADMIN:
              return {...user, roleName: t('roles.admin')};

            case USER_ORG_ROLES.MARKETER:
              return {...user, roleName: t('roles.marketer')};

            default:
              return {...user, roleName: user.roleName.toLowerCase()};
          }
        });
      setOptions(roles);
    }
  };

  useEffect(() => {
    getOrganizationRoles();
  }, []);

  const optionClickHandler = (id: number): void => {
    setFieldValue(field.name, id);
    setIsComponentVisible(false);
  };

  const titleClickHandler = () => {
    const valueIndex = options.findIndex((i) => i.id === field.value);
    const target = optionRef[valueIndex]?.current;
    if (target)
      // set timeout cause in a first time the scroll doesn't work
      setTimeout(() => {
        target.scrollIntoView();
      }, 0);

    if (isComponentVisible) setFieldTouched(field.name, true);
    setIsComponentVisible(!isComponentVisible);
  };

  return (
    <FieldWrapper {...fieldBaseProps}>
      <div
        id={field.name}
        className={cx(styles.dropdown_select, styles.roles, {
          [styles.md]: classList?.includes('md'),
          [styles.dropdown_select__active]: isComponentVisible,
          [styles.disabled]: disabled,
        })}
        ref={componentRef}
      >
        <div
          className={cx(styles.dropdown_select__title, styles.roles, {
            [styles.dropdown_select__title_selected]: !!field.value,
          })}
          onClick={titleClickHandler}
        >
          <span>{title || placeholder}</span>
          {!isComponentVisible ? (
            <BlueArrow className={styles.dropdown_select__title__vector} />
          ) : (
            <WhiteArrow className={styles.dropdown_select__title__vector} />
          )}
        </div>
        <div className={styles.dropdown_select__options}>
          {options.map((option: IRolesResponse) => (
            <div
              key={option.id}
              className={cx(styles.dropdown_select__options__item, styles.roles)}
              onClick={() => optionClickHandler(option.id)}
            >
              {option.roleName.split('_')}
            </div>
          ))}
        </div>
      </div>
    </FieldWrapper>
  );
};

export default RolesSelect;
