import {FC, ReactNode, useEffect} from 'react';
//components
import Navbar from 'components/Organization/NavBar';
import HeaderOrg from 'components/Headers/HeaderOrg';
//styles
import styles from './MainPageLayout.module.scss';
import {urls} from '../../../core/appUrls';
import {useHistory} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {selectorGetUserData} from '../../../redux/user-service/selector';

type MainPageLayoutPropsType = {
  children: ReactNode;
};

const MainPageLayout: FC<MainPageLayoutPropsType> = ({children}: MainPageLayoutPropsType) => {
  const history = useHistory();

  const userData = useSelector(selectorGetUserData);

  useEffect(() => {
    const volunteerId = localStorage.getItem('volunteerId');
    const organisationId = localStorage.getItem('organisationId');

    if (volunteerId || !organisationId) {
      history.push(urls.onboardingChooseUserType);
    } else {
      document.title = 'Just One Chesed Admin';
    }
  }, [userData]);

  return (
    <div className={styles.main}>
      <HeaderOrg />
      <div className={styles.main__content__container}>
        <Navbar />
        <div className={styles.main__content}>{children}</div>
      </div>
    </div>
  );
};

export default MainPageLayout;
