import {FC, useMemo, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import cx from 'classnames';
//redux
import {useSelector} from 'react-redux';
import {selectorGetHashtags} from 'redux/hashtag-service/selectors';
//formik
import {Field, Form, useFormikContext} from 'formik';
//components
import Input from 'shared/inputs/Input';
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
import {getIsTagIncludeSpecialSymbols} from 'components/HashtagModals/CreateHashtagModal/CreateHashtagSection/helper';
import InputErrorHint from 'shared/components/InputErrorHint';
//styles
import styles from './index.module.scss';

interface IEditHashtagFormProps {
  fieldLabel?: string;
  fieldPlaceholder?: string;
}

export const EditHashtagForm: FC<IEditHashtagFormProps> = ({fieldLabel, fieldPlaceholder}) => {
  const {t} = useTranslation(['validation', 'buttons']);

  const {
    handleSubmit,
    dirty,
    errors,
    values: {name},
  } = useFormikContext() as any;

  const isErrorsArePresent = useMemo(() => !!Object.values(errors).length, [errors]);

  const currentTagName = useRef(name);

  const orgHashtags = useSelector(selectorGetHashtags);

  const isExistingTag = useMemo(
    () =>
      !!orgHashtags.find((tag) => tag.name.toLowerCase() === name.toLowerCase()) &&
      name.toLowerCase() !== currentTagName.current.toLowerCase(),
    [orgHashtags, name]
  );

  const isIncludeSpecialSymbols = useMemo(() => getIsTagIncludeSpecialSymbols(name as string), [name]);

  const isSubmitButtonDisabled = useMemo(
    () => !dirty || isErrorsArePresent || isExistingTag || isIncludeSpecialSymbols || !name.trim().length,
    [dirty, isErrorsArePresent, isExistingTag, isIncludeSpecialSymbols, name]
  );

  return (
    <Form className={styles.edit__hash__form}>
      <Field
        name="name"
        component={Input}
        label={fieldLabel || ''}
        placeholder={fieldPlaceholder || ''}
        parentClassName={styles.edit__hash__form_field}
      />
      {(isExistingTag || isIncludeSpecialSymbols) && (
        <div className={cx(styles.error, isErrorsArePresent && styles.error_formik)}>
          <InputErrorHint
            errorText={isExistingTag ? t('validation:hashTag.alreadyExists') : t('validation:hashTag.specialSymbols')}
          />
        </div>
      )}
      <ButtonDefault
        title={t('buttons:button.confirm')}
        submitType={false}
        clickHandler={handleSubmit}
        disabled={isSubmitButtonDisabled}
        classList={['primary', 'lg', 'publish']}
      />
    </Form>
  );
};
