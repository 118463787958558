import {Dispatch, FC, SetStateAction, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
//formik
import {Formik} from 'formik';
//types
import {IUpdateOrganizationRequest} from '@joc/api-gateway';
//redux
import {setError} from 'redux/error-service/action';
import {updateOrg} from 'redux/organization-service/actions';
import {selectorGetOrgId, selectorGetOrgInfo} from 'redux/organization-service/selector';
//form configuration
import {OrgInfoFormValues, getInitialValues, validationSchema} from './formConfiguration';
//components
import OrganizationForm from './OrganizationForm/OrganizationForm';

type OrgInfoFormParentProps = {
  setIsUpdateSuccess: Dispatch<SetStateAction<boolean>>;
  isOrgSuspended?: boolean;
};

const OrgInfoForm: FC<OrgInfoFormParentProps> = ({setIsUpdateSuccess, isOrgSuspended}: OrgInfoFormParentProps) => {
  const {t} = useTranslation('errors');

  const dispatch = useDispatch();

  const orgId = useSelector(selectorGetOrgId);
  const orgData = useSelector(selectorGetOrgInfo);

  const [isLoading, setIsLoading] = useState(false);

  const initialValues = useMemo(() => getInitialValues(orgData), [orgData]);

  const submitClickHandler = (orgId?: number) => async (values: OrgInfoFormValues) => {
    try {
      if (!orgId) throw new Error(`${t('sorry')} ${t('cantFindOrgId')}`);
      setIsLoading(true);
      await dispatch(updateOrg(orgId, values as IUpdateOrganizationRequest));
      setIsUpdateSuccess(true);
    } catch (error) {
      dispatch(setError(error?.response?.message || error.response, error?.response?.code || error.code));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={submitClickHandler(orgId)}
    >
      <OrganizationForm isOrgSuspended={isOrgSuspended} isLoading={isLoading} />
    </Formik>
  );
};

export default OrgInfoForm;
