export const REGEX_WEBSITE =
  /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
export const REGEX_NAME = /^[^!@#$%^&*+=<>:;()|~]*$/;
export const REGEX_ORG_NAME = /^[\p{L}\u0590-\u05FF\u200f\u200e_:!'()*+-`/. 0-9]{2,}$/u;

export const REGEX_TITLE = /^[@'"!?:;&$\\/.,\u0590-\u05FF\u200f\u200e\p{L}0-9 ]*$/u;
export const REGEX_DESCRIPTION = /^[@'"!?:;&$\\/.,\u0590-\u05FF\u200f\u200e\p{L}0-9 ]*$/u;

export const REGEX_EMAIL =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const REGEX_URL =
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)/g;

export const REGEX_PHONENUMBER = /^\+?\d+$/;
