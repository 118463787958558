export interface CreateManualOppModalData {
  volunteerId: string;
  volunteerName: string;
  refreshVolunteers: () => void;
}

export enum TableType {
  Header = 'header',
  Body = 'body',
}

export enum TableTotalAmount {
  Year = 'Year',
  Total = 'Total',
}
