import {FC} from 'react';
import {useTranslation} from 'react-i18next';
//formik
import {Field, Form} from 'formik';
//types
//components
import Input from 'shared/inputs/Input';
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
import InputPhone from 'shared/components/FormInputs/InputPhone';
import Loader from 'shared/components/Loader';
//styles
import styles from './InviteColleaguesSignupForm.module.scss';

type InvColleaguesSignupFormParentProps = {
  isLoading: boolean;
};

const InvColleaguesSignupForm: FC<InvColleaguesSignupFormParentProps> = ({
  isLoading,
}: InvColleaguesSignupFormParentProps) => {
  const {t} = useTranslation(['form', 'inputs', 'buttons']);

  return (
    <Form className={styles.form}>
      <Field
        name="firstName"
        label={t('form:user.firstName')}
        placeholder={t('inputs:placeholders.fillIn')}
        component={Input}
      />
      <Field
        name="lastName"
        label={t('form:user.lastName')}
        placeholder={t('inputs:placeholders.fillIn')}
        component={Input}
      />
      <Field name="email" label={t('form:emailAddress')} placeholder="example@email.com" component={Input} />
      <Field name="phoneNumber" label={t('form:phoneNumber')} component={InputPhone} />
      {isLoading ? (
        <Loader loadProps={{disableCenterStyle: true}} />
      ) : (
        <ButtonDefault submitType classList={['primary', 'lg']} title={t('buttons:button.confirm')} />
      )}
    </Form>
  );
};

export default InvColleaguesSignupForm;
