import {FC, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
//redux
import {selectorGetUserVolunteerId} from 'redux/user-service/selector';
import {deleteManualOpportunity} from 'redux/opportunities-service/action';
//assets
import ShareSvg from 'assets/image/share.svg';
import CalendarSvg from 'assets/image/calendar-blue.svg';
import CrossSvg from 'assets/image/cross-button.svg';
import DeleteSvg from 'assets/icons/delete.svg';
import EditSvg from 'assets/image/editMO.svg';
//urls
import {urls} from 'core/appUrls';
//constants
import {LINKS_URLS} from 'core/constants';
//functions
import {hasDatePassed, isVolunteerCantMakeIt} from 'core/functions';
// interfaces
import {IOpportunityResponse} from '@joc/api-gateway';
import {ACTION_BUTTON} from './OppoActionItem/interfaces';
// helpers
import {getDisplayedItems, getNewHistoryLocation} from './helpers';
// components
import PopupSmallWhite from 'shared/components/PopupSmallWhite';
import PopupConfirmation from 'shared/components/PopupConfirmation';
import ShareSocialPopup from '../../../ShareLinks/ShareSocialPopup';
import {OppoActionItem} from './OppoActionItem/OppoActionItem';
//styles
import styles from './OpportunityActions.module.scss';

interface IOpportunityActionsProps {
  isAdminManualOppo?: boolean;
  opportunity: IOpportunityResponse;
  joinClickHandler: () => Promise<void>;
  setIsOpenSnackbar: (a: boolean) => void;
}

const OpportunityActions: FC<IOpportunityActionsProps> = ({
  opportunity,
  joinClickHandler,
  isAdminManualOppo,
  setIsOpenSnackbar,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {t} = useTranslation(['myChesed', 'messages', 'buttons']);

  const volunteerId = useSelector(selectorGetUserVolunteerId);
  const link = `${process.env.REACT_APP_MAIN_URL}${LINKS_URLS.feedViewLink}/${opportunity.id}`;

  const [isShowPopup, setIsShowPopup] = useState(false);
  const [isShowSharing, setIsShowSharing] = useState(false);
  const [isShowDeleteConfirmation, setIsShowDeleteConfirmation] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', () => setIsShowPopup(false));
  }, []);

  const handleDelete = (id: number) => {
    dispatch(deleteManualOpportunity(id));
    setIsShowDeleteConfirmation(false);
  };

  const handleEdit = () => {
    const location = getNewHistoryLocation(urls.volUpdateManualOpportunity, opportunity.id);
    history.push(location);
  };

  const volunteerStatus = useMemo(
    () => opportunity.volunteers?.find((volunteer) => volunteer.id === volunteerId)?.status,
    [opportunity, volunteerId]
  );

  const isPastOppo = useMemo(() => hasDatePassed(opportunity.endDate), [opportunity.endDate]);

  const rejoinItemText = useMemo(
    () =>
      isVolunteerCantMakeIt(opportunity.volunteers, volunteerId)
        ? ACTION_BUTTON.REJOIN
        : isPastOppo
        ? ACTION_BUTTON.COULDNT
        : ACTION_BUTTON.CANT,
    [opportunity.volunteers, volunteerId, isPastOppo]
  );

  const conditionsForDisplaying = useMemo(
    () => getDisplayedItems(opportunity, volunteerStatus, isAdminManualOppo),
    [getDisplayedItems, opportunity]
  );

  const isVisiblePopup = useMemo(
    () => Object.values(conditionsForDisplaying).some((visiblePopup) => visiblePopup),
    [conditionsForDisplaying]
  );

  return isVisiblePopup ? (
    <>
      <PopupSmallWhite
        className={styles.dots}
        withButton
        isShowPopup={isShowPopup}
        parentClassName={styles.actions}
        popupClickHandler={() => setIsShowPopup(!isShowPopup)}
      >
        {conditionsForDisplaying.isVisibleRemindItem && (
          <OppoActionItem isDisabled icon={CalendarSvg} text={ACTION_BUTTON.REMIND} />
        )}
        {conditionsForDisplaying.isVisibleEditItem && (
          <OppoActionItem icon={EditSvg} text={ACTION_BUTTON.EDIT} handleClick={handleEdit} />
        )}
        {conditionsForDisplaying.isVisibleShareItem && (
          <OppoActionItem icon={ShareSvg} text={ACTION_BUTTON.SHARE} handleClick={() => setIsShowSharing(true)} />
        )}
        {conditionsForDisplaying.isVisibleDeleteItem && (
          <OppoActionItem
            icon={DeleteSvg}
            text={ACTION_BUTTON.DELETE}
            handleClick={() => setIsShowDeleteConfirmation(true)}
          />
        )}
        {conditionsForDisplaying.isVisibleRejoinItem && (
          <OppoActionItem icon={CrossSvg} text={rejoinItemText} handleClick={joinClickHandler} />
        )}
        {isShowSharing && (
          <ShareSocialPopup
            link={link}
            opportunity={opportunity}
            setIsShowPopup={setIsShowSharing}
            setIsOpenSnackbar={setIsOpenSnackbar}
          />
        )}
      </PopupSmallWhite>
      {isShowDeleteConfirmation && (
        <PopupConfirmation
          setIsShowPopup={setIsShowPopup}
          text={t('messages:wantToDeleteOppo')}
          confirmClickHandler={() => handleDelete(opportunity.id)}
          cancelClickHandler={() => setIsShowDeleteConfirmation(false)}
        />
      )}
    </>
  ) : null;
};
export default OpportunityActions;
