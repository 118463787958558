import {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';
import cx from 'classnames';
//formik
import {useFormikContext} from 'formik';
//types
import {FormikOpportunityMainForm} from '..';
//components
import SwitchCheckbox from 'shared/components/SwitchCheckbox';
import RepeatOpportunityModal from './RepeatOpportunityModal';
//styles
import styles from './RepeatOpportunity.module.scss';

type RepeatOpportunityProps = {
  isEdit: boolean;
  disabled?: boolean;
};

const RepeatOpportunity: FC<RepeatOpportunityProps> = ({isEdit, disabled}) => {
  const {t} = useTranslation('form');

  const {values, setFieldValue}: FormikOpportunityMainForm = useFormikContext();

  const [isRepeatOpportunityModalOpen, setIsRepeatOpportunityModalOpen] = useState(false);

  const repeatOpportunityClickHandler = () => {
    manageRepeatOpportunityModalHelper(!values.isRecurrenceEnabled);
    if (!values.isRecurrenceEnabled) setFieldValue('repeatableOpportunity', undefined);
  };

  const openRepeatOpportunityModal = () => {
    if (!!values.isRecurrenceEnabled) setIsRepeatOpportunityModalOpen(true);
  };

  const closeRepeatOpportunityModal = () => setIsRepeatOpportunityModalOpen(false);

  const manageRepeatOpportunityModalHelper = (newState: boolean) => {
    setIsRepeatOpportunityModalOpen(newState);
    setFieldValue('isRecurrenceEnabled', newState);
  };

  const repeatTitle = t('opportunity.repeatThisOpportunity');

  return (
    <div className={styles.switchCheckbox__wrapper}>
      <label
        className={cx(styles.switchCheckbox__label, {
          [styles.switchCheckbox__label_active]: values.isRecurrenceEnabled,
        })}
        onClick={openRepeatOpportunityModal}
      >
        {repeatTitle}
      </label>

      <SwitchCheckbox
        id="isRecurrenceEnabled"
        isDisabled={disabled}
        isChecked={values.isRecurrenceEnabled}
        clickHandler={repeatOpportunityClickHandler}
      />

      {(values.isRecurrenceEnabled || isEdit) && isRepeatOpportunityModalOpen && (
        <RepeatOpportunityModal
          popupClickHandler={closeRepeatOpportunityModal}
          isRepeatOpportunityModalOpen={isRepeatOpportunityModalOpen}
        />
      )}
    </div>
  );
};

export default RepeatOpportunity;
