import {FC, RefObject, createRef, useEffect, useState} from 'react';
import cx from 'classnames';

import {ReactComponent as ArrowSvg} from 'assets/icons/arrows-up-circle.svg';

import styles from './index.module.scss';
import {SELECTOR_FOR_TENTH_LIST_ITEM} from './constants';

interface ButtonUpToTopProps {
  containerSelector: string;
  isPopup?: boolean;
  isSmartGroup?: boolean;
}
const ButtonUpToTop: FC<ButtonUpToTopProps> = ({containerSelector, isPopup, isSmartGroup}: ButtonUpToTopProps) => {
  const elementRef = createRef<HTMLDivElement>();
  const [isScrollTopVisible, setIsScrollTopVisible] = useState(false);

  const scrollTo = () => {
    const containerRef = document.querySelector(`.${containerSelector}`);
    if (containerRef) containerRef.scrollTo({left: 0, top: 0, behavior: 'smooth'});
  };

  useEffect(() => {
    const scrollContainerRef = document.querySelector(`.${containerSelector}`);
    const tenthListItemR = scrollContainerRef?.querySelector(`.${SELECTOR_FOR_TENTH_LIST_ITEM}`);

    if (scrollContainerRef) {
      const handleScroll = () => {
        const tenthListItemRef = scrollContainerRef.querySelector(`.${SELECTOR_FOR_TENTH_LIST_ITEM}`);

        if (tenthListItemRef) {
          const listRect = scrollContainerRef?.getBoundingClientRect();
          const tenthElementRect = tenthListItemRef?.getBoundingClientRect();

          setIsScrollTopVisible(tenthElementRect!.bottom <= listRect!.bottom);
        }
      };

      if (scrollContainerRef) scrollContainerRef?.addEventListener('scroll', handleScroll);

      return () => {
        scrollContainerRef.removeEventListener('scroll', handleScroll);
      };
    }
  }, [containerSelector]);

  return (
    <div
      ref={elementRef}
      onClick={scrollTo}
      className={cx(styles.scrollIcon, {
        [styles.scrollIcon_visible]: isScrollTopVisible,
        [styles.scrollIcon_inPopup]: isPopup,
        [styles.scrollIcon_inSmartGroup]: isSmartGroup,
      })}
    >
      <ArrowSvg />
    </div>
  );
};

export default ButtonUpToTop;
