import {FC, Fragment} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import cx from 'classnames';
//redux
import {Store} from 'redux/root';
import {selectorGetHiddenNavbarModeState} from 'redux/user-service/selector';
//functions
import {getDisplayedUnreadMessagesCount} from 'core/functions';
//styles
import styles from './NavBarItem.module.scss';

type NavBarItemParentProps = {
  icon: string;
  title: string;
  counterIndicatorValue?: number;
};

const NavBarItem: FC<NavBarItemProps> = ({icon, title, hiddenState, counterIndicatorValue}: NavBarItemProps) => {
  return (
    <Fragment>
      <div className={styles.icon}>
        <img src={icon} alt="icon" />
        {!!counterIndicatorValue && (
          <span className={styles.icon__counter}>{getDisplayedUnreadMessagesCount(counterIndicatorValue)}</span>
        )}
      </div>
      <span
        className={cx(styles.title, {
          [styles.title__hide]: hiddenState,
        })}
      >
        {title}
      </span>
    </Fragment>
  );
};

const mapStateToProps = (store: Store) => ({
  hiddenState: selectorGetHiddenNavbarModeState(store),
});

const connector = connect(mapStateToProps);
type NavBarItemProps = ConnectedProps<typeof connector> & NavBarItemParentProps;

export default connector(NavBarItem);
