import moment, {Moment} from 'moment';
//types
import {
  DateRange,
  HowFarRequest,
  IGetLocationAddressByIdResponse,
  ITimeRange,
  OPPORTUNITY_TYPES,
} from '@joc/api-gateway';
import {TabFilterType} from 'core/types';
import {metersInAMile} from 'core/constants';

export const getOpportunityTypesList = ({opportunityTypesList}: TabFilterType): Array<string> | undefined => {
  const combinedProp = `${OPPORTUNITY_TYPES.PRIVATE}, ${OPPORTUNITY_TYPES.PUBLIC}`;

  if (!opportunityTypesList || !opportunityTypesList.includes(combinedProp)) {
    return opportunityTypesList;
  }

  return [
    ...opportunityTypesList.filter((item) => item !== combinedProp),
    OPPORTUNITY_TYPES.PRIVATE,
    OPPORTUNITY_TYPES.PUBLIC,
  ];
};

export const getStartDay = ({
  fromDate,
  toDate,
}: TabFilterType): {fromDate: string | undefined; toDate: string | undefined} => {
  let newFromDate: string | undefined;
  let newToDate: string | undefined;

  if (fromDate) {
    newFromDate = fromDate.toISOString(true);
  }

  if (toDate) {
    newToDate = toDate.toISOString(true);
  }

  if (toDate && !fromDate) {
    newFromDate = moment().startOf('day').toISOString(true);
  }

  return {fromDate: newFromDate, toDate: newToDate};
};

export const getTransformDate = (fromDate?: Moment | null, toDate?: Moment | null) => {
  const localTimeZone = new Date().getTimezoneOffset();

  const transformFromDate = fromDate ? new Date(String(fromDate)) : null;
  const transformToDate = toDate ? new Date(String(toDate)) : null;

  const dateFromWithTimeZone = transformFromDate ? new Date(transformFromDate.getTime() + localTimeZone * 60000) : null;
  const dateToWithTimeZone = transformToDate ? new Date(transformToDate.getTime() + localTimeZone * 60000) : null;

  const newFromDate = dateFromWithTimeZone ? moment(dateFromWithTimeZone).startOf('day').toISOString() : null;
  const newToDate = dateToWithTimeZone ? moment(dateToWithTimeZone).endOf('day').toISOString() : null;

  return {fromDate: newFromDate, toDate: newToDate};
};

const getUTCTime = (time: string) => {
  return moment(time, 'HH:mm').utc().format('HH:mm');
};

export const getWhatTime = ({startTime, endTime}: TabFilterType): ITimeRange | undefined => {
  if (!startTime && !endTime) {
    return undefined;
  }

  let newStartTime = '00:00';
  let newEndTime = '23:59';

  if (startTime) {
    newStartTime = getUTCTime(startTime);
  }

  if (endTime) {
    newEndTime = getUTCTime(endTime);
  }

  return {startTime: newStartTime, endTime: newEndTime};
};

export const getHowFar = (
  {howFar}: TabFilterType,
  userAddress?: IGetLocationAddressByIdResponse
): HowFarRequest | undefined => {
  if (!howFar || !userAddress) {
    return undefined;
  }

  const {latitude, longitude} = userAddress;
  const distance = Number(howFar) * metersInAMile;

  return HowFarRequest.fromJS({distance, latitude, longitude});
};

export const getTransformStartDay = (startDay: DateRange | undefined) => {
  const localTimeZone = new Date().getTimezoneOffset();

  const transformFromDate = startDay?.fromDate ? new Date(String(startDay?.fromDate)) : null;

  const dateFromWithTimeZone = transformFromDate ? new Date(transformFromDate.getTime() + localTimeZone * 60000) : null;

  const newFromDate = dateFromWithTimeZone ? moment(dateFromWithTimeZone).toISOString() : null;

  return DateRange.fromJS({fromDate: newFromDate});
};

export const getFilterOrgId = (organisationId?: string | number) => {
  if (!organisationId) {
    return undefined;
  }
  return typeof organisationId === 'string' ? Number(organisationId) : organisationId;
};
