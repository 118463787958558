import {IOrganisationResponse} from '@joc/api-gateway';

export const getTransformOrg = (
  organisations: Pick<IOrganisationResponse, 'id' | 'organizationName' | 'organizationLogoPath'>[]
) => {
  return organisations.map(({organizationName, id, organizationLogoPath}) => ({
    title: organizationName,
    value: id,
    id,
    imagePath: organizationLogoPath,
  }));
};
