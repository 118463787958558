import firebase from 'firebase';
import {useTranslation} from 'react-i18next';
//types
import {LOGIN_ERROR} from 'core/types';
import {FIREBASE_LOGIN_ERROR_CODE, FirebaseLoginError} from './types';

export const checkPasswordProviderToUser = async (email: string) => {
  try {
    const userSigninMethods = await firebase.auth().fetchSignInMethodsForEmail(email);
    if (!userSigninMethods.includes('password')) return false;
    return true;
  } catch (error) {
    throw error;
  }
};

export const addPasswordProviderToUser = async (userSigninMethods: Array<string>) => {
  const {t} = useTranslation('errors');

  try {
    const provider = userSigninMethods.includes('google.com')
      ? new firebase.auth.GoogleAuthProvider().setCustomParameters({
          prompt: 'select_account',
        })
      : userSigninMethods.includes('facebook.com')
      ? new firebase.auth.FacebookAuthProvider().setCustomParameters({
          prompt: 'select_account',
        })
      : userSigninMethods.includes('apple.com')
      ? new firebase.auth.OAuthProvider('apple.com').setCustomParameters({
          prompt: 'select_account',
        })
      : null;
    if (!provider) throw new Error(t('youHaventAutorization'));
    await firebase.auth().signInWithPopup(provider);
    const password = await firebase.auth().currentUser?.updatePassword('11111111');
  } catch (error) {
    console.log(error);
  }
};

export const getLoginError = (error: FirebaseLoginError<FIREBASE_LOGIN_ERROR_CODE>): LOGIN_ERROR => {
  switch (error.code) {
    case FIREBASE_LOGIN_ERROR_CODE.WRONG_PASSWORD:
      return LOGIN_ERROR.WRONG_PASSWORD;

    case FIREBASE_LOGIN_ERROR_CODE.USER_NOT_FOUND:
      return LOGIN_ERROR.NOT_FOUND;

    case FIREBASE_LOGIN_ERROR_CODE.TOO_MANY_REQUESTS:
      return LOGIN_ERROR.TOO_MANY_REQUESTS;

    case FIREBASE_LOGIN_ERROR_CODE.POPUP_CLOSED_BY_USER:
      return LOGIN_ERROR.INTERRUPTED;

    default:
      return LOGIN_ERROR.NONE;
  }
};
