import {Dispatch, FC, SetStateAction, useEffect, useState} from 'react';
import {useHistory, useLocation} from 'react-router';
import qs from 'qs';
//formik
import {Formik} from 'formik';
//redux
import {Store} from 'redux/root';
import {useSelector} from 'react-redux';
//functions
import {VolunteersFiltersSubmitClickHandler as handleVolunteersFiltersSubmit} from 'core/functions/submitClickHandler';
import {deserializeQueryParamsVolunteersFilters} from 'core/functions/deserializeQueryParamsVolunteersFilters';
//types
import {VOLUNTEERS_FILTERS_VALUES} from 'core/types';
//constants
import {INITIAL_VOLUNTEERS_FILTERS_VALUES} from 'core/constants';
//components
import VolunteersFiltersForm from './VolunteersFiltersForm';
//validations
import {DateRangeFiltersValidationSchema} from 'core/validation';

type VolunteersFiltersParentProps = {
  changeVisibilityFilters: () => void;
  isCloseFilters: boolean;
  setIsClearSearch?: Dispatch<SetStateAction<boolean>>;
};

const VolunteersFilters: FC<VolunteersFiltersParentProps> = ({
  changeVisibilityFilters,
  isCloseFilters,
  setIsClearSearch,
}) => {
  const history = useHistory();
  const location = useLocation();

  const schoolId = useSelector((store: Store) => Number(store.organizationRedux.organizationInfo?.schoolId));

  const [initialValues, setInitialValues] = useState<VOLUNTEERS_FILTERS_VALUES>(INITIAL_VOLUNTEERS_FILTERS_VALUES);

  useEffect(() => {
    const queryParams = qs.parse(location.search, {ignoreQueryPrefix: true});
    if (Object.keys(queryParams).length) {
      const requestBody = deserializeQueryParamsVolunteersFilters(queryParams);
      setInitialValues(requestBody);
    }
  }, [location.search]);

  const handleSubmit = (values: VOLUNTEERS_FILTERS_VALUES) => {
    handleVolunteersFiltersSubmit(values, history);
  };

  return (
    <Formik
      enableReinitialize
      validateOnChange
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={DateRangeFiltersValidationSchema}
    >
      <VolunteersFiltersForm
        history={history}
        schoolId={schoolId}
        isCloseFilters={isCloseFilters}
        setInitialValues={setInitialValues}
        setIsClearSearch={setIsClearSearch}
        changeVisibilityFilters={changeVisibilityFilters}
      />
    </Formik>
  );
};

export default VolunteersFilters;
