import {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';
import cx from 'classnames';
import Tooltip from '@mui/material/Tooltip';

//assets
import CopyImg from 'assets/image/copy.svg';
import {ReactComponent as InfoIcon} from 'assets/icons/info.svg';

//styles
import styles from './CellDefault.module.scss';

type CellDefaultProps = {
  text: string | number | undefined;
  parentClassName?: string;
  isCopy?: boolean;
  className?: string;
  warning?: string;
};

const CellDefault: FC<CellDefaultProps> = ({text, parentClassName, isCopy, className, warning}: CellDefaultProps) => {
  const [isCopied, SetIsCopied] = useState(false);

  const {t} = useTranslation(['errors', 'common', 'buttons', 'messages', 'tooltips']);

  const copyClickHandler = (a: string | number | undefined) => {
    const cb = navigator.clipboard;
    cb.writeText(a !== undefined ? a.toString() : t('errors:errorSimple'));
    SetIsCopied(true);
    setTimeout(() => SetIsCopied(false), 500);
  };

  return (
    <>
      <div className={cx(styles.main, parentClassName && styles[parentClassName], className)} title={text as string}>
        {warning && (
          <>
            <Tooltip title={warning}>
              <InfoIcon className={styles.warning} />
            </Tooltip>
          </>
        )}
        <span>{text}</span>
        {isCopy && text && (
          <div className={styles.copy} onClick={() => copyClickHandler(text)} title={t('tooltips:copyToClipboard')}>
            {!isCopied ? (
              <img className={styles.icon} src={CopyImg} alt={t('common:copyImg')} />
            ) : (
              t('messages:copied').toLowerCase()
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default CellDefault;
