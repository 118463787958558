import {useHistory, useLocation} from 'react-router';
import {useDispatch, useSelector} from 'react-redux';
import {useEffect, useState} from 'react';
import qs from 'qs';
//redux
import {selectorGetOrgId} from 'redux/organization-service/selector';
import {getOrgMembers, searchMemberOrg} from 'redux/organization-service/actions';
//helpers
import {parseMemberQuery} from 'pages/Organization/Members/helpers';
//types
import {IFullTextSearchUsersForAdmin, SearchUsersByOrgRequest} from '@joc/api-gateway/lib/api-client';
import {ISearchVolunteersSort, SEARCH_USER_BY_ADMIN_POSSIBLE_SEARCH} from '@joc/api-gateway';
//core
import {setSearchOrSortQueryString} from 'core/functions';

export const useSearchMember = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const orgId = useSelector(selectorGetOrgId);

  const [isFirstTime, setIsFirstTime] = useState<boolean>(true);

  useEffect(() => {
    const data = parseMemberQuery(location.search);

    if (data) {
      dispatch(searchMemberOrg(orgId.toString(), data as SearchUsersByOrgRequest));
    }
  }, [location.search]);

  const searchChangeHandler = (value: string): void => {
    const fullTextSearchParams: IFullTextSearchUsersForAdmin = {
      value,
      fields: Object.values(SEARCH_USER_BY_ADMIN_POSSIBLE_SEARCH),
    };
    if (fullTextSearchParams.value.length) {
      appendSearchQuery(fullTextSearchParams, 'fullTextSearch');
    } else {
      const searchParams = qs.parse(location.search, {ignoreQueryPrefix: true});
      delete searchParams.fullTextSearch;
      history.push({search: qs.stringify(searchParams)});

      if (isFirstTime) {
        setIsFirstTime(false);
      } else {
        dispatch(getOrgMembers());
      }
    }
  };

  const appendSearchQuery = (
    newQueryParams: IFullTextSearchUsersForAdmin | ISearchVolunteersSort,
    searchBy: string
  ): void => {
    const searchQuery = setSearchOrSortQueryString(location.search, newQueryParams, searchBy);
    history.push({search: searchQuery});
  };

  return searchChangeHandler;
};
