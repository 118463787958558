export const setEachMessageToNewLine = (text: string) =>
  String(text)
    .split('.')
    .filter((part) => part.trim() !== '')
    .map((part, index, array) => (
      <span key={index}>
        {part}
        {index !== array.length && '.'}
        <br />
      </span>
    ));
