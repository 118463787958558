import {FC, ReactNode, useState} from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
//redux
import {selectorGetOrgLogo, selectorGetUserImage, selectorGetUserVolunteerStatus} from 'redux/user-service/selector';
import {selectorGetOrgActiveStatus, selectorGetOrgId} from 'redux/organization-service/selector';
//types
import {ORGANISATION_ACTIVE_STATUS, VOLUNTEER_ACTIVE_STATUS} from '@joc/api-gateway';
//components
import HeaderLogin from 'components/Headers/HeaderVolunteer/HeaderNav/HeaderLogin';
import Logo from 'shared/components/Logo';
import ChatInfo from './ChatInfo';
import LangSwitch from './LangSwitch';
import ImageWithPopup from '../ImageWithPopup';
//styles
import styles from './HeaderWrapper.module.scss';

type HeaderWrapperProps = {
  children: ReactNode;
  dropdown: JSX.Element;
  chatLink: string;
  isGuest?: boolean;
};

const HeaderWrapper: FC<HeaderWrapperProps> = ({children, dropdown, isGuest, chatLink}) => {
  const [isShowDropdown, setIsShowDropdown] = useState({isShow: false, isClose: false});

  const orgLogo = useSelector(selectorGetOrgLogo);
  const userImage = useSelector(selectorGetUserImage);
  const orgStatus = useSelector(selectorGetOrgActiveStatus);
  const volunteerStatus = useSelector(selectorGetUserVolunteerStatus);
  const orgId = useSelector(selectorGetOrgId);

  const changeVisibilityDropdown = () => {
    setIsShowDropdown({...isShowDropdown, isClose: !isShowDropdown.isClose});
    setTimeout(() => setIsShowDropdown({...isShowDropdown, isShow: !isShowDropdown.isShow}), 200);
  };

  const isOrgSuspended = orgStatus?.status === ORGANISATION_ACTIVE_STATUS.SUSPENDED;
  const isVolunteerSuspended = volunteerStatus === VOLUNTEER_ACTIVE_STATUS.SUSPENDED;

  return (
    <div className={styles.wrapper}>
      <div className={styles.logoBlockWrapper}>
        <Logo isOnboarding={isGuest} />
        {!isGuest && children}
      </div>
      <div className={styles.content}>
        {isGuest ? (
          <HeaderLogin />
        ) : (
          <>
            <LangSwitch />
            {isOrgSuspended || isVolunteerSuspended ? (
              <ChatInfo isSuspended={isOrgSuspended || isVolunteerSuspended} />
            ) : (
              <Link to={chatLink}>
                <ChatInfo />
              </Link>
            )}
            <div className={styles.content__image} onClick={changeVisibilityDropdown}>
              <ImageWithPopup
                disablePopup
                classNames={styles.avatar}
                imagePath={orgId ? orgLogo : userImage}
                alt="avatar"
              />
              {isShowDropdown.isShow && dropdown}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default HeaderWrapper;
