import i18n from 'components/translate';
import {SidebarVisibility} from './interfaces';

export const TABS_TITLES = {
  all: i18n.t('common:titles.all'),
  past: i18n.t('chesedOpportunities:past'),
  upcoming: i18n.t('chesedOpportunities:upcoming'),
  rejected: i18n.t('chesedOpportunities:rejected'),
  opportunities: i18n.t('chesedOpportunities:title'),
  vacancies: i18n.t('chesedOpportunities:vacancies'),
  pending: i18n.t('chesedOpportunities:pendingApproval'),
};

export const queryDataConfig = {ignoreQueryPrefix: true};

export const initialSidebarVisibility: SidebarVisibility = {
  isShowCalendar: false,
  isShowFilter: false,
};
