import {FC} from 'react';
import cx from 'classnames';
//types
import {BUTTON_CROSS_COLOR} from 'core/types';
//helpers
import {getButtonCrossImage} from './helpers';
//styles
import styles from './ButtonCross.module.scss';

type ButtonCrossProps = {
  clickHandler: () => void;
  colorImage?: BUTTON_CROSS_COLOR;
  parentClassName?: string;
};

const ButtonCross: FC<ButtonCrossProps> = ({clickHandler, parentClassName, colorImage = BUTTON_CROSS_COLOR.BLUE}) => {
  const image = getButtonCrossImage(colorImage);

  return (
    <button className={cx(styles.main, parentClassName && parentClassName)} onClick={clickHandler}>
      <img src={image} alt="" />
    </button>
  );
};

export default ButtonCross;
