// react
import {useState} from 'react';
import {AnyAction} from 'redux';
import {Store} from 'redux/root';
import {ThunkAction} from 'redux-thunk';
import useThunkDispatch from './useThunkDispatch';
// core
import {errorHandler} from 'core/functions/errorHandler';
// other packages
import {debounce} from 'lodash';

export interface IUseLoadingResponse {
  isLoading: boolean;
  startLoading: <T>(action: ThunkAction<T, Store, void, AnyAction>) => Promise<T>;
}

export const useLoading = (withDebounce: boolean = false) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useThunkDispatch();

  const debouncedSetIsLoading = debounce(() => setIsLoading(false), withDebounce ? 300 : 0);

  const startLoading = async <T,>(action: ThunkAction<T, Store, void, AnyAction>) => {
    setIsLoading(true);
    try {
      const data = await dispatch(action);
      return data;
    } catch (e) {
      throw errorHandler(e);
    } finally {
      debouncedSetIsLoading();
    }
  };

  return {
    startLoading,
    isLoading,
  };
};
