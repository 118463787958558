import {FC, useState} from 'react';
import {useSelector} from 'react-redux';
import {Formik} from 'formik';
//redux
import {Store} from 'redux/root';
//types
import {ICreateOpportunityRequest, IUpdateOpportunityRequest, OPPORTUNITY_TYPES} from '@joc/api-gateway';
import {IUpdateOpportunityInitialValues, OpportunityInitialValuesType} from 'core/types';
//validation
import {opportunityFormValidationSchemaWithUrl, opportunityFormValidationSchemaWithAddress} from 'core/validation';
//components
import OpportunityMainForm from './OpportunityMainForm';
//styles
import styles from './OpportunityForm.module.scss';

type OpportunityFormParentProps = {
  initialValues:
    | OpportunityInitialValuesType
    | ICreateOpportunityRequest
    | IUpdateOpportunityRequest
    | IUpdateOpportunityInitialValues;
  submitClickHandler: (values: any, groupId?: string | null) => Promise<void>;
  isLoading: boolean;
  isManual?: boolean;
  statusClose?: boolean;
  isEdit?: boolean;
};

const OpportunityForm: FC<OpportunityFormParentProps> = ({
  initialValues,
  isLoading,
  submitClickHandler,
  isManual,
  statusClose,
  isEdit,
}) => {
  const [isVirtualOpportunity, setIsVirtualOpportunity] = useState(initialValues?.isVirtual);
  const [manualTimeTracking, setManualTimeTracking] = useState(initialValues?.manualTimeTracking);
  const [isVisibleHashtagField, setIsVisibleHashtagField] = useState<boolean>(false);
  const [isVisibleVolunteerLimit, setIsVisibleVolunteerLimit] = useState<boolean>(false);

  const schoolId = useSelector((store: Store) => Number(store.organizationRedux.organizationInfo?.schoolId));

  const [isPrivateOpportunity, setIsPrivateOpportunity] = useState(
    !!schoolId || initialValues.opportunityType === OPPORTUNITY_TYPES.PRIVATE
  );

  return (
    <div className={styles.form__container}>
      <Formik
        enableReinitialize
        initialValues={initialValues as OpportunityInitialValuesType | IUpdateOpportunityInitialValues}
        validationSchema={
          isVirtualOpportunity ? opportunityFormValidationSchemaWithUrl : opportunityFormValidationSchemaWithAddress
        }
        onSubmit={(values) => {
          if (values.gender && values.gender === 3) values.gender = undefined;
          submitClickHandler(values);
        }}
        validateOnChange
      >
        <OpportunityMainForm
          isEdit={isEdit}
          schoolId={schoolId}
          isManual={isManual}
          isLoading={isLoading}
          statusClose={statusClose}
          manualTimeTracking={manualTimeTracking}
          isPrivateOpportunity={isPrivateOpportunity}
          isVirtualOpportunity={isVirtualOpportunity}
          isVisibleHashtagField={isVisibleHashtagField}
          setManualTimeTracking={setManualTimeTracking}
          setIsVirtualOpportunity={setIsVirtualOpportunity}
          setIsPrivateOpportunity={setIsPrivateOpportunity}
          isVisibleVolunteerLimit={isVisibleVolunteerLimit}
          isRepeated={!!initialValues.repeatableOpportunity}
          setIsVisibleHashtagField={setIsVisibleHashtagField}
          setIsVisibleVolunteerLimit={setIsVisibleVolunteerLimit}
        />
      </Formik>
    </div>
  );
};

export default OpportunityForm;
