import {FC} from 'react';
import {useHistory} from 'react-router';
import {useTranslation} from 'react-i18next';
import cx from 'classnames';
//assets
import FilterSvg from 'assets/image/filter.svg';
import ClearSvg from 'assets/image/clear.svg';
import ArrowSvg from 'assets/image/button-arrow-dark.svg';
//styles
import styles from './FiltersHeader.module.scss';

type FiltersHeaderParentProps = {
  changeVisibilityFilters: () => void;
  resetForm: () => void;
  isShowFilters: boolean;
  toggleShow?: boolean;
};

const FiltersHeader: FC<FiltersHeaderParentProps> = ({
  changeVisibilityFilters,
  resetForm,
  isShowFilters,
  toggleShow = true,
}) => {
  const history = useHistory();

  const {t} = useTranslation('form');

  return (
    <div className={styles.main}>
      <div className={styles.title}>
        <img className={styles.title__icon} src={FilterSvg} alt="filter" />
        <span className={styles.title__text}>{t('filter.filtersHeader')}</span>
      </div>
      {isShowFilters && (
        <div
          className={styles.clear}
          onClick={() => {
            resetForm();
            history.push({search: ''});
          }}
        >
          <img className={styles.clear__icon} src={ClearSvg} alt="clear" />
          <span className={styles.clear__text}>{t('filter.filtersClear')}</span>
        </div>
      )}
      {toggleShow && (
        <div className={styles.buttonClose} onClick={changeVisibilityFilters}>
          <img className={cx(styles.buttonClose__icon, isShowFilters && styles.showed)} src={ArrowSvg} alt="arrow" />
        </div>
      )}
    </div>
  );
};

export default FiltersHeader;
