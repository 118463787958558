import moment from 'moment/moment';
//types
import {ICreateOpportunityRequest, OPPORTUNITY_TYPES, ORGANISATION_GENERAL_TYPES} from '@joc/api-gateway';
import {OpportunityInitialValuesType} from 'core/types';
//constants
import {OPPORTUNITY_INITIAL_VALUES} from 'core/constants';

export const getOpportunityInitialValues = (
  isSmartGroupMode: boolean,
  groupId?: string,
  orgGeneralType?: ORGANISATION_GENERAL_TYPES,
  userId?: number
) => {
  return {
    ...OPPORTUNITY_INITIAL_VALUES,
    startDate: new Date(),
    startTime: moment().add(1, 'hours').format('HH:mm'),
    endTime: moment().add(2, 'hours').format('HH:mm'),
    smartGroupId: isSmartGroupMode && groupId ? +groupId : 1,
    isRecurrenceEnabled: false,
    recurrence: null,
    opportunityType:
      orgGeneralType === ORGANISATION_GENERAL_TYPES.SCHOOL ? OPPORTUNITY_TYPES.PRIVATE : OPPORTUNITY_TYPES.PUBLIC,
    user: userId || null,
  };
};

export const getOpportunityFromValues = (values: OpportunityInitialValuesType) => {
  const formattedStartDay = moment((values as ICreateOpportunityRequest).startDate).format('YYYY/MM/DD');
  const formattedStartTime = (values as OpportunityInitialValuesType).startTime;
  const formattedEndTime = (values as OpportunityInitialValuesType).endTime;
  const formattedStartDate = moment(`${formattedStartDay} ${formattedStartTime}`).format('YYYY/MM/DD HH:mm');
  const formattedEndDate = moment(`${formattedStartDay} ${formattedEndTime}`).format('YYYY/MM/DD HH:mm');
  const volunteersMaxCount = !values.volunteersMaxCount ? null : values.volunteersMaxCount;
  const tagId = values.tagId ? Number(values.tagId) : null;

  return {
    ...values,
    tagId,
    volunteersMaxCount,
    startDate: new Date(formattedStartDate),
    endDate: new Date(formattedEndDate),
    filePathes: values.imagePath,
    smartGroupId: values.smartGroupId && +values.smartGroupId,
  } as ICreateOpportunityRequest;
};
