import {FC, ReactNode} from 'react';
import {userResetData} from 'redux/user-service/actions';
import {connect, ConnectedProps} from 'react-redux';
//redux
import {Store} from 'redux/root';
import {selectorGetUserData} from 'redux/user-service/selector';
//components
import OrganizationInfo from 'components/Organization/OrganizationInfo';
import VolunteerMainInfo from 'shared/components/VolunteerMainInfo';
//styles
import styles from './AccountMenuDropDown.module.scss';

type AccountMenuDropDownParentProps = {
  children: ReactNode;
  volunteers?: boolean;
};

const AccountMenuDropDown: FC<AccountMenuDropDownProps> = ({
  children,
  volunteers,
  userData,
}: AccountMenuDropDownProps) => {
  return (
    <div className={styles.overlay}>
      <div className={styles.dropDown__Block}>
        <div className={styles.userDescription__block}>
          {volunteers ? (
            <VolunteerMainInfo
              parentClass={styles.df_column}
              points={userData?.points || 0}
              userImage={userData?.imagePath || ''}
              userLastName={userData?.lastName}
              userFirstName={userData?.firstName}
              userGrade={userData?.diploma?.statusName}
              disableAvatarPopup
            />
          ) : (
            <OrganizationInfo parentClassName={styles.df_column} />
          )}
        </div>
        <div className={styles.userMenu__block}>
          <nav className={styles.menu}>{children}</nav>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (store: Store) => ({
  userData: selectorGetUserData(store),
});

const mapDispatchToProps = {
  userResetData,
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type AccountMenuDropDownProps = ConnectedProps<typeof connector> & AccountMenuDropDownParentProps;

export default connector(AccountMenuDropDown);
