import {Dispatch} from 'redux';
import {API} from '../../core/API';
import {NOT_ACCEPTED_INVITES_COUNT, RESEND_INVITE_VOLUNTEER, SET_ERROR, SET_VOLUNTEERS_INVITED} from '../actions-types';

export const resendInvites =
  (orgId: string | undefined) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      await API.resendInvitesByOrganization(orgId);
      dispatch({
        type: RESEND_INVITE_VOLUNTEER,
        payload: {response: true},
      });
    } catch (error) {
      dispatch({type: SET_ERROR, payload: {state: true, message: error?.response?.message || error.message}});
    }
  };

export const setVolunteersInvited =
  () =>
  (dispatch: Dispatch): void => {
    dispatch({
      type: SET_VOLUNTEERS_INVITED,
      payload: {response: false},
    });
  };

export const notAcceptedInvitesCount =
  (orgId: string | undefined) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const response = await API.notAcceptedInvitesCount(orgId);
      dispatch({
        type: NOT_ACCEPTED_INVITES_COUNT,
        payload: response,
      });
    } catch (error) {
      dispatch({type: SET_ERROR, payload: {state: true, message: error?.response?.message || error.message}});
    }
  };
