//types
import {OpportunitiesRegularManualPayload} from './types';
import {
  IGeneralStatisticsResponse,
  IUsersStatisticsResponse,
  IVolunteersAvgAgeResponse,
  IVolunteersSpendTimeResponse,
} from '@joc/api-gateway';
//redux
import {Store} from 'redux/root';

export const selectorGetDashboardOpportunitiesAll = (store: Store): number =>
  store.dashboardRedux.main.opportunitiesTotal;

export const selectorGetDashboardOpportunitiesUpcoming = (store: Store): number =>
  store.dashboardRedux.main.opportunitiesUpcoming;

export const selectorGetDashboardOpportunitiesPending = (store: Store): number =>
  store.dashboardRedux.main.opportunitiesPendingVolunteers;

export const selectorGetDashboardOpportunitiesVacancies = (store: Store): number =>
  store.dashboardRedux.main.opportunitiesVacancies;

export const selectorGetCountOpportunitiesInSchoolYear = (store: Store): number =>
  store.dashboardRedux.main.countOpportunitiesInSchoolYear;

export const selectorGetDashboardOpportunitiesManual = (store: Store): number =>
  store.dashboardRedux.main.opportunitiesManual;

export const selectorGetDashboardOpportunitiesTotalHours = (store: Store): number =>
  store.dashboardRedux.main.totalSpendTime;

export const selectorGetVolunteersHoursThisYear = (store: Store): number => store.dashboardRedux.main.hoursInSchoolYear;

export const selectorGetDashboardVolunteers = (store: Store): number => store.dashboardRedux.main.volunteersTotal;

export const selectorGetDashboardVolunteersClaimsCount = (store: Store) => store.dashboardRedux.main.claimsCount;

export const selectorGetDashboardTariffVolunteers = (store: Store): number =>
  store.dashboardRedux.main.volunteersCountByTariff;

export const selectorGetDashboardSmartGroups = (store: Store): number => store.dashboardRedux.main.volunteersGroups;

export const selectorGetStatisticsGeneral = (store: Store): IGeneralStatisticsResponse =>
  store.dashboardRedux.statistics.general;

export const selectorGetStatisticsUsersSignupTraffic = (store: Store): Array<IUsersStatisticsResponse> =>
  store.dashboardRedux.statistics.usersSignupTraffic;

export const selectorGetStatisticsUsersGradeTimeSpent = (store: Store): Array<IVolunteersSpendTimeResponse> =>
  store.dashboardRedux.statistics.studentsSpentTime;

export const selectorGetStatisticsUsersLoginTraffic = (store: Store): Array<IUsersStatisticsResponse> =>
  store.dashboardRedux.statistics.usersLoginTraffic;

export const selectorGetStatisticsOpportunitiesRegularManual = (store: Store): OpportunitiesRegularManualPayload =>
  store.dashboardRedux.statistics.opportunitiesRegularManual;

export const selectorGetStatisticsVolunteersAvgAge = (store: Store): Array<IVolunteersAvgAgeResponse> =>
  store.dashboardRedux.statistics.volunteersAvgAge;

export const selectorGetStatisticsVolunteersAvgAgeTotal = (store: Store): number => {
  const avgAgeTotal = store.dashboardRedux.statistics.volunteersAvgAge.reduce(
    (acc, current) => acc + current.allAwgAge,
    0
  );

  const rangeLength = store.dashboardRedux.statistics.volunteersAvgAge.length;
  return Math.floor(avgAgeTotal / rangeLength) || 0;
};

export const selectorGetStatisticsStudentsAvgSpentTotal = (store: Store): number => {
  const avgTimeTotal = store.dashboardRedux.statistics.studentsSpentTime.reduce(
    // TODO: write types
    (acc: any, current: any) => acc + current.spendTime,
    0
  );
  const rangeLength = store.dashboardRedux.statistics.studentsSpentTime.length;
  return Math.floor(avgTimeTotal / rangeLength) || 0;
};

export const selectorGetStatisticsUsersLoginTrafficAvg = (store: Store): number => {
  const usersAvgLogin = store.dashboardRedux.statistics.usersLoginTraffic.reduce(
    (acc, current) => Number(acc) + Number(current.count),
    0
  );
  const rangeLength = store.dashboardRedux.statistics.usersLoginTraffic.length;
  return Math.floor(usersAvgLogin / rangeLength) || 0;
};

export const selectorGetStatisticsUsersSignupTrafficTotal = (store: Store): number =>
  store.dashboardRedux.statistics.usersSignupTraffic.reduce((acc, current) => Number(acc) + Number(current.count), 0);
