import {BaseSyntheticEvent, FC, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import cx from 'classnames';
//assets
import {ReactComponent as BlueArrow} from 'assets/image/selects/blue-arrow.svg';
import {ReactComponent as WhiteArrow} from 'assets/image/selects/white-arrow.svg';
//styles
import styles from './index.module.scss';

type SimpleSelectTypes = {
  options: Array<string | number>;
  value?: string | number;
  id?: string;
  placeholder?: string;
  classList?: Array<string>;
  parentClass?: string;
  langSwitch?: boolean;
};

const SimpleSelect: FC<SimpleSelectTypes> = ({
  id,
  value,
  placeholder,
  options,
  classList,
  parentClass,
  langSwitch,
}: SimpleSelectTypes) => {
  const [isShowOptions, setIsShowOptions] = useState<boolean>(false);
  const [isValue, setValue] = useState(value);

  const selectRef = useRef<HTMLDivElement>(null);

  const {i18n} = useTranslation();

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => document.removeEventListener('click', handleClickOutside, true);
  }, [isShowOptions]);

  const optionClickHandler = (event: BaseSyntheticEvent<MouseEvent>): void => {
    event.stopPropagation();
    const {target} = event;
    if (target && target.textContent) {
      setValue(target.textContent);
      if (target.textContent === 'HE') {
        // document.getElementsByTagName('html')[0].setAttribute('dir', 'rtl');
        i18n.changeLanguage('he-IS');
      } else {
        // document.getElementsByTagName('html')[0].setAttribute('dir', 'ltr');
        i18n.changeLanguage('en-US');
      }
      window.location.reload();
      setIsShowOptions(false);
    }
  };

  const handleClickOutside = (event: Event) => {
    if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
      setIsShowOptions(false);
    }
  };

  return (
    <div
      className={cx(parentClass && parentClass, langSwitch && styles.wrap_langSwitch)}
      onClick={() => {
        setIsShowOptions(!isShowOptions);
      }}
    >
      <div
        id={id}
        className={cx(styles.dropdown_select, {
          [styles.md]: classList?.includes('md'),
          [styles.dropdown_select__active]: isShowOptions,
        })}
        ref={selectRef}
      >
        <div
          className={cx(styles.dropdown_select__title, {
            [styles.dropdown_select__title_selected]: !!value,
          })}
        >
          <span>{isValue || placeholder}</span>
          {!isShowOptions ? (
            <BlueArrow className={styles.dropdown_select__title__vector} />
          ) : (
            <WhiteArrow className={styles.dropdown_select__title__vector} />
          )}
        </div>
        <div
          className={cx(styles.dropdown_select__options, {
            [styles.dropdown_select__options_center]: classList?.includes('option_center'),
          })}
        >
          {options.map((option: string | number) => (
            <div
              key={option}
              className={styles.dropdown_select__options__item}
              onClick={(event: BaseSyntheticEvent<MouseEvent>) => optionClickHandler(event)}
            >
              {option}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SimpleSelect;
