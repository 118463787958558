import {FC} from 'react';

export const SkeletonLoader: FC = () => {
  return (
    <div style={{position: 'relative'}}>
      <ul className="skeleton-loader__list">
        <li>
          <div className="skeleton-loader__avatar" />
          <div className="skeleton-loader__text">
            <div />
            <div />
          </div>
        </li>
        <li>
          <div className="skeleton-loader__avatar" />
          <div className="skeleton-loader__text">
            <div />
            <div />
          </div>
        </li>
        <li>
          <div className="skeleton-loader__avatar" />
          <div className="skeleton-loader__text">
            <div />
            <div />
          </div>
        </li>
        <li>
          <div className="skeleton-loader__avatar" />
          <div className="skeleton-loader__text">
            <div />
            <div />
          </div>
        </li>
        <li>
          <div className="skeleton-loader__avatar" />
          <div className="skeleton-loader__text">
            <div />
            <div />
          </div>
        </li>
      </ul>
    </div>
  );
};
