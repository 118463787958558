import {IInviteUserRequest} from '@joc/api-gateway';

// dropped from business logic user flow
// TODO: [JF-1114] change table field's and xls column name's
export const INITIAL_INVITE_COLLEAGUES_SIGNUP: IInviteUserRequest = {
  firstName: '',
  lastName: '',
  email: '',
  positionId: 0,
  roleId: 0,
  phoneNumber: '',
};
