export const periodMinute = 15;
export const periodHour = 1;
const minLimitHour = 0;
export const period = 0.25;
const limit = 24;

export const getIsButtonsDisabled = (
  isDisabled: boolean,
  fieldValue: number,
  isMinus?: boolean,
  type?: 'minute' | 'hour'
): boolean =>
  isDisabled || isMinus ? fieldValue <= (type === 'minute' ? periodMinute : minLimitHour) : fieldValue >= limit;
