import {Dispatch, FC, SetStateAction} from 'react';
import {useTranslation} from 'react-i18next';
import SidebarContainer from 'components/Volunteer/Opportunities/SidebarContainer';
import FilterTags from 'components/Volunteer/Opportunities/FilterTags';

//styles
import styles from './index.module.scss';

interface IMyFeedSidebarProps {
  currentTab: string | null;
  totalOpportunities: string;
  setCurrentTab: Dispatch<SetStateAction<string | null>>;
}

export const MyFeedSidebar: FC<IMyFeedSidebarProps> = ({totalOpportunities, currentTab, setCurrentTab}) => {
  const {t} = useTranslation(['myChesed']);

  return (
    <SidebarContainer>
      <div className={styles.feed__sidebarSearch}>{totalOpportunities}</div>
      <div className={styles.feed__sidebarFilter}>
        <div className={styles.sidebarTitle}>
          <div>{t('myChesed:tags')}</div>
        </div>
        <FilterTags currentTab={currentTab} setCurrentTab={setCurrentTab} />
      </div>
    </SidebarContainer>
  );
};
