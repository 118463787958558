// react
import {FC} from 'react';
// icons
import {ReactComponent as CrossIcon} from 'assets/image/cross-button.svg';

interface ICloseButtonProps {
  onClick: () => void;
  classNames: string;
}

export const CloseButton: FC<ICloseButtonProps> = ({onClick, classNames}) => {
  return (
    <button onClick={onClick} className={classNames}>
      <CrossIcon />
    </button>
  );
};
