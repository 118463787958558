import {ICountsOpportunitiesResponse, IOpportunityResponse} from '@joc/api-gateway';
import {Store} from 'redux/root';
import {UserResponse} from '@joc/api-gateway/lib/api-client';

export const selectorGetOpportunitiesRecords = (store: Store): Array<IOpportunityResponse> => {
  return store.opportunitiesRedux.opportunitiesAll.records;
};

export const selectorGetCoordinators = (store: Store): Array<UserResponse> => {
  return store.opportunitiesRedux.coordinators;
};

export const selectorGetOpportunitiesTotal = (store: Store): number => {
  return store.opportunitiesRedux.opportunitiesAll.total;
};

export const selectorGetOpportunitiesCount = (store: Store): ICountsOpportunitiesResponse => {
  return store.opportunitiesRedux.opportunitiesCount;
};

export const selectorGetOpportunityById = (store: Store): IOpportunityResponse | undefined => {
  if (store.opportunitiesRedux.currentOpportunity) return store.opportunitiesRedux.currentOpportunity;
};

export const selectorGetOpportunitiesReminderTape = (store: Store): Array<IOpportunityResponse> => {
  return store.opportunitiesRedux.opportunitiesReminderTape;
};

export const selectorGetOpportunitiesIsNeedRefresh = (store: Store): boolean => {
  return store.opportunitiesRedux.isNeedRefresh;
};
