import {FC, Fragment, useEffect, useState} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {useHistory, useLocation} from 'react-router';
//translation
import {useTranslation} from 'react-i18next';
//types
import {LOGIN_ERROR, SocialProvider} from 'core/types/index';
//redux
import {resetError} from 'redux/error-service/action';
import {Store} from 'redux/root';
import {selectorGetError} from 'redux/error-service/selector';
//assets
import {ReactComponent as GoogleSvg} from 'assets/image/social/google.svg';
//constants
import {urls} from 'core/appUrls';
//components
import ArrowBackLink from 'shared/components/ArrowBackLink';
import Appeal from 'shared/components/Appeal/DefaultRenderer';
import ResponseFailure from 'shared/components/ResponseFailure';
import PopupAddPasswordProvider from 'components/Organization/Login/PopupAddPasswordProvider';
import LoginFormV from 'components/Volunteer/LoginFormV';
//styles
import styles from '../Login.module.scss';

const LoginVolunteer: FC<LoginVolunteerProps> = ({error, resetError}) => {
  const history = useHistory();
  const location = useLocation();
  const {t} = useTranslation(['login', 'errors', 'buttons']);

  const [loginError, setLoginError] = useState<LOGIN_ERROR>(LOGIN_ERROR.NONE);
  const [isShowLoginWithCredentional, setIsShowLoginWithCredentional] = useState(false);
  const [socialProviders, setSocialProviders] = useState<SocialProvider>();

  useEffect(() => {
    resetError();

    return () => {
      resetError();
    };
  }, []);

  const buttonWithoutPasswordClickHandler = () => {
    resetErrorHelper();
    history.replace(`${urls.onboardingLoginVolunteer}${location.search}`);
  };

  const resetErrorHelper = () => {
    resetError();
    setLoginError(LOGIN_ERROR.NONE);
  };

  return (
    <Fragment>
      {loginError === LOGIN_ERROR.WITHOUT_PASSWORD ? (
        <ResponseFailure
          title={`${t('errors:loginWithFollowingMethods')}:`}
          message={<GoogleSvg />}
          buttonClickHandler={buttonWithoutPasswordClickHandler}
          buttonTitle={t('buttons:button.backToLogIn')}
        />
      ) : isShowLoginWithCredentional ? (
        <PopupAddPasswordProvider
          socialProviders={socialProviders}
          setIsShowLoginWithCredentional={setIsShowLoginWithCredentional}
        />
      ) : loginError === LOGIN_ERROR.WRONG_PASSWORD ? (
        <ResponseFailure
          message={t('errors:thePasswordOrUsernameSensitive')}
          buttonClickHandler={resetErrorHelper}
          buttonTitle={t('buttons:button.backToLogIn')}
          eachMessageToNewLine
        />
      ) : loginError === LOGIN_ERROR.NOT_FOUND ? (
        <ResponseFailure
          message={t('errors:thePasswordOrUsernameIncorrect')}
          buttonClickHandler={resetErrorHelper}
          buttonTitle={t('buttons:button.backToLogIn')}
        />
      ) : loginError === LOGIN_ERROR.INTERRUPTED ? (
        <ResponseFailure
          message={t('errors:signInInterrupted')}
          buttonClickHandler={resetErrorHelper}
          buttonTitle={t('buttons:button.backToLogIn')}
        />
      ) : loginError === LOGIN_ERROR.TOO_MANY_REQUESTS ? (
        <ResponseFailure
          errorType={LOGIN_ERROR.TOO_MANY_REQUESTS}
          message={t('errors:errorToManyRequest.title')}
          buttonClickHandler={resetErrorHelper}
          buttonTitle={t('buttons:button.backToLogIn')}
        />
      ) : error.state ? (
        <ResponseFailure
          message={error.message}
          buttonClickHandler={resetErrorHelper}
          buttonTitle={t('buttons:button.backToLogIn')}
        />
      ) : (
        <div className={styles.content}>
          <ArrowBackLink onboardingStyle />
          <Appeal styleLoginV>
            <LoginFormV setLoginError={setLoginError} setSocialProviders={setSocialProviders} />
          </Appeal>
        </div>
      )}
    </Fragment>
  );
};

const mapStateToProps = (store: Store) => ({
  error: selectorGetError(store),
});

const mapDispatchToProps = {
  resetError,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type LoginVolunteerProps = ConnectedProps<typeof connector>;

export default connector(LoginVolunteer);
