import {JocGatewayAPI} from '@joc/api-gateway';
import axios from 'axios';
import moment from 'moment';
import {urls} from './appUrls';

const instance = axios.create();

instance.interceptors.request.use(
  async (req) => {
    const token = localStorage.getItem('accessToken');
    const orgId = localStorage.getItem('organisationId');
    const appType = 'web-app';
    const appVersion = process.env.REACT_APP_JOCP_VERSION;
    const appEnv = process.env.REACT_APP_JOCP_ENV;
    const timeZone = `${moment(new Date()).format('Z')}`;
    if (token) {
      req.headers.Authorization = `Bearer ${token}`;
    }
    if (orgId) {
      req.headers['x-organization-id'] = orgId.toString();
    }
    if (appType) {
      req.headers['x-app-type'] = appType.toString();
    }
    if (appVersion) {
      req.headers['x-app-version'] = appVersion.toString();
    }
    if (appEnv) {
      req.headers['x-app-env'] = appEnv.toString();
    }
    if (timeZone) {
      req.headers['x-time-zone-offset'] = timeZone.toString();
    }
    return req;
  },
  (err) => {
    console.error(err);
    throw err;
  }
);

instance.interceptors.response.use(
  async (res) => {
    return res;
  },
  (err) => {
    console.log('ERROR', err);

    // TODO: EUG when login not exists user it already works 'cause BE returns 400 code
    if (
      err?.response?.status === 401
      // || err?.response?.status === 400
    ) {
      window.location.href = `${window.location.origin}${urls.accessDenied}`;
      throw err;
    }

    console.dir(err);
    throw err;
  }
);

const API = new JocGatewayAPI(process.env.REACT_APP_JOC_API, instance);

export {API};
