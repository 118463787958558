import {Dispatch, SetStateAction} from 'react';
import {API} from 'core/API';
import {RouteComponentProps} from 'react-router-dom';
import moment, {Moment, utc} from 'moment';
import qs from 'qs';
//translate
import i18n from 'components/translate';
//functions
import {excelDateToJSDate} from './index';
import {DateRange, IDateRange, IInviteUserRequest, InviteUsersOrganizationRequest} from '@joc/api-gateway';
//types
import {VOLUNTEERS_FILTERS_VALUES} from 'core/types';
import {RegisterVolunteersByAdminRequest} from '@joc/api-gateway/lib/api-client';

export const invitePopupSubmitClickHandler = async (
  values: Array<IInviteUserRequest>,
  orgId: string | undefined,
  setIsLoading: (isLoading: boolean) => void,
  setIsSuccess: (isSuccess: boolean) => void,
  setCustomError: (customError?: string) => void,
  isAddingByAdmin = false,
  password?: string
) => {
  // TODO: add validation if school make schoolgrade mandatory
  setIsLoading(true);
  try {
    if (!orgId) throw new Error(`${i18n.t('errors:sorry')} ${i18n.t('errors:cantFindOrgId')}`);
    const valuesWithPosition = values.map((item) => {
      const keys = Object.keys(item);
      const itemValues = Object.values(item).map((value) => (value === '' ? undefined : value));
      // eslint-disable-next-line no-return-assign
      keys.forEach((key, ind) => ((item as any)[key] = itemValues[ind]));
      return {
        ...item,
        gradeId: !!item.gradeId ? item.gradeId.toString() : undefined,
        positionId: 3,
        roleId: 3,
        birthDate: !!item.birthDate ? excelDateToJSDate(item.birthDate) : undefined,
        ...(password ? {password} : {}),
      };
    });
    if (isAddingByAdmin) {
      await API.registerVolunteersByAdmin(
        RegisterVolunteersByAdminRequest.fromJS({volunteers: valuesWithPosition, organizationId: +orgId})
      );
    } else {
      await API.inviteVolunteers(
        +orgId,
        orgId,
        InviteUsersOrganizationRequest.fromJS({invitedUsers: valuesWithPosition})
      );
    }

    setIsLoading(false);
    // TODO: [JF-1077] [FE] setIsSuccess check if this needed
    setIsSuccess(true);
  } catch (error) {
    setIsLoading(false);
    // todo: add pencil to student when error
    if (error?.response?.message) {
      const err = error.response.message[0].split('.');
      const correctMessage = `Invalid ${err[2].split(' ')[0]} in ${+err[1] + 1} row`;
      setCustomError(correctMessage);
    }
    if (error?.message) {
      setCustomError(error.message);
    } else {
      const err = error.message[0].split('.');
      const correctMessage = `Invalid ${err[2].split(' ')[0]} in ${+err[1] + 1} row`;
      setCustomError(correctMessage);
    }
  }
};

export const getTransformDate = (fromDate?: Moment | null, toDate?: Moment | null) => {
  const newFromDate = fromDate ? moment(fromDate).startOf('day').toDate() : null;
  const newToDate = toDate ? moment(toDate).endOf('day').toDate() : moment().endOf('day').toDate();

  return {fromDate: newFromDate, toDate: newToDate};
};

export const VolunteersFiltersSubmitClickHandler = (
  values: VOLUNTEERS_FILTERS_VALUES | undefined,
  history: RouteComponentProps['history']
): void => {
  const newParams = qs.parse(location.search, {ignoreQueryPrefix: true});

  const {fromDate, toDate} = getTransformDate(values?.fromDate, values?.toDate);

  if (fromDate) {
    newParams.createdDate = {
      fromDate: fromDate as unknown as string,
    };
  } else {
    const queryParamsCreatedDate = newParams?.createdDate as Record<string, string>;
    delete queryParamsCreatedDate?.fromDate;
  }

  if (toDate) {
    newParams.createdDate = {
      toDate: toDate as unknown as string,
    };
  } else {
    const queryParamsCreatedDate = newParams?.createdDate as Record<string, string>;
    delete queryParamsCreatedDate?.toDate;
  }

  if (toDate && fromDate) {
    newParams.createdDate = {
      fromDate: fromDate as unknown as string,
      toDate: toDate as unknown as string,
    };
  } else {
    delete newParams?.createdDate;
  }

  if (values?.minAge && values?.maxAge) {
    newParams.ages = [{min: values.minAge.toString(), max: values.maxAge.toString()}];
  }

  if (values?.minAge && !values?.maxAge) {
    newParams.ages = [{min: values.minAge.toString(), max: '100'}];
  }

  if (!values?.minAge && values?.maxAge) {
    newParams.ages = [{min: '0', max: values.maxAge.toString()}];
  }

  if (!values?.minAge && !values?.maxAge) {
    delete newParams.ages;
  }

  values?.genderId ? (newParams.genderId = values.genderId.toString()) : delete newParams.genderId;

  values?.howFar ? (newParams.howFar = values.howFar.toString()) : delete newParams.howFar;

  history.push({search: qs.stringify(newParams)});
};

export const StatitsicsFilterFormSubmitClickHandler = (values: IDateRange, history: RouteComponentProps['history']) => {
  const {fromDate: chosenFromDate = null, toDate: chosenToDate = null} = values;

  const fromDate = moment(chosenFromDate).startOf('day');
  const toDate = moment(chosenToDate).endOf('day');

  const queryParams = qs.stringify({
    fromDate: fromDate.toISOString(),
    toDate: toDate.toISOString(),
  });
  history.push({search: queryParams});
};
