import {Dispatch, FC, SetStateAction} from 'react';
//components
import {Snackbar} from '@material-ui/core';
import {Alert} from '@material-ui/lab';

type SnackbarSuccessProps = {
  isOpenSnackbar: boolean;
  setIsOpenSnackbar: Dispatch<SetStateAction<boolean>>;
  text: string;
  hideDuration?: number;
};

const SnackbarSuccess: FC<SnackbarSuccessProps> = ({
  isOpenSnackbar,
  setIsOpenSnackbar,
  text,
  hideDuration = 2000,
}: SnackbarSuccessProps) => {
  return (
    <Snackbar open={isOpenSnackbar} autoHideDuration={hideDuration} onClose={() => setIsOpenSnackbar(false)}>
      <Alert onClose={() => setIsOpenSnackbar(false)} severity="success">
        {text}
      </Alert>
    </Snackbar>
  );
};

export default SnackbarSuccess;
