import {Dispatch, FC, SetStateAction, useMemo, useState} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {useTranslation} from 'react-i18next';
//redux
import {Store} from 'redux/root';
import {selectorGetOrgActiveStatus, selectorGetOrgId, selectorGetSchoolId} from 'redux/organization-service/selector';
//types
import {
  IGetVolunteerByIdResponse,
  IOpportunityResponse,
  OPPORTUNITY_STATUSES,
  OPPORTUNITY_TYPES,
  ORGANISATION_ACTIVE_STATUS,
} from '@joc/api-gateway';
//components
import PopupContainer from 'shared/components/PopupContainer';
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
import ButtonConfirmAll from './ButtonConfirmAll';
import PossibleVolunteersTable from './PossibleVolunteersTable';
import SmartGroupPopup from './SmartGroupPopup';
import ButtonUpToTop from 'shared/components/Buttons/ButtonUpToTop';
import {VolunteerLimit} from 'shared/components/VolunteerLimit/VolunteerLimit';
//styles
import styles from './PopupVolunteers.module.scss';

type PopupVolunteersParentProps = {
  opportunityId: number;
  opportunityName: string;
  opportunityStatus: OPPORTUNITY_STATUSES;
  volunteersArray: Array<IGetVolunteerByIdResponse>;
  setIsShowPopup: Dispatch<SetStateAction<boolean>>;
  opportunityType: OPPORTUNITY_TYPES;
  isApprovalRequired: boolean | undefined;
  opportunity: IOpportunityResponse;
};

const PopupVolunteers: FC<PopupVolunteersProps> = ({
  opportunityId,
  setIsShowPopup,
  opportunityName,
  volunteersArray,
  opportunityStatus,
  opportunityType,
  orgId,
  schoolId,
  orgStatus,
  isApprovalRequired,
  opportunity,
}: PopupVolunteersProps) => {
  const [isSmartGroupPopupOpen, setIsSmartGroupPopupOpen] = useState<boolean>(false);

  const volunteersArrayMembers = useMemo(() => {
    if (orgId) return volunteersArray.filter((i) => i.organizations?.map((org) => +org.id).includes(orgId));
    return [];
  }, [orgId, volunteersArray]);

  const {t} = useTranslation(['popup', 'buttons', 'oppoViewVolunteer']);

  const choosePopupTitle = () => {
    if (opportunityStatus === OPPORTUNITY_STATUSES.CLOSED) {
      return t('popup:opportunity.pastOpportunityDetails');
    }
    if (!!schoolId) {
      return t('popup:opportunity.possibleStudents');
    }
    return t('popup:opportunity.possibleVolunteers');
  };

  const isSuspended = orgStatus?.status === ORGANISATION_ACTIVE_STATUS.SUSPENDED;
  const isVisibleVolunteersLimit = useMemo(() => opportunity.volunteersMaxCount !== null, [opportunity]);

  return (
    <PopupContainer isContentFullWidth containerClassName="PossibleVolunteersTable" setIsShowPopup={setIsShowPopup}>
      <>
        <div className={styles.content__header}>
          <h2 className="title">{choosePopupTitle()}</h2>
          <div className={styles.content__header__chesed_wrapper}>
            <span className={styles.content__header__chesed}>{t('popup:opportunity.opportunity')}</span>
            <span className={styles.content__header__chesed_name}>{opportunityName}</span>
          </div>
          <div className={styles.volunteersLimit}>
            {isVisibleVolunteersLimit && (
              <>
                <p className={styles.volunteersLimit__title}>{`${t('oppoViewVolunteer:volunteersRequired')}:`}</p>
                <div className={styles.volunteersLimit__wrapper}>
                  <VolunteerLimit
                    maxVolunteers={opportunity.volunteersMaxCount || 0}
                    currentVolunteers={opportunity.volunteersCount || 0}
                  />
                </div>
              </>
            )}
            {opportunityType !== OPPORTUNITY_TYPES.MANUAL ? (
              opportunityStatus === OPPORTUNITY_STATUSES.OPEN ? (
                <ButtonConfirmAll
                  opportunityId={opportunityId}
                  volunteersArray={volunteersArray}
                  disabled={isSuspended}
                />
              ) : (
                <ButtonDefault
                  title={t('buttons:button.createSmartGroup')}
                  classList={['lg', 'primary']}
                  clickHandler={() => setIsSmartGroupPopupOpen(true)}
                />
              )
            ) : null}
          </div>
        </div>

        {isSmartGroupPopupOpen ? (
          <SmartGroupPopup
            opportunityName={opportunityName}
            setIsShowPopup={setIsShowPopup}
            opportunityId={opportunityId}
            volunteersArray={volunteersArray}
            opportunityStatus={opportunityStatus}
            opportunityType={opportunityType}
            isApprovalRequired={isApprovalRequired}
          />
        ) : (
          <>
            <div className={styles.content}>
              <PossibleVolunteersTable
                opportunityId={opportunityId}
                volunteersArray={volunteersArray}
                opportunityStatus={opportunityStatus}
                opportunityType={opportunityType}
                volunteersArrayMembers={volunteersArrayMembers}
                isOrgSuspended={isSuspended}
                isApprovalRequired={isApprovalRequired}
              />
            </div>
            <ButtonUpToTop containerSelector="PossibleVolunteersTable" isPopup />
          </>
        )}
      </>
    </PopupContainer>
  );
};

const mapStateToProps = (store: Store) => ({
  orgId: selectorGetOrgId(store),
  orgStatus: selectorGetOrgActiveStatus(store),
  schoolId: selectorGetSchoolId(store),
});

const connector = connect(mapStateToProps);

type PopupVolunteersProps = ConnectedProps<typeof connector> & PopupVolunteersParentProps;

export default connector(PopupVolunteers);
