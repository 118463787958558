// react
import {FC} from 'react';
// components
import OpportunitiesFilters from 'components/Organization/Opportunities/OpportunitiesFilters';
// mui
import Drawer from '@material-ui/core/Drawer';
import {makeStyles} from '@material-ui/core/styles';
// context
import {useOpportunitiesContext} from 'pages/Organization/Opportunities/context';
// data
import {defaultSidebarConfig} from 'components/Organization/Opportunities/OpportunitiesFilters/OpportunitiesFiltersForm/data';

const useStyles = makeStyles({
  paper: {
    zIndex: 99,
  },
});

export const OppoSidebar: FC = () => {
  const classes = useStyles();

  const {
    sidebarVisibility: {isShowFilter},
    setSidebarVisibility,
  } = useOpportunitiesContext();

  const handleClose = () => {
    setSidebarVisibility(defaultSidebarConfig);
  };

  return (
    <Drawer
      anchor="right"
      variant="persistent"
      classes={{
        paper: classes.paper,
      }}
      open={isShowFilter}
      onClose={handleClose}
    >
      <OpportunitiesFilters />
    </Drawer>
  );
};
