import {FC, useMemo} from 'react';
import moment, {Moment} from 'moment';
import cx from 'classnames';
import {IStatusResponse} from '@joc/api-gateway';
// redux
import {useSelector} from 'react-redux';
import {Store} from '../../../../../redux/root';
// components
import StatusMedia from './StatusMedia';
import StatusAction from './StatusActions';
// icons
import {ReactComponent as ClockSvg} from '../../../../../assets/icons/clock.svg';
import {ReactComponent as NoStatusImage} from 'assets/image/no-items-image.svg';
// styles
import styles from './StatusesTable.module.scss';

type StatusesTableProps = {search?: string};

const StatusesTable: FC<StatusesTableProps> = ({search}) => {
  const statuses = useSelector((store: Store) => store.statusRedux.statusRecords);
  const phase = (createDate: Date) =>
    moment().isSameOrAfter(createDate)
      ? moment(createDate).add(1, 'day').isBefore(moment())
        ? 'Archived'
        : 'Published'
      : 'Scheduled';

  const formatDate = (date: Moment) => date.format('MMMM DD, YYYY, hh:mm');

  const filteredStatuses = useMemo(
    () =>
      search
        ? statuses.filter((status) => status.caption?.includes(search) || status.linkTitle?.includes(search))
        : statuses,
    [statuses, search]
  );

  return (
    <section className={styles.statuses__wrapper}>
      {filteredStatuses.length ? (
        <table className={styles.statuses}>
          <thead>
            <tr>
              <th align="left" className={styles.statuses_media}>
                Content Type
              </th>
              <th align="left" className={styles.statuses_caption}>
                Caption
              </th>
              <th align="left" className={styles.statuses_link}>
                Link
              </th>
              <th align="left" className={styles.statuses_startDate}>
                Publication Date
              </th>
              <th align="left" className={styles.statuses_endDate}>
                End Date
              </th>
              <th align="left" className={styles.statuses_phase}>
                Phase
              </th>
              <th align="left" aria-label="Actions" />
            </tr>
          </thead>
          <tbody>
            {filteredStatuses
              .sort((a, b) => a.id - b.id)
              .map((status, index) => (
                <tr key={index}>
                  <td className={styles.statuses_media}>
                    <StatusMedia url={status.mediaPath} />
                  </td>
                  <td className={cx(styles.statuses_caption, styles.statuses_row_caption)} title={status.caption}>
                    <span>{status.caption}</span>
                  </td>
                  <td className={cx(styles.statuses_link, styles.statuses_row_link)} title={status.linkTitle}>
                    <a href={status.link} target="_blank" rel="noreferrer">
                      {status.linkTitle}
                    </a>
                  </td>
                  <td className={styles.statuses_startDate}>
                    <div className={styles.statuses_startDate_wrapper}>
                      <ClockSvg />
                      <span>{formatDate(moment(status.startDate))}</span>
                    </div>
                  </td>
                  <td className={cx(styles.statuses_endDate)}>
                    <div className={styles.statuses_endDate_wrapper}>
                      <ClockSvg />
                      <span>{formatDate(moment(status.startDate).add(1, 'day'))}</span>
                    </div>
                  </td>
                  <td className={cx(styles.statuses_phase, styles.statuses_row_phase)} title={phase(status.startDate)}>
                    <span>{phase(status.startDate)}</span>
                  </td>
                  <td align="center">
                    <StatusAction status={status} />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      ) : (
        <div className={styles.noItemsContainer}>
          <NoStatusImage className={styles.noItemsImage} />
          <div className={styles.noItemsTextContainer}>
            <span className={styles.noItemsTitle}>Oops</span>
            <span className={styles.noItemsText}>
              There are currently no statuses in the list. Create them to see them here.
            </span>
          </div>
        </div>
      )}
    </section>
  );
};

export default StatusesTable;
