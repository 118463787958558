import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import cx from 'classnames';
//helpers
import {ButtonTab, CurrentTab} from 'components/Organization/Volunteers/VolunteersPopups/InvitePopup/helpers';
//styles
import styles from './ButtonTabs.module.scss';

type ButtonTabsProps = {
  currentTab: CurrentTab;
  tabClickHandler: (tab: CurrentTab) => () => void;
};

const ButtonTabs: FC<ButtonTabsProps> = ({currentTab, tabClickHandler}) => {
  const {t} = useTranslation('inviteColleagues');

  const tabs: Array<ButtonTab> = [
    {
      title: t('singleForm'),
      clickHandler: tabClickHandler(CurrentTab.form),
      isActive: currentTab === CurrentTab.form,
    },
    // {
    //   title: t('byList'),
    //   clickHandler: tabClickHandler(CurrentTab.list),
    //   isActive: currentTab === CurrentTab.list,
    // },
    {
      title: t('addSingle'),
      clickHandler: tabClickHandler(CurrentTab.adding),
      isActive: currentTab === CurrentTab.adding,
    },
  ];

  return (
    <div className={styles.tabs}>
      {tabs.map((tab) => {
        return !tab.isHide ? (
          <button
            key={tab.title}
            className={cx(styles.tabs__tab, tab.isActive && styles.tabs__tab_active)}
            onClick={tab.clickHandler}
          >
            {tab.title}
          </button>
        ) : null;
      })}
    </div>
  );
};

export default ButtonTabs;
