import {Dispatch, FC, SetStateAction, useContext, useMemo, useState} from 'react';
import {connect, ConnectedProps, useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import cx from 'classnames';
//redux
import {Store} from 'redux/root';
import {selectorGetSchoolId} from 'redux/organization-service/selector';
import {setVolunteersInvited} from 'redux/invites-service/action';
//context
import {CurrentTab} from '../InvitePopup/helpers';
import {VolunteersPageContext, VolunteersPageContextType} from 'pages/Organization/Volunteers';
//components
import Appeal from 'shared/components/Appeal/DefaultRenderer';
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
//styles
import styles from './PopupCongrats.module.scss';

type PopupCongratsParentProps = {
  setIsShowPopup: Dispatch<SetStateAction<boolean>>;
  resendInvites?: boolean;
  isSuccessAdding?: boolean;
  currentTab?: CurrentTab;
};

const PopupCongrats: FC<PopupCongratsProps> = ({
  setIsShowPopup,
  schoolId,
  resendInvites,
  isSuccessAdding,
  currentTab,
}: PopupCongratsProps) => {
  const [isClosePopup, setIsClosePopup] = useState<boolean>(false);

  const dispatch = useDispatch();

  const {t} = useTranslation(['messages', 'inviteColleagues', 'buttons']);

  const {refreshVolunteers}: VolunteersPageContextType = useContext(VolunteersPageContext);

  const doneButtonClickHandler = () => {
    setIsClosePopup(true);
    resendInvites ? dispatch(setVolunteersInvited()) : setTimeout(() => setIsShowPopup(false), 200);
    refreshVolunteers();
  };

  const getSubtitle = useMemo(() => {
    const isListForm = currentTab === CurrentTab.list;
    const isStudentsAdded = currentTab === CurrentTab.addByList;

    const user = !!schoolId ? t('inviteColleagues:student') : t('inviteColleagues:volunteer');
    const users = !!schoolId ? t('inviteColleagues:students2') : t('inviteColleagues:volunteers2');
    const orgSchoolDetect = !!schoolId ? t('inviteColleagues:school') : t('inviteColleagues:org');
    const isAdding = !isSuccessAdding
      ? isListForm
        ? t('inviteColleagues:invitedMany')
        : t('inviteColleagues:invited')
      : `${t('inviteColleagues:added')} ${orgSchoolDetect}`;

    return isStudentsAdded
      ? t('messages:studentsAddedSuccessfully')
      : `${t('inviteColleagues:the')} ${isListForm ? users : user} ${isAdding}`;
  }, [schoolId, isSuccessAdding]);

  return (
    <div
      className={cx(styles.container, {
        [styles.close]: isClosePopup,
      })}
    >
      <Appeal isHeightDisable>
        {!resendInvites ? (
          <div className={cx(styles.appeal, isSuccessAdding && styles.appealAdding)}>
            <span className="title">{t('messages:congratulations')}</span>
            <span className={styles.subtitle}>{getSubtitle}</span>
            {!isSuccessAdding && currentTab !== CurrentTab.addByList && (
              <span className={styles.subtitle}>{t('inviteColleagues:messageSent')}</span>
            )}
          </div>
        ) : (
          <div className={styles.appeal}>
            <span className="title">{t('messages:congratulations')}</span>
            <span className={styles.subtitle}>{t('inviteColleagues:messageResent')}</span>
          </div>
        )}
      </Appeal>
      <div className={styles.options}>
        <ButtonDefault
          classList={['lg', 'primary']}
          title={t('buttons:button.done')}
          clickHandler={doneButtonClickHandler}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (store: Store) => ({
  schoolId: selectorGetSchoolId(store),
});

const connector = connect(mapStateToProps);
type PopupCongratsProps = ConnectedProps<typeof connector> & PopupCongratsParentProps;

export default connector(PopupCongrats);
