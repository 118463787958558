// react
import {useSelector} from 'react-redux';
// redux
import {
  selectorGetUserYearlyOppos,
  selectorGetUserYearlyOpposGoal,
  selectorGetUserYearlyTime,
  selectorGetUserYearlyTimeGoal,
} from 'redux/user-service/selector';
// components
import {ProgressBar} from './ProgressBar';
// assets
import HourglassImage from 'assets/image/hourglass.png';
import ChesedHeartImage from 'assets/image/chesedHeart.png';
// styles
import styles from './Progress.module.scss';
import {useMemo} from 'react';
import {useViewport} from '../../../../hooks';
import cx from 'classnames';

const ProgressData = () => {
  const {width, outerWidth} = useViewport();

  const userYearlyTime = useSelector(selectorGetUserYearlyTime);
  const userYearlyTimeGoal = useSelector(selectorGetUserYearlyTimeGoal);
  const userYearlyOppos = useSelector(selectorGetUserYearlyOppos);
  const userYearlyOpposGoal = useSelector(selectorGetUserYearlyOpposGoal);

  const getYearlyTimeProgress = useMemo(() => {
    if (!userYearlyTimeGoal || userYearlyTimeGoal === 0) return null;

    if (!userYearlyTime || userYearlyTime === 0) return 0;

    if (Math.round(userYearlyTime / 3600) > userYearlyTimeGoal) return 100;

    return Math.round((Math.round(userYearlyTime / 3600) / userYearlyTimeGoal) * 100);
  }, [userYearlyTime, userYearlyTimeGoal]);

  const getYearlyOpposProgress = useMemo(() => {
    if (userYearlyOpposGoal === 0) return null;

    if (userYearlyOppos === 0) return 0;

    if (userYearlyOppos > userYearlyOpposGoal) return 100;

    return Math.round((userYearlyOppos / userYearlyOpposGoal) * 100);
  }, [userYearlyOppos, userYearlyOpposGoal]);

  const isMobile = useMemo(() => width < 736 || outerWidth < 736, [width, outerWidth]);

  return (
    <div className={cx(styles.wrap, isMobile ? styles.mobileWrap : '')}>
      <div className={cx(styles.goalBlock, isMobile ? styles.mobileGoalsBlock : '')}>
        <div className={styles.goalData}>
          <span>Chesed Hours</span>
          <span className={styles.progressLabel}>
            {getYearlyTimeProgress !== null ? (
              <>
                {Math.round(userYearlyTime / 3600)}/{userYearlyTimeGoal}
              </>
            ) : (
              <>{Math.round(userYearlyTime / 3600)}</>
            )}
          </span>
          {getYearlyTimeProgress !== null ? <ProgressBar progress={getYearlyTimeProgress} /> : null}
        </div>
        <img className={styles.image} src={HourglassImage} alt="hourglass" />
      </div>
      <div className={styles.goalBlock}>
        <div className={styles.goalData}>
          <span>Chesed Acts</span>
          <span className={styles.progressLabel}>
            {userYearlyOppos}/{userYearlyOpposGoal}
          </span>
          {getYearlyOpposProgress === null ? <></> : <ProgressBar progress={getYearlyOpposProgress} />}
        </div>
        <img className={styles.image} src={ChesedHeartImage} alt="chesed heart" />
      </div>
    </div>
  );
};

export default ProgressData;
