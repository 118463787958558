import {
  INewCreateOrganizationRequest,
  GetLocationIdByAddressRequest,
  ORGANISATION_GENERAL_TYPES,
} from '@joc/api-gateway/lib/api-client';
import {ContactOrg} from 'redux/organization-service/types';

export const INITIAL_ORG_SIGNUP: INewCreateOrganizationRequest = {
  organizationName: '',
  organisationGeneralType: ORGANISATION_GENERAL_TYPES.OTHER,
  organizationLogoPath: '',
  organizationDescription: '',
  organizationPhoneNumber: '',
  organizationEmail: '',
  organizationWebSite: '',
  organisationAddress: GetLocationIdByAddressRequest.fromJS({
    countryName: '',
    stateName: '',
    cityName: '',
    streetName: '',
    buildingNumber: '',
    zipCode: '',
    pobox: '',
  }),
};

export const mokValues: INewCreateOrganizationRequest = {
  organizationName: 'American Express',
  organisationGeneralType: ORGANISATION_GENERAL_TYPES.OTHER,
  organizationLogoPath: '093f0d35-aded-474d-bea8-e771bd8a6601-2022-07-12_16-37.png',
  organizationDescription: 'some huge org',
  organizationPhoneNumber: '+380681684122',
  organizationEmail: '',
  organizationWebSite: 'https://www.americanexpress.com/',
  organisationAddress: GetLocationIdByAddressRequest.fromJS({
    countryName: 'Canada',
    stateName: 'Ontario',
    cityName: 'Richmond Hill',
    streetName: 'Highway 7',
    buildingNumber: '600',
    zipCode: 'L4B 1B2',
    pobox: 'L4B 1B2',
  }),
};

export const INITIAL_CONTACT_ORG_SIGNUP: ContactOrg = {
  organizationPhoneNumber: '',
  organizationEmail: '',
  organizationWebSite: '',
  address: {
    countryName: '',
    stateName: '',
    cityName: '',
    streetName: '',
    buildingNumber: '',
    zipCode: '',
    pobox: '',
    longitude: 0,
    latitude: 0,
  },
};

export const MOK_CONTACT_ORG_SIGNUP: ContactOrg = {
  organizationPhoneNumber: '+380681684141',
  organizationEmail: '',
  organizationWebSite: 'https://www.americanexpress.com/',
  address: {
    countryName: 'Canada',
    stateName: 'Ontario',
    cityName: 'Richmond Hill',
    streetName: 'Highway 7',
    buildingNumber: '600',
    zipCode: 'L4B 1B2',
    pobox: 'L4B 1B2',
    longitude: -79.38163349999999,
    latitude: 43.84571239999999,
  },
};
