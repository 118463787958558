import {FC} from 'react';
import cx from 'classnames';
//api
import {
  GetVolunteerByIdResponse,
  IOpportunityResponse,
  OPPORTUNITY_STATUSES,
  OPPORTUNITY_TYPES,
} from '@joc/api-gateway';
//components
import PossibleVolunteersItem from 'shared/components/PossibleVolunteersItem';
//styles
import styles from './PossibleVolunteersCell.module.scss';
import defaultStyles from '../CellDefault.module.scss';

type PossibleVolunteersCellProps = {
  volunteers: Array<GetVolunteerByIdResponse> | undefined;
  opportunityId: number;
  isApprovalRequired: boolean;
  opportunityName: string;
  opportunityStatus: OPPORTUNITY_STATUSES;
  opportunityType: OPPORTUNITY_TYPES;
  disableButton: boolean;
  opportunity: IOpportunityResponse;
};

const PossibleVolunteersCell: FC<PossibleVolunteersCellProps> = ({
  volunteers,
  opportunityId,
  isApprovalRequired,
  opportunityName,
  opportunityStatus,
  opportunityType,
  disableButton,
  opportunity,
}: PossibleVolunteersCellProps) => {
  return (
    <div className={cx(styles.possibleVolunteers, defaultStyles.main)}>
      <PossibleVolunteersItem
        opportunity={opportunity}
        isShowVolunteerLimit
        volunteers={volunteers}
        opportunityId={opportunityId}
        isApprovalRequired={isApprovalRequired}
        opportunityName={opportunityName}
        opportunityStatus={opportunityStatus}
        opportunityType={opportunityType}
        actionsDisable={opportunityStatus === OPPORTUNITY_STATUSES.CLOSED}
        isViewVolunteersDisable={disableButton}
      />
    </div>
  );
};

export default PossibleVolunteersCell;
