import {FC, ReactNode} from 'react';
import cx from 'classnames';
//components
import FiltersHeader from '../FiltersHeader';
//styles
import styles from './FiltersContainer.module.scss';

type FiltersContainerProps = {
  children: ReactNode;
  changeVisibilityFilters: () => void;
  resetForm: () => void;
  isCloseFilters: boolean;
  isOrgFilter?: boolean;
};

const FiltersContainer: FC<FiltersContainerProps> = ({
  children,
  changeVisibilityFilters,
  isCloseFilters,
  isOrgFilter,
  resetForm,
}) => {
  return (
    <div className={cx(styles.main, isCloseFilters && styles.close, isOrgFilter && styles.orgStyle)}>
      <div className={styles.content}>
        <FiltersHeader changeVisibilityFilters={changeVisibilityFilters} resetForm={resetForm} />
        <hr className={styles.separator} />
        <div className={styles.content__filters}>{children}</div>
      </div>
    </div>
  );
};

export default FiltersContainer;
