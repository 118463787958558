import {FC, MouseEventHandler} from 'react';
import cx from 'classnames';
//types
import {IMAGE_WITH_POPUP_TYPE} from 'core/types';
//components
import ImageWithPopup from 'shared/components/ImageWithPopup';
//styles
import styles from './AvatarCell.module.scss';
import defaultStyles from '../CellDefault.module.scss';

type AvatarCellProps = {
  imagePath: string | undefined;
  avatarTitle: string;
  disablePopupZeroTop?: boolean;
  popupType?: IMAGE_WITH_POPUP_TYPE;
  onClick?: MouseEventHandler;
};

const AvatarCell: FC<AvatarCellProps> = ({imagePath, avatarTitle, disablePopupZeroTop, popupType, onClick}) => (
  <div className={cx(styles.avatar, defaultStyles.main)} onClick={onClick}>
    <ImageWithPopup
      imagePath={imagePath}
      popupTitle={avatarTitle}
      disableZeroTop={disablePopupZeroTop}
      type={popupType}
    />
  </div>
);

export default AvatarCell;
