import {FC, useEffect, useState} from 'react';
import moment from 'moment';
import {useTranslation} from 'react-i18next';
//components
import SimpleSelect from 'shared/components/SelectDropdown/SimpleSelect';
//styles
import styles from './index.module.scss';

const LangSwitch: FC = () => {
  const {i18n} = useTranslation();

  const detectedLang = i18n.language.split('-')[0].toUpperCase();
  const fallbackLng = i18n.options.fallbackLng?.toString().toUpperCase();
  const defaultLang = detectedLang === 'HE' ? detectedLang : fallbackLng;

  const [lang, setLang] = useState(defaultLang);
  useEffect(() => {
    moment.locale(lang);
  }, [lang]);

  useEffect(() => {
    setLang(detectedLang);
  }, [i18n.language]);

  i18n.on('languageChanged', (lng: string) => {
    moment.locale(lng);
  });

  const options = ['EN', 'HE'].filter((language) => language !== detectedLang);

  return (
    <div className={styles.LangSwitch}>
      <SimpleSelect options={options} value={lang} langSwitch parentClass={styles.wrap} />
    </div>
  );
};

export default LangSwitch;
