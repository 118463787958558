import {FC, Dispatch, SetStateAction, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import firebase from 'firebase';
import qs from 'qs';
//API
import {API} from 'core/API';
//redux
import {setError} from 'redux/error-service/action';
//formik
import {Field, FieldProps, Form, useFormikContext} from 'formik';
//urls
import {urls} from 'core/appUrls';
//functions
import {emailBlurSignUpMainFormV} from 'core/functions/emailBlur';
//types
import {ValidateUserEmailRequest} from '@joc/api-gateway';
import {LocalSrorageUserData, Picture, SocialProviderVolunteer} from 'core/types';
import {ClaimJoinParsedQs} from 'core/types/queryStrings';
//components
import Loader from 'shared/components/Loader';
import InputContainer from 'shared/components/FormInputs/InputContainer';
import InputText from 'shared/components/FormInputs/InputText';
import InputErrorHint from 'shared/components/InputErrorHint';
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
import OptionsSeparator from '../../LoginFormV/OptionsSeparator';
import SocialButton from '../../LoginFormV/SocialButton';
//styles
import styles from './SignUpMainFormV.module.scss';

type SignUpMainFormVParentsProps = {
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  setIsShowAboutYouForm: Dispatch<SetStateAction<boolean>>;
};

const SignUpMainFormV: FC<SignUpMainFormVParentsProps> = ({isLoading, setIsLoading, setIsShowAboutYouForm}) => {
  const history = useHistory();

  const location = useLocation();

  const dispatch = useDispatch();

  const [errorEmail, setEmailError] = useState('');
  const [socialProviders] = useState({
    facebookProvider: new firebase.auth.FacebookAuthProvider(),
    googleProvider: new firebase.auth.GoogleAuthProvider(),
    appleProvider: new firebase.auth.OAuthProvider('apple.com'),
  });

  const {errors, touched, dirty, isValid} = useFormikContext<{email: string}>();

  const {t} = useTranslation(['signUpUser', 'inputs', 'buttons']);

  const parsedQs = qs.parse(location.search, {ignoreQueryPrefix: true}) as ClaimJoinParsedQs;

  const signupSocialVolunteer = async (provider: SocialProviderVolunteer): Promise<void> => {
    setIsLoading(true);
    try {
      const userFirebaseResponse = await firebase.auth().signInWithPopup(provider);
      if (userFirebaseResponse.user?.email)
        await API.validateUser(ValidateUserEmailRequest.fromJS({email: userFirebaseResponse.user?.email}));
      provider.addScope('user_birthday');
      if (userFirebaseResponse.credential) {
        const userFirebaseInfo: LocalSrorageUserData | null | undefined = await userFirebaseResponse.additionalUserInfo
          ?.profile;
        const userPicture: string =
          userFirebaseInfo && !!(userFirebaseInfo?.picture as string).length
            ? (userFirebaseInfo.picture as string)
            : userFirebaseInfo
            ? (userFirebaseInfo.picture as Picture).data.url
            : '';
        const userUid = userFirebaseResponse.user?.uid;
        const userFirebaseInfoObj = userFirebaseInfo && {
          firstName: userFirebaseInfo.first_name?.length
            ? userFirebaseInfo.first_name
            : userFirebaseInfo.given_name?.length
            ? userFirebaseInfo.given_name
            : '',
          lastName: userFirebaseInfo.family_name?.length
            ? userFirebaseInfo.family_name
            : userFirebaseInfo.last_name?.length
            ? userFirebaseInfo.last_name
            : '',
          email: userFirebaseInfo.email?.length ? userFirebaseInfo.email : '',
          birthday: !!userFirebaseInfo.birthday && new Date(userFirebaseInfo.birthday),
          picture: userPicture,
          organisationId: parsedQs.organisationId || '',
        };
        localStorage.setItem('firebaseUid', JSON.stringify(userUid));
        localStorage.setItem('userFirebaseInfo', JSON.stringify(userFirebaseInfoObj));
        localStorage.setItem('userFirebaseInfo', JSON.stringify(userFirebaseInfoObj));
        localStorage.setItem('socialSignup', JSON.stringify(true));

        setIsLoading(false);
        setIsShowAboutYouForm(true);
      }
    } catch (error) {
      console.log('file: index.tsx:93  error:', error);
      dispatch(setError(error?.response?.message || error.message, error.code));
      setIsLoading(false);
    }
  };

  const onBlurEmailHandler = (email: string) => emailBlurSignUpMainFormV(email, setEmailError);

  return (
    <Form className={styles.form}>
      <Field name="email">
        {({field}: FieldProps) => (
          //TODO: redo to async yup validation
          <InputContainer isDisabledMargin>
            <InputText field={field} placeholder={t('inputs:placeholders.email')} onBlur={onBlurEmailHandler} />

            {(errorEmail && <InputErrorHint errorText={errorEmail} />) ||
              (errors.email && touched.email && <InputErrorHint errorText={errors.email} />)}
          </InputContainer>
        )}
      </Field>

      {/* <OptionsSeparator marginTopDisable /> */}

      {/* <div className={styles.login_options}> */}
      {/* <SocialButton
          googleType
          clickHandler={() => signupSocialVolunteer(socialProviders.googleProvider)}
          isDisabled={isLoading}
        /> */}
      {/* <SocialButton
            facebookType
            clickHandler={() => signupSocial(socialProviders.facebookProvider)}
            isDisabled={isLoading}
          /> */}
      {/* <SocialButton
            appleType
            clickHandler={() => signupSocial(socialProviders.appleProvider)}
            isDisabled={isLoading}
          /> */}
      {/* </div> */}
      {isLoading ? (
        <div className={styles.button__container}>
          <Loader />
        </div>
      ) : (
        <div className={styles.button__container}>
          <ButtonDefault
            submitType
            classList={['primary', 'extra_lg']}
            title={t('buttons:button.register')}
            disabled={!(dirty && isValid)}
          />
        </div>
      )}
      <div className={styles.loggedIn}>
        <span>{t('signUpUser:existUser')}</span>

        <ButtonDefault
          clickHandler={() => history.push(urls.onboardingLoginVolunteer)}
          classList={['secondary', 'extra_lg']}
          title={t('buttons:button.login')}
        />
      </div>
    </Form>
  );
};

export default SignUpMainFormV;
