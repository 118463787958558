import {FC, ReactNode} from 'react';
import cx from 'classnames';
//styles
import styles from './InputContainer.module.scss';

type InputContainerPropsType = {
  children: ReactNode;
  uploadLogoStyle?: boolean;
  textAreaStyle?: boolean;
  isDisabledMargin?: boolean;
  isSmallHeight?: boolean;
  parentClassnames?: string;
  label?: string;
  isDisableHeight?: boolean;
  disabled?: boolean;
};

const InputContainer: FC<InputContainerPropsType> = ({
  children,
  uploadLogoStyle,
  textAreaStyle,
  isDisabledMargin,
  isSmallHeight,
  parentClassnames,
  label,
  isDisableHeight,
  disabled,
}: InputContainerPropsType) => {
  return (
    <div
      className={cx(styles.main, parentClassnames && parentClassnames, {
        [styles.uploadLogo]: uploadLogoStyle,
        [styles.textArea]: textAreaStyle,
        [styles.margin_disabled]: isDisabledMargin,
        [styles.height_small]: isSmallHeight,
        [styles.disableHeight]: isDisableHeight,
        [styles.disable]: disabled,
      })}
    >
      {label && <label className={styles.label}>{label}</label>}
      {children}
    </div>
  );
};

export default InputContainer;
