// react
import {FC, useEffect, useRef} from 'react';
//helpers
import {handleCheckingAllButtonsForDisabled, transformInHourFormat, transformInMillisecond} from './helper';
// hooks
import {useTimeLimit} from 'hooks/useTimeLimit';
// interfaces
import {ActionType, Actions, TimeLimitProps} from './interfaces';
//components
import FieldWrapper from 'shared/inputs/FieldWrapper';
import {TimeLimitButton} from './TimeLimitButton';
//styles
import styles from './index.module.scss';

export const TimeLimit: FC<TimeLimitProps> = (fieldBaseProps) => {
  const {field, form, disabled} = fieldBaseProps;
  const {setFieldValue, setFieldTouched} = form;

  const intervalRef = useRef<null | NodeJS.Timeout>(null);

  const {
    time,
    setTime,
    buttonDisabledState,
    handlePlusHourAction,
    handleMinusHourAction,
    handlePlusMinuteAction,
    setButtonDisabledState,
    handleMinusMinuteAction,
  } = useTimeLimit();

  const getTimeActions = () => {
    return {
      minusHour: handleMinusHourAction,
      plusHour: handlePlusHourAction,
      minusMinute: handleMinusMinuteAction,
      plusMinute: handlePlusMinuteAction,
    };
  };

  const handleClickTimeButton = (disabledButton: boolean, actionType: ActionType) => {
    const actions: Actions = getTimeActions();

    if (!disabledButton) {
      setFieldTouched(field.name, true);
      return actions[actionType]();
    }
  };

  const stopCounter = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };

  const startCounter = (disabledButton: boolean, actionType: ActionType) => {
    const actions: Actions = getTimeActions();

    if (intervalRef.current) return;

    intervalRef.current = setInterval(() => {
      if (!disabledButton) {
        setFieldTouched(field.name, true);
        actions[actionType]();
      }
    }, 200);
  };

  useEffect(() => {
    const localTime = transformInMillisecond(field.value);
    setTime(localTime);
  }, [field.value]);

  useEffect(() => {
    if ((field.value && time) || (field.value === 0 && time)) {
      const transformedTime = transformInHourFormat(time);
      setFieldValue(field.name, transformedTime);
    }
  }, [time]);

  useEffect(() => {
    handleCheckingAllButtonsForDisabled(time, !!disabled, setButtonDisabledState);
  }, [time]);

  useEffect(() => {
    return () => stopCounter();
  }, []);

  return (
    <FieldWrapper {...fieldBaseProps}>
      <div className={styles.time__wrapper}>
        <div className={styles.time__block_wrapper}>
          <TimeLimitButton
            value="-"
            actionType="minusHour"
            stopCounter={stopCounter}
            startCounter={startCounter}
            disabledButton={buttonDisabledState.isMinusActionHour}
            classNameDisabled={buttonDisabledState.isMinusActionHour}
            disabled={disabled || buttonDisabledState.isMinusActionHour}
            onClick={() => handleClickTimeButton(buttonDisabledState.isMinusActionHour, 'minusHour')}
          />
          <span className={styles.time__value}>{`${time.hour} h`}</span>
          <TimeLimitButton
            value="+"
            actionType="plusHour"
            stopCounter={stopCounter}
            startCounter={startCounter}
            disabledButton={buttonDisabledState.isPlusActionHour}
            classNameDisabled={buttonDisabledState.isPlusActionHour}
            disabled={disabled || buttonDisabledState.isPlusActionHour}
            onClick={() => handleClickTimeButton(buttonDisabledState.isPlusActionHour, 'plusHour')}
          />
        </div>

        <div className={styles.time__divider} />

        <div className={styles.time__block_wrapper}>
          <TimeLimitButton
            value="-"
            actionType="minusMinute"
            stopCounter={stopCounter}
            startCounter={startCounter}
            disabledButton={buttonDisabledState.isMinusActionMinute}
            classNameDisabled={buttonDisabledState.isMinusActionMinute}
            disabled={disabled || buttonDisabledState.isMinusActionMinute}
            onClick={() => handleClickTimeButton(buttonDisabledState.isMinusActionMinute, 'minusMinute')}
          />
          <span className={styles.time__value}>{`${time.minute} m`}</span>
          <TimeLimitButton
            value="+"
            actionType="plusMinute"
            stopCounter={stopCounter}
            startCounter={startCounter}
            disabledButton={buttonDisabledState.isPlusActionMinute}
            classNameDisabled={buttonDisabledState.isPlusActionMinute}
            disabled={disabled || buttonDisabledState.isPlusActionMinute}
            onClick={() => handleClickTimeButton(buttonDisabledState.isPlusActionMinute, 'plusMinute')}
          />
        </div>
      </div>
    </FieldWrapper>
  );
};
