import {FC, useMemo} from 'react';
//assets
import NewUserBadge from 'assets/image/badges/en/new-user.svg';
import NewUserBadgeHE from 'assets/image/badges/he/new-user.svg';
import MemberBadge from 'assets/image/diplom.svg';
//functions
import {isShowNewBadge, getIsHebrew} from 'core/functions';
//styles
import styles from './VolunteerBadges.module.scss';

type VolunteerBadgesProps = {
  createDate?: Date;
  isShowMemberBadge?: boolean;
  language?: string;
};

const VolunteerBadges: FC<VolunteerBadgesProps> = ({createDate, isShowMemberBadge, language}: VolunteerBadgesProps) => {
  const isShowNewUserBadge = useMemo(() => createDate && isShowNewBadge(createDate), [createDate]);
  const newUserBadgeSvg = useMemo(() => (getIsHebrew(language) ? NewUserBadgeHE : NewUserBadge), [language]);

  return (
    <div className={styles.badges}>
      {isShowNewUserBadge && <img className={styles.badge_new} src={newUserBadgeSvg} alt="badge" />}
      {isShowMemberBadge && <img className={styles.badge_member} src={MemberBadge} alt="badge" />}
    </div>
  );
};

export default VolunteerBadges;
