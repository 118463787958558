import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import cx from 'classnames';
//constants
import {TABLE_ITEMS_CLASSNAMES} from 'core/constants';
//components
import ButtonConfirmCircle from 'shared/components/Buttons/ButtonActionCircle/ButtonConfirmCircle';
import ButtonRejectCircle from 'shared/components/Buttons/ButtonActionCircle/ButtonRejectCircle';
//styles
import styles from '../CellDefault.module.scss';

type ConfirmRejectActionsProps = {
  confirmHandler: () => void;
  rejectHandler: () => void;
  disabled?: boolean;
};

const ConfirmRejectActions: FC<ConfirmRejectActionsProps> = ({confirmHandler, rejectHandler, disabled}) => {
  const {t} = useTranslation('tooltips');

  return (
    <div className={cx(styles.main, styles.actionRejectButtons, styles[TABLE_ITEMS_CLASSNAMES.options])}>
      <ButtonConfirmCircle disabled={disabled} clickHandler={confirmHandler} title={t('confirm')} />
      <ButtonRejectCircle disabled={disabled} clickHandler={rejectHandler} title={t('reject')} />
    </div>
  );
};

export default ConfirmRejectActions;
