// react
import {ChangeEvent, FC, useMemo, useState} from 'react';
import {ThunkAction} from 'redux-thunk';
import {AnyAction} from 'redux';
import {Store} from 'redux/root';
import {useTranslation} from 'react-i18next';
// helpers
import {removeSpaces, getIsTagIncludeSpecialSymbols} from './helper';
// types
import {IHashtag} from 'redux/hashtag-service/types';
// actions
import {createHashtag} from 'redux/hashtag-service/actions';
// mui
import TextField from '@material-ui/core/TextField';
import Autocomplete, {AutocompleteInputChangeReason} from '@material-ui/lab/Autocomplete';
// components
import {HashtagOption} from '../HashtagOption';
import InputErrorHint from 'shared/components/InputErrorHint';
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
//styles
import styles from './index.module.scss';

/**
 *
 * props: the type cannot be found and is listed under the hood as any
 */
const CustomPaper = (props: any) => {
  return (
    <div className={styles.list__wrap}>
      <ul {...props} className={styles.list}>
        {props.children}
      </ul>
    </div>
  );
};

interface ICreateHashtagSectionProps {
  title: string;
  options: IHashtag[];
  orgId: string | null;
  startLoading: <T>(action: ThunkAction<T, Store, void, AnyAction>) => Promise<T>;
}

export const CreateHashtagSection: FC<ICreateHashtagSectionProps> = ({options, title, orgId, startLoading}) => {
  const {t} = useTranslation('validation');

  const [newHashtag, setNewHashtag] = useState('');

  const handleChangeInput = (_event: ChangeEvent<{}>, value: string, _reason: AutocompleteInputChangeReason) => {
    const updatedValue = removeSpaces(value);
    setNewHashtag(updatedValue);
  };

  const handleCreateHashtag = async () => {
    if (!!newHashtag && orgId) {
      await startLoading(createHashtag(orgId, newHashtag));
      setNewHashtag('');
    }
  };

  const isExistingTag = useMemo(
    () => !!options.find((option) => option.name.toLowerCase() === newHashtag.toLowerCase()),
    [options, newHashtag]
  );
  const isIncludeSpecialSymbols = useMemo(() => getIsTagIncludeSpecialSymbols(newHashtag), [newHashtag]);
  const isNewHashtagLongest = useMemo(() => newHashtag.length > 100, [newHashtag]);

  const isSubmitButtonDisabled = useMemo(
    () => !newHashtag || isExistingTag || isIncludeSpecialSymbols || isNewHashtagLongest,
    [newHashtag, isExistingTag, isIncludeSpecialSymbols, isNewHashtagLongest]
  );

  return (
    <>
      <div className={styles.create__section}>
        <h3 className={styles.create__section__title}>{title}</h3>
        <div className={styles.create__section__content}>
          <Autocomplete
            freeSolo
            open={false}
            disableClearable
            value={newHashtag}
            noOptionsText={t('hashTag.notFound')}
            className={styles.create__section__autocomplete}
            options={options.map((option) => option.name)}
            onInputChange={handleChangeInput}
            ListboxComponent={CustomPaper}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder={t('hashTag.name')}
                className={styles.create__section__textField}
              />
            )}
            renderOption={(option) => {
              return <HashtagOption option={option} />;
            }}
          />

          <div className={styles.error__wrapper}>
            {isExistingTag && <InputErrorHint errorText={t('hashTag.alreadyExists')} />}
            {isIncludeSpecialSymbols && <InputErrorHint errorText={t('hashTag.specialSymbols')} />}
            {isNewHashtagLongest && <InputErrorHint errorText={t('hashTag.nameLong')} />}
          </div>

          <div>
            <ButtonDefault
              title={t('hashTag.add')}
              classList={['primary', 'sm']}
              clickHandler={handleCreateHashtag}
              disabled={isSubmitButtonDisabled}
            />
          </div>
        </div>
      </div>
    </>
  );
};
