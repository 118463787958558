import {FC} from 'react';
import {useHistory} from 'react-router';
import {useTranslation} from 'react-i18next';
import cx from 'classnames';
//components
import PopupContainer from 'shared/components/PopupContainer';
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
import Appeal from 'shared/components/Appeal/DefaultRenderer';
//core
import {urls} from 'core/appUrls';
//styles
import styles from './AddUserBirthdayPopup.module.scss';

type AddUserBirthdayPopupProps = {
  handleOpenPopup: (value: boolean) => void;
};

const AddUserBirthdayPopup: FC<AddUserBirthdayPopupProps> = ({handleOpenPopup}) => {
  const history = useHistory();

  const {t} = useTranslation('messages');

  const handleEditProfileClick = () => {
    handleOpenPopup(false);
    history.push(`${urls.volEditAccount}?ref=birthDate`);
  };

  return (
    <PopupContainer parentClassName={styles.popup} isDisableCloseButton isBroadcastStyle>
      <div className={styles.content}>
        <Appeal styleContact isHeightDisable={false} isFullHeight />
        <h1 className={cx('title')}>{t('pleaseUpdateYourProfile')!}</h1>

        <ButtonDefault
          parentClassName={styles.button}
          classList={['primary', 'extra_lg']}
          title={t('goToMyProfile')!}
          clickHandler={handleEditProfileClick}
        />
      </div>
    </PopupContainer>
  );
};

export default AddUserBirthdayPopup;
