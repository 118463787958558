import {Store} from 'redux/root';
import {UserToConnectType} from './types';

export const selectorGetChatUserToConnect = (store: Store): UserToConnectType | null => {
  return store.chatRedux.userToConnect;
};

export const selectorGetChatUnreadMessagesCount = (store: Store): number => {
  return store.chatRedux.unreadCount;
};

export const selectorGetUserConnectionState = (store: Store): boolean => {
  return store.chatRedux.userConnectState;
};

export const selectorGetVolunteerActiveStatus = (store: Store): string | undefined => {
  return store.userRedux.userData?.volunteerActiveStatus;
};
