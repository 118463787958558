// react
import {FC} from 'react';
import {useDispatch} from 'react-redux';
import {setTabFilter} from 'redux/filter-service/actions';
//formik
import {Formik} from 'formik';
//redux
import {resetOpportunities} from 'redux/opportunities-service/action';
//context
import {useOpportunitiesContext} from 'pages/Organization/Opportunities/context';
//functions
import {removeEmptyProps} from 'core/functions';
//constants
import {INIT_OPPORTUNITIES_FILTERS_VALUES} from 'core/constants';
//components
import OpportunitiesFiltersForm from './OpportunitiesFiltersForm';
// validation
import {DateRangeFiltersValidationSchema} from 'core/validation';

const OpportunitiesFilters: FC = () => {
  const dispatch = useDispatch();
  const {QueryPreset, activeTab} = useOpportunitiesContext();

  /**
   *
   * TODO: need to add the correct typing so that there is no any !!!!
   */
  const handleSubmit = (values?: any): void => {
    dispatch(resetOpportunities());
    const requestBody = {...QueryPreset?.get(activeTab), ...removeEmptyProps(values)};
    if (activeTab) dispatch(setTabFilter(requestBody));
  };

  return (
    <Formik
      enableReinitialize
      onSubmit={handleSubmit}
      validationSchema={DateRangeFiltersValidationSchema}
      initialValues={INIT_OPPORTUNITIES_FILTERS_VALUES}
    >
      <OpportunitiesFiltersForm onSubmit={handleSubmit} />
    </Formik>
  );
};

export default OpportunitiesFilters;
