import {FC} from 'react';
import {useTranslation} from 'react-i18next';
//formik
import {Field, Form} from 'formik';
//components
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
import InputPhone from 'shared/components/FormInputs/InputPhone';
import {DatePicker} from 'shared/components/Pickers/NewUI/DatePicker';
import GoogleApiAutoCompleat from 'shared/components/GoogleApiAutoComplete';
import UploadPhoto from 'components/Volunteer/UploadPhoto';
import GenderSelect from 'shared/components/SelectDropdown/GenderSelect';
import Input from 'shared/inputs/Input';
//styles
import styles from './YouSignupForm.module.scss';

const YouSignupForm: FC = () => {
  const {t} = useTranslation(['signUpUser', 'inputs', 'buttons', 'form']);

  return (
    <Form className={styles.form}>
      <Field name="imagePath" parentClassName={styles.upload_photo} component={UploadPhoto} />
      <Field
        name="firstName"
        label={t('signUpUser:firstName')}
        placeholder={t('inputs:placeholders.firstName')}
        component={Input}
      />
      <Field
        name="lastName"
        label={t('signUpUser:lastName')}
        placeholder={t('inputs:placeholders.lastName')}
        component={Input}
      />
      <Field
        name="birthDate"
        label={t('signUpUser:dob')}
        placeholder={t('inputs:placeholders.dob')}
        parentClassName={styles.birthDate}
        disableFuture
        availablePast
        component={DatePicker}
      />
      <Field
        name="email"
        label={t('inputs:placeholders.email')}
        placeholder={t('inputs:placeholders.email')}
        component={Input}
      />
      <Field name="phoneNumber" label={t('form:phoneNumber')} component={InputPhone} />
      <Field
        name="gender"
        label={t('inputs:placeholders.gender')}
        placeholder={t('inputs:placeholders.gender')}
        parentClassName={styles.gender}
        component={GenderSelect}
      />
      <Field
        name="address"
        label={t('form:address')}
        placeholder={t('inputs:placeholders.enterAddress')}
        isAddressErrorText
        isInputValidation
        component={GoogleApiAutoCompleat}
      />
      <ButtonDefault submitType classList={['primary', 'lg']} title={t('buttons:button.next')} />
    </Form>
  );
};

export default YouSignupForm;
