import {Dispatch} from 'redux';
import {API} from '../../core/API';
import {IStatusRequest, StatusRequest, UpdateStatusRequest} from '@joc/api-gateway';
import {GET_STATUS_ALL, CREATE_STATUS, DELETE_STATUS, LOADING_STATUS, EDIT_STATUS, SET_ERROR} from '../actions-types';

export const getStatuses =
  (organizationId?: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const statusResponse = await API.getAllStatusesByOrganisation(organizationId);
      dispatch({
        type: GET_STATUS_ALL,
        payload: {records: statusResponse, total: statusResponse.length},
      });
      dispatch({
        type: LOADING_STATUS,
        payload: false,
      });
    } catch (error: any) {
      dispatch({type: SET_ERROR, payload: {state: true, message: error?.response?.message || error.message}});
    }
  };

export const createStatus =
  ({organizationId, status}: {organizationId?: string; status: IStatusRequest}) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const statusResponse = await API.createStatus(organizationId, StatusRequest.fromJS(status));

      dispatch({type: CREATE_STATUS, payload: statusResponse});
      dispatch({
        type: LOADING_STATUS,
        payload: false,
      });
    } catch (error: any) {
      throw error;
    }
  };

export const editStatus =
  ({id, status}: {id: number; status: IStatusRequest}) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const statusResponse = await API.updateStatus(id, undefined, UpdateStatusRequest.fromJS(status));
      //
      dispatch({type: EDIT_STATUS, payload: statusResponse});
      dispatch({
        type: LOADING_STATUS,
        payload: false,
      });
    } catch (error: any) {
      throw error;
    }
  };

export const deleteStatus =
  ({id, organizationId}: {id: number; organizationId?: string}) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      await API.deleteStatus(id, organizationId);

      dispatch({type: DELETE_STATUS, payload: id});
      dispatch({
        type: LOADING_STATUS,
        payload: false,
      });
    } catch (error: any) {
      throw error;
    }
  };

export const setLoadingStatus =
  (isLoading: boolean) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch({
      type: LOADING_STATUS,
      payload: isLoading,
    });
  };
