import {FC, Fragment} from 'react';
import cx from 'classnames';
import {useTranslation} from 'react-i18next';
//components
import ArrowBackLink from 'shared/components/ArrowBackLink';
import AboutOrgSignupForm from 'components/Organization/Forms/AboutOrgSignupForm';
import SignUpContentContainer from '../SignUpContentContainer';
//styles
import styles from './AboutOrgSignup.module.scss';

const AboutOrgSignup: FC = () => {
  const {t} = useTranslation('login');

  return (
    <Fragment>
      <ArrowBackLink onboardingStyle />
      <SignUpContentContainer title={t('welcome')} subTitle={t('subtitle')}>
        <div className={styles.container}>
          <div className={styles.title__wrapper}>
            <span className={cx(styles.title, 'title')}>{t('subtitle2')}</span>
            <span className={styles.sub_title}>{t('subtitle3')}</span>
          </div>
          <AboutOrgSignupForm />
        </div>
      </SignUpContentContainer>
    </Fragment>
  );
};

export default AboutOrgSignup;
