import {INITIAL_VOLUNTEERS_FILTERS_VALUES} from 'core/constants';
import {Moment} from 'moment';
import qs from 'qs';

export const deserializeQueryParamsVolunteersFilters = (queryParams: qs.ParsedQs) => {
  const createdDate = queryParams.createdDate as Record<string, string>;
  console.log('createdDate__deserializeQueryParamsVolunteersFilters', createdDate);
  const fromDate = createdDate?.fromDate;
  const toDate = createdDate?.toDate;

  const requestBody = {
    minAge:
      Object.keys(queryParams).includes('ages') &&
      !!queryParams.ages?.length &&
      ((queryParams.ages as Array<any>)[0].min as string)
        ? +((queryParams.ages as Array<any>)[0].min as string)
        : INITIAL_VOLUNTEERS_FILTERS_VALUES.minAge,

    maxAge:
      Object.keys(queryParams).includes('ages') &&
      !!queryParams.ages?.length &&
      ((queryParams.ages as Array<any>)[0].max as string)
        ? +((queryParams.ages as Array<any>)[0].max as string)
        : INITIAL_VOLUNTEERS_FILTERS_VALUES.maxAge,

    genderId: (queryParams.genderId as string)
      ? +(queryParams.genderId as string)
      : INITIAL_VOLUNTEERS_FILTERS_VALUES.genderId,

    howFar: (queryParams.howFar as string) ? +(queryParams.howFar as string) : INITIAL_VOLUNTEERS_FILTERS_VALUES.howFar,

    fromDate: (fromDate as unknown as Moment) || INITIAL_VOLUNTEERS_FILTERS_VALUES.fromDate,
    toDate: (toDate as unknown as Moment) || INITIAL_VOLUNTEERS_FILTERS_VALUES.toDate,
  };

  return requestBody;
};
