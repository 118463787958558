import {FC, useEffect, useState} from 'react';
import {connect, ConnectedProps} from 'react-redux';
//types
import {VOLUNTEER_ACTIVE_STATUS} from '@joc/api-gateway';
//redux
import {Store} from 'redux/root';
import {selectorGetUserVolunteerStatus} from 'redux/user-service/selector';
//urls
import {urls} from 'core/appUrls';
//translation
import {useTranslation} from 'react-i18next';
//images
import Logout from 'assets/image/logout.svg';
import UserSvg from 'assets/image/user-circle.svg';
import ChatSvg from 'assets/image/chat2.svg';
import HeartSvg from 'assets/image/heart.svg';
import MailSvg from 'assets/image/mail.svg';
//components
import HeaderWrapper from 'shared/components/HeaderWrapper';
import AccountMenuDropDown from 'shared/components/AccountMenuDropDown';
import AccountMenuDropdownItem from 'shared/components/AccountMenuDropDown/AccountMenuDropdownItem';
import HeaderNav from './HeaderNav';

const HeaderVolunteer: FC<HeaderVolunteerProps> = ({volunteerStatus}: HeaderVolunteerProps) => {
  const [isGuest, setIsGuest] = useState(false);

  const {t} = useTranslation('header');

  useEffect(() => {
    const localStorageAccessToken = localStorage.getItem('accessToken');
    if (!localStorageAccessToken) setIsGuest(true);
  }, []);

  useEffect(() => {
    const localStorageAccessToken = localStorage.getItem('accessToken');
    if (!localStorageAccessToken) setIsGuest(true);
  }, []);

  const isVolunteerSuspended = volunteerStatus === VOLUNTEER_ACTIVE_STATUS.SUSPENDED;

  const dropdown = (
    <AccountMenuDropDown volunteers>
      <AccountMenuDropdownItem
        link={urls.volInbox}
        text={t('volunteer.chat')}
        img={ChatSvg}
        disabled={isVolunteerSuspended}
      />
      <AccountMenuDropdownItem link={urls.volEditAccount} text={t('volunteer.editAcc')} img={UserSvg} />
      <AccountMenuDropdownItem link={urls.volAbout} text={t('volunteer.about')} img={HeartSvg} />
      <AccountMenuDropdownItem link={urls.volManualOpportunity} text={t('volunteer.proposeManual')} img={UserSvg} />
      <AccountMenuDropdownItem link={urls.volGetInTouch} text={t('volunteer.getInTouch')} img={MailSvg} />
      <AccountMenuDropdownItem logout text={t('volunteer.logout')} img={Logout} />
    </AccountMenuDropDown>
  );

  return (
    <HeaderWrapper chatLink={urls.volInbox} dropdown={dropdown} isGuest={isGuest}>
      <HeaderNav />
    </HeaderWrapper>
  );
};

const mapStateToProps = (store: Store) => ({
  volunteerStatus: selectorGetUserVolunteerStatus(store),
});

const connector = connect(mapStateToProps);

type HeaderVolunteerProps = ConnectedProps<typeof connector>;

export default connector(HeaderVolunteer);
