import {FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import cx from 'classnames';
//API
import {API} from 'core/API';
//types
import {CreatePaymentRequest, ITariffsResponse} from '@joc/api-gateway';
//helpers
import {getPaymentData, getTariffAmount} from './helpers';
//components
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
import TariffPlanContent from './Renderers';
//styles
import styles from './TariffPlansItem.module.scss';

type TariffPlansItemParentProps = {
  tariff: ITariffsResponse;
};

const TariffPlansItem: FC<TariffPlansItemParentProps> = ({tariff}) => {
  const [defCountOfStudents, setDefCountOfStudents] = useState('50');
  const [orgId, setOrgId] = useState('');
  const [isSchool, setIsSchool] = useState(false);

  const {t} = useTranslation('tariffPlans');

  useEffect(() => {
    const id = localStorage.getItem('organisationId');
    const school = localStorage.getItem('school');

    if (id) setOrgId(id);
    if (school) setIsSchool(true);
  }, []);

  const purchaseClickHandler = async () => {
    try {
      const {tariffCost, id} = tariff;

      const amount = getTariffAmount(isSchool, tariffCost, defCountOfStudents);

      const paymentData = getPaymentData(amount, orgId, id);

      const paymentUrl = await API.createPayment(CreatePaymentRequest.fromJS(paymentData));

      window.location.href = `${paymentUrl}`;
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className={cx(styles.content)}>
      <TariffPlanContent
        tariff={tariff}
        defCountOfStudents={defCountOfStudents}
        setDefCountOfStudents={setDefCountOfStudents}
      />

      <ButtonDefault title={t('purchase')} clickHandler={purchaseClickHandler} classList="primary center" />
    </div>
  );
};

export default TariffPlansItem;
