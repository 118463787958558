//types
import {GET_VOLUNTEERS_ALL, GET_VOLUNTEERS_BY_GROUP, RESET_VOLUNTEERS} from 'redux/actions-types';
import {IVolunteerReducer, VolunteerActionTypes, VolunteerPayload} from './types';

const initState: IVolunteerReducer = {
  volunteers: {records: [], total: 0},
  volunteersAllTotal: 0,
};

const volunteersReducer = (state = initState, action: VolunteerActionTypes): IVolunteerReducer => {
  const {type, payload}: VolunteerActionTypes = action;

  switch (type) {
    case GET_VOLUNTEERS_ALL:
      return {
        ...state,
        volunteers: {
          records: [...state.volunteers.records, ...(payload as VolunteerPayload).records],
          total: (payload as VolunteerPayload).total,
        },
      };
    case GET_VOLUNTEERS_BY_GROUP:
      return {
        ...state,
        volunteers: {
          records: [...state.volunteers.records, ...(payload as VolunteerPayload).records],
          total: (payload as VolunteerPayload).total,
        },
      };

    case RESET_VOLUNTEERS: {
      return initState;
    }

    default:
      return state;
  }
};

export default volunteersReducer;
