import {FC, useEffect, useRef, useState} from 'react';
import moment, {Moment} from 'moment';

// new material-ui
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {dateTimePickerToolbarClasses, timePickerToolbarClasses} from '@mui/x-date-pickers';

// components
import FieldWrapper from 'shared/inputs/FieldWrapper';

// hooks
import {useClickOutside} from 'hooks/useClickOutside';

// types
import {DateTimePickerProps} from './types';

// styled
import {DateTimePickerStyled} from './styles';

export const DateTimePicker: FC<DateTimePickerProps> = ({availableFuture, availablePast, ...fieldBaseProps}) => {
  const [value, setValue] = useState<Moment | null>(null);
  const [isHighlight, setIsHighlight] = useState(false);

  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const handleClosePicker = () => setIsPickerOpen(false);
  const popperRef = useClickOutside<HTMLDivElement>(handleClosePicker);
  const inputRef = useRef<HTMLImageElement>();

  const {field, form, disabled} = fieldBaseProps;
  const {setFieldValue, setFieldTouched} = form;

  const onChange = (value: Moment | null) => {
    setFieldValue(field.name, value);
    setIsHighlight(true);
  };

  const onTouchHandler = () => {
    setFieldTouched(field.name, true);
  };

  useEffect(() => {
    setIsHighlight(!!field?.value);
    setValue(moment(field.value));
  }, [field.value]);

  return (
    <FieldWrapper {...fieldBaseProps}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DateTimePickerStyled
          disabled={disabled}
          value={value}
          className={isHighlight ? 'selected' : ''}
          disablePast={!availablePast}
          disableFuture={!availableFuture}
          onChange={onChange}
          onSelectedSectionsChange={onTouchHandler}
          timeSteps={{hours: 1, minutes: 1}}
          selectedSections={null}
          open={isPickerOpen}
          onAccept={handleClosePicker}
          views={['month', 'year', 'day', 'hours', 'minutes']}
          slotProps={{
            textField: {
              inputRef,
              onClick: () => {
                inputRef.current?.blur();
                setIsPickerOpen(true);
              },
              sx: {
                '.MuiOutlinedInput-input': {
                  color: isHighlight ? '#1C2F4C !important' : '#5B768B !important',
                },
              },
            },
            popper: {
              ref: popperRef,

              sx: {
                '.MuiPaper-root': {
                  borderRadius: '10px',
                },
              },
            },
            layout: {
              sx: {
                '& .MuiPickersLayout-contentWrapper': {
                  'button, li': {
                    '&.Mui-selected': {
                      backgroundColor: '#4B5FF6 !important',

                      '&:hover': {
                        backgroundColor: '#4B5FF6',
                      },
                    },
                  },
                },
              },
            },
            actionBar: {
              sx: {
                button: {
                  color: '#4B5FF6',
                },
              },
            },
            toolbar: {
              hidden: false,
              sx: {
                [`&.${dateTimePickerToolbarClasses.root} .MuiPickersToolbar-content .Mui-selected`]: {
                  color: '#4B5FF6',
                },
              },
            },
          }}
        />
      </LocalizationProvider>
    </FieldWrapper>
  );
};
