import {FC} from 'react';
import cx from 'classnames';
//images
import SuccessSvg from 'assets/image/Success.svg';
//styles
import styles from './ButtonActionCircle.module.scss';

type ButtonConfirmVolunteerProps = {
  clickHandler: () => void;
  disabled?: boolean;
  title?: string;
  larger?: boolean;
};

const ButtonConfirmCircle: FC<ButtonConfirmVolunteerProps> = ({
  clickHandler,
  disabled,
  title,
  larger,
}: ButtonConfirmVolunteerProps) => {
  return (
    <button className={!larger ? styles.main : styles.larger} disabled={disabled} onClick={clickHandler} title={title}>
      <img className={cx(styles.success, disabled && styles.success_disabled)} src={SuccessSvg} alt="success" />
    </button>
  );
};

export default ButtonConfirmCircle;
