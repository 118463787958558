import * as Yup from 'yup';
//types
import {IBasicVolunteerResponse, IGetLocationIdByAddressRequest, IUpdateVolunteerRequest} from '@joc/api-gateway';
//validation
import {
  addressSchema,
  birthNotRequiredDateSchema,
  firstNameSchema,
  genderNotRequiredSchema,
  gradeSchema,
  lastNameSchema,
  passwordSchema,
  phoneNotRequiredNumberSchema,
  studentIdSchema,
} from 'core/validation';

export type EditVolunteerFormValues = Required<
  Pick<IUpdateVolunteerRequest, 'firstName' | 'lastName' | 'phoneNumber' | 'gradeId'>
> & {
  birthDate: Date | null;
  address: IGetLocationIdByAddressRequest | null;
  gender: number | null;
  password: string;
  studentId: string | null;
};

export const getInitialValues = (volunteer: IBasicVolunteerResponse, schoolId?: number): EditVolunteerFormValues => {
  return {
    firstName: volunteer.firstName || '',
    lastName: volunteer.lastName || '',
    phoneNumber: volunteer.phoneNumber || '',
    birthDate: volunteer.birthDate || null,
    gender: volunteer.gender?.id || null,
    address: volunteer.address || null,
    gradeId:
      volunteer.volunteerSchoolsGrades?.find((grade) => String(grade.schoolId) === String(schoolId))?.gradeId || '',
    password: '',
    studentId: volunteer.studentId || null,
  };
};

export const validationSchema = Yup.object().shape({
  firstName: firstNameSchema,
  lastName: lastNameSchema,
  phoneNumber: phoneNotRequiredNumberSchema,
  birthDate: birthNotRequiredDateSchema,
  gender: genderNotRequiredSchema,
  address: addressSchema,
  gradeId: gradeSchema.notRequired(),
  password: passwordSchema.notRequired(),
  studentId: studentIdSchema.notRequired(),
});
