import {FC, Fragment} from 'react';
import cx from 'classnames';
//formik
import {FormikErrors} from 'formik';
//types
import {IGetLocationIdByAddressRequest} from '@joc/api-gateway';
//styles
import styles from './InputErrorHint.module.scss';

type InputErrorHintPropsType = {
  errorText?: string | Array<string> | any;
  addressErrorText?: FormikErrors<IGetLocationIdByAddressRequest | string>;
  parentClassName?: string;
};

type AddressErrors = {
  [key: string]: string;
};

const InputErrorHint: FC<InputErrorHintPropsType> = ({errorText, addressErrorText, parentClassName}) => {
  if (addressErrorText && typeof addressErrorText !== 'string')
    return (
      <div className={styles.error_address}>
        {Object.keys(addressErrorText).map((i: string) => (
          <span key={i} className={styles.main}>
            {(addressErrorText as AddressErrors)[i]}
          </span>
        ))}
      </div>
    );

  if (Array.isArray(errorText))
    return (
      <Fragment>
        {errorText.map((i) => (
          <span key={i} className={styles.main}>
            {i}
          </span>
        ))}
      </Fragment>
    );

  return <span className={cx(styles.main, parentClassName)}>{errorText || addressErrorText}</span>;
};

export default InputErrorHint;
