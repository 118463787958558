import {FC, ReactNode} from 'react';
//styles
import styles from './SidebarContainer.module.scss';

type SidebarContainerProps = {
  children: ReactNode;
};

const SidebarContainer: FC<SidebarContainerProps> = ({children}: SidebarContainerProps) => {
  return <div className={styles.main}>{children}</div>;
};

export default SidebarContainer;
