import {FC, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useFormikContext} from 'formik';
//from configuration
import {RepeatOpportunityModalFormValues, getCurrentWeekDay} from '../../formConfiguration';
//types
import {WHICH_DAY_FILTER_FORMAT, WHICH_DAY_FILTER_TYPE} from 'core/types';
//helpers
import {daysDimension} from './helpers';
//components
import WhichDaysFilter from 'shared/components/FiltersFields/WichDaysFilter';
//styles
import styles from './RepeatsOn.module.scss';

const RepeatsOn: FC = () => {
  const {
    setFieldValue,
    values: {weekDays},
  } = useFormikContext<RepeatOpportunityModalFormValues>();
  const {t} = useTranslation('form');

  useEffect(() => {
    if (!weekDays?.length) {
      setFieldValue('weekDays', [getCurrentWeekDay()]);
    }
  }, [weekDays]);

  return (
    <div className={styles.wrapper}>
      <label>{t('opportunity.repeatsOn')}</label>
      <WhichDaysFilter
        withoutLabel
        fieldValues={weekDays}
        dimension={daysDimension}
        setFieldValue={setFieldValue}
        type={WHICH_DAY_FILTER_TYPE.BLUE}
        parentClassName={styles.weeksFilter}
        format={WHICH_DAY_FILTER_FORMAT.ONE_LETTER}
      />
    </div>
  );
};

export default RepeatsOn;
