export type FirebaseLoginError<T extends string = string> = {
  a?: null;
  message?: string;
  code?: T;
};

export enum FIREBASE_LOGIN_ERROR_CODE {
  WRONG_PASSWORD = 'auth/wrong-password',
  USER_NOT_FOUND = 'auth/user-not-found',
  TOO_MANY_REQUESTS = 'auth/too-many-requests',
  POPUP_CLOSED_BY_USER = 'auth/popup-closed-by-user',
  ACCOUNT_EXISTS_WITH_DIFFERENT_CREDENTIAL = 'auth/account-exists-with-different-credential',
}
