import {FC, ReactNode} from 'react';
//components
import AppealSignupRenderer from 'shared/components/Appeal/SignupRenderer';
//styles
import styles from './SignUpContentContainer.module.scss';

type SignUpContentContainerPropsType = {
  children: ReactNode;
  colleaguesStyle?: boolean;
  subTitle: string;
  title: string;
  congratsStyle?: boolean;
};

const SignUpContentContainer: FC<SignUpContentContainerPropsType> = ({
  children,
  colleaguesStyle,
  subTitle,
  title,
}: SignUpContentContainerPropsType) => {
  return (
    <div className={styles.wrapper}>
      <AppealSignupRenderer
        parentClassName={styles.wrapper_left}
        signupColleaguesStyle={colleaguesStyle && colleaguesStyle}
        subTitle={subTitle}
        title={title}
      />
      <div className={styles.wrapper_right}>{children}</div>
    </div>
  );
};

export default SignUpContentContainer;
