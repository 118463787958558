import {FC} from 'react';
import cx from 'classnames';
import {useTranslation} from 'react-i18next';
import {connect, ConnectedProps} from 'react-redux';
//redux
import {Store} from 'redux/root';
import {selectorGetChatUnreadMessagesCount} from 'redux/chat-service/selector';
//functions
import {getDisplayedUnreadMessagesCount} from 'core/functions';
//images
import {ReactComponent as ChatSvg} from 'assets/image/chat.svg';
//styles
import styles from './index.module.scss';

const ChatInfo: FC<ChatInfoProps> = ({count, isSuspended}) => {
  const {t} = useTranslation(['chat', 'messages']);

  return (
    <div
      className={cx(styles.chatInfo, {[styles.onsuspend]: isSuspended})}
      title={t(!isSuspended ? 'chat:organizationInbox' : 'messages:youOrYourOrgSuspended')}
    >
      <ChatSvg className={styles.chatInfo__image} />
      {!!count && <div className={styles.chatInfo__counter}>{getDisplayedUnreadMessagesCount(count)}</div>}
    </div>
  );
};

const mapStateToProps = (store: Store) => ({
  count: selectorGetChatUnreadMessagesCount(store),
});

const connector = connect(mapStateToProps);

type ChatInfoProps = ConnectedProps<typeof connector> & {isSuspended?: boolean};

export default connector(ChatInfo);
