export const CommonFieldStyles = {
  '&.selected': {
    '.MuiOutlinedInput-input': {
      height: 'unset',
      color: '#1C2F4C',
    },
  },
  '.MuiOutlinedInput-root': {
    height: 40,
    border: '1px solid #c6ccfe',
    borderRadius: 30,
    fontSize: 18,
  },
  '.MuiOutlinedInput-input': {
    height: 'unset',
    color: '#5b768b',
    paddingLeft: 24,
  },
  '.MuiTouchRipple-root': {
    display: 'none',
  },
  '.MuiInputAdornment-root': {
    right: 16,
    marginLeft: 'unset',
  },
  '.MuiOutlinedInput-notchedOutline': {
    top: 0,
    border: 'none',
  },
  '.Mui-disabled': {
    pointerEvents: 'none',

    input: {
      color: '#00000061',
    },
  },
};
