import {CREATE_STATUS, DELETE_STATUS, GET_STATUS_ALL, EDIT_STATUS, LOADING_STATUS} from '../actions-types';
import {StatusActionTypes, IStatusReducer, IStatusPayload} from './types';

const initState: IStatusReducer = {
  statusRecords: [],
  statusTotal: 0,
  isLoading: false,
};

const statusReducer = (state = initState, action: StatusActionTypes): IStatusReducer => {
  const {type, payload}: StatusActionTypes = action;

  switch (type) {
    case GET_STATUS_ALL: {
      return {
        ...state,
        statusRecords: (payload as IStatusPayload).records,
        statusTotal: (payload as IStatusPayload).total,
      };
    }

    case CREATE_STATUS: {
      return {
        ...state,
        statusRecords: [...state.statusRecords, payload],
        statusTotal: state.statusTotal + 1,
      };
    }

    case EDIT_STATUS: {
      return {
        ...state,
        statusRecords: state.statusRecords.map((challenge) => (challenge.id !== payload.id ? challenge : payload)),
      };
    }

    case DELETE_STATUS: {
      return {
        ...state,
        statusRecords: state.statusRecords.filter((challenge) => challenge.id !== payload),
        statusTotal: state.statusTotal - 1,
      };
    }

    case LOADING_STATUS: {
      return {
        ...state,
        isLoading: payload,
      };
    }

    default:
      return state;
  }
};

export default statusReducer;
