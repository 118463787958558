import {Dispatch, FC, SetStateAction, useEffect, useState} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {useTranslation} from 'react-i18next';
//redux
import {Store} from 'redux/root';
import {selectorGetOrgVideos} from 'redux/organization-service/selector';
import {getOrgVideos} from 'redux/organization-service/actions';
import {resetError, setError} from 'redux/error-service/action';
import {selectorGetError} from 'redux/error-service/selector';
//components
import Loader from 'shared/components/Loader';
import ResponseFailure from 'shared/components/ResponseFailure';
import MarketingVideoPreview from '../MarketingVideoPreview';
//styles
import styles from './index.module.scss';

type MarketingTableParentProps = {
  setIsShowPopup: Dispatch<SetStateAction<boolean>>;
};

const MarketingListVideo: FC<MarketingTableProps> = ({
  orgVideos,
  getOrgVideos,
  setIsShowPopup,
  setError,
  resetError,
  error,
}: MarketingTableProps) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let unmounted = false;
    getOrgVideosRequest(unmounted);

    return () => {
      unmounted = true;
    };
  }, []);

  const {t} = useTranslation(['messages', 'buttons']);

  const getOrgVideosRequest = async (unmounted?: boolean) => {
    if (!unmounted) setIsLoading(true);
    try {
      await getOrgVideos();
      if (!unmounted) setIsLoading(false);
    } catch (error) {
      setError(error?.response?.message || error.message, error.code);
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      {error.state ? (
        <ResponseFailure
          styleTable
          message={error.message}
          buttonClickHandler={() => {
            resetError();
            getOrgVideosRequest();
          }}
        />
      ) : isLoading ? (
        <Loader loadProps={{stylePageCenter: true}} />
      ) : !orgVideos.length && !isLoading ? (
        <ResponseFailure
          styleTable
          message={t('messages:orgDoesntHaveVideos')}
          buttonTitle={t('buttons:button.uploadNow')}
          buttonClickHandler={() => setIsShowPopup(true)}
        />
      ) : (
        <div className={styles.marketing_grid}>
          {orgVideos.map((el) => (
            <MarketingVideoPreview key={el.id} videoData={el} />
          ))}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (store: Store) => ({
  orgVideos: selectorGetOrgVideos(store),
  error: selectorGetError(store),
});

const mapDispatchToProps = {
  getOrgVideos,
  setError,
  resetError,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type MarketingTableProps = ConnectedProps<typeof connector> & MarketingTableParentProps;

export default connector(MarketingListVideo);
