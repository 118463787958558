import {FC, ReactNode, useState} from 'react';
import cx from 'classnames';
//components
import FiltersHeader from '../FiltersHeader';
//styles
import styles from './FiltersContainer.module.scss';

type FiltersContainerProps = {
  children: ReactNode;
  resetForm: () => void;
  isVisible?: boolean;
  disablePaddingSide?: boolean;
  toggleShow?: boolean;
};

const FiltersContainer: FC<FiltersContainerProps> = ({
  children,
  resetForm,
  isVisible,
  disablePaddingSide,
  toggleShow,
}) => {
  const [visibilityFilters, setVisibilityFilters] = useState({isClose: true, isShowFilter: !!isVisible});

  const changeVisibilityFilters = () => {
    setVisibilityFilters({...visibilityFilters, isClose: !visibilityFilters.isClose});
    setTimeout(() => setVisibilityFilters({...visibilityFilters, isShowFilter: !visibilityFilters.isShowFilter}), 200);
  };

  return (
    <div className={cx(styles.main, visibilityFilters.isClose && styles.close)}>
      <div className={styles.content}>
        <FiltersHeader
          isShowFilters={visibilityFilters.isShowFilter}
          changeVisibilityFilters={changeVisibilityFilters}
          resetForm={resetForm}
          toggleShow={toggleShow}
        />
        {visibilityFilters.isShowFilter && (
          <div className={cx(styles.content__filters, disablePaddingSide && styles.disable_padding_side)}>
            {children}
          </div>
        )}
      </div>
    </div>
  );
};

export default FiltersContainer;
