import {BaseSyntheticEvent, FC, ReactNode} from 'react';
import cx from 'classnames';
//types
import {BUTTON_CROSS_COLOR} from 'core/types';
//components
import ButtonDots from '../Buttons/ButtonDots';
import ButtonCross from '../Buttons/ButtonCross';
//styles
import styles from './PopupSmallWhite.module.scss';

type PopupSmallWhiteProps = {
  children: ReactNode;
  isShowPopup: boolean;
  popupClickHandler: (e?: BaseSyntheticEvent) => void;
  withoutOverlay?: boolean;
  withButton?: boolean;
  parentClassName?: string;
  className?: string;
  crossButtonParentClassName?: string;
};

const PopupSmallWhite: FC<PopupSmallWhiteProps> = ({
  children,
  isShowPopup,
  className,
  parentClassName,
  withButton,
  withoutOverlay = false,
  crossButtonParentClassName,
  popupClickHandler,
}) => {
  return (
    <div className={className}>
      {withButton && <ButtonDots clickHandler={popupClickHandler} />}

      {isShowPopup && (
        <div className={(cx({[styles.crossButton__wrapper]: withoutOverlay}), crossButtonParentClassName)}>
          {!withoutOverlay ? (
            <div className={styles.overlay} onClick={popupClickHandler} />
          ) : (
            <ButtonCross
              colorImage={BUTTON_CROSS_COLOR.BLACK}
              clickHandler={popupClickHandler}
              parentClassName={styles.crossButton}
            />
          )}

          <div className={cx(styles.popup, parentClassName && parentClassName)}>{children}</div>
        </div>
      )}
    </div>
  );
};

export default PopupSmallWhite;
