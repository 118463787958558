//styles
import {FC} from 'react';
import styles from './index.module.scss';

interface IClockActionHelperTextProps {
  text: string;
}

export const ClockActionHelperText: FC<IClockActionHelperTextProps> = ({text}) => {
  return (
    <div className={styles.helper__wrapper}>
      <p className={styles.helper__wrapper_text}>{text}</p>
    </div>
  );
};
