import React, {FC, useEffect} from 'react';
import Loader from 'shared/components/Loader';
// import styles from './Landing.module.scss';

const Landing: FC = () => {
  useEffect(() => {
    window.location.href = 'https://justonechesed.org/chesed-works/';
  }, []);

  return <Loader />;
};

export default Landing;
