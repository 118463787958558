import {FC} from 'react';
//styles
import styles from './PolicyInfo.module.scss';

const PolicyInfo: FC = () => (
  <div className={styles.policy}>
    <div className={styles.article__text}>
      Our Site is dedicated to helping donors, volunteers, and nonprofit groups interact in a safe, fun, and effective
      online environment. We collect very basic information about you in order to enhance your giving experience. Our
      Privacy Policy noted below works in conjunction with our{' '}
      <a href="https://info.givegab.com/givegabs-terms-and-conditions">Terms and Conditions</a>.
    </div>
    {/* ///////////////////////////// */}
    <h2 className={styles.title}>Personal and Non-Personal Information Collected</h2>
    <div className={styles.article__text}>
      Personal Information means any information that identifies or can be used to identify you directly or indirectly,
      including, but not limited to, first and last name, date of birth, email address, gender, occupation or other
      demographic information. We collect personal information in the following circumstances:
      <ol>
        <li>
          <b>When you register to become a Just One Chesed user (“User”):</b> Your name, e-mail, phone number, and a
          user created password.
        </li>
        <li>
          <b>When you build your profile:</b> Optional information provided by a user such as your skills, interests,
          friends, general location, and photo. Your information is made viewable to local nonprofit groups and other
          Users who are seeking people interested in helping and making a difference in your local communities.
        </li>
        <li>
          <b>When you volunteer time:</b> Number of hours volunteered, what nonprofits and volunteer communities you
          belong to, and what volunteering events you are attending.
        </li>
        <li>
          <b>When you donate to an organization:</b> the amount of your donation, whether you want it to remain
          anonymous to the public that you gave to that organization, your email (if you aren&apos;t already a User),
          and your credit card information for transactional purposes (see Security section below for more information).
        </li>
      </ol>
    </div>
    <div className={styles.article__text}>
      Non-personal information means information including, but not limited to, your browser type, the URL of previous
      web pages you visited, your operating system, and Internet protocol (IP) address. The non-personal information is
      not used by us to personally identify you and is not readily usable for that purpose. We may use analytic and
      reporting technologies to record non-personal information to assist you during support interactions or to gain
      knowledge and best develop our product to meet our customer&apos;s needs.
    </div>
    {/* ///////////////////////////// */}
    <h2 className={styles.title}>Uses of Personal Information</h2>
    <div className={styles.article__text}>We use the information you provide to:</div>
    <ol>
      <li>Enable you to share your information and communicate with other Users.</li>
      <li>Administer your account and customize the service we provide to you and other Users.</li>
      <li>Send you service or promotional communications through email and notices on the Just One Chesed website.</li>
      <li>Connect you to opportunities by enabling nonprofits and other users to find you on Just One Chesed.</li>
    </ol>
    <div className={styles.article__text}>
      Our general policy is to not sell, rent, or otherwise provide personally identifiable information to third parties
      without your consent, except under the following limited conditions:
    </div>
    <ol style={{listStyleType: 'none'}}>
      <li>(a) We have your consent to share the information;</li>
      <li>
        (b) We need to share your information to provide the product or service you have requested or contractually
        agreed to;
      </li>
      <li>
        (c) We need to transmit the information to third parties who work on behalf of Just One Chesed to provide a
        product or service. In this instance we will only provide those companies the information they need to deliver
        the product or service, and they are prohibited from using that information for any other purpose;
      </li>
      <li>
        (d) To protect and enforce our legal rights or enforce the legal rights of a third party, or as we in good faith
        believe we are required to do so by law (such as to comply with a subpoena or court order, for example).
      </li>
    </ol>
    {/* ///////////////////////////// */}
    <h2 className={styles.title}>Your Information Choices</h2>
    <div className={styles.article__text}>You can:</div>
    <ol>
      <li>Review, enhance or edit your personal information through your personal profile page.</li>
      <li>Change your settings to control visibility and accessibility through our website.</li>
      <li>Tell us to close your Just One Chesed account via Justonechesed.org.</li>
      <li>Opt out of email messaging using the &quot;opt out&quot; option in the email footer.</li>
    </ol>
    {/* ///////////////////////////// */}
    <h2 className={styles.title}>Your Obligations to Other Users</h2>
    <ol>
      <li>
        You shall not abuse the Just One Chesed service by using it to spam, abuse, harass, or otherwise violate the
        Just One Chesed Terms and Conditions or Privacy Policy.
      </li>
    </ol>
    {/* ///////////////////////////// */}
    <h2 className={styles.title}>FERPA</h2>
    <ol>
      <li>
        Many of our Users are schools, colleges, and universities (hereinafter “Schools”) that are subject to privacy
        requirements of the Family Educational Rights and Privacy Act (20 U.S.C. § 1232g; 34 CFR Part 99) (“FERPA”).
        FERPA is a Federal law that protects the privacy of student education records. The law applies to all
        educational agencies and institutions that receive funds under an applicable program of the U.S. Department of
        Education. FERPA gives parents certain rights with respect to their children&apos;s education records. These
        rights transfer to the student when he or she reaches the age of 18 or attends a school beyond the high school
        level. Students to whom the rights have transferred are &quot;eligible students.&quot;
      </li>
      <li>
        Just One Chesed is committed to providing a platform that allows these Schools to remain in compliance with
        FERPA by not disclosing any FERPA-protected information permissibly posted on our site by Schools. Schools are
        solely responsible for determining which information may be lawfully posted and for sending the information to
        post on Just One Chesed’s site privately so that only their school, college, or university may see their post
        without the consent of the student. Additionally, we record the affiliation with that School on the Just One
        Chesed platform such that the student becomes a User of Just One Chesed and a “member” of that School’s
        volunteer community. This information can be seen by any other users outside of that School’s volunteer
        community. Because the student will be a User on the Just One Chesed platform, the student may receive messages
        from other organizations or Users seeking volunteers. At such time, the student’s basic information, as
        described above, will be available to all other Just One Chesed Users. The student may at any time contact their
        School’s administrator and ask to have their privacy set to “Hidden” which will prevent such information from
        being displayed to all other Users on the Just One Chesed site.
      </li>
      <li>
        All Users, including volunteers, nonprofits, individuals and Schools must consent to having all information they
        enter onto our site to be shared with other users on our site. In the Just One Chesed registration process, the
        student logs on and at the time of registration, the student accepts the terms of use and becomes a standard
        Just One Chesed User, making their information available to all other Just One Chesed Users. At any time, the
        student may contact their School’s Just One Chesed administrator and have their full privacy flag set to
        “Hidden” which would protect their information from being seen by anyone on the Just One Chesed platform other
        than their School’s administrator. Just One Chesed does not accept any student information from Schools except
        first name, last name and email address. This information is generally considered “directory information” by
        most schools’ FERPA policies as the term is defined in FERPA. It is the sole responsibility of each School to
        either (a) not load this information on Just One Chesed’s site, or (b) set the student’s privacy flag to
        “HIDDEN” if the student or his or her parents have requested that directory information not be shared.
      </li>
      <li>
        By providing student directory information to Just One Chesed, Schools represent that they have received consent
        to share such directory information from either the student, if the student is over 18 years of age or otherwise
        considered an “eligible student,” or from the student’s parent or guardian as required by FERPA.
      </li>
    </ol>
    {/* ///////////////////////////// */}
    <h2 className={styles.title}>Children and Privacy</h2>
    <div className={styles.article__text}>
      The Just One Chesed Service is not intended for users under the age of thirteen (13). By signifying your
      acceptance of the Just One Chesed Terms and Conditions or making any use of the Just One Chesed Service, you
      represent and warrant that you are at least thirteen (13) years of age.
    </div>
    {/* ///////////////////////////// */}
    <h2 className={styles.title}>Cookies of Third-Party Providers</h2>
    <div className={styles.article__text}>
      <b>Cookies of third-party providers and tracking:</b> We may use cookies to improve your user experience, to help
      us understand how you are using our products and services, and to help us tailor our advertising. Find out more
      details here or make updates to your cookie choices here. Continued use of Just One Chesed represents your consent
      to do this. Just One Chesed sometimes uses services from other companies to optimize our websites and our
      services, as outlined below.
    </div>
    <ol>
      <li>
        <b>Google Analytics:</b> We often uses Google Analytics, a web analytics service that utilizes cookies (text
        files stored on your computer) to help analyze how our users are using Just One Chesed. This generated
        information (including your IP address) is transmitted to and stored by Google on servers in the United States.
        You can choose to stop the use of cookies by selecting the appropriate settings on your browser. However, please
        note that this may inhibit your use of some of the functions of this website. You can also prohibit Google from
        utilizing your cookie-generated data by installing this browser plugin:{' '}
        <a href="http://tools.google.com/dlpage/gaoptout?hl=en">http://tools.google.com/dlpage/gaoptout?hl=en</a>.
      </li>
      <li>
        <b>Facebook pixels:</b> Using Just One Chesed may involve data collection via the implementation of Facebook
        pixels. If you&apos;d like to opt out of having your data collected by Facebook pixel, you can do so here:{' '}
        <a href="https://www.facebook.com/settings?tab=ads">https://www.facebook.com/settings?tab=ads</a>
      </li>
      <li>
        Facebook guarantees compliance with the GDPR through its Privacy Shield Agreement certification:{' '}
        <a href="https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&status=Active">
          https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&status=Active
        </a>
      </li>
      <li>
        <b>Twitter Conversion Tracking:</b> Just One Chesed may use the Conversion Tracking service of Twitter Inc,
        which tracks records if a user of our website clicks on a link posted via Twitter. From this, both Just One
        Chesed and Twitter can see if a registration has taken place, with the identity of the user remaining anonymous.
        More information about Twitter’s privacy policy is available in their data privacy statement:{' '}
        <a href="https://twitter.com/privacy?lang=en">https://twitter.com/privacy?lang=en</a>. Twitter also supports “Do
        Not Track” (DNT), so if you have this activated in your browser, Twitter will not receive any browser-related
        information (browser cookie ID) from web partners.
      </li>
    </ol>
    {/* ///////////////////////////// */}
    <h2 className={styles.title}>Security</h2>
    <ol>
      <li>
        Credit card and/or bank account information collected is purely for transactional purposes and is stored
        securely within a fully PCI compliant third-party provider in a fully encrypted manner. No access to this
        information is available to the Just One Chesed team. We can only see that we have charged your card or bank
        account and that you are a customer of ours.
      </li>
      <li>
        Personal information you provide will be secured in accordance with industry standards and technology best
        practices. Since no method of transmission over the internet, or method of electronic storage, is a 100% secure
        environment, we cannot ensure or warrant the security of any information you transmit to Just One Chesed. There
        is no guarantee that information may not be accessed, copied, disclosed, altered, or destroyed by breach of any
        of our physical, technical, or managerial safeguards. We are not responsible for the unauthorized acts of others
        and we assume no liability for any disclosure of information due to errors in transmission, unauthorized third
        party access (such as through hacking) or other acts of third parties, or acts or omissions beyond our
        reasonable control.
      </li>
      <li>
        You are responsible for maintaining the secrecy of your unique password and account information, and for
        controlling access to your email communications at all times.
      </li>
    </ol>
    {/* ///////////////////////////// */}
    <h2 className={styles.title}>California Users of Just One Chesed:</h2>
    <div className={styles.article__text}>
      To learn more about the California Consumer Privacy Act and how it applies to you, please visit the{' '}
      <a href="https://oag.ca.gov/privacy/ccpa" className={styles.blue}>
        California Attorney General&apos;s website
      </a>
      .
    </div>
    <div className={styles.article__text}>
      <b>Right to know:</b> You have the right to request that we disclose the data we collect, use and disclose, and
      other information relating to data we collect about you.
    </div>
    <div className={styles.article__text}>
      <b>Right to delete:</b> You have the right to request the deletion of data that we have collected from you,
      subject to certain exceptions.
    </div>
    <div className={styles.article__text}>
      <b>Right to non-discrimination:</b> You have the right not to receive discriminatory treatment for exercising the
      rights listed above.
    </div>
    <div className={styles.article__text}>
      You may submit a request to know or delete via privacy@justonechesed.org.
    </div>
    <div className={styles.article__text}>
      Only you, or someone you authorize to act on your behalf, may make a request to know or delete your data. An
      authorized agent may make a request on your behalf by providing written permission signed by you.
    </div>
    <div className={styles.article__text}>
      We will need to confirm your identity before processing your request by asking you to log into your existing
      account (if you are a registered user) or by asking you for additional information, such as a government issued
      ID, to confirm your identity against information we have already collected.
    </div>
    <div className={styles.article__text}>
      If you are a registered Just One Chesed User under the age of 18, you may request, via privacy@justonechesed.org,
      and obtain removal of content or information publicly posted on Just One Chesed. Please note that removal does not
      ensure complete or comprehensive removal of said content or information from the Internet.
    </div>
    {/* ///////////////////////////// */}
    <h2 className={styles.title}>Changes in Privacy Policy</h2>
    <div className={styles.article__text}>
      This Privacy Policy is subject to change from time to time. Such changes are effective immediately upon the
      earliest of (a) our posting the updated Privacy Policy on our website as indicated by the “Date of Last Revision”
      date at the top of this Privacy Policy, or (b) your continued use of our website after we post the updated Privacy
      Policy on our website.
    </div>
    {/* ///////////////////////////// */}
    <h2 className={styles.title}>How to Contact Us</h2>
    <div className={styles.article__text}>
      For questions or comments please send them to <a href="mailto:info@justonechesed.org">info@justonechesed.org</a> .
    </div>
    <div className={styles.article__text}>
      We are committed to making your time on Just One Chesed rewarding and socially interactive while respecting your
      privacy. We are excited to continue building on our growing base of active volunteers and nonprofit professionals,
      and we look forward to your feedback and suggestions leading us toward the best combination of open exchange and
      personal privacy.
    </div>
    {/* ///////////////////////////// */}
    <div className={styles.article__text}>
      <i>Last updated: 8/14/2024</i>
    </div>
  </div>
);

export default PolicyInfo;
