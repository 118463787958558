import i18n from 'components/translate';

export const tagsConfig = {
  create: {
    title: i18n.t('messages:createHashtag'),
    subtitle: i18n.t('messages:opportunityHashtags'),
  },
  edit: {
    title: i18n.t('messages:editTag'),
    label: i18n.t('messages:tagName'),
    placeholder: i18n.t('messages:enterTheTagName'),
  },
  delete: {
    title: i18n.t('messages:areYouSure'),
    buttonTextConfirm: i18n.t('buttons:button.confirm'),
    buttonTextCancel: i18n.t('buttons:button.back'),
    subtitle: i18n.t('messages:wantToDeleteHashtag'),
  },
};
