import {FC} from 'react';
import {Link} from 'react-router-dom';
import cx from 'classnames';
//images
import ArrowDarkSvg from 'assets/image/button-arrow-dark.svg';
//types
import {DASHBOARD_ITEM_IMAGE} from 'core/types';
//helpers
import {getDashboardItemImage} from './helpers';
//styles
import styles from './DashboardItem.module.scss';
import {useSelector} from 'react-redux';
import {selectorIsUserMarketer} from 'redux/user-service/selector';

type DashboardItemProps = {
  count: number | string;
  title: string;
  urlTo: string;
  image?: DASHBOARD_ITEM_IMAGE;
  isAdditionalFields?: boolean;
  additionalTitle?: string;
  additionalCount?: number | string;
};

const DashboardItem: FC<DashboardItemProps> = ({
  count,
  title,
  urlTo,
  image,
  isAdditionalFields,
  additionalTitle,
  additionalCount,
}) => {
  const dashboardItemImage = getDashboardItemImage(image);
  const isMarketer = useSelector(selectorIsUserMarketer);

  return (
    <div className={styles.dashboard_item}>
      <div className={styles.item__ico}>
        <img src={dashboardItemImage} alt="DashboardImage" />
      </div>
      {isAdditionalFields ? <div className={styles.item__count}>{additionalCount}</div> : null}
      {isAdditionalFields ? <div className={styles.item__caption}>{additionalTitle}</div> : null}
      <div className={cx(styles.item__count, isAdditionalFields && styles.item__count_small)}>{count}</div>
      <div className={cx(styles.item__caption, isAdditionalFields && styles.item__caption_small)}>{title}</div>
      {!isMarketer && (
        <Link to={urlTo} className={styles.item__navButton}>
          <img src={ArrowDarkSvg} alt="ArrowImage" />
        </Link>
      )}
    </div>
  );
};

export default DashboardItem;
