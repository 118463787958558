import {Dispatch, FC, SetStateAction, useRef} from 'react';
//styles
import styles from './index.module.scss';

type InputTextProps = {
  placeholder?: string;
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
  type?: string;
};

const InputText: FC<InputTextProps> = ({placeholder = '', value, setValue, type}: InputTextProps) => {
  const ref = useRef<HTMLInputElement>(null);

  const setFocus = (): void => {
    if (ref && ref.current) {
      ref.current.focus();
    }
  };

  return (
    <div className={styles.input_text}>
      <input
        type={type || 'text'}
        placeholder={placeholder}
        ref={ref}
        onChange={(e) => setValue(e.target.value)}
        value={value || ''}
      />
      {value && <div className={styles.input_text__edit} onClick={setFocus} />}
    </div>
  );
};

export default InputText;
