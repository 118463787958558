import {FC, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
//components
import SignatureCanvas from 'react-signature-canvas';
//styles
import styles from './VerificationSignature.module.scss';

type IVerifyManualOpportunitySignature = {
  fieldName: string;
  fieldValue: string;
  setFieldValue: (name: string, value: string) => void;
};

const VerifyManualOpportunitySignature: FC<IVerifyManualOpportunitySignature> = ({
  fieldName,
  fieldValue,
  setFieldValue,
}) => {
  const signatureRef = useRef<SignatureCanvas>(null);

  const [isTouched, setIsTouched] = useState<boolean>(false);

  useEffect(() => {
    if (!fieldValue) {
      signatureRef.current?.clear();
      setIsTouched(false);
    }
  }, [fieldValue]);

  const {t} = useTranslation('inputs');

  const handleSignatureEnd = () => {
    if (signatureRef.current) {
      setFieldValue(fieldName, signatureRef.current.getTrimmedCanvas().toDataURL());
    }
  };

  const handleSignatureBegin = () => setIsTouched(true);

  return (
    <div className={styles.container}>
      {!isTouched && (
        <svg className={styles.placeholder} viewBox="0 0 150 50">
          <text x="50%" y="50%">
            {t('placeholders.addSign')}
          </text>
        </svg>
      )}

      <SignatureCanvas
        penColor="black"
        backgroundColor="transparent"
        canvasProps={{className: styles.signatureCanvas}}
        ref={signatureRef}
        onEnd={handleSignatureEnd}
        onBegin={handleSignatureBegin}
      />
    </div>
  );
};

export default VerifyManualOpportunitySignature;
