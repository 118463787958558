// react
import {FC, useMemo, useState, MouseEvent, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
// redux
import {Store} from 'redux/root';
import {useSelector} from 'react-redux';
import {setError} from 'redux/error-service/action';
import useThunkDispatch from 'hooks/useThunkDispatch';
import {selectorGetOrgId} from 'redux/organization-service/selector';
import {deleteOpportunity, getOpportunitiesCount} from 'redux/opportunities-service/action';
// components
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import {TableActionsPopups} from './TableActionsPopups';
// hooks
import {useTableActionsPopup} from 'hooks/useTableActionsPopup';
// constants
import {LINKS_URLS} from 'core/constants';
import {
  IOpportunityResponse,
  OPPORTUNITY_ACTIVE_STATUS,
  OPPORTUNITY_TYPES,
  RECURRENCE_POSSIBLE_UPDATE,
  USER_ROLES,
} from '@joc/api-gateway';
// mui
import Menu from '@material-ui/core/Menu';
import {makeStyles} from '@material-ui/core/styles';
import ShareSocialPopup from '../Volunteer/Opportunities/OpportunitiesList/ShareLinks/ShareSocialPopup';
// icons
import {ReactComponent as MoreIcon} from 'assets/icons/more.svg';
import {ReactComponent as EyeIcon} from 'assets/image/eye-lavender.svg';
import {ReactComponent as TrashIcon} from 'assets/image/trash-light.svg';
import {ReactComponent as ShareIcon} from 'assets/image/share-light.svg';
import {ReactComponent as PencilIcon} from 'assets/image/pencil-light.svg';
// data
import {menuItemText} from './data';
//styles
import styles from './index.module.scss';
import {selectorGetUserRoles} from 'redux/user-service/selector';

const useStyles = makeStyles({
  paper: {
    boxShadow: 'none',
    border: '1px solid rgba(75, 94, 245, 0.5)',
    borderRadius: '10px',
  },
  list: {
    width: '100px',
    paddingTop: '0px',
    paddingBottom: '0px',
  },
  root: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '22px',
  },
  share: {
    padding: 0,
  },
});

interface ITableActionsPopupProps {
  isSuspended: boolean;
  generalTabClickHandler?: () => void;
  opportunity: IOpportunityResponse | null;
  isPastOppoView?: boolean;
}

export const TableActionsPopup: FC<ITableActionsPopupProps> = ({
  isSuspended,
  opportunity,
  generalTabClickHandler,
  isPastOppoView,
}) => {
  const classes = useStyles();
  const dispatch = useThunkDispatch();
  const {t} = useTranslation('tooltips');

  const orgId = useSelector((store: Store) => selectorGetOrgId(store));
  const userRoles = useSelector((store: Store) => selectorGetUserRoles(store));

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorShare, setAnchorShare] = useState<null | HTMLElement>(null);
  const [isAllowEdit, setIsAllowEdit] = useState<boolean>();

  useEffect(() => {
    if (userRoles?.length && opportunity?.organisation?.id) {
      const rolesByOrganisation = userRoles.filter(
        (role) => Number(role.organizationId) === Number(opportunity?.organisation?.id)
      );

      const isAllow = rolesByOrganisation.some(
        (role) => role.roleName === USER_ROLES.ADMIN || role.roleName || USER_ROLES.COORDINATOR
      );

      setIsAllowEdit(isAllow);
    }
  }, [userRoles, opportunity]);

  const handleOpenPopup = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopup = () => {
    setAnchorEl(null);
  };

  const handleSharePopup = (event: MouseEvent<HTMLLIElement>) => {
    setAnchorShare(event.currentTarget);
    handleClosePopup();
  };

  const handleCloseSharePopup = () => {
    setAnchorShare(null);
  };

  const {
    popupsState,
    popupsActions: {
      handleEditManualOpp,
      handleOpenUpdatePopup,
      handleOpenDeleteModal,
      handleOpenManualPopup,
      handleOpenDeleteTypeModal,
      handleCloseDeleteTypeModal,
    },
  } = useTableActionsPopup(handleClosePopup);

  const handleDeleteOppo = async (updateType?: RECURRENCE_POSSIBLE_UPDATE) => {
    if (opportunity?.id) {
      try {
        await dispatch(deleteOpportunity(opportunity.id, updateType));
        if (orgId) await dispatch(getOpportunitiesCount(orgId.toString()));
        if (!!updateType) window.location.reload();
        popupsState.confirmation.popupSetState(false);
      } catch (error) {
        dispatch(setError(error?.response?.message || error.message, error.code));
      }
    }
  };

  const handleOpenConfirmModal = () => {
    !!opportunity?.repeatableOpportunity ? handleOpenDeleteTypeModal() : handleDeleteOppo();
  };

  const isDisabled = useMemo(
    () => opportunity?.opportunityActiveStatus?.status === OPPORTUNITY_ACTIVE_STATUS.SUSPENDED || isSuspended,
    [opportunity, isSuspended]
  );

  const linkForCopying = useMemo(
    () => `${process.env.REACT_APP_MAIN_URL}${LINKS_URLS.feedViewLink}/${opportunity ? opportunity.id : ''}`,
    [opportunity]
  );

  const isManualOppo = useMemo(() => opportunity?.opportunityType === OPPORTUNITY_TYPES.MANUAL, [opportunity]);

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="menu"
        onClick={handleOpenPopup}
        title={t('viewActions')}
        className={styles.more__button}
      >
        <MoreIcon />
      </Button>
      {opportunity && (
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          onClose={handleClosePopup}
          open={Boolean(anchorEl)}
          classes={{
            paper: classes.paper,
            list: classes.list,
          }}
        >
          <div>
            {!isManualOppo && (
              <>
                {isAllowEdit && (
                  <MenuItem
                    className={styles.menu__item}
                    onClick={handleOpenUpdatePopup}
                    title={menuItemText.edit}
                    classes={{
                      root: classes.root,
                    }}
                  >
                    <PencilIcon className={styles.icon} />
                    <span className={styles.text}>{menuItemText.edit}</span>
                  </MenuItem>
                )}
                <MenuItem
                  className={styles.menu__item}
                  onClick={handleSharePopup}
                  title={menuItemText.share}
                  classes={{
                    root: classes.root,
                  }}
                >
                  <ShareIcon className={styles.icon__share} />
                  <span className={styles.text}>{menuItemText.share}</span>
                </MenuItem>
              </>
            )}
            {isManualOppo && (
              <>
                <MenuItem
                  className={styles.menu__item}
                  classes={{
                    root: classes.root,
                  }}
                  title={menuItemText.view}
                  onClick={handleOpenManualPopup}
                >
                  <EyeIcon className={styles.icon__share} />
                  <span className={styles.text__share}>{menuItemText.view}</span>
                </MenuItem>
                {isAllowEdit && (
                  <MenuItem
                    className={styles.menu__item}
                    onClick={handleEditManualOpp}
                    title={menuItemText.edit}
                    classes={{
                      root: classes.root,
                    }}
                  >
                    <PencilIcon className={styles.icon} />
                    <span className={styles.text}>{menuItemText.edit}</span>
                  </MenuItem>
                )}
              </>
            )}
            {!(isPastOppoView && !isManualOppo) && isAllowEdit && (
              <MenuItem
                className={styles.menu__item}
                disabled={isDisabled}
                onClick={handleOpenDeleteModal}
                title={menuItemText.delete}
                classes={{
                  root: classes.root,
                }}
              >
                <TrashIcon className={styles.icon} />
                <span className={styles.text}>{menuItemText.delete}</span>
              </MenuItem>
            )}
          </div>
        </Menu>
      )}
      {!!opportunity ? (
        <TableActionsPopups
          opportunity={opportunity}
          popupsState={popupsState}
          deleteConfirmClickHandler={handleDeleteOppo}
          generalTabClickHandler={generalTabClickHandler}
          popupConfirmationClickHandler={handleOpenConfirmModal}
          closeDeleteTypeModalClickHandler={handleCloseDeleteTypeModal}
        />
      ) : null}
      <Menu
        anchorEl={anchorShare}
        onClose={handleCloseSharePopup}
        open={Boolean(anchorShare)}
        classes={{
          list: classes.share,
        }}
      >
        <MenuItem classes={{root: classes.share}}>
          {!!opportunity ? (
            <ShareSocialPopup
              className={styles.share}
              opportunity={opportunity}
              link={linkForCopying}
              closePopup={handleCloseSharePopup}
              setIsShowPopup={handleCloseSharePopup}
              setIsOpenSnackbar={popupsState.snackbar.popupSetState}
            />
          ) : null}
        </MenuItem>
      </Menu>
    </div>
  );
};
