import {Dispatch, FC, Fragment, SetStateAction, useMemo, useState} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {useTranslation} from 'react-i18next';
//redux
import {Store} from 'redux/root';
import {selectorGetOrgActiveStatus, selectorGetOrgId, selectorGetSchoolId} from 'redux/organization-service/selector';
//types
import {
  IGetVolunteerByIdResponse,
  OPPORTUNITY_STATUSES,
  OPPORTUNITY_TYPES,
  ORGANISATION_ACTIVE_STATUS,
} from '@joc/api-gateway';
//components
import PopupContainer from 'shared/components/PopupContainer';
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
import CreateVolunteersSmartGroup from '../CreateVolunteersSmartGroup';
import PossibleVolunteersTable from '../PossibleVolunteersTable';
import ButtonUpToTop from 'shared/components/Buttons/ButtonUpToTop';
//styles
import styles from './SmartGroupPopup.module.scss';

type SmartGroupPopupParentProps = {
  opportunityId: number;
  opportunityName: string;
  opportunityStatus: OPPORTUNITY_STATUSES;
  volunteersArray: Array<IGetVolunteerByIdResponse>;
  setIsShowPopup: Dispatch<SetStateAction<boolean>>;
  opportunityType: OPPORTUNITY_TYPES;
  isApprovalRequired: boolean | undefined;
};

const SmartGroupPopup: FC<SmartGroupPopupProps> = ({
  opportunityId,
  setIsShowPopup,
  opportunityName,
  volunteersArray,
  opportunityStatus,
  opportunityType,
  orgId,
  orgStatus,
  isApprovalRequired,
}: SmartGroupPopupProps) => {
  const {t} = useTranslation(['chesedOpportunities', 'buttons']);

  const [smartGroupVolunteers, setSmartGroupVolunteers] = useState<Array<number>>([]);
  const [isCreateSmartGroup, setIsCreateSmartGroup] = useState(false);

  const volunteersArrayMembers = useMemo(() => {
    if (orgId)
      return volunteersArray.filter((i) => i.volunteerSpendTime?.map((org) => +org.organizationId).includes(orgId));
    return [];
  }, [orgId, volunteersArray]);

  const setCheckedVolunteer = (checked?: boolean, value?: string): void => {
    checked && value
      ? setSmartGroupVolunteers([...smartGroupVolunteers, Number(value)])
      : setSmartGroupVolunteers(smartGroupVolunteers.filter((id: number) => id !== Number(value)));
  };

  const setSmartGroupVolunteersAll = () => {
    volunteersArrayMembers && smartGroupVolunteers?.length < volunteersArrayMembers.length && orgId
      ? setSmartGroupVolunteers(volunteersArrayMembers.map((i) => +i.id))
      : setSmartGroupVolunteers([]);
  };

  const isSuspended = orgStatus?.status === ORGANISATION_ACTIVE_STATUS.SUSPENDED;

  return (
    <PopupContainer isContentFullWidth containerClassName="SmartGroupPopup" setIsShowPopup={setIsShowPopup}>
      {isCreateSmartGroup ? (
        <CreateVolunteersSmartGroup
          closeHandle={() => setIsCreateSmartGroup(false)}
          smartGroupVolunteers={smartGroupVolunteers}
          setIsShowPopup={setIsShowPopup}
        />
      ) : (
        <>
          <div className={styles.content__header}>
            <h2 className="title">{t('chesedOpportunities:selectVolToGroup')}</h2>
            <span className={styles.content__header__chesed}>
              {`${t('chesedOpportunities:opportunity')}: ${opportunityName}`}
            </span>
            <ButtonDefault
              disabled={!smartGroupVolunteers.length}
              title={t('buttons:button.create')}
              classList={['primary']}
              clickHandler={() => setIsCreateSmartGroup(true)}
            />
          </div>
          <div className={styles.content}>
            <PossibleVolunteersTable
              opportunityId={opportunityId}
              volunteersArray={volunteersArray}
              opportunityStatus={opportunityStatus}
              smartGroupVolunteers={smartGroupVolunteers}
              setSmartGroupVolunteers={setCheckedVolunteer}
              setSmartGroupVolunteersAll={setSmartGroupVolunteersAll}
              opportunityType={opportunityType}
              volunteersArrayMembers={volunteersArrayMembers}
              isOrgSuspended={isSuspended}
              isApprovalRequired={isApprovalRequired}
              showButtons
            />
          </div>
          <ButtonUpToTop containerSelector="SmartGroupPopup" isPopup />
        </>
      )}
    </PopupContainer>
  );
};

const mapStateToProps = (store: Store) => ({
  orgId: selectorGetOrgId(store),
  orgStatus: selectorGetOrgActiveStatus(store),
  schoolId: selectorGetSchoolId(store),
});

const connector = connect(mapStateToProps);

type SmartGroupPopupProps = ConnectedProps<typeof connector> & SmartGroupPopupParentProps;

export default connector(SmartGroupPopup);
