import {FC, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
// other packages
import cx from 'classnames';
import {saveAs} from 'file-saver';
// redux
import {selectorGetOrgId, selectorGetSchoolId} from 'redux/organization-service/selector';
import {setError} from 'redux/error-service/action';
// interfaces
import {IDateRangeSelector} from './interfaces';
//types
import {ISearchVolunteersRequest, ISearchVolunteersByGroupRequest, DateRange} from '@joc/api-gateway';
//helpers
import {getExportedVolunteers, getTransformDate} from './helpers';
//components
import Checkbox from 'shared/components/Checkbox';
import {CloseButton} from 'shared/components/CloseButton';
import {ExportPopupDateRangeForm} from './ExportPopupDateRangeForm';
// config
import {initValuesDateRangeSelector} from './config';
// formik
import {Formik} from 'formik';
// validation
import {DateRangeFiltersValidationSchema} from 'core/validation';
//styles
import styles from './index.module.scss';

interface IExportPopupProps {
  groupname: string;
  setIsShowPopup: () => void;
  searchBody: ISearchVolunteersRequest | ISearchVolunteersByGroupRequest;
}

const ExportPopup: FC<IExportPopupProps> = ({setIsShowPopup, searchBody, groupname}) => {
  const dispatch = useDispatch();

  const {t} = useTranslation(['errors', 'sections', 'statistics']);

  const orgId = useSelector(selectorGetOrgId);
  const schoolId = useSelector(selectorGetSchoolId);

  const selectRef = useRef<HTMLDivElement>(null);

  const [isShowOptions, setIsShowOptions] = useState<boolean>(false);
  const [isShowDateRangeSelects, setIsShowDateRangeSelects] = useState<boolean>(false);
  const [isIncludeOpportunities, setIsIncludeOpportunities] = useState<boolean>(false);

  const handleSubmit = async (values: IDateRangeSelector) => {
    const oppoDateRange = DateRange.fromJS(getTransformDate(values.fromDate, values.toDate));

    try {
      if (!orgId) throw new Error(`${t('errors:sorry')} ${t('errors:cantFindOrgId')}`);

      const accessToken = localStorage.getItem('accessToken');

      if (!accessToken) throw new Error(`${t('errors:sorry')} ${t('errors:cantFindAccessToken')}`);

      const exportedVolunteersData = await getExportedVolunteers(
        searchBody,
        isIncludeOpportunities,
        orgId,
        accessToken,
        oppoDateRange
      );

      const exportedListName = `${groupname}_${!!schoolId ? 'students' : 'volunteers'}_list`;

      saveAs(exportedVolunteersData, exportedListName);
    } catch (error) {
      console.error(error);
      dispatch(setError(error.message, error.code));
    } finally {
      handleClosePopup();
    }
  };

  const checkboxChangeHandler = (value: boolean) => setIsIncludeOpportunities(value);
  const checkboxDateRangeChangeHandler = (value: boolean) => setIsShowDateRangeSelects(value);

  const handleClosePopup = () => {
    setIsShowOptions(false);
    setIsShowPopup();
  };

  return (
    <div className={cx(styles.popup, isShowOptions && styles.hide)} ref={selectRef}>
      <CloseButton onClick={handleClosePopup} classNames={styles.close__button} />
      <div className={styles.popup__content}>
        <div className={cx(styles.popup__content__includeOpportunities, styles.popup__content__includeDateRange)}>
          <div className={styles.popup__content__includeOpportunities__checkboxContainer}>
            <Checkbox
              changeHandle={checkboxDateRangeChangeHandler}
              defaultChecked={isShowDateRangeSelects}
              classNames={styles.checkbox}
            />
          </div>
          <p className={styles.popup__content__includeOpportunities__text}>{t('statistics:dateRange')}</p>
        </div>
      </div>

      <Formik
        enableReinitialize
        validateOnChange
        onSubmit={handleSubmit}
        initialValues={initValuesDateRangeSelector}
        validationSchema={DateRangeFiltersValidationSchema}
      >
        <ExportPopupDateRangeForm isShowDateRangeSelects={isShowDateRangeSelects} />
      </Formik>

      {!!schoolId && (
        <div className={styles.popup__content}>
          <div className={styles.popup__content__includeOpportunities}>
            <div className={styles.popup__content__includeOpportunities__checkboxContainer}>
              <Checkbox
                changeHandle={checkboxChangeHandler}
                defaultChecked={isIncludeOpportunities}
                classNames={styles.checkbox}
              />
            </div>
            <p className={styles.popup__content__includeOpportunities__text}>
              {t('sections:table.exportList.includeOpportunities')}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default ExportPopup;
