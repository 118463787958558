import {FC, useMemo} from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatch} from 'react-redux';
//formik
import {Formik} from 'formik';
//redux
import {setAboutYouOrg} from 'redux/organization-service/actions';
//urls
import {urls} from 'core/appUrls';
//form configuration
import {AboutYouSignUpFormValues, getInitialValues, validationSchema} from './formConfiguration';
//component
import YouSignupForm from './YouSignupForm/YouSignupForm';

const AboutYouSignupForm: FC = () => {
  const history = useHistory();

  const dispatch = useDispatch();

  const initialValues = useMemo(() => getInitialValues(), []);

  const submitClickHandler = (values: AboutYouSignUpFormValues) => {
    if (values.imagePath === '') values.imagePath = undefined;
    localStorage.setItem('aboutYou', JSON.stringify({...values, positionId: 1}));
    dispatch(setAboutYouOrg(values));
    history.push(urls.onboardingOrgSignupAboutOrganization);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={submitClickHandler}
    >
      <YouSignupForm />
    </Formik>
  );
};

export default AboutYouSignupForm;
