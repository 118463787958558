import {FC, useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
//redux
import {selectorGetOpportunitiesTotal} from 'redux/opportunities-service/selector';
import {getOpportunitiesByGuest, resetOpportunities} from 'redux/opportunities-service/action';
import {resetError, setError} from 'redux/error-service/action';
import {selectorGetError} from 'redux/error-service/selector';
import {selectorGetFilter} from 'redux/filter-service/selector';
import {resetFilter} from 'redux/filter-service/actions';
//types
import {SearchOpportunitiesByGuestRequest} from '@joc/api-gateway';
//components
import Loader from 'shared/components/Loader';
import MainPageLayoutV from 'components/Volunteer/MainPageLayoutV';
import SidebarContainer from 'components/Volunteer/Opportunities/SidebarContainer';
import OpportunityContainer from 'components/Volunteer/Opportunities/OpportunitiesList/OpportunityContainer';
import OpportunitiesFilters from 'components/Volunteer/Opportunities/OpportunitiesFilters';
import OpportunitiesList from 'components/Volunteer/Opportunities/OpportunitiesList';
import ResponseFailure from 'shared/components/ResponseFailure';
//context
import {FeedVContext} from '../FeedV';

const GuestFeed: FC = () => {
  const dispatch = useDispatch();

  const filter = useSelector(selectorGetFilter);
  const opportunitiesTotal = useSelector(selectorGetOpportunitiesTotal);
  const error = useSelector(selectorGetError);

  const [isLoading, setIsLoading] = useState(true);
  const [isClearSearch, setIsClearSearch] = useState(false);

  const {t} = useTranslation(['messages', 'buttons']);

  const buttonFailureClickHandler = async () => {
    dispatch(resetError());
    dispatch(resetOpportunities());
    dispatch(resetFilter());
    setIsClearSearch(true);
  };

  const getOpportunitiesRequest = useCallback(async () => {
    if (filter) {
      try {
        setIsLoading(true);

        const requestBody = {...filter};
        if (!requestBody.fullTextSearch?.value) delete requestBody.fullTextSearch;

        await dispatch(getOpportunitiesByGuest(SearchOpportunitiesByGuestRequest.fromJS(requestBody)));

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        dispatch(setError(error?.response?.message || error.message, error?.response?.code || error.code));
      }
    }
  }, [filter]);

  useEffect(() => {
    getOpportunitiesRequest();
  }, [getOpportunitiesRequest]);

  useEffect(() => {
    return () => {
      dispatch(resetError());
      dispatch(resetOpportunities());
      dispatch(resetFilter());
    };
  }, []);

  return (
    <MainPageLayoutV>
      <OpportunityContainer>
        <SidebarContainer>
          <FeedVContext.Provider value={{isClearSearch, setIsClearSearch}}>
            <OpportunitiesFilters />
          </FeedVContext.Provider>
        </SidebarContainer>
        <div>
          {isLoading && !opportunitiesTotal ? (
            <Loader />
          ) : !opportunitiesTotal && !isLoading ? (
            <ResponseFailure
              styleTable
              message={error.message || t('messages:noRelevantListings')}
              buttonTitle={t('buttons:button.resetFilters')}
              buttonClickHandler={buttonFailureClickHandler}
            />
          ) : (
            <OpportunitiesList isLoading={isLoading} />
          )}
        </div>
      </OpportunityContainer>
    </MainPageLayoutV>
  );
};

export default GuestFeed;
