import {FC} from 'react';
import {connect, ConnectedProps} from 'react-redux';
//redux
import {Store} from 'redux/root';
import {setPushMessage} from 'redux/push-service/action';
import {selectorGetPushMessage} from 'redux/push-service/selector';
//components
import {Snackbar} from '@material-ui/core';
import {Alert} from '@material-ui/lab';

const SnackbarInfo: FC<SnackbarInfoProps> = ({text, setPushMessage}: SnackbarInfoProps) => {
  if (!text.title) return null;

  return (
    <Snackbar open={!!text.title} autoHideDuration={10000} onClose={() => setPushMessage({title: '', body: ''})}>
      <Alert onClose={() => setPushMessage({title: '', body: ''})} severity="info">
        {text.title}
        <br />
        {text.body}
      </Alert>
    </Snackbar>
  );
};

const mapStateToProps = (store: Store) => ({
  text: selectorGetPushMessage(store),
});

const mapDispatchToProps = {
  setPushMessage,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type SnackbarInfoProps = ConnectedProps<typeof connector>;

export default connector(SnackbarInfo);
