import {ChangeEvent, FC, MouseEvent, useState} from 'react';
import cx from 'classnames';
import Menu from '@material-ui/core/Menu';
import {makeStyles} from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import {FormControlLabel, Radio, RadioGroup} from '@material-ui/core';
//components
import ButtonCross from 'shared/components/Buttons/ButtonCross';
//types
import {SortDirection} from '@joc/api-gateway';
import {TableTotalAmount} from 'components/Organization/Volunteers/Table/interfaces';
//images
import {ReactComponent as FilterTriangle} from 'assets/image/filter-triangle-dark.svg';
import ArrowSvg from 'assets/image/button-arrow-dark.svg';
//helpers
import {CommonProps, WithSortProps} from '../helpers';
//styles
import styles from '../TableHeadCell.module.scss';

const useStyles = makeStyles({
  option: {
    padding: 0,
    transition: 'none',
    '&:hover': {
      background: 'white !important',
    },
  },
});

type TableHeadCellWithSortPropsType = CommonProps & WithSortProps;

const TableHeadCellWithSort: FC<TableHeadCellWithSortPropsType> = ({
  text,
  itemClassName,
  sortProps,
  clickSortHandler,
  clickOptionHandler,
  optionValue,
  children,
}) => {
  const classes = useStyles();
  const {sort, ownSortBy} = sortProps;

  const sortTriangleClickHandler = () => clickSortHandler(ownSortBy);
  const [anchorOption, setAnchorOption] = useState<null | HTMLElement>(null);

  const handleOptionPopup = (event: MouseEvent<HTMLLIElement | HTMLImageElement>) => {
    if (!optionValue) return;
    setAnchorOption(event.currentTarget);
  };

  const handleClosePopup = () => {
    setAnchorOption(null);
  };

  const handleOptionChange = (_: ChangeEvent<HTMLInputElement>, value: string) => {
    if (clickOptionHandler) {
      clickOptionHandler(value as TableTotalAmount);
      handleClosePopup();
    }
  };

  return (
    <div className={cx(styles.main, itemClassName && styles[itemClassName])}>
      {children || (
        <div className={styles.cell__container}>
          <span className={cx(styles.text, optionValue && styles.clickable)} onClick={handleOptionPopup}>
            {text}
          </span>
          {optionValue && <img onClick={handleOptionPopup} src={ArrowSvg} className={styles.cell__arrow} alt="Arrow" />}
          {text && (
            <div className={styles.sortTriangles} onClick={sortTriangleClickHandler}>
              {sort?.sortBy === ownSortBy && sort?.sortDir ? (
                <FilterTriangle className={cx(styles.image, sort.sortDir === SortDirection.ASC && styles.ascDir)} />
              ) : (
                <div className={styles.sortTriangles_default}>
                  <FilterTriangle className={cx(styles.image, styles.ascDir)} />
                  <FilterTriangle className={styles.image} />
                </div>
              )}
            </div>
          )}
          {optionValue && (
            <Menu
              anchorEl={anchorOption}
              onClose={handleClosePopup}
              open={Boolean(anchorOption)}
              transitionDuration="auto"
              classes={{
                list: classes.option,
              }}
            >
              <MenuItem disableRipple classes={{root: classes.option}}>
                <div className={styles.option__container}>
                  <ButtonCross clickHandler={handleClosePopup} parentClassName={styles.option__cross} />
                  <RadioGroup onChange={handleOptionChange} value={optionValue} className={styles.group}>
                    <FormControlLabel value={TableTotalAmount.Year} control={<Radio />} label="Current Year" />

                    <FormControlLabel
                      value={TableTotalAmount.Total}
                      control={<Radio />}
                      label={TableTotalAmount.Total}
                    />
                  </RadioGroup>
                </div>
              </MenuItem>
            </Menu>
          )}
        </div>
      )}
    </div>
  );
};

export default TableHeadCellWithSort;
