import {FC, useLayoutEffect} from 'react';
import {connect, ConnectedProps} from 'react-redux';
//redux
import {changeNavbarMode} from 'redux/user-service/actions';
//styles
import styles from './NavBarVisibilityButton.module.scss';

const NavBarVisibilityButton: FC<NavBarVisibilityButtonProps> = ({changeNavbarMode}: NavBarVisibilityButtonProps) => {
  useLayoutEffect(() => {
    const viewportWidth = window.innerWidth;
    if (viewportWidth < 1920) changeNavbarMode();
  }, []);

  return (
    <button className={styles.main} onClick={changeNavbarMode}>
      <span className={styles.line} />
      <span className={styles.line} />
      <span className={styles.line} />
    </button>
  );
};

const mapDispatchToProps = {
  changeNavbarMode,
};

const connector = connect(null, mapDispatchToProps);

type NavBarVisibilityButtonProps = ConnectedProps<typeof connector>;

export default connector(NavBarVisibilityButton);
