import {FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
//types
import {IGroupLocationsRequest} from '@joc/api-gateway';
import {FieldBaseProps} from 'core/types';
//form configuration
import {SmartGroupLocationValue} from '../../formConfiguration';
//helpers
import {getLocationFromId} from './helpers';
//components
import InputSearchLocation from './InputSearchLocation';
import FieldWrapper from 'shared/inputs/FieldWrapper';

type LocationControlProps = FieldBaseProps<{}, SmartGroupLocationValue>;

const LocationControl: FC<LocationControlProps> = (fieldBaseProps) => {
  const {field, form} = fieldBaseProps;
  const {setFieldValue, setFieldTouched} = form;

  const {t} = useTranslation(['inputs']);

  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    if (field.value) {
      (async () => {
        const [locationId, locationType] = field.value.cityId
          ? [field.value.cityId, 'city']
          : [field.value.stateId, 'state'];

        if (locationId) {
          const addressText = await getLocationFromId(locationId, locationType);
          setInputValue(addressText);
        }
      })();
    }
  }, []);

  const locationChangeHandler = (address?: IGroupLocationsRequest) => {
    if (address) {
      console.log('address', address);
      setFieldValue(field.name, address);
      setFieldTouched(field.name, true);
    }
  };

  return (
    <FieldWrapper {...fieldBaseProps}>
      <InputSearchLocation
        fieldValue={inputValue}
        placeholder={t('inputs:placeholders.stateCity')}
        selectHandler={locationChangeHandler}
      />
    </FieldWrapper>
  );
};
export default LocationControl;
