import {FC, Fragment, useEffect, useState} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {useHistory, useLocation} from 'react-router';
import {useTranslation} from 'react-i18next';
import qs from 'qs';
//redux
import {resetError} from 'redux/error-service/action';
import {selectorGetError} from 'redux/error-service/selector';
import {Store} from 'redux/root';
//urls
import {urls} from 'core/appUrls';
//types
import {SIGNUP_ERROR} from 'core/types';
//components
import SignUpFormV from 'components/Volunteer/SignUpFormV';
import AboutYouSignupFormV from 'components/Volunteer/SignUpFormV/AboutYouSignupFormV';
import Appeal from 'shared/components/Appeal/DefaultRenderer';
import ArrowBackLink from 'shared/components/ArrowBackLink';
import ResponseFailure from 'shared/components/ResponseFailure';
//styles
import styles from './SignUpV.module.scss';

const SignUpV: FC<SignUpVProps> = ({error, resetError}) => {
  const history = useHistory();

  const location = useLocation();

  const [signupError, setSignupError] = useState<SIGNUP_ERROR>(SIGNUP_ERROR.NONE);
  const [isShowAboutYouForm, setIsShowAboutYouForm] = useState(false);

  useEffect(() => {
    const queryUserValues = qs.parse(location.search, {ignoreQueryPrefix: true});
    const userValues = {
      firstName: queryUserValues.firstName || '',
      lastName: queryUserValues.lastName || '',
      email: queryUserValues.email || '',
      gender: queryUserValues.genderId ? Number(queryUserValues.genderId) : null,
      phoneNumber:
        queryUserValues.phoneNumber && queryUserValues.phoneNumber.length && queryUserValues.phoneNumber !== 'null'
          ? queryUserValues.phoneNumber
          : '',
      invitationId: queryUserValues.id || '',
      organisationId: queryUserValues.organisationId || /* joinByLinkOrganisationId ||*/ '',
      studentId: queryUserValues.studentId || '',
      birthDate:
        queryUserValues.birthDate && queryUserValues.birthDate.length && queryUserValues.birthDate !== 'null'
          ? queryUserValues.birthDate
          : '',
      // TODO: [JF-1137] [fe] uncomment when back will be enable to receive data
      // grade: queryUserValues.gradeId || '',
      // spendTime: queryUserValues.spendTime || '',
    };

    if (userValues.invitationId) {
      localStorage.setItem('userFirebaseInfo', JSON.stringify(userValues));
      setIsShowAboutYouForm(true);
    }
  }, []);

  const {t} = useTranslation(['buttons', 'validation']);

  if (isShowAboutYouForm) {
    return <AboutYouSignupFormV />;
  }

  const loginClickHandler = () => {
    resetError();
    setSignupError(SIGNUP_ERROR.NONE);
    history.push(`${urls.onboardingLoginVolunteer}${location.search}`);
  };

  return (
    <Fragment>
      {signupError === SIGNUP_ERROR.EMAIL_ALREADY_EXISTS ? (
        <ResponseFailure
          message={t('validation:emailSchema.exists')}
          buttonClickHandler={loginClickHandler}
          buttonTitle={t('buttons:button.backToLogIn')}
        />
      ) : error.state ? (
        <ResponseFailure
          message={error.message}
          buttonClickHandler={loginClickHandler}
          buttonTitle={t('buttons:button.backToLogIn')}
        />
      ) : (
        <div className={styles.content}>
          <ArrowBackLink onboardingStyle />
          <Appeal styleLoginV>
            <SignUpFormV setIsShowAboutYouForm={setIsShowAboutYouForm} setSignupError={setSignupError} />
          </Appeal>
        </div>
      )}
    </Fragment>
  );
};

const mapStateToProps = (store: Store) => ({
  error: selectorGetError(store),
});
const mapDispatchToProps = {
  resetError,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type SignUpVProps = ConnectedProps<typeof connector>;

export default connector(SignUpV);
