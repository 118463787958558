//user
export const GET_USER = 'GET_USER';
export const RESET_USER_DATA = 'RESET_USER_DATA';
export const UPDATE_USER = 'UPDATE_USER';
export const VERIFY_TOKEN_USER = 'VERIFY_TOKEN_USER';
export const CREATE_USER = 'CREATE_USER';
export const CHANGE_NAVBAR_MODE = 'CHANGE_NAVBAR_MODE';
export const ADD_PUSH_NOTIFICATION_TOKEN = 'ADD_PUSH_NOTIFICATION_TOKEN';
export const REMOVE_PUSH_NOTIFICATION_TOKEN = 'REMOVE_PUSH_NOTIFICATION_TOKEN';

//dashboard
export const GET_DASHBOARD_INFO = 'GET_DASHBOARD_INFO';
export const GET_STATISTICS_GENERAL = 'GET_STATISTICS_GENERAL';
export const GET_USERS_SIGNUP_TRAFFIC = 'GET_USERS_SIGNUP_TRAFFIC';
export const GET_USERS_GRADE_TOTAL_TIME_TRAFFIC = 'GET_USERS_GRADE_TOTAL_TIME_TRAFFIC';
export const GET_USERS_LOGIN_TRAFFIC = 'GET_USERS_LOGIN_TRAFFIC';
export const GET_VOLUNTEERS_AVG_AGE = 'GET_VOLUNTEERS_AVG_AGE';
export const GET_OPPORTUNITIES_REGULAR_MANUAL = 'GET_OPPORTUNITIES_REGULAR_MANUAL';
export const GET_VOLUNTEERS_TOP = 'GET_VOLUNTEERS_TOP';

//org
export const SET_ORG_ABOUT_YOU = 'SET_ORG_ABOUT_YOU';
export const SET_ORG_ABOUT_ORG = 'SET_ORG_ABOUT_ORG';
export const SET_ORG_CONTACT = 'SET_ORG_CONTACT';
export const SET_ORG_INVITED_COLLEAGUES = 'SET_ORG_INVITED_COLLEAGUES';
export const GET_ORG_INFO = 'GET_ORG_INFO';
export const GET_OPPO_SETTINGS = 'GET_OPPO_SETTINGS';
export const GET_OPPO_SETTINGS_FOR_VOLUNTEER = 'GET_OPPO_SETTINGS_FOR_VOLUNTEER';

export const GET_GRADE_SETTINGS = 'GET_GRADE_SETTINGS';

export const RESET_ORG_INFO = 'RESET_ORG_INFO';
export const RESET_CREATE_ORG_INFO = 'RESET_CREATE_ORG_INFO';
export const UPDATE_ORG_INFO = 'UPDATE_ORG_INFO';
export const GET_ORG_VIDEOS = 'GET_ORG_VIDEOS';
export const UPLOAD_ORG_VIDEO = 'UPLOAD_ORG_VIDEO';
export const SET_CURRENT_VIDEO = 'SET_CURRENT_VIDEO';
export const UNSET_CURRENT_VIDEO = 'UNSET_CURRENT_VIDEO';
export const DELETE_CURRENT_VIDEO = 'DELETE_CURRENT_VIDEO';
export const ADD_VIDEO_COUNT_WATCH = 'ADD_VIDEO_COUNT_WATCH';
export const GET_ORG_MEMBERS = 'GET_ORG_MEMBERS';
export const SET_IS_LOADING_SEARCH_ORG_MEMBERS = 'SET_IS_LOADING_SEARCH_ORG_MEMBERS';
export const SEARCH_ORG_MEMBERS = 'SEARCH_ORG_MEMBERS';
export const RESET_ORG_MEMBERS = 'RESET_ORG_MEMBERS';
export const SET_IS_LOADING_GRADES = 'SET_IS_LOADING_GRADES';
//opportunities
export const GET_OPPORTUNITIES = 'GET_OPPORTUNITIES';
export const GET_OPPORTUNITY_BY_ID = 'GET_OPPORTUNITY_BY_ID';
export const GET_COORDINATORS_BY_ID = 'GET_COORDINATORS_BY_ID';
export const GET_OPPORTUNITIES_COUNT = 'GET_OPPORTUNITIES_COUNT';
export const RESET_OPPORTUNITIES_RECORDS = 'RESET_OPPORTUNITIES_RECORDS';
export const RESET_CURRENT_OPPORTUNITY = 'RESET_CURRENT_OPPORTUNITY';
export const CREATE_OPPORTUNITY = 'CREATE_OPPORTUNITY';
export const UPDATE_OPPORTUNITY = 'UPDATE_OPPORTUNITY';
export const UPDATE_CURRENT_OPPORTUNITY = 'UPDATE_CURRENT_OPPORTUNITY';
export const DELETE_OPPORTUNITY = 'DELETE_OPPORTUNITY';
export const GET_OPPORTUNITIES_REMINDER_TAPE = 'GET_OPPORTUNITIES_REMINDER_TAPE';
export const CANTMAKEIT = 'CANTMAKEIT';
export const CHANGE_STATUS_MANUAL_OPPORTUNITY = 'CHANGE_STATUS_MANUAL_OPPORTUNITY';
export const JOIN_TO_OPPORTUNITY_BY_VOLUNTEER = 'JOIN_TO_OPPORTUNITY_BY_VOLUNTEER';
export const UPDATE_MANUAL_OPPORTUNITY = 'UPDATE_MANUAL_OPPORTUNITY';
export const REFRESH_PENDING_OPPORTUNITIES = 'REFRESH_PENDING_OPPORTUNITIES';
export const SET_IS_NEED_REFRESH = 'SET_IS_NEED_REFRESH';
export const RESET_IS_NEED_REFRESH = 'RESET_IS_NEED_REFRESH';

//reminder Tape
export const GET_REMINDER_TAPE_OPPORTUNITIES = 'GET_REMINDER_TAPE_OPPORTUNITIES';

//volunteers
export const GET_VOLUNTEERS_ALL = 'GET_VOLUNTEERS_ALL';
export const GET_VOLUNTEERS_BY_GROUP = 'GET_VOLUNTEERS_BY_GROUP';
export const RESET_VOLUNTEERS = 'RESET_VOLUNTEERS';
export const SET_BROADCASTINFO = 'SET_BROADCASTINFO';

//signup requests
export const GET_SIGNUP_REQUESTS_ALL = 'GET_SIGNUP_REQUESTS_ALL';
export const GET_SIGNUP_REQUESTS_TOTAL = 'GET_SIGNUP_REQUESTS_TOTAL';
export const APPROVE_SIGNUP_REQUEST = 'APPROVE_SIGNUP_REQUEST';
export const DECLINE_SIGNUP_REQUEST = 'DECLINE_SIGNUP_REQUEST';
export const RESET_SIGNUP_REQUESTS = 'RESET_SIGNUP_REQUESTS';

//smart groups
export const GET_SMART_GROUPS_ALL = 'GET_SMART_GROUPS_ALL';
export const CREATE_SMART_GROUP = 'CREATE_SMART_GROUP';
export const EDIT_SMART_GROUP = 'EDIT_SMART_GROUP';
export const DELETE_SMART_GROUP = 'DELETE_SMART_GROUP';

//errors
export const SET_ERROR = 'SET_ERROR';
export const RESET_ERROR = 'RESET_ERROR';

//pushes
export const SET_PUSH_MESSAGE = 'SET_PUSH_MESSAGE';

//chat
export const SET_CHAT_USER_TO_CONNECT = 'SET_CHAT_USER_TO_CONNECT';
export const SET_CHAT_UNREAD_MESSAGE_COUNT = 'SET_CHAT_UNREAD_MESSAGE_COUNT';
export const SET_CHAT_CLIENT_CONNECT_STATE = 'SET_CHAT_CLIENT_CONNECT_STATE';
export const RESET_CHAT = 'RESET_CHAT';
export const RESET_CHAT_COUNTER = 'RESET_CHAT_COUNTER';

//invites
export const RESEND_INVITE_VOLUNTEER = 'RESEND_INVITE_VOLUNTEER';
export const SET_VOLUNTEERS_INVITED = 'SET_VOLUNTEERS_INVITED';
export const NOT_ACCEPTED_INVITES_COUNT = 'NOT_ACCEPTED_INVITES_COUNT';

//filters
export const SET_FILTER = 'SET_FILTER';
export const SET_TAB_FILTER = 'SET_TAB_FILTER';
export const SET_VOLUNTEER_TAB_FILTER = 'SET_VOLUNTEER_TAB_FILTER';
export const RESET_FILTER = 'RESET_FILTER';
export const SET_SORT = 'SET_SORT';
export const SET_PAGINATION = 'SET_PAGINATION';
export const SET_SEARCH = 'SET_SEARCH';

//public dashboard
export const SET_PUBLIC_DASHBOARD_DATA = 'SET_PUBLIC_DASHBOARD_DATA';

// hashtags
export const EDIT_HASHTAG = 'EDIT_HASHTAG';
export const CREATE_HASHTAG = 'CREATE_HASHTAG';
export const DELETE_HASHTAG = 'DELETE_HASHTAG';
export const GET_ALL_HASHTAGS = 'GET_ALL_HASHTAGS';

// loading

export const UPDATE_PROGRESS = 'UPDATE_PROGRESS';
export const SET_END_UPLOAD = 'SET_END_UPLOAD';
export const SET_ERROR_UPLOAD = 'SET_ERROR_UPLOAD';
export const HIDE_UPLOAD_ALERT_INFO = 'HIDE_UPLOAD_ALERT_INFO';

// Challenges
export const GET_OPEN_CHALLENGE = 'GET_ACTIVE_CHALLENGE';
export const ATTACH_USER_TO_CHALLENGE = 'ATTACH_USER_TO_CHALLENGE';

// Statuses
export const EDIT_STATUS = 'EDIT_STATUS';
export const CREATE_STATUS = 'CREATE_STATUS';
export const DELETE_STATUS = 'DELETE_STATUS';
export const GET_STATUS_ALL = 'GET_STATUS_ALL';
export const LOADING_STATUS = 'LOADING_STATUS';
