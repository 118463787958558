import {FC, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import cx from 'classnames';
//api
import {CreateExternalUserRequest} from '@joc/api-gateway';
import {API} from 'core/API';
//formik
import {Formik} from 'formik';
import {ChangeDefaultPasswordFormValues, validationSchema} from './formConfiguration';
//redux
import {selectorGetUserData} from 'redux/user-service/selector';
import {setError} from 'redux/error-service/action';
import {getCurrentUser} from 'redux/user-service/actions';
//helpers
import {getInitialValues} from './helpers';
//components
import Appeal from 'shared/components/Appeal/DefaultRenderer';
import PopupContainer from 'shared/components/PopupContainer';
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
import ChangePasswordForm from './ChangePasswordForm';
//styles
import styles from './ChangeDefaultPasswordPopup.module.scss';

type ChangeDefaultPasswordPopupProps = {
  handleOpenPopup: (value: boolean) => void;
};

const ChangeDefaultPasswordPopup: FC<ChangeDefaultPasswordPopupProps> = ({handleOpenPopup}) => {
  const dispatch = useDispatch();

  const userData = useSelector(selectorGetUserData);

  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessfullyUpdate, setIsSuccessfullyUpdate] = useState(false);

  const {t} = useTranslation(['messages', 'buttons']);

  const submitClickHandler = async (values: ChangeDefaultPasswordFormValues) => {
    setIsLoading(true);
    try {
      const {email, password} = values;
      const reqBody = {
        email,
        password,
      };
      await API.updateFireBaseUserPassword(reqBody as CreateExternalUserRequest);
      await dispatch(getCurrentUser());
      setIsSuccessfullyUpdate(true);
    } catch (error) {
      dispatch(setError(error?.response?.message || error.response, error?.response?.code || error.code));
    } finally {
      setIsLoading(false);
    }
  };

  const initialValues = useMemo(() => getInitialValues(userData), [userData]);

  return (
    <div>
      <PopupContainer parentClassName={styles.popup} isDisableCloseButton isBroadcastStyle>
        {!isSuccessfullyUpdate ? (
          <>
            <div className={styles.content}>
              <Appeal styleThink isHeightDisable={false} isFullHeight />
              <h1 className={cx('title')}>{t('messages:changeYourPassword')}</h1>
              <p>{t('messages:pleaseResetYourPassword')}</p>
            </div>

            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={submitClickHandler}
            >
              <ChangePasswordForm isLoading={isLoading} />
            </Formik>
          </>
        ) : (
          <div className={styles.content}>
            <Appeal styleJoin isHeightDisable={false} isFullHeight />
            <h1 className={cx('title')}>{t('messages:success')}!</h1>
            <p>{t('messages:passwordHasBeenChanged')}!</p>

            <ButtonDefault
              parentClassName={styles.button}
              classList={['primary', 'extra_lg']}
              title={t('buttons:button.done')}
              clickHandler={() => handleOpenPopup(false)}
            />
          </div>
        )}
      </PopupContainer>
    </div>
  );
};

export default ChangeDefaultPasswordPopup;
