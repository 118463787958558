import {colorHandler, redoHandler, sizeHandler, undoHandler} from './helpers';
import {allowedColors, allowedSizes} from './constants';

export const modules = (isShowToolbar: boolean) => ({
  magicUrl: true,
  history: {
    delay: 1000,
    maxStack: 50,
    userOnly: true,
  },
  // bodyWrapper: {},
  toolbar: isShowToolbar && {
    handlers: {
      undo: undoHandler,
      redo: redoHandler,
      color: colorHandler,
      size: sizeHandler,
    },
    container: [
      [{size: [false, ...allowedSizes]}],
      [
        {
          color: allowedColors,
        },
        {
          background: [false, ...allowedColors],
        },
      ],
      ['bold', 'italic', 'underline', 'strike', {align: []}, {list: 'ordered'}, {list: 'bullet'}],
      [{indent: '-1'}, {indent: '+1'}],
      ['link'],
      ['undo', 'redo'],
    ],
  },
});

export const formats = [
  'bold',
  'italic',
  'lineHeight',
  'underline',
  'strike',
  'list',
  'bullet',
  'link',
  'color',
  'background',
  'align',
  'indent',
  'size',
];
