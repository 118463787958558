import {Dispatch, SetStateAction} from 'react';
//translate
import i18n from 'components/translate';
//formik
import {FormikErrors} from 'formik';
//redux
import {ContactOrg} from 'redux/organization-service/types';
//API
import {API} from 'core/API';
//validation
import {emailSchema} from 'core/validation';
//types
import {INewCreateUserRequest, ValidateUserEmailRequest} from '@joc/api-gateway';
import {UserType} from 'components/Organization/Volunteers/VolunteersPopups/InvitePopup/helpers';

const validationT = i18n.getFixedT(null, 'validation');

export const emailBlur = async (
  email: string,
  setErrors: (errors: FormikErrors<INewCreateUserRequest | ContactOrg | string>) => void,
  errors?: FormikErrors<INewCreateUserRequest | ContactOrg>
) => {
  try {
    setErrors('');
    const isValid = await emailSchema.isValid(email);
    if (isValid) await API.validateUser(ValidateUserEmailRequest.fromJS({email}));
  } catch (error) {
    console.error(error);
    if (error.response.code === 409) {
      const errorText = validationT('emailSchema.exists');
      setErrors({...errors, email: errorText});
      return;
    }
    setErrors({...errors, email: error.response.message});
  }
};

export const emailBlurSignUpMainFormV = async (
  email: string,
  setEmailError: Dispatch<SetStateAction<string>>,
  userType?: UserType
) => {
  try {
    setEmailError('');
    const isValid = await emailSchema.isValid(email);
    if (isValid) await API.validateUser(ValidateUserEmailRequest.fromJS({email}));
  } catch (error) {
    console.error(error);
    if (error.response.code === 409) {
      const errorText = validationT(
        userType === UserType.Volonteer
          ? 'emailSchema.existsForAdding'
          : userType === UserType.Member
          ? 'emailSchema.existsForAddingMember'
          : 'emailSchema.exists'
      );
      setEmailError(errorText);
    } else {
      setEmailError(error.response.message);
    }
  }
};
