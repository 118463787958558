import moment from 'moment';
//types
import {OPPORTUNITY_TYPES} from '@joc/api-gateway';

export const getDateDetails = (startDate: Date, endDate: Date): string =>
  `${moment(startDate).format('DD MMM')}, ${moment(startDate).format('hh:mm A')} - ${moment(endDate).format(
    'hh:mm A'
  )}`;

export const isManualOppo = (opportunityType?: OPPORTUNITY_TYPES) => opportunityType === OPPORTUNITY_TYPES.MANUAL;
