// react
import {FC, useMemo} from 'react';
import {ThunkAction} from 'redux-thunk';
import {Store} from 'redux/root';
import {AnyAction} from 'redux';
// formik
import {Formik} from 'formik';
// mui
import Dialog from '@material-ui/core/Dialog';
// actions
import {editHashtag} from 'redux/hashtag-service/actions';
// components
import {EditHashtagForm} from './EditHashtagForm';
import {CloseButton} from 'shared/components/CloseButton';
// types
import {ITagRequest} from '@joc/api-gateway';
// interfaces
import {IModalsData} from '../interface';
// validation
import {hashtagFormValidationSchema} from 'core/validation';
// helper
import {removeSpaces} from '../CreateHashtagModal/CreateHashtagSection/helper';
//styles
import styles from './index.module.scss';

interface IEditHashtagModalProps {
  title: string;
  isOpen: boolean;
  fieldLabel?: string;
  orgId: string | null;
  modalsData: IModalsData;
  handleClose: () => void;
  fieldPlaceholder?: string;
  startLoading: <T>(action: ThunkAction<T, Store, void, AnyAction>) => Promise<T>;
}

export const EditHashtagModal: FC<IEditHashtagModalProps> = ({
  orgId,
  title,
  isOpen,
  fieldLabel,
  modalsData,
  handleClose,
  startLoading,
  fieldPlaceholder,
}) => {
  const {
    edit: {editHashtagModalData},
  } = modalsData;

  const initValue = useMemo(
    () => ({
      name: editHashtagModalData?.name || '',
    }),
    [editHashtagModalData?.name]
  );

  const handleSubmit = async (values: ITagRequest) => {
    if (editHashtagModalData?.id && values.name && orgId) {
      const updatedValue = {name: removeSpaces(values.name)};
      const {id: hashtagId} = editHashtagModalData;

      startLoading(editHashtag(hashtagId, orgId, updatedValue));
      handleClose();
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      className={styles.editTag__modal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className={styles.content}>
        <div className={styles.content__wrapper}>
          <CloseButton onClick={handleClose} classNames={styles.content__close__button} />
          <h3 className={styles.content__title}>{title}</h3>
          <div>
            <Formik
              validateOnChange
              enableReinitialize
              onSubmit={handleSubmit}
              initialValues={initValue}
              validationSchema={hashtagFormValidationSchema}
            >
              <EditHashtagForm fieldLabel={fieldLabel} fieldPlaceholder={fieldPlaceholder} />
            </Formik>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
