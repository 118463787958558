// react
import {FC} from 'react';
// mui
import Chip from '@material-ui/core/Chip';
// interfaces
import {IDeleteHashtagModalData, IEditHashtagModalData} from 'components/HashtagModals/interface';
// icons
import {ReactComponent as ChipHashtag} from 'assets/icons/hashtag.svg';
import {ReactComponent as TrashIcon} from 'assets/image/trash-light.svg';
import {ReactComponent as PencilIcon} from 'assets/image/pencil-light.svg';
// styles
import styles from './index.module.scss';

interface IHashtagProps {
  id: number;
  name: string;
  handleOpenEditHashtagModal: (data?: IEditHashtagModalData | undefined) => void;
  handleOpenDeleteHashtagModal: (data?: IDeleteHashtagModalData | undefined) => void;
}

export const Hashtag: FC<IHashtagProps> = ({id, name, handleOpenDeleteHashtagModal, handleOpenEditHashtagModal}) => {
  const handleOpenDeleteModal = () => {
    handleOpenDeleteHashtagModal({id, name});
  };

  const handleOpenEditModal = () => {
    handleOpenEditHashtagModal({id, name});
  };

  return (
    <div className={styles.hashtag}>
      <Chip
        label={name}
        size="medium"
        color="default"
        className={styles.hashtag__chip}
        icon={<ChipHashtag className={styles.hashtag__icon} />}
      />
      <div className={styles.hashtag__edit__icon}>
        <PencilIcon onClick={handleOpenEditModal} />
        <TrashIcon onClick={handleOpenDeleteModal} className={styles.hashtag__trash__icon} />
      </div>
    </div>
  );
};
