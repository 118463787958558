import * as Yup from 'yup';
//types
import {
  GetLocationIdByAddressRequest,
  INewCreateOrganizationRequest,
  ORGANISATION_GENERAL_TYPES,
} from '@joc/api-gateway';
//validation
import {
  imagePathSchema,
  organizationDescriptionSchema,
  organizationGeneralTypeSchema,
  organizationNameShema,
} from 'core/validation';

export type AboutOrgSignupFormValues = INewCreateOrganizationRequest;

export const getInitialValues = (): AboutOrgSignupFormValues => {
  const localStorageAboutOrg = localStorage.getItem('aboutOrg');

  if (localStorageAboutOrg) return JSON.parse(localStorageAboutOrg);
  return {
    organizationName: '',
    organisationGeneralType: ORGANISATION_GENERAL_TYPES.OTHER,
    organizationLogoPath: '',
    organizationDescription: '',
    organizationPhoneNumber: '',
    organizationEmail: '',
    organizationWebSite: '',
    organisationAddress: GetLocationIdByAddressRequest.fromJS({
      countryName: '',
      stateName: '',
      cityName: '',
      streetName: '',
      buildingNumber: '',
      zipCode: '',
      pobox: '',
    }),
  };
};

export const validationSchema = Yup.object().shape({
  organizationName: organizationNameShema.trim(),
  organisationGeneralType: organizationGeneralTypeSchema,
  organizationLogoPath: imagePathSchema,
  organizationDescription: organizationDescriptionSchema.trim(),
});
