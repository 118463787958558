import {Dispatch} from 'redux';
import {LiteralStringForUnion, StreamChat} from 'stream-chat';
//redux
import {
  RESET_CHAT,
  RESET_CHAT_COUNTER,
  SET_CHAT_CLIENT_CONNECT_STATE,
  SET_CHAT_UNREAD_MESSAGE_COUNT,
  SET_CHAT_USER_TO_CONNECT,
} from 'redux/actions-types';
//API
import {API} from 'core/API';
//types
import {GenerateChatUserTokenRequest, SearchVolunteersRequest} from '@joc/api-gateway';
import {UserToConnectType} from './types';

export type AttachmentType = {};
export type ChannelType = {};
export type CommandType = LiteralStringForUnion;
export type EventType = {};
export type MessageType = {};
export type ReactionType = {};
export type UserType = {image?: string; id: string};

const apiKey = process.env.REACT_APP_STREAM_KEY;
export const chatClient = StreamChat.getInstance(apiKey!);

export const setChatUserToConnect =
  (userToConnect: UserToConnectType | null) =>
  (dispatch: Dispatch): void => {
    dispatch({type: SET_CHAT_USER_TO_CONNECT, payload: userToConnect});
  };

export const connectUserToGetStream =
  (userToConnect: UserToConnectType) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const userChatToken: string = await API.generateChatUserToken(
        GenerateChatUserTokenRequest.fromJS({chatId: userToConnect.id})
      );

      const connectedUser = await chatClient.connectUser(
        {
          id: userToConnect.id as string,
          name: userToConnect.name,
          image: userToConnect.image,
          type: userToConnect.type,
        } as any,
        userChatToken
      );

      dispatch({type: SET_CHAT_CLIENT_CONNECT_STATE, payload: !!connectedUser || false});

      const unreadMessagesCount = connectedUser && connectedUser?.me?.total_unread_count;

      if (unreadMessagesCount)
        dispatch({
          type: SET_CHAT_UNREAD_MESSAGE_COUNT,
          payload: unreadMessagesCount,
        });
    } catch (error) {
      console.error('ERROR CHAT', error);
      chatClient?.disconnectUser();
    }
  };

export const setChatUnreadMessagesCount =
  (count: number) =>
  (dispatch: Dispatch): void => {
    dispatch({
      type: SET_CHAT_UNREAD_MESSAGE_COUNT,
      payload: count,
    });
  };

export const resetChatCounter = () => (dispatch: Dispatch) => {
  dispatch({
    type: RESET_CHAT_COUNTER,
  });
};

export const resetChat = () => (dispatch: Dispatch) => {
  dispatch({
    type: RESET_CHAT,
  });
};
