import {IDiplomasResponse} from '@joc/api-gateway/lib/api-client';
import {GRADE_IMAGES} from 'core/constants';

export const setDiplomaSvg = (allDiplomas: Array<IDiplomasResponse>, param: string | undefined) => {
  if (allDiplomas?.length) {
    switch (param) {
      case allDiplomas[0].statusName:
        return GRADE_IMAGES.grade1;
      case allDiplomas[1].statusName:
        return GRADE_IMAGES.grade2;
      case allDiplomas[2].statusName:
        return GRADE_IMAGES.grade3;
      case allDiplomas[3].statusName:
        return GRADE_IMAGES.grade4;
      case allDiplomas[4].statusName:
        return GRADE_IMAGES.grade5;
      case allDiplomas[5].statusName:
        return GRADE_IMAGES.grade6;
      case allDiplomas[6].statusName:
        return GRADE_IMAGES.grade7;
      case allDiplomas[7].statusName:
        return GRADE_IMAGES.grade8;
      case allDiplomas[8].statusName:
        return GRADE_IMAGES.grade9;
      case allDiplomas[9].statusName:
        return GRADE_IMAGES.grade10;
      case allDiplomas[10].statusName:
        return GRADE_IMAGES.grade11;
      case allDiplomas[11].statusName:
        return GRADE_IMAGES.grade12;
      case allDiplomas[12].statusName:
        return GRADE_IMAGES.grade13;
      case allDiplomas[13].statusName:
        return GRADE_IMAGES.grade14;
      case allDiplomas[14].statusName:
        return GRADE_IMAGES.grade15;
      case allDiplomas[15].statusName:
        return GRADE_IMAGES.grade16;
      case allDiplomas[16].statusName:
        return GRADE_IMAGES.grade17;
      case allDiplomas[17].statusName:
        return GRADE_IMAGES.grade18;
      case allDiplomas[18].statusName:
        return GRADE_IMAGES.grade19;
      case allDiplomas[19].statusName:
        return GRADE_IMAGES.grade20;
      case allDiplomas[20].statusName:
        return GRADE_IMAGES.grade20;
      default:
        return GRADE_IMAGES.grade1;
    }
  } else {
    return GRADE_IMAGES.grade1;
  }
};
