import {useEffect, useRef} from 'react';

export function useClickOutside<T extends HTMLElement>(callback?: () => void) {
  const ref = useRef<T>(null);

  const outsideClickHandler = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node) && callback) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', outsideClickHandler);

    return () => {
      document.removeEventListener('mousedown', outsideClickHandler);
    };
  }, [callback]);

  return ref;
}
