import {FC, useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
//redux
import {useDispatch, useSelector} from 'react-redux';
import {getStatuses} from '../../../../redux/status-service/actions';
import {selectorGetOrgInfo} from '../../../../redux/organization-service/selector';
//components
import InputSearch from '../../../../shared/components/Inputs/InputSearch';
import ButtonDefault from '../../../../shared/components/Buttons/ButtonsDefault';
import StatusesTable from './StatusesTable';
import StatusesPopup from './StatusesPopup';
//styles
import styles from './Statuses.module.scss';

const Statuses: FC = () => {
  const {t} = useTranslation(['inputs', 'buttons']);
  const [isClearSearch, setIsClearSearch] = useState(true);
  const [isShowStatusPopup, setIsShowStatusPopup] = useState(false);
  const [search, setSearch] = useState('');

  const dispatch = useDispatch();
  const organizationInfo = useSelector(selectorGetOrgInfo);

  const searchChangeHandler = (value: string): void => setSearch(value);

  const closePopup = useCallback(() => setIsShowStatusPopup(false), []);

  useEffect(() => {
    dispatch(getStatuses(organizationInfo?.id));
  }, [organizationInfo]);

  return (
    <section className={styles.statuses}>
      <div className={styles.statuses__header}>
        <InputSearch
          placeholder={t('inputs:placeholders.searchWithDots')}
          classList={['big']}
          parentClass={styles.statuses__search}
          changeHandler={searchChangeHandler}
          isClearSearch={isClearSearch}
          setIsClearSearch={setIsClearSearch}
        />
        <ButtonDefault
          title={t('buttons:button.create')}
          classList={['primary', 'sm']}
          parentClassName={styles.statuses__create}
          clickHandler={() => setIsShowStatusPopup(true)}
        />
      </div>
      <StatusesTable search={search} />

      {isShowStatusPopup && <StatusesPopup isModalOpen={isShowStatusPopup} onClose={closePopup} />}
    </section>
  );
};

export default Statuses;
