import {FC} from 'react';
import {useTranslation} from 'react-i18next';
//components
import Appeal from 'shared/components/Appeal/DefaultRenderer';
//styles
import styles from '../styles.module.scss';

const ClaimPending: FC = () => {
  const {t} = useTranslation(['messages']);

  return (
    <Appeal styleJoin>
      <div className={styles.content}>
        <h1 className="title">{t('messages:congratulations')}</h1>
        <div className={styles.subtitle}>{t('messages:claimSentSuccess')}</div>
      </div>
    </Appeal>
  );
};

export default ClaimPending;
