import {Channel} from 'stream-chat';
import {Avatar} from 'stream-chat-react';
//functions
import {ePreventDefault, setUserPhotoSrc} from 'core/functions';
//components
import {AvatarGroup} from '../MessagingChannelHeader/MessagingChannelHeader';

export type ChannelProps = Channel;

type ChannelSearchResultProps = {
  channel: ChannelProps;
  setActiveChannel: any;
};

export const ChannelSearchResult = ({channel, setActiveChannel}: ChannelSearchResultProps) => {
  const members = Object.values(channel.state.members || {});

  return (
    <li
      className="messaging-create-channel__user-result"
      style={{justifyContent: 'flex-start'}}
      onClick={() => setActiveChannel(channel)}
      onMouseDown={ePreventDefault}
    >
      {members.length === 2 ? (
        <Avatar
          image={setUserPhotoSrc(
            members
              .slice(1, 2)
              .map((member) => member?.user?.image)
              .toString()
          )}
          size={50}
        />
      ) : (
        <AvatarGroup members={members} />
      )}
      <div className="messaging-create-channel__user-result__details">
        {channel.data?.name ||
          Object.values(channel.state.members)
            .slice(-1, 1)
            .map((member) => member?.user?.name)
            .filter((name) => !!name)
            .join(', ')}
      </div>
    </li>
  );
};
