//images
import HeartSvg from 'assets/image/heart.svg';
import RingSvg from 'assets/image/ring.svg';
import UserSvg from 'assets/image/user.svg';
import HammerSvg from 'assets/image/hammer.svg';
import UsergroupSvg from 'assets/image/usergroup.svg';
//types
import {DASHBOARD_ITEM_IMAGE} from 'core/types';

export const getDashboardItemImage = (imageValues?: DASHBOARD_ITEM_IMAGE): string => {
  switch (imageValues) {
    case DASHBOARD_ITEM_IMAGE.HEART:
      return HeartSvg;

    case DASHBOARD_ITEM_IMAGE.RING:
      return RingSvg;

    case DASHBOARD_ITEM_IMAGE.USER_GROUP:
      return UsergroupSvg;

    case DASHBOARD_ITEM_IMAGE.USER:
      return UserSvg;

    case DASHBOARD_ITEM_IMAGE.HAMMER:
      return HammerSvg;

    default:
      return HeartSvg;
  }
};
