import {OPPORTUNITY_TYPES, WITH_WHO} from '@joc/api-gateway';
import moment from 'moment';

export const CREATE_ADMIN_MANUAL_OPPORTUNITY_INIT_VALUES = {
  endTime: 0,
  user: null,
  tagId: null,
  imagePath: '',
  filePathes: [],
  volunteerId: null,
  recipientName: '',
  recipientEmail: '',
  organisationId: '',
  opportunityName: '',
  endDate: new Date(),
  startDate: new Date(),
  isApprovalRequired: false,
  opportunityDescription: '',
  recipientPhoneNumber: null,
  opportunityType: OPPORTUNITY_TYPES.MANUAL,
  startTime: moment().add(1, 'hours').format('HH:mm'),
  withWho: [WITH_WHO.BABIES, WITH_WHO.OTHER, WITH_WHO.ADULTS, WITH_WHO.SENIORS, WITH_WHO.KIDS, WITH_WHO.TEENS],
};
