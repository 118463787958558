import * as Yup from 'yup';
//types
import {
  IGroupAgesRequest,
  IGroupLocationsRequest,
  IGroupSpendTimeRequest,
  SPEND_TIME_GROUP_TYPE,
} from '@joc/api-gateway';
//i18n
import i18n from 'components/translate';

const validationT = i18n.getFixedT(null, 'validation');

export type SmartGroupLocationValue = IGroupLocationsRequest;
export type SmartGroupAgeValue = IGroupAgesRequest;
export type SmartGroupGenderValue = number;
export type SmartGroupSpendTimeValue = {
  groupType: SPEND_TIME_GROUP_TYPE;
  direction: keyof IGroupSpendTimeRequest;
  amount: number | SPEND_TIME_GROUP_TYPE;
};
export type SmartGroupShoolGradeValue = number;
export type SmartGroupVolunteersValue = Array<number>;
export type SmartGroupControlName = keyof Omit<SmartGroupFormValues, 'groupName'>;
export type SmartGroupControl = {id: string; name?: SmartGroupControlName};
export type SmartGroupFormValues = {
  groupName: string;
  locations: SmartGroupLocationValue | null;
  ages: SmartGroupAgeValue | null;
  gender: SmartGroupGenderValue | null;
  spendTime: SmartGroupSpendTimeValue | null;
  schoolGrades: SmartGroupShoolGradeValue | null;
  volunteers: SmartGroupVolunteersValue | null;
};

export const initialValues: SmartGroupFormValues = {
  groupName: '',
  gender: null,
  schoolGrades: null,
  locations: null,
  volunteers: null,
  ages: null,
  spendTime: null,
};

export const validationSchema = Yup.object().shape({
  groupName: Yup.string()
    .test('noLeadingSpaces', validationT('organizationSmartGroupFilterSchema.spaces'), (value) => {
      if (value && value.startsWith(' ')) return false;
      return true;
    })
    .required(validationT('organizationSmartGroupFilterSchema.required'))
    .min(1, validationT('organizationSmartGroupFilterSchema.min'))
    .max(100, validationT('organizationSmartGroupFilterSchema.max')),
  schoolGrades: Yup.number().nullable(),
  gender: Yup.number().nullable(),
  ages: Yup.object().nullable().shape({
    min: Yup.number().required(),
    max: Yup.number().required(),
  }),
  spendTime: Yup.object()
    .nullable()
    .shape({
      groupType: Yup.string().oneOf(['TOTAL', 'YEAR']).required(),
      direction: Yup.string().oneOf(['moreThan', 'lessThan']).required(),
      amount: Yup.number().when('direction', {
        is: 'lessThan',
        then: Yup.number().min(1).required(),
        otherwise: Yup.number().min(0).required(),
      }),
    }),
  locations: Yup.object().nullable(),
  volunteers: Yup.array(Yup.number()).nullable(),
});
