import {Dispatch, FC, ReactNode, SetStateAction} from 'react';
//components
import PopupContainer from 'shared/components/PopupContainer';

type PopupMainProps = {
  setIsShowPopup: Dispatch<SetStateAction<boolean>> | ((isOpen: boolean) => void);
  isDisablePadding?: boolean;
  children?: ReactNode;
};

const PopupMain: FC<PopupMainProps> = ({children, setIsShowPopup, isDisablePadding}: PopupMainProps) => {
  return (
    <PopupContainer isDisablePadding={isDisablePadding} setIsShowPopup={setIsShowPopup}>
      {children}
    </PopupContainer>
  );
};

export default PopupMain;
