import {FC, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation} from 'react-router';
import {useSelector} from 'react-redux';
//redux
import {selectorGetOrgInfo, selectorGetSchoolId} from 'redux/organization-service/selector';
//types
import {IDateRange} from '@joc/api-gateway';
//helpers
import {getDefaultDateRange, getParsedDateRange} from './helpers';
//components
import TopVolunteers from 'components/Organization/Statistics/TopVolunteers';
import GeneralOverview from 'components/Organization/Statistics/GeneralOverview';
import StatisticsFilter from 'components/Organization/Statistics/StatisticsFilter';
import UsersTrafficSignup from 'components/Organization/Statistics/UsersTrafficSignup';
import UsersGradeTimeSpent from 'components/Organization/Statistics/UsersGradeTimeSpent';
//styles
import styles from './Statistics.module.scss';

const Statistics: FC = () => {
  const location = useLocation();

  const schoolId = useSelector(selectorGetSchoolId);

  const [chosenDateRange, setChosenDateRange] = useState<IDateRange | null>(getParsedDateRange(location.search));

  const orgInfo = useSelector(selectorGetOrgInfo);

  const defaultDateRange = useMemo(
    () => getDefaultDateRange(orgInfo?.school?.startYearDate, orgInfo?.school?.endYearDate),
    [orgInfo?.school]
  );

  const statisticsDateRange = useMemo(() => chosenDateRange || defaultDateRange, [chosenDateRange, defaultDateRange]);

  const {t} = useTranslation('statistics');

  useEffect(() => {
    setChosenDateRange(getParsedDateRange(location.search));
  }, [location.search]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1 className="title">{t('title')}</h1>
        <StatisticsFilter dateRange={statisticsDateRange} />
      </div>
      <div className={styles.wrapper}>
        <div className={styles.left_side}>
          <GeneralOverview dateRange={statisticsDateRange} />
          {schoolId ? (
            <UsersGradeTimeSpent dateRange={statisticsDateRange} />
          ) : (
            <UsersTrafficSignup dateRange={statisticsDateRange} />
          )}
          <TopVolunteers dateRange={statisticsDateRange} />
        </div>
      </div>
    </div>
  );
};

export default Statistics;
