import {FC, useMemo, useState} from 'react';
import cx from 'classnames';
//functions
import {setWithWhoFilterVision} from 'core/functions';
//images
import whoFilterBack from 'assets/image/selects/whoFilterBackground.svg';
import whoFilterBackSelected from 'assets/image/selects/whoFilterBackgroundSelected.svg';
//styles
import styles from './WhoItem.module.scss';

type WhoItemProps = {
  // fieldName: string;
  who: string;
  checked: boolean;
  parentClassName?: string;
};

const WhoItem: FC<WhoItemProps> = ({who, parentClassName, checked}: WhoItemProps) => {
  const [whoVision] = useState(setWithWhoFilterVision(who));

  const placeholder = useMemo(() => {
    return `${whoVision.name} ${whoVision.age}`;
  }, [whoVision]);

  return (
    <div className={cx(styles.main, checked && styles.checked, parentClassName && parentClassName)}>
      <div className={styles.icon}>
        <img src={checked ? whoFilterBackSelected : whoFilterBack} className={styles.icon__label} alt="who" />
        {whoVision.src && <img className={styles.icon__image} src={whoVision.src} alt="who" />}
      </div>
      <span className={styles.placeholder}>{placeholder}</span>
    </div>
  );
};

export default WhoItem;
