import {FC, useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
//redux
import {Store} from 'redux/root';
import {connect, ConnectedProps} from 'react-redux';
import {getStatisticsGeneral} from 'redux/dashboard-service/action';
import {
  selectorGetStatisticsGeneral,
  selectorGetStatisticsVolunteersAvgAgeTotal,
} from 'redux/dashboard-service/selector';
import {selectorGetSchoolId} from 'redux/organization-service/selector';
//types
import {IDateRange} from '@joc/api-gateway';
//urls
import {urls} from 'core/appUrls';
//types
import {DASHBOARD_ITEM_IMAGE} from 'core/types';
//helpers
import {getTotalOpportunitiesLink} from './helpers';
//components
import DashboardItem from 'components/Organization/Dashboard/DashboardItem';
import ResponseFailure from 'shared/components/ResponseFailure';
import WhiteContainer from 'shared/components/WhiteContainer';
import Loader from 'shared/components/Loader';
//styles
import parentStyles from 'pages/Organization/Statistics/Statistics.module.scss';
import styles from './GeneralOverview.module.scss';

type GeneralOverviewParentProps = {
  dateRange: IDateRange | null;
};

const GeneralOverview: FC<GeneralOverviewProps> = ({
  general,
  schoolId,
  getStatisticsGeneral,
  avgAgeTotal,
  dateRange,
}: GeneralOverviewProps) => {
  const {toDate, fromDate} = {...dateRange};

  const [isLoading, setIsLoading] = useState(true);
  const [customError, setCustomError] = useState('');

  const {t} = useTranslation(['statistics', 'errors']);

  const getGeneralData = useCallback(async () => {
    setCustomError('');
    setIsLoading(true);
    try {
      await getStatisticsGeneral(dateRange);
    } catch (error) {
      setCustomError(error?.response?.message || error.message);
    } finally {
      setIsLoading(false);
    }
  }, [fromDate, toDate]);

  useEffect(() => {
    getGeneralData();
  }, [getGeneralData]);

  const totalOpportunitiesLink = getTotalOpportunitiesLink();

  if (customError.length)
    return (
      <ResponseFailure
        message={customError}
        buttonTitle={t('errors:tryAgain')}
        buttonClickHandler={getGeneralData}
        styleTable
      />
    );
  return (
    <WhiteContainer parentClassName={parentStyles.container_block} title={t('statistics:overview.title')}>
      {isLoading ? (
        <Loader />
      ) : (
        <div className={styles.content}>
          <DashboardItem
            title={t('statistics:overview.totalOpportunities')}
            count={general.opportunities.currentValue}
            urlTo={totalOpportunitiesLink}
            image={DASHBOARD_ITEM_IMAGE.HEART}
          />
          <DashboardItem
            title={t('statistics:overview.totalChesedHours')}
            count={general.chesedHours.currentValue}
            urlTo={totalOpportunitiesLink}
            image={DASHBOARD_ITEM_IMAGE.HEART}
          />
          <DashboardItem
            title={t(`statistics:overview.${schoolId ? 'totalStudents' : 'totalVolunteers'}`)}
            count={general.volunteers.currentValue}
            urlTo={urls.orgVolunteers}
            image={DASHBOARD_ITEM_IMAGE.USER}
          />
          {/* <DashboardItem
					title={schoolId ? 'Total Students Average Age' : 'Total Volunteers Average age'}
					count={avgAgeTotal}
					urlTo={urls.orgVolunteers}
					usersvg
				/> */}
        </div>
      )}
    </WhiteContainer>
  );
};

const mapStateToProps = (store: Store) => ({
  general: selectorGetStatisticsGeneral(store),
  avgAgeTotal: selectorGetStatisticsVolunteersAvgAgeTotal(store),
  schoolId: selectorGetSchoolId(store),
});

const mapDispatchToProps = {
  getStatisticsGeneral,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type GeneralOverviewProps = ConnectedProps<typeof connector> & GeneralOverviewParentProps;

export default connector(GeneralOverview);
