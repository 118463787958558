//types
import {RECURRENCE_POSSIBLE_UPDATE} from '@joc/api-gateway';

const JUST_THIS_OPPORTUNITY = 'Just this opportunity';
const THIS_AND_ALL_FUTURE_OPPORTUNITIES = 'This and all future opportunities';
const ALL_OPEN_OPPORTUNITIES = 'All open opportunities';

export type UpdateOpportunityOptionLabel =
  | typeof JUST_THIS_OPPORTUNITY
  | typeof THIS_AND_ALL_FUTURE_OPPORTUNITIES
  | typeof ALL_OPEN_OPPORTUNITIES;

type UpdateOpportunityOption = {
  label: UpdateOpportunityOptionLabel;
  value: RECURRENCE_POSSIBLE_UPDATE;
};

export const updateOpportunityOptions: Array<UpdateOpportunityOption> = [
  {label: THIS_AND_ALL_FUTURE_OPPORTUNITIES, value: RECURRENCE_POSSIBLE_UPDATE.FUTURE},
  {label: ALL_OPEN_OPPORTUNITIES, value: RECURRENCE_POSSIBLE_UPDATE.ALL},
];
