import {ComponentType, useEffect, useState} from 'react';
import {Avatar, AvatarProps} from 'stream-chat-react';
//functions
import {getDefaultImage, setUserPhotoSrcPromise} from 'core/functions';

const UserChatAvatar: ComponentType<AvatarProps> = ({image, size}) => {
  const [avatarSrc, setAvatarSrc] = useState<string>(getDefaultImage());

  useEffect(() => {
    const loadImg = async () => {
      const url = await setUserPhotoSrcPromise(image || '');
      setAvatarSrc(url);
    };

    loadImg();
  }, [image]);

  const imageSize = size || 50;

  return <Avatar image={avatarSrc} shape="circle" size={imageSize} />;
};

export default UserChatAvatar;
